import { BehaviorSubject } from 'rxjs';
import axios from 'axios';
import {
   readLocalStorage,
   removeLocalStorage,
   saveLocalStorage
} from '@helpers/local-storage-helper';
import {
   KEY_ARRAY_MY_MENU,
   KEY_EMPRESA,
   KEY_OBJECT_MY_MENU,
   KEY_TOOGLE_MENU,
   KEY_USER_DATA,
   TOKEN_API,
   RUC_EMPRESA,
   KEY_BODEGAS,
   KEY_BODEGA_SELECT,
   PRODUCTS_CARD,
   KEY_BENEFICIO_PRO,
   KEY_BENEFICIO_MON,
   KEY_EDIT_PEDIDO,
   KEY_APPLY_CUPONS,
   KEY_APPLY_BENEF_POINTS,
   KEY_CLIENT_POINTS
} from '@constants/local-storage';
import { APP_AUTHORIZE_KEY } from '@defaults/app';
import { STATIC_ROUTERS } from '@defaults/static-routers';
import {
   ROLE_SUPER_ADMIN,
   ROLE_ADMIN,
   ROLE_CAJERO,
   ROLE_VENDEDOR,
   ROLE_USUARIO_CLIENTE,
   ROUTES_FOR_SUPER_ADMIN,
   ROUTES_FOR_ADMIN,
   ROUTES_FOR_CAJERO,
   ROUTES_FOR_VENDEDOR,
   ROUTES_FOR_USER_CLIENTE,
   ROLE_USUARIO_ECOMMERCE} from '@defaults/static-roles';
import { setAuthCookie, readAuthCookie } from '@helpers/cookie.helper';
import { authenticationRepository } from '../repositories/Authentication.repository';
import { BeneficioMontoRepository } from '../repositories/BeneficioMonto.repository';
import { BeneficioProductoRepository } from '../repositories/BeneficioProducto.repository';
import { Authentication } from '../models/Authentication';
import { ROUTE_CAJA, ROUTE_HOME, ROUTE_PEDIDOS, ROUTE_SALAS, ROUTE_SHOP_ECOMMERCE } from '@/toolbox/constants/route-map';

const currentUserSubject = new BehaviorSubject<Authentication | null>(init());

export const authenticationService = {
   loginShop,
   loginEcommerce,
   loginQR,
   loginClient,
   logout,
   end,
   authCookie: readAuthCookie,
   currentUser: currentUserSubject.asObservable(),
   get currentUserValue () { return currentUserSubject.value }
};

function init() {
   const auth: Authentication = readLocalStorage(KEY_USER_DATA);
   axios.defaults.headers.common[APP_AUTHORIZE_KEY] = auth?.token || '';
   return auth;
}
function end() {
   removeLocalStorage(KEY_USER_DATA);
   removeLocalStorage(KEY_ARRAY_MY_MENU);
   removeLocalStorage(KEY_OBJECT_MY_MENU);
   removeLocalStorage(KEY_TOOGLE_MENU);
   removeLocalStorage(KEY_BODEGAS);
   removeLocalStorage(KEY_BODEGA_SELECT);
   removeLocalStorage(RUC_EMPRESA);
   removeLocalStorage(TOKEN_API);
   removeLocalStorage(PRODUCTS_CARD);
   removeLocalStorage(KEY_EMPRESA);
   removeLocalStorage(KEY_EMPRESA);
   removeLocalStorage(KEY_EDIT_PEDIDO);
   removeLocalStorage(KEY_APPLY_CUPONS);
   removeLocalStorage(KEY_APPLY_BENEF_POINTS);
   removeLocalStorage(KEY_CLIENT_POINTS);
   removeLocalStorage('keysClient');
   axios.defaults.headers.common[APP_AUTHORIZE_KEY] = null;
   currentUserSubject.next(null);
}

async function loginShop(user_nick: string, rut_empresa: string, password: string) : Promise<Authentication> {
   try {
      const login = await authenticationRepository.login(user_nick, rut_empresa, password);
      if (!!login.error) {
         return {
            user  : null,
            token : '',
            error : login.error
         }
      }


      const token_softnet = `${login.data?.token_softnet|| ''}`;

      let bodegas: any;
      if(token_softnet !== ''){
         const auth = await authenticationRepository.dataUser(token_softnet);
         // bodegas = await authenticationRepository.bodegasPorSucursal(token_softnet, auth.id_sucursal);
      }

      const access_token = `${login.data?.token_type || ''} ${login.data?.token || ''}`;
      if (access_token === '') {
         return {
            user  : null,
            token : '',
            error : login.error
         }
      }
      
      axios.defaults.headers.common[APP_AUTHORIZE_KEY] = access_token;
      const authUser = await authenticationRepository.profile(access_token);
      if (!!authUser.error) {
         return {
            user  : null,
            token : '',
            error : authUser.error
         }
      }

      let role: string = authUser.user.rol || '';
      let routesRules: any = [],
      mainRedirect: any,
      modules = [];

      if(role === ROLE_ADMIN){
         routesRules = ROUTES_FOR_ADMIN;
         mainRedirect = ROUTE_HOME;
      }
      else if(role === ROLE_SUPER_ADMIN){
         routesRules = ROUTES_FOR_SUPER_ADMIN;
         mainRedirect = ROUTE_HOME;
      }
      else if(role === ROLE_CAJERO){

         routesRules = ROUTES_FOR_CAJERO;
         mainRedirect = ROUTE_HOME;
      }
      else if(role === ROLE_VENDEDOR){
         routesRules = ROUTES_FOR_VENDEDOR;
         mainRedirect = ROUTE_HOME;
      }
      else if(role === ROLE_USUARIO_CLIENTE){
         routesRules = ROUTES_FOR_USER_CLIENTE;
         mainRedirect = ROUTE_HOME;
      }

      authUser.user.main_redirect = mainRedirect;

      STATIC_ROUTERS.forEach( module => {
         routesRules.forEach((route: {module: string, navigators: []}) => {
            if (route.module === module.route) {
               // if ((idTipoEmpresa === 1 || idTipoEmpresa === 4) && (route.module === ROUTE_PEDIDOS || route.module === ROUTE_SALAS )) {
               // } else {
                  modules.push({
                     ...module,
                     "route-navigators": route.navigators
                  })
               // }
            }
         })
      });

   //    await BeneficioMontoRepository.getBeneficioMonto().then((data) =>{
   //       saveLocalStorage(KEY_BENEFICIO_MON, (data.puntosMonto||[]));
   //   })
   //    await BeneficioProductoRepository.getBeneficioProducto().then((data) =>{
   //       saveLocalStorage(KEY_BENEFICIO_PRO, (data.puntosProducto||[]));
   //   })

      saveLocalStorage(KEY_USER_DATA, authUser);
      saveLocalStorage(KEY_ARRAY_MY_MENU, modules);
      saveLocalStorage(RUC_EMPRESA, rut_empresa);
      saveLocalStorage(KEY_EMPRESA, {
         "id": authUser?.user?.id_empresa,
         "id_sucursal": authUser?.user?.id_sucursal,
         "id_tipo_empresa": authUser?.user?.id_tipo_empresa,
         "nombre": authUser?.user?.nombre_empresa,
         "equivalencia_puntos": authUser?.user?.equivalencia_puntos_empresa,
         "id_country": authUser?.user?.id_country,
         "country_name": authUser?.user?.country_name,
         "country_abbreviation": authUser?.user?.country_abbreviation,
         "mesas": authUser?.user?.mesas,
      });
      saveLocalStorage(TOKEN_API, token_softnet);
      saveLocalStorage(KEY_TOOGLE_MENU, true);
      if(bodegas){
         saveLocalStorage(KEY_BODEGAS, bodegas);
         saveLocalStorage(KEY_BODEGA_SELECT, bodegas[0].id);
      }

      if(!!login.data.token) {
         setAuthCookie(access_token, undefined);
         currentUserSubject.next(authUser);
      }
      const authResponse = {...authUser, data: login.data};

      return authResponse;

   } catch (e) {
      return {
         user: null, token: '', error: { code: 0, message: 'Error en obtener permisos' }
      }
   }
}

async function loginEcommerce(name: string, rut:string, username:string, email:string, password:string) : Promise<Authentication> {
   try {
      const login = await authenticationRepository.loginEcommerce(name, rut, username, email, password);
      if (!!login.error) {
         return {
            cliente_id : null,
            user  : null,
            token : '',
            error : login.error
         }
      }
      const access_token = `${login.data?.token_type || ''} ${login.data?.token || ''}`;
      axios.defaults.headers.common[APP_AUTHORIZE_KEY] = access_token;

      const authUser = await authenticationRepository.profile(access_token);
      if (!!authUser.error) {
         return {
            cliente_id : null,
            user  : null,
            token : '',
            error : authUser.error,
            data : login.data
         }
      }

      let role: string = authUser.user.rol || '';
      let routesRules: any = [],
      mainRedirect: any,
      modules = [];

      if(role === ROLE_USUARIO_ECOMMERCE){
         // eslint-disable-next-line
         routesRules = ROUTES_FOR_USER_CLIENTE;
         // eslint-disable-next-line
         mainRedirect = ROUTE_SHOP_ECOMMERCE;
      } else {
         return {
            cliente_id : null,
            user  : null,
            token : '',
            error : authUser.error,
            data : login.data
         }
      }
      saveLocalStorage(KEY_USER_DATA, authUser);
      saveLocalStorage(KEY_ARRAY_MY_MENU, modules);
      saveLocalStorage(KEY_TOOGLE_MENU, true);

      if(!!login.data.token) {
         setAuthCookie(access_token, undefined);
         currentUserSubject.next(authUser);
      }
      const authResponse = {...authUser, data: login.data, cliente_id : login.data.cliente_id};

      return authResponse;

   } catch (e) {
      return {
         user: null, token: '', error: { code: 0, message: 'Error en obtener permisos' }
      }
   }
}

async function loginQR(mesaID: string, name: string, rut:string, user:string, password:string) : Promise<Authentication> {
   try {
      const login = await authenticationRepository.loginQR(mesaID, name, rut, user, password);
      if (!!login.error) {
         return {
            cliente_id : null,
            user  : null,
            token : '',
            error : login.error
         }
      }
      const token_softnet = `${login.data?.token_softnet|| ''}`;
      const access_token = `${login.data?.token_type || ''} ${login.data?.token || ''}`;
      axios.defaults.headers.common[APP_AUTHORIZE_KEY] = access_token;

      const authUser = await authenticationRepository.profile(access_token);

      if (!!authUser.error) {
         return {
            cliente_id : null,
            user  : null,
            token : '',
            error : authUser.error
         }
      }

      let role: string = authUser.user.rol || '';
      let routesRules: any = [],
      mainRedirect: any,
      modules = [];

      if(role === ROLE_USUARIO_CLIENTE){
         routesRules = ROUTES_FOR_USER_CLIENTE;
         mainRedirect = ROUTE_HOME;
      } else {
         return {
            cliente_id : null,
            user  : null,
            token : '',
            error : authUser.error
         }
      }

      authUser.user.main_redirect = mainRedirect+"?key="+mesaID+"&key2="+login.data?.cliente_id;

      let idTipoEmpresa = authUser?.user?.id_tipo_empresa || 0;
      STATIC_ROUTERS.forEach( module => {
         routesRules.forEach((route: {module: string, navigators: []}) => {
            if (route.module === module.route) {
               if ((idTipoEmpresa === 1 || idTipoEmpresa === 4) && (route.module === ROUTE_PEDIDOS || route.module === ROUTE_SALAS )) {
               } else {
                  modules.push({
                     ...module,
                     "route-navigators": route.navigators
                  })
               }
            }
         })
      });

   //    await BeneficioMontoRepository.getBeneficioMonto().then((data) =>{
   //       saveLocalStorage(KEY_BENEFICIO_MON, (data.puntosMonto||[]));
   //   })
   //    await BeneficioProductoRepository.getBeneficioProducto().then((data) =>{
   //       saveLocalStorage(KEY_BENEFICIO_PRO, (data.puntosProducto||[]));
   //   })

      saveLocalStorage(KEY_USER_DATA, authUser);
      saveLocalStorage(KEY_ARRAY_MY_MENU, modules);
      saveLocalStorage(RUC_EMPRESA, authUser?.user?.rut_empresa);
      saveLocalStorage(KEY_EMPRESA, {
         "id": authUser?.user?.id_empresa,
         "id_tipo_empresa": authUser?.user?.id_tipo_empresa,
         "nombre": authUser?.user?.nombre_empresa,
         "equivalencia_puntos": authUser?.user?.equivalencia_puntos_empresa,
         "id_country": authUser?.user?.id_country,
         "country_name": authUser?.user?.country_name,
         "country_abbreviation": authUser?.user?.country_abbreviation,
         "mesas": authUser?.user?.mesas,
      });
      saveLocalStorage(TOKEN_API, token_softnet);
      saveLocalStorage(KEY_TOOGLE_MENU, true);

      if(!!login.data.token) {
         setAuthCookie(access_token, undefined);
         currentUserSubject.next(authUser);
      }
      const authResponse = {...authUser, data: login.data, cliente_id : login.data.cliente_id};

      return authResponse;

   } catch (e) {
      return {
         user: null, token: '', error: { code: 0, message: 'Error en obtener permisos' }
      }
   }
}

async function loginClient(mesaID: string, user:string, password:string) : Promise<Authentication> {
   try {
      const login = await authenticationRepository.loginClient(mesaID, user, password);
      if (!!login.error) {
         return {
            cliente_id : null,
            user  : null,
            token : '',
            error : login.error
         }
      }
      const token_softnet = `${login.data?.token_softnet|| ''}`;
      const access_token = `${login.data?.token_type || ''} ${login.data?.token || ''}`;
      axios.defaults.headers.common[APP_AUTHORIZE_KEY] = access_token;

      const authUser = await authenticationRepository.profile(access_token);

      if (!!authUser.error) {
         return {
            cliente_id : null,
            user  : null,
            token : '',
            error : authUser.error
         }
      }

      let role: string = authUser.user.rol || '';
      let routesRules: any = [],
      mainRedirect: any,
      modules = [];

      if(role === ROLE_USUARIO_CLIENTE){
         routesRules = ROUTES_FOR_USER_CLIENTE;
         mainRedirect = ROUTE_HOME;
      } else {
         return {
            cliente_id : null,
            user  : null,
            token : '',
            error : authUser.error
         }
      }

      authUser.user.main_redirect = mainRedirect+"?key="+mesaID+"&key2="+login.data?.cliente_id;

      let idTipoEmpresa = authUser?.user?.id_tipo_empresa || 0;
      STATIC_ROUTERS.forEach( module => {
         routesRules.forEach((route: {module: string, navigators: []}) => {
            if (route.module === module.route) {
               if ((idTipoEmpresa === 1 || idTipoEmpresa === 4) && (route.module === ROUTE_PEDIDOS || route.module === ROUTE_SALAS )) {
               } else {
                  modules.push({
                     ...module,
                     "route-navigators": route.navigators
                  })
               }
            }
         })
      });

   //    await BeneficioMontoRepository.getBeneficioMonto().then((data) =>{
   //       saveLocalStorage(KEY_BENEFICIO_MON, (data.puntosMonto||[]));
   //   })
   //    await BeneficioProductoRepository.getBeneficioProducto().then((data) =>{
   //       saveLocalStorage(KEY_BENEFICIO_PRO, (data.puntosProducto||[]));
   //   })

      saveLocalStorage(KEY_USER_DATA, authUser);
      saveLocalStorage(KEY_ARRAY_MY_MENU, modules);
      saveLocalStorage(RUC_EMPRESA, authUser?.user?.rut_empresa);
      saveLocalStorage(KEY_EMPRESA, {
         "id": authUser?.user?.id_empresa,
         "id_tipo_empresa": authUser?.user?.id_tipo_empresa,
         "nombre": authUser?.user?.nombre_empresa,
         "equivalencia_puntos": authUser?.user?.equivalencia_puntos_empresa,
         "id_country": authUser?.user?.id_country,
         "country_name": authUser?.user?.country_name,
         "country_abbreviation": authUser?.user?.country_abbreviation,
         "mesas": authUser?.user?.mesas,
      });
      saveLocalStorage(TOKEN_API, token_softnet);
      saveLocalStorage(KEY_TOOGLE_MENU, true);

      if(!!login.data.token) {
         setAuthCookie(access_token, undefined);
         currentUserSubject.next(authUser);
      }
      const authResponse = {...authUser, data: login.data, cliente_id: login.data.cliente_id};

      return authResponse;

   } catch (e) {
      return {
         cliente_id : null, user: null, token: '', error: { code: 0, message: 'Error en obtener permisos' }
      }
   }
}

async function logout() {
   const rpta = await authenticationRepository.logout();
   if (!rpta.error) {
      end();
   }
   return rpta;
}
