import React, { useState } from 'react';
import { TextField } from '@mui/material';
import { Loading } from '@/components/common/Loading/Loading';
import { beneficioMontoService } from '@/service/services/BeneficioMonto.service';

import { Divider, Grid, Typography, Tooltip } from '@mui/material';
import { Form, Formik } from 'formik';
import PriorityHighIcon from '@mui/icons-material/PriorityHigh';
import { Snackbar, Alert } from '@mui/material';

export const AddModalMonto: React.FC<any> = (props:any): JSX.Element => {
    const {closeModal, onListBeneficioMonto, beneficioMonto} = props;

    const [loading, setLoading] = React.useState<boolean>(false);
   // eslint-disable-next-line
    const [dataMonto, setDataMonto] = React.useState({
        id: beneficioMonto && beneficioMonto.id,
        monto: beneficioMonto && beneficioMonto.monto,
        cantidad_puntos: beneficioMonto && beneficioMonto.cantidad_puntos,
    });

    const [snackBarConfig, setSnackBarConfig] = useState<any>({
        open: false,
        severity: 'error',
        message: 'Error',
        autoHideDuration: 3000,
    });

    const validateForm = (values) => {
        let errors: any ={};
        if(!values.cantidad_puntos) errors.cantidad_puntos = "cantidad requerido";
        if(!values.monto) errors.monto = "monto requerido";

        return errors;
    }

    const onSubmit = async(values, {resetForm}) => {
        const formData = new FormData();
        formData.append('monto', values.monto);
        formData.append('cantidad_puntos', values.cantidad_puntos);

        setLoading(true);
        if(beneficioMonto){
            await beneficioMontoService.update(formData, values.id).then((data) => {
                if(data.status === 200){
                    onListBeneficioMonto && onListBeneficioMonto();
                    closeModal();
                }
            })
        }else{
            await beneficioMontoService.register(formData).then((data) => {
                if(data.data.status === 400){
                    setSnackBarConfig(prev => ({
                        ...prev,
                        open: true,
                        message: `${data.data.error}`,
                    }));
                }else{
                    onListBeneficioMonto && onListBeneficioMonto();
                    closeModal();
                }
            })
        }
        setLoading(false);
    }

    return(
        <div className="container">
            {loading && <Loading/>}

            <Snackbar
                open={snackBarConfig.open}
                autoHideDuration={snackBarConfig.autoHideDuration}
                onClose={() => setSnackBarConfig(prev => ({ ...prev, open: false }))}
                anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                >
                <Alert
                onClose={() => setSnackBarConfig(prev => ({ ...prev, open: false }))}
                severity={snackBarConfig.severity}
                variant="filled"
                >
                {snackBarConfig.message}
                </Alert>
            </Snackbar>

            <Formik enableReinitialize initialValues={dataMonto} validate={ (values) => validateForm(values) } onSubmit={onSubmit}>
                {({values, errors, touched, handleSubmit, handleChange, setFieldValue}) => {
                    return(
                        <Form onSubmit={handleSubmit}>
                            <Grid container spacing={3} alignItems="center" justifyContent="center">

                                <Grid item xs={12} container style={{display: "flex", alignItems: "center"}}>
                                <Grid item xs={4}>
                                    <Typography variant="subtitle1" className="custom-input"><b>Monto Mínimo:</b></Typography>
                                </Grid>
                                <Grid item xs={8} style={{display: 'flex', alignItems: "center"}}>
                                    {
                                        errors.monto && touched.monto ? true : false && (
                                            <Tooltip title={errors.monto}>
                                                <PriorityHighIcon className={'iconWarning'}/>
                                            </Tooltip>
                                        )
                                    }
                                    <TextField
                                    id="monto"
                                    type="number"
                                    name="monto"
                                    fullWidth
                                    value={values.monto}
                                    onChange={handleChange}
                                    placeholder="Monto minimo"
                                    error={errors.monto && touched.monto ? true : false}
                                    />
                                </Grid>
                                </Grid>

                                <Grid item xs={12} container style={{display: "flex", alignItems: "center"}}>
                                <Grid item xs={4}>
                                    <Typography variant="subtitle1" className="custom-input"><b>Puntos por monto:</b></Typography>
                                </Grid>
                                <Grid item xs={8} style={{display: 'flex', alignItems: "center"}}>
                                    {
                                        errors.cantidad_puntos && touched.cantidad_puntos ? true : false && (
                                            <Tooltip title={errors.cantidad_puntos}>
                                                <PriorityHighIcon className={'iconWarning'}/>
                                            </Tooltip>
                                        )
                                    }
                                    <TextField
                                    id="cantidad_puntos"
                                    type="number"
                                    name="cantidad_puntos"
                                    fullWidth
                                    value={values.cantidad_puntos}
                                    onChange={handleChange}
                                    placeholder="Puntos por monto"
                                    error={errors.cantidad_puntos && touched.cantidad_puntos ? true : false}
                                    />
                                </Grid>
                                </Grid>


                            </Grid>
                            <Divider sx={{ my: 2 }} />
                            <div className="form-group mb-3 buton_container_modal">
                            <button type="submit" className="btn btn-primary">
                            {beneficioMonto ? "Editar Benefico" : "Agregar Beneficio" }
                            </button>
                        </div>
                        </Form>
                    )
                }}

            </Formik>
        </div>
    )
}
