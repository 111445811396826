import React, { useMemo } from 'react';
import { BrowserRouter as Router, Route, Switch, Redirect } from 'react-router-dom';
import * as Routes from '@constants/route-map';
import { useLocalStorage } from '@toolbox/hooks/local-storage.hook';
import { KEY_USER_DATA, KEY_EMPRESA } from '@toolbox/constants/local-storage';
import { Login } from "@views/Login"
import { QRView } from "@views/QRView"
import { Home } from "@views/Home"
import { PrivateRoute, AuthRoute } from './PrivateRoute';

import { Reporte } from '@/views/Reporte/Reporte ';
import { Caja } from '@/views/Caja';
import { Pedidos } from '@/views/Pedidos';
// import { ProductosMaestros } from '@/views/Productos/Productos';
import { Empleados } from '@/views/Empleados/Empleados';
import { Salas } from '@/views/Salas/Salas';
import { Editor } from '@/views/Editor/Editor';
import { Clientes } from '@/views/Clientes/Clientes';
import {
   ROLE_ADMIN,
   ROLE_SUPER_ADMIN,
   ROLE_CAJERO,
   ROLE_VENDEDOR,
   ROLE_USUARIO_CLIENTE,
   ROLE_USUARIO_ECOMMERCE
} from '@/toolbox/defaults/static-roles';
import { Beneficios } from '@/views/Beneficios';
import { Users } from '@/views/users';
import { Empresas } from '@/views/Empresas';
import { ReporteTable } from '@/views/ReporteTabla';
import { Proveedor } from '@/views/Proveedor';
import { Compras } from '@/views/Compras';
import { NuevaCompra } from '@/views/Compras/NuevaCompra';
import { PageCuadratura } from '@/views/Cuadratura';
import { Sucursales } from '@/views/Sucursales/Sucursales';
import { Inventario } from '@/views/Inventario/Inventario';
import { ListaPrecios } from '@/views/ListaPrecios';
import { AddListaPrecio } from '@/views/ListaPrecios/componentes/AddListaPrecio';
import { Insumos } from '@/views/Insumos';
import { LoginCliente } from '@/views/LoginCliente';
import { MyOrders } from '@/views/ClienteEcommerce/components/MyOrders';
import { MyPurchases } from '@/views/ClienteEcommerce/components/myPurchases';
import { PedidosProductos } from '@/views/PedidosProductos';
import { ClienteEcommerce } from '@/views/ClienteEcommerce';
import { ProductsEcommerce } from '@/views/ClienteEcommerce/components/Products';
import { HomeEcommerce } from '@/views/ClienteEcommerce/components/HomeEcommerce';
import { Cocinas } from '@/views/Cocinas';
import { CompaniesEcommerce } from '@/views/ClienteEcommerce/components/CompaniesEcommerce';
import { ReserveTable } from '@/views/ClienteEcommerce/components/ReserveTable';
import { Configuraciones } from '@/views/Configuraciones';
import { HomeV2 } from '@/views/Home/HomeV2';
import { Principal } from '@/views/Principal/Principal';
import { CajasMantenedor } from '@/views/Caja/CajasMantenedor';
import { CierreDiario } from '@/views/Caja/CierreDiario/CierreDiario';
import { MisProductos } from '@/views/Productos/MisProductos';
import { CodigoBarras } from '@/views/Productos/CodigoBarras';
import { ReporteVenta } from '@/views/Reporte/ReporteVenta';
import { ReporteVentasVentas } from '@/views/Reporte/ReporteVentasVentas';
import { Receta } from '@/views/Receta/Receta';
import { TarjetaExistencia } from '@/views/TarjetaExistencia/TarjetaExistencia';
import { VentasDiarias } from '@/views/Ventas/VentasDiarias';


const AllRoutes: React.FC = () => {

	const [dataUser] = useLocalStorage(KEY_USER_DATA, undefined);
	const [dataEmpresa] = useLocalStorage(KEY_EMPRESA, undefined);

   const moduleHome = [
      <PrivateRoute key={5} exact path={Routes.ROUTE_HOME} component={HomeV2} />,
      <PrivateRoute key={5} exact path={Routes.CIERRE_DIARIO} component={CierreDiario} />,
      <PrivateRoute key={5} exact path={Routes.ROUTE_PRODUCTOS_LIST} component={MisProductos} />,
      <PrivateRoute key={5} exact path={Routes.ROUTE_CODIGO_BARRA} component={CodigoBarras} />,
      <PrivateRoute key={5} exact path={Routes.ROUTE_REPORTE_VENTA} component={ReporteVenta} />,
      <PrivateRoute key={5} exact path={Routes.ROUTE_REPORTE_VENTA_VENTAS} component={ReporteVentasVentas} />,
      <PrivateRoute key={5} exact path={Routes.ROUTE_RECETA} component={Receta} />,
   ];

   const moduleReporte = [
      <PrivateRoute key={5} exact path={Routes.ROUTE_REPORTE} component={Reporte} />,
   ];
   const moduleReporteTable = [
      <PrivateRoute key={5} exact path={Routes.ROUTE_REPORTE_TABLE} component={ReporteTable} />,
   ];
   const moduleCaja = [
      <PrivateRoute key={5} exact path={Routes.ROUTE_CAJA} component={Caja} />,
   ];
   const moduleCajaMantenedor = [
      <PrivateRoute key={5} exact path={Routes.ROUTE_MANTENEDOR_CAJAS} component={CajasMantenedor} />,
   ];
   const moduleTarjetaExistencia = [
      <PrivateRoute key={5} exact path={Routes.ROUTE_TARJETA_EXISTENCIA_DIARIA} component={TarjetaExistencia} />,
   ];

   const moduleVentasDiaria = [
      <PrivateRoute key={5} exact path={Routes.ROUTE_VENTAS} component={VentasDiarias} />,
   ];

   const modulePedidos = [
      <PrivateRoute key={5} exact path={Routes.ROUTE_PEDIDOS} component={Pedidos} />,
   ];

   const modulePedidosProductos = [
      <PrivateRoute key={5} exact path={Routes.ROUTE_PEDIDOS_PRODUCTOS} component={ PedidosProductos} />,
   ]

   const moduleInventario = [
      <PrivateRoute key={5} exact path={Routes.ROUTE_INVENTARIO} component={Inventario} />,
   ];
   const moduleEmpleados = [
      <PrivateRoute key={5} exact path={Routes.ROUTE_EMPLEADOS} component={Empleados} />,
   ];
   const moduleSalas = [
      <PrivateRoute key={5} exact path={Routes.ROUTE_SALAS} component={Salas} />,
   ];
   const moduleEditor = [
      <PrivateRoute key={5} exact path={Routes.ROUTE_EDITOR} component={Editor} />,
   ];

   const moduleCliente = [
      <PrivateRoute key={5} exact path={Routes.ROUTE_CLIENTES} component={ Clientes} />,
   ]

   const moduleBeneficios = [
      <PrivateRoute key={5} exact path={Routes.ROUTE_BENEFICIOS} component={ Beneficios} />,
   ]

   const moduleUsuarios = [
      <PrivateRoute key={5} exact path={Routes.ROUTE_USUARIOS} component={ Users} />,
   ]

   const moduleEmpresas = [
      <PrivateRoute key={5} exact path={Routes.ROUTE_EMPRESAS} component={ Empresas} />,
   ]

   const moduleProveedores = [
      <PrivateRoute key={5} exact path={Routes.ROUTE_PROVEEDORES} component={ Proveedor} />,
   ]

   const moduleCompras = [
      <PrivateRoute key={5} exact path={Routes.ROUTE_COMPRAS} component={ Compras} />,
   ]

   const moduleNuevaCompra = [
      <PrivateRoute key={5} exact path={Routes.ROUTE_NUEVA_COMPRA} component={ NuevaCompra} />,
   ]

   const moduleCuadraturas = [
      <PrivateRoute key={5} exact path={Routes.ROUTE_CUADRATURAS} component={ PageCuadratura} />,
   ]

   const moduleSucursales = [
      <PrivateRoute key={5} exact path={Routes.ROUTE_SUCURSALES} component={ Sucursales} />,
   ]

   const moduleListaPrecios = [
      <PrivateRoute key={5} exact path={Routes.ROUTE_LISTA_PRECIO} component={ ListaPrecios} />,
   ]

   const moduleListaPreciosCrear = [
      <PrivateRoute key={5} exact path={Routes.ROUTE_CREAR_LISTA_PRECIO} component={ AddListaPrecio} />,
   ]

   const moduleInsumos = [
      <PrivateRoute key={5} exact path={Routes.ROUTE_PRODUCTOS_INSUMO} component={ Insumos} />,
   ]

   const moduleCocinas = [
      <PrivateRoute key={5} exact path={Routes.ROUTE_COCINAS} component={ Cocinas} />,
   ]

   //configuraciones
   const moduleConfiguraciones = [
      <PrivateRoute key={5} exact path={Routes.ROUTE_COMPANY_CONFIG} component={ Configuraciones} />,
   ]

   //ecommerce:public view
   const moduleClienteEcommerce = [
      <PrivateRoute key={5} exact path={Routes.ROUTE_SHOP_ECOMMERCE} component={ ClienteEcommerce } />,
   ];

   const moduleClienteEcommerceCompanies = [
      <PrivateRoute key={5} exact path={Routes.ROUTE_SHOP_ECOMMERCE_COMPANIES} component={ CompaniesEcommerce } />,
   ];

   const moduleClienteEcommerceReserve = [
      <PrivateRoute key={5} exact path={Routes.ROUTE_SHOP_RESERVE_TABLE} component={ ReserveTable } />,
   ];

   const moduleHomeEcommerce = [
      <PrivateRoute key={5} exact path={Routes.ROUTE_CLIENTE_ECOMMERCE} component={ HomeEcommerce } />,
   ];

   const modulePedidosCliente = [
      <PrivateRoute key={5} exact path={Routes.ROUTE_MIS_PEDIDOS_CLIENTE} component={ MyOrders} />,
   ]

   const moduleComprasCliente = [
      <PrivateRoute key={5} exact path={Routes.ROUTE_MIS_COMPRAS_CLIENTE} component={ MyPurchases} />,
   ]

   const moduleProductosEcommerce = [
      <PrivateRoute key={5} exact path={Routes.ROUTE_PRODUCTOS_ECOMMERCE} component={ ProductsEcommerce} />,
   ];

	const routes = useMemo(() => {
      let role: string = 'prueba';
      if (!!dataUser) {
         if (!!dataUser?.user) {
            role = dataUser?.user?.rol;
         }
      }

      switch (role) {
         case ROLE_SUPER_ADMIN:
            return (
               <Router>
                  <Switch>
                     {moduleHome}
                     {moduleReporte}
                     {moduleReporteTable}
                     {moduleCaja}
                     {modulePedidos}
                     {/* {dataEmpresa && dataEmpresa?.id_tipo_empresa && (dataEmpresa?.id_tipo_empresa !== 1 && dataEmpresa?.id_tipo_empresa !== 4 ) && modulePedidos} */}
                     {moduleEmpleados}
                     {dataEmpresa && dataEmpresa?.id_tipo_empresa && (dataEmpresa?.id_tipo_empresa !== 1 && dataEmpresa?.id_tipo_empresa !== 4 ) && moduleSalas}
                     {moduleEditor}
                     {moduleCliente}
                     {moduleBeneficios}
                     {moduleUsuarios}
                     {moduleEmpresas}
                     {moduleCuadraturas}
                     {/* no deberia estar en superadmin */}
                     {moduleSucursales}
                     {moduleListaPrecios}
                     {moduleInsumos}
                     {modulePedidosProductos}
                     {moduleClienteEcommerce}
                     {moduleClienteEcommerceCompanies}
                     {moduleProductosEcommerce}
                     {moduleClienteEcommerceReserve}
                     <AuthRoute exact path={Routes.ROUTE_LOGIN} component={Login} />
                     <AuthRoute exact path={Routes.ROUTE_SHOP_ECOMMERCE} component={ClienteEcommerce} />
                     {<Route path='*' exact={true} component={() => {
                        return <Redirect to={Routes.ROUTE_LOGIN} />
                     }} />}
                  </Switch>
               </Router>
            )
         case ROLE_ADMIN:
            return (
               <Router>
                  <Switch>
                     {moduleHome}
                     {moduleReporte}
                     {moduleReporteTable}
                     {moduleCaja}
                     {moduleCajaMantenedor}
                     {moduleTarjetaExistencia}
                     {moduleVentasDiaria}
                     {/* {dataEmpresa && dataEmpresa?.id_tipo_empresa && (dataEmpresa?.id_tipo_empresa !== 1 && dataEmpresa?.id_tipo_empresa !== 4 ) && modulePedidos} */}
                     {modulePedidos}
                     {moduleInventario}
                     {moduleEmpleados}
                     {dataEmpresa && dataEmpresa?.id_tipo_empresa && (dataEmpresa?.id_tipo_empresa !== 1 && dataEmpresa?.id_tipo_empresa !== 4 ) && moduleSalas}
                     {moduleEditor}
                     {moduleCliente}
                     {moduleBeneficios}
                     {moduleUsuarios}
                     {moduleProveedores}
                     {moduleCompras}
                     {moduleNuevaCompra}
                     {moduleCuadraturas}
                     {moduleSucursales}
                     {moduleListaPrecios}
                     {moduleListaPreciosCrear}
                     {moduleInsumos}
                     {moduleCocinas}
                     {modulePedidosProductos}
                     {moduleClienteEcommerce}
                     {moduleClienteEcommerceCompanies}
                     {moduleProductosEcommerce}
                     {moduleClienteEcommerceReserve}
                     {moduleConfiguraciones}
                     <AuthRoute exact path={Routes.ROUTE_LOGIN} component={Login} />
                     <AuthRoute exact path={Routes.ROUTE_SHOP_ECOMMERCE} component={ClienteEcommerce} />
                     {<Route path='*' exact={true} component={() => {
                        return <Redirect to={Routes.ROUTE_LOGIN} />
                     }} />}
                  </Switch>
               </Router>
            )
         case ROLE_CAJERO:
            return (
               <Router>
                  <Switch>
                     {moduleHome}
                     {moduleCaja}
                     {moduleTarjetaExistencia}
                     {modulePedidos}
                     {moduleEditor}
                     {/* {dataEmpresa && dataEmpresa?.id_tipo_empresa && (dataEmpresa?.id_tipo_empresa !== 1 && dataEmpresa?.id_tipo_empresa !== 4 ) && modulePedidos} */}
                     {dataEmpresa && dataEmpresa?.id_tipo_empresa && (dataEmpresa?.id_tipo_empresa !== 1 && dataEmpresa?.id_tipo_empresa !== 4 ) && moduleSalas}
                     {moduleCliente}
                     {moduleCuadraturas}
                     {moduleCompras}
                     {moduleNuevaCompra}
                     {moduleProveedores}
                     {modulePedidosProductos}
                     <AuthRoute exact path={Routes.ROUTE_LOGIN} component={Login} />
                     <AuthRoute exact path={Routes.ROUTE_SCANNER_CLIENT} component={Login} />
                     <AuthRoute exact path={Routes.ROUTE_SHOP_ECOMMERCE} component={ClienteEcommerce} />
                     {<Route path='*' exact={true} component={() => {
                        return <Redirect to={Routes.ROUTE_LOGIN} />
                     }} />}
                  </Switch>
               </Router>
            )
         case ROLE_VENDEDOR:
            return (
               <Router>
                  <Switch>
                     {moduleHome}
                     {moduleCaja}
                     {moduleCuadraturas}
                     {modulePedidos}
                     {/* { dataEmpresa && dataEmpresa?.id_tipo_empresa && (dataEmpresa?.id_tipo_empresa !== 1 && dataEmpresa?.id_tipo_empresa !== 4 ) && modulePedidos} */}
                     {moduleBeneficios}
                     {moduleInventario}
                     { dataEmpresa && dataEmpresa?.id_tipo_empresa && (dataEmpresa?.id_tipo_empresa !== 1 && dataEmpresa?.id_tipo_empresa !== 4 ) && moduleSalas}
                     {moduleCliente}
                     {moduleProveedores}
                     {modulePedidosProductos}
                     <AuthRoute exact path={Routes.ROUTE_LOGIN} component={Login} />
                     <AuthRoute exact path={Routes.ROUTE_SHOP_ECOMMERCE} component={ClienteEcommerce} />
                     {<Route path='*' exact={true} component={() => {
                        return <Redirect to={Routes.ROUTE_LOGIN} />
                     }} />}
                  </Switch>
               </Router>
            )
         case ROLE_USUARIO_CLIENTE:
            return (
               <Router>
                  <Switch>
                     {moduleHomeEcommerce}
                     {moduleClienteEcommerce}
                     {moduleClienteEcommerceCompanies}
                     {modulePedidosCliente}
                     {moduleComprasCliente}
                     {moduleProductosEcommerce}
                     {moduleClienteEcommerceReserve}
                     <AuthRoute exact path={Routes.ROUTE_LOGIN} component={Login} />
                     <AuthRoute exact path={Routes.ROUTE_LOGIN_CLIENT} component={LoginCliente} />
                     <AuthRoute exact path={Routes.ROUTE_SCANNER_CLIENT} component={QRView} />
                     <AuthRoute exact path={Routes.ROUTE_SHOP_ECOMMERCE} component={ClienteEcommerce} />
                     {<Route path='*' exact={true} component={() => {
                        return <Redirect to={Routes.ROUTE_LOGIN} />
                     }} />}
                  </Switch>
               </Router>
            )
         case ROLE_USUARIO_ECOMMERCE:
            return (
               <Router>
                  <Switch>
                     {moduleClienteEcommerce}
                     {moduleHomeEcommerce}
                     {moduleProductosEcommerce}
                     {moduleClienteEcommerceCompanies}
                     {moduleComprasCliente}
                     {moduleClienteEcommerceReserve}
                     <AuthRoute exact path={Routes.ROUTE_LOGIN} component={Login} />
                     <AuthRoute exact path={Routes.ROUTE_LOGIN_CLIENT} component={LoginCliente} />
                     <AuthRoute exact path={Routes.ROUTE_SHOP_ECOMMERCE} component={ClienteEcommerce} />
                     {<Route path='*' exact={true} component={() => {
                        return <Redirect to={Routes.ROUTE_SHOP_ECOMMERCE} />
                     }} />}
                  </Switch>
               </Router>
            )
         default:
            return (
               <Router>
                  <Switch>
                     <AuthRoute exact path={Routes.ROUTE_LOGIN} component={Login} />
                     <AuthRoute exact path={Routes.ROUTE_LOGIN_CLIENT} component={LoginCliente} />
                     <AuthRoute exact path={Routes.ROUTE_SCANNER_CLIENT} component={QRView} />
                     <AuthRoute exact path={Routes.ROUTE_SHOP_ECOMMERCE} component={ClienteEcommerce} />
                     <AuthRoute exact path={Routes.ROUTE_SHOP_ECOMMERCE_COMPANIES} component={CompaniesEcommerce} />
                     {<Route path='*' exact={true} component={() => {
                        return <Redirect to={Routes.ROUTE_LOGIN} />
                     }} />}
                  </Switch>
               </Router>
            )
      }
	},// eslint-disable-next-line
	[JSON.stringify(dataUser)]);

	return routes;
}

export default AllRoutes;
