import { API_URL_NUPY } from "@/toolbox/defaults/app"
import axios from "axios"

export const EmpresasRepository = {
    getPaises: async(): Promise<any> =>{
        const res = await axios.get(`${API_URL_NUPY}/pais`);
        return res.data;
    },
    getPais: async(id: number): Promise<any> =>{
        const res = await axios.get(`${API_URL_NUPY}/pais/${id}`);
        return res.data;
    },
    getTipoEmpresa: async(): Promise<any> =>{
        const res = await axios.get(`${API_URL_NUPY}/tipoEmpresa/list`);
        return res.data;
    },
    getEquivalencia: async(): Promise<number> => {
        const res = await axios.get(`${API_URL_NUPY}/empresas/equivalencia`);
        return res.data;
    },
    getEmpresas: async(): Promise<any> =>{
        const res = await axios.get(`${API_URL_NUPY}/empresas/list`);
        return res.data;
    },
    getEmpresasAvailable: async(): Promise<any> =>{
        const res = await axios.get(`${API_URL_NUPY}/empresas/companiesAvailable`);
        return res.data;
    },
    getEmpresasByService: async(idService:number): Promise<any> =>{
        const res = await axios.get(`${API_URL_NUPY}/empresas/list-byService/${idService}`);
        return res.data;
    },
    editState: async(idEmpresa:number, state:string): Promise<any> => {
        const res = await axios.patch(`${API_URL_NUPY}/empresas/editState/${idEmpresa}/${state}`);
        return res.data;
    },
    register: async(data:{}): Promise<any> =>{
        const res = await axios.post(`${API_URL_NUPY}/empresas/register`, data);
        return res.data;
    },
    update: async(data:{}, idEmpresa:number): Promise<any> => {
        const res = await axios.post(`${API_URL_NUPY}/empresas/update/${idEmpresa}`, data);
        return res.data;
    },
    listEmpresaByName: async(): Promise<any> =>{
        const res = await axios.get(`${API_URL_NUPY}/empresas/listByName`);
        return res.data;
    },
    editEquivalencia: async( data:{}, idEmpresa:number): Promise<any> => {
        const res = await axios.post(`${API_URL_NUPY}/empresas/editEquivalencia/${idEmpresa}`, data);
        return res.data;
    },
    editTipoAtencionMesero: async( data:{}, idEmpresa:number): Promise<any> => {
        const res = await axios.post(`${API_URL_NUPY}/empresas/editTipoAtencionMesero/${idEmpresa}`, data);
        return res.data;
    },
    editStockRestricction: async( data:{}, idEmpresa:number): Promise<any> => {
        const res = await axios.post(`${API_URL_NUPY}/empresas/editStockRestricction/${idEmpresa}`, data);
        return res.data;
    },
    requestServices: async(data:{}, idEmpresa:number): Promise<any> => {
        const res = await axios.post(`${API_URL_NUPY}/empresas/requestServices/${idEmpresa}`, data);
        return res.data;
    },
    findCompany: async(idEmpresa:number): Promise<any> => {
        const res = await axios.get(`${API_URL_NUPY}/empresas/findCompany/${idEmpresa}`);
        return res.data;
    },

}
