import { Loading } from "@/components/common/Loading";
import { Protected } from "@/components/layout/Protected";
import { ventaService } from "@/service/services/Venta.service";
import { Vrpano } from "@mui/icons-material";
import { Autocomplete, Card, CardContent, CircularProgress, Grid, TextField, Typography } from "@mui/material";
import React, { FC, useEffect, useState } from "react";
import Chart from "react-google-charts";
import { ReporteVentasVentas } from "./ReporteVentasVentas";
import { ROUTE_REPORTE_VENTA_VENTAS } from "@/toolbox/constants/route-map";
import { useHistory } from "react-router-dom";
import { moneyFormatInt } from "@/toolbox/helpers/money.helper";
import { NotData } from "@/components/common/NotData/NotData";

const optionsFilter = [
    { id: 1, code: 'day', name: 'Por día' },
    { id: 2, code: 'month', name: 'Por mes' },
    { id: 3, code: 'dates', name: 'Por fechas' }
];

const mesActual = new Date().toISOString().slice(0, 7)
const fechaActual = new Date().toISOString().slice(0, 10);

export const ReporteVenta: FC = () => {
    const history = useHistory();

    const [selectOption, setSelectOption] = useState<any>(optionsFilter[1]);

    const [loading, setLoading] = useState<boolean>(false)
    const [date1, setDate1] = useState<string>('')
    const [date2, setDate2] = useState<string>('')
    const [resumen, setResumen] = useState<any>('')

    const [dashFamilyCount, setDashFamilyCount] = useState<any[]>([])
    const [dashFamilyAmount, setDashFamilyAmount] = useState<any[]>([])
    const [dashFormaPagoCount, setDashFormaPagoCount] = useState<any[]>([])
    const [dashFormaPagoAmount, setDashFormaPagoAmount] = useState<any[]>([])
    const [dashCajeroCount, setDashCajeroCount] = useState<any[]>([])
    const [dashCajeroAmount, setDashCajeroAmount] = useState<any[]>([])
    const [dashWeekCount, setDashWeekCount] = useState<any[]>([])
    const [dashWeekAmount, setDashWeekAmount] = useState<any[]>([])
    const [dashProductCount, setDashProductCount] = useState<any[]>([])
    const [dashProductAmount, setDashProductAmount] = useState<any[]>([])
    const [ventas, setVentas] = useState<any>([])


    useEffect(() => {
        if(selectOption?.id === 3 && !!date1 &&  !!date2){
            getDataAPI()
        }
        if(selectOption?.id !== 3 && !!date1){
            getDataAPI()
        }
    }, [date1, date2])

    useEffect(() => {
        if(!!selectOption){
            if (selectOption?.id === 2) {
                setDate1(mesActual)
            } else {
                setDate1(fechaActual)
            }
        }
    }, [selectOption])

    const getDataAPI = async () => {
        setLoading(true)
        if (date1 === '') return
        let payload: any = {
            date1: date1
        }
        if (date2 !== '') payload = { ...payload, date2: date2 }
        const response = await ventaService.getReporteVentas(payload)

        setResumen(response.data?.resumen || [])
        setVentas(response.data?.ventas || [])

        /*DASH AGRUPADO POR FAMILIAS */
        const headersFC = [
            ['Familia', 'N° Ventas'],
            ...response.data?.dashes?.familias?.map(item => [item.nombre, item.cantidad])
        ]
        const headersFA = [
            ['Familia', 'Monto de ventas'],
            ...response.data?.dashes?.familias?.map(item => [item.nombre, parseInt(item.monto)])
        ]

        /*DASH AGRUPADO POR FORMAS DE PAGO */
        const headersFPC = [
            ['Forma de pago', 'N° Ventas'],
            ...response.data?.dashes?.formas_pago?.map(item => [item.nombre, item.cantidad])
        ]
        const headersFPA = [
            ['Forma de pago', 'Monto de ventas'],
            ...response.data?.dashes?.formas_pago?.map(item => [item.nombre, parseInt(item.monto)])
        ]

        /*DASH AGRUPADO POR CAJERO */
        const headersCC = [
            ['Cajero', 'N° Ventas'],
            ...response.data?.dashes?.cajeros?.map(item => [item.nombre, item.cantidad])
        ]
        const headersCA = [
            ['Cajero', 'Monto de ventas'],
            ...response.data?.dashes?.cajeros?.map(item => [item.nombre, parseInt(item.monto)])
        ]

        /*DASH DE ENTRE DIAS */
        if (response.data?.dashes?.days.length > 0) {
            const headersWC = [
                ['Día', 'N° Ventas'],
                ...response.data?.dashes?.days?.map(item => [item.nombre, item.cantidad])
            ]
            const headersWA = [
                ['Día', 'Monto de ventas'],
                ...response.data?.dashes?.days?.map(item => [item.nombre, parseInt(item.monto)])
            ]
            console.log('headersWA', headersWA)
            setDashWeekCount(headersWC || [])
            setDashWeekAmount(headersWA || [])
        }

        /* DASH DE PRODUCTOS*/
        const headersPC = [
            ['Producto', 'N° Ventas'],
            ...response.data?.dashes?.products?.map(item => [item.nombre, item.cantidad])
        ]
        const headersPA = [
            ['Producto', 'Monto de ventas'],
            ...response.data?.dashes?.products?.map(item => [item.nombre, parseInt(item.monto)])
        ]

        setDashFamilyCount(headersFC || [])
        setDashFamilyAmount(headersFA || [])
        setDashFormaPagoCount(headersFPC || [])
        setDashFormaPagoAmount(headersFPA || [])
        setDashCajeroCount(headersCC || [])
        setDashCajeroAmount(headersCA || [])
        setDashProductCount(headersPC || [])
        setDashProductAmount(headersPA || [])

        setLoading(false)
    }

    const redirectViewVentas = (grupo: string) => {
        let grupoVentas: any = []
        switch (grupo) {
            case 'familias':
                grupoVentas = ventas.familias
                break;

            case 'formas_pago':
                grupoVentas = ventas.formas_pago
                break;

            case 'cajeros':
                grupoVentas = ventas.cajeros
                break;

            case 'days':
                grupoVentas = ventas.days
                break;

            case 'products':
                grupoVentas = ventas.products
                break;

            default:
                break;
        }

        const jsonString = JSON.stringify(grupoVentas)
        history.push({
            pathname: ROUTE_REPORTE_VENTA_VENTAS,
            state: { data: jsonString }
        });
    }

    return (
        <Protected>
            {loading && <Loading />}
            <Grid container spacing={3}>
                <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'center' }}>
                    <Typography sx={{
                        fontWeight: 'bold',
                        fontSize: '30px',
                        color: 'black'
                    }}>REPORTE DE VENTAS</Typography>
                </Grid>
                <Grid item container xs={12}>
                    <Grid item container xs={12} md={6} spacing={2}>
                        <Grid item xs={4}>
                            <Typography sx={{
                                fontSize: '20px',
                                color: 'black'
                            }}>Fechas:</Typography>
                        </Grid>
                        <Grid item xs={6}>
                            <Autocomplete
                                key='cbx-comuna'
                                id="cbx-comuna"
                                size="small"
                                value={selectOption || null}
                                options={optionsFilter || []}
                                getOptionLabel={(option) => option?.name || ''}
                                isOptionEqualToValue={(option, value) => option.id === value?.id}
                                renderInput={(params) =>
                                    <TextField {...params}
                                        label=""
                                        size="small"
                                        InputProps={{
                                            ...params.InputProps,
                                            endAdornment: (
                                                <React.Fragment>
                                                    {loading ? <CircularProgress color="inherit" size={20} /> : null}
                                                    {params.InputProps.endAdornment}
                                                </React.Fragment>
                                            ),
                                        }}
                                    />}
                                onChange={(e, newValue) => {
                                    setSelectOption(newValue)
                                }}
                                fullWidth
                                noOptionsText={!selectOption ? "Seleccione una opcion de filtro" : "Sin opciones"}
                            />
                        </Grid>
                    </Grid>
                    <Grid item container xs={12} md={6}
                        sx={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'space-between',
                        }}
                        spacing={3}
                    >
                        <Grid item>
                            {
                                selectOption?.id !== 2 &&
                                <TextField
                                    id="date1"
                                    type="date"
                                    size="small"
                                    value={date1}
                                    onChange={(e) => setDate1(e.target.value)}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                />
                            }
                            {
                                selectOption?.id === 2 &&
                                <TextField
                                    id="month"
                                    label="Seleccione un mes"
                                    type="month"
                                    size="small"
                                    value={date1}
                                    onChange={(e) => setDate1(e.target.value)}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    InputProps={{
                                        inputProps: {
                                            max: "9999-12",
                                            min: "1000-01"
                                        }
                                    }}
                                />
                            }

                        </Grid>
                        {
                            selectOption?.id === 3 && (<>
                                <Grid item>
                                    <Typography>hasta</Typography>
                                </Grid>
                                <Grid item>
                                    <TextField
                                        id="date2"
                                        type="date"
                                        size="small"
                                        value={date2}
                                        onChange={(e) => setDate2(e.target.value)}
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                    />
                                </Grid>
                            </>)
                        }
                    </Grid>
                </Grid>
                <Grid item container xs={12} spacing={2} sx={{ display: 'flex', justifyContent: 'center' }}>
                    <Grid item>
                        <Card className="card" style={{ backgroundColor: "#F0F8FF" }}>
                            <CardContent>
                                <Typography sx={{ fontSize: 16, textAlign: 'center' }} color="text.secondary" gutterBottom>
                                    {resumen.total_ventas || 0}
                                </Typography>
                                <Typography color="text.secondary" sx={{ mb: 1.5, textAlign: 'center' }}>
                                    Cantidad total de ventas
                                </Typography>
                            </CardContent>
                        </Card>
                    </Grid>
                    <Grid item>
                        <Card className="card" style={{ backgroundColor: "#F0F8FF" }}>
                            <CardContent>
                                <Typography sx={{ fontSize: 16, textAlign: 'center' }} color="text.secondary" gutterBottom>
                                    {moneyFormatInt(resumen.monto_total_ventas || 0)}
                                </Typography>
                                <Typography color="text.secondary" sx={{ mb: 1.5, textAlign: 'center' }}>
                                    Ganancia total
                                </Typography>
                            </CardContent>
                        </Card>
                    </Grid>
                </Grid>

                { resumen?.monto_total_ventas  > 0 ? 
(
    <>
    
    <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'center' }}>
                    <Typography sx={{
                        fontWeight: 'bold',
                        fontSize: '20px',
                        color: 'black'
                    }}>TOP PRODUCTOS MÁS VENDIDOS</Typography>
                </Grid>
                <Grid item xs={6} onClick={() => redirectViewVentas('products')}>
                    <Chart
                        width={'100%'}
                        height={'500px'}
                        chartType="BarChart"
                        loader={<div>Loading Chart...</div>}
                        data={dashProductCount}
                        options={{
                            chart: {
                                title: 'Company Performance',
                                subtitle: 'Sales vs Expenses',
                            },
                        }}
                        rootProps={{ 'data-testid': '1' }}
                        style={{
                            border: '10px solid #ccc',
                            borderRadius: '10px'
                        }}
                    />
                </Grid>
                <Grid item xs={6} onClick={() => redirectViewVentas('products')}>
                    <Chart
                        width={'100%'}
                        height={'500px'}
                        chartType="BarChart"
                        loader={<div>Loading Chart...</div>}
                        data={dashProductAmount}
                        options={{
                            chart: {
                                title: 'Company Performance',
                                subtitle: 'Sales vs Expenses',
                            },
                            colors: ['orange']
                        }}
                        rootProps={{ 'data-testid': '1' }}
                        style={{
                            border: '10px solid #ccc',
                            borderRadius: '10px'
                        }}
                    />
                </Grid>

                <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'center' }}>
                    <Typography sx={{
                        fontWeight: 'bold',
                        fontSize: '20px',
                        color: 'black'
                    }}>POR FAMILIAS</Typography>
                </Grid>
                <Grid item xs={6} onClick={() => redirectViewVentas('familias')}>
                    <Chart
                        width={'100%'}
                        height={'500px'}
                        chartType="BarChart"
                        loader={<div>Loading Chart...</div>}
                        data={dashFamilyCount}
                        options={{
                            chart: {
                                title: 'Company Performance',
                                subtitle: 'Sales vs Expenses',
                            },
                        }}
                        rootProps={{ 'data-testid': '1' }}
                        style={{
                            border: '10px solid #ccc',
                            borderRadius: '10px'
                        }}
                    />
                </Grid>
                <Grid item xs={6} onClick={() => redirectViewVentas('familias')}>
                    <Chart
                        width={'100%'}
                        height={'500px'}
                        chartType="BarChart"
                        loader={<div>Loading Chart...</div>}
                        data={dashFamilyAmount}
                        options={{
                            chart: {
                                title: 'Company Performance',
                                subtitle: 'Sales vs Expenses',
                            },
                            colors: ['orange']
                        }}
                        rootProps={{ 'data-testid': '1' }}
                        style={{
                            border: '10px solid #ccc',
                            borderRadius: '10px'
                        }}
                    />
                </Grid>

                <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'center' }}>
                    <Typography sx={{
                        fontWeight: 'bold',
                        fontSize: '20px',
                        color: 'black'
                    }}>POR FORMAS DE PAGO</Typography>
                </Grid>
                <Grid item xs={6} onClick={() => redirectViewVentas('formas_pago')}>
                    <Chart
                        width={'100%'}
                        height={'500px'}
                        chartType="BarChart"
                        loader={<div>Loading Chart...</div>}
                        data={dashFormaPagoCount}
                        options={{
                            chart: {
                                title: 'Company Performance',
                                subtitle: 'Sales vs Expenses',
                            },
                        }}
                        rootProps={{ 'data-testid': '1' }}
                        style={{
                            border: '10px solid #ccc',
                            borderRadius: '10px'
                        }}
                    />
                </Grid>
                <Grid item xs={6} onClick={() => redirectViewVentas('formas_pago')}>
                    <Chart
                        width={'100%'}
                        height={'500px'}
                        chartType="BarChart"
                        loader={<div>Loading Chart...</div>}
                        data={dashFormaPagoAmount}
                        options={{
                            chart: {
                                title: 'Company Performance',
                                subtitle: 'Sales vs Expenses',
                            },
                            colors: ['orange']
                        }}
                        rootProps={{ 'data-testid': '1' }}
                        style={{
                            border: '10px solid #ccc',
                            borderRadius: '10px'
                        }}
                    />
                </Grid>

                <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'center' }}>
                    <Typography sx={{
                        fontWeight: 'bold',
                        fontSize: '20px',
                        color: 'black'
                    }}>POR CAJEROS</Typography>
                </Grid>
                <Grid item xs={6} onClick={() => redirectViewVentas('cajeros')}>
                    <Chart
                        width={'100%'}
                        height={'500px'}
                        chartType="BarChart"
                        loader={<div>Loading Chart...</div>}
                        data={dashCajeroCount}
                        options={{
                            chart: {
                                title: 'Company Performance',
                                subtitle: 'Sales vs Expenses',
                            },
                        }}
                        rootProps={{ 'data-testid': '1' }}
                        style={{
                            border: '10px solid #ccc',
                            borderRadius: '10px'
                        }}
                    />
                </Grid>
                <Grid item xs={6} onClick={() => redirectViewVentas('cajeros')}>
                    <Chart
                        width={'100%'}
                        height={'500px'}
                        chartType="BarChart"
                        loader={<div>Loading Chart...</div>}
                        data={dashCajeroAmount}
                        options={{
                            chart: {
                                title: 'Company Performance',
                                subtitle: 'Sales vs Expenses',
                            },
                            colors: ['orange']
                        }}
                        rootProps={{ 'data-testid': '1' }}
                        style={{
                            border: '10px solid #ccc',
                            borderRadius: '10px'
                        }}
                    />
                </Grid>
                {
                    (dashWeekAmount.length > 0 || dashWeekCount.length > 0) && (
                        <>
                            <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'center' }}>
                                <Typography sx={{
                                    fontWeight: 'bold',
                                    fontSize: '20px',
                                    color: 'black'
                                }}>POR DÍAS</Typography>
                            </Grid>
                            <Grid item xs={6} onClick={() => redirectViewVentas('days')}>
                                <Chart
                                    width={'100%'}
                                    height={'500px'}
                                    chartType="BarChart"
                                    loader={<div>Loading Chart...</div>}
                                    data={dashWeekCount}
                                    options={{
                                        chart: {
                                            title: 'Company Performance',
                                            subtitle: 'Sales vs Expenses',
                                        },
                                    }}
                                    rootProps={{ 'data-testid': '1' }}
                                    style={{
                                        border: '10px solid #ccc',
                                        borderRadius: '10px'
                                    }}
                                />
                            </Grid>
                            <Grid item xs={6} onClick={() => redirectViewVentas('days')}>
                                <Chart
                                    width={'100%'}
                                    height={'500px'}
                                    chartType="BarChart"
                                    loader={<div>Loading Chart...</div>}
                                    data={dashWeekAmount}
                                    options={{
                                        chart: {
                                            title: 'Company Performance',
                                            subtitle: 'Sales vs Expenses',
                                        },
                                        colors: ['orange']
                                    }}
                                    rootProps={{ 'data-testid': '1' }}
                                    style={{
                                        border: '10px solid #ccc',
                                        borderRadius: '10px'
                                    }}
                                />
                            </Grid>
                        </>
                    )
                }
    </> 
) : <NotData description="No hay data para mostrar"/>

                }

            </Grid>
            {
                ventas.length > 0 && <ReporteVentasVentas ventas={ventas} />
            }
        </Protected>
    );
};