import { moneyFormatInt } from "@/toolbox/helpers/money.helper";
import { Box, Checkbox, createTheme, Grid, Paper, Tab, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, ThemeProvider } from "@mui/material";
import { esES } from "@mui/material/locale";
import { useState } from "react";

const theme = createTheme( {
        palette: {  primary: { main: '#1976D2' }, },
    },
    esES,
);


export const TiposPagosFamilia = (props) => {
    const {formasPago, checkboxes, setCheckboxes, type} = props;

    function transformData(data) {
        return data.map(item => {
            const detalleKeys = Object.keys(item.detalle);
            const nuevoDetalle = detalleKeys.map(key => {
                if (key === "total") {
                    return null; // Ignorar el campo "total"
                } else {
                    return {
                        nombre: key,
                        total: item.detalle[key].total,
                        detalle: item.detalle[key].detail
                    };
                }
            }).filter(detalle => detalle !== null); // Filtrar para eliminar valores nulos

            return {
                forma_pago: item.forma_pago,
                total: item.detalle.total,
                detalle: nuevoDetalle
            };
        });
    }

    // Uso de la función para transformar los datos
    const newData = transformData(formasPago);
    console.log(newData);


    const calculateHorizontalTotals = () => {
        const totals = {};

        // Iterar sobre cada forma de pago y sus detalles
        newData.forEach(formaPago => {
            formaPago.detalle.forEach(detalle => {
                // Inicializar el total del detalle si es la primera vez que se encuentra
                if (!totals[detalle.nombre]) {
                    totals[detalle.nombre] = {};
                }

                // Sumar al total del detalle
                totals[detalle.nombre][formaPago.forma_pago] = detalle.total + (totals[detalle.nombre][formaPago.forma_pago] || 0);
            });
        });

        return totals;
    };

    // Función para calcular los totales verticales por forma de pago
    const calculateVerticalTotals = () => {
        const totals = {};

        // Iterar sobre cada forma de pago y sus detalles
        newData.forEach(formaPago => {
            formaPago.detalle.forEach(detalle => {
                // Inicializar el total de la forma de pago si es la primera vez que se encuentra
                if (!totals[formaPago.forma_pago]) {
                    totals[formaPago.forma_pago] = 0;
                }

                // Sumar al total de la forma de pago
                totals[formaPago.forma_pago] += detalle.total;
            });
        });

        return totals;
    };

    // Calcular los totales horizontales por detalle
    const horizontalTotals = calculateHorizontalTotals();

    // Calcular los totales verticales por forma de pago
    const verticalTotals = calculateVerticalTotals();

    // Obtener una lista de todas las formas de pago
    const data = newData.map(item => item.forma_pago);


    // Función para manejar el cambio de estado de un checkbox
    const handleCheckboxChange = (index) => {
        const newCheckboxes = [...checkboxes]; // Crear una copia del estado actual de los checkboxes
        newCheckboxes[index] = !newCheckboxes[index]; // Cambiar el estado del checkbox en la posición 'index'
        setCheckboxes(newCheckboxes); // Actualizar el estado de los checkboxes
    };

    return (
        <Grid>
            <Box sx={{Width: '100%', marginTop:'0px'}}>
            <TableContainer component={Paper}>
            <ThemeProvider theme={theme}>
            <TableContainer component={Paper}>
            <Table aria-label="simple table">
                <TableHead>
                    <TableRow>
                        <TableCell><b>FAMILIAS</b></TableCell> {/* Celda vacía para la primera columna */}
                        {data.map((formaPago, index) => (
                            <TableCell key={index} align="right"><b>{formaPago}</b></TableCell>
                        ))}
                        <TableCell align="right"><b>TOTAL</b></TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {Object.keys(horizontalTotals).map((nombre, index) => (
                        
                            (Object.values(horizontalTotals[nombre]) as number[]).reduce((acc, curr) => acc + curr, 0) > 0 &&
                            <TableRow key={index}>
                            <TableCell component="th" scope="row">{nombre}</TableCell>
                            {data.map((formaPago, index) => (
                                <TableCell key={index} align="right">{moneyFormatInt(horizontalTotals[nombre][formaPago] || 0) }</TableCell>
                            ))}
                            <TableCell align="right">{moneyFormatInt((Object.values(horizontalTotals[nombre]) as number[]).reduce((acc, curr) => acc + curr, 0))}</TableCell>
                        </TableRow>
                        
                        
                    ))}
                    <TableRow>
                        <TableCell align="left"><b>TOTAL</b></TableCell>
                        {data.map((formaPago, index) => (
                            <TableCell key={index} align="right"><b>{moneyFormatInt(verticalTotals[formaPago] || 0)}</b></TableCell>
                        ))}
                        <TableCell align="right"><b>{moneyFormatInt((Object.values(verticalTotals) as number[]).reduce((acc, curr) => acc + curr, 0))}</b></TableCell>
                    </TableRow>
                    {
                        type == 'actual' &&
                        <TableRow>
                        <TableCell>CONFIRMAR</TableCell>
                        {data.map((formaPago, index) => (
                            <TableCell key={index} align="right">
                                <Checkbox checked={checkboxes[index]} onChange={() => handleCheckboxChange(index)} /> {/* Agregar el checkbox con estado y función de cambio */}
                            </TableCell>
                        ))}
                        <TableCell align="center">
                            <Checkbox
                                checked={checkboxes.reduce((acc, checkbox) => acc && checkbox, true)}
                                onChange={() => setCheckboxes(Array(formasPago.length).fill(true))}
                            />

                        </TableCell>
                    </TableRow>
                    }

                </TableBody>
            </Table>
        </TableContainer>
            </ThemeProvider>
            </TableContainer>
            </Box>
        </Grid>
    );
}