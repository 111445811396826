import { Protected } from "@/components/layout/Protected";
import { Button, Card, Grid, MenuItem, Paper, Select, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from "@mui/material";
import { Props } from "./Configuraciones.type";
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import { Loading } from "@/components/common/Loading";
import { useEffect, useState } from "react";
import { servicioService } from "@/service/services/Servicio.service";
import { ModalConfigConfirm } from "./Componentes/ModalConfigConfirm";
import { Snackbar, Alert } from '@mui/material';
import { empresasService } from "@/service/services/Empresas.service";
import { KEY_EMPRESA, KEY_USER_DATA } from "@/toolbox/constants/local-storage";
import { readLocalStorage } from "@/toolbox/helpers/local-storage-helper";
import { salas } from "@/service/services/Salas.service";

export const Configuraciones: React.FC<Props> = (props:Props):JSX.Element => {

    const [loading, setLoading] = useState<boolean>(false);
    const [showModal, setShowModal] = useState<boolean>(false);
    const [services, setServices] = useState([]);
    const [selectedServices, setSelectedServices] = useState([]);
    const [informationCompany, setInformationCompany] = useState({});
    const [rooms, setRooms] = useState([]);
    const [tables, setTables] = useState([]);
    const [selectedRoom, setSelectedRoom] = useState<any>('');
   
    const [opcioneSelected, setOpcionSelected] = useState(1);
    const [opcionStockSelected, setOpcionStockSelected] = useState(1);

    const [snackBarConfig, setSnackBarConfig] = useState<any>({
        open: false,
        severity: 'error',
        message: 'Error',
        autoHideDuration: 3000,
    });

    const sessionCompany: any = readLocalStorage(KEY_EMPRESA);
    const idCompany = sessionCompany.id;

    const sessionUsuario = readLocalStorage(KEY_USER_DATA);
    const idSucursal = sessionUsuario.user?.id_sucursal;

    useEffect(() => {
        findCompany();
        getServices();
        if(idCompany && idSucursal) cargarSalas(idCompany, idSucursal);
    }, [])

    const findCompany = async() => {
        setLoading(true);
        await empresasService.findCompany(idCompany).then((data) => {
            if(data.status == 200){
                setInformationCompany(data.company);
                setSelectedServices(data.company.idServices);
                setOpcionSelected(data.company.tipo_atencion_mesero)
                setOpcionStockSelected(data.company.stock_restriction)
            }
        });
        setLoading(false);
    }

    const getServices = async() => {
        setLoading(true);
        await servicioService.getServicios().then((data) => {
            setServices(data.services || []);
        });
        setLoading(false);
    }

    const handleServices = (e) => {
        let idServices = [...selectedServices];
        if(e.target.checked){
            idServices = ([...selectedServices, parseInt(e.target.value)]);
        }else{
            idServices.splice(selectedServices.indexOf(e.target.value),1);
        }
        setSelectedServices(idServices);
    }

    const requestServices = async() => {
        setLoading(true);
        if(selectedServices.length == 0){
            setShowModal(false);
            setLoading(false);
            return (
                setSnackBarConfig(prev => ({
                    ...prev,
                    open: true,
                    severity: 'warning',
                    message: `Debe seleccionar almenos un servicio.`,
                }))
            )
        }

        const data = { idServices : selectedServices }

        await empresasService.requestServices(data, idCompany).then((data) => {
            
            if(data.status == 200){
                setLoading(false);
                setShowModal(false);
                return (
                    setSnackBarConfig(prev => ({
                        ...prev,
                        open: true,
                        severity: 'success',
                        message: `su solicitud ha sido enviada.`,
                    }))
                )
            }else{
                setLoading(false);
                setShowModal(false);
                return (
                    setSnackBarConfig(prev => ({
                        ...prev,
                        open: true,
                        severity: 'error',
                        message: `Ocurrió un problema.`,
                    }))
                )
            }
        })
    }

    const cargarSalas = async (idCompany, idSucursal)=>{
        setLoading(true);
        await salas.getSalasByIdCompany(idCompany, idSucursal).then((data)=>{
            setRooms(data || []);
            if(data){
                setSelectedRoom(data[0].nombre);
                cargarMesas(data[0].id);
                // setIdRoom(data[0].id);
            }
            setLoading(false);
        });
    }

    const cargarMesas = async (id_sala)=>{
        setLoading(true);
        await salas.cargarMesas(id_sala).then((data)=>{
            setTables(data.mesas || [])
            setLoading(false)
        });

    }


    const saveTipoAtencion = async() => {
        setLoading(true);
        await empresasService.editTipoAtencionMesero({tipo_atencion_mesero: opcioneSelected}, idCompany).then(async(data)=>{
            if (data && data?.status === 200) {
               setSnackBarConfig({open:true, severity:'success', message: 'Actualizado con éxito'})
            }else{
               setSnackBarConfig({open:true, severity:'error', message: 'Ocurrio un error'})
            }
            await findCompany();
            setLoading(false);
        }) 
    }

    const saveStockRestriction = async() => {
        setLoading(true);
        await empresasService.editStockRestricction({stock_restriction: opcionStockSelected}, idCompany).then(async(data)=>{
            if (data && data?.status === 200) {
               setSnackBarConfig({open:true, severity:'success', message: 'Actualizado con éxito'})
            }else{
               setSnackBarConfig({open:true, severity:'error', message: 'Ocurrio un error'})
            }
            await findCompany();
            setLoading(false);
        }) 
    }

    return (
        <Protected>
            {loading && <Loading/>}
            <Snackbar
                open={snackBarConfig.open}
                autoHideDuration={snackBarConfig.autoHideDuration}
                onClose={() => setSnackBarConfig(prev => ({ ...prev, open: false }))}
                anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                >
                <Alert
                onClose={() => setSnackBarConfig(prev => ({ ...prev, open: false }))}
                severity={snackBarConfig.severity}
                variant="filled"
                >
                {snackBarConfig.message}
                </Alert>
            </Snackbar>

            <Card style={{ padding: '20px', marginBottom: '20px' }}>
                <Grid display='flex' alignItems='center' justifyContent='space-between'>
                    <Typography>Configura la restricción de ventas por stock</Typography>
                </Grid>
                <Grid style={{ marginTop: '15px' }}>
                    <FormGroup>
                        <FormControlLabel
                            key={1}
                            control={
                                <Checkbox
                                    name="opcion1"
                                    checked={opcionStockSelected == 0}
                                    onChange={() => setOpcionStockSelected(0)}
                                />
                            }
                            label={"No se restringen ventas de productos cuyo stock sea menor o igual a cero."}
                        />
                        <FormControlLabel
                            key={2}
                            control={
                                <Checkbox
                                    name="opcion2"
                                    checked={opcionStockSelected == 1}
                                    onChange={() => setOpcionStockSelected(1)}
                                />
                            }
                            label={"Se restringen las ventas de productos cuyo stock sea menor o igual a cero."}
                        />
                    </FormGroup>
                </Grid>
                <Grid display="flex" justifyContent="end">
                    <Button onClick={() => { saveStockRestriction() }}>Guardar Cambios</Button>
                </Grid>
            </Card>

            <Card style={{ padding: '20px', marginBottom: '20px' }}>
                <Grid display='flex' alignItems='center' justifyContent='space-between'>
                    <Typography>Configura la atencion de los meseros</Typography>
                </Grid>
                <Grid style={{ marginTop: '15px' }}>
                    <FormGroup>
                        <FormControlLabel
                            key={1}
                            control={
                                <Checkbox
                                    name="opcion1"
                                    checked={opcioneSelected == 1}
                                    onChange={() => setOpcionSelected(1)}
                                />
                            }
                            label={"Los meseros no pueden ver los pedidos de otros meseros"}
                        />
                        <FormControlLabel
                            key={2}
                            control={
                                <Checkbox
                                    name="opcion2"
                                    checked={opcioneSelected == 2}
                                    onChange={() => setOpcionSelected(2)}
                                />
                            }
                            label={"Los meseros pueden ver todos los pedidos"}
                        />
                    </FormGroup>
                </Grid>
                <Grid display="flex" justifyContent="end">
                    <Button onClick={() => { saveTipoAtencion() }}>Guardar Cambios</Button>
                </Grid>
            </Card>

            <Card style={{padding: '20px', marginBottom: '20px'}}>
                <Grid display='flex' alignItems='center' justifyContent='space-between'>
                    <Typography>Configuracion de servicios</Typography>
                    {/* <span>Estado de la solicitud:</span> */}
                </Grid>
                <span>
                Al confirmar la solicitud de servicios, estos estarán disponibles en la web pública de "NUPY" para sus clientes.
                </span>
                <Grid  style={{marginTop: '15px'}}>
                    <FormGroup>
                        {
                            services.length > 0 && services.map((service, key) => {
                                let exits = selectedServices.includes(service.id);
                                if(exits) {return (
                                    <FormControlLabel 
                                        key={key} 
                                        checked
                                        control={
                                            <Checkbox value={service.id} onChange={handleServices} />
                                        } 
                                        label={service.name}
                                     />
                                )}else{
                                    return (
                                        <FormControlLabel 
                                            key={key}
                                            checked={false} 
                                            control={
                                                <Checkbox value={service.id} onChange={handleServices} />
                                            } 
                                            label={service.name}
                                        />
                                    )
                                }
                                
                            })
                        }
                    </FormGroup>
                </Grid>
                <Grid display="flex" justifyContent="end">
                    <Button onClick={() => {setShowModal(true)}}>Realizar habilitación</Button>
                </Grid>
            </Card>

            <Card style={{padding: '20px'}}>
                <Grid display='flex' alignItems='center' justifyContent='space-between'>
                    <Typography>Asignación de mesas para reservas</Typography>
                </Grid>
                <span>
                Debe seleccionar las mesas que estarán disponibles para reservaciones
                </span>

                <Grid sx={{ marginBottom:'15px', marginTop: '15px', display: 'flex', alignItems: 'center'}}>
                    <Grid style={{marginTop:'-30px !important'}}>
                        <span>{"salas: "}</span>
                    </Grid>

                    <Grid item xs={2} spacing={3} sx={{marginLeft: '5px'}}>
                        <Select
                            id="filtro"
                            size='small'
                            value={selectedRoom}
                            onChange={(event) => {
                                setSelectedRoom(event.target.value);
                            }}
                            sx={{width:'250px'}}
                            >

                            <MenuItem value={''} disabled><em>seleccionar filtro</em></MenuItem>

                            {
                                rooms.length > 0 && rooms.map((room, key) => (
                                    <MenuItem key={key} value={room.nombre} onClick={() => {
                                        cargarMesas(room.id);
                                    }}
                                        >{room.nombre}</MenuItem>
                                ))
                            }
                        </Select>
                    </Grid>
                </Grid>
                <Grid container display='flex' style={{marginTop: '15px'}}>
                    <Grid item xs={7}>
                        <TableContainer component={Paper}>
                            <Table sx={{ minWidth: 500, maxHeight: 600 }} aria-label="simple table">
                                <TableHead>
                                    <TableRow>
                                        <TableCell align="left">Nombre</TableCell>
                                        <TableCell align="center">Acción</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {
                                        tables.map((table, key) => (
                                            <TableRow key={key}>
                                            <TableCell align="left">{table.nombre}</TableCell>
                                            <TableCell align="center">
                                                <>
                                                <Button
                                                    id="basic-button"
                                                    variant='contained'
                                                    size="small"
                                                    color="error"
                                                    style={{textTransform: "capitalize"}}
                                                    sx={{fontSize: "14px !important"}}
                                                    onClick={(e) => {
                                                        // applyBenefit(beneficio, key);
                                                    }}
                                                >  Aplicar
                                                </Button>
                                                </>
                                            </TableCell>
                                            </TableRow>
                                        ))
                                    }
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Grid>
                    
                </Grid>
                <Grid display="flex" justifyContent="end">
                    <Button onClick={() => {setShowModal(true)}}>Guardar configuración </Button>
                </Grid>
            </Card>

            {
                showModal && (
                    <ModalConfigConfirm
                        open={showModal}
                        title={'¿Esta seguro se solicitar estos servicios?'}
                        context={'Al confirmar la solicitud de servicios, estos estaran disponibles en la web publica de "NUPY" para sus clientes.'}
                        closeModal={() => {setShowModal(false)}}
                        onConfirm={requestServices}
                    />
                )
            }

        </Protected>
    )
}