import { readLocalStorage } from '@helpers/local-storage-helper';
import { KEY_BODEGA_SELECT } from "@/toolbox/constants/local-storage";

import { notaCreditoService } from '@/service/services/NotaCredito.service';
import { ventaService } from '@/service/services/Venta.service';

export const generateAnuladoParcial = async (
      dataEmpresa,
      codigoTransbank,
      setLoadData,
      detalle,
      setSnackBarConfig,
      arrayIdsProducto,
      setArrayIdsProducto,
      tipoBoletaSoftnet,
      token,
      beneficioProducto,
      KEY_EMPRESA,
      beneficioMonto,
      pedido,
      pagosSelect, namepagosSelect, STATUS_FINALIZADO,
      ruc_empresa,
      setShowPDF,
      setDataImprimir,
      setOpenPopup,
      loadDataList,
      idPedidoAnulado
   ) => {
      const pais = dataEmpresa.country_abbreviation;
      const formats = {
         codigoPeru: /^\d{6}$/,
         codigoChile: /^\d{6}$/,
         codigoColombia: /^\d{6}$/,
      }

      let error:any;
      if(pais==='CLP' && !formats.codigoChile.test(codigoTransbank)) error=true;
      if(pais==='PEN' && formats.codigoPeru.test(codigoTransbank)) error=true;
      // eslint-disable-next-line
      if(pais==='COP' && formats.codigoColombia.test(codigoTransbank)) error=true;
      // setLoadData(true);

      if(detalle.length === 0){
         setSnackBarConfig(prev => ({
            ...prev,
            open: true,
            message: `debe agregar al menos un producto`,
         }));
         setLoadData(false);
      }else{

         let detalleBoletaDef = [...detalle];
         let detalleBoleta = [...detalle];
         detalleBoleta = detalleBoleta.filter(item => item?.wasDeleted  !== true);
         detalleBoleta.forEach(element => {
            if (element?.newCantidad) {
               element.cantidadD = element.newCantidad;
            } else {
               element.cantidadD = element.cantidad;
            }
         });
         arrayIdsProducto.length = 0;
         setArrayIdsProducto(arrayIdsProducto);
         setLoadData(true);

         let confProducto: any
         confProducto = await tipoBoletaSoftnet(token);
         let tipoBoleta = '';
         // eslint-disable-next-line
         confProducto?.conf.map((item)=>{
            if(item.tipo_doc === '39'){
               tipoBoleta = item.tipo;
            }
         })

         const bodega = readLocalStorage(KEY_BODEGA_SELECT);
         let copyProducts: Array<any> = []
         let monto_neto_calculado = 0;

         let total: number = 0;
         let descuento: number = 0;
         let descuentoMonto: number = 0;
         let descuentoProductos: number = 0;
         let totalCard: number = 0;
         for (let i = 0; i < detalleBoleta.length; i++) {

            if (!detalleBoleta[i]?.cantidadD) {
               if(detalleBoleta[i].precio_oferta){
                  total = (total + Number(detalleBoleta[i].precio_oferta))
               }else{
                  total = (total + Number(detalleBoleta[i].precio_standar))
               }
            }else{
               if(detalleBoleta[i].precio_oferta){
                  if (detalleBoleta[i].id_unidad_medida) {
                     total = (total + ((Number(detalleBoleta[i].precio_oferta)*detalleBoleta[i].cantidadD) / Number(detalleBoleta[i].cantidad_peso)))
                  } else {
                     total = (total + ((Number(detalleBoleta[i].precio_oferta)*detalleBoleta[i].cantidadD) / Number(detalleBoleta[i].cantidad_peso)))
                  }
               }else{
                  if (detalleBoleta[i].id_unidad_medida) {
                     total = (total + ((Number(detalleBoleta[i].precio_standar)*detalleBoleta[i].cantidadD) / Number(detalleBoleta[i].cantidad_peso)))
                  } else {
                     total = (total + (Number(detalleBoleta[i].precio_standar)*detalleBoleta[i].cantidadD))
                  }
               }
            }
            //si tiene beneficio se comenta su aplicacion directa
            // if (detalleBoleta[i].id_producto) {
            //    let result = beneficioProducto.find(prod => prod.id_producto === Number(detalleBoleta[i].id_producto));
            //    if ( result ) {
            //       let cantidadProd = detalleBoleta[i]?.cantidadD || 1;
            //       if (cantidadProd >= result.cantidad_productos) {
            //          let multiplo = cantidadProd / result.cantidad_productos
            //          let descuentoProd = (result.cantidad_puntos * dataEmpresa.equivalencia_puntos) * Math.trunc(multiplo)
            //          descuento += descuentoProd;
            //       }
            //    }
            // }
            let precio = 0;
            let codigo = 0;
            if(detalleBoleta[i].precio_oferta){
               precio = detalleBoleta[i].precio_oferta;
            }else{
               precio = detalleBoleta[i].precio_standar;
            }
            codigo = detalleBoleta[i].codigo_erp;

            let monto_neto_producto1 = 0;
            let filtro_cantidad = 0;
            if (detalleBoleta[i].id_unidad_medida) {
               monto_neto_producto1 = tipoBoleta === 'N'? Math.ceil((precio/detalleBoleta[i].cantidad_peso) /1.19) : precio;
               if(detalleBoleta[i].id_unidad_medida !== 1){
                  filtro_cantidad = Math.ceil(detalleBoleta[i].cantidadD);
               }else{
                  filtro_cantidad = detalleBoleta[i].cantidadD;
               }
            } else {
               monto_neto_producto1 = tipoBoleta === 'N'? Math.ceil(precio /1.19) : precio;
               filtro_cantidad = detalleBoleta[i].cantidadD;
            }
            copyProducts.push({
               "Afecto": tipoBoleta === 'N'? true : false,
               "Descuento": 0,
               "Bodega": bodega,
               "Cantidad": filtro_cantidad,
               "Codigo": codigo,
               "Precio": monto_neto_producto1,
            })
            if (detalleBoleta[i].id_unidad_medida && detalleBoleta[i].id_unidad_medida !== 1) {
               monto_neto_calculado= monto_neto_calculado + monto_neto_producto1*filtro_cantidad;
            }else{
               monto_neto_calculado= monto_neto_calculado + monto_neto_producto1*detalleBoleta[i].cantidadD;
            }

            arrayIdsProducto.push(detalleBoleta[i].id_producto);
            setArrayIdsProducto(arrayIdsProducto);
         }

         // let mayorMonto = 0;
         let puntos = 0;
         // Se comenta su aplicacion directa
         // for (let i = 0; i < beneficioMonto.length; i++) {
         //    const beneficio = beneficioMonto[i];
         //    if ((total-descuento) >= beneficio.monto) {
         //       if (mayorMonto < beneficio.monto) {
         //          mayorMonto = beneficio.monto
         //          puntos = beneficio.cantidad_puntos
         //       }
         //    }
         // }

         descuentoMonto = puntos*dataEmpresa.equivalencia_puntos;
         descuentoProductos = descuento;
         totalCard = total-(descuento + (puntos*dataEmpresa.equivalencia_puntos));

         let descuentoTotal = 0;
         if(descuentoMonto > 0){
            descuentoTotal = descuentoMonto;
         }
         if(descuentoProductos > 0){
            descuentoTotal = descuentoProductos
         }
         if(descuentoMonto > 0 && descuentoProductos > 0){
            descuentoTotal = descuentoMonto + descuentoProductos;
         }

         let sendData = [{
            "Encabezado": {
               "Receptor": '66666666-6',
               "MontoNeto": monto_neto_calculado,
               "Descuento":descuentoTotal,
               "TipoDocumento": '39',
               "AreaNegocio": "3831",
            },
            "Detalle":copyProducts,
            "cliente": 'cliente',
            "Adicional": {
               "Uno": pagosSelect,
               "Dos": ruc_empresa,
            }
         }];

         const boletagenerada = await ventaService.enviarBoleta(token, sendData);

         if(!boletagenerada[0]){
            setSnackBarConfig(prev => ({
               ...prev,
               open: true,
               message: 'Los datos a procesar son inválidos',
         }));
            setLoadData(false);
         }else{
            if(boletagenerada[0].mensaje!=='Item guardado con exito.'){
               setSnackBarConfig(prev => ({
                  ...prev,
                  open: true,
                  message: `${boletagenerada[0].mensaje}`,
            }));
               setLoadData(false);
            }else{
               let ventaID = 0
               ventaID = await ventaService.generarBoletaVentaDirect(pedido?.id_mesa || null, pedido?.id_cliente || null, totalCard, descuentoMonto, descuentoProductos, detalleBoleta, (codigoTransbank || null), namepagosSelect || null,  boletagenerada[0].folio, 1, bodega, arrayIdsProducto, boletagenerada[0].urlTimbre, boletagenerada[0].urlTimbre ,monto_neto_calculado, STATUS_FINALIZADO, 0);

               let res = await notaCreditoService.anularVentaParcial(idPedidoAnulado, detalleBoletaDef, ventaID)
               setShowPDF({
                  idVenta: ventaID,
                  montoNeto: monto_neto_calculado,
                  timbre: boletagenerada[0].urlTimbre,
                  folio: boletagenerada[0].folio,
                  open: true
               })
               ventaService.getVentaDetalle(ventaID).then((res) => {

                  if (res.status === 200) {
                     let arrayProducts = [];
                     // eslint-disable-next-line
                     (res.tickets).map((item) => {
                        for(let i=0; i < item.detalle_ticket.length; i++){
                           const productos = item.detalle_ticket[i].productos;
                           arrayProducts.push({
                           "cantidad": item.detalle_ticket[i].cantidad,
                           "id_producto": item.detalle_ticket[i].id_producto,
                           "codigo_erp": productos?.codigo_erp,
                           "nombre": productos?.nombre,
                           "id_tipo_producto": productos?.id_tipo_producto,
                           "precio_standar": productos?.precio_standar,
                           "precio_oferta": productos?.precio_oferta,
                           "cantidad_peso": productos?.cantidad_peso,
                           });
                        }
                     });

                     const venta = {
                        detalle : arrayProducts,
                        ventaDetalle : res.venta,
                        folio : boletagenerada[0].folio,
                        timbre: boletagenerada[0].urlTimbre,
                        montoNeto: monto_neto_calculado,
                     }

                     setDataImprimir(venta)
                     setOpenPopup(false)
                  }
               })
                  await loadDataList()
                  setLoadData(false);
            }
         }
      }

};
