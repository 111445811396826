import { editor } from "@/service/services/Editor.service";
import React, { useEffect, useState } from "react";
import ReportIcon from '@mui/icons-material/Report';
import { Loading } from '../../../../components/common/Loading/Loading';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Box from '@mui/material/Box';
import FormControl from '@mui/material/FormControl';
import { Button, Grid, Typography } from "@mui/material";
import TextField from '@mui/material/TextField';
import Input from '@mui/material/Input';
import InputAdornment from '@mui/material/InputAdornment';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { TOKEN_API } from "@/toolbox/constants/local-storage";
import { useLocalStorage } from '@toolbox/hooks/local-storage.hook';
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import { KEY_EMPRESA } from '@constants/local-storage';
import Divider from '@mui/material/Divider';
import "./AddProducto.sass"
import Chip from '@mui/material/Chip';
import Stack from '@mui/material/Stack';
import { Popup } from "../Popup";
import { AddProductoS } from "@/views/Productos/AddProductoS";
import { removeLocalStorage } from "@/toolbox/helpers/local-storage-helper";
import { Snackbar, Alert } from '@mui/material';
import { DatePicker, LocalizationProvider } from "@mui/lab";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import esLocale from 'date-fns/locale/es';
import moment from "moment";
import { productoService } from "@/service/services/Producto.service";
import { TipoImpuestoService } from "@/service/services/TipoImpuesto.service";

interface State {
   codigo_erp: string;
   nombre: string;
   precio_standar: string;
   precio_oferta: string;
   caducidad_precio_oferta: string;
   id_tipo_producto: number;
   id_empresa: number;
   submitted: boolean;
   cantidad: number;
   stock_minimo: number;
   id_unidad_medida: number;
   familia_id: number,
   sub_familia_id: number,
   marca_id: number,
   linea_id: number,
   stock?: number,
   idtipo_impuesto: number
}


export const AddProducto: React.FC<any> = (props: any): JSX.Element => {
   const [token] = useLocalStorage(TOKEN_API, "");
   const [loadData, setLoadData] = React.useState<boolean>(false);
   const [products, setProducts] = useState<Array<any>>([]);
   const { submodulo, handleLoad, idSubmodulo, newProduct, getProducts, nameSubmodulo, closeModal, productsExist } = props;

   const [dateCaducidad, setDateCaducidad] = React.useState(new Date());

   const [dataEmpresa] = useLocalStorage(KEY_EMPRESA, {
      id: 0, id_tipo_empresa: 0, equivalencia_puntos: 0,
      id_country: 0, country_name: "", country_abbreviation: "",
   });
   const [values, setValues] = React.useState<State>({
      codigo_erp: '',
      nombre: '',
      precio_standar: '',
      precio_oferta: '',
      caducidad_precio_oferta: '',
      id_tipo_producto: 0,
      id_empresa: 0,
      cantidad: 0,
      submitted: false,
      stock_minimo: 0,
      id_unidad_medida: 1,
      familia_id: 0,
      sub_familia_id: 0,
      marca_id: 0,
      linea_id: 0,
      stock: 0,
      idtipo_impuesto: 0
   });

   const [familias, setFamilia] = React.useState<Array<any>>([]);
   const [lineas, setLineas] = React.useState<Array<any>>([]);
   const [familiasSelected, setFamiliasSelected] = React.useState<any>({
      id: 0,
      nombre: "Familia",
      estado: 0,
      subFamilia: [],
   });

   const [cantidadMenu, setCantidadMenu] = React.useState<any>(0);
   const [nombreMenu, setNombreMenu] = React.useState<any>('');
   const [combo, setCombo] = React.useState([]);
   //  const [totalCombo, setTotalCombo] = React.useState<number>(0);

   const [tipo, setTipo] = React.useState('');
   const [select, setSelect] = React.useState<boolean>(false);
   const [seleccionado, setSeleccionado] = React.useState<any>("");
   const [prodMenu, setProdMenu] = React.useState([]);
   const [cargarProd, setCargarProd] = React.useState<boolean>(false);
   const [filter, setFilter] = useState<Array<any>>([]);
   const [textSearch, setTextSearch] = useState<string>("");
   const [itemsMenu, setItemsMenu] = useState<Array<any>>([]);
   const [itemSelect, setItemSelect] = useState<boolean>(false);
   const [addItem, setAddItem] = useState<boolean>(false);
   const [indexItemSelect, setIndexItemSelect] = React.useState<number>(0);

   const [openPopup, setOpenPopup] = React.useState<boolean>(false);
   const [popUp, setPopUp] = React.useState<String>('');
   const [editMode, setEditMode] = React.useState<boolean>(false);
   const [tiposImpuesto, setTiposImpuesto] = useState<any>([])

   const [snackBarConfig, setSnackBarConfig] = useState<any>({
      open: false,
      severity: 'error',
      message: 'Error',
      autoHideDuration: 3000,
   });

   React.useEffect(() => {
      setTipo(nameSubmodulo);
      getProductApi()
   }, [])

   const getProductApi = async () => {
      setLoadData(true)
      const response = await productoService.getProductsByCompany(dataEmpresa.id)
      if (response.productos?.length) {
         const productosFiltrados = response.productos?.filter(producto => {
            const productoAEliminar = productsExist?.find(p => p[0].id === producto.id);
            return !productoAEliminar;
         });

         setProducts(productosFiltrados)
         setFilter(productosFiltrados)
      }
      setLoadData(false)
   }

   useEffect(() => {
      getLinesProducts();
      getFamiliesAndSubFamilies();
      getTiposImpuestoApi()
   }, [])

   const getLinesProducts = async () => {
      setLoadData(true);
      await productoService.getLinesProducts().then((data) => {
         if (data.status === 200) {
            setLineas(data.lines || []);
         } else {
            setLoadData(false);
            return setSnackBarConfig(prev => ({
               ...prev,
               open: true,
               message: `No se cargaron lineas de productos`,
            }));
         }
      });
      setLoadData(false);
   }

   const getTiposImpuestoApi = async () => {
      const response = await TipoImpuestoService.getTiposImpuesto()
      if (response.data?.length) {
         setTiposImpuesto(response.data)
      }
   }

   const getFamiliesAndSubFamilies = async () => {
      setLoadData(true);
      await productoService.getFamiliesAndSubFamilies().then((data) => {
         if (data.status === 200) {
            setFamilia(data.families || []);
         } else {
            setLoadData(false);
            return setSnackBarConfig(prev => ({
               ...prev,
               open: true,
               message: `No se cargaron Familia de productos`,
            }));
         }
      });
      setLoadData(false);
   }


   const handleChangeFamilia = (id) => {
      setFamiliasSelected({
         id: 0,
         nombre: "",
         subFamilia: [],
         estado: 0,
      })

      let familia = familias.find((f: any) => f.id === id);
      if (familia) {
         setFamiliasSelected(familia)
      }
   }

   React.useEffect(() => {
      if (!openPopup) {
         removeLocalStorage('productsArray');
      }
      // eslint-disable-next-line
   }, [openPopup])

   const handleChangeSearch = (e) => {
      const search: string = e.target.value
      
      if (search !== '') {
         const filteredProducts = products.filter(product => {
            const searchLowerCase = search.trim().toLowerCase()
            return product.nombre && product.nombre.toLowerCase().startsWith(searchLowerCase)
         })
         setFilter(filteredProducts || [])
      } else {
         setFilter(products || [])
      }
   }

   React.useEffect(() => {
      if (textSearch) {
         let copyFilters = []
         copyFilters = products.filter(producto => (producto?.nombre.toLowerCase().includes(textSearch.toLowerCase()) || producto?.codigo_erp.toLowerCase().includes(textSearch.toLowerCase())))
         setFilter([...copyFilters])
      }
   },// eslint-disable-next-line
      [textSearch])


   const handleChange = (event: SelectChangeEvent) => {
      setTipo(event.target.value as string);
      setValues({
         codigo_erp: '',
         nombre: '',
         precio_standar: '',
         precio_oferta: '',
         caducidad_precio_oferta: '',
         id_tipo_producto: 0,
         id_empresa: 0,
         cantidad: 0,
         submitted: false,
         stock_minimo: 0,
         id_unidad_medida: 1,
         familia_id: 0,
         sub_familia_id: 0,
         marca_id: 0,
         linea_id: 0,
         idtipo_impuesto: 0
      })
      setSeleccionado('');
      setSelect(false);
   };

   const handleChangeInput =
      (prop: keyof State) => (event: React.ChangeEvent<HTMLInputElement>) => {
         setValues({ ...values, [prop]: event.target.value });
      };

   const guardar = async (e) => {
      e.preventDefault();
      const id_sub = idSubmodulo ? idSubmodulo : submodulo[0].id;
      if (dataEmpresa.country_name === "Chile") {
         let standar = Math.abs(Number(values.precio_standar || 0));
         let oferta = Math.abs(Number(values.precio_oferta || 0));

         if (((standar - Math.floor(standar)) !== 0) || ((oferta - Math.floor(oferta)) !== 0)) {
            return setSnackBarConfig(prev => ({
               ...prev,
               open: true,
               message: `Los precios deben terminar en 0`,
            }));
         }

         let standar_t = standar / 10;
         let oferta_t = oferta / 10;

         if (((standar_t - Math.floor(standar_t)) !== 0) || ((oferta_t - Math.floor(oferta_t)) !== 0)) {
            return setSnackBarConfig(prev => ({
               ...prev,
               open: true,
               message: `Los precios deben terminar en 0`,
            }));
         }
      }

      setValues(prev => ({
         ...prev,
         submitted: true
      }))

      if (tipo === 'Simple') {
         setLoadData(true);
         if (!values.precio_standar) {
            setLoadData(false);
            return setSnackBarConfig(prev => ({
               ...prev,
               open: true,
               message: `Debe llenar los campos requeridos`,
            }));
         }
         values.codigo_erp = seleccionado.codigo_erp;
         values.nombre = seleccionado.nombre;

         if (values.precio_oferta !== "") {
            values.caducidad_precio_oferta = moment(dateCaducidad).format('DD-MM-YYYY');
         } else {
            values.caducidad_precio_oferta = null;
         }
         values.familia_id = seleccionado.familia_id;
         values.sub_familia_id = seleccionado.sub_familia_id;
         values.marca_id = seleccionado.marca_id;
         values.linea_id = seleccionado.linea_id;
         values.stock = seleccionado.stock;
         values.id_tipo_producto = 1;
         await editor.addProducto(values, id_sub, false, seleccionado.id).then((data) => {
            if (data?.data?.status === 400) {
               setSnackBarConfig(prev => ({
                  ...prev,
                  open: true,
                  message: `${data.data.error}`,
               }));
            } else {
               handleLoad && handleLoad();
            }
         });
      }
      if (tipo === 'Agrupado') {
         values.id_tipo_producto = 2;
         if (!values.codigo_erp || !values.nombre || !values.precio_standar) {
            setLoadData(false);
            return setSnackBarConfig(prev => ({
               ...prev,
               open: true,
               message: `Debe llenar los campos requeridos`,
            }));
         }

         if (values.precio_oferta !== "") {
            values.caducidad_precio_oferta = moment(dateCaducidad).format('DD-MM-YYYY');
         } else {
            values.caducidad_precio_oferta = null;
         }
         setLoadData(true);
         editor.addProducto(values, id_sub, true).then((data) => {
            if (data.data.status === 400) {
               setSnackBarConfig(prev => ({
                  ...prev,
                  open: true,
                  message: `${data.data.error}`,
               }));
            } else {
               editor.addItemsProductoAgrupado(combo, data?.data?.productoID).then((data) => {
                  handleLoad && handleLoad();
               })
            }
         })
      }
      if (tipo === 'Menu') {
         const validado = validateMenu();
         if (!values.codigo_erp || !values.nombre || !values.precio_standar) {
            setLoadData(false);
            return setSnackBarConfig(prev => ({
               ...prev,
               open: true,
               message: `Debe llenar los campos requeridos`,
            }));
         } else {
            if (validado) {
               values.id_tipo_producto = 3;

               if (values.precio_oferta !== "") {
                  values.caducidad_precio_oferta = moment(dateCaducidad).format('DD-MM-YYYY');
               } else {
                  values.caducidad_precio_oferta = null;
               }
               setLoadData(true);
               const resp = await editor.addProductoMenu(values, id_sub, itemsMenu, true);
               if (resp.status === 400) {
                  setSnackBarConfig(prev => ({
                     ...prev,
                     open: true,
                     message: `${resp.error}`,
                  }));
               } else {
                  for (let i = 0; i < prodMenu.length; i++) {
                     const array = [];
                     for (let j = 0; j < prodMenu[i].length; j++) {
                        array.push(prodMenu[i][j]);
                     }
                     await editor.addItemsMenu(array, resp[i]);
                  }
                  return handleLoad();
               }
            }
         }
      }
      setLoadData(false);
   }

   const handleChangePriceItem = (index, e) => {
      let copyCombo = combo;
      if (copyCombo[index]) {
         if (e.target.value) {
            copyCombo[index].cantidad = e.target.value
            setCombo([...copyCombo])
         }
      }
   }

   const handleChangeInputMenu =
      (e) => {
         setNombreMenu(e.target.value)
      };

   const handleChangeInputCantidad = (e) => {
      setCantidadMenu(e.target.value)
   }

   const handleDeleteItemAgrupado = (index) => {
      let copyCombo = combo;
      if (copyCombo[index]) {
         copyCombo.splice(index, 1);
         setCombo([...copyCombo])
      }
   }

   React.useEffect(() => {
      setNombreMenu('');
   }, [itemsMenu.length])

   React.useEffect(() => {
      setItemsMenu(itemsMenu);
   }, [itemsMenu])

   const agregarItem = () => {
      if (nombreMenu === '') {
         return
      }
      if (cantidadMenu === 0) {
         return
      }
      const item = {
         nombre: nombreMenu,
         cantidad: cantidadMenu,
      }
      setNombreMenu('');
      setCantidadMenu(0);
      itemsMenu.push(item)
      setItemsMenu(itemsMenu)
      setNombreMenu('');
   }

   const eliminarItemMenu = (index) => {
      itemsMenu.splice(index, 1);
      setItemsMenu([...itemsMenu]);
   }

   const eliminarProductosItemMenu = (index) => {
      prodMenu.splice(index, 1);
      setProdMenu([...prodMenu]);
   }

   const eliminarProductoItemMenu = (indexItem, indexProd) => {
      prodMenu[indexItem].splice(indexProd, 1);
      setProdMenu([...prodMenu]);
   }

   const validateMenu = () => {
      if (itemsMenu.length > 0) {
         for (let i = 0; i < itemsMenu.length; i++) {
            if (prodMenu[i] === undefined) {
               return setSnackBarConfig(prev => ({
                  ...prev,
                  open: true,
                  message: `Los Items deben contener al menos un producto`,
               }));
            } else {
               if (prodMenu[i].length === 0) {
                  return setSnackBarConfig(prev => ({
                     ...prev,
                     open: true,
                     message: `Los Items deben contener al menos un producto`,
                  }));
               }
            }
         }
      } else {
         return setSnackBarConfig(prev => ({
            ...prev,
            open: true,
            message: `Debe agregar almenos un Item`,
         }));
      }
      return true;
   }

   useEffect(() => {
      console.log('prodMenu', prodMenu)
   }, [prodMenu])
   return (
      <>
         <Snackbar
            open={snackBarConfig.open}
            autoHideDuration={snackBarConfig.autoHideDuration}
            onClose={() => setSnackBarConfig(prev => ({ ...prev, open: false }))}
            anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
         >
            <Alert
               onClose={() => setSnackBarConfig(prev => ({ ...prev, open: false }))}
               severity={snackBarConfig.severity}
               variant="filled"
            >
               {snackBarConfig.message}
            </Alert>
         </Snackbar>

         <div>
            {loadData && <Loading />}
            {!select && !cargarProd && (
               <Grid container spacing={3} alignItems="center" justifyContent="center">
                  <Grid item xs={12} container style={{ display: "flex", alignItems: "center" }}>
                     <Grid item xs={4}>
                        <Typography variant="subtitle1" className="custom-input"><b>Tipo de producto:</b></Typography>
                     </Grid>
                     <Grid item xs={8}>
                        <FormControl fullWidth>
                           <InputLabel id="demo-simple-select-label">Tipo</InputLabel>
                           <Select
                              labelId="demo-simple-select-label"
                              id="demo-simple-select"
                              value={tipo}
                              label="Tipo"
                              onChange={handleChange}
                           >
                              <MenuItem value={'Simple'}>Simple</MenuItem>
                              <MenuItem value={'Agrupado'}>Agrupado</MenuItem>
                              <MenuItem value={'Menu'}>Menú</MenuItem>
                           </Select>
                        </FormControl>
                     </Grid>
                  </Grid>
               </Grid>)
            }

            {!cargarProd &&
               <div className="container">
                  <div className="row" style={{ width: '100%' }}>
                     <div className="col-md-12">
                        <div className="card-body">
                           <form onSubmit={guardar}>

                              {/* select */}
                              {
                                 !select && (
                                    <Divider sx={{ my: 2 }} />
                                 )
                              }

                              {tipo === 'Simple' &&
                                 <div>
                                    {
                                       !select && (
                                          newProduct && newProduct === 'addNewProduct' ? (
                                             <Button
                                                onClick={() => {
                                                   setOpenPopup(true);
                                                   setPopUp('addNewProduct');
                                                }}
                                                style={{ marginTop: '10px', float: 'right' }}
                                                variant="outlined"
                                             >Agregar producto</Button>
                                          ) : (
                                             <>
                                                <Button
                                                   onClick={() => {
                                                      setCargarProd(true);
                                                      setOpenPopup(true);
                                                      setPopUp('selectProductSofnet');
                                                   }}
                                                   style={{ marginTop: '10px', float: 'right' }}
                                                   variant="outlined"
                                                >Seleccionar producto</Button>

                                                {window.location.pathname === '/Editor' && (
                                                   <Button
                                                      onClick={() => {
                                                         setOpenPopup(true);
                                                         setEditMode(true);
                                                         setPopUp('addNewProduct');
                                                      }}
                                                      style={{ marginTop: '10px', marginLeft: '300px' }}
                                                      variant="outlined"
                                                   >Agregar nuevo producto</Button>
                                                )}

                                             </>
                                          ))
                                    }
                                    <div>
                                       {select &&
                                          <div>
                                             <TextField
                                                fullWidth
                                                id="outlined-read-only-input"
                                                label="Nombre Producto"
                                                defaultValue={seleccionado.nombre} //cambiar por el nombre de prod
                                                InputProps={{
                                                   readOnly: true,
                                                }}
                                                style={{ marginTop: '20px' }}
                                             />

                                             <Grid display="flex" spacing="3" container>
                                                <Grid xs={4}>
                                                   <InputLabel htmlFor="standard-adornment-amount" style={{ marginTop: '20px' }}>Precio Estandar</InputLabel>
                                                   <Input
                                                      name="precio"
                                                      id="standard-adornment-amount"
                                                      type="number"
                                                      value={values.precio_standar}
                                                      onChange={handleChangeInput('precio_standar')}
                                                      startAdornment={<InputAdornment position="start">{dataEmpresa.country_abbreviation}</InputAdornment>}
                                                   />
                                                   {values.submitted && !values.precio_standar && (
                                                      <div className='help-block'><ReportIcon />Debes ingresar un monto</div>)}
                                                </Grid>
                                                <Grid xs={4}>
                                                   <InputLabel style={{ marginTop: '20px' }} htmlFor="standard-adornment-amount">Precio Oferta</InputLabel>
                                                   <Input
                                                      name="precio_oferta"
                                                      id="standard-adornment-amount"
                                                      type="number"
                                                      value={values.precio_oferta}
                                                      onChange={handleChangeInput('precio_oferta')}
                                                      startAdornment={<InputAdornment position="start">{dataEmpresa.country_abbreviation}</InputAdornment>}
                                                   />
                                                </Grid>
                                                {
                                                   values.precio_oferta !== "" && (
                                                      <Grid item xs={4} >
                                                         <InputLabel style={{ marginTop: '20px' }} htmlFor="standard-adornment-amount">Caducidad Precio</InputLabel>
                                                         <LocalizationProvider dateAdapter={AdapterDateFns} locale={esLocale} style={{ width: '300px' }}>
                                                            <DatePicker
                                                               views={['year', 'month', 'day']}
                                                               minDate={new Date('2022-01-01')}
                                                               maxDate={new Date('2025-01-01')}
                                                               value={dateCaducidad}
                                                               onChange={(newValue) => {
                                                                  setDateCaducidad(newValue);
                                                               }}
                                                               renderInput={(params) => <TextField size='small' {...params} helperText={null} />}
                                                            />
                                                         </LocalizationProvider>
                                                      </Grid>
                                                   )
                                                }
                                                <Grid xs={4}>
                                                   <InputLabel style={{ marginTop: '10px' }} htmlFor="standard-adornment-amount">Stock Minimo</InputLabel>
                                                   <Input
                                                      name="precio_oferta"
                                                      id="standard-adornment-amount"
                                                      type="number"
                                                      value={values.stock_minimo}
                                                      onChange={handleChangeInput('stock_minimo')}
                                                   />
                                                </Grid>
                                             </Grid>
                                             <Divider sx={{ my: 2 }} />
                                             <div className="form-group mb-3 buton_container_modal">
                                                <button type="submit" className="btn btn-primary"
                                                //onClick={()=> agregarProducto()}
                                                >Agregar Producto</button>
                                             </div>

                                          </div>
                                       }
                                    </div>
                                 </div>
                              }
                              {tipo === 'Agrupado' &&
                                 <div>
                                    <Grid container sx={{ justifyContent: "space-between", alignItems: "center" }} spacing={2}>

                                       <Grid container item xs={12} style={{ display: "flex", alignItems: "center" }}>
                                          <Grid item xs={4}>Código producto: </Grid>
                                          <Grid item xs={8} style={{ display: 'flex', alignItems: "center", width: "230px" }}>
                                             <TextField
                                                placeholder="Codigo"
                                                id="outlined-size-small"
                                                size="small"
                                                value={values.codigo_erp}
                                                onChange={handleChangeInput('codigo_erp')}
                                                fullWidth />
                                             {values.submitted && !values.codigo_erp && (
                                                <div className='help-block'><ReportIcon />Debes ingresar un codigo</div>)}
                                          </Grid>
                                       </Grid>

                                       <Grid container item xs={12} style={{ display: "flex", alignItems: "center" }}>
                                          <Grid item xs={4}>Nombre producto: </Grid>
                                          <Grid item xs={8} style={{ display: 'flex', alignItems: "center", width: "230px" }}>
                                             <TextField
                                                placeholder="Nombre"
                                                id="outlined-size-small"
                                                size="small"
                                                value={values.nombre}
                                                onChange={handleChangeInput('nombre')}
                                                fullWidth />
                                             {values.submitted && !values.nombre && (
                                                <div className='help-block'><ReportIcon />Debes ingresar un nombre</div>)}
                                          </Grid>
                                       </Grid>

                                       <Grid container item xs={12} style={{ display: "flex", alignItems: "center" }}>
                                          <Grid item xs={4}>{`Precio (${dataEmpresa.country_abbreviation}):`}</Grid>
                                          <Grid item xs={8} style={{ display: 'flex', alignItems: "center", width: "230px" }}>
                                             <TextField
                                                placeholder="precio"
                                                name="precio"
                                                id="standard-adornment-amount"
                                                size="small"
                                                type="number"
                                                value={values.precio_standar}
                                                onChange={handleChangeInput('precio_standar')}
                                                fullWidth
                                             // startAdornment={<InputAdornment position="start">{dataEmpresa.country_abbreviation}</InputAdornment>}
                                             />
                                             {values.submitted && !values.precio_standar && (
                                                <div className='help-block'><ReportIcon />Debes ingresar un monto</div>)}
                                          </Grid>
                                       </Grid>

                                       <Grid container item xs={12} style={{ display: "flex", alignItems: "center" }}>
                                          <Grid item xs={4}>{`Precio oferta (${dataEmpresa.country_abbreviation}):`}</Grid>
                                          <Grid item xs={8} style={{ display: 'flex', alignItems: "center", width: "230px" }}>
                                             <TextField
                                                placeholder="precio oferta"
                                                name="precio_oferta"
                                                id="standard-adornment-amount"
                                                size="small"
                                                type="number"
                                                value={values.precio_oferta}
                                                onChange={handleChangeInput('precio_oferta')}
                                                fullWidth
                                             // startAdornment={<InputAdornment position="start">{dataEmpresa.country_abbreviation}</InputAdornment>}
                                             />
                                          </Grid>
                                       </Grid>

                                       {
                                          values.precio_oferta !== "" && (
                                             <Grid container item xs={12} style={{ display: "flex", alignItems: "center" }}>
                                                <Grid item xs={4}>Caducidad precio: </Grid>
                                                <LocalizationProvider dateAdapter={AdapterDateFns} locale={esLocale}>
                                                   <DatePicker
                                                      views={['year', 'month', 'day']}
                                                      minDate={new Date('2022-01-01')}
                                                      maxDate={new Date('2025-01-01')}
                                                      value={dateCaducidad}
                                                      onChange={(newValue) => {
                                                         setDateCaducidad(newValue);
                                                      }}
                                                      renderInput={(params) => <TextField size="small" style={{ width: '230px' }} {...params} helperText={null} />}
                                                   />
                                                </LocalizationProvider>
                                             </Grid>
                                          )
                                       }

                                       <Grid container item xs={12} style={{ display: "flex", alignItems: "center" }}>
                                          <Grid item xs={4}>Stock: </Grid>
                                          <Grid item xs={8} style={{ display: 'flex', alignItems: "center", width: "230px" }}>
                                             <TextField
                                                name="precio_oferta"
                                                id="standard-adornment-amount"
                                                size="small"
                                                type="number"
                                                value={values.stock_minimo}
                                                onChange={handleChangeInput('stock_minimo')}
                                                fullWidth
                                             />
                                          </Grid>
                                       </Grid>

                                       <Grid container item xs={12} style={{ display: "flex", alignItems: "center" }}>
                                          <Grid item xs={4}>Familia: </Grid>
                                          <Grid item xs={8} style={{ display: 'flex', alignItems: "center", width: "230px" }}>
                                             <Select
                                                id="familia_id"
                                                size="small"
                                                value={values.familia_id}
                                                onChange={(event) => {
                                                   setValues({ ...values, "familia_id": Number(event.target.value || 0) });
                                                   handleChangeFamilia(event.target.value);
                                                }}
                                                /* error={ errors.rol && touched.rol ? true : false } */
                                                fullWidth
                                             >
                                                {
                                                   (familias || []).map((item, i) => {
                                                      return <MenuItem key={i} value={item.id}>{item.nombre}</MenuItem>;
                                                   })
                                                }
                                             </Select>
                                          </Grid>
                                       </Grid>

                                       {familiasSelected?.subFamilias &&
                                          <Grid container item xs={12} style={{ display: "flex", alignItems: "center" }}>
                                             <Grid item xs={4}>Subfamilia: </Grid>
                                             <Grid item xs={8} style={{ display: 'flex', alignItems: "center", width: "230px" }}>
                                                <Select
                                                   id="sub_familia_id"
                                                   size="small"
                                                   value={values.sub_familia_id}
                                                   onChange={(event) => {
                                                      setValues({ ...values, "sub_familia_id": Number(event.target.value || 0) })
                                                   }}
                                                   fullWidth
                                                >
                                                   {
                                                      (familiasSelected?.subFamilias || []).map((item, i) => {
                                                         return <MenuItem key={i} value={item.id}>{item.nombre_subFamilia}</MenuItem>;
                                                      })
                                                   }
                                                </Select>
                                             </Grid>
                                          </Grid>
                                       }

                                       <Grid container item xs={12} style={{ display: "flex", alignItems: "center" }}>
                                          <Grid item xs={4}>Linea de producto: </Grid>
                                          <Grid item xs={8} style={{ display: 'flex', alignItems: "center", width: "230px" }}>
                                             <Select
                                                id="linea_id"
                                                size="small"
                                                value={values.linea_id}
                                                onChange={(event) => {
                                                   setValues({ ...values, "linea_id": Number(event.target.value || 0) })
                                                }}
                                                /* error={ errors.rol && touched.rol ? true : false } */
                                                fullWidth
                                             >
                                                {
                                                   (lineas || []).map((item, i) => {
                                                      return <MenuItem key={i} value={item.id}>{item.nombre}</MenuItem>;
                                                   })
                                                }
                                             </Select>
                                          </Grid>
                                       </Grid>

                                       <Grid container item xs={12} style={{ display: "flex", alignItems: "center" }}>
                                          <Grid item xs={4}>
                                             Tipo de impuesto:
                                          </Grid>
                                          <Grid item xs={8} style={{ display: 'flex', alignItems: "center", width: "230px" }}>
                                             <Select
                                                id="idtipo_impuesto"
                                                value={values.idtipo_impuesto}
                                                onChange={(event) => {
                                                   setValues({ ...values, "idtipo_impuesto": Number(event.target.value || 0) })
                                                }}
                                                /* error={ errors.rol && touched.rol ? true : false } */
                                                fullWidth
                                             // disabled={!editMode}
                                             >
                                                {
                                                   (tiposImpuesto || []).map((item, i) => {
                                                      return <MenuItem key={i} value={item.id}>{item.nombre}</MenuItem>;
                                                   })
                                                }
                                             </Select>
                                          </Grid>
                                       </Grid>

                                    </Grid>

                                    {combo.length !== 0 &&
                                       <Box>
                                          <Box>
                                             <div className="item">
                                                {
                                                   (combo || []).map((item, index) => (
                                                      <div className="item_group">
                                                         <div>
                                                            <span>{index + 1}</span>
                                                            <span>{combo[index]['codigo']} - {combo[index]['nombre']}</span>
                                                         </div>
                                                         <div>
                                                            <input type="number" value={combo[index]['cantidad']}
                                                               onChange={(e) => handleChangePriceItem(index, e)} />
                                                            <button onClick={() => handleDeleteItemAgrupado(index)}>Quitar</button>
                                                         </div>
                                                      </div>
                                                   ))
                                                }
                                             </div>
                                          </Box>
                                       </Box>
                                    }
                                    <Divider sx={{ my: 2 }} />
                                    <Button
                                       onClick={() => {
                                          setCargarProd(true);
                                          setOpenPopup(true);
                                          setPopUp('selectProductSofnet');
                                       }}
                                       style={{ marginTop: '10px' }}
                                       variant="outlined"
                                    >Seleccionar productos
                                    </Button>
                                    <Divider sx={{ my: 2 }} />
                                    {combo.length !== 0 &&
                                       <Box sx={{ marginTop: '30px' }}>
                                          <div className="form-group mb-3 buton_container_modal">
                                             <button type="submit" className="btn btn-primary">Agregar Producto</button>
                                          </div>
                                       </Box>
                                    }
                                 </div>
                              }

                              {tipo === 'Menu' &&
                                 <div>
                                    <Grid container sx={{ justifyContent: "space-between", alignItems: "center" }} spacing={2}>

                                       <Grid container item xs={12} style={{ display: "flex", alignItems: "center" }}>
                                          <Grid xs={4}>Código producto: </Grid>
                                          <Grid item xs={8} style={{ display: 'flex', alignItems: "center", width: "230px" }}>
                                             <TextField
                                                placeholder="codigo"
                                                id="outlined-size-small"
                                                size="small"
                                                value={values.codigo_erp}
                                                onChange={handleChangeInput('codigo_erp')}
                                                fullWidth />
                                             {values.submitted && !values.codigo_erp && (
                                                <div className='help-block'><ReportIcon />Debes ingresar un codigo</div>)}
                                          </Grid>
                                       </Grid>

                                       <Grid container item xs={12} style={{ display: "flex", alignItems: "center" }}>
                                          <Grid xs={4}>Nombre producto: </Grid>
                                          <Grid item xs={8} style={{ display: 'flex', alignItems: "center", width: "230px" }}>
                                             <TextField
                                                placeholder="nombre"
                                                id="outlined-size-small"
                                                size="small"
                                                value={values.nombre}
                                                onChange={handleChangeInput('nombre')}
                                                fullWidth
                                             />
                                             {values.submitted && !values.nombre && (
                                                <div className='help-block'><ReportIcon />Debes ingresar un nombre</div>)}
                                          </Grid>
                                       </Grid>

                                       <Grid container item xs={12} style={{ display: "flex", alignItems: "center" }}>
                                          <Grid xs={4}>{`Precio (${dataEmpresa.country_abbreviation}):`}</Grid>
                                          <Grid item xs={8} style={{ display: 'flex', alignItems: "center", width: "230px" }}>
                                             <TextField
                                                placeholder="precio"
                                                name="precio"
                                                id="standard-adornment-amount"
                                                size="small"
                                                type="number"
                                                value={values.precio_standar}
                                                onChange={handleChangeInput('precio_standar')}
                                                // startAdornment={<InputAdornment position="start">{dataEmpresa.country_abbreviation}</InputAdornment>}
                                                fullWidth
                                             />
                                             {values.submitted && !values.precio_standar && (
                                                <div className='help-block'><ReportIcon />Debes ingresar un monto</div>)}
                                          </Grid>
                                       </Grid>

                                       <Grid container item xs={12} style={{ display: "flex", alignItems: "center" }}>
                                          <Grid xs={4}>{`Precio oferta (${dataEmpresa.country_abbreviation}):`} </Grid>
                                          <Grid item xs={8} style={{ display: 'flex', alignItems: "center", width: "230px" }}>
                                             <TextField
                                                placeholder="precio oferta"
                                                name="precio_oferta"
                                                id="standard-adornment-amount"
                                                size="small"
                                                type="number"
                                                value={values.precio_oferta}
                                                onChange={handleChangeInput('precio_oferta')}
                                                fullWidth
                                             // startAdornment={<InputAdornment position="start">{dataEmpresa.country_abbreviation}</InputAdornment>}
                                             />
                                          </Grid>
                                       </Grid>
                                       {
                                          values.precio_oferta !== "" && (
                                             <Grid container item xs={12} style={{ display: "flex", alignItems: "center" }}>
                                                <Grid xs={4}>Caducidad precio: </Grid>
                                                <LocalizationProvider dateAdapter={AdapterDateFns} locale={esLocale} style={{ width: '300px' }}>
                                                   <DatePicker
                                                      views={['year', 'month', 'day']}
                                                      minDate={new Date('2022-01-01')}
                                                      maxDate={new Date('2025-01-01')}
                                                      value={dateCaducidad}
                                                      onChange={(newValue) => {
                                                         setDateCaducidad(newValue);
                                                      }}
                                                      renderInput={(params) => <TextField size="small" style={{ width: '230px' }} {...params} helperText={null} />}
                                                   />
                                                </LocalizationProvider>
                                             </Grid>
                                          )
                                       }

                                       <Grid container item xs={12} style={{ display: "flex", alignItems: "center" }}>
                                          <Grid xs={4}>Stock requerido: </Grid>
                                          <Grid item xs={8} style={{ display: 'flex', alignItems: "center", width: "230px" }}>
                                             <TextField
                                                name="precio_oferta"
                                                id="standard-adornment-amount"
                                                size="small"
                                                type="number"
                                                value={values.stock_minimo}
                                                onChange={handleChangeInput('stock_minimo')}
                                                fullWidth
                                             />
                                          </Grid>
                                       </Grid>

                                       <Grid container item xs={12} style={{ display: "flex", alignItems: "center" }}>
                                          <Grid xs={4}>Familia: </Grid>
                                          <Grid item xs={8} style={{ display: 'flex', alignItems: "center", width: "230px" }}>
                                             <Select
                                                id="familia_id"
                                                size="small"
                                                value={values.familia_id}
                                                onChange={(event) => {
                                                   setValues({ ...values, "familia_id": Number(event.target.value || 0) });
                                                   handleChangeFamilia(event.target.value);
                                                }}
                                                /* error={ errors.rol && touched.rol ? true : false } */
                                                fullWidth
                                             >
                                                {
                                                   (familias || []).map((item, i) => {
                                                      return <MenuItem key={i} value={item.id}>{item.nombre}</MenuItem>;
                                                   })
                                                }
                                             </Select>
                                          </Grid>
                                       </Grid>

                                       {familiasSelected?.subFamilias &&
                                          <Grid container item xs={12} style={{ display: "flex", alignItems: "center" }}>
                                             <Grid xs={4}>Sub Familia: </Grid>
                                             <Grid item xs={8} style={{ display: 'flex', alignItems: "center", width: "230px" }}>
                                                <Select
                                                   id="sub_familia_id"
                                                   size="small"
                                                   value={values.sub_familia_id}
                                                   onChange={(event) => {
                                                      setValues({ ...values, "sub_familia_id": Number(event.target.value || 0) })
                                                   }}
                                                   fullWidth
                                                >
                                                   {
                                                      (familiasSelected?.subFamilias || []).map((item, i) => {
                                                         return <MenuItem key={i} value={item.id}>{item.nombre_subFamilia}</MenuItem>;
                                                      })
                                                   }
                                                </Select>
                                             </Grid>
                                          </Grid>
                                       }

                                       <Grid container item xs={12} style={{ display: "flex", alignItems: "center" }}>
                                          <Grid xs={4}>Linea: </Grid>
                                          <Grid item xs={8} style={{ display: 'flex', alignItems: "center", width: "230px" }}>
                                             <Select
                                                id="linea_id"
                                                size="small"
                                                value={values.linea_id}
                                                onChange={(event) => {
                                                   setValues({ ...values, "linea_id": Number(event.target.value || 0) })
                                                }}
                                                /* error={ errors.rol && touched.rol ? true : false } */
                                                fullWidth
                                             >
                                                {
                                                   (lineas || []).map((item, i) => {
                                                      return <MenuItem key={i} value={item.id}>{item.nombre}</MenuItem>;
                                                   })
                                                }
                                             </Select>
                                          </Grid>
                                       </Grid>

                                       <Grid container item xs={12} style={{ display: "flex", alignItems: "center" }}>
                                          <Grid item xs={4}>
                                             Tipo de impuesto:
                                          </Grid>
                                          <Grid item xs={8} style={{ display: 'flex', alignItems: "center", width: "230px" }}>
                                             <Select
                                                id="idtipo_impuesto"
                                                value={values.idtipo_impuesto}
                                                onChange={(event) => {
                                                   setValues({ ...values, "idtipo_impuesto": Number(event.target.value || 0) })
                                                }}
                                                /* error={ errors.rol && touched.rol ? true : false } */
                                                fullWidth
                                             // disabled={!editMode}
                                             >
                                                {
                                                   (tiposImpuesto || []).map((item, i) => {
                                                      return <MenuItem key={i} value={item.id}>{item.nombre}</MenuItem>;
                                                   })
                                                }
                                             </Select>
                                          </Grid>
                                       </Grid>

                                    </Grid>
                                    {/* <Button
                                    onClick={() => {
                                       setItemSelect(false);
                                       setCargarProd(true)
                                       setOpenPopup(true);
                                       setPopUp('selectProductSofnet');
                                    }}
                                    style={{marginTop: '20px', justifyContent: 'end'}}
                                    variant="outlined">
                                       Seleccionar producto
                                 </Button> */}
                                    <div>
                                       { /* select && */
                                          <div>
                                             {/* <Grid container sx={{justifyContent:"space-between", alignItems: "center"}}>
                                          <Box sx={{ flexGrow: 3 }}>
                                             <TextField
                                                fullWidth
                                                id="outlined-read-only-input"
                                                label="Nombre Producto"
                                                value={seleccionado.nombre} //cambiar por el nombre de prod

                                                InputProps={{
                                                   readOnly: true,
                                                }}
                                                style={{marginTop: '20px', marginRight: '20px'}} />
                                          </Box>
                                          <Box sx={{marginRight: '20px',marginLeft: '20px'}}>
                                             <InputLabel htmlFor="standard-adornment-amount" style={{marginTop: '20px'}}>Precio Estandar</InputLabel>
                                             <Input
                                                name="precio"
                                                id="standard-adornment-amount"
                                                value={values.precio_standar}
                                                onChange={handleChangeInput('precio_standar')}
                                                startAdornment={<InputAdornment position="start">{dataEmpresa.country_abbreviation}</InputAdornment>} />
                                             {values.submitted && !values.precio_standar && (
                                             <div className='help-block'><ReportIcon/>Debes ingresar un monto</div>)}
                                          </Box>
                                          <Box >
                                             <InputLabel style={{marginTop: '20px'}} htmlFor="standard-adornment-amount">Precio Oferta</InputLabel>
                                             <Input
                                                name="precio_oferta"
                                                id="standard-adornment-amount"
                                                value={values.precio_oferta}
                                                onChange={handleChangeInput('precio_oferta')}
                                                startAdornment={<InputAdornment position="start">{dataEmpresa.country_abbreviation}</InputAdornment>}
                                                />
                                          </Box>
                                          {
                                             values.precio_oferta !== "" && (
                                                <Grid >
                                                   <InputLabel style={{marginTop: '20px'}} htmlFor="standard-adornment-amount">Caducidad precio</InputLabel>
                                                   <LocalizationProvider dateAdapter={AdapterDateFns} locale={esLocale} style={{width: '300px'}}>
                                                      <DatePicker
                                                      views={['year', 'month', 'day']}
                                                      minDate={new Date('2022-01-01')}
                                                      maxDate={new Date('2025-01-01')}
                                                      value={dateCaducidad}
                                                      onChange={(newValue) => {
                                                            setDateCaducidad(newValue);
                                                      }}
                                                      renderInput={(params) => <TextField {...params} helperText={null} />}
                                                      />
                                                   </LocalizationProvider>
                                                </Grid>
                                             )
                                          }
                                       </Grid> */}

                                             <Divider sx={{ marginTop: '10px', marginBottom: '10px' }} />

                                             {itemsMenu.length !== 0 &&
                                                <Box>
                                                   <Box>
                                                      <Button size="small" variant='contained' sx={{ bgcolor: '#229954', ml: 1, textTransform: 'none' }}
                                                         onClick={() => {
                                                            setAddItem(true);
                                                         }}
                                                      >Agregar Item</Button>
                                                   </Box>
                                                   <TableContainer component={Paper} sx={{ marginTop: '20px' }} >
                                                      <Table sx={{ minWidth: 650 }} aria-label="simple table">
                                                         <Box>
                                                            {itemsMenu.map((item, index) => (
                                                               <Box sx={{ marginLeft: '5px', marginTop: '5px' }}>

                                                                  <Grid sx={{ display: "flex", alignItems: "center", justifyContent: 'space-between' }}>
                                                                     <Box><strong className="mayus">{item.nombre.toUpperCase()}</strong> &nbsp;&nbsp;Cantidad de productos a seleccionar: {item.cantidad} </Box>

                                                                     {/* <Box>Agrega las opciones del menu:</Box> */}

                                                                     <Box sx={{ marginTop: '5px', marginBottom: '5px' }}>
                                                                        <Button variant="outlined" size="small"
                                                                           onClick={() => {
                                                                              setPopUp('selectProductSofnet');
                                                                              setOpenPopup(true);
                                                                              setCargarProd(true);
                                                                              setItemSelect(true);
                                                                              setIndexItemSelect(index);
                                                                           }}
                                                                        >Buscar productos</Button>
                                                                        <Button variant="outlined" size="small" color="error" style={{ marginLeft: '20px' }}
                                                                           onClick={() => {
                                                                              eliminarItemMenu(index);
                                                                              eliminarProductosItemMenu(index);
                                                                           }}
                                                                        >Eliminar</Button>
                                                                     </Box>
                                                                  </Grid>

                                                                  <Divider sx={{ marginTop: '10px', marginBottom: '10px' }} />

                                                                  <Box >
                                                                     <Stack direction="row" spacing={2}>
                                                                        <Divider />
                                                                        {prodMenu[index] && prodMenu[index].map((item, indexProd) =>
                                                                           <Stack
                                                                              direction="row" spacing={2}>
                                                                              <Chip label={item.nombre} onDelete={() => eliminarProductoItemMenu(index, indexProd)} color="warning" />
                                                                           </Stack>
                                                                        )}
                                                                     </Stack>
                                                                     <Divider sx={{ marginTop: '10px', marginBottom: '10px' }} />
                                                                  </Box>

                                                               </Box>
                                                            ))}
                                                         </Box>
                                                      </Table>
                                                   </TableContainer>

                                                </Box>
                                             }
                                             {
                                                (itemsMenu.length === 0 || addItem) && (
                                                   <>
                                                      <div>Selecciona los items que componen este producto: </div>
                                                      <Box sx={{ display: 'flex' }}>
                                                         <Box sx={{ marginRight: '20px' }}>
                                                            <TextField
                                                               inputProps={{ maxLength: 10 }}
                                                               fullWidth
                                                               label="Nombre"
                                                               id="outlined-size-small"
                                                               defaultValue={nombreMenu}
                                                               onChange={(e) => {
                                                                  handleChangeInputMenu(e)
                                                               }}
                                                               size="small"
                                                               style={{ marginTop: '20px' }}
                                                            ></TextField>
                                                         </Box>
                                                         <Box sx={{ marginTop: '20px' }}>
                                                            <TextField size="small" type="number" value={cantidadMenu} id="demo-helper-text-aligned" inputProps={{ min: "0", step: "1", onkeypress: "return (event.keyCode? (event.keyCode == 69 ? false : event.keyCode >= 48 && event.keyCode <= 57) : (event.charCode >= 48 && event.charCode <= 57))? true : event.preventDefault();" }}
                                                               onChange={(e) => handleChangeInputCantidad(e)}
                                                            />
                                                         </Box>

                                                         <Box >
                                                            <Button variant="outlined" style={{ marginTop: '20px', marginLeft: '20px' }}
                                                               onClick={() => {
                                                                  agregarItem();
                                                                  setAddItem(false);
                                                               }}
                                                            >Agregar</Button>
                                                         </Box>
                                                      </Box>
                                                   </>
                                                )
                                             }
                                             <Divider sx={{ marginTop: '5px', marginBottom: '5px' }} />
                                             <Box sx={{ marginTop: '30px' }}>
                                                <div className="form-group mb-3 buton_container_modal">
                                                   <button type="submit" className="btn btn-primary">Agregar Producto</button>
                                                </div>
                                             </Box>
                                          </div>
                                       }
                                    </div>
                                 </div>
                              }
                           </form>
                        </div>

                     </div>
                  </div>
               </div>
            }
            {cargarProd && popUp === "selectProductSofnet" && (
               <Popup
                  title='Seleccionar Producto'
                  largo="md"
                  openPopup={openPopup}
                  setOpenPopup={setOpenPopup}
                  closeModal={closeModal}
               >
                  <div>
                     <div className="H-card__fcontainer">
                        <Box sx={{ marginTop: '10px', marginBottom: '10px' }}>
                           <div className="H-card__fcontainer__filter">
                              <Box sx={{ marginRight: '30px' }} >
                                 <Button
                                    onClick={() => setCargarProd(false)}
                                    color="error"
                                    size="small"
                                    sx={{
                                       display: 'flex',
                                       justifyContent: 'flex-end',
                                    }}
                                 ><KeyboardBackspaceIcon />atrás</Button>
                              </Box>
                              <span>Filtro:</span>
                              <TextField
                                 type="text"
                                 // value={textSearch}
                                 placeholder="Buscar por código o nombre del producto"
                                 onChange={handleChangeSearch}
                                 classes={{
                                    root: "H-card__fcontainer__filter__input"
                                 }}
                                 sx={{ flexGrow: 1 }}
                              />
                           </div>
                        </Box>
                        <span className="H-card__fcontainer__infoTable">*Para agregar un producto, de "click" sobre el nombre.</span>
                     </div>
                     <TableContainer component={Paper}>
                        <Table sx={{ minWidth: 650 }} aria-label="simple table">
                           <TableHead>
                              <TableRow>
                                 <TableCell>{`N°`}</TableCell>
                                 <TableCell>Código - Nombre</TableCell>
                                 <TableCell align="right">Familia</TableCell>
                                 <TableCell align="right">Linea</TableCell>
                                 <TableCell align="right">Precio</TableCell>
                                 <TableCell align="right">Precio oferta&nbsp;</TableCell>
                                 <TableCell align="right">Stock&nbsp;</TableCell>
                              </TableRow>
                           </TableHead>
                           <TableBody>
                              {filter.map((products, index) => (
                                 <TableRow
                                    key={products.id}
                                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                    classes={{
                                       hover: 'hover-row-t'
                                    }}
                                 >
                                    <TableCell component="th" scope="row">
                                       {index + 1}
                                    </TableCell>
                                    <TableCell component="th" scope="row">
                                       <button className="H-card__tableButton" onClick={() => {
                                          if (!itemSelect) {
                                             setSeleccionado(products);
                                             setCargarProd(false);
                                             setSelect(true);
                                             tipo !== 'Agrupado' && setValues(prev => ({
                                                ...prev,
                                                codigo_erp: products.codigo_erp,
                                                precio_standar: products.precio_standar,
                                                precio_oferta: products.precio_oferta,
                                                id_unidad_medida: products.unidad_medida_id,
                                             }));

                                          } else {
                                             if (prodMenu[indexItemSelect]) {
                                                prodMenu[indexItemSelect].push(products);
                                                setProdMenu(prodMenu)
                                             } else {
                                                for (let i = 0; i <= indexItemSelect; i++) {

                                                   if (!prodMenu[indexItemSelect]) {
                                                      const array = [];
                                                      prodMenu.push(array);
                                                   }
                                                }
                                                prodMenu[indexItemSelect].push(products);
                                                setProdMenu(prodMenu)
                                             }
                                             // setProdMenu(prodMenu);
                                             setCargarProd(false);
                                          }

                                          if (tipo === 'Agrupado') {
                                             combo.push({
                                                ...products,
                                                cantidad: 1,
                                             })
                                          }
                                       }}>
                                          {products.codigo_erp} - {products.nombre}
                                       </button>
                                    </TableCell>
                                    <TableCell align="right">{products.familia || ''}</TableCell>
                                    <TableCell align="right">{products.linea || ''}</TableCell>
                                    <TableCell align="right">{products.precio_standar}</TableCell>
                                    <TableCell align="right">{products.precio_oferta || "--"}</TableCell>
                                    <TableCell align="right"><span className={products.stock < 1 ? 'X' : 'o'}>{products.stock < 1 ? 'SIN STOCK' : products.stock}</span></TableCell>
                                 </TableRow>
                              ))}
                           </TableBody>
                        </Table>
                     </TableContainer>
                  </div>
               </Popup>
            )
            }
            {
               <Box>
                  {
                     popUp === 'addNewProduct' && (
                        <Popup
                           largo="sm"
                           title='Agregar Producto'
                           openPopup={openPopup}
                           setOpenPopup={setOpenPopup}
                        >
                           <AddProductoS
                              closeModal={() => { setOpenPopup(false) }}
                              getProducts={getProducts}
                              editMode={editMode}
                              id_sub={idSubmodulo ? idSubmodulo : submodulo[0].id}
                              handleLoad={handleLoad}
                           />
                        </Popup>

                     )
                  }
               </Box>
            }

         </div>
      </>
   )
}
