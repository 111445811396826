import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { Divider } from '@mui/material';
import { AddProductoS } from '@/views/Productos/AddProductoS';
import { Popup } from '@/views/Editor/components/Popup';
import { Link } from 'react-router-dom';
import { ROUTE_EDITOR } from '@/toolbox/constants/route-map';

export const ModalConfirm: React.FC<any> = (props:any): JSX.Element => {
    const { open, closeModal, codigoBarras, getProducts, isHome} = props;

    const [openPopup, setOpenPopup] = React.useState<boolean>(false);
    const [popUp, setPopUp] = React.useState<String>('');
    const [editMode, setEditMode] = React.useState<boolean>(false);

    return (
        <div>
            <Dialog
                open={open}
                onClose={closeModal}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                {"Modal de Confirmación"}
                </DialogTitle>
                <Divider/>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                    {`No existe el producto con el código "${codigoBarras}".
                    ¿Desea agregarlo?`}
                    </DialogContentText>
                </DialogContent>
                <Divider/>
                <DialogActions>
                {
                    isHome &&
                     <Button  component={Link} to={ROUTE_EDITOR}>
                           Agregar ahora
                     </Button>
                }
                { !isHome &&
                  <Button onClick={() => {
                        setOpenPopup(true);
                        setPopUp('addProducto');
                        setEditMode(true);
                        closeModal && closeModal();
                  }}>
                        Agregar
                  </Button>
                }
                <Button onClick={closeModal}>Cancelar</Button>
                </DialogActions>
            </Dialog>
            {
                popUp === 'addProducto' && (
                    <Popup
                        largo="sm"
                        title = 'Agregar Producto'
                        openPopup = {openPopup}
                        setOpenPopup = {setOpenPopup}
                    >
                        <AddProductoS
                            closeModal={() => {setOpenPopup(false)}}
                            codigoBarras={codigoBarras}
                            getProducts={getProducts}
                            editMode = {editMode}
                        />

                    </Popup>
                )
            }
        </div>
    );
}
