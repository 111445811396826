import {
   ROUTE_HOME,
   ROUTE_CAJA,
   ROUTE_PEDIDOS,
   ROUTE_REPORTE,
   ROUTE_SALAS,
   ROUTE_PRODUCTOS,
   ROUTE_EMPLEADOS,
   ROUTE_EDITOR,
   ROUTE_CLIENTES,
   ROUTE_BENEFICIOS,
   ROUTE_USUARIOS,
   ROUTE_EMPRESAS,
   ROUTE_PROVEEDORES,
   ROUTE_COMPRAS,
   ROUTE_REPORTE_TABLE,
   ROUTE_NUEVA_COMPRA,
   ROUTE_CUADRATURAS,
   ROUTE_SUCURSALES,
   ROUTE_LISTA_PRECIO,
   ROUTE_PRODUCTOS_INSUMO,
   ROUTE_INVENTARIO,
  //  ecommerce
   ROUTE_SHOP_ECOMMERCE,
   ROUTE_SHOP_ECOMMERCE_COMPANIES,
   ROUTE_MIS_PEDIDOS_CLIENTE,
   ROUTE_MIS_COMPRAS_CLIENTE,
   ROUTE_PEDIDOS_PRODUCTOS,
   ROUTE_CLIENTE_ECOMMERCE,
   ROUTE_PRODUCTOS_ECOMMERCE,
   ROUTE_COCINAS,
   ROUTE_SHOP_RESERVE_TABLE,
   ROUTE_COMPANY_CONFIG,
   ROUTE_MASTER_CONFIG,
} from "../constants/route-map";

export const STATIC_ROUTERS = [
    {
      "name": "Inicio",
      "icon": "EvaluateIcon",
      "moduloId": 1,
      "route": ROUTE_HOME,
      "isMenu": true,
      "route-navigators": [],
    },
    {
      "name": "Caja",
      "icon": "EvaluateIcon",
      "moduloId": 2,
      "route": ROUTE_CAJA,
      "isMenu": true,
      "route-navigators": [
      ],
    },
    {
      "name": "Pedidos",
      "icon": "EvaluateIcon",
      "moduloId": 3,
      "route": ROUTE_PEDIDOS,
      "isMenu": true,
      "route-navigators": [
      ],
    },
    {
      "name": "Reporte",
      "icon": "EvaluateIcon",
      "moduloId": 4,
      "route": ROUTE_REPORTE,
      "isMenu": true,
      "route-navigators": [
      ],
    },
    {
      "name": "Productos",
      "icon": "EvaluateIcon",
      "moduloId": 6,
      "route": ROUTE_PRODUCTOS,
      "isMenu": true,
      "route-navigators": [
      ],
    },
    {
      "name": "EMPLEADOS",
      "icon": "EvaluateIcon",
      "moduloId": 7,
      "route": ROUTE_EMPLEADOS,
      "isMenu": true,
      "route-navigators": [
      ],
    },
    {
      "name": "Productos",
      "icon": "EvaluateIcon",
      "moduloId": 8,
      "route": ROUTE_EDITOR,
      "isMenu": true,
      "route-navigators": [
      ],
    },
    {
      "name": "Salas",
      "icon": "UserIcon",
      "moduloId": 5,
      "route": ROUTE_SALAS,
      "isMenu": true,
      "onClick":"handleProfileMenuOpen",
      "route-navigators": [
        // ROUTE_USERS_REGISTER,
      ],

    },
    {
      "name": "Empresas",
      "icon": "EvaluateIcon",
      "moduloId": 12,
      "route": ROUTE_EMPRESAS,
      "isMenu": true,
      "route-navigators": [
      ],
    },
    {
      "name": "Usuarios",
      "icon": "EvaluateIcon",
      "moduloId": 11,
      "route": ROUTE_USUARIOS,
      "isMenu": true,
      "route-navigators": [
      ],
    },
    {
      "name": "Clientes",
      "icon": "EvaluateIcon",
      "moduloId": 9,
      "route": ROUTE_CLIENTES,
      "isMenu": true,
      "route-navigators": [
      ],
    },
    {
      "name": "Beneficios",
      "icon": "EvaluateIcon",
      "moduloId": 10,
      "route": ROUTE_BENEFICIOS,
      "isMenu": true,
      "route-navigators": [
      ],
    },
    {
      "name": "Proveedores",
      "icon": "EvaluateIcon",
      "moduloId": 13,
      "route": ROUTE_PROVEEDORES,
      "isMenu": true,
      "route-navigators": [
      ],
    },
    {
      "name": "Compras",
      "icon": "EvaluateIcon",
      "moduloId": 14,
      "route": ROUTE_COMPRAS,
      "isMenu": true,
      "route-navigators": [
      ],
    },
    {
      "name": "ReporteTable",
      "icon": "EvaluateIcon",
      "moduloId": 15,
      "route": ROUTE_REPORTE_TABLE,
      "isMenu": true,
      "route-navigators": [
      ],
    },
    {
      "name": "NuevaCompra",
      "icon": "EvaluateIcon",
      "moduloId": 16,
      "route": ROUTE_NUEVA_COMPRA,
      "isMenu": true,
      "route-navigators": [
      ],
    },
    {
      "name": "cuadraturas",
      "icon": "EvaluateIcon",
      "moduloId": 17,
      "route": ROUTE_CUADRATURAS,
      "isMenu": true,
      "route-navigators": [
      ],
    },
    {
      "name": "Sucursales",
      "icon": "EvaluateIcon",
      "moduloId": 18,
      "route": ROUTE_SUCURSALES,
      "isMenu": true,
      "route-navigators": [
      ],
    },
    {
      "name": "Lista de Precios",
      "icon": "EvaluateIcon",
      "moduloId": 19,
      "route": ROUTE_LISTA_PRECIO,
      "isMenu": true,
      "route-navigators": [
      ],
    },
    {
      "name": "Insumos",
      "icon": "EvaluateIcon",
      "moduloId": 20,
      "route": ROUTE_PRODUCTOS_INSUMO,
      "isMenu": true,
      "route-navigators": [
      ],
    },
    {
      "name": "Inventario",
      "icon": "EvaluateIcon",
      "moduloId": 22,
      "route": ROUTE_INVENTARIO,
      "isMenu": true,
      "route-navigators": [
      ],
    },
    {
      "name": "Cocinas",
      "icon": "EvaluateIcon",
      "moduloId": 23,
      "route": ROUTE_COCINAS,
      "isMenu": true,
      "route-navigators": [
      ],
    },

    // ecommerce
    {
      "name": "shopEcommerce",
      "icon": "EvaluateIcon",
      "moduloId": 24,
      "route": ROUTE_SHOP_ECOMMERCE,
      "isMenu": true,
      "route-navigators": [
      ],
    },
    {
      "name": "MisPedidos",
      "icon": "EvaluateIcon",
      "moduloId": 25,
      "route": ROUTE_MIS_PEDIDOS_CLIENTE,
      "isMenu": true,
      "route-navigators": [
      ],
    },
    {
      "name": "MisCompras",
      "icon": "EvaluateIcon",
      "moduloId": 26,
      "route": ROUTE_MIS_COMPRAS_CLIENTE,
      "isMenu": true,
      "route-navigators": [
      ],
    },
    {
      "name": "ProductosPedidos",
      "icon": "EvaluateIcon",
      "moduloId": 27,
      "route": ROUTE_PEDIDOS_PRODUCTOS,
      "isMenu": true,
      "route-navigators": [
      ],
    },
    {
      "name": "ClienteEcommerce",
      "icon": "EvaluateIcon",
      "moduloId": 28,
      "route": ROUTE_CLIENTE_ECOMMERCE,
      "isMenu": true,
      "route-navigators": [
      ],
    },
    {
      "name": "ProductosEcommerce",
      "icon": "EvaluateIcon",
      "moduloId": 29,
      "route": ROUTE_PRODUCTOS_ECOMMERCE,
      "isMenu": true,
      "route-navigators": [
      ],
    },
    {
      "name": "shopEcommerceCompany",
      "icon": "EvaluateIcon",
      "moduloId": 30,
      "route": ROUTE_SHOP_ECOMMERCE_COMPANIES,
      "isMenu": true,
      "route-navigators": [
      ],
    },
    {
      "name": "shopEcommerceReserveTable",
      "icon": "EvaluateIcon",
      "moduloId": 31,
      "route": ROUTE_SHOP_RESERVE_TABLE,
      "isMenu": true,
      "route-navigators": [
      ],
    },

    //config
    {
      "name": "configuracion",
      "icon": "EvaluateIcon",
      "moduloId": 32,
      "route": ROUTE_COMPANY_CONFIG,
      "isMenu": true,
      "route-navigators": [
      ],
    },
    {
      "name": "masterConfiguracion",
      "icon": "EvaluateIcon",
      "moduloId": 33,
      "route": ROUTE_MASTER_CONFIG,
      "isMenu": true,
      "route-navigators": [
      ],
    },
]
