import { editor } from "@/service/services/Editor.service";
import React, { useState } from "react";
import  "./../AddCategoria/AddCategoria.sass";
import { Loading } from '../../../../components/common/Loading/Loading';
import { Form, Formik } from 'formik';
import { Divider, Grid, TextField, Typography } from '@mui/material';

export const AddSubCategoria: React.FC<any> = (props:any): JSX.Element => {
    const [loadData,setLoadData] = React.useState<boolean>(false);
    const {id_modulo, handleLoad}= props;
    // eslint-disable-next-line
    const [data, setData] = useState({
        nombre: '',
        submitted: false,
    });

    const validateForm = (values) => {
        let errors: any = {};

        if(!values.nombre) errors.nombre = "nombre requerido";
        return errors;
    }


    const onSubmit = async(values, {resetForm}) =>{
        const state = 1;
        setLoadData(true);
        await editor.addSubmodulo(values.nombre, state, id_modulo);
        // eslint-disable-next-line
        location.reload();
        return handleLoad();
    }

    return(
        <div className="container">
            {loadData && <Loading/>}
            <Formik enableReinitialize initialValues={data} validate={ (values) => validateForm(values) } onSubmit={onSubmit}>
            {({values, errors, touched, handleSubmit, handleChange, setFieldValue})=>{
                return(
                    <Form onSubmit={handleSubmit} style={{ width: '100%'}}>
                        <Grid container spacing={3} alignItems="center" justifyContent="center">

                        <Grid item xs={12} container style={{display: "flex", alignItems: "center"}}>
                            <Grid item xs={4}>
                                <Typography variant="subtitle1" className="custom-input"><b>Nombre:</b></Typography>
                            </Grid>
                            <Grid item xs={8}>
                                <TextField
                                id="nombre"
                                type="text"
                                name="nombre"
                                fullWidth
                                value={values.nombre}
                                onChange={handleChange}
                                placeholder="Nombre del submodulo"
                                error={errors.nombre && touched.nombre ? true : false}
                                />
                            </Grid>
                        </Grid>

                        </Grid>
                        <Divider sx={{ my: 2 }} />

                        <div className="form-group mb-3 buton_container_modal">
                            <button type="submit" className="btn btn-primary">
                                Agregar Submodulo
                            </button>
                        </div>

                    </Form>
                )
            }}
            </Formik>

        </div>
    );
}
