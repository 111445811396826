import axios from 'axios';
import { API_URL_NUPY } from '@/toolbox/defaults/app';

export const cajasRepository = {

    getCajas: async( ): Promise<any> =>{
        const res = await axios.get(`${API_URL_NUPY}/cajas/get_cajas`);
        return res.data;
    },

    getCajasByIdCompany: async( idCompany:number, idSucursal:number ): Promise<any> =>{
        const res = await axios.get(`${API_URL_NUPY}/cajas/getCajasByIdCompany/${idCompany}/${idSucursal}`);
        return res.data;
    },

    getCierreByCaja: async( idCaja ): Promise<any> =>{
        const res = await axios.get(`${API_URL_NUPY}/cajas/getEstadoCierre/${idCaja}`);
        return res.data;
    },

    addCaja: async( nombre: any, id_empresa: any, id_sucursal: number ): Promise<any> =>{
        const res = await axios.post(`${API_URL_NUPY}/cajas/crear_caja`, {nombre, id_empresa, id_sucursal});
        return res.data;
    },

    deleteCaja: async( id: any ): Promise<any> =>{
        const res = await axios.delete(`${API_URL_NUPY}/cajas/delete_caja/${id}`);
        return res;
    },

    editCaja: async( id: number , nombre: any, id_sucursal:any): Promise<any> =>{
        const res = await axios.put(`${API_URL_NUPY}/cajas/editar_caja/${id}`,{
            nombre,
            id_sucursal
        });
        return res;
    },


}
