import { Autocomplete, Box, Grid, Input, InputAdornment, MenuItem, Select, TextField } from "@mui/material";
import { useEffect, useState } from "react";
import { Props } from "./PagoCompra.type";
import { useLocalStorage } from '@toolbox/hooks/local-storage.hook';
import { KEY_EDIT_COMPRA, KEY_EMPRESA, PRODUCTS_CARD, KEY_EDIT_COMPRA_ID, KEY_EDIT_COMPRA_PROVEEDOR_ID } from "@/toolbox/constants/local-storage";
import { proveedorService } from "@/service/services/Proveedor.service";
import { styled } from '@mui/material/styles';
import { ROUTE_COMPRAS } from "@/toolbox/constants/route-map";
import Button from '@mui/material/Button';
import { Loading } from "@/components/common/Loading";
import { useHistory } from 'react-router-dom';
import { compraService } from "@/service/services/Compra.service";
import { Popup } from "@/views/Editor/components/Popup";
import { ModalProveedor } from "@/views/Proveedor/componentes/Modal";
import { moneyFormatInt } from "@/toolbox/helpers/money.helper";



const StyledButton = styled(Button)(({ theme }) => ({
    textTransform: 'none',
}));

export const PagoCompra: React.FC<Props> = (props: Props): JSX.Element => {

    const { productsCard, idsTarjetas } = props;

    const [dataEmpresa] = useLocalStorage(KEY_EMPRESA, {
        id: 0, id_tipo_empresa: 0, equivalencia_puntos: 0,
        id_country: 0, country_name: "", country_abbreviation: "",
    });

    const [loading, setLoading] = useState<boolean>(false);
    const [proveedores, setProveedores] = useState<Array<any>>([]);
    const [proveedorSelect, setProveedorSelect] = useState<any>("");
    const [documento, setDocumento] = useState<any>(33);

    const [totalCard, setTotalCard] = useState<number>(0);
    const [folio, setFolio] = useState<number>(0);
    // eslint-disable-next-line
    const [IVA, setIVA] = useState<number>(
        // (dataEmpresa.country_name === "Perú") ? 18 : (dataEmpresa.country_name === "Chile") ? 19 : 16
        19
    );
    const [arrayIdsProducto, setArrayIdsProducto] = useState<any>([]);

    const [editCompra] = useLocalStorage(KEY_EDIT_COMPRA, []);
    const [editCompraID] = useLocalStorage(KEY_EDIT_COMPRA_ID, 0);
    const [editCompraProvID] = useLocalStorage(KEY_EDIT_COMPRA_PROVEEDOR_ID, {
        key: 0,
        label: "--",
        folio: 0
    });
    const [openPopup, setOpenPopup] = useState<boolean>(false);
    const [popUp, setPopUp] = useState<String>('');
    const history = useHistory();

    useEffect(() => {
        getProveedoresByCompany();
        if (editCompraProvID.key) {
            setProveedorSelect(editCompraProvID)
            setFolio(editCompraProvID.folio)
        }
    }, // eslint-disable-next-line
        [])

    useEffect(() => {
        let total: number = 0;
        for (let i = 0; i < productsCard.length; i++) {
            if (productsCard[i].precio_unidad && productsCard[i].cantidad) {
                total = (total + ((productsCard[i].precio_unidad) * (productsCard[i].cantidad)))
            }
        }
        setTotalCard(total);
    }, [productsCard])

    const getProveedoresByCompany = async () => {
        setLoading(true);
        await proveedorService.getProveedoresByCompany(dataEmpresa.id).then((data) => {
            setProveedores(data.map((el) => {
                return { label: el.razon_social, key: el.id, rut: el.rut };
            }));
            setLoading(false);
        });
    }

    const getFolio = (e) => {
        setFolio(e.target.value);
    }

    const handleSubmit = async (type: "edit" | "create") => {
        arrayIdsProducto.length = 0;
        setArrayIdsProducto(arrayIdsProducto);
        let validate = 0;
        if (!folio) {
            validate = 4;
        }
        setLoading(true);
        if (proveedorSelect === "") {
            setLoading(false);
            return alert('debe seleccionar un proveedor');
        }
        let detalleSofnet = [];
        for (let i = 0; i < productsCard.length; i++) {
            if (!productsCard[i].cantidad || !productsCard[i].precio_unidad) {
                validate = 1;
            } else if (productsCard[i].cantidad < 1 || productsCard[i].precio_unidad < 1) {
                validate = 2;
            } else if (productsCard[i].stock_minimo - productsCard[i].stock > productsCard[i].cantidad) {
                validate = 3;
            }
            if (productsCard[i].precio_unidad) {
                arrayIdsProducto.push(productsCard[i].id);
                setArrayIdsProducto(arrayIdsProducto);
            }
            if (productsCard.length > 0) {
                detalleSofnet.push({
                    "Codigo": productsCard[i].codigo_erp,
                    "Cantidad": productsCard[i].cantidad,
                    "Lote": "1234",
                    "TipoDescuento": productsCard[i].tipoDescuento ? productsCard[i].tipoDescuento : "1",
                    "ValorDescuento": productsCard[i].valorDescuento ? productsCard[i].valorDescuento : "0",
                    "FechaVencimiento": "2015-01-01",
                    "Costo": Number(productsCard[i].precio_unidad)
                })
            }
        }
        if (validate === 0) {
            if (type === "create") {
                await compraService.register(folio, IVA, proveedorSelect.key, productsCard, arrayIdsProducto, documento, detalleSofnet, proveedorSelect.rut, idsTarjetas).then((data) => {
                    setLoading(false);
                    localStorage.removeItem(KEY_EDIT_COMPRA);
                    localStorage.removeItem(PRODUCTS_CARD);
                    localStorage.removeItem(KEY_EDIT_COMPRA_ID);
                    localStorage.removeItem(KEY_EDIT_COMPRA_PROVEEDOR_ID);
                })
            } else {
                await compraService.update(editCompraID, folio, IVA, proveedorSelect.key, productsCard, arrayIdsProducto).then((data) => {
                    setLoading(false);
                    localStorage.removeItem(KEY_EDIT_COMPRA);
                    localStorage.removeItem(PRODUCTS_CARD);
                    localStorage.removeItem(KEY_EDIT_COMPRA_ID);
                    localStorage.removeItem(KEY_EDIT_COMPRA_PROVEEDOR_ID);
                })
            }
            history.push(ROUTE_COMPRAS);
        } else {
            switch (validate) {
                case 1:
                    alert("Ingrese los campos requeridos en todos los productos");
                    break;
                case 2:
                    alert("Tanto la cantidad como el precio unitario deben ser mayor a cero en todos los productos");
                    break;
                case 3:
                    alert("La cantidad minima que puede comprar es la inficada en cada producto")
                    break;
                case 4:
                    alert("Ingrese un folio correspondiente a la compra")
                    break;

            }
        }
        setLoading(false);
    }

    return (
        <Box sx={{ padding: 2 }}>
            {loading && <Loading />}
            <form>
                <Grid container spacing={2}>
                    <Grid item xs={12} style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                        <Grid item xs={7}>
                            <Autocomplete
                                disablePortal
                                id="combo-box-demo"
                                options={proveedores}
                                sx={{ width: '100%' }}
                                renderInput={(params) => <TextField {...params} label="Seleccione un Proveedor" />}
                                value={proveedorSelect}
                                onChange={(e, newValue) => {
                                    setProveedorSelect(newValue);
                                }}
                                size="small"
                            />
                        </Grid>
                        <Grid item xs={4}>
                            <Button variant='contained' color="primary" size="small" style={{ textTransform: "capitalize" }}
                                onClick={() => { setOpenPopup(true); setPopUp('addProveedor') }} sx={{ fontSize: "14px !important" }}
                            >Agregar Proveedor
                            </Button>
                        </Grid>
                    </Grid>

                    <Grid item xs={12} style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}></Grid>

                    <Grid item xs={6} spacing={2} style={{ padding: '12px' }} justifyContent="flex-end" >
                        <strong>Folio: &nbsp;</strong>
                        <Input
                            size="small" id="cliente"
                            name="folio" type="string"
                            value={folio}
                            onChange={getFolio}
                        />
                    </Grid>

                    {/* <Grid item xs={6} spacing={2} style={{ padding: '12px' }} justifyContent="flex-end" >
                        <strong>IVA: &nbsp;</strong>
                        <Input
                            size="small" id="cliente"
                            name="IVA" type="string"
                            value={IVA}
                        />
                    </Grid> */}

                    <Grid item xs={6} spacing={2} style={{ padding: '12px', display: 'flex', alignItems: 'center' }}>
                        <strong style={{ marginRight: '5px' }}>Documento: </strong>
                        <Select
                            id="filtro"
                            value={documento}
                            onChange={(event) => {
                                setDocumento(event.target.value);
                            }}
                            fullWidth
                            size="small"
                        >
                            <MenuItem value={33} onClick={() => { }}>
                                Factura
                            </MenuItem>
                            <MenuItem value={39} onClick={() => { }}>
                                Boleta
                            </MenuItem>
                            <MenuItem value={52} onClick={() => { }}>
                                Guía de despacho
                            </MenuItem>
                            <MenuItem value={500} onClick={() => { }}>
                                Guía de ajuste
                            </MenuItem>
                        </Select>
                    </Grid>

                    <Grid item xs={12} spacing={2} style={{ padding: '12px' }} justifyContent="flex-end" >

                        <strong>Total a pagar: &nbsp;</strong>
                        <span> {dataEmpresa.country_abbreviation} {moneyFormatInt(totalCard)}</span>
                    </Grid>

                    <Grid item xs={12}>
                        <StyledButton fullWidth
                            variant="contained" color='success'
                            size='small'
                            onClick={Object.keys(editCompra).length > 0 ? () => handleSubmit("edit") : () => handleSubmit("create")}
                            sx={{ mt: "10px" }}
                        >
                            {
                                Object.keys(editCompra).length > 0 ? 'Editar Compra' : 'Realizar Compra'
                            }
                        </StyledButton>

                    </Grid>

                </Grid>
            </form>
            {
                popUp === 'addProveedor' && (
                    <Popup
                        largo="sm"
                        title='Agregar Proveedor'
                        openPopup={openPopup}
                        setOpenPopup={setOpenPopup}
                    >
                        <ModalProveedor
                            closeModal={() => { setOpenPopup(false) }}
                            onListProveedor={getProveedoresByCompany}
                            proveedorSelected={setProveedorSelect}
                            editMode={true}
                        />

                    </Popup>
                )
            }
        </Box>
    );
}
