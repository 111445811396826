import moment from 'moment';
import { compraRepository } from '../repositories/Compra.repository';
import { compraDetalle } from '../repositories/CompraDetalle.repository';
import { KEY_EMPRESA} from '@constants/local-storage';
import {readLocalStorage} from '@helpers/local-storage-helper';
import { tarjetaExistenciaService } from './TarjetaExistencia.service';

export const compraService = {
    register,
    update,
    listCompras,
    listByCompra,
    getValoracion,
    receivedAt,
}

async function register (folio:number, IVA:number, id_proveedor:number, products: Array<any>, arrayIdsProducto:Array<any>, documento, detalleSofnet, rut_proveedor, ids_tarjetas?: number[]){
   const data: any = readLocalStorage(KEY_EMPRESA);
    let fecha = moment(new Date()).format('DD-MM-YYYY');

    const res = await compraRepository.saveCompra(folio, fecha, IVA, 1, id_proveedor, data.id, documento, detalleSofnet, rut_proveedor);

    if(res?.compraID){
        for(let i = 0; i < products.length; i++){
            const element = products[i];
            await compraDetalle.saveCompraDetalle(element.codigo_erp, element.cantidad, element.precio_unidad, (element.tipoDescuento ? element.tipoDescuento : 1), (element.valorDescuento ? element.valorDescuento : 0), arrayIdsProducto[i], res.compraID);
        }
        console.log('ids_tarjetas.length', ids_tarjetas.length)
        if(!!ids_tarjetas.length) await tarjetaExistenciaService.createDet({ detalles: ids_tarjetas.map(item => { return { idcompra: res?.compraID, idtarjeta_existencia: item } }) })
    }
}

async function update (idCompra: number, folio:number, IVA:number, id_proveedor:number, products: Array<any>, arrayIdsProducto:Array<any>){

   let fecha = moment(new Date()).format('DD-MM-YYYY');

   const res = await compraRepository.update(idCompra, folio, fecha, IVA, 1, id_proveedor);


   if(res?.compraID){
       for(let i = 0; i < products.length; i++){
           const element = products[i];
           await compraDetalle.saveCompraDetalle(element?.codigo_erp, element.cantidad, element.precio_unidad, (element.tipoDescuento ? element.tipoDescuento : 1), (element.valorDescuento ? element.valorDescuento : 0), arrayIdsProducto[i], res.compraID);
       }
   }
}

async function receivedAt (idCompra: number){

   let fecha = moment(new Date()).format('DD-MM-YYYY');

   const res = await compraRepository.receivedAt(idCompra, fecha);

   return res;

}

async function listCompras () {
    const res = await compraRepository.listCompras();
    return res;
}

async function listByCompra (idCompra: number) {
    const res = await compraDetalle.listByCompra(idCompra);
    return res;
}

async function getValoracion (idEmpresa: number) {
   const res = await compraRepository.getValoracion(idEmpresa);
   return res;
}
