import { Protected } from "@/components/layout/Protected"
import { Grid, IconButton, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, Tooltip, Typography } from "@mui/material"
import { FC, useState } from "react"
import VisibilityIcon from '@mui/icons-material/Visibility';
import { useHistory, useLocation } from 'react-router-dom';
import ArrowCircleLeftIcon from '@mui/icons-material/ArrowCircleLeft';


export const ReporteVentasVentas: FC<any> = () => {

    const location: any = useLocation();
    const ventas = (location.state && location.state.data) ? JSON.parse(location.state?.data) : []
    const history = useHistory();

    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(20);

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const showPDF = (url: string) => {
        if (url === '') return
        window.open(url, '_blank')
    }

    const goBack = () => {
        history.push('/reporte-venta');
    };

    return (<Protected>
        <Grid item container xs={12}>
            <Grid item xs={12} display='flex' alignItems='center'>
                <Grid className="btn-back-container" onClick={goBack} sx={{ cursor: 'pointer', border: '1px solid #188dcd', borderRadius: '4px', padding: '8px', display: 'flex', alignItems: 'center' }}>
                    <ArrowCircleLeftIcon fontSize='small' sx={{ color: '#188dcd', marginRight: '2px' }} />
                    <Typography className='btn-back-text'>Volver</Typography>
                </Grid>
            </Grid>
            <TableContainer component={Paper}>
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            <TableCell>{`N°`}</TableCell>
                            <TableCell align="left">Folio</TableCell>
                            <TableCell align="left">Fecha</TableCell>
                            <TableCell align="left">Tipo</TableCell>
                            <TableCell align="left">Monto neto</TableCell>
                            <TableCell align="left">Monto total</TableCell>
                            <TableCell align="left">Monto exento</TableCell>
                            <TableCell align="left">Total pagado</TableCell>
                            <TableCell align="left">Responsable</TableCell>
                            <TableCell align="left">Caja</TableCell>
                            <TableCell align="center">Detalle</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {ventas?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((venta, index) =>
                            <TableRow>
                                <TableCell align="left">{index + 1}</TableCell>
                                <TableCell align="left">{venta.folio}</TableCell>
                                <TableCell align="left">{venta.fecha}</TableCell>
                                <TableCell align="left">{venta.tipo_doc_nombre}</TableCell>
                                <TableCell align="left">{venta.monto_neto}</TableCell>
                                <TableCell align="left">{venta.monto_total}</TableCell>
                                <TableCell align="left">{venta.exento}</TableCell>
                                <TableCell align="left">{venta.monto_pagado}</TableCell>
                                <TableCell align="left">{venta.responsable}</TableCell>
                                <TableCell align="left">{venta.caja}</TableCell>
                                <TableCell align="center">
                                    <Tooltip title={"Ver detalle"}>
                                        <IconButton onClick={() => showPDF(venta.pdf_softnet)}>
                                            <VisibilityIcon />
                                        </IconButton>
                                    </Tooltip>
                                </TableCell>
                            </TableRow>
                        )}
                    </TableBody>
                </Table>
            </TableContainer>
            <TablePagination
                rowsPerPageOptions={[20, 30, 40]}
                component="div"
                count={ventas?.length || 0}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
            />
        </Grid>
    </Protected>)
}