import React, { useState} from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import MenuItem from '@mui/material/MenuItem';
import { useLocalStorage } from '@toolbox/hooks/local-storage.hook';
import Menu from '@mui/material/Menu';
import {  Button, Divider, Grid, useMediaQuery, useTheme} from '@mui/material';
import Fade from '@mui/material/Fade';
import { Link } from 'react-router-dom';
import { ROUTE_HOME , ROUTE_REPORTE, ROUTE_CAJA, ROUTE_PEDIDOS, ROUTE_SALAS, ROUTE_EMPLEADOS, ROUTE_EDITOR, ROUTE_CLIENTES, ROUTE_BENEFICIOS, ROUTE_USUARIOS, ROUTE_EMPRESAS, ROUTE_PROVEEDORES, ROUTE_COMPRAS, ROUTE_SUCURSALES, ROUTE_INVENTARIO, ROUTE_LISTA_PRECIO, ROUTE_MIS_PEDIDOS_CLIENTE, ROUTE_MIS_COMPRAS_CLIENTE, ROUTE_COCINAS} from '@/toolbox/constants/route-map';
import { DrawerComponent } from './Drawer';
import { readLocalStorage, saveLocalStorage } from '@/toolbox/helpers/local-storage-helper';
import { KEY_BODEGAS, KEY_BODEGA_SELECT, KEY_ARRAY_MY_MENU, KEY_USER_DATA } from '@/toolbox/constants/local-storage';
import { styled } from '@mui/material/styles';
import { authenticationService } from '@/service/services/Authentication.service';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import ReceiptLongIcon from '@mui/icons-material/ReceiptLong';
import PointOfSaleIcon from '@mui/icons-material/PointOfSale';
import EqualizerIcon from '@mui/icons-material/Equalizer';
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import { KEY_EMPRESA} from "@/toolbox/constants/local-storage";
import LogoutIcon from '@mui/icons-material/Logout';
import { red } from '@mui/material/colors';
import { clientesService } from '@/service/services/Clientes.service';
import { ModalChangePassword } from '../ModalChangePassword';
import { Popup } from '@/views/Editor/components/Popup';

import MenuIcon from '@mui/icons-material/Menu';
import { DrawerLateral } from './DrawerLateral';
import { ROLE_CAJERO, ROLE_VENDEDOR } from '@/toolbox/defaults/static-roles';


type Props = {
   // setShowDrawer?: any
}

const ColorButton = styled(Button)(({ theme }) => ({
   color: theme.palette.getContrastText(red[500]),
   textTransform: "capitalize",
   fontSize: "18px !important",
   fontWeight: "normal",
   padding: "5px 10px",
   backgroundColor: red[500],
   '&:hover': {
     backgroundColor: red[700],
   },
 }));

export const Header: React.FC<Props> = (
   props: Props
): JSX.Element => {
   const [menuUser] = useLocalStorage(KEY_ARRAY_MY_MENU, []);
   const [anchorEl, setAnchorEl] = React.useState(null);
   const [value, setValue] = useState<any>("");
   const [showBtnClient, setShowBtnClient] = React.useState<boolean>(false);
   const [showModal, setShowModal] = React.useState<boolean>(false);
   const isMenuOpen = Boolean(anchorEl);
    // eslint-disable-next-line
   const [bodegas,setBodegas] = useState<Array<any>>([]);
   const [dataEmpresa] = useLocalStorage(KEY_EMPRESA, {mesas: 0, id_tipo_empresa: 0});

   const [showDrawer, setShowDrawer] = useState<Boolean>(false);

   const dataUsuario = readLocalStorage(KEY_USER_DATA);
   const rutUser = dataUsuario.user.rut_usuario;
   const role = dataUsuario.user.rol;

   const handleProfileMenuOpen = (event) => {
      setAnchorEl(event.currentTarget);
   };
   const handleMenuClose = () => {
      setAnchorEl(null);
   };

   const existClienteUsuario = async() => {
      await clientesService.getClienteByRut(rutUser).then((data)=>{

         if (data.status === 200) {
            setShowBtnClient(true);
         }
      });
   }

   React.useEffect(() => {
      const ver = readLocalStorage(KEY_BODEGA_SELECT);
      setBodegas(readLocalStorage(KEY_BODEGAS));
      setValue(ver);
   },// eslint-disable-next-line
   []);

   React.useEffect(() => {
      saveLocalStorage(KEY_BODEGA_SELECT, value);
   },
   [value]);

   //MENU- ICONO USUARIO
   const renderMenu = (
      <Menu
         id="fade-menu"
         MenuListProps={{
            'aria-labelledby': 'fade-button',
         }}
         anchorEl={anchorEl}
         open={isMenuOpen}
         onClose={handleMenuClose}
         TransitionComponent={Fade}
      >
         <MenuItem onClick={()=>setShowModal(true)} >
            Cambiar contraseña
         </MenuItem>
         <Divider></Divider>
         <MenuItem onClick={()=>handleLogout()} >
            {/* <ColorButton variant="contained"> salir&nbsp;&nbsp;<LogoutIcon style={{width: "18px", height: "19px"}}/> </ColorButton> */}
            Cerrar sesión
         </MenuItem>
      </Menu>
   );

   const theme = useTheme();
   const isMobile = useMediaQuery(theme.breakpoints.down("md"));

   const handleLogout = () => {
      authenticationService.logout().then(res => {
         if (!res.error) {
            window.location.replace('/');
         }
      })
   }

   const redirectRoute = () => {
      // let route
      // if (dataUsuario.user.rol === ROLE_CAJERO) {
      //    route = ROUTE_CAJA
      // } else {
      //    route = ROUTE_HOME
      // }
      // return route
      return ROUTE_HOME
   }

   return (
      <div>
         <Box sx={{ flexGrow: 1, padding: 4, position:'relative' }}>
            <AppBar>
               <Toolbar sx={{ flexGrow: 1, display: 'flex', justifyContent: 'space-between', bgcolor:'#176ECA' }}>

                  <Grid sx={{display: 'flex', }}>

                     <Button color="inherit" onClick={()=> {setShowDrawer(true)}}>
                        <MenuIcon/>
                     </Button>

                     <Button color="inherit" 
                     component={Link} to={redirectRoute}
                     // onClick={() => console.log('dataUsuario', dataUsuario)}
                     >
                        <Typography
                           variant="h6"

                           component="div"
                           sx={{ display: { xs: 'block', sm: 'block' } }}
                        >
                           NUPY
                        </Typography>
                     </Button>
                  </Grid>

                  {
                     showDrawer && (<DrawerLateral showDrawer={showDrawer} setShowDrawer={setShowDrawer}/>)
                  }
                  <Box sx={{ flexGrow: 1, display: 'flex', justifyContent: 'flex-end' }} >
                     <Box sx={{ display: { xs: 'block', md: 'flex' } }}>
                     { !isMobile && 
                        (menuUser || []).map((item, index) => {
                           if (item?.route === ROUTE_CAJA && role !== ROLE_VENDEDOR) {
                              return (
                                 <Button variant='contained' title="Caja"  sx={{bgcolor:'#229954',ml:1,textTransform:'none'}} component={Link} to={ROUTE_CAJA}>
                                    <PointOfSaleIcon/> &nbsp;{item?.name}
                                 </Button>
                              )
                           } else if (item?.route === ROUTE_PEDIDOS) {
                              return (
                                 <Button variant='contained' title="Pedidos" sx={{bgcolor:'#9500ae',ml:1,textTransform:'none'}} component={Link} to={ROUTE_PEDIDOS}>
                                    <ReceiptLongIcon/> &nbsp;{item?.name}
                                 </Button>
                              )
                           } else if (item?.route === ROUTE_MIS_PEDIDOS_CLIENTE && showBtnClient) {
                              return (
                                 <Button variant='contained' title="Productos" sx={{bgcolor:'red',ml:1,textTransform:'none'}} component={Link} to={ROUTE_MIS_PEDIDOS_CLIENTE}>
                                    <ManageAccountsIcon/> &nbsp;{item?.name}
                                 </Button>
                              )
                           } else if (item?.route === ROUTE_MIS_COMPRAS_CLIENTE && showBtnClient) {
                              return (
                                 <Button variant='contained' title="Productos" sx={{bgcolor:'red',ml:1,textTransform:'none'}} component={Link} to={ROUTE_MIS_COMPRAS_CLIENTE}>
                                    <ManageAccountsIcon/> &nbsp;{item?.name}
                                 </Button>
                              )
                           }

                        })
                     }
                        <Grid sx={{marginLeft:'25px', display: 'flex', alignItems: 'center'}}>
                           <Grid>
                              <div style={{textAlign:'end'}}>{dataUsuario?.user?.usuario}</div>
                              <div style={{textAlign:'end'}}><span style={{fontSize:'12px'}}>{dataUsuario?.user?.rol}</span></div>
                           </Grid>
                           <Button color='inherit' onClick={handleProfileMenuOpen}>
                              <AccountCircleIcon fontSize='large'/>
                           </Button>
                        </Grid>
                        
                     </Box>
                  </Box>
               </Toolbar>
            </AppBar>
            {renderMenu}
         </Box>

         {
            showModal && (
               <Popup
                  largo="sm"
                  title = 'Cambiar contraseña'
                  openPopup = {showModal}
                  setOpenPopup = {setShowModal}
               >
                  <ModalChangePassword
                        closeModal={() => {setShowModal(false)}}
                        handleSessionClose = {handleLogout}
                  />

               </Popup>
            )
         }
      </div>
   );
}
