import { Props } from "./CompaniesEcommerce.type";
import { Card, CardContent, CardMedia, Container, Grid, Typography } from "@mui/material";
import '../Categories/CategoryProducts_style.css'
import { HeaderEcommerce } from "../Header/HeaderEcommerce";
import { useEffect, useState } from "react";
import { empresasService } from "@/service/services/Empresas.service";
import { Loading } from "@/components/common/Loading";
import { readLocalStorage, saveLocalStorage } from "@/toolbox/helpers/local-storage-helper";
import { CLIENTE_SHOP, KEY_EMPRESA, KEY_USER_DATA } from "@/toolbox/constants/local-storage";
import { userService } from "@/service/services/User.service";
import { Popup } from "@/views/Editor/components/Popup";
import { LoginModal } from "../LoginModal";

export const CompaniesEcommerce: React.FC<Props> = (props:Props):JSX.Element => {
    const [loading, setLoading] = useState<boolean>(false);
    const [sucursales, setSucursales] = useState([]);
    const [selectedCompany, setSelectedCompany] = useState(0);
    const [showModalLogin, setShowModalLogin] = useState<boolean>(false);

    const idService = props?.location?.state?.idService;

    //hacemos una busqueda del servicio (name)

    const dataUsuario = readLocalStorage(KEY_USER_DATA);

    useEffect(()=> {
        getSucursales();
     }, []);

    //listado de sucursales - de empresas por idService
    const getSucursales = async() => {
        setLoading(true);
        if(idService){
            await empresasService.getEmpresasByService(idService).then((data) => {
                setSucursales(data || []);
            });
        }else{
            await empresasService.getEmpresasAvailable().then((data) => {
                setSucursales(data || []);
            });
        }
        setLoading(false);
    }

    const validateLogin = async(sucursal) => {
        if(dataUsuario){
            const user = dataUsuario?.user;
            const dataUser = {
                id_user: user.id_usuario,
                nombre_user: user.nombre,
                rut_user: user.rut_usuario,
                id_sucursal: sucursal?.id,
                id_empresa: sucursal?.empresa?.id,
            }
            setLoading(true);
            await userService.validateDetalleClienteUser(dataUser).then((data) => {
                if(data.status === 200){
                    if(idService && idService == 1){
                        window.location.replace(`/shop-ecommerce/reserve-table?company=${sucursal?.empresa?.id}&sucursal=${sucursal?.id}`);
                    }else{
                        window.location.replace("/productos-ecommerce");
                    }
                    saveLocalStorage(CLIENTE_SHOP, data?.clienteID);
                    saveLocalStorage(KEY_EMPRESA, {
                        "id": sucursal?.empresa?.id,
                        "id_tipo_empresa": sucursal?.empresa?.id_tipo_empresa,
                        "nombre": sucursal?.empresa?.name,
                        "equivalencia_puntos": sucursal?.empresa?.equivalencia_puntos,
                        "id_country": sucursal?.empresa?.id_country,
                        "country_name": sucursal?.empresa?.country?.nombre,
                        "country_abbreviation": sucursal?.empresa?.country?.abbreviation,
                        "mesas": sucursal?.empresa?.mesas,
                     });
                }
            });
            setLoading(false);
        }else{
            setShowModalLogin(true);
        }
    }

    return (
        <Grid>
            {loading && <Loading/>}
            <Grid container sx={{ marginBottom: '25px', alignContent: 'center'}}>

                <HeaderEcommerce/>

                <Grid item xs={12} textAlign='center' mb={2}>
                    <Typography variant="h2" gutterBottom mb={3} className="textTitle">
                    ¡Encuentra aquí todo lo que necesitas!
                    </Typography>
                    <Typography variant="subtitle2" style={{ color: '#3F3F3F' }}>
                    Para empezar, selecciona tu proveedor preferido y encuentra tu producto
                    </Typography>
                </Grid>

                <Grid container item xs={12} sx={{ display: 'flex', margin: 'auto' }}>
                    {
                        sucursales.length > 0 && sucursales.map((sucursal, i) => (
                            <Grid item xs={12} sm={6} md={4} lg={3} p={2} key={i} onClick = {() => {
                                setSelectedCompany(sucursal);
                                validateLogin(sucursal);
                                }}>
                                <Card key={i} className={"card-container"} sx={{ borderRadius: '25px' }}>
                                    <CardMedia
                                        component="img"
                                        // image={company.imagen}
                                        image= {"https://images.rappi.cl/restaurants_background/crudosin-1662003183359.jpg?e=webp&q=80&d=400x400"}
                                        alt="logo"
                                        sx={{ height: '150px', margin: 'auto', background: "#82A8F3", boxSizing: "border-box"}}
                                    />
                                    <CardContent sx={{ height: '100px', padding: '20px', boxSizing: "border-box" }} >
                                        <Typography sx={{ height: '100%' }}  className="card-container-name" textAlign={'center'}>
                                            {sucursal.nombre}
                                        </Typography>
                                        <Typography className="card-container-name" textAlign={'center'}>
                                            {sucursal.direccion}
                                        </Typography>
                                    </CardContent>
                                </Card>
                            </Grid>
                        ))
                    }
                </Grid>

            </Grid>
            {
                showModalLogin && (
                    <Popup
                        largo="sm"
                        title = 'LOGIN SHOP'
                        openPopup = {showModalLogin}
                        setOpenPopup = {setShowModalLogin}
                        closeModal = {()=> {
                           setShowModalLogin(false);
                           setSelectedCompany(0);
                        }}
                    >
                        <LoginModal
                            closeModal = {() => {
                              setShowModalLogin(false);
                              setSelectedCompany(0);
                           }}
                           selectedCompany={selectedCompany}
                        />

                    </Popup>
                )
            }
        </Grid>
    )
}