import React, { FunctionComponent, useEffect, useState, } from 'react';
import { Protected } from '@/components/layout/Protected';
import { Loading } from '@/components/common/Loading';
import { Alert, Box, Button, Grid, IconButton, InputAdornment, Paper, Snackbar, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, TextField, Tooltip, Typography } from '@mui/material';
import { DatePicker, LocalizationProvider } from '@mui/lab';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import esLocale from 'date-fns/locale/es';
import { productoService } from '@/service/services/Producto.service';
import { readLocalStorage } from '@/toolbox/helpers/local-storage-helper';
import { KEY_EMPRESA, KEY_USER_DATA } from '@/toolbox/constants/local-storage';
import SearchIcon from "@mui/icons-material/Search";
import TagIcon from '@mui/icons-material/Tag';
import WarningIcon from '@mui/icons-material/Warning';
import SouthIcon from '@mui/icons-material/South';
import ArrowCircleUpIcon from '@mui/icons-material/ArrowCircleUp';
import Inventory2Icon from '@mui/icons-material/Inventory2';
import LocalOfferIcon from '@mui/icons-material/LocalOffer';
import TableRestaurantIcon from '@mui/icons-material/TableRestaurant';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import { tarjetaExistenciaService } from '@/service/services/TarjetaExistencia.service';
import moment from 'moment';

export const TarjetaExistencia: FunctionComponent = (props: any) => {

    const dataUsuario = readLocalStorage(KEY_USER_DATA)
    const sessionCompany: any = readLocalStorage(KEY_EMPRESA);
    const idCompany = sessionCompany.id;

    const [snackBarConfig, setSnackBarConfig] = React.useState<any>({
        open: false,
        severity: 'error',
        message: 'Error',
        autoHideDuration: 3000,
    });

    const [loading, setLoading] = React.useState<boolean>(false);
    const [fecha, setFecha] = React.useState(new Date());
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(20);
    const [productos, setProductos] = useState<any[]>([])
    const [productosBackUp, setProductosBackUp] = useState<any[]>([])
    const [data, setData] = useState<any[]>([])
    const [dataEdit, setDataEdit] = useState<any[]>([])

    const [viewEdit, setViewEdit] = useState<boolean>(false);
    const [isViewClosed, setIsViewClosed] = useState<boolean>(false);

    // Estado para manejar la edición de los diferentes campos de los productos
    const [editableFields, setEditableFields] = useState({});

    // Estado para manejar los valores editados de los diferentes campos de los productos
    const [editedValues, setEditedValues] = useState({});

    // Función para activar la edición de un campo específico de un producto
    const handleFieldClick = (productId, fieldName) => {
       
        setEditableFields({
            ...editableFields,
            [productId]: {
                ...editableFields[productId],
                [fieldName]: true,
            },
        });
        
    };

    // Función para manejar el cambio de valor de un campo específico de un producto
    const handleFieldChange = (event, productId, fieldName) => {
        const newValue = event.target.value;
        setEditedValues((prevEditedValues) => ({
            ...prevEditedValues,
            [productId]: {
                ...prevEditedValues[productId],
                [fieldName]: newValue,
            },
        }));
    };

    // Función para desactivar la edición de un campo específico de un producto
    const handleFieldBlur = (productId, fieldName) => {
        setEditableFields({
            ...editableFields,
            [productId]: {
                ...editableFields[productId],
                [fieldName]: false,
            },
        });
    };

    useEffect(() => {
        getProductosApi()
        getExistencia()
    }, [])

    useEffect(()=>{
        getExistencia()
    },[fecha])

    useEffect(()=>{
        if(viewEdit && dataEdit.length > 0){
            dataEdit.map(producto => {
                console.log(producto)
                setEditedValues((prevEditedValues) => ({
                    ...prevEditedValues,
                    [producto.id]: {
                        ...prevEditedValues[producto.id],
                        ["stock_real"]: producto.stock_sistema
                    },
                }));
            })
        }

    },[productos, isViewClosed, viewEdit])

    const getExistencia = async() => {
        setLoading(true)
        const fechaM = moment(fecha).format('YYYY-MM-DD');
        const hoy = moment().startOf('day');
        
        await tarjetaExistenciaService.getTarjetaExistencia(fechaM,idCompany).then((res)=> {
            if(res.status == 200){
                if(res.data.length > 0){
                    setData(res.data)
                    setViewEdit(false)
                    setIsViewClosed(true)
                }else{
                    setViewEdit(true)
                    setIsViewClosed(false)

                }
            }
            if (res.status == 400 ) {
                if(!moment(fechaM).isSame(hoy, 'day')){
                    setViewEdit(false)
                    setIsViewClosed(false)
                    setData([])
                }else{  
                    setDataEdit(res.data)             
                    setViewEdit(true)
                    setIsViewClosed(false)
                }
             
            }
        })
        setLoading(false)

    }

    const showPDF = async () => {
        try {
            const fechaM = moment(fecha).format('YYYY-MM-DD');

            const pdf = await tarjetaExistenciaService.getPDF(fechaM, idCompany);
            const blob = new Blob([pdf], { type: 'application/pdf' });
            const blobURL = URL.createObjectURL(blob);
            window.open(blobURL, '_blank');
        } catch (error) {
            console.error('Error al mostrar el PDF:', error);
        }
    };

    const getProductosApi = async () => {
        setLoading(true)
        const response = await productoService.getProductosSimplesCompany(dataUsuario.user.id_empresa)
        setProductos(response.productos || [])
        setProductosBackUp(response.productos || [])
        setLoading(false)
    }

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const onSearch = (search: string) => {
        const searchLowerCase = search.trim().toLowerCase();
        if (search !== '') {
            const filteredProducts = productosBackUp.filter(product => (
                (product.nombre && product.nombre.toLowerCase().includes(searchLowerCase)) ||
                (product.codigo_erp && product.codigo_erp.toLowerCase().includes(searchLowerCase))
            ));
            setProductos(filteredProducts);
        } else {
            setProductos(productosBackUp);
            setPage(0)
        }
    }

    const marcarCierre = async() => {
        const fechaM = moment(fecha).format('YYYY-MM-DD HH:mm:ss');
    
        const payload = dataEdit.map(producto => {
            if((parseInt(producto.stock_sistema || 0) - parseInt(editedValues[producto.id]?.merma || 0) - parseInt(editedValues[producto.id]?.faltante || 0) + parseInt(editedValues[producto.id]?.sobrante || 0)) != parseInt(editedValues[producto.id]?.stock_real || 0) ){
                setSnackBarConfig({...snackBarConfig, open:true, severity:'error', message: 'Existe productos descuadrados'});
                return null; // Retorna null para indicar que no se debe incluir este producto en el payload
            }
            if( parseInt(editedValues[producto.id]?.stock_real || 0) < 0){
                setSnackBarConfig({...snackBarConfig, open:true, severity:'error', message: 'Existe productos con stock menor a 0'});
                return null; 
            }
            return {
                id: producto.id,
                stock_ultimo_cierre: producto.stock_ultimo_cierre,
                total_compras: producto.total_compras,
                total_ventas: producto.total_ventas,
                stock_sistema: producto.stock_sistema,
                faltante: parseInt(editedValues[producto.id]?.faltante || 0),
                merma: parseInt(editedValues[producto.id]?.merma || 0),
                sobrante: parseInt(editedValues[producto.id]?.sobrante || 0),
                stock_real: parseInt(editedValues[producto.id]?.stock_real  || 0) 
            };
        });
    
        // Si hay algún producto con stock menor a 0, no continuar con la ejecución
        if (payload.includes(null)) {
            return;
        }
    
        const resp = await tarjetaExistenciaService.crearTarjetaExistencia(fechaM, idCompany, JSON.stringify(payload));
        if(resp.status == 200){
            setSnackBarConfig({...snackBarConfig, open:true, severity:'success', message: resp.message})
        }
        if(resp.status == 400){
            setSnackBarConfig({...snackBarConfig, open:true, severity:'error', message: resp.message})
        }
    
        await getExistencia();
    };
    

    return (
        <Protected>
            {loading && <Loading />}
            <Snackbar
                open={snackBarConfig.open}
                autoHideDuration={snackBarConfig.autoHideDuration}
                onClose={() => setSnackBarConfig(prev => ({ ...prev, open: false }))}
                anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
            >
                <Alert
                    onClose={() => setSnackBarConfig(prev => ({ ...prev, open: false }))}
                    severity={snackBarConfig.severity}
                    variant="filled"
                >
                    {snackBarConfig.message}
                </Alert>
            </Snackbar>
            <Box sx={{ marginLeft: '10px', marginRight: '10px', marginBottom: '20px' }}>
                <Grid container alignItems="center" justifyContent="center" spacing={2}>
                    <Grid item>
                        <Typography variant='h4' align='center' my={3}>
                            Tarjeta de Existencia Diaria
                        </Typography>
                    </Grid>
                    <Grid item>
                        <LocalizationProvider dateAdapter={AdapterDateFns} locale={esLocale}>
                            <DatePicker
                                PopperProps={{ placement: "auto-start" }}
                                views={['day', 'month', 'year']}
                                value={fecha}
                                minDate={new Date('2020-01-01')}
                                maxDate={new Date('2030-01-01')}
                                onChange={(newValue) => {
                                    setFecha(newValue);
                                }}
                                renderInput={(params) => <TextField {...params} />}
                            />
                        </LocalizationProvider>
                    </Grid>
                </Grid>
                
                <Grid item xs={12}>
                    <Grid sx={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                        <Grid item xs={12}>
                            <TextField
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <SearchIcon fontSize="small" />
                                        </InputAdornment>
                                    ),
                                }}
                                placeholder="Buscar..."
                                size="small"
                                sx={{width: '300px'}}
                                onChange={(e) => onSearch(e.target.value)}
                            />
                            
                        </Grid>
                                                            
                        <Grid sx={{ display: "flex", alignItems: "center" }}>
                            {viewEdit && !isViewClosed &&
                                <Button onClick={() => marcarCierre()}> <CheckCircleOutlineIcon />
                                    Marcar Cierre</Button>
                            }
                            {
                                !viewEdit && isViewClosed &&
                                <>
                                <Button onClick={() => showPDF()}> <PictureAsPdfIcon />
                                    Visualizar PDF</Button>
                                <span style={{ backgroundColor: '#BAF3DB', color: '#216E4E', padding: '4px 6px', borderRadius: '4px' }}>
                                    {"Estado: Cerrado"}
                                </span>
                                </>
                                
                            }

                        </Grid>
                    </Grid>
                    <TableContainer component={Paper}>
                        <Table sx={{ minWidth: 650 }} aria-label="simple table">
                            <TableHead>
                                <TableRow>
                                    <TableCell align="left" sx={{ fontWeight: 700, fontSize: 14 }}><TagIcon /> Codigo</TableCell>
                                    <TableCell align="left" sx={{ fontWeight: 700, fontSize: 14 }}><TableRestaurantIcon /> Nombre</TableCell>
                                    <TableCell align="left" sx={{ fontWeight: 700, fontSize: 14 }}><LocalOfferIcon /> Tipo</TableCell>
                                    <TableCell align="left" sx={{ fontWeight: 700, fontSize: 14 }}><Inventory2Icon /> Stock inicial</TableCell>
                                    <TableCell align="left" sx={{ fontWeight: 700, fontSize: 14 }}><Inventory2Icon /> Ingreso</TableCell>
                                    <TableCell align="left" sx={{ fontWeight: 700, fontSize: 14 }}><Inventory2Icon /> Egreso</TableCell>
                                    <TableCell align="left" sx={{ fontWeight: 700, fontSize: 14 }}><Inventory2Icon /> Stock sistema</TableCell>
                                    <TableCell align="left" sx={{ fontWeight: 700, fontSize: 14 }}><WarningIcon /> Faltante (-)</TableCell>
                                    <TableCell align="left" sx={{ fontWeight: 700, fontSize: 14 }}><SouthIcon /> Merma (-)</TableCell>
                                    <TableCell align="left" sx={{ fontWeight: 700, fontSize: 14 }}><ArrowCircleUpIcon /> Sobrante (+)</TableCell>
                                    <TableCell align="left" sx={{ fontWeight: 700, fontSize: 14 }}><Inventory2Icon /> Stock real</TableCell>
                                    { viewEdit && !isViewClosed && <TableCell align="left" sx={{ fontWeight: 700, fontSize: 14 }}><Inventory2Icon /> Estado</TableCell>}
                                </TableRow>
                            </TableHead>
                           { viewEdit ? <TableBody>
                                {!!dataEdit.length
                                    ?
                                    dataEdit?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((producto, index) =>
                                        <TableRow key={index}>
                                            <TableCell align="left" sx={{ fontSize: 13 }}>{producto.codigo_erp}</TableCell>
                                            <TableCell align="left" sx={{ fontSize: 13 }}>{producto.nombre}</TableCell>
                                            <TableCell align="left" sx={{ fontSize: 12 }}>
                                                <Typography>
                                                    {
                                                        producto.is_supply == 0 ?
                                                            <span style={{ backgroundColor: '#C7C2E4', color: '#5243AA', padding: '2px 4px', borderRadius: '4px' }}>
                                                                {"PRODUCTO"}
                                                            </span> :
                                                            <span style={{ backgroundColor: '#E9F2FF', color: '#92C1FF', padding: '2px 4px', borderRadius: '4px' }}>
                                                                {"INSUMO"}
                                                            </span>
                                                    }                 
                                                </Typography>
                                            </TableCell>
                                            <TableCell align="left" sx={{ fontSize: 13 }}>{producto.stock_ultimo_cierre}</TableCell>
                                            <TableCell align="left" sx={{ fontSize: 13 }}>{producto.total_compras}</TableCell>
                                            <TableCell align="left" sx={{ fontSize: 13 }}>{producto.total_ventas}</TableCell>
                                            <TableCell align="left" sx={{ fontSize: 12 }}>{producto.stock_sistema || 0}</TableCell>
                                            <TableCell align="left" sx={{ fontSize: 12 }}>
                                                {!!editableFields[producto.id] && editableFields[producto.id].faltante ? (
                                                    <TextField
                                                        type='number'
                                                        size='small'
                                                        sx={{ width: '100px' }}
                                                        variant="standard"
                                                        value={editedValues[producto.id]?.faltante || ''}
                                                        onChange={(event) => handleFieldChange(event, producto.id, 'faltante')}
                                                        onBlur={() => handleFieldBlur(producto.id, 'faltante')}
                                                        autoFocus
                                                    />
                                                ) : (
                                                    <Typography
                                                        onClick={() => handleFieldClick(producto.id, 'faltante')}
                                                        style={{
                                                            cursor: 'pointer',
                                                            border: '1px solid transparent',
                                                            padding: '5px'
                                                        }}
                                                    >
                                                        {editedValues[producto.id]?.faltante || 0}
                                                       
                                                            <span
                                                                style={{
                                                                    backgroundColor: 'gray',
                                                                    color: 'white',
                                                                    borderRadius: '4px',
                                                                    padding: '3px 6px',
                                                                    margin: ' 0px 10px',
                                                                    fontSize: '12px',
                                                                }}
                                                            >
                                                                Editar
                                                            </span>
                                                        
                                                    </Typography>
                                                )}
                                            </TableCell>
                                            <TableCell align="left" sx={{ fontSize: 12 }}>
                                                {!!editableFields[producto.id] && editableFields[producto.id].merma ? (
                                                    <TextField
                                                        type='number'
                                                        size='small'
                                                        sx={{ width: '100px' }}
                                                        variant="standard"
                                                        value={editedValues[producto.id]?.merma || ''}
                                                        onChange={(event) => handleFieldChange(event, producto.id, 'merma')}
                                                        onBlur={() => handleFieldBlur(producto.id, 'merma')}
                                                        autoFocus
                                                    />
                                                ) : (
                                                    <Typography
                                                        onClick={() => handleFieldClick(producto.id, 'merma')}
                                                        style={{
                                                            cursor: 'pointer',
                                                            border: '1px solid transparent',
                                                            padding: '5px'
                                                        }}
                                                    >
                                                        {editedValues[producto.id]?.merma || 0}
                                                       
                                                            <span
                                                                style={{
                                                                    backgroundColor: 'gray',
                                                                    color: 'white',
                                                                    borderRadius: '4px',
                                                                    padding: '3px 6px',
                                                                    margin: ' 0px 10px',
                                                                    fontSize: '12px',
                                                                }}
                                                            >
                                                                Editar
                                                            </span>
                                                    </Typography>
                                                )}
                                            </TableCell>
                                            <TableCell align="left" sx={{ fontSize: 12 }}>
                                                {!!editableFields[producto.id] && editableFields[producto.id].sobrante ? (
                                                    <TextField
                                                        type='number'
                                                        size='small'
                                                        sx={{ width: '100px' }}
                                                        variant="standard"
                                                        value={editedValues[producto.id]?.sobrante || ''}
                                                        onChange={(event) => handleFieldChange(event, producto.id, 'sobrante')}
                                                        onBlur={() => handleFieldBlur(producto.id, 'sobrante')}
                                                        autoFocus
                                                    />
                                                ) : (
                                                    <Typography
                                                        onClick={() => handleFieldClick(producto.id, 'sobrante')}
                                                        style={{
                                                            cursor: 'pointer',
                                                            border: '1px solid transparent',
                                                            padding: '5px'
                                                        }}
                                                    >
                                                        {editedValues[producto.id]?.sobrante || 0}
                                                        
                                                            <span
                                                                style={{
                                                                    backgroundColor: 'gray',
                                                                    color: 'white',
                                                                    borderRadius: '4px',
                                                                    padding: '3px 6px',
                                                                    margin: ' 0px 10px',
                                                                    fontSize: '12px',
                                                                }}
                                                            >
                                                                Editar
                                                            </span>
                                                    </Typography>
                                                )}
                                            </TableCell>
                                            <TableCell align="left" sx={{ fontSize: 12 }}>
                                                {!!editableFields[producto.id] && editableFields[producto.id].stock_real ? (
                                                    <TextField
                                                        type='number'
                                                        size='small'
                                                        sx={{ width: '100px' }}
                                                        variant="standard"
                                                        value={editedValues[producto.id]?.stock_real || ''}
                                                        onChange={(event) => handleFieldChange(event, producto.id, 'stock_real')}
                                                        onBlur={() => handleFieldBlur(producto.id, 'stock_real')}
                                                        autoFocus
                                                    />
                                                ) : (
                                                    <Typography
                                                        onClick={() => handleFieldClick(producto.id, 'stock_real')}
                                                        style={{
                                                            cursor: 'pointer',
                                                            border: '1px solid transparent',
                                                            padding: '5px'
                                                        }}
                                                    >
                                                        {editedValues[producto.id]?.stock_real || 0}
                                                        
                                                            <span
                                                                style={{
                                                                    backgroundColor: 'gray',
                                                                    color: 'white',
                                                                    borderRadius: '4px',
                                                                    padding: '3px 6px',
                                                                    margin: ' 0px 10px',
                                                                    fontSize: '12px',
                                                                }}
                                                            >
                                                                Editar
                                                            </span>
                                                    </Typography>
                                                )}
                                            </TableCell>
                                            <TableCell align="left" sx={{ fontSize: 12 }}>{ (parseInt(producto.stock_sistema || 0) - parseInt(editedValues[producto.id]?.merma || 0) - parseInt(editedValues[producto.id]?.faltante || 0) + parseInt(editedValues[producto.id]?.sobrante || 0)) == parseInt(editedValues[producto.id]?.stock_real || 0) ?
                                             <span style={{ backgroundColor: '#BAF3DB', color: '#216E4E', padding: '4px 6px', borderRadius: '4px' }}>
                                             {"CUADRADO"}
                                         </span> :  <span style={{ backgroundColor: '#FFD6D6', color: '#C53030', padding: '4px 6px', borderRadius: '4px' }}>
                                             {"DESCUADRADO"}
                                         </span>}</TableCell>
                                        </TableRow>
                                    )
                                    :
                                    <TableRow>
                                        <TableCell align="center" colSpan={12}>Sin resultados</TableCell>
                                    </TableRow>
                                }
                            </TableBody>:
                            <TableBody>
                            {!!data.length
                                ?
                                data?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((productoE, index) =>
                                    <TableRow key={index}>
                                        <TableCell align="left" sx={{ fontSize: 13 }}>{productoE.producto.codigo_erp}</TableCell>
                                        <TableCell align="left" sx={{ fontSize: 13 }}>{productoE.producto.nombre}</TableCell>
                                        <TableCell align="left" sx={{ fontSize: 12 }}>
                                            <Typography>
                                                {
                                                    productoE.producto.is_supply == 0 ?
                                                        <span style={{ backgroundColor: '#C7C2E4', color: '#5243AA', padding: '2px 4px', borderRadius: '4px' }}>
                                                            {"PRODUCTO"}
                                                        </span> :
                                                        <span style={{ backgroundColor: '#E9F2FF', color: '#92C1FF', padding: '2px 4px', borderRadius: '4px' }}>
                                                            {"INSUMO"}
                                                        </span>
                                                }                 
                                            </Typography>
                                        </TableCell>
                                        <TableCell align="left" sx={{ fontSize: 13 }}>{productoE.stock_ultimo_cierre}</TableCell>

                                        <TableCell align="left" sx={{ fontSize: 13 }}>{productoE.total_compras}</TableCell>
                                        <TableCell align="left" sx={{ fontSize: 13 }}>{productoE.total_ventas}</TableCell>

                                        <TableCell align="left" sx={{ fontSize: 12 }}>{productoE.stock_sistema || 0}</TableCell>
                                        <TableCell align="left" sx={{ fontSize: 12 }}>
                                            <Typography>{productoE.faltante || 0}</Typography>
                                        </TableCell>
                                        <TableCell align="left" sx={{ fontSize: 12 }}>
                                            <Typography>{productoE.merma || 0}</Typography>
                                        </TableCell>
                                        <TableCell align="left" sx={{ fontSize: 12 }}>
                                            <Typography>{productoE.sobrante || 0}</Typography>
                                        </TableCell>
                                        <TableCell align="left" sx={{ fontSize: 12 }}>
                                            <Typography>{productoE.stock_real || 0}</Typography>
                                        </TableCell>
                                    </TableRow>
                                )
                                :
                                <TableRow>
                                    <TableCell align="center" colSpan={12}>Sin resultados</TableCell>
                                </TableRow>
                            }
                        </TableBody>}
                        </Table>
                    </TableContainer>
                    <TablePagination
                        rowsPerPageOptions={[10, 20, 30, 40]}
                        component="div"
                        count={productos?.length || 0}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                    />
                </Grid>
            </Box>
        </Protected>
    )
}