import { Props } from "./ModalReservas.type";
import React, { useEffect } from "react";
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import { Grid, Typography } from "@mui/material";
import DateTimePicker from '@mui/lab/DateTimePicker';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import { salas } from '@/service/services/Salas.service';
import { Loading } from "@/components/common/Loading";
import { Snackbar, Alert } from '@mui/material';

export const ModalReservas: React.FC<Props> = (props:Props):JSX.Element => {
   // eslint-disable-next-line
   const [loading, setLoading] = React.useState<boolean>(false);
   const [fecha, setFecha] = React.useState(null)
   const time = (new Date()).getTime();

   console.log(fecha);

   const [snackBarConfig, setSnackBarConfig] = React.useState<any>({
      open: false,
      severity: 'error',
      message: 'Error',
      autoHideDuration: 3000,
   });

   useEffect(()=> {
   if (props.data) {
      setFecha((props.data?.reservated_at))
   }
   // eslint-disable-next-line
   },[])

   const reservarMesa = async() => {
      setLoading(true);
      let fechaState = (new Date(fecha));
      if (fechaState.getTime()>(time+(60*60*3*1000))){
         let sendDate = (fecha.toString()).slice(0, 24)
         let res = await salas.editMesaReserva(props.data?.id, sendDate)
         if (res?.status === 200){
            setSnackBarConfig(prev => ({
               ...prev,
               open: true,
               severity: 'success',
               message: `Mesa reservada con éxito.`,
            }));
            props.onlistTable && props.onlistTable();
            props.close && props.close();
         } else {
            setSnackBarConfig(prev => ({
               ...prev,
               open: true,
               message: `Ocurrió algun error.`,
            }));
         }
      } else {
         setSnackBarConfig(prev => ({
            ...prev,
            open: true,
            severity: 'warning',
            message: `La fecha de reserva debe ser mayor a 3 horas`,
         }));
      }
      setLoading(false);
   }

   const liberarMesa = async() => {
      setLoading(true);
      let res = await salas.editMesaReserva(props.data?.id, null)
      if (res?.status === 200){
         props.close()
      } else {
         setSnackBarConfig(prev => ({
            ...prev,
            open: true,
            message: `Ocurrió algun error.`,
         }));
      }
      setLoading(false);
   }

   return (
      <>
      {loading && <Loading/>}
      <Snackbar
         open={snackBarConfig.open}
         autoHideDuration={snackBarConfig.autoHideDuration}
         onClose={() => setSnackBarConfig(prev => ({ ...prev, open: false }))}
         anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
         >
         <Alert
         onClose={() => setSnackBarConfig(prev => ({ ...prev, open: false }))}
         severity={snackBarConfig.severity}
         variant="filled"
         >
         {snackBarConfig.message}
         </Alert>
      </Snackbar>
      <Grid container padding={"10px"}>
         <Grid
            container spacing={3}
            direction="row"
            justifyContent="space-between"
            alignItems="center"
         >
            <Grid item xs={4}>
               <Typography variant="subtitle1" className="custom-input"><b>Fecha:</b></Typography>
            </Grid>
            <Grid item xs={8}>
               <LocalizationProvider dateAdapter={AdapterDateFns} >
                  <DateTimePicker
                     value={fecha}
                     minDate={new Date()}
                     onChange={(newValue) => {
                        setFecha(newValue);
                     }}
                     renderInput={(params) => <TextField style={{width: "100%"}} {...params} />}
                  />
               </LocalizationProvider>
            </Grid>
         </Grid>
         <Grid
            container direction="row"
            justifyContent="flex-end"
            alignItems="center"
            paddingTop={"10px"}
         >
            {
               props?.data?.reservated_at && (
                  <Button variant='outlined' style={{textTransform: "capitalize"}}
                     onClick={liberarMesa}
                  >Liberar Mesa</Button>
               )
            }
            <Button variant='outlined' style={{textTransform: "capitalize"}} disabled={fecha ? false: true}
               onClick={reservarMesa}
            >Reservar Mesa</Button>
         </Grid>
      </Grid>
      </>
   )
}
