import { ComandaRepository } from "../repositories/Comanda.repository";

export const ComandaService = {
    get,
    getPDF,
    register,
    imprimir
}

async function get(data: {}) {
    const res = await ComandaRepository.get(data);
    return res;
}

async function getPDF(id: number) {
    const res = await ComandaRepository.getPDF(id);
    return res;
}

async function register(data: {}) {
    const res = await ComandaRepository.register(data);
    return res;
}

async function imprimir(idticket: number) {
    const res = await ComandaRepository.imprimir(idticket);
    return res;
}