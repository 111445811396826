import React, { useEffect } from 'react';
import { Props } from "./ComponentBalanzaTicket.type"
import { Grid, Typography } from '@mui/material';
import { useBarcode } from '@createnextapp/react-barcode';
import { readLocalStorage } from '@/toolbox/helpers/local-storage-helper';
import { KEY_EMPRESA } from '@/toolbox/constants/local-storage';

export const ComponentBalanzaTicket: React.FC<Props> = (props: Props) => {

    const {data, closeModal} = props;

    const SessionEmpresa: any = readLocalStorage(KEY_EMPRESA);

    useEffect(() => {
        closeModal && closeModal();
        // eslint-disable-next-line
    }, []);

    function BarCode({ textobarcode }) {
        const { inputRef } = useBarcode({
          value: textobarcode === "" ? "vacio" : textobarcode,
          options: {
             width:1.5
          }
        });
        return (<img ref={inputRef} alt="barcodeIMG"/>);
    }

    return(
        <div style={{  marginBottom: 30, width: '300px', margin: 'auto', marginTop: 30 ,padding:"10px"}}>
            <div style={{width:"100%"}} >

                <div style={{textAlign:"center"}}>
                    <Typography sx={{fontSize:'1.3em !important', fontFamily:'sans-serif',fontWeight:'700'}}>
                        {SessionEmpresa?.nombre}
                    </Typography>
                </div>

                <hr style={{border: '0', height: '2px', borderTop: '1px dashed black', borderBottom: '1px dashed black'}}/>

                <div>
                    <Grid container>
                        <Grid item xs={6}>
                            <Typography sx={{textAlign:"left",fontSize:'1.2em !important'}}>Artículo</Typography>
                        </Grid>
                        <Grid item xs={6}>
                            <Typography sx={{textAlign:"right",fontSize:'1.2em !important'}}>Cantidad</Typography>
                        </Grid>
                    </Grid>
                </div>

                <hr style={{border: '0', height: '2px', borderTop: '1px dashed black', borderBottom: '1px dashed black'}}/>

                <div>
                     <Grid container>
                        <Grid item xs={8}>
                            <Typography sx={{textAlign:"left",fontSize:'1em !important'}}>{data?.nombre}</Typography>
                        </Grid>
                        <Grid item xs={4}>
                            <Typography sx={{textAlign:"right",fontSize:'1em !important'}}>{data?.cantidad} {` ${data?.unidad_medida?.abbreviation}`}</Typography>
                        </Grid>
                     </Grid>
                </div>
                <hr style={{border: '0', height: '2px', borderTop: '1px dashed black', borderBottom: '1px dashed black'}}/>

                <div>
                     <Grid container>
                        <Grid item xs={8}>
                            <Typography sx={{textAlign:"left",fontSize:'1em !important'}}>Monto a pagar</Typography>
                        </Grid>
                        <Grid item xs={4}>
                            <Typography sx={{textAlign:"right",fontSize:'1em !important'}}>{data?.precio}</Typography>
                        </Grid>
                     </Grid>
                </div>

                <hr/>

                <div style={{textAlign:"center"}}>
                    <BarCode textobarcode={`${data?.codigo_erp}xyz${data?.cantidad}`} />
                </div>

            </div>
        </div>
    )
}
