import { cuadraturaRepository } from "../repositories/Cuadratura.repository"
import { KEY_EMPRESA} from '@constants/local-storage';
import {readLocalStorage} from '@helpers/local-storage-helper';

export const cuadraturaService = {
   abrirCuadratura,
   getActualCuadratura,
   getActualCuadraturaRooms,
   getUltimaCuadratura,
   getUltimaCuadraturaRooms,
   getCuadraturaAjuste,
   getCuadraturaDescuadre,
   cerrarCuadratura,
   montoCuadratura,
   getCierresDiarios,
   getPDFCierreDiario,
   getPDFCuadratura
}

async function abrirCuadratura(responsable_id: number, type: string, monto: number, id_caja){
   const data: any = readLocalStorage(KEY_EMPRESA);
    const res = await cuadraturaRepository.abrirCuadratura(responsable_id, type, monto, data.id, id_caja);
    return res;
}

async function getActualCuadratura(id_responsable: number){
    const data: any = readLocalStorage(KEY_EMPRESA);
    const res = await cuadraturaRepository.getActualCuadratura(data.id, id_responsable);
    return res;
}

async function getActualCuadraturaRooms(id_sala: number){
    const data: any = readLocalStorage(KEY_EMPRESA);
    const res = await cuadraturaRepository.getActualCuadraturaRooms(data.id, id_sala);
    return res;
}

async function getUltimaCuadratura(id_responsable: number){
    const data: any = readLocalStorage(KEY_EMPRESA);
    const res = await cuadraturaRepository.getUltimaCuadratura(data.id, id_responsable);
    return res;
}

async function getUltimaCuadraturaRooms(id_sala: number){
    const data: any = readLocalStorage(KEY_EMPRESA);
    const res = await cuadraturaRepository.getUltimaCuadraturaRooms(data.id, id_sala);
    return res;
}

async function getCuadraturaAjuste(id_cuadratura: number){
    const res = await cuadraturaRepository.getCuadraturaAjuste( id_cuadratura);
    return res;
}

async function getCuadraturaDescuadre(id_cuadratura: number){
    const res = await cuadraturaRepository.getCuadraturaDescuadre( id_cuadratura);
    return res;
}

async function cerrarCuadratura(id :number, monto_final: number, idsVentasJSON, total_ajuste: number, total_cierre_efectivo:number,  detalleMov, detalleDescuadre,
    monto_final_real, total_descuadre, total_descuadre_efectivo, total_ventas, total_ventas_efectivo){
    const res = await cuadraturaRepository.cerrarCuadratura(id, monto_final, idsVentasJSON, total_ajuste, total_cierre_efectivo, detalleMov, detalleDescuadre,
        monto_final_real, total_descuadre, total_descuadre_efectivo, total_ventas, total_ventas_efectivo);
    return res;
}

async function montoCuadratura ( id_responsable: number, id_cuadratura:number) {
    const res = await cuadraturaRepository.montoCuadratura( id_responsable, id_cuadratura);
    return res;
}

async function getCierresDiarios(data?: any) {
    const res = await cuadraturaRepository.getCierresDiarios(data);
    return res;
}

async function getPDFCierreDiario(data?: any) {
    const res = await cuadraturaRepository.getPDFCierreDiario(data);
    return res;
}

async function getPDFCuadratura(id: number) {
    const res = await cuadraturaRepository.getPDF(id);
    return res;
}