import axios from 'axios';
import { API_URL_NUPY } from '@/toolbox/defaults/app';
import { TOKEN_API } from "@/toolbox/constants/local-storage";
import { readLocalStorage } from '@helpers/local-storage-helper';

export const editorRepository = {
    cargarModulos: async (id_empresa: any): Promise<any> => {
        const res = await axios.get(`${API_URL_NUPY}/showModulos/get_modulos/${id_empresa}`);
        return res.data;
    },

    addModulo: async (nombre: any, state: any, id_empresa: any): Promise<any> => {
        const res = await axios.post(`${API_URL_NUPY}/crudModulos/add_modulo`, { nombre, state, id_empresa });
        return res;
    },

    deleteModulo: async (id: Number): Promise<any> => {
        const res = await axios.delete(`${API_URL_NUPY}/crudModulos/delete_modulo/${id}`);
        return res;
    },

    cargarSubModulos: async (id: any): Promise<any> => {
        const res = await axios.post(`${API_URL_NUPY}/showModulos/get_submodulos`, { submodulos: id });
        return res.data;
    },

    getSubmoduloById: async (id: any): Promise<any> => {
        const res = await axios.get(`${API_URL_NUPY}/showModulos/get_submodulos/${id}`);
        return res.data;
    },

    addSubmodulo: async (nombre: any, state: any): Promise<any> => {
        const res = await axios.post(`${API_URL_NUPY}/crudModulos/add_submodulo`, { nombre, state });
        return res;
    },

    addSubmoduloAModulo: async (id_modulo: any, id_submodulo: any): Promise<any> => {
        const res = await axios.put(`${API_URL_NUPY}/crudModulos/add_sub/${id_modulo}/${id_submodulo}`);
        return res;
    },

    deleteSubmodulo: async (id: Number): Promise<any> => {
        const res = await axios.delete(`${API_URL_NUPY}/crudModulos/delete_submodulo/${id}`);
        return res;
    },

    deleteSubmoduloAModulo: async (id_modulo: any, id_submodulo: any): Promise<any> => {
        const res = await axios.put(`${API_URL_NUPY}/crudModulos/delete_sub/${id_modulo}/${id_submodulo}`);
        return res;
    },

    deleteSubmodulos: async (ids: Number): Promise<any> => {
        const res = await axios.delete(`${API_URL_NUPY}/crudModulos/delete_submodulos/${ids}`);
        return res;
    },

    addProductoASubmodulo: async (id_submodulo: any, id_producto: any): Promise<any> => {
        const res = await axios.put(`${API_URL_NUPY}/crudModulos/add_prod/${id_submodulo}/${id_producto}`);
        return res;
    },

    deleteProductoDet: async (id: Number): Promise<any> => {
        const res = await axios.delete(`${API_URL_NUPY}/crudModulos/delete_proucto/${id}`);
        return res;
    },

    deleteProductoASubmodulo: async (id_submodulo: any, id_producto: any): Promise<any> => {
        const res = await axios.put(`${API_URL_NUPY}/crudModulos/delete_prod/${id_submodulo}/${id_producto}`);
        return res;
    },

    //edit
    // producto: codigo_erp, nombre,precio_standar, precio_oferta, id_tipo_producto, id_empresa
    addProducto: async (producto: any, id_empresa: any, is_new = false): Promise<any> => {
        const codigo_erp = producto.codigo_erp;
        const nombre = producto.nombre;
        const precio_standar = producto.precio_standar;
        const precio_oferta = producto.precio_oferta;
        const id_tipo_producto = producto.id_tipo_producto;
        const is_supply = producto.is_supply;
        const stock_minimo = producto.stock_minimo ? producto.stock_minimo : 0;
        const cantidad_peso = producto.cantidad_peso ? producto.cantidad_peso : 1;
        const familia_id = producto.familia_id ? producto.familia_id : 0;
        const sub_familia_id = producto.sub_familia_id ? producto.sub_familia_id : 0;
        const marca_id = producto.marca_id ? producto.marca_id : 0;
        const linea_id = producto.linea_id ? producto.linea_id : 0;
        const id_unidad_medida = producto.id_unidad_medida;
        const caducidad_precio_oferta = producto.caducidad_precio_oferta;
        const stock = producto.stock;
        const idtipo_impuesto = producto.idtipo_impuesto

        // let estado = true;

        // if (is_new) {
        //     const exento = idtipo_impuesto === 2 ? 1 : 0
        //     const res = await addProductoSofnet(
        //         codigo_erp,
        //         nombre,
        //         precio_standar,
        //         precio_oferta,
        //         id_unidad_medida,
        //         familia_id,
        //         sub_familia_id,
        //         marca_id,
        //         linea_id,
        //         exento
        //     );
        //     estado = res;
        // }

        // if (estado) {
            const res = await axios.post(`${API_URL_NUPY}/productos/add_producto`, {
                codigo_erp, nombre,
                precio_standar,
                precio_oferta,
                caducidad_precio_oferta,
                id_tipo_producto,
                id_empresa,
                is_supply,
                stock_minimo,
                cantidad_peso,
                id_unidad_medida,
                familia_id,
                sub_familia_id,
                marca_id,
                linea_id,
                stock,
                idtipo_impuesto: idtipo_impuesto
            });
            return res;
        // } else {
        //     return false;
        // }
    },

    deleteProducto: async (id: any): Promise<any> => {
        const res = await axios.delete(`${API_URL_NUPY}/productos/delete_producto/${id}`);
        return res;
    },
    getProductoById: async (id: number): Promise<any> => {
        const res = await axios.get(`${API_URL_NUPY}/productos/get_producto/${id}`);
        return res;
    },
    editProducto: async (id: number, producto: any): Promise<any> => {
        const res = await axios.patch(`${API_URL_NUPY}/productos/update_producto/${id}`, {
            producto
        });
        return res;
    },

    cargarProductos: async (id: any): Promise<any> => {
        const res = await axios.post(`${API_URL_NUPY}/productos/get_productos`, { id_productos: id });
        return res.data;
    },

    cargarProductosArray: async (array: any): Promise<any> => {
        const res = await axios.post(`${API_URL_NUPY}/productos/get_productos_array`, { id_productos: array });
        return res.data;
    },

    addItemsProductoAgrupado: async (combo: Array<any>, productoId: number): Promise<any> => {
        const res = await axios.post(`${API_URL_NUPY}/agrupado/add_items`, {
            items: combo,
            producto_id: productoId
        });

        return res.data;
    },

    getItemsProductoAgrupado: async (idProducto: number): Promise<any> => {
        const res = await axios.get(`${API_URL_NUPY}/agrupado/get_items/${idProducto}`);
        return res.data;
    },

    deleteItemsProductoAgrupado: async (idProducto: number): Promise<any> => {
        const res = await axios.delete(`${API_URL_NUPY}/agrupado/delete_items/${idProducto}`);
        return res.data;
    },

    addItemProductoAgrupado: async (item: any): Promise<any> => {
        const res = await axios.post(`${API_URL_NUPY}/agrupado/add_item`, { item: item });
        return res.data;
    },

    deleteItemProductoAgrupado: async (id: number): Promise<any> => {
        const res = await axios.delete(`${API_URL_NUPY}/agrupado/delete_item/${id}`);
        return res.data;
    },

    editItemProductoAgrupado: async (id: number, cantidad: any): Promise<any> => {
        const res = await axios.put(`${API_URL_NUPY}/agrupado/edit_item/${id}`, {
            cantidad: cantidad,
        });
        return res;
    },

    addMenu: async (menu: any, id: any): Promise<any> => {
        const nombre = menu.nombre;
        const cantidad = menu.cantidad;
        const id_producto = id;
        const res = await axios.post(`${API_URL_NUPY}/menu/add_menu`, { nombre, cantidad, id_producto });

        return res.data.productoID;
    },

    addItemsMenu: async (items: any, menuId: number): Promise<any> => {
        const res = await axios.post(`${API_URL_NUPY}/menu/add_items`, {
            items: items,
            producto_id: menuId
        });
        return res.data;
    },

    getMenu: async (idProducto: number): Promise<any> => {
        const res = await axios.get(`${API_URL_NUPY}/menu/get_menu/${idProducto}`);
        return res.data;
    },

    getItemsMenu: async (idMenu: number): Promise<any> => {
        const res = await axios.get(`${API_URL_NUPY}/menu/get_items/${idMenu}`);
        return res.data;
    },

    deleteMenu: async (idProducto: number): Promise<any> => {

        const res = await axios.delete(`${API_URL_NUPY}/menu/delete_menu/${idProducto}`);
        return res.data;
    },

    deleteItemsMenu: async (idMenu: number): Promise<any> => {
        const res = await axios.delete(`${API_URL_NUPY}/menu/delete_items/${idMenu}`);

        return res.data;
    },

    editMenu: async (id: number, nombre: any, cantidad: any): Promise<any> => {
        const res = await axios.put(`${API_URL_NUPY}/menu/edit_menu/${id}`, {
            nombre: nombre,
            cantidad: cantidad,
        });
        return res;
    },

    addItemMenu: async (item: any): Promise<any> => {
        const res = await axios.post(`${API_URL_NUPY}/menu/add_item`, { item: item });
        return res.data;
    },

    deleteItemMenu: async (id: number): Promise<any> => {
        const res = await axios.delete(`${API_URL_NUPY}/menu/delete_item/${id}`);
        return res.data;
    },

    postProductBoleta: async (form: any): Promise<any> => {
        const res = await axios.post(`${API_URL_NUPY}/productos/post_product_boleta`, form)


        return res.data;
    },

    listAllSubmodulos: async (): Promise<any> => {
        const res = await axios.get(`${API_URL_NUPY}/showModulos/listAllSubmodulos`);
        return res.data;
    },
    addProductoSofnet,
}

function addProductoSofnet(
    codigo_erp, nombre, precio_standar, precio_oferta, id_unidad_medida,
    familia_id,
    sub_familia_id,
    marca_id,
    linea_id,
    exento: 0 | 1
) {

    const token: any = readLocalStorage(TOKEN_API);

    return fetch(`https://api2.softnet.cl/productoProyectos`, {
        method: 'POST',
        headers: {
            'Access-Control-Allow-Origin': '*',
            'Content-Type': 'application/json',
            token: token,
        },
        body: JSON.stringify({
            productos: [{
                "codigo": `${codigo_erp}`,
                "codigo_barra": `${codigo_erp}`,
                "nombre": `${nombre}`,
                "tipo_producto_id": `${6}`,
                "unidad_medida_id": `${id_unidad_medida}`,
                "estado": `${1}`,
                "precio": `${precio_standar}`,
                "exento": `${exento}`,
                "precio_oferta": `${precio_oferta}`,
                "fecha_oferta": "0",
                "linea_id": linea_id === null ? 0 : linea_id,
                "familia_id": familia_id === null ? 0 : familia_id
            }]
        })

    }).then(pros => pros.json())
        .then(async pros => {
            return true
        })
        .catch(error => {
            /* alert("REGISTRO MAL EN SOFNET") */
            return false;
        })
}




