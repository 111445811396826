import React, { useState } from 'react';
import { Divider, Grid, TextField, Typography, Button } from '@mui/material';
import { Loading } from '@/components/common/Loading/Loading';
import { Form, Formik } from 'formik';
import {KEY_EMPRESA, KEY_USER_DATA, CLIENTE_SHOP} from '@constants/local-storage';
import {saveLocalStorage} from '@helpers/local-storage-helper';
import { Snackbar, Alert } from '@mui/material';
import { Props } from './LoginModal.type';
import { Popup } from '@/views/Editor/components/Popup';
import { RegisterModal } from '../RegisterModal';
import { authenticationService } from '@/service/services/Authentication.service';
import { useLocalStorage } from '@/toolbox/hooks/local-storage.hook';
import { userService } from "@/service/services/User.service";

export const LoginModal: React.FC<Props> = (props: Props): JSX.Element => {
    const { closeModal, selectedCompany } = props;
    const [loading, setLoading] = React.useState<boolean>(false);
    const [showModalRegister, setShowModalRegister] = React.useState<boolean>(false);
    // eslint-disable-next-line
    const [data, setData] = React.useState({
        username: '',
        password: '',
    });

    const [snackBarConfig, setSnackBarConfig] = useState<any>({
        open: false,
        severity: 'error',
        message: 'Error',
        autoHideDuration: 3000,
    });

    const [dataUser] = useLocalStorage(KEY_USER_DATA, undefined);
    const userSession = dataUser && dataUser.user;

    const validateForm = async (values) => {
        let errors: any = {};
        if(!values.username) errors.username = "nombre de usuario requerido";
        if(!values.password) errors.password = "contraseña requerido";

        return errors;
    }

    const onSubmit = async(values, {resetForm}) => {
        setLoading(true);
        const response = await authenticationService.loginEcommerce('', '', values.username, '', values.password);

        setLoading(false);
        if (response.token === '') {
            setSnackBarConfig(prev => ({
               ...prev,
               open: true,
               message: response?.error?.message || "Usuario no autorizado",
            }));
        } else {
            if (selectedCompany?.empresa?.id) {
               const userClient = {
                  id_user: response?.user.id_usuario,
                  nombre_user: response?.user.nombre,
                  rut_user: response?.user.rut_usuario,
                  id_sucursal: selectedCompany?.id,
                  id_empresa: selectedCompany?.empresa?.id,
               }
               await userService.validateDetalleClienteUser(userClient).then((data) => {
                  if(data.status === 200){
                     saveLocalStorage(CLIENTE_SHOP, data?.clienteID);
                     saveLocalStorage(KEY_EMPRESA, {
                        "id": selectedCompany?.empresa?.id,
                        "id_tipo_empresa": selectedCompany?.empresa?.id_tipo_empresa,
                        "nombre": selectedCompany?.empresa?.name,
                        "equivalencia_puntos": selectedCompany?.empresa?.equivalencia_puntos,
                        "id_country": selectedCompany?.empresa?.id_country,
                        "country_name": selectedCompany?.empresa?.country?.nombre,
                        "country_abbreviation": selectedCompany?.empresa?.country?.abbreviation,
                        "mesas": selectedCompany?.empresa?.mesas,
                     });
                     window.location.replace("/productos-ecommerce");
                  }
               });
            } else {
               closeModal && closeModal();
               window.location.replace("/shop-ecommerce");
            }
         }
    }

    return (
        <div className="container">
            {loading && <Loading/>}
            <Snackbar
                open={snackBarConfig.open}
                autoHideDuration={snackBarConfig.autoHideDuration}
                onClose={() => setSnackBarConfig(prev => ({ ...prev, open: false }))}
                anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                >
                <Alert
                onClose={() => setSnackBarConfig(prev => ({ ...prev, open: false }))}
                severity={snackBarConfig.severity}
                variant="filled"
                >
                {snackBarConfig.message}
                </Alert>
            </Snackbar>

            <Formik enableReinitialize initialValues={data} validate={ (values) => validateForm(values) } onSubmit={onSubmit}>
                {({values, errors, touched, handleSubmit, handleChange, setFieldValue}) => {
                    return (
                        <Form onSubmit={handleSubmit} style={{width:'100%'}}>
                            <Grid container spacing={3} alignItems="center" justifyContent="center">

                            <Grid item xs={12} container style={{display: "flex", alignItems: "center"}}>
                                <Grid item xs={4}>
                                    <Typography variant="subtitle1" className="custom-input"><b>Nombre de Usuario:</b></Typography>
                                </Grid>
                                <Grid item xs={8} style={{display: 'flex', alignItems: "center"}}>
                                    <TextField
                                    variant="standard"
                                    id="username"
                                    type="text"
                                    name="username"
                                    size='small'
                                    fullWidth
                                    value={values.username}
                                    onChange={handleChange}
                                    placeholder="username"
                                    error={errors.username && touched.username ? true : false}
                                    />
                                </Grid>
                            </Grid>

                            <Grid item xs={12} container style={{display: "flex", alignItems: "center"}}>
                                <Grid item xs={4}>
                                    <Typography variant="subtitle1" className="custom-input"><b>Contraseña:</b></Typography>
                                </Grid>
                                <Grid item xs={8} style={{display: 'flex', alignItems: "center"}}>
                                    <TextField
                                    variant="standard"
                                    id="password"
                                    type="password"
                                    name="password"
                                    size='small'
                                    fullWidth
                                    value={values.password}
                                    onChange={handleChange}
                                    placeholder="password"
                                    error={errors.password && touched.password ? true : false}
                                    />
                                </Grid>
                            </Grid>

                            <Grid item container sx={{marginLeft:'22px', display:'flex', justifyContent:'center'}}>
                                <Button type="submit">
                                    Ingresar
                                </Button>
                            </Grid>

                            <Divider />

                            <Grid item container sx={{marginLeft:'22px', display:'flex', justifyContent:'center'}}>
                                <Grid >
                                    <span>¿Aún no te has registrado?</span>
                                    <span style={{cursor:'pointer', color:'green'}} onClick={()=>{
                                        setShowModalRegister(true);
                                    }}> Registrate aquí</span>
                                </Grid>
                            </Grid>

                            </Grid>
                        </Form>
                    )
                }}
            </Formik>
            {
                showModalRegister && (
                    <Popup
                        largo="sm"
                        title = 'REGISTER SHOP'
                        openPopup = {showModalRegister}
                        setOpenPopup = {setShowModalRegister}
                    >
                        <RegisterModal
                           selectedCompany={selectedCompany}
                        />
                    </Popup>
                )
            }
        </div>
    );
}
