import { ProveedorRepository } from "../repositories/Proveedor.repository";

export const proveedorService = {
    getProveedores,
    editState,
    register,
    update,
    getProveedoresByCompany,
    validateProveedoresSofnet
}

async function getProveedores(){
    const res = await ProveedorRepository.getProveedores();
    return res;
}

async function validateProveedoresSofnet(){
   const res = await ProveedorRepository.validateProveedoresSofnet();
   return res;
}

async function register(data:{}){
    const res = await ProveedorRepository.register(data);
    return res;
}

async function update(data:{}, idProveedor:number){
    const res = await ProveedorRepository.update(data, idProveedor);
    return res;
}

async function editState(idProveedor:number, state:any){
    const res = await ProveedorRepository.editState(idProveedor, state);
    return res;
}

async function getProveedoresByCompany(idCompany:number){
    const res = await ProveedorRepository.getProveedoresByCompany(idCompany);
    return res;
}
