import React, { useEffect } from "react";
import { Props } from "./ValidateCupon.type";
import { Divider, Grid, TextField, Typography } from "@mui/material";
import { CuponesService } from "@/service/services/Cupones.service";
import { Snackbar, Alert } from '@mui/material';
import moment from "moment";
import {
   PRODUCTS_CARD, KEY_APPLY_CUPONS
} from '@constants/local-storage';
import { TOKEN_API } from "@/toolbox/constants/local-storage";
import { useLocalStorage } from '@toolbox/hooks/local-storage.hook';
import { saveLocalStorage } from '@helpers/local-storage-helper';
import { productoService } from "@/service/services/Producto.service";

export const ValidateCupon: React.FC<Props> = (props: Props): JSX.Element => {

   const [token] = useLocalStorage(TOKEN_API, "");
   const [cupones] = useLocalStorage(KEY_APPLY_CUPONS, []);
   const [validCoupon, setValidCoupon] = React.useState<any>({});
   const [validated, setValidated] = React.useState(false);
   const [card_shop] = useLocalStorage(PRODUCTS_CARD, []);
   // eslint-disable-next-line
   const [tipoCupon, secTipoCupon] = React.useState([]);
    // eslint-disable-next-line
   const [familias, secFamilias] = React.useState([]);

   const [snackBarConfig, setSnackBarConfig] = React.useState<any>({
       open: false,
       severity: 'error',
       message: 'Error',
       autoHideDuration: 3000,
   });

   useEffect(() => {
      getFamiliesAndSubFamilies();
      // fetch(`https://api2.softnet.cl/familia`, {
      //    method: 'GET',
      //    headers: {
      //       'Access-Control-Allow-Origin':'*',
      //       'Content-Type': 'application/x-www-form-urlencoded',
      //       token: token,
      //    },
      //    })
      //    .then(familiasData => familiasData.json())
      //    .then(async familiasData => {
      //       secFamilias(familiasData)
      //    })
      //    .catch(error => {
      //       setSnackBarConfig(prev => ({
      //          ...prev,
      //          open: true,
      //          message: `No se Cargaron Familia de productos`,
      //       }));
      //    })
      }, // eslint-disable-next-line
   [])

   const getFamiliesAndSubFamilies = async() =>{
         await productoService.getFamiliesAndSubFamilies().then((data) => {
             if(data.status === 200){
               secFamilias(data.families || []);
             }else{
                 return setSnackBarConfig(prev => ({
                     ...prev,
                     open: true,
                     message: `No se cargaron Familia de productos`,
                 }));
             }
         });
   }

   const handleKeyDown = async (event) => {
        let texto = `${event.target.value}`;
        if(texto && texto.length >= 23){
            let array = texto.split(':');
            const id = array[0];

            await CuponesService.showCupon(Number(id)).then((data) => {
               if (data.status === 200) {
                  const dateToday = moment();

                  const dateCuponFin = data.cupon.fecha_fin;
                  const dateCuponInicio = data.cupon.fecha_inicio;
                  const expirationCupon = moment(dateCuponFin, 'YYYY-MM-DD');
                  const initCupon = moment(dateCuponInicio, 'YYYY-MM-DD');
                  data.cupon.familia_id = Number(data.cupon.familia_id);
                  setValidCoupon(data.cupon);

                  if((expirationCupon.isSameOrAfter(dateToday)) && (initCupon.isSameOrBefore(dateToday))){
                     setValidated(true);
                  }else{
                     setValidated(false);
                  }
               } else {
                  setValidated(false);
                  setValidCoupon({});
                  setSnackBarConfig(prev => ({
                     ...prev,
                     open: true,
                     message: `No existe el cupon`,
                  }));
               }
            })
        }
   }

   const applyCupon = () => {
      if (card_shop && card_shop.length > 0) {
         let wasAdded = cupones.find(e => e.id === validCoupon.id)
         if (!wasAdded) {
            if (validCoupon.familia_id) {

               let existfamily = card_shop.find(e => e.familia_id === validCoupon.familia_id);

               if (existfamily) {

                  cupones.push(validCoupon);
                  saveLocalStorage(KEY_APPLY_CUPONS, cupones);
                  props.closeModal();
               } else {
                  handlePrintError(`No existe cupon por familia`);
               }
            } else if (validCoupon.sub_familia_id) {
               let existfamily = card_shop.find(e => e.sub_familia_id === validCoupon.sub_familia_id)
               if (existfamily) {
                  cupones.push(validCoupon);
                  saveLocalStorage(KEY_APPLY_CUPONS, cupones);
                  props.closeModal();
               } else {
                  handlePrintError(`No existe cupon por Sub Familia`);
               }
            } else if (validCoupon.linea_id) {
               let existfamily = card_shop.find(e => e.linea_id === validCoupon.linea_id)
               if (existfamily) {
                  cupones.push(validCoupon);
                  saveLocalStorage(KEY_APPLY_CUPONS, cupones);
                  props.closeModal();
               } else {
                  handlePrintError(`No existe cupon por Linea de productos`);
               }
            } else if (validCoupon.productos !== "[]") {
               let productos = JSON.parse(validCoupon.productos);
               let added = false;
               card_shop.forEach(element => {
                  let exits = productos.find(id => id === element.id);
                  if (exits) {
                     added = true;
                  }
               });
               if (added) {
                  cupones.push(validCoupon);
                  saveLocalStorage(KEY_APPLY_CUPONS, cupones);
                  props.closeModal();
               } else {
                  handlePrintError(`No existe cupon por productos especificos`);
               }
            }
         } else {
            handlePrintError(`No cuenta con ningun producto en su carrito`);
         }
      } else {
         handlePrintError(`No cuenta con ningun producto en su carrito`);
      }
   }

   const handlePrintError = (message: string) => {
      setSnackBarConfig(prev => ({
         ...prev,
         open: true,
         message: message,
      }));
      setTimeout(function(){
         props.closeModal();
     }, 3000);
   }

   return (
        <div className="container">
            <Snackbar
                open={snackBarConfig.open}
                autoHideDuration={snackBarConfig.autoHideDuration}
                onClose={() => setSnackBarConfig(prev => ({ ...prev, open: false }))}
                anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                >
                <Alert
                onClose={() => setSnackBarConfig(prev => ({ ...prev, open: false }))}
                severity={snackBarConfig.severity}
                variant="filled"
                >
                {snackBarConfig.message}
                </Alert>
            </Snackbar>

            <Grid container >
                <Grid item style={{width: "100%"}}>
                    <Grid item >
                        <div className="H-card__fcontainer">
                            <div className="H-card__fcontainer__filter">
                                <span>Filtro:</span>
                                <TextField
                                    type="text"
                                    id="mi-codigo-de-barras"
                                    placeholder="Buscar..."
                                    onChange={handleKeyDown}
                                    className="H-card__fcontainer__filter__input"
                                    style={{ backgroundColor: "white", width: "290px", marginLeft: "10px" }}
                                />
                            </div>
                        </div>
                    </Grid>
                </Grid>
                {
                    (!validated && validCoupon?.fecha_fin) ? (
                        <Grid xs={12} sx={{padding:'5px', borderRadius:'5px', backgroundColor:'red', color: 'white', textAlign: 'center'}}>
                            <span>cupón no válido</span>
                        </Grid>
                    ) : (validated && validCoupon?.fecha_fin) && (
                        <Grid xs={12} sx={{padding:'5px', borderRadius:'5px', backgroundColor:'green', color: 'white', textAlign: 'center'}}>
                            <span>cupón válido</span>
                        </Grid>
                    )
                }
                {
                    validCoupon && validCoupon.fecha_fin && (
                        <>
                        <Grid container sx={{marginTop: '10px', marginBottom: '10px'}}>
                            <Grid item xs={8}>
                                <Typography sx={{textAlign:"left",fontSize:'1em !important'}}>
                                    Fecha de validez:
                                </Typography>
                            </Grid>
                            <Grid item xs={4}>
                                <Typography sx={{textAlign:"right",fontSize:'1em !important'}}>
                                    {validCoupon?.fecha_inicio}
                                </Typography>
                            </Grid>
                        </Grid>
                        <Grid container sx={{marginTop: '10px', marginBottom: '10px'}}>
                            <Grid item xs={8}>
                                <Typography sx={{textAlign:"left",fontSize:'1em !important'}}>
                                    Fecha de expiración:
                                </Typography>
                            </Grid>
                            <Grid item xs={4}>
                                <Typography sx={{textAlign:"right",fontSize:'1em !important'}}>
                                    {validCoupon?.fecha_fin}
                                </Typography>
                            </Grid>
                        </Grid>
                        </>
                    )
                }

                <Divider style={{ width:'100%'}}/>

                <Grid style={{textAlign: 'right', justifyContent:'end', width: '100%', marginTop:'5px'}}>
                    <div className="form-group mb-3 buton_container_modal">
                        <button  className="btn btn-primary" onClick={() => {
                           if (validated) {
                              applyCupon();
                           }
                        }}>
                            Canjear Cupon
                        </button>
                    </div>
                </Grid>

            </Grid>

        </div>
    );
}
