import React, { useEffect } from "react";
import { Autocomplete, Divider, Grid, TextField, Typography } from "@mui/material";
import { listaPrecioService } from "@/service/services/ListaPrecio.service";
import { userService } from "@/service/services/User.service";
import { Snackbar, Alert } from '@mui/material';
import { Loading } from "@/components/common/Loading";

export const DetalleListaPrecio: React.FC<any> = (props: any): JSX.Element => {
      // eslint-disable-next-line
    const { closeModal, onListSucursales, listaPrecio, editMode, setEditMode, getListaPrecios } = props;
    const [loading, setLoading] = React.useState<boolean>(false);
    const [selectedUser, setSelectedUser] = React.useState<any>([])
    const [userBySucursal, setUserBySucursal] = React.useState([]);
    const [dataListPrice, setDataListPrice] = React.useState<any>({
        id:'',
        margen_utilidad: '',
        max_descuento: '',
        nombre: '',
        fecha_inicio: '',
        fecha_fin: '',
        hora_inicio: '',
        hora_fin: '',
        dias: [],
    });
    const [snackBarConfig, setSnackBarConfig] = React.useState<any>({
        open: false,
        severity: 'error',
        message: 'Error',
        autoHideDuration: 3000,
    });

    const dataInicialDias = [
        { value: 0, dia: 'Todos los dias' },
        { value: 1, dia: 'Lunes' },
        { value: 2, dia: 'Martes' },
        { value: 3, dia: 'Miercoles' },
        { value: 4, dia: 'Jueves' },
        { value: 5, dia: 'Viernes' },
        { value: 6, dia: 'Sabado' },
        { value: 7, dia: 'Domingo' }
    ]

    const handleChange = (e) => {
        const handleChangeFormValues = {
            ...dataListPrice,
            [e.target.name]: e.target.value
        }
        setDataListPrice(handleChangeFormValues);
    }

    const getListPrice = async (id) => {
        setLoading(true);
        const resp = await listaPrecioService.getAjustesListaPrecio(id);
        if (resp.status === 200) {
            const dataListaPrecio = {
                id: id,
                margen_utilidad: resp.data && resp.data.margen_utilidad,
                max_descuento: resp.data && resp.data.max_descuento,
                nombre: resp.data && resp.data.nombre,
                fecha_inicio: resp.data && resp.data.fecha_inicio,
                fecha_fin: resp.data && resp.data.fecha_fin,
                hora_inicio: resp.data && resp.data.hora_inicio,
                hora_fin: resp.data && resp.data.hora_fin,
                dias: resp.data && JSON.parse(resp.data.dias),
            }
            setDataListPrice(dataListaPrecio);
            setSelectedUser(resp.usuarios)
        }
        setLoading(false);
    }

    const EditListPrice = async (data, id) => {
        setLoading(true);
        const resp = await listaPrecioService.editAjusteListaPrecios(data, id);
        if (resp.status === 200) {
            getListPrice(id);
            setSnackBarConfig(prev => ({
                ...prev,
                open: true,
                severity: 'success',
                message: "Lista de precios editada satisfactoriamente",
            }));
        }
        setLoading(false);
    }

    const getUsersBySucursal = async (id_sucursal) => {
        const resp = await userService.getUsersBySucursal(id_sucursal);
        if (resp.status === 200) {
            setUserBySucursal(resp.users)
        }
        return resp.users;
    }

    useEffect(() => {
        getListPrice(listaPrecio.id);
        const idSucursalJson = JSON.stringify([listaPrecio.id_sucursal])
        getUsersBySucursal(idSucursalJson);
      // eslint-disable-next-line
    }, [])

    const handleSubmit = async(e) =>{

        e.preventDefault();
        const objData = {
            'dataListPrice': JSON.stringify(dataListPrice),
            'dataUserList': JSON.stringify(selectedUser)
        }

        await EditListPrice(objData,listaPrecio.id)
        closeModal && closeModal();
        getListaPrecios && getListaPrecios();
    }

    const FormularioParametrosLista = (
        <form onSubmit={handleSubmit}>
            <Grid container spacing={2}>
                <Grid item xs={6} container style={{ display: "flex", alignItems: "center" }}>
                    <Grid item xs={4}>
                        <Typography variant="subtitle1" className="custom-input"><b>Nombre:</b></Typography>
                    </Grid>
                    <Grid item xs={8} style={{ display: 'flex', alignItems: "center" }}>
                        <TextField
                            id="nombre"
                            type="text"
                            name="nombre"
                            fullWidth
                            value={dataListPrice.nombre}
                            disabled={editMode}
                            onChange={handleChange}
                            placeholder="Nombre de Lista"
                        // error={errors.nombre && touched.nombre ? true : false}W
                        />
                    </Grid>
                </Grid>
                <Grid item xs={6} container style={{ display: "flex", alignItems: "center" }}>
                    <Grid item xs={4}>
                        <Typography variant="subtitle1" className="custom-input"><b>Usuarios:</b></Typography>
                    </Grid>
                    <Grid item xs={8} style={{ display: 'flex', alignItems: "center" }}>
                        <Autocomplete
                            multiple={true}
                            disablePortal
                            id="combo-box-demo"
                            value={selectedUser}
                            disabled={editMode}
                            options={userBySucursal || []}
                            getOptionLabel={(option) => option.nombre || ""}
                            fullWidth
                            renderInput={(params) => <TextField {...params} label="Escriba para buscar" />}
                            onChange={(e, newValue) => {
                                setSelectedUser(newValue);
                            }}

                        />
                    </Grid>
                </Grid>
                <Grid item xs={6} container style={{ display: "flex", alignItems: "center" }}>
                    <Grid item xs={4}>
                        <Typography variant="subtitle1" className="custom-input"><b>Margen de Utilidad:</b></Typography>
                    </Grid>
                    <Grid item xs={8} style={{ display: 'flex', alignItems: "center" }}>
                        <TextField
                            id="margenUtilidad"
                            type="text"
                            name="margen_utilidad"
                            fullWidth
                            value={dataListPrice.margen_utilidad}
                            disabled={editMode}
                            onChange={handleChange}
                            placeholder="Margen de Utilidad"
                        // error={errors.nombre && touched.nombre ? true : false}
                        />
                    </Grid>
                </Grid>
                <Grid item xs={6} container style={{ display: "flex", alignItems: "center" }}>
                    <Grid item xs={4}>
                        <Typography variant="subtitle1" className="custom-input"><b>Descuento Máximo:</b></Typography>
                    </Grid>
                    <Grid item xs={8} style={{ display: 'flex', alignItems: "center" }}>
                        <TextField
                            id="descuentoMaximo"
                            type="text"
                            name="max_descuento"
                            fullWidth
                            value={dataListPrice.max_descuento}
                            disabled={editMode}
                            onChange={handleChange}
                            placeholder="Descuento Máximo"
                        // error={errors.nombre && touched.nombre ? true : false}W
                        />
                    </Grid>
                </Grid>
                <Grid item xs={6} container style={{ display: "flex", alignItems: "center" }}>
                    <Grid item xs={4}>
                        <Typography variant="subtitle1" className="custom-input"><b>Fecha de Inicio:</b></Typography>
                    </Grid>
                    <Grid item xs={8} style={{ display: 'flex', alignItems: "center" }}>
                        <TextField
                            id="fechaInicio"
                            type="date"
                            name="fecha_inicio"
                            fullWidth
                            value={dataListPrice.fecha_inicio}
                            disabled={editMode}
                            onChange={handleChange}
                            placeholder="Fecha de Inicio"
                        // error={errors.nombre && touched.nombre ? true : false}W
                        />
                    </Grid>
                </Grid>
                <Grid item xs={6} container style={{ display: "flex", alignItems: "center" }}>
                    <Grid item xs={4}>
                        <Typography variant="subtitle1" className="custom-input"><b>Fecha de Fin:</b></Typography>
                    </Grid>
                    <Grid item xs={8} style={{ display: 'flex', alignItems: "center" }}>
                        <TextField
                            id="fechaFin"
                            type="date"
                            name="fecha_fin"
                            fullWidth
                            value={dataListPrice.fecha_fin}
                            disabled={editMode}
                            onChange={handleChange}
                            placeholder="Fecha de Inicio"
                        // error={errors.nombre && touched.nombre ? true : false}W
                        />
                    </Grid>
                </Grid>

                <Grid item xs={6} container style={{ display: "flex", alignItems: "center" }}>
                    <Grid item xs={4}>
                        <Typography variant="subtitle1" className="custom-input"><b>Dias:</b></Typography>
                    </Grid>
                    <Grid item xs={8} style={{ display: 'flex', alignItems: "center" }}>
                        <Autocomplete
                            multiple={true}
                            disablePortal
                            id="combo-box-demo"
                            value={dataListPrice.dias}
                            disabled={editMode}
                            options={dataInicialDias || []}
                            getOptionLabel={(option) => option.dia || ""}
                            fullWidth
                            renderInput={(params) => <TextField {...params} label="Escriba para buscar" />}
                            onChange={(e, newValue) => {
                                setDataListPrice(prev => ({ ...prev, dias: newValue }))
                            }}

                        />
                    </Grid>
                </Grid>
                <Grid item xs={6} container style={{ display: "flex", alignItems: "center" }}>
                    <Grid item xs={4}>
                        <Typography variant="subtitle1" className="custom-input"><b>Hora de Inicio:</b></Typography>
                    </Grid>
                    <Grid item xs={8} style={{ display: 'flex', alignItems: "center" }}>
                        <TextField
                            id="horaInicio"
                            type="time"
                            name="hora_inicio"
                            fullWidth
                            value={dataListPrice.hora_inicio}
                            disabled={editMode}
                            onChange={handleChange}
                            placeholder="Hora de Inicio"
                        // error={errors.nombre && touched.nombre ? true : false}W
                        />
                    </Grid>
                </Grid>
                <Grid item xs={6} container style={{ display: "flex", alignItems: "center" }}>
                    <Grid item xs={4}>
                        <Typography variant="subtitle1" className="custom-input"><b>Hora de Fin:</b></Typography>
                    </Grid>
                    <Grid item xs={8} style={{ display: 'flex', alignItems: "center" }}>
                        <TextField
                            id="horaFin"
                            type="time"
                            name="hora_fin"
                            fullWidth
                            value={dataListPrice.hora_fin}
                            disabled={editMode}
                            onChange={handleChange}
                            placeholder="Fecha de Inicio"
                        // error={errors.nombre && touched.nombre ? true : false}W
                        />
                    </Grid>
                </Grid>
                <Grid item xs={6} container style={{ display: "flex", alignItems: "center" }}></Grid>

                <Divider sx={{ my: 2 }} />
                {
                    editMode && (
                        <div className="form-group mb-3 buton_container_modal" style={{textAlign: 'end'}} >
                            <button onClick={() => { setEditMode(false) }} className="btn btn-primary">
                                Editar
                            </button>
                        </div>
                    ) /* : (
                        <div className="form-group mb-3 buton_container_modal">
                            <button onClick={(e) => { handleSubmit(e) }} className="btn btn-primary">
                                Guardar
                            </button>
                        </div>
                    ) */
                }
                {
                    !editMode && (
                        <div className="form-group mb-3 buton_container_modal" style={{display: 'flex', justifyContent: 'end'}}>
                            <button onClick={(e) => { handleSubmit(e) }} className="btn btn-primary">
                                Guardar
                            </button>
                        </div>
                    )
                }

            </Grid>
        </form>

    )

    return (
        <>
            {loading && <Loading/>}
            <Snackbar
                open={snackBarConfig.open}
                autoHideDuration={snackBarConfig.autoHideDuration}
                onClose={() => setSnackBarConfig(prev => ({ ...prev, open: false }))}
                anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                >
                <Alert
                onClose={() => setSnackBarConfig(prev => ({ ...prev, open: false }))}
                severity={snackBarConfig.severity}
                variant="filled"
                >
                {snackBarConfig.message}
                </Alert>
            </Snackbar>
            {FormularioParametrosLista}
        </>
    )
}
