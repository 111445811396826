import { RecetaRepository } from "../repositories/Receta.repository"

export const RecetaService = {
    getRecetas,
    addInsumo,
    updateInsumo,
    deleteInsumo,
    getTemplateImport,
    importRecetas
}

async function getRecetas(data?: {}) {
    const res = await RecetaRepository.get(data)
    return res
}

async function addInsumo(data: {}) {
    const res = await RecetaRepository.create(data)
    return res
}

async function updateInsumo(id: number, data: {}) {
    const res = await RecetaRepository.update(id, data)
    return res
}

async function deleteInsumo(id: number) {
    const res = await RecetaRepository.delete(id)
    return res
}

async function getTemplateImport() {
    const res = await RecetaRepository.getTemplateImport()
    return res
}

async function importRecetas(data: any) {
    const res = await RecetaRepository.import(data)
    return res
}