import {  Dialog, DialogContent, DialogTitle } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import  "./Popup.sass"

export const PopupComponent: React.FC<any> = (props:any): JSX.Element => {
    const {title, children, openPopup, setOpenPopup, largo} = props;

    return(
        <Dialog open={openPopup} maxWidth={largo} fullWidth classes={{
            root: 'noseeeeeeeeeeee'
        }}>
            <DialogTitle classes={{
               root: "modal__title"
            }}>
               <div>
                  <span>{title}</span>
                  <IconButton aria-label="delete" size="small" color="error" onClick={() =>{setOpenPopup(false)}}>
                     <CloseIcon sx={{ fontSize: 20 }}/>
                  </IconButton>
               </div>
            </DialogTitle>
            <DialogContent dividers classes={{root:"modal__container"}}>
                <div>
                    {children}
                </div>

            </DialogContent>
        </Dialog>
    );

}
