export const KEY_LOCAL_STORAGE  = process.env.REACT_APP_KEY_COOKIE || '_';
export const KEY_USER_DATA      = 'userData';
export const KEY_EMPRESA        = 'userEmpresa'
export const KEY_ARRAY_MY_MENU  = 'arrayMyMenu';
export const KEY_OBJECT_MY_MENU = 'objectMyMenu';
export const KEY_PARMS          = 'parms';
export const KEY_TOKEN          = 'token';
export const KEY_TOOGLE_MENU    = 'userToogleMenu';
export const KEY_TIPO_ROL       = 'tipoRol';
export const KEY_RUT            = 'RUT';
export const KEY_BODEGAS        = 'bodegas';
export const KEY_BODEGA_SELECT  = 'bodegaSelect';
export const KEY_EDIT_PEDIDO    = 'editarPedido';
export const KEY_EDIT_COMPRA_ID    = 'editarCompraID';
export const KEY_EDIT_COMPRA_PROVEEDOR_ID    = 'editarCompraProvID';
export const KEY_EDIT_COMPRA    = 'editarCompra';
export const KEY_BENEFICIO_PRO    = 'benePro';
export const KEY_BENEFICIO_MON    = 'beneMon';
export const KEY_APPLY_CUPONS    = 'cuponesApply';
export const KEY_APPLY_BENEF_POINTS    = 'benefitApply';
export const KEY_CLIENT_POINTS    = 'clientPoints';
export const CLIENTE_SHOP        = 'userClient';


export const TOKEN_API            = 't_api';
export const RUC_EMPRESA            = 'r-emp';
export const PRODUCTS_CARD            = 'products_c';
