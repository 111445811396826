import { Loading } from "@/components/common/Loading";
import { Protected } from "@/components/layout/Protected";
import { productoService } from "@/service/services/Producto.service";
import { KEY_USER_DATA } from "@/toolbox/constants/local-storage";
import { readLocalStorage } from "@/toolbox/helpers/local-storage-helper";
import { Button, Grid, IconButton, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, TextField, Tooltip, Typography } from "@mui/material";
import { FC, useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { CodigoBarrasModal } from "./CodigoBarrasModal";
import { CodigoBarraService } from "@/service/services/CodigoBarra.service";
import { ModalConfirm } from "@/components/common/ModalConfirm/ModalConfirm";
import ArrowCircleLeftIcon from '@mui/icons-material/ArrowCircleLeft';

export const CodigoBarras: FC = () => {

    const params: { id: string } = useParams();
    const { id } = params
    const history = useHistory();

    const dataUsuario = readLocalStorage(KEY_USER_DATA)

    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(20);
    const [loading, setLoading] = useState<boolean>(false)
    const [showModalBarCode, setShowModalBarCode] = useState<boolean>(false)
    const [barCodes, setBarCodes] = useState<any[]>([])
    const [action, setAction] = useState<string>('')
    const [rowSelected, setRowSelected] = useState<any>(null)
    const [showModalDelete, setShowModalDelete] = useState<boolean>(false)
    const [nombreProducto, setNombreProducto] = useState<string>('')
    const [loadingSubmit, setLoadingSubmit] = useState<boolean>(false)

    useEffect(() => {
        getBarCodesApi()
    }, [])

    const getBarCodesApi = async () => {
        setLoading(true)
        const response = await productoService.getProductsByCompany(dataUsuario.user.id_empresa)
        if (response.productos) {
            const producto = response.productos?.find(producto => producto.id === parseInt(id))
            const codigoBarras = producto?.codigos_barra.filter(barra => barra.estado === 1)
            setNombreProducto(producto.nombre || '')
            setBarCodes(codigoBarras || [])
        }
        setLoading(false)
    }

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const onDelete = (rowSelected) => {
        setRowSelected(rowSelected)
        setShowModalDelete(true)
    }

    const confirmDelete = async () => {
        setLoadingSubmit(true)
        await CodigoBarraService.deleteBarCode(rowSelected.id)
        getBarCodesApi()
        setShowModalDelete(false)
        setLoadingSubmit(false)
    }

    const goBack = () => {
        history.push('/mis-productos');
    };

    return (<Protected>
        {loading && <Loading />}
        <Grid container spacing={2}>
            <Grid item xs={12} display='flex' alignItems='center'>
                <Grid className="btn-back-container" onClick={goBack} sx={{ cursor: 'pointer', border: '1px solid #188dcd', borderRadius: '4px', padding: '8px', display: 'flex', alignItems: 'center' }}>
                    <ArrowCircleLeftIcon fontSize='small' sx={{ color: '#188dcd', marginRight: '2px' }} />
                    <Typography className='btn-back-text'>Volver</Typography>
                </Grid>
            </Grid>
            <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'center' }}>
                <Typography variant="h4">CODIGO DE BARRAS: {nombreProducto}</Typography>
            </Grid>
            <Grid item container xs={12}
                sx={{
                    alignItems: "center",
                    display: "flex",
                    justifyContent: "space-between",
                    flexWrap: "wrap",
                    marginBottom: "10px",
                }}
            >
                <Grid item>

                </Grid>
                <Grid
                    item
                    sx={{ textAlign: "right" }}
                >
                    <Button
                        onClick={() => {
                            setAction('create');
                            setRowSelected(null);
                            setShowModalBarCode(true)
                        }}
                        sx={{
                            border: "solid 1px #73B2FF",
                            mt: "10px",
                            color: "#73B2FF",
                            "&:hover": {
                                bgcolor: "#73B2FF",
                                color: "#fff",
                            },
                        }}
                    >
                        Nuevo codigo de barras
                    </Button>
                </Grid>
            </Grid>

            <Grid item xs={12}>
                <TableContainer component={Paper}>
                    <Table sx={{ minWidth: 650 }} aria-label="simple table">
                        <TableHead>
                            <TableRow>
                                <TableCell>{`N°`}</TableCell>
                                <TableCell align="left">Codigo</TableCell>
                                <TableCell align="center">Opciones</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {barCodes?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((barCode, index) =>
                                <TableRow key={index}>
                                    <TableCell align="left">{index + 1 + page * rowsPerPage}</TableCell>
                                    <TableCell align="left">{barCode.codigo_barra}</TableCell>
                                    <TableCell align="center">
                                        <Tooltip title={"Editar codigo de barras"}>
                                            <IconButton onClick={() => { setAction('edit'); setRowSelected(barCode); setShowModalBarCode(true) }}>
                                                <EditIcon color="primary" />
                                            </IconButton>
                                        </Tooltip>
                                        <Tooltip title={"Eliminar codigo de barras"}>
                                            <IconButton onClick={() => onDelete(barCode)}>
                                                <DeleteIcon color="error" />
                                            </IconButton>
                                        </Tooltip>
                                    </TableCell>
                                </TableRow>
                            )}
                        </TableBody>
                    </Table>
                </TableContainer>
                <TablePagination
                    rowsPerPageOptions={[20, 30, 40]}
                    component="div"
                    count={barCodes?.length || 0}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                />
            </Grid>
        </Grid>
        {
            showModalBarCode &&
            <CodigoBarrasModal
                open={showModalBarCode}
                setOpenPopup={setShowModalBarCode}
                action={action}
                rowSelected={rowSelected}
                onList={getBarCodesApi}
                idproducto={parseInt(id)}
            />
        }
        {
            showModalDelete &&
            <ModalConfirm
                open={showModalDelete}
                closeModal={() => setShowModalDelete(false)}
                onCancel={() => setShowModalDelete(false)}
                onConfirm={confirmDelete}
                status2={loadingSubmit}
                title={"¿Esta seguro de eliminar el codigo de barras?"}
            />

        }
    </Protected>)
}