import { Props } from "./MyOrders.type";
import React from "react";
import { Button, Card, CardActions, CardContent, Container,  Grid, Typography } from "@mui/material";
import { Loading } from "@/components/common/Loading";
import { ticketService } from "@/service/services/Ticket.service";
import { ModalOrders } from "./components/ModalOrders";
import { PopupComponent } from "@/components/common/Popup";
import { HeaderEcommerce } from "../Header";

export const MyOrders: React.FC<Props> = (props:Props):JSX.Element => {
    const [loading, setLoading] = React.useState<boolean>(false);
    const [tickets,setTickest] = React.useState<Array<any>>([]);
    const [openPopup, setOpenPopup] = React.useState(false);
    const [ticketSelected, setTicketSelect] = React.useState<number>(0);

    React.useEffect(() => {
        getPedidos();
    },[]);

    const getPedidos = async() => {
        setLoading(true);
        await ticketService.getTicketsByClient().then((data)=>{
            if(data && data.status === 200){
                setTickest(data.tickets || []);
            }
        });
        setLoading(false);
    }

    return (
        <Container>
            {loading && <Loading/>}
            <Grid container sx={{ marginBottom: '25px', alignContent: 'center', marginTop: '20px' }}>
            <HeaderEcommerce/>
            <Container maxWidth='xl' sx={{ mt: 1, mb: 2 }}>

                <Grid container  spacing={2}>
                    <Grid item xs={12} sm={8} md={8}>
                        <Typography variant='h4' >Mis Pedidos</Typography>
                    </Grid>
                </Grid>

                <Grid container  className='orders-content'>
                    <Grid className='column-content'>
                        <Typography style={{marginLeft: '7px'}}>Pendientes</Typography>
                        <Grid className= 'column-cards'>
                            {
                               // eslint-disable-next-line
                                tickets.length > 0 && tickets.map((ticket, i) => {
                                    if(ticket.state === 'pendiente'){
                                        return (
                                            <Card sx={{ minWidth: 275, margin:'8px'}} key={i}>
                                                <CardContent>
                                                    <Typography className='text'>
                                                        <b>{ticket.n_ticket}</b>
                                                    </Typography>
                                                    <Typography className='text'>
                                                        <b>$ {ticket.monto_pedido}</b>
                                                    </Typography>
                                                </CardContent>
                                                <CardActions>
                                                    <Button variant='contained'
                                                        color="primary" size="small"
                                                        style={{textTransform: "capitalize"}}
                                                        onClick={()=>{
                                                            setOpenPopup(true);
                                                            setTicketSelect(ticket.id);
                                                        }}
                                                        >
                                                            Ver ticket
                                                    </Button>
                                                </CardActions>
                                            </Card>
                                        )
                                    }
                                })
                            }
                        </Grid>

                    </Grid>

                    <Grid className='column-content'>
                        <Typography style={{marginLeft: '7px'}}>Por Entregar</Typography>
                        <Grid className= 'column-cards'>
                            {
                               // eslint-disable-next-line
                                tickets.length > 0 && tickets.map((ticket, i) => {
                                    if(ticket.state === 'por-entregar'){
                                        return (
                                            <Card sx={{ minWidth: 275, margin:'8px'}} key={i}>
                                                <CardContent>
                                                    <Typography className='text'>
                                                        <b>{ticket.n_ticket}</b>
                                                    </Typography>
                                                    <Typography className='text'>
                                                        <b>$ {ticket.monto_pedido}</b>
                                                    </Typography>
                                                </CardContent>
                                                <CardActions>
                                                    <Button variant='contained'
                                                        color="primary" size="small"
                                                        style={{textTransform: "capitalize"}}
                                                        onClick={()=>{
                                                            setOpenPopup(true);
                                                            setTicketSelect(ticket.id);
                                                        }}
                                                        >
                                                            Ver ticket
                                                    </Button>
                                                </CardActions>
                                            </Card>
                                        )
                                    }
                                })
                            }
                        </Grid>

                    </Grid>

                    <Grid className='column-content'>
                        <Typography style={{marginLeft: '7px'}}>Entregados</Typography>
                        <Grid className= 'column-cards'>
                            {
                               // eslint-disable-next-line
                                tickets.length > 0 && tickets.map((ticket, i) => {
                                    if(ticket.state === 'entregado'){
                                        return (
                                            <Card sx={{ minWidth: 275, margin:'8px'}} key={i}>
                                                <CardContent>
                                                    <Typography className='text'>
                                                        <b>{ticket.n_ticket}</b>
                                                    </Typography>
                                                    <Typography className='text'>
                                                        <b>$ {ticket.monto_pedido}</b>
                                                    </Typography>
                                                </CardContent>
                                                <CardActions>
                                                    <Button variant='contained'
                                                        color="primary" size="small"
                                                        style={{textTransform: "capitalize"}}
                                                        onClick={()=>{
                                                            setOpenPopup(true);
                                                            setTicketSelect(ticket.id);
                                                        }}
                                                        >
                                                            Ver ticket
                                                    </Button>
                                                </CardActions>
                                            </Card>
                                        )
                                    }
                                })
                            }
                        </Grid>

                    </Grid>

                </Grid>

            </Container>
            </Grid>
            { openPopup &&
               <PopupComponent
               largo="md"
               title = {'Detalle del Ticket'}
               openPopup = {openPopup}
               setOpenPopup = {setOpenPopup}
               >
               <ModalOrders idTicket={ticketSelected} setOpenPopup={setOpenPopup} />
            </PopupComponent>
            }
        </Container>
    );
}
