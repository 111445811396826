import { tarjetaExistenciaRepository } from "../repositories/TarjetaExistencia.repository";

export const tarjetaExistenciaService = {
    getTarjetaExistencia,
    crearTarjetaExistencia,
    getPDF,
    getSobrantes,
    createDet
}

async function getTarjetaExistencia (fecha, idCompany) {
    const res = await tarjetaExistenciaRepository.getTarjetaExistencia(fecha, idCompany);
    return res;
}

async function crearTarjetaExistencia (fecha, idCompany, payload) {
    const res = await tarjetaExistenciaRepository.crearTarjetaExistencia(fecha, idCompany, payload);
    return res;
}

async function getPDF(fecha, idCompany) {
    const res = await tarjetaExistenciaRepository.getPDF(fecha, idCompany);
    return res;
}

async function getSobrantes(data: {}) {
    const res = await tarjetaExistenciaRepository.getSobrantes(data);
    return res;
}

async function createDet(payload: {}) {
    const res = await tarjetaExistenciaRepository.createDet(payload);
    return res;
}