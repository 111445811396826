import { Alert, Divider, Grid, Snackbar, TextField, Tooltip, Typography } from "@mui/material"
import { FC, useEffect, useState } from "react"
import { Popup } from "../Editor/components/Popup"
import { Form, Formik } from "formik"
import PriorityHighIcon from '@mui/icons-material/PriorityHigh';
import { CodigoBarraService } from "@/service/services/CodigoBarra.service";

interface CodigoBarrasModalProps {
    open: boolean
    setOpenPopup: React.Dispatch<React.SetStateAction<boolean>>
    action: string
    rowSelected: any
    idproducto: number
    onList: () => Promise<void>
}

export const CodigoBarrasModal: FC<CodigoBarrasModalProps> = (props) => {
    const { open, action, setOpenPopup, rowSelected, onList, idproducto } = props

    const [title, setTitle] = useState<string>('')
    const [data, setData] = useState({
        id: rowSelected?.id || 0,
        codigo_barra: rowSelected?.codigo_barra || ''
    });
    const [snackBarConfig, setSnackBarConfig] = useState<any>({
        open: false,
        severity: 'error',
        message: 'Error',
        autoHideDuration: 3000,
    });
    const [loadingSubmit, setLoadingSubmit] = useState<boolean>(false);

    useEffect(() => {
        switch (action) {
            case 'create':
                setTitle('Crear producto')
                break;

            case 'edit':
                setTitle('Editar producto')
                break;

            default:
                break;
        }
    }, [action])

    const validateForm = (values) => {
        let errors: any = {};

        if (!values.codigo_barra) errors.codigo_barra = "campo es requerido";

        return errors;
    }

    const onSubmit = async (values) => {
        try {
            const payloadCreate = {
                codigos_barra: `[\"${values.codigo_barra}\"]`
            }

            const payloadUpdate = {
                codigo_barra: values.codigo_barra
            }

            await (action === 'create' ? CodigoBarraService.registerBarCode(idproducto, payloadCreate) : CodigoBarraService.updateBarCode(rowSelected.id, payloadUpdate))
            setOpenPopup(false)
            onList()
        } catch (error) {
            console.error("Error:", error);
        }
    }

    return (<>
        <Snackbar
            open={snackBarConfig.open}
            autoHideDuration={snackBarConfig.autoHideDuration}
            onClose={() => setSnackBarConfig(prev => ({ ...prev, open: false }))}
            anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        >
            <Alert
                onClose={() => setSnackBarConfig(prev => ({ ...prev, open: false }))}
                severity={snackBarConfig.severity}
                variant="filled"
            >
                {snackBarConfig.message}
            </Alert>
        </Snackbar>

        <Popup
            largo="sm"
            title={title}
            openPopup={open}
            setOpenPopup={setOpenPopup}
        >
            <Formik enableReinitialize initialValues={data} validate={(values) => validateForm(values)} onSubmit={onSubmit}>
                {({ values, errors, touched, handleSubmit, handleChange, setFieldValue }) => {
                    return (
                        <Form onSubmit={handleSubmit}>
                            <Grid container spacing={3} alignItems="center" justifyContent="center">
                                <Grid item xs={12} container style={{ display: "flex", alignItems: "center" }}>
                                    <Grid item xs={4}>
                                        <Typography variant="subtitle1" className="custom-input"><b>Código de barras:</b></Typography>
                                    </Grid>
                                    <Grid item xs={8} style={{ display: 'flex', alignItems: "center" }}>
                                        {
                                            errors.codigo_barra && (touched.codigo_barra ? true : false) && (
                                                <Tooltip title={errors.codigo_barra}>
                                                    <PriorityHighIcon className={'iconWarning'} />
                                                </Tooltip>
                                            )
                                        }
                                        <TextField
                                            id="codigo_barra"
                                            type="text"
                                            name="codigo_barra"
                                            fullWidth
                                            value={values.codigo_barra}
                                            onChange={handleChange}
                                            placeholder="Codigo del producto"
                                            error={errors.codigo_barra && touched.codigo_barra ? true : false}
                                        />
                                    </Grid>
                                </Grid>

                            </Grid>

                            <Divider sx={{ my: 2 }} />

                            <div className="form-group mb-3 buton_container_modal">
                                <button type="submit" className="btn btn-primary" disabled={loadingSubmit}>
                                    {action === 'create' ? 'Agregar' : 'Actualizar'}
                                </button>
                            </div>

                        </Form>
                    )
                }}
            </Formik>
        </Popup>
    </>)
}