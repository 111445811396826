import { Loading } from "@/components/common/Loading";
import { Divider, Grid, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material";
import React from "react";
import { createTheme, ThemeProvider } from "@mui/material";
import Paper from '@mui/material/Paper';
import { styled } from '@mui/material/styles';
import { esES } from "@mui/material/locale";
import { ticketService } from "@/service/services/Ticket.service";

const theme = createTheme(
    {
        palette: {
            primary: { main: '#1976D2' },
        },
    },
    esES,
 );


 const Titulo = styled('div')(({ theme }) => ({
    ...theme.typography.button,
    padding: theme.spacing(1),
    fontWeight: 'bold',
    fontSize: '18px',
    color: 'black',
  }));

export const ModalOrders: React.FC<any> = (props:any): JSX.Element => {

    const {idTicket, idPedido} = props;

    const [loading, setLoading] = React.useState<boolean>(false);
    const [detalle, setDetalle] = React.useState<Array<any>>([]);
    const [ticket, setTicket] = React.useState<any>(null);
    const [pedido, setPedido] = React.useState<any>(null);

    React.useEffect(() => {
        if(idTicket){
            setLoading(true);
            ticketService.getTicketDetalle(idTicket).then(async (data) => {
                if (data?.status === 200) {
                    setTicket(data.ticket);
                    let arrayProducts = [];
                    for(let i = 0; i < data.ticket.detalle_ticket.length; i++){
                        const productos = data.ticket.detalle_ticket[i].productos;
                        arrayProducts.push({
                           "cantidad": data.ticket.detalle_ticket[i].cantidad,
                           "id_producto": data.ticket.detalle_ticket[i].id_producto,
                           "codigo_erp": productos?.codigo_erp,
                           "nombre": productos?.nombre,
                           "id_tipo_producto": productos?.id_tipo_producto,
                           "precio_standar": productos?.precio_standar,
                           "precio_oferta": productos?.precio_oferta
                        });
                    }
                    setDetalle(arrayProducts);
                    setLoading(false)
                } else {
                    setLoading(false)
                }
            })
        }
        if (idPedido) {
            setLoading(true);
            ticketService.getPedidoDetalle(idPedido).then(async (data) => {
               if (data?.status === 200) {
                    setPedido(data.pedido);
                    let arrayProducts = [];
                  // eslint-disable-next-line
                    (data.tickets).map((item) => {
                        for(let i=0; i < item.detalle_ticket.length; i++){
                            const productos = item.detalle_ticket[i].productos;
                            arrayProducts.push({
                            "cantidad": item.detalle_ticket[i].cantidad,
                            "id_producto": item.detalle_ticket[i].id_producto,
                            "codigo_erp": productos?.codigo_erp,
                            "nombre": productos?.nombre,
                            "id_tipo_producto": productos?.id_tipo_producto,
                            "precio_standar": productos?.precio_standar,
                            "precio_oferta": productos?.precio_oferta
                            });
                        }
                    });

                  setDetalle(arrayProducts);
                  setLoading(false)
               } else {
                setLoading(false)
               }
            })
         }
         // eslint-disable-next-line
    }, [])

    return (
        <div className="container">
            {loading && <Loading/>}
            <Grid container spacing={0} direction="column">
                <Grid item xs={3} style={{textAlign:"center"}}>
                    <Titulo>{"Productos"}</Titulo>
                </Grid>

                <TableContainer component={Paper}>
                    <ThemeProvider theme={theme}>
                        <Table sx={{ minWidth: 650 }} aria-label="simple table">

                        <TableHead>
                            <TableRow>
                                <TableCell align="center">Codigo</TableCell>
                                <TableCell align="center">Nombre</TableCell>
                                <TableCell align="center">Precio</TableCell>
                                <TableCell align="center">Cantidad</TableCell>
                            </TableRow>
                        </TableHead>

                        <TableBody>
                            {(detalle||[]).map((item,index) => (
                                <TableRow
                                    key={item.id}
                                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                    classes={{
                                    hover: 'hover-row-t'
                                    }}
                                >
                                    <TableCell align="center">{item.codigo_erp}</TableCell>
                                    <TableCell align="center">{item.nombre}</TableCell>
                                    <TableCell align="center">
                                    <span className={ item.precio_oferta ? 'X' : 'o'}>{ item.precio_oferta ? item.precio_oferta : item.precio_standar}</span>
                                    {/* <span className="descuento" style={{color: "red"}}>
                                        {
                                            ValiarBeneficio(item.id_producto, item.cantidad ? item.cantidad: 1) ?
                                            `  - ${ValiarBeneficio(item.id_producto, item.cantidad ? item.cantidad: 1)}` : ''
                                        }
                                    </span> */}
                                    </TableCell>
                                    <TableCell align="center">{item.cantidad}</TableCell>

                                </TableRow>
                            ))}
                        </TableBody>

                        </Table>
                    </ThemeProvider>
                </TableContainer>
                <Divider style={{width:"100%", height:"3px", marginTop:"15px"}}/>
                <Grid item xs={12} style={{display: "flex", alignItems: "end", marginTop: "20px", justifyContent: "end", marginRight: "5px"}}>
                    <span style={{width:'150px'}}><b>Monto a Pagar:</b></span>
                    <span style={{fontSize: "20px"}}>{ticket?.monto_pedido || pedido?.monto_total}</span>
                </Grid>
            </Grid>

        </div>
    );
}
