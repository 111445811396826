import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import { Loading } from '@/components/common/Loading/Loading';
import DialogTitle from '@mui/material/DialogTitle';
import { Divider } from '@mui/material';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import { editor } from '@/service/services/Editor.service';
import { distribucionRepository } from '@/service/repositories/Distribucion.repository';

export const DeletedProduct: React.FC<any> = (props:any): JSX.Element => {
    const { open, closeModal, ProductId, TipoProductId, id_sub, isSupply, handleLoad, getProductos, text } = props;

    const [loading, setLoading] = React.useState<boolean>(false);

    const onDelete = async() => {
        setLoading(true);
        if (TipoProductId && TipoProductId === 1) {
            if(isSupply === 1){
                await distribucionRepository.deleteByIdInsumo(ProductId);
            }else{
                await distribucionRepository.deleteByIdProduct(ProductId);
            }
            await editor.eliminarProducto(ProductId,id_sub);
        } else if (TipoProductId && TipoProductId === 2) {
            await editor.deleteItemsProductoAgrupado(ProductId,id_sub);
        } else if (TipoProductId && TipoProductId === 3) {
            await editor.deleteItemsMenu(ProductId,id_sub);
        }
        setLoading(false);
        getProductos && getProductos();
        handleLoad && handleLoad();
        closeModal && closeModal();
    }

    return (
        <div>
            {loading && <Loading/>}
            <Dialog
                open={open}
                onClose={closeModal}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                {"Eliminar producto"}
                </DialogTitle>
                <Divider/>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                    { text || '¿Está seguro de eliminar el producto del submódulo?'}
                    </DialogContentText>
                </DialogContent>
                <Divider/>
                <DialogActions>
                <Button onClick={closeModal}>Cancelar</Button>
                <Button onClick={onDelete} autoFocus>
                    Aceptar
                </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}
