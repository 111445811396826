import { Divider, Grid, Typography } from "@mui/material";
import React, { useEffect, useRef } from "react";
import QRCode from "qrcode";
import { useReactToPrint } from 'react-to-print';
import { PrintQR } from "../PrintQR";

export const CodigoQR: React.FC<any> = (props:any): JSX.Element => {

    const { mesa} = props;
    const [imagValue, setImgValue] = React.useState<string>("");
    const componentRef = useRef();
    const [dataPrint, setDataPrint] = React.useState<any>({});

    useEffect(()=> {
        if (mesa.qr) {
            try {
                const fetchData = async () => {
                    const data = await QRCode.toDataURL(mesa.qr);
                    setImgValue(data);
                    setDataPrint({
                        mesa: mesa.nombre,
                        imagValue: data,
                    })
                }
                fetchData();
                
            } catch (error) {
                alert(error)
            }
        }
        // eslint-disable-next-line
    }, [])

    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
  
    });

    const handleDownloadImage = () => {
        let tag = document.getElementById("qr-mesa-id");
        // tag.title = mesa.name;
        tag.click();
    }
    return(
        <div className="container">
            <Grid container spacing={2} sx={{display: "block"}}>
                <Grid textAlign="center" justifyContent="center">
                    <Typography variant="subtitle1" sx={{marginTop: '10px'}}><b>{mesa && mesa.nombre}</b></Typography>
                    <Grid sx={{padding: "10px"}}>
                        {
                            imagValue ? (
                                /* ponerlo dentro de un img */
                                <a href={imagValue} download={mesa.nombre} id="qr-mesa-id" >
                                    <img src={imagValue} alt="Mesa QR" style={{minWidth: "250px", minHeight: "250px"}}/>
                                </a>
                            ) : null
                        }
                    </Grid>
                </Grid>

                <Divider sx={{ my: 2 }} />

                <Grid sx={{display: "flex", alignItems: "center", justifyContent: "end" }}>
                    <div className="form-group mb-3 buton_container_modal" style={{marginRight: '5px'}}>
                        <button type="submit" className="btn btn-primary" onClick={handleDownloadImage}>
                            Descargar
                        </button>
                    </div>

                    <div className="form-group mb-3 buton_container_modal">
                        <button type="submit" className="btn btn-primary" onClick={handlePrint}>
                            Imprimir
                        </button>
                    </div>
                </Grid>

            </Grid>

            <div style={{ display: "none" }}>
               <div ref={componentRef}>
                  <PrintQR
                    data={dataPrint}
                  />
               </div>
            </div>

        </div>
    );
}