import { Props } from "./ModalDetalle.type";
import { styled } from '@mui/material/styles';
import { Grid, Table, TableCell, TableContainer, TableHead, TableRow, TextField, TableBody } from "@mui/material";
import Paper from '@mui/material/Paper';
import { compraService } from "@/service/services/Compra.service";
import React, { useEffect } from "react";
import { Loading } from "@/components/common/Loading";
import { moneyFormatInt } from "@/toolbox/helpers/money.helper";

const Titulo = styled('div')(({ theme }) => ({
    ...theme.typography.button,
    padding: theme.spacing(1),
    fontWeight: 'bold',
    fontSize: '18px',
    color: 'black',
  }));

export const ModalDetalle: React.FC<any> = (props:Props): JSX.Element => {

    const {compraId} = props;

    const [loading,setLoading] = React.useState<boolean>(false);
    const [detalleCompra, setDetalleCompra] = React.useState({
        folio: '',
        fecha: '',
        proveedor: {
            razon_social: ''
        }

    });
    const [detalleProducto, setDetalleProducto] = React.useState<Array<any>>([]);
    const [totalCard,setTotalCard] = React.useState<number>(0);


    useEffect(() => {
        getDetalleCompra();
    }, // eslint-disable-next-line
    [])

    useEffect(() => {
        let total: number = 0;
        for(let i = 0; i < detalleProducto.length; i++){
            if(detalleProducto[i].precio_unidad && detalleProducto[i].cantidad){
                total = (total + ((detalleProducto[i].precio_unidad )*( detalleProducto[i].cantidad)))
            }
        }
        setTotalCard(total);
    }, [detalleProducto])

    const getDetalleCompra = async() => {
        setLoading(true);
        await compraService.listByCompra(compraId).then((data) =>{
            setDetalleCompra(data[0]);
            setDetalleProducto(data[1]);
            setLoading(false);
        })
    }

    return (
        <Grid container spacing={0} direction="column" alignItems="center" justifyContent="center" style={{ marginBottom: '15px', marginTop:'5px'}} >
            {loading && <Loading/>}

            <Grid item xs={3} style={{ marginBottom:'10px'}}>
               <Titulo>{"Informacion de la Compra"}</Titulo>
            </Grid>

            <Grid container>

                <Grid item xs={6} style={{display: "flex", alignItems: "center", marginTop: "20px"}}>
                    <span style={{width: "100px"}}>Folio: </span>
                    <TextField
                    disabled
                        size="small"
                        value={detalleCompra && detalleCompra.folio}/>
               </Grid>

               <Grid item xs={6} style={{display: "flex", alignItems: "center", marginTop: "20px"}}>
                  <span style={{width: "100px"}}>Proveedor: </span>
                    <TextField
                    disabled
                     size="small"
                     value={detalleCompra && (detalleCompra.proveedor?.razon_social || '---' )}/>
               </Grid>

               <Grid item xs={6} style={{display: "flex", alignItems: "center", marginTop: "20px"}}>
                  <span style={{width: "100px"}}>Fecha: </span>
                    <TextField
                    disabled
                     size="small"
                     value={detalleCompra && detalleCompra.fecha}/>
               </Grid>

               <Grid item xs={6} style={{display: "flex", alignItems: "center", marginTop: "20px"}}>
                  <span style={{width: "100px"}}>Total: </span>
                    <TextField
                    disabled
                     size="small"
                     value={totalCard && totalCard}/>
               </Grid>

            </Grid>

            <Grid item xs={3} style={{marginTop: "20px"}}>
               <Titulo>{"Productos"}</Titulo>
            </Grid>

            <TableContainer component={Paper}>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
                <TableHead>
                     <TableRow>
                        <TableCell>{`N°`}</TableCell>
                        <TableCell align="right">Codigo</TableCell>
                        <TableCell align="right">Nombre</TableCell>
                        <TableCell align="right">Precio Unidad</TableCell>
                        <TableCell align="right">Cantidad</TableCell>
                     </TableRow>
                </TableHead>
                <TableBody>
                    {
                        detalleProducto && detalleProducto.map((item,index) =>(
                            <TableRow key={item.id}
                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                            classes={{
                               hover: 'hover-row-t'
                            }}>

                                <TableCell>{index+1}</TableCell>
                                <TableCell align="right">{item.codigo}</TableCell>
                                <TableCell align="right">{item.productos.nombre}</TableCell>
                                <TableCell align="right">{moneyFormatInt(item.precio_unidad)}</TableCell>
                                <TableCell align="right">{item.cantidad}</TableCell>

                            </TableRow>
                        ))
                    }
                </TableBody>
            </Table>
            </TableContainer>

        </Grid>
    );
}
