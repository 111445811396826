import React, { useEffect } from "react";
import { Autocomplete, Divider, Grid, TextField, Typography, TableFooter, TablePagination, FormControlLabel } from "@mui/material";
import { useLocalStorage } from '@/toolbox/hooks/local-storage.hook';
import { KEY_USER_DATA, TOKEN_API } from '@/toolbox/constants/local-storage';
import { sucursalesService } from "@/service/services/Sucursales.service";
import { Snackbar, Alert } from '@mui/material';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import  "../../../Home/Home.sass";
import { productoService } from "@/service/services/Producto.service";
import { listaPrecioService } from "@/service/services/ListaPrecio.service";
import Switch from '@mui/material/Switch';
import { userService } from "@/service/services/User.service";
import { Protected } from "@/components/layout/Protected";
import { Loading } from "@/components/common/Loading";
import { KEY_EMPRESA } from '@constants/local-storage';

export const AddListaPrecio: React.FC<any> = (props: any): JSX.Element => {
   // eslint-disable-next-line
    const { closeModal, onListSucursales, sucursal, editMode } = props;
    const [loading, setLoading] = React.useState<boolean>(false);

    const [dataUser] = useLocalStorage(KEY_USER_DATA, undefined);
    const rol = dataUser && dataUser.user.rol;
    // eslint-disable-next-line
    const isSuperAdmin = rol === 'Superadmin';
    const userSession = dataUser && dataUser.user;
   // eslint-disable-next-line
    const [token] = useLocalStorage(TOKEN_API, "");

    const [listaPrecioNew, setListaPrecioNew] = React.useState<any>([]);
    const [renderTabla, setRenderTabla] = React.useState(false);

    const [sucursalSelect, setSucursalSelect] = React.useState<any>([]);
    const [sucursales, setSucursales] = React.useState([]);

    const [selectedUser, setSelectedUser] = React.useState<any>([])
    const [userBySucursal, setUserBySucursal] = React.useState([]);

    const [filter, setFilter] = React.useState<Array<any>>([]);
    const [products, setProducts] = React.useState<Array<any>>([]);
    const [textSearch, setTextSearch] = React.useState<string>("");
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    const [dataEmpresa] = useLocalStorage(KEY_EMPRESA, {
      id: 0, id_tipo_empresa:0, equivalencia_puntos: 0,
      id_country: 0, country_name: "", country_abbreviation: "",
   });


    const handleChangePage = (event, newPage)  => {
        setPage(newPage);
     };

     const handleChangeRowsPerPage = (event)   =>{
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
     };


    const [snackBarConfig, setSnackBarConfig] = React.useState<any>({
        open: false,
        severity: 'error',
        message: 'Error',
        autoHideDuration: 3000,
    });

    const [dataListPrice, setDataListPrice] = React.useState<any>({
        margen_utilidad: '',
        max_descuento: '',
        nombre:'',
        fecha_inicio:'',
        fecha_fin:'',
        hora_inicio:'',
        hora_fin:'',
        dias:[],
    })

    const { nombre, margen_utilidad, max_descuento, fecha_inicio, fecha_fin, hora_inicio, hora_fin, dias  } = dataListPrice

    const dataInicialDias = [
        { value:0, dia:'Todos los dias'},
        { value:1, dia:'Lunes'},
        { value:2, dia:'Martes'},
        { value:3, dia:'Miercoles'},
        { value:4, dia:'Jueves'},
        { value:5, dia:'Viernes'},
        { value:6, dia:'Sabado'},
        { value:7, dia:'Domingo'}
    ]

    const [checkedUsuario, setCheckedUsuario] = React.useState(false);
    const [checkedFrecuencia, setCheckedFrecuencia] = React.useState(false);

    const handleChangeCheckedUsuario = (event: React.ChangeEvent<HTMLInputElement>) => {
        setCheckedUsuario(event.target.checked);
    };
    const handleChangeCheckedFrecuencia = (event: React.ChangeEvent<HTMLInputElement>) => {
        setCheckedFrecuencia(event.target.checked);
    };

    const saveLista = async() => {
        setLoading(true);
        if(margen_utilidad && listaPrecioNew.length > 0) {
            if (sucursalSelect.length > 0 ) {
                const data = obtenerArrayIdSucursales();
                const dataJson = JSON.stringify(data);
                const allUser = await getUsersBySucursal(dataJson);

                const dataDetalle = {
                    'listaProductos' : JSON.stringify(listaPrecioNew) ,
                    'listaSucursales' : JSON.stringify(sucursalSelect),
                    'dataListPrice' :  JSON.stringify(dataListPrice),
                    'usersListPrice' : checkedUsuario? JSON.stringify(selectedUser):JSON.stringify(allUser),
                }

                await listaPrecioService.addDetalleProductoPrecio(dataDetalle);
            }
        }else{
            setSnackBarConfig(prev => ({
                ...prev,
                open: true,
                message: "Verifique los campos",
            }));
        }
        setListaPrecioNew([]);
        setSucursales([]);
        setSucursalSelect([]);
        setDataListPrice({
            margen_utilidad: '',
            max_descuento: '',
            nombre:'',
            fecha_inicio:'',
            fecha_fin:'',
            hora_inicio:'',
            hora_fin:'',
            dias:[],
        })
        setLoading(false);
        setSnackBarConfig(prev => ({
            ...prev,
            open: true,
            severity: 'success',
            message: "Lista de precios agregada satisfactoriamente",
        }));
    }

    const handleChange = (e) => {
        const handleChangeFormValues = {
            ...dataListPrice,
            [e.target.name]: e.target.value
        }
        setDataListPrice(handleChangeFormValues);
    }

    useEffect(() => {
        //getListPrice(sucursal.id);
        if (sucursalSelect.length > 0 && checkedUsuario) {
            const data = obtenerArrayIdSucursales();
            const dataJson = JSON.stringify(data);
            getUsersBySucursal(dataJson);
        }
        // eslint-disable-next-line
    }, [checkedUsuario, sucursalSelect])

    useEffect(() => {
        setRenderTabla(false);
        // eslint-disable-next-line
    }, [renderTabla])

    const obtenerArrayIdSucursales = () => {
        let arrayIdSucursales = [];
        // eslint-disable-next-line
        sucursalSelect.map((value) => {
            arrayIdSucursales.push(value.id)
        })
        return arrayIdSucursales;
    }

    const getSucursales = async () => {
        const resp = await sucursalesService.listAllSucursales();
        setSucursales(resp)
    }

    const getUsersBySucursal = async (id_sucursal) => {
        const resp = await userService.getUsersBySucursal(id_sucursal);
        if (resp.status === 200) {
            setUserBySucursal(resp.users)
        }
        return resp.users;
    }

    const arrayProducts = [];

    const getProductosEmpresa = async (id_empresa) => {
      await productoService.getProductsByCompany(id_empresa).then((data) => {
         if (data.status === 200) {
            // setProductsShop(data.productos);
            // eslint-disable-next-line
               (data.productos || []).map((product) => {
                  arrayProducts.push({
                     id: product.id, codigo:product.codigo_erp, nombre:product.nombre, precio: Number(product.precio_standar), erp:false })
               })
               setProducts(arrayProducts)
               setFilter(arrayProducts)
               setLoading(false)
         }
      });
   }
    useEffect(() => {
        setLoading(true);
        getSucursales();
        getCompraDetalleEmpresa();
        getProductosEmpresa(dataEmpresa.id);
        setLoading(false);
      //  fetch(`https://api2.softnet.cl/producto`, {
      //  method: 'GET',
      //  headers: {
      //     'Access-Control-Allow-Origin':'*',
      //     'Content-Type': 'application/x-www-form-urlencoded',
      //     token: token,
      //  },
      //  })
      //  .then(dataProducts => dataProducts.json())
      //  .then(dataProducts => {
      //     // setIsLoaded(false)
      //     // eslint-disable-next-line
      //     dataProducts.map((product) => {
      //        if(product.stock > 0 && product.estado === 1){

      //           arrayProducts.push({
      //               id: product.id, codigo:product.codigo, nombre:product.nombre, precio: Number(product.precio), erp:true })
      //        }
      //     })
      //     setProducts(arrayProducts)
      //     setFilter(arrayProducts)
      //     setLoading(false)

      //  })
      //  .catch(error => {
      //   setLoading(false)
      //  })
       // eslint-disable-next-line
    },[])

    const getCompraDetalleEmpresa = async() => {

        const data = await listaPrecioService.getCompraDetalleEmpresa(userSession.id_empresa);
         // eslint-disable-next-line
        data.map((producto)=>{
            arrayProducts.push({id: producto.id, codigo:producto.codigo, nombre:producto.nombre, precio: producto.precio, erp:false });
        })
    }

    const handleKeyDown = async (event) => {
        let codigo: any = document.getElementById("mi-codigo-de-barras");
        if(event.keyCode === 13){
           let newValue = codigo.value;

         //  setIsLoaded(true);
          await productoService.getProductsByCodigo(newValue).then((data) =>{
              if(data.status === 200){
                  codigo.value = ""
                  setTextSearch(newValue);
                  // eslint-disable-next-line
                  (data.productos).map((producto) =>{
                   // handleSelectProduct(producto);
                })
              }else{
                //   openModalConfirm();
                //   setPopUp('modalConfirm');
                //   setCodigoBarras(newValue);
              }
              // setIsLoaded(false);
          })

           //alert("codigo de barras "+ newValue)
       }else{
        let texto = `${event.target.value}`;
        setTextSearch(texto);
        if (!texto) {
            setFilter([...products])
        }
       }
     }

     useEffect(() => {
        if (textSearch) {
           let copyFilters = []
           copyFilters = products.filter(producto => (producto?.nombre.toLowerCase().includes(textSearch.toLowerCase()) || producto?.codigo.toLowerCase().includes(textSearch.toLowerCase())) )
           setFilter([...copyFilters])
        }
     }, // eslint-disable-next-line
     [textSearch])

     const handleSelectProduct = (products) =>{
         let card: Array<any> =  listaPrecioNew;
         let exits: boolean = false;

         if(margen_utilidad){

            for (let i = 0; i < card.length; i++) {
                if ( card[i].codigo_erp === products?.codigo  && card[i].nombre === products?.nombre ) {
                    exits = true;
                }
            }

            if(exits){
                setSnackBarConfig(prev => ({
                    ...prev,
                    open: true,
                    message: "No se puede seleccionar el mismo producto",
                 }));
            }else{
                const producto = {
                    'codigo_erp':products.codigo,
                    'nombre':products.nombre,
                    'precio_standar':products.precio,
                    'precio_oferta': null,
                    'precio_calculado': products.erp?Number(products.precio):Math.ceil(Number(products.precio)*((100 + Number(margen_utilidad))/100)),
                    'id_tipo_producto': '',
                    'erp': products.erp,
                    'id_empresa':userSession.id_empresa,
                };

                setRenderTabla(true)
                const data = listaPrecioNew
                data.push(producto);
                setListaPrecioNew(data);
            }
         }
         else{
            setSnackBarConfig(prev => ({
                ...prev,
                open: true,
                message: "Ingrese el Margen de Utilidad para poder hacer el calculo",
             }));
         }

     }

     const handleDeleteProduct = (posicion)=>{

        listaPrecioNew.splice(posicion,1);
        setRenderTabla(true);
     }

     const TablaListaPrecios = (
        <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
                <TableRow>
                    <TableCell>Código</TableCell>
                    <TableCell>Nombre</TableCell>
                    <TableCell align="right">Precio</TableCell>
                    <TableCell align="right" width={'2rem'}></TableCell>
                </TableRow>
            </TableHead>
            <TableBody>
                {(rowsPerPage > 0
                    ? listaPrecioNew.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    : listaPrecioNew
                )
                    .map((products, index) => (
                        <TableRow
                            key={products.id}
                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                            classes={{
                                hover: 'hover-row-t'
                            }}
                        >
                            <TableCell component="th" scope="row">
                                <span className="tabla">{products.codigo_erp}</span>
                            </TableCell>
                            <TableCell component="th" scope="row">
                                    <span className="tabla">{products.nombre.charAt(0).toUpperCase() + products.nombre.slice(1).toLowerCase()}</span>
                            </TableCell>
                            <TableCell align="right"><span >{products.precio_calculado}</span></TableCell>
                            <TableCell align="center">
                                    <button className="dissmiss-more delete" onClick={()=>handleDeleteProduct(index)}>x</button>
                            </TableCell>
                        </TableRow>
                    ))}
            </TableBody>
            <TableFooter>
                <TableRow>
                    <TablePagination
                        count={filter.length}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                        rowsPerPageOptions={[10, 15, 20]}
                        labelRowsPerPage={<span className="tabla">Filas:</span>}
                        labelDisplayedRows={({ page }) => {
                            return `Página: ${page + 1}`;
                        }}
                        SelectProps={{
                            inputProps: {
                                "aria-label": "page number"
                            }
                        }}
                        showFirstButton={true}
                        showLastButton={true}
                    />
                </TableRow>
            </TableFooter>
        </Table>
    </TableContainer>
     )

     const FormularioParametrosLista = (

        <Grid container spacing={2}>
            <Grid item xs={6} container style={{ display: "flex", alignItems: "center" }}>
                <Grid item xs={4}>
                    <Typography variant="subtitle1" className="custom-input"><b>Nombre:</b></Typography>
                </Grid>
                <Grid item xs={8} style={{ display: 'flex', alignItems: "center" }}>
                    <TextField
                        id="nombre"
                        type="text"
                        name="nombre"
                        fullWidth
                        value={nombre}
                        onChange={handleChange}
                        placeholder="Nombre de Lista"
                    // error={errors.nombre && touched.nombre ? true : false}W
                    />
                </Grid>
            </Grid>
            <Grid item xs={6} container style={{ display: "flex", alignItems: "center" }}>
                <Grid item xs={4}>
                    <Typography variant="subtitle1" className="custom-input"><b>Sucursal:</b></Typography>
                </Grid>
                <Grid item xs={8} style={{ display: 'flex', alignItems: "center" }}>
                    <Autocomplete
                        multiple={true}
                        disablePortal
                        id="combo-box-demo"
                        value={sucursalSelect}
                        options={sucursales || []}
                        getOptionLabel={(option) => option.nombre || ""}
                        fullWidth
                        renderInput={(params) => <TextField {...params} label="Escriba para buscar" />}
                        onChange={(e, newValue) => {
                            setSucursalSelect(newValue);
                        }}
                    />

                </Grid>
            </Grid>
            {checkedUsuario && <Grid item xs={6} container style={{ display: "flex", alignItems: "center" }}>
                <Grid item xs={4}>
                    <Typography variant="subtitle1" className="custom-input"><b>Usuarios:</b></Typography>
                </Grid>
                <Grid item xs={8} style={{ display: 'flex', alignItems: "center" }}>
                    <Autocomplete
                        multiple={true}
                        disablePortal
                        id="combo-box-demo"
                        value={selectedUser}
                        options={userBySucursal || []}
                        getOptionLabel={(option) => option.nombre || ""}
                        fullWidth
                        renderInput={(params) => <TextField {...params} label="Escriba para buscar" />}
                        onChange={(e, newValue) => {
                            setSelectedUser(newValue);
                        }}
                    />
                </Grid>
            </Grid>}
            <Grid item xs={6} container style={{ display: "flex", alignItems: "center" }}>
                <Grid item xs={4}>
                    <Typography variant="subtitle1" className="custom-input"><b>Margen de Utilidad:</b></Typography>
                </Grid>
                <Grid item xs={8} style={{ display: 'flex', alignItems: "center" }}>
                    <TextField
                        id="margenUtilidad"
                        type="text"
                        name="margen_utilidad"
                        fullWidth
                        value={margen_utilidad}
                        onChange={handleChange}
                        placeholder="Margen de Utilidad"
                    // error={errors.nombre && touched.nombre ? true : false}
                    />
                </Grid>
            </Grid>
            <Grid item xs={6} container style={{ display: "flex", alignItems: "center" }}>
                <Grid item xs={4}>
                    <Typography variant="subtitle1" className="custom-input"><b>Descuento Máximo:</b></Typography>
                </Grid>
                <Grid item xs={8} style={{ display: 'flex', alignItems: "center" }}>
                    <TextField
                        id="descuentoMaximo"
                        type="text"
                        name="max_descuento"
                        fullWidth
                        value={max_descuento}
                        onChange={handleChange}
                        placeholder="Descuento Máximo"
                    // error={errors.nombre && touched.nombre ? true : false}W
                    />
                </Grid>
            </Grid>
            <Grid item xs={6} container style={{ display: "flex", alignItems: "center" }}>
                <Grid item xs={4}>
                    <Typography variant="subtitle1" className="custom-input"><b>Fecha de Inicio:</b></Typography>
                </Grid>
                <Grid item xs={8} style={{ display: 'flex', alignItems: "center" }}>
                    <TextField
                        id="fechaInicio"
                        type="date"
                        name="fecha_inicio"
                        fullWidth
                        value={fecha_inicio}
                        onChange={handleChange}
                        placeholder="Fecha de Inicio"
                    // error={errors.nombre && touched.nombre ? true : false}W
                    />
                </Grid>
            </Grid>
            <Grid item xs={6} container style={{ display: "flex", alignItems: "center" }}>
                <Grid item xs={4}>
                    <Typography variant="subtitle1" className="custom-input"><b>Fecha de Fin:</b></Typography>
                </Grid>
                <Grid item xs={8} style={{ display: 'flex', alignItems: "center" }}>
                    <TextField
                        id="fechaFin"
                        type="date"
                        name="fecha_fin"
                        fullWidth
                        value={fecha_fin}
                        onChange={handleChange}
                        placeholder="Fecha de Inicio"
                    // error={errors.nombre && touched.nombre ? true : false}W
                    />
                </Grid>
            </Grid>
            {checkedFrecuencia &&
            (
                <>
                     <Grid item xs={6} container style={{ display: "flex", alignItems: "center" }}>
                         <Grid item xs={4}>
                             <Typography variant="subtitle1" className="custom-input"><b>Dias:</b></Typography>
                         </Grid>
                         <Grid item xs={8} style={{ display: 'flex', alignItems: "center" }}>
                             <Autocomplete
                                 multiple={true}
                                 disablePortal
                                 id="combo-box-demo"
                                 value={dias}
                                 options={dataInicialDias || []}
                                 getOptionLabel={(option) => option.dia || ""}
                                 fullWidth
                                 renderInput={(params) => <TextField {...params} label="Escriba para buscar" />}
                                 onChange={(e, newValue) => {
                                     setDataListPrice(prev => ({ ...prev, dias: newValue }))
                                 }}
                             />
                         </Grid>
                     </Grid>
                     <Grid item xs={6} container style={{ display: "flex", alignItems: "center" }}>
                         <Grid item xs={4}>
                             <Typography variant="subtitle1" className="custom-input"><b>Hora de Inicio:</b></Typography>
                         </Grid>
                         <Grid item xs={8} style={{ display: 'flex', alignItems: "center" }}>
                             <TextField
                                 id="horaInicio"
                                 type="time"
                                 name="hora_inicio"
                                 fullWidth
                                 value={hora_inicio}
                                 onChange={handleChange}
                                 placeholder="Hora de Inicio"
                             // error={errors.nombre && touched.nombre ? true : false}W
                             />
                         </Grid>
                     </Grid>
                     <Grid item xs={6} container style={{ display: "flex", alignItems: "center" }}>
                         <Grid item xs={4}>
                             <Typography variant="subtitle1" className="custom-input"><b>Hora de Fin:</b></Typography>
                         </Grid>
                         <Grid item xs={8} style={{ display: 'flex', alignItems: "center" }}>
                             <TextField
                                 id="horaFin"
                                 type="time"
                                 name="hora_fin"
                                 fullWidth
                                 value={hora_fin}
                                 onChange={handleChange}
                                 placeholder="Fecha de Inicio"
                             // error={errors.nombre && touched.nombre ? true : false}W
                             />
                         </Grid>
                     </Grid>
                </>
            )
            }
        </Grid>

     )

     const tablaProductos = (
         <>
         <div className="H-card__fcontainer__filter">
                            <span>Filtro:</span>
                            <TextField
                                type="text"
                                id="mi-codigo-de-barras"
                                placeholder="Buscar..."
                                onKeyDown={handleKeyDown}
                                className="H-card__fcontainer__filter__input"
                                style={{ backgroundColor: "white", width: "290px", marginLeft: "10px" }}
                            />
                        </div>

                        <TableContainer component={Paper}>
                            <Table sx={{ minWidth: 650 }} aria-label="simple table">
                                <TableHead>
                                    <TableRow>
                                        <TableCell>Código</TableCell>
                                        <TableCell>Nombre</TableCell>
                                        <TableCell align="right">Precio</TableCell>
                                        {/* <TableCell align="right">Precio oferta&nbsp;</TableCell> */}
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {(rowsPerPage > 0
                                        ? filter.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                        : filter
                                    )
                                        .map((products, index) => (
                                            <TableRow
                                                key={products.id}
                                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                                classes={{
                                                    hover: 'hover-row-t'
                                                }}
                                            >
                                                <TableCell component="th" scope="row">
                                                    <span className="tabla">{products.codigo}</span>
                                                </TableCell>
                                                <TableCell component="th" scope="row">
                                                    <button className="H-card__tableButton"
                                                    onClick={() => handleSelectProduct(products)}
                                                    >
                                                        <span className="tabla">{products.nombre.charAt(0).toUpperCase() + products.nombre.slice(1).toLowerCase()}</span>
                                                    </button>
                                                </TableCell>
                                                <TableCell align="right"><span className={'o' }>{products.precio}</span></TableCell>
                                            </TableRow>
                                        ))}
                                </TableBody>
                                <TableFooter>
                                    <TableRow>
                                        <TablePagination
                                            count={filter.length}
                                            rowsPerPage={rowsPerPage}
                                            page={page}
                                            onPageChange={handleChangePage}
                                            onRowsPerPageChange={handleChangeRowsPerPage}
                                            rowsPerPageOptions={[10, 15, 20]}
                                            labelRowsPerPage={<span className="tabla">Filas:</span>}
                                            labelDisplayedRows={({ page }) => {
                                                return `Página: ${page + 1}`;
                                            }}
                                            SelectProps={{
                                                inputProps: {
                                                    "aria-label": "page number"
                                                }
                                            }}
                                            showFirstButton={true}
                                            showLastButton={true}
                                        />
                                    </TableRow>
                                </TableFooter>
                            </Table>
                        </TableContainer>
         </>
     )

    return (
        <Protected>
            <div >
            {loading && <Loading/>}
                <Snackbar
                    open={snackBarConfig.open}
                    autoHideDuration={snackBarConfig.autoHideDuration}
                    onClose={() => setSnackBarConfig(prev => ({ ...prev, open: false }))}
                    anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                >
                    <Alert
                        onClose={() => setSnackBarConfig(prev => ({ ...prev, open: false }))}
                        severity={snackBarConfig.severity}
                        variant="filled"
                    >
                        {snackBarConfig.message}
                    </Alert>
                </Snackbar>
                <Grid container justifyContent='space-between' spacing={2}>
                    <Grid item xs={7} container>
                        <Typography variant='h4' mr={2} >Crear Lista de Precios</Typography>
                        <div className="form-group mb-3 buton_container_modal">
                            <button onClick={()=>{saveLista()}} className="btn btn-primary">
                                {
                                    sucursal ? `Editar ` : `Procesar`
                                }
                            </button>
                        </div>
                    </Grid>
                    <Grid item>
                        <FormControlLabel
                            control={<Switch checked={checkedUsuario}
                            onChange={handleChangeCheckedUsuario} />} label="Personalizar por Usuario" />
                        <FormControlLabel
                            control={<Switch checked={checkedFrecuencia}
                            onChange={handleChangeCheckedFrecuencia} />} label="Personalizar frecuencia" />
                    </Grid>
                </Grid>

                <Divider sx={{ my: 2 }} />
                {FormularioParametrosLista}
                <Divider sx={{my:2}}/>
                <Grid container spacing={4}>
                    <Grid item xs={6} container>
                    {tablaProductos}
                    </Grid>
                    <Grid item xs={6} container>
                   {renderTabla? TablaListaPrecios : TablaListaPrecios }
                    </Grid>
                </Grid>
            </div>
        </Protected>
    );
}
