import { Props } from "./PrintQR.type";
import { Grid, Typography } from "@mui/material";
import { KEY_EMPRESA } from "@/toolbox/constants/local-storage";
import { useLocalStorage } from "@/toolbox/hooks/local-storage.hook";

export const PrintQR: React.FC<Props> = (props: Props) => {

    const {data} = props;
    const [dataEmpresa] = useLocalStorage(KEY_EMPRESA, {
        id_country: 0, country_name: "", country_abbreviation: "", nombre: "",
    });

    return (
        <div style={{  marginBottom: 30, width: '500px', margin: 'auto', marginTop: 30 ,padding:"10px"}}>
            <Grid textAlign="center" justifyContent="center">
                <Typography variant="subtitle1" sx={{marginTop: '10px', fontSize:'18px'}}><b>{dataEmpresa && dataEmpresa.nombre}</b></Typography>
                <Grid sx={{padding: "10px"}}>
                    {
                        data && data.imagValue && (
                            <img src={data.imagValue} alt="Mesa QR" style={{minWidth: "350px", minHeight: "250px"}}/>
                        )
                    }
                </Grid>
                <Typography variant="subtitle1" sx={{marginTop: '10px', fontSize:'20px'}}><b>{data.mesa && data.mesa}</b></Typography>
            </Grid>
        </div>
    );
}
