import axios from 'axios';
import { API_URL_NUPY } from '@/toolbox/defaults/app';
import { readLocalStorage } from '@/toolbox/helpers/local-storage-helper';
import { TOKEN_API } from '@/toolbox/constants/local-storage';

export const ClientesRepository = {

  getClientes: async (): Promise<any> => {
    const res = await axios.get(`${API_URL_NUPY}/cliente/getClientes`);
    return res.data;
  },

  validateClientesSofnet: async (): Promise<any> => {
    const res = await axios.post(`${API_URL_NUPY}/cliente/sync`);
    return res.data;
  },

  getClienteByRut: async (rut: any): Promise<any> => {
    const res = await axios.get(`${API_URL_NUPY}/cliente/getCliente-rut/${rut}`);
    return res.data;
  },

  editState: async (idCliente: number, state: string): Promise<any> => {
    const res = await axios.patch(`${API_URL_NUPY}/cliente/editState/${idCliente}/${state}`);
    return res.data;
  },

  register: async (data: any): Promise<any> => {
    if (data?.extranjero === 0) {
      const response = await addClienteSofnet(data, 1, 0);
      const mensajesErrores = response.flatMap(obj => Object.values(obj).flatMap(error => Object.values(error)[0]))
      const primerMensajeError = mensajesErrores[0]
      if (primerMensajeError !== 'Cliente ingresado con exito.' && primerMensajeError !== 'Empresa ya registrada.') return false
    }

    const res = await axios.post(`${API_URL_NUPY}/cliente/register`, data);
    return res.data;
  },

  update: async (data: any, idCliente: number): Promise<any> => {

    await updateClienteSofnet(data, 1, 0, data.rut);
    const res = await axios.post(`${API_URL_NUPY}/cliente/update/${idCliente}`, data);
    return res.data;
  },

  diminishPoints: async (idCliente: number, points: number): Promise<any> => {
    const res = await axios.post(`${API_URL_NUPY}/cliente/diminishPoints/${idCliente}/${points}`);
    return res.data;
  },

  getNewClientMes: async (fecha: any): Promise<any> => {
    const res = await axios.get(`${API_URL_NUPY}/cliente/nuevos-mes/${fecha}`);
    return res.data;
  },

  getNewClientDay: async (fecha: any): Promise<any> => {
    const res = await axios.get(`${API_URL_NUPY}/cliente/nuevos-day/${fecha}`);
    return res.data;
  },

  getNewClientFechas: async (fecha_de: any, fecha_a: any): Promise<any> => {
    const res = await axios.get(`${API_URL_NUPY}/cliente/nuevos-fechas/${fecha_de}/${fecha_a}`);
    return res.data;
  },

  //view ecommerce: historial de compras
  shoppingHistory: async (): Promise<any> => {
    const res = await axios.get(`${API_URL_NUPY}/cliente/shoppingHistory`);
    return res.data;
  },

  downloadTemplateImport: async (): Promise<any> => {
    const res = await axios.get(`${API_URL_NUPY}/cliente/downloadTemplateImport`);
    return res.data;
  },

  import: async (data: FormData): Promise<any> => {
    const res = await axios.post(`${API_URL_NUPY}/cliente/import`, data);
    return res.data;
  },
}

export async function addClienteSofnet(data, isClient: number, isProveedor: number) {
  const token: any = readLocalStorage(TOKEN_API);
  console.log('data', data)
  try {
    const response = await fetch(`https://api2.softnet.cl/cliente`, {
      method: 'POST',
      headers: {
        'Access-Control-Allow-Origin': '*',
        'Content-Type': 'application/json',
        token: token,
      },
      body: JSON.stringify({
        clientes: [{
          nombre_rz: data.nombre_rz || data.nombre,
          nombre_fantasia: data.nombre_fantasia || data.nombre,
          rut: data.rut,
          cliente: isClient.toString(),
          proveedor: isProveedor.toString(),
          prospecto: '0',
          extranjero: '0',
          giro: data.giro || 'giro cliente',
          direccion: data.direccion,
          telefono: '02-2222222',
          fax: '02-2222222',
          comuna: data.comuna,
          ciudad: data.ciudad,
          pais: 'chile',
          email: 'cliente@cliente.com',
          web: 'http://www.ciente.cl',
          observacion: data.observacion || 'observacion cliente',
          nombre_cto: 'nombre contacto',
          rut_cto: '21292222-6',
          email_cto: 'contacto@cliente.com',
          telefono_cto: '02-2222222',
          celular_cto: '02-2222222',
          cargo_cto: 'cargo de contacto cliente',
          cobranza_cto: '1',
          vendedor: '22222222-2'
        }]
      })
    });

    if (!response.ok) {
      throw new Error('Error al añadir cliente en Softnet');
    }

    const result = await response.json();
    return result;
  } catch (error) {
    /* alert("REGISTRO MAL EN SOFNET") */
    return false;
  }
}


export function updateClienteSofnet(data, isClient: number, isProveedor: number, rut: string) {
  const token: any = readLocalStorage(TOKEN_API);
  return fetch(`https://api2.softnet.cl/cliente/${rut}`, {
    method: 'PUT',
    headers: {
      'Access-Control-Allow-Origin': '*',
      'Content-Type': 'application/x-www-form-urlencoded',
      token: token,
    },

    body: JSON.stringify({
      clientes: [{
        "nombre_rz": `${data.nombre_rz || data.nombre}`,
        "cliente": `${isClient}`,
        "proveedor": `${isProveedor}`,
        "giro": `${data.giro || 'giro cliente'}`,
        "direccion": `${data.direccion}`,
        "comuna": `${data.comuna}`,
        "ciudad": `${data.ciudad}`,
        "observacion": `${data.observacion || 'observacion cliente'}`,
      }]
    })
  }).then(pros => {
    pros.json()
  })
    .then(async pros => {
      return true
    })
    .catch(error => {
      /* alert("REGISTRO MAL EN SOFNET") */
      return false;
    })
}
