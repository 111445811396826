import React from "react";
import { Box, Button, createTheme, FormControlLabel, Grid, Paper, Switch, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, ThemeProvider } from "@mui/material";
import { esES } from "@mui/material/locale";
import { PencilIcon, VisibilityIcon } from "@/toolbox/constants/icons";
import DeleteIcon from '@mui/icons-material/Delete';
import { Popup } from "@/views/Editor/components/Popup";
import { Loading } from "@/components/common/Loading";
import { Props } from "./BeneficioPuntos.type";
import { BeneficioPuntosService } from "@/service/services/BeneficioPuntos.service";
import { PuntosModal } from "./PuntosModal";
import { PuntosModalDelete } from "./PuntosModalDelete";

const theme = createTheme(
    {
        palette: {
            primary: { main: '#1976D2' },
        },
    },
    esES,
);

export const BeneficioPuntos: React.FC<Props> = (props: Props):JSX.Element => {
    const {beneficios, onListBeneficios} = props;

    const [openPopup, setOpenPopup] = React.useState<boolean>(false);
    const [popUp, setPopUp] = React.useState<String>('');
    const [loading, setLoading] = React.useState<boolean>(false);
    const [detailMode, setDetailMode] = React.useState<boolean>(false);

    const [selectedBeneficio, setSelectedBeneficio] = React.useState<any>('');
    const [showModalDelete, setShowModalDelete]= React.useState(false);

    const openModalDelete=()=>{
        setShowModalDelete(true);
    }

    const closeModalDelete=()=>{
        setShowModalDelete(false);
    }

    const funcionSwitch = async (cupon)=>{
        setLoading(true);
        if(cupon.state === 1){
            await BeneficioPuntosService.updateStatus(cupon.id,0);
        }else{
            await BeneficioPuntosService.updateStatus(cupon.id,1);
        }
        onListBeneficios && onListBeneficios();
    }

    return (
        <Grid>
            {loading && <Loading/>}
            <Box sx={{Width: '100%', marginTop:'0px'}}>
                <TableContainer component={Paper}>
                    <ThemeProvider theme={theme}>
                        <Table aria-label="simple table" size='small'>
                            <TableHead>
                                <TableRow  sx={{ bgcolor:'#1976D2'}}>
                                    <TableCell sx={{ color: 'white' }} >
                                        <strong>N°</strong>
                                    </TableCell>
                                    <TableCell align='center' sx={{ color: 'white' }} >
                                        <strong>Producto</strong>
                                    </TableCell>
                                    <TableCell align='center' sx={{ color: 'white' }} >
                                        <strong>Puntos requeridos</strong>
                                    </TableCell>
                                    <TableCell align='center' sx={{ color: 'white' }} >
                                        <strong>Descuento</strong>
                                    </TableCell>
                                    <TableCell align='center' sx={{ color: 'white' }} >
                                        <strong>Max. Descuento</strong>
                                    </TableCell>
                                    <TableCell align='center' sx={{ color: 'white' }} >
                                        <strong>Estado</strong>
                                    </TableCell>
                                    <TableCell align='center' sx={{ color: 'white' , minWidth:'8rem', maxWidth:'12rem'}}><strong>Acciones</strong></TableCell>
                                </TableRow>
                            </TableHead>

                            <TableBody>
                                {
                                    beneficios && beneficios.map((item, id) =>(
                                        <TableRow key={id}>
                                            <TableCell component="th" scope="row">
                                                {id+1}
                                            </TableCell>
                                            <TableCell align='center' component="th" scope="row">
                                                {item.producto?.nombre}
                                            </TableCell>
                                            <TableCell align='center' component="th" scope="row">
                                                {item.puntos}
                                            </TableCell>
                                            <TableCell align='center' component="th" scope="row">
                                                {item.descuento}
                                            </TableCell>
                                            <TableCell align='center' component="th" scope="row">
                                                {item.max_descuento}
                                            </TableCell>
                                            <TableCell align='center' component="th" scope="row">
                                                <Box>
                                                <FormControlLabel
                                                    control={<Switch onClick={() => {
                                                        funcionSwitch(item);
                                                    }}
                                                />}
                                                checked={item.state === 1 ? true : false}
                                                label=""
                                                />
                                                </Box>
                                            </TableCell>
                                            <TableCell align='center' sx={{width: '12rem'}}>
                                                <Button
                                                    onClick={() =>  {
                                                        setOpenPopup(true);
                                                        setDetailMode(true);
                                                        setPopUp('detailCupon');
                                                        setSelectedBeneficio(item);
                                                    }}
                                                >
                                                    <VisibilityIcon/>
                                                </Button>
                                                
                                                <Button
                                                    onClick={() =>  {
                                                        setOpenPopup(true);
                                                        setDetailMode(false);
                                                        setPopUp('editCupon');
                                                        setSelectedBeneficio(item);
                                                    }}
                                                >
                                                    <PencilIcon/>
                                                </Button>

                                                <Button color="error"
                                                    onClick={() =>  {
                                                        setOpenPopup(true);
                                                        openModalDelete();
                                                        setPopUp('delete');
                                                        setSelectedBeneficio(item);
                                                    }}
                                                >
                                                    <DeleteIcon/>
                                                </Button>
                                            </TableCell>
                                        </TableRow>
                                    ))
                                }
                            </TableBody>
                        </Table>
                    </ThemeProvider>
                </TableContainer>
            </Box>
            {
                popUp === 'detailCupon'  && (
                    <Popup
                        largo="sm"
                        title = {'Detalle del Beneficio'}
                        openPopup = {openPopup}
                        setOpenPopup = {setOpenPopup}
                    >
                        <PuntosModal
                            onListBeneficios={onListBeneficios}
                            closeModal={() => {setOpenPopup(false)}}
                            beneficio={selectedBeneficio}
                            detailMode={detailMode}
                        />
                    </Popup>
                )
            }
            {
                popUp === 'editCupon'  && (
                    <Popup
                        largo="sm"
                        title = {'Editar Beneficio'}
                        openPopup = {openPopup}
                        setOpenPopup = {setOpenPopup}
                    >
                        <PuntosModal
                            onListBeneficios={onListBeneficios}
                            closeModal={() => {setOpenPopup(false)}}
                            beneficio={selectedBeneficio}
                            detailMode={detailMode}
                        />
                    </Popup>
                )
            }
            {
                popUp === 'delete'  && (
                    <PuntosModalDelete
                        open={showModalDelete}
                        closeModal={closeModalDelete}
                        beneficio={selectedBeneficio}
                        onListBeneficios={onListBeneficios}
                    />
                )
            }
        </Grid>
    )
}