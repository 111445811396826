import { Box, Grid } from "@mui/material"
import ContentPasteOffIcon from '@mui/icons-material/ContentPasteOff';

type NotDataProps = {
    description: string
}
export const NotData: React.FC<NotDataProps> = (props: NotDataProps): JSX.Element => {
     
    const {description} = props;
    
    return (
        <Grid container justifyContent="center">
            <Box sx={{ marginTop: '50px' }}>
                <Grid container justifyContent="center"> <ContentPasteOffIcon className="iconocarrito" /></Grid>
                <Box sx={{ Width: '100%', marginBottom: '30px', textAlign: 'center' }}>{description}</Box>
            </Box>
        </Grid>
    )
}

