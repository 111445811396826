import React, { useEffect } from "react";
import { Grid, TextField, TableFooter, TablePagination, Button } from "@mui/material";
import { useLocalStorage } from '@/toolbox/hooks/local-storage.hook';
import { KEY_USER_DATA } from '@/toolbox/constants/local-storage';
import { Snackbar, Alert } from '@mui/material';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { productoService } from "@/service/services/Producto.service";
import { listaPrecioService } from "@/service/services/ListaPrecio.service";

export const ListaPrecioProducto: React.FC<any> = (props: any): JSX.Element => {
   // eslint-disable-next-line
    const { closeModal, onListSucursales, listaPrecio, editMode } = props;
    const [modeEditPrice, setModeEditPrice] = React.useState<boolean>(false);
    const [textSearch, setTextSearch] = React.useState<string>("");
    const [selectPrice, setSelectPrice] = React.useState<any>({
    });
    const [dataUser] = useLocalStorage(KEY_USER_DATA, undefined);
    const rol = dataUser && dataUser.user.rol;
    // eslint-disable-next-line
    const isSuperAdmin = rol === 'Superadmin';
    const [products, setProducts] = React.useState<Array<any>>([]);
    const [filter, setFilter] = React.useState<Array<any>>([]);
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);


    const [snackBarConfig, setSnackBarConfig] = React.useState<any>({
        open: false,
        severity: 'error',
        message: 'Error',
        autoHideDuration: 3000,
    });

    useEffect(() => {
        getListaPrecioProducto(listaPrecio.id);
        // eslint-disable-next-line
    }, [])

    const getListaPrecioProducto = async(id) =>{
        const dataProducts = await listaPrecioService.getListaPrecio(id);

        setProducts(dataProducts.data)
        setFilter(dataProducts.data)
    }

    useEffect(() => {
        if (textSearch) {
           let copyFilters = []
           copyFilters = products.filter(producto => (producto?.nombre.toLowerCase().includes(textSearch.toLowerCase()) || producto?.codigo.toLowerCase().includes(textSearch.toLowerCase())) )
           setFilter([...copyFilters])
        }
      // eslint-disable-next-line
     },[textSearch])

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };
    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const handleKeyDown = async (event) => {
        let codigo: any = document.getElementById("mi-codigo-de-barras");
        if (event.keyCode === 13) {
            let newValue = codigo.value;

            //    setIsLoaded(true);
            await productoService.getProductsByCodigo(newValue).then((data) => {
                if (data.status === 200) {
                    codigo.value = ""
                    setTextSearch(newValue);
                    // eslint-disable-next-line
                    (data.productos).map((producto) => {
                        // handleSelectProduct(producto);
                    })
                } else {
                    //   openModalConfirm();
                    //   setPopUp('modalConfirm');
                    //   setCodigoBarras(newValue);
                }
                //   setIsLoaded(false);
            })

            //alert("codigo de barras "+ newValue)
        } else {
            let texto = `${event.target.value}`;
            setTextSearch(texto);
            if (!texto) {
                setFilter([...products])
            }
        }
    }

    const SaveListPrice = async () => {
        await listaPrecioService.editListaPrecioDetalle(selectPrice, selectPrice.id_detalle);
        getListaPrecioProducto(listaPrecio.id);
    }

    return (
        <div className="container">
            <Snackbar
                open={snackBarConfig.open}
                autoHideDuration={snackBarConfig.autoHideDuration}
                onClose={() => setSnackBarConfig(prev => ({ ...prev, open: false }))}
                anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
            >
                <Alert
                    onClose={() => setSnackBarConfig(prev => ({ ...prev, open: false }))}
                    severity={snackBarConfig.severity}
                    variant="filled"
                >
                    {snackBarConfig.message}
                </Alert>
            </Snackbar>
            <Grid container>
                <Grid item xs={12}>
                    <div className="H-card__fcontainer">
                        <div className="H-card__fcontainer__filter">
                            <span>Filtro:</span>
                            <TextField
                                type="text"
                                id="mi-codigo-de-barras"
                                placeholder="Buscar..."
                                onKeyDown={handleKeyDown}
                                className="H-card__fcontainer__filter__input"
                                style={{ backgroundColor: "white", width: "290px", marginLeft: "10px" }}
                            />
                        </div>
                    </div>
                </Grid>
                <Grid item xs={12}>
                    <TableContainer component={Paper}>
                        <Table sx={{ minWidth: 650 }} aria-label="simple table">
                            <TableHead>
                                <TableRow>
                                    <TableCell>Código</TableCell>
                                    <TableCell>Nombre</TableCell>
                                    <TableCell >Precio</TableCell>
                                    {/* <TableCell align="right">Precio oferta&nbsp;</TableCell> */}
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {(rowsPerPage > 0
                                    ? filter.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                    : filter
                                )
                                    .map((products, index) => (
                                        <TableRow
                                            key={products.id}
                                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                            classes={{
                                                hover: 'hover-row-t'
                                            }}
                                        >
                                            <TableCell component="th" scope="row">
                                                <span className="tabla">{products.codigo}</span>
                                            </TableCell>
                                            <TableCell component="th" scope="row">
                                                <button className="H-card__tableButton"
                                                //onClick={() => handleSelectProduct(products)}
                                                >
                                                    <span className="tabla">{products.producto.charAt(0).toUpperCase() + products.producto.slice(1).toLowerCase()}</span>
                                                </button>
                                            </TableCell>
                                            <TableCell component="th" scope="row">
                                                {modeEditPrice===true && selectPrice.codigo === products.codigo?
                                                <TextField
                                                id="select-price"
                                                type="text"
                                                name="nombre"
                                                size="small"
                                                fullWidth
                                                value={selectPrice.precio}
                                                onChange={(e) => {
                                                    setSelectPrice(prev=>({...prev, precio: e.target.value}));
                                                }}
                                                placeholder="Nombre de la sucursal"
                                                //error={errors.nombre && touched.nombre ? true : false}
                                            />
                                                :
                                                <TextField
                                                    id={"precio" + products.codigo}
                                                    type="text"
                                                    name="nombre"
                                                    size="small"
                                                    fullWidth
                                                    value={selectPrice.codigo === products.codigo? selectPrice.precio : products.precio}
                                                    placeholder="Nombre de la sucursal"
                                                    //error={errors.nombre && touched.nombre ? true : false}
                                                />}
                                            </TableCell>
                                            <TableCell>
                                                {modeEditPrice === true && selectPrice.codigo === products.codigo ?
                                                    <>
                                                        <Button
                                                        onClick={() => {
                                                            setModeEditPrice(false)
                                                            SaveListPrice()
                                                        }}
                                                        >Guardar</Button>
                                                        <Button
                                                        onClick={() => {
                                                            setModeEditPrice(false)
                                                            setSelectPrice({})
                                                        }}
                                                        >Cancelar</Button>
                                                    </>
                                                    :
                                                    <Button onClick={() => {
                                                        setModeEditPrice(true)
                                                        setSelectPrice(products)
                                                    }}>
                                                        Editar
                                                    </Button>}
                                            </TableCell>
                                        </TableRow>
                                    ))}
                            </TableBody>
                            <TableFooter>
                                <TableRow>
                                    <TablePagination
                                        count={filter.length}
                                        rowsPerPage={rowsPerPage}
                                        page={page}
                                        onPageChange={handleChangePage}
                                        onRowsPerPageChange={handleChangeRowsPerPage}
                                        rowsPerPageOptions={[5, 10, 15, 20]}
                                        labelRowsPerPage={<span className="tabla">Filas:</span>}
                                        labelDisplayedRows={({ page }) => {
                                            return `Página: ${page + 1}`;
                                        }}
                                        SelectProps={{
                                            inputProps: {
                                                "aria-label": "page number"
                                            }
                                        }}
                                        showFirstButton={true}
                                        showLastButton={true}
                                    />
                                </TableRow>
                            </TableFooter>
                        </Table>
                    </TableContainer>
                </Grid>
            </Grid>
        </div>
    );
}
