import * as React from 'react';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import Collapse from '@mui/material/Collapse';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import { salas } from '@/service/services/Salas.service';
import { ticketService } from '@/service/services/Ticket.service';
import { Snackbar, Alert, Grid, Paper, Typography, TableCell, TableRow, TableContainer, Table, TableBody } from '@mui/material';
import { Loading } from '@/components/common/Loading';
import TableRestaurantIcon from '@mui/icons-material/TableRestaurant';
import RestaurantIcon from '@mui/icons-material/Restaurant';

export const ModalListTable: React.FC<any> = (props: any): JSX.Element => {
    const { idPedido, loadPedidos, closeModal } = props;

    const [loading, setLoading] = React.useState<boolean>(false);
    const [open, setOpen] = React.useState(true);
    const [salasE, setSalas] = React.useState<Array<any>>([]);
    const [mesas, setMesas] = React.useState<Array<any>>([]);
    const [selectedSala, setSelectedSala] = React.useState<any>(null)
    const [selectedMesa, setSelectedMesa] = React.useState<any>(null)
    const [diseñoSala, setDiseñoSala] = React.useState<any[]>([])
    const [snackBarConfig, setSnackBarConfig] = React.useState<any>({
        open: false,
        severity: 'error',
        message: 'Error',
        autoHideDuration: 3000,
    });

    const fecha = new Date();

    React.useEffect(() => {
        if (mesas.length > 0) {
            const sala = salasE.find(sala => sala.id === mesas[0].id_sala)
            setSelectedSala(sala)
        }
    }, [mesas, salasE])

    const handleClick = () => {
        setOpen(!open);
    };

    const changeMesa = async (mesa) => {
        setLoading(true);
        await ticketService.changeTablePedido(idPedido, mesa.id).then((data) => {
            if (data.status === 200) {
                setSnackBarConfig(prev => ({
                    ...prev,
                    open: true,
                    severity: 'success',
                    message: `El cambio se realizó con éxito.`,
                }));
                loadPedidos && loadPedidos(fecha);
                closeModal && closeModal();
            }
        })
        setLoading(false);
    };

    React.useEffect(() => {
        setLoading(true);
        salas.cargarSalas().then((data) => {
            setSalas(data.salas || [])
        })

        salas.cargarAllMesas().then((data) => {
            setMesas(data.mesas || [])
        })

        setLoading(false);
    }, []);


    React.useEffect(() => {
        if (selectedSala) actualizarDiseñoSala(selectedSala.filas, selectedSala.columnas)
    }, [selectedSala])

    const actualizarDiseñoSala = (numFilas: number, numColumnas: number) => {
        const nuevoDiseñoSala = [];
        for (let i = 0; i < numFilas; i++) {
            for (let j = 0; j < numColumnas; j++) {
                const mesaExistente = selectedSala.all_mesas.find(mesa => mesa.fila === i && mesa.columna === j);
                if (mesaExistente) {
                    nuevoDiseñoSala.push(mesaExistente);
                } else {
                    nuevoDiseñoSala.push({
                        nombre: '',
                        fila: i,
                        columna: j,
                    });
                }
            }
        }
        setDiseñoSala(nuevoDiseñoSala);
    };

    const renderMesas = () => {
        const rows = [];
        const numFilas = Math.max(...diseñoSala.map(mesa => mesa.fila)) + 1;
        const numColumnas = Math.max(...diseñoSala.map(mesa => mesa.columna)) + 1;

        for (let i = 0; i < numFilas; i++) {
            const cells = [];
            for (let j = 0; j < numColumnas; j++) {
                const mesa = diseñoSala.find(m => m.fila === i && m.columna === j);

                if (mesa.id) {
                    cells.push(
                        <TableCell key={`cell-${i}-${j}`} sx={{ border: 'none', width: 'fit-content' }}>
                            <Paper elevation={3}
                                style={{
                                    width: '75px',
                                    height: '75px',
                                    display: 'flex',
                                    flexDirection: 'column',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    cursor: 'pointer',
                                    background: mesa?.state === 0 ? '#990000' : (selectedMesa?.id === mesa?.id ? '#E67300' : '#229954'),
                                    color: '#fff',
                                    margin: '0px auto'
                                }}
                                onClick={() => mesa?.state === 0 ? null : handleSelectMesa(mesa)}
                            >
                                <Typography variant="h6" sx={{ textAlign: 'center', fontSize: 'calc(10px + 0.5vw)', color: 'white' }}>{mesa?.nombre.toUpperCase().split(" DE ")[0]}</Typography>
                                <TableRestaurantIcon />
                            </Paper>
                        </TableCell>
                    );
                } else {
                    cells.push(
                        <TableCell key={`cell-${i}-${j}`} sx={{ border: 'none' }}>
                            <div
                                style={{
                                    width: '75px',
                                    height: '75px',
                                    display: 'flex',
                                    flexDirection: 'column',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    background: '#efefef',
                                    color: '#fff'
                                }}
                            >
                            </div>
                        </TableCell>
                    );
                }
            }
            rows.push(<TableRow key={`row-${i}`} sx={{ border: 'none' }}>{cells}</TableRow>);
        }
        return rows;
    };

    const handleSelectMesa = async (mesa) => {
        setSelectedMesa(mesa)
        changeMesa(mesa)
    }

    return (
        <div>
            {loading && <Loading />}
            <Snackbar
                open={snackBarConfig.open}
                autoHideDuration={snackBarConfig.autoHideDuration}
                onClose={() => setSnackBarConfig(prev => ({ ...prev, open: false }))}
                anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
            >
                <Alert
                    onClose={() => setSnackBarConfig(prev => ({ ...prev, open: false }))}
                    severity={snackBarConfig.severity}
                    variant="filled"
                >
                    {snackBarConfig.message}
                </Alert>
            </Snackbar>
            {/* <List
                sx={{ width: '100%', bgcolor: 'background.paper' }}
                component="nav"
                aria-labelledby="nested-list-subheader"
            >
                {
                    (salasE.length > 0) && (
                        salasE.map((sala, index) => (
                            <>
                            <ListItemButton onClick={handleClick}>
                                <ListItemText primary={`${sala.nombre}`} />
                                {open ? <ExpandLess /> : <ExpandMore />}
                            </ListItemButton>

                            <Collapse in={open} timeout="auto" unmountOnExit>
                                <List component="div" disablePadding>
                                {
                                    (mesas.length !== 0) && (
                                        mesas.map((mesa, index)=>{
                                           if(mesa.id_sala === sala.id){
                                              return (
                                                <ListItemButton sx={{ pl: 4 }} onClick={() => {changeMesa(mesa)}}>
                                                    <ListItemText primary={`${mesa.nombre}`}/>
                                                </ListItemButton>
                                              )
                                           }
                                        })
                                    )
                                }
                                </List>
                            </Collapse>
                            </>
                        ))
                    )
                }
            </List> */}
            <Grid container spacing={2}>
                <Grid item container xs={12} spacing={2} sx={{ display: 'flex', justifyContent: 'center', alignContent: 'center', overflowX: 'auto' }}>
                    {salasE?.map((sala) => (
                        salasE.length !== 0 &&
                        <Grid item key={sala.id}>
                            <Paper elevation={3}
                                style={{
                                    width: '175px',
                                    height: '100px',
                                    padding: '30px',
                                    display: 'flex',
                                    flexDirection: 'column',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    cursor: 'pointer',
                                    background: selectedSala?.id === sala.id ? '#2b3a47' : '#516E80',
                                    color: 'white'
                                }}
                                onClick={() => setSelectedSala(sala)}
                            >
                                <Typography variant="h6" sx={{ textAlign: 'center', fontSize: 'calc(12px + 0.5vw)', color: 'white' }}>{sala.nombre.toUpperCase()}</Typography>
                                <RestaurantIcon />
                            </Paper>
                        </Grid>
                    ))}
                </Grid>
                <Grid item container xs={12} spacing={2} sx={{ display: 'flex', justifyContent: 'center', alignContent: 'center' }}>
                    <Grid item xs={12}>
                        <TableContainer component={Paper}>
                            <Table sx={{ borderCollapse: 'separate', borderSpacing: '0', borderRadius: '5px', background: '#efefef' }}>
                                <TableBody>
                                    {renderMesas()}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Grid>
                </Grid>
            </Grid>
        </div>
    );
}