import { ticketRepository } from "../repositories/Ticket.repository";
import { PedidoRepository } from "../repositories/Pedido.repository";

import { KEY_EMPRESA, KEY_USER_DATA } from '@constants/local-storage';
import { readLocalStorage } from '@helpers/local-storage-helper';

// import axios from "axios";
// import {KEY_USER_DATA} from '@constants/local-storage';
// import {readLocalStorage} from '@helpers/local-storage-helper';

export const ticketService = {
   generarBoleta,
   generarTicket,
   getTickets,
   getPedidos,
   getTicketsFecha,
   //carga productos vendidos
   getDetalleTicket,

   //detalle de pedidos-carga detalle de los tickets
   getPedidoDetalle,
   getTicketDetalle,

   getPedidoDetalleByMesa,
   editPedidoStatus,
   backState,
   changeTablePedido,
   getPedidoXFinalizar,
   deleteTickets,
   editarPedido,
   editarDetallePedido,
   getPedidoFinalizadoByClient,
   getPedidoDetailByClient,
   getTicketsByClient,
   editarPedidoV2,
   updatePedido
}


async function generarBoleta(totalCard: number, state: number, products: Array<any>) {

}

async function generarTicket(id_mesa: string, id_cliente: string, totalCard: number, descuentoMonto: number, descuentoProducto: number, state: string, products: Array<any>/* , idForma: number */, arrayIdsProducto: Array<any>) {
   let data = readLocalStorage(KEY_USER_DATA)
   let company = readLocalStorage(KEY_EMPRESA)
   const fecha = new Date();
   const num_ticket = `T-${fecha.getTime()}-${data.user?.id_empresa || company?.id}`;
   const res = await ticketRepository.saveTicket(num_ticket, totalCard, state ?? 'pendiente');
   if (res?.ticketID) {
      for (let i = 0; i < products.length; i++) {
         const element = products[i];
         await ticketRepository.saveTicketDetail(res.ticketID, arrayIdsProducto[i], element.cantidad, 1, element?.descuento, element?.precio_unitario, element?.subProductos);
      }
   }
   const respPedido = await PedidoRepository.savePedido(res.ticketID, id_mesa, id_cliente, state,
      "boleta", totalCard, descuentoMonto, descuentoProducto/* , idForma */, data.user?.id_usuario, 0)
   if (respPedido) {
      return {resTicket: res, respPedido: respPedido };
   }

}

async function getTickets(state: number, fecha: any) {
   const res = await ticketRepository.getTickets(state, fecha);
   return res;
}

async function getPedidos(state: any, fecha: any) {
   const res = await ticketRepository.getPedidos(state, fecha);
   return res;
}

async function getTicketsFecha(state: any, fecha: any) {
   const res = await ticketRepository.getTicketsFecha(state, fecha);
   return res;
}

async function getDetalleTicket(id_ticket: any) {
   const res = await ticketRepository.getTicketDetail(id_ticket);
   return res;
}

async function getPedidoDetalle(idPedido: number) {
   const res = await PedidoRepository.getPedidoDetalle(idPedido);
   return res;
}

async function getTicketDetalle(idTicket: number) {
   const res = await PedidoRepository.getTicketDetalle(idTicket);
   return res;
}

async function getPedidoDetalleByMesa(idMesa: number) {
   const res = await PedidoRepository.getPedidoDetalleByMesa(idMesa);
   return res;
}

async function editPedidoStatus(idTicket: number, estado: string, idPedido: number,) {
   const res = await PedidoRepository.editPedidoStatus(idTicket, estado, idPedido);
   return res;
}

async function backState(idTicket: number, estado: string) {
   const res = await PedidoRepository.backState(idTicket, estado);
   return res;
}

async function changeTablePedido(idPedido: number, idMesa: number) {
   const res = await PedidoRepository.changeTablePedido(idPedido, idMesa);
   return res;
}

async function getPedidoXFinalizar() {
   const res = await PedidoRepository.getPedidoXFinalizar();
   return res;
}

async function deleteTickets(id_ticket: any) {
   const res = await ticketRepository.deleteDetalleTicket(id_ticket);
   return res;
}

async function getPedidoFinalizadoByClient() {
   const res = await PedidoRepository.getPedidoFinalizadoByClient();
   return res;
}
//A ELIMINAR
async function getPedidoDetailByClient() {
   const res = await PedidoRepository.getPedidoDetailByClient();
   return res;
}

async function getTicketsByClient() {
   const res = await PedidoRepository.getTicketsByClient();
   return res;
}

async function editarPedido(idPedido: number, cliente: string, estado: string, montoTotal: number, descuentoMonto: number, descuentoPorducto: number) {
   const res = await PedidoRepository.editPedido(idPedido, cliente, estado, montoTotal, descuentoMonto, descuentoPorducto)
   return res;
}

async function editarPedidoV2(idPedido: number, payload: {}) {
   const res = await PedidoRepository.editPedidoV2(idPedido, payload)
   return res;
}

async function editarDetallePedido(mesaSelect: any, clienteId: any, id_ticket: any, totalCard: number, descuentoMonto: number, descuentoProducto: number, products: Array<any>, arrayIdsProducto: Array<any>, id_pedido: any) {

   let data = readLocalStorage(KEY_USER_DATA)
   const fecha = new Date();
   const num_ticket = `T-${fecha.getTime()}-${data.user?.id_empresa}`;

   const res = await ticketRepository.saveTicket(num_ticket, totalCard, 'pendiente');
   if (res?.ticketID) {

      await PedidoRepository.addNewTicket(id_pedido, res.ticketID, res.montoTicket);

      for (let i = 0; i < products.length; i++) {
         const element = products[i];
         await ticketRepository.saveTicketDetail(res.ticketID, arrayIdsProducto[i], element.cantidad, 1, element?.descuento, element?.precio_unitario);
      }

      await PedidoRepository.editMontoPedido(mesaSelect, clienteId, id_pedido, res.montoTicket, descuentoMonto, descuentoProducto);
   }

   //edit ticket total
   //await ticketRepository.editTicket(id_ticket, totalCard, 1);

   //edit pedido total

}

async function updatePedido(idpedido: number, payload: {}) {
   const res = await PedidoRepository.update(idpedido, payload)
   return res;
}

