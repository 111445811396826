import { Loading } from "@/components/common/Loading";
import { empresasService } from "@/service/services/Empresas.service";
import { Autocomplete, Checkbox, Divider, Grid, TextField, Tooltip, Typography } from "@mui/material";
import { Form, Formik } from "formik";
import React, { useEffect } from "react";
import PriorityHighIcon from '@mui/icons-material/PriorityHigh';
import { Snackbar, Alert } from '@mui/material';

export const AddEmpresa: React.FC<any> = (props:any): JSX.Element => {

  const {closeModal, onListEmpresas, company} = props;
   
   const [loading, setLoading] = React.useState<boolean>(false);
   const [tipoEmpresas, setTipoEmpresas] = React.useState([]);
   const [tipoEmpresaSelect, setTipoEmpresaSelect] = React.useState<any>('');
   const [countries, setCountries] = React.useState([]);
   const [countrySelect, setCountrySelect] = React.useState<any>("");

  // eslint-disable-next-line
  const [data, setData] = React.useState<any>({
    id: company && company.id ? company.id : "",
    name: company && company.name ? company.name : "",
    rut: company && company.rut ? company.rut : "",
    // formas_de_pago: company && company.formas_de_pago ? company.formas_de_pago : "",
    state: company && company.state ? company.state : 1,
    mesas: company && company.mesas ? company.mesas : 0,
    equivalencia_puntos: company && company.equivalencia_puntos ? company.equivalencia_puntos : 1,
    unidad_sii: company && company.unidad_sii ? company.unidad_sii : "",
    giro1: company && company.giro1 ? company.giro1 : "",
    giro2: company && company.giro2 ? company.giro2 : "",
    direccion: company && company.direccion ? company.direccion : "",
    comuna: company && company.comuna ? company.comuna : "",
    ciudad: company && company.ciudad ? company.ciudad : "",

  });
  const [checked, setChecked] = React.useState<any>(company && company.mesas ? company.mesas : false);

  const [snackBarConfig, setSnackBarConfig] = React.useState<any>({
    open: false,
    severity: 'error',
    message: 'Error',
    autoHideDuration: 3000,
  });

  useEffect(() => {

    if(company){
      if (company?.tipo_empresa) {
        setTipoEmpresaSelect({
            "tipo": company.tipo_empresa.tipo,
            "id": company.tipo_empresa.id,
        })
      }
  
      if (company?.country) {
        setCountrySelect({
            "label": `${company.country.abbreviation} - ${company.country.nombre}`,
            "key": company.country.id,
        })
      }
    }

    getInfo();

  }, // eslint-disable-next-line
  [])

  const getInfo = async() => {
    setLoading(true);

    await empresasService.getTipoEmpresa().then((data)=>{
      setTipoEmpresas(data.tipos);
      if(data.tipos && !company){
        setTipoEmpresaSelect(data.tipos[0]);
      }
    })

    await empresasService.getPaises().then((data)=>{
      setCountries(data.countries.map((el) => {
        return {
          label: `${el.abbreviation} - ${el.nombre}`,
          key: el.id
        }
      }));
      if(data.countries && !company){
        setCountrySelect({
          label: `${data.countries[1].abbreviation} - ${data.countries[1].nombre}`,
          key: data.countries[1].id
        })
      }
    })
    
    setLoading(false);
  }

  const validateForm = (values) => {
    let errors: any = {};

    const formats = {
      name: /^[A-Za-zÑñÁáÉéÍíÓóÚúÜü\s]+$/,
      rutChile: /^[0-9]{8,9}[-|‐]{1}[0-9kK]{1}$/,
      rutPeru: /^\d{11}$/,
      rutColombia: /^[0-9]{9}[-|‐]{1}[0-9]{1}$/,
    }

    if(!values.name) errors.name = "Nombre requerido";
    // if(values.name && !formats.name.test(values.name)) errors.name = "Nombre inválido";
    if(!values.rut) errors.rut = "RUT requerido";

    if(countrySelect && countrySelect.label ==='CLP - Chile' && values.rut && !formats.rutChile.test(values.rut)) errors.rut = "Rut inválido";
    if(countrySelect && countrySelect.label ==='PEN - Perú' && values.rut && !formats.rutPeru.test(values.rut)) errors.rut = "Rut inválido";
    if(countrySelect && countrySelect.label ==='COP - Colombia' && values.rut && !formats.rutColombia.test(values.rut)) errors.rut = "Rut inválido";

    // if(!values.formas_de_pago) errors.formas_de_pago = "Formas de pago requerido";
    if(!values.equivalencia_puntos) errors.equivalencia_puntos = "Equivalencia - puntos requerido";

    if(!values.direccion) errors.direccion = "direccion requerido";
    if(!values.comuna) errors.comuna = "comuna requerido";
    if(!values.ciudad) errors.ciudad = "Ciudad requerido";
    if(!values.giro1) errors.giro1 = "Giro requerido";
    if(!values.unidad_sii) errors.unidad_sii = "SII requerido";

    return errors;
  }

  const onSubmit = async(values, {resetForm}) => {
    const formData = new FormData();
    formData.append('name', values.name);
    formData.append('rut', values.rut);
    // formData.append('formas_de_pago', values.formas_de_pago);
    formData.append('state', data.state);
    formData.append('mesas', checked ? '1' : '0');
    formData.append('id_tipo_empresa', tipoEmpresaSelect.id);
    formData.append('id_country', countrySelect.key);
    formData.append('equivalencia_puntos', values.equivalencia_puntos); 
    formData.append('unidad_sii', values.unidad_sii);
    formData.append('giro1', values.giro1);
    formData.append('giro2', values.giro2);
    formData.append('direccion', values.direccion);
    formData.append('comuna', values.comuna);
    formData.append('ciudad', values.ciudad); 

    setLoading(true);
    if(company){
      //Editar
      await empresasService.update(formData, values.id).then((data) => {
        onListEmpresas && onListEmpresas();
        closeModal();
      })
    }else{
      //Agregar
      await empresasService.register(formData).then((data) => {
        if(data.status === 400){
          setSnackBarConfig(prev => ({
            ...prev,
            open: true,
            message: `${data.error}`,
          }));
        }else{
          onListEmpresas && onListEmpresas();
          closeModal();
        }
      })
    }

    setLoading(false);
  }

  return (
    <div className="container">
      {loading && <Loading/>}

      <Snackbar
        open={snackBarConfig.open}
        autoHideDuration={snackBarConfig.autoHideDuration}
        onClose={() => setSnackBarConfig(prev => ({ ...prev, open: false }))}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        >
        <Alert
        onClose={() => setSnackBarConfig(prev => ({ ...prev, open: false }))}
        severity={snackBarConfig.severity}
        variant="filled"
        >
        {snackBarConfig.message}
        </Alert>
      </Snackbar>

      <Formik enableReinitialize initialValues={data} validate={ (values) => validateForm(values) } onSubmit={onSubmit}>

        {({values, errors, touched, handleSubmit, handleChange, setFieldValue}) => {
          return (
            <Form onSubmit={handleSubmit}>

              <Grid container spacing={3} alignItems="center" justifyContent="center">

                <Grid item xs={12} style={{display: "flex", alignItems: "center"}}>
                  <Grid item xs={4}>
                    <Typography variant="subtitle1" className="custom-input"><b>Nombre Empresa:</b></Typography>
                  </Grid>
                  <Grid item xs={8} style={{display: 'flex', alignItems: "center"}}>
                  {
                    errors.name && (touched.name ? true : false) && (
                        <Tooltip title={errors.name}>
                            <PriorityHighIcon className={'iconWarning'}/>
                        </Tooltip>
                    )
                  }
                    <TextField
                      id="name"
                      type="text"
                      name="name"
                      size="small"
                      fullWidth
                      value={values.name}
                      onChange={handleChange}
                      placeholder="Nombre de la Empresa"
                      error= {errors.name && touched.name ? true : false}
                    />
                  </Grid>
                </Grid>

                
                <Grid container item xs={12} style={{display: "flex", alignItems: "center"}}>
                  <Grid item xs={4}>
                    <Typography variant="subtitle1"><b>Tipo de Empresa:</b> </Typography>
                  </Grid>
                  <Grid item xs={8}>
                  <Autocomplete
                    size="small"
                    disablePortal
                    id="combo-box-demo"
                    options={tipoEmpresas || []}
                    getOptionLabel={(option) => option.tipo || ""}
                    fullWidth
                    renderInput={(params) => <TextField {...params} label="Escriba para buscar" />}
                    value={tipoEmpresaSelect}
                    onChange={(e, newValue) => {
                      setTipoEmpresaSelect(newValue);
                    }}
                  />
                  </Grid>
                </Grid>

                <Grid container item xs={12} style={{display: "flex", alignItems: "center"}}>
                  <Grid item xs={4}>
                    <Typography variant="subtitle1"><b>Moneda - Pais:</b> </Typography>
                  </Grid>
                  <Grid item xs={8}>
                  <Autocomplete
                    size="small"
                    disablePortal
                    id="combo-box-demo"
                    options={countries || []}
                    fullWidth
                    renderInput={(params) => <TextField {...params} label="Escriba para buscar" />}
                    value={
                    countrySelect
                    //  company && (company.id_tipo_empresa == tipoEmpresaSelect.key) || tipoEmpresaSelect
                    }
                    onChange={(e, newValue) => {
                      setCountrySelect(newValue);
                    }}
                  />
                  </Grid>
                </Grid>

                <Grid item xs={12} container style={{display: "flex", alignItems: "center"}}>
                  <Grid item xs={4}>
                    <Typography variant="subtitle1" className="custom-input"><b>RUT Empresa:</b></Typography>
                  </Grid>
                  <Grid item xs={8} style={{display: 'flex', alignItems: "center"}}>
                  {
                    errors.rut && (touched.rut ? true : false) && (
                        <Tooltip title={errors.rut}>
                            <PriorityHighIcon className={'iconWarning'}/>
                        </Tooltip>
                    )
                  }
                    <TextField
                      id="rut"
                      type="text"
                      name="rut"
                      fullWidth
                      size="small"
                      value={values.rut}
                      onChange={handleChange}
                      placeholder={countrySelect && (countrySelect.label ==='PEN - Perú' ? 'XXXXXXXXXXX' : (countrySelect.label=== 'CLP - Chile' ? 'xxxxxxxx-x' : 'xxxxxxxxx-x') )}
                      error= {errors.rut && touched.rut ? true : false}
                    />
                  </Grid>
                </Grid>
{/* 
                <Grid item xs={12} container style={{display: "flex", alignItems: "center"}}>
                  <Grid item xs={4}>
                    <Typography variant="subtitle1" className="custom-input"><b>Servicios:</b></Typography>
                  </Grid>
                  <Grid item xs={8} style={{display: 'flex', alignItems: "center"}}>
                    <Autocomplete
                      multiple
                      id="combo-box-demo"
                      disablePortal
                      fullWidth
                      size="small"
                      value={Selectedservices || []}
                      options={services || []}
                      getOptionLabel={(option) => option.name || ""}
                      renderInput={params => (
                          <TextField {...params} fullWidth />
                      )}
                      onChange={(event, newValues) => {
                        setSelectedServices(newValues);
                      }}
                    />
                  </Grid>
                </Grid> */}

                {/* <Grid item xs={12} container style={{display: "flex", alignItems: "center"}}>
                  <Grid item xs={4}>
                    <Typography variant="subtitle1" className="custom-input"><b>Formas de Pago:</b></Typography>
                  </Grid>
                  <Grid item xs={8} style={{display: 'flex', alignItems: "center"}}>
                    {
                        errors.formas_de_pago && (touched.formas_de_pago ? true : false) && (
                            <Tooltip title={errors.formas_de_pago}>
                                <PriorityHighIcon className={'iconWarning'}/>
                            </Tooltip>
                        )
                    }
                    <TextField
                      id="formas_de_pago"
                      type="text"
                      name="formas_de_pago"
                      fullWidth
                      value={values.formas_de_pago}
                      onChange={handleChange}
                      placeholder="Formas de pago"
                      error= {errors.formas_de_pago && touched.formas_de_pago ? true : false}
                    />
                  </Grid>
                </Grid> */}

                <Grid item xs={12} container style={{display: "flex", alignItems: "center"}}>
                  <Grid item xs={4}>
                      <Typography variant="subtitle1" className="custom-input"><b>SII:</b></Typography>
                  </Grid>
                  <Grid item xs={8} style={{display: 'flex', alignItems: "center"}}>
                      {
                          errors.unidad_sii && (touched.unidad_sii ? true : false) && (
                              <Tooltip title={errors.unidad_sii}>
                                  <PriorityHighIcon className={'iconWarning'}/>
                              </Tooltip>
                          )
                      }
                      <TextField
                      id="unidad_sii"
                      type="text"
                      name="unidad_sii"
                      fullWidth
                      size="small"
                      value={values.unidad_sii}
                      onChange={handleChange}
                      placeholder="unidad sii"
                      error={errors.unidad_sii && touched.unidad_sii ? true : false}
                      />
                  </Grid>
                </Grid>

                <Grid item xs={12} container style={{display: "flex", alignItems: "center"}}>
                  <Grid item xs={4}>
                      <Typography variant="subtitle1" className="custom-input"><b>Giro 1:</b></Typography>
                  </Grid>
                  <Grid item xs={8} style={{display: 'flex', alignItems: "center"}}>
                      {
                          errors.giro1 && (touched.giro1 ? true : false) && (
                              <Tooltip title={errors.giro1}>
                                  <PriorityHighIcon className={'iconWarning'}/>
                              </Tooltip>
                          )
                      }
                      <TextField
                      id="giro1"
                      type="text"
                      name="giro1"
                      fullWidth
                      size="small"
                      value={values.giro1}
                      onChange={handleChange}
                      placeholder="Giro"
                      error={errors.giro1 && touched.giro1 ? true : false}
                      />
                  </Grid>
                </Grid>

                <Grid item xs={12} container style={{display: "flex", alignItems: "center"}}>
                  <Grid item xs={4}>
                      <Typography variant="subtitle1" className="custom-input"><b>Giro 2:</b></Typography>
                  </Grid>
                  <Grid item xs={8} style={{display: 'flex', alignItems: "center"}}>
                      <TextField
                      id="giro2"
                      type="text"
                      name="giro2"
                      fullWidth
                      size="small"
                      value={values.giro2}
                      onChange={handleChange}
                      placeholder="Giro"
                      />
                  </Grid>
                </Grid>

                <Grid item xs={12} container style={{display: "flex", alignItems: "center"}}>
                  <Grid item xs={4}>
                      <Typography variant="subtitle1" className="custom-input"><b>Dirección:</b></Typography>
                  </Grid>
                  <Grid item xs={8} style={{display: 'flex', alignItems: "center"}}>
                      {
                          errors.direccion && (touched.direccion ? true : false) && (
                              <Tooltip title={errors.direccion}>
                                  <PriorityHighIcon className={'iconWarning'}/>
                              </Tooltip>
                          )
                      }
                      <TextField
                      id="direccion"
                      type="text"
                      name="direccion"
                      fullWidth
                      size="small"
                      value={values.direccion}
                      onChange={handleChange}
                      placeholder="Dirección"
                      error={errors.direccion && touched.direccion ? true : false}
                      />
                  </Grid>
              </Grid>

              <Grid item xs={12} container style={{display: "flex", alignItems: "center"}}>
                  <Grid item xs={4}>
                      <Typography variant="subtitle1" className="custom-input"><b>Comuna:</b></Typography>
                  </Grid>
                  <Grid item xs={8} style={{display: 'flex', alignItems: "center"}}>
                      {
                          errors.comuna && (touched.comuna ? true : false) && (
                              <Tooltip title={errors.comuna}>
                                  <PriorityHighIcon className={'iconWarning'}/>
                              </Tooltip>
                          )
                      }
                      <TextField
                      id="comuna"
                      type="text"
                      name="comuna"
                      fullWidth
                      size="small"
                      value={values.comuna}
                      onChange={handleChange}
                      placeholder="Comuna"
                      error={errors.comuna && touched.comuna ? true : false}
                      />
                  </Grid>
              </Grid>

              <Grid item xs={12} container style={{display: "flex", alignItems: "center"}}>
                  <Grid item xs={4}>
                      <Typography variant="subtitle1" className="custom-input"><b>Ciudad:</b></Typography>
                  </Grid>
                  <Grid item xs={8} style={{display: 'flex', alignItems: "center"}}>
                      {
                          errors.ciudad && (touched.ciudad ? true : false) && (
                              <Tooltip title={errors.ciudad}>
                                  <PriorityHighIcon className={'iconWarning'}/>
                              </Tooltip>
                          )
                      }
                      <TextField
                      id="ciudad"
                      type="text"
                      name="ciudad"
                      fullWidth
                      size="small"
                      value={values.ciudad}
                      onChange={handleChange}
                      placeholder="Ciudad"
                      error={errors.ciudad && touched.ciudad ? true : false}
                      />
                  </Grid>
              </Grid>

                {
                  company && (
                    <Grid item xs={12} container style={{display: "flex", alignItems: "center"}}>
                      <Grid item xs={4}>
                        <Typography variant="subtitle1" className="custom-input"><b>Equivalencia - puntos:</b></Typography>
                      </Grid>
                      <Grid item xs={8}>
                        <TextField
                          id="equivalencia_puntos"
                          type="number"
                          name="equivalencia_puntos"
                          fullWidth
                          size="small"
                          value={values.equivalencia_puntos}
                          onChange={handleChange}
                          placeholder="Valor por punto"
                          error= {errors.equivalencia_puntos && touched.equivalencia_puntos ? true : false}
                        />
                      </Grid>
                    </Grid>
                  )
                }

                <Grid item xs={12} container style={{display: "flex", alignItems: "center"}}>
                  <Grid item xs={4} className="custom-input">
                      <Typography variant="subtitle1" className="custom-input"><b>Mesas: </b></Typography>
                  </Grid>
                  <Grid item xs={8}>
                      <Checkbox
                          id="is_supply"
                          name="is_supply"
                          checked={checked}
                          onChange={(event) => {
                              setChecked(event.target.checked)
                          }}
                      />
                  </Grid>
                </Grid>

              </Grid>

              <Divider sx={{ my: 2 }} />

              <div className="form-group mb-3 buton_container_modal">
                <button type="submit" className="btn btn-primary">
                  {
                    company ? `Editar Empresa` : `Agregar Empresa`
                  }
                </button>
              </div>

            </Form>
          )
        }}

      </Formik>
    </div>
  );
}
