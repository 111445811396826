import { productoRepository } from "../repositories/Producto.repository";
// import axios from "axios";
// import {KEY_USER_DATA} from '@constants/local-storage';
// import {readLocalStorage} from '@helpers/local-storage-helper';

export const productoService = {
   register,
   cargarModulos,
   getProductsByCompany,
   getProductsByCodigo,
   getProductsSimplesByMonth,
   getProductsSimplesByDay,
   getProductsSimplesByFechas,
   getProductsSimplesByDFechasByResponsable,
   getProductsMenuByMonth,
   getProductsMenuByDay,
   getProductsMenuByFechas,
   getProductsMenuByFechasByResponsable,
   getProductsAgrupadoByMonth,
   getProductsAgrupadoByDay,
   getProductsAgrupadoByFechas,
   getProductsAgrupadoByFechasByResponsable,
   getTypeProductsByMes,
   getTypeProductsByDay,
   getTypeProductsByFechas,
   topProductsMoreSelledMes,
   topProductsMoreSelledDay,
   topProductsMoreSelledFechas,
   topProductSimpleMes,
   topProductSimpleDay,
   topProductSimpleFechas,
   topProductAgrupadoMes,
   topProductAgrupadoDay,
   topProductAgrupadoFechas,
   topProductMenuMes,
   topProductMenuDay,
   topProductMenuFechas,
   getProductosSimplesCompany,
   getProductosNoInsumo,
   getProductosInsumo,
   getPedidosProductos,
   getPedidosProductosByCocina,
   getSimplesWithStock,
   getProductosBalanza,
   topProductsByAgrupadoMes,
   topProductsByAgrupadoDay,
   topProductsByAgrupadoFechas,
   topProductsByMenuMes,
   topProductsByMenuDay,
   topProductsByMenuFechas,
   validateProductsSofnet,
   getProductosNokitchen,
   searchProductsByFilters,
   syncFamiliaProducts,
   syncSubFamiliaProducts,
   syncLineaProducts,
   getFamiliesProducts,
   getSubFamiliesByIdFamilia,
   getLinesProducts,
   getFamiliesAndSubFamilies,
   downloadTemplateImport,
   importProducts,
   update,
   downloadInsumoTemplateImport,
   importInsumos,
   getInsumosVenta
}

async function register(data: {}) {
   const res = await productoRepository.register(data);
   return res;
}

async function update(id: number, data: {}) {
   const res = await productoRepository.update(id, data);
   return res;
}

async function validateProductsSofnet() {
   const res = await productoRepository.validateProductsSofnet();
   return res;
}

async function syncFamiliaProducts() {
   const res = await productoRepository.syncFamiliaProducts();
   return res;
}

async function syncSubFamiliaProducts() {
   const res = await productoRepository.syncSubFamiliaProducts();
   return res;
}

async function syncLineaProducts() {
   const res = await productoRepository.syncLineaProducts();
   return res;
}

async function cargarModulos() {
   const res = await productoRepository.getAllProducts();
   return res;
}

async function getProductsByCompany(idCompany: number) {
   const res = await productoRepository.getProductsByCompany(idCompany);
   return res;
}

async function getSimplesWithStock(idCompany: number) {
   const res = await productoRepository.getSimplesWithStock(idCompany);
   return res;
}

async function getProductsByCodigo(codigo: number) {
   const res = await productoRepository.getProductsByCodigo(codigo);
   return res;
}

async function getProductosSimplesCompany(idCompany: number) {
   const res = await productoRepository.getProductosSimplesCompany(idCompany);
   return res;
}

async function getProductosNoInsumo(idCompany: number) {
   const res = await productoRepository.getProductosNoInsumo(idCompany);
   return res;
}

async function getProductosInsumo(idCompany: number) {
   const res = await productoRepository.getProductosInsumo(idCompany);
   return res;
}

async function getProductosBalanza() {
   const res = await productoRepository.getProductosBalanza();
   return res;
}

async function getProductosNokitchen() {
   const res = await productoRepository.getProductosNokitchen();
   return res;
}

async function searchProductsByFilters(term1: string = "", term2: string = "", term3: string = "") {
   const res = await productoRepository.searchProductsByFilters(term1, term2, term3);
   return res;
}

async function getFamiliesProducts() {
   const res = await productoRepository.getFamiliesProducts();
   return res;
}

async function getSubFamiliesByIdFamilia(idFamily: number) {
   const res = await productoRepository.getSubFamiliesByIdFamilia(idFamily);
   return res;
}

async function getLinesProducts() {
   const res = await productoRepository.getLinesProducts();
   return res;
}

async function getFamiliesAndSubFamilies() {
   const res = await productoRepository.getFamiliesAndSubFamilies();
   return res;
}

async function downloadTemplateImport() {
   const res = await productoRepository.downloadTemplateImport();
   return res;
}

async function downloadInsumoTemplateImport() {
   const res = await productoRepository.downloadInsumoTemplateImport();
   return res;
}

//REPORTES

async function getProductsSimplesByMonth(fecha: any) {
   const res = await productoRepository.getProductsSimplesByMonth(fecha);
   return res;
}

async function getProductsSimplesByDay(fecha: any) {
   const res = await productoRepository.getProductsSimplesByDay(fecha);
   return res;
}

async function getProductsSimplesByFechas(fecha_de: any, fecha_a: any) {
   const res = await productoRepository.getProductsSimplesByDFechas(fecha_de, fecha_a);
   return res;
}

async function getProductsSimplesByDFechasByResponsable(fecha_de: any, fecha_a: any, filtro: string, id_responsable: number) {
   const res = await productoRepository.getProductsSimplesByDFechasByResponsable(fecha_de, fecha_a, filtro, id_responsable);
   return res;
}

async function getProductsMenuByMonth(fecha: any) {
   const res = await productoRepository.getProductsMenuByMonth(fecha);
   return res;
}

async function getProductsMenuByDay(fecha: any) {
   const res = await productoRepository.getProductsMenuByDay(fecha);
   return res;
}

async function getProductsMenuByFechas(fecha_de: any, fecha_a: any) {
   const res = await productoRepository.getProductsMenuByDFechas(fecha_de, fecha_a);
   return res;
}

async function getProductsMenuByFechasByResponsable(fecha_de: any, fecha_a: any, filtro: string, id_responsable: number) {
   const res = await productoRepository.getProductsMenuByFechasByResponsable(fecha_de, fecha_a, filtro, id_responsable);
   return res;
}

async function getProductsAgrupadoByMonth(fecha: any) {
   const res = await productoRepository.getProductsAgrupadoByMonth(fecha);
   return res;
}

async function getProductsAgrupadoByDay(fecha: any) {
   const res = await productoRepository.getProductsAgrupadoByDay(fecha);
   return res;
}

async function getProductsAgrupadoByFechas(fecha_de: any, fecha_a: any) {
   const res = await productoRepository.getProductsAgrupadoByDFechas(fecha_de, fecha_a);
   return res;
}

async function getProductsAgrupadoByFechasByResponsable(fecha_de: any, fecha_a: any, filtro: string, id_responsable: number) {
   const res = await productoRepository.getProductsAgrupadoByFechasByResponsable(fecha_de, fecha_a, filtro, id_responsable);
   return res;
}

async function getPedidosProductos(payload?: {}) {
   const res = await productoRepository.getPedidosProductos(payload);
   return res;
}

async function getPedidosProductosByCocina(idCocina: number) {
   const res = await productoRepository.getPedidosProductosByCocina(idCocina);
   return res;
}
//DONAS DASHBOARD
async function getTypeProductsByMes(fecha: any) {
   const res = await productoRepository.getTypeProductsByMes(fecha);
   return res;
}

async function getTypeProductsByDay(fecha: any) {
   const res = await productoRepository.getTypeProductsByDay(fecha);
   return res;
}

async function getTypeProductsByFechas(fecha_de: any, fecha_a: any) {
   const res = await productoRepository.getTypeProductsByFechas(fecha_de, fecha_a);
   return res;
}

//BARRAS DASHBOARD
async function topProductsMoreSelledMes(fecha: any) {
   const res = await productoRepository.topProductsMoreSelledMes(fecha);
   return res;
}

async function topProductsMoreSelledDay(fecha: any) {
   const res = await productoRepository.topProductsMoreSelledDay(fecha);
   return res;
}

async function topProductsMoreSelledFechas(fecha_de: any, fecha_a: any) {
   const res = await productoRepository.topProductsMoreSelledFechas(fecha_de, fecha_a);
   return res;
}

//top product Simple
async function topProductSimpleMes(fecha: any) {
   const res = await productoRepository.topProductSimpleMes(fecha);
   return res;
}

async function topProductSimpleDay(fecha: any) {
   const res = await productoRepository.topProductSimpleDay(fecha);
   return res;
}

async function topProductSimpleFechas(fecha_de: any, fecha_a: any) {
   const res = await productoRepository.topProductSimpleFechas(fecha_de, fecha_a);
   return res;
}
//top product Agrupado
async function topProductAgrupadoMes(fecha: any) {
   const res = await productoRepository.topProductAgrupadoMes(fecha);
   return res;
}

async function topProductAgrupadoDay(fecha: any) {
   const res = await productoRepository.topProductAgrupadoDay(fecha);
   return res;
}

async function topProductAgrupadoFechas(fecha_de: any, fecha_a: any) {
   const res = await productoRepository.topProductAgrupadoFechas(fecha_de, fecha_a);
   return res;
}

//top product Menu
async function topProductMenuMes(fecha: any) {
   const res = await productoRepository.topProductMenuMes(fecha);
   return res;
}

async function topProductMenuDay(fecha: any) {
   const res = await productoRepository.topProductMenuDay(fecha);
   return res;
}

async function topProductMenuFechas(fecha_de: any, fecha_a: any) {
   const res = await productoRepository.topProductMenuFechas(fecha_de, fecha_a);
   return res;
}

//productos mas vendidos por agrupado
async function topProductsByAgrupadoMes(fecha: any) {
   const res = await productoRepository.topProductsByAgrupadoMes(fecha);
   return res;
}

async function topProductsByAgrupadoDay(fecha: any) {
   const res = await productoRepository.topProductsByAgrupadoDay(fecha);
   return res;
}

async function topProductsByAgrupadoFechas(fecha_de: any, fecha_a: any) {
   const res = await productoRepository.topProductsByAgrupadoFechas(fecha_de, fecha_a);
   return res;
}

//productos mas vendidos por menu
async function topProductsByMenuMes(fecha: any) {
   const res = await productoRepository.topProductsByMenuMes(fecha);
   return res;
}

async function topProductsByMenuDay(fecha: any) {
   const res = await productoRepository.topProductsByMenuDay(fecha);
   return res;
}

async function topProductsByMenuFechas(fecha_de: any, fecha_a: any) {
   const res = await productoRepository.topProductsByMenuFechas(fecha_de, fecha_a);
   return res;
}

async function importProducts(data: FormData) {
   const res = await productoRepository.import(data);
   return res;
}

async function importInsumos(data: FormData) {
   const res = await productoRepository.importInsumo(data);
   return res;
}

async function getInsumosVenta(payload?: {}) {
   const res = await productoRepository.getInsumosVenta(payload);
   return res;
}