import React from "react";
import { Box, Button, createTheme, Grid, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, ThemeProvider } from "@mui/material";
import { esES } from "@mui/material/locale";
import { PencilIcon } from "@/toolbox/constants/icons";
import { Props } from "./BeneficioMonto.type";
import DeleteIcon from '@mui/icons-material/Delete';
import { Popup } from "@/views/Editor/components/Popup";
import { AddModalMonto } from "../ModalAddMonto";
import { DeleteModalMonto } from "../ModalDeleteMonto";

const theme = createTheme(
    {
        palette: {
            primary: { main: '#1976D2' },
        },
    },
    esES,
);

export const BeneficioMonto: React.FC<Props> = (props:Props):JSX.Element => {

    const {beneficiosMonto, onListBeneficioMonto} = props;

    const [openPopup, setOpenPopup] = React.useState<boolean>(false);
    const [popUp, setPopUp] = React.useState<String>('');

    const [selectedBeneficioMonto, setSelectedBeneficioMonto] = React.useState<any>('');
    const [showModalDelete, setShowModalDelete]= React.useState(false);

    const openModalDelete=()=>{
        setShowModalDelete(true);
    }

    const closeModalDelete=()=>{
        setShowModalDelete(false);
    }

    return (
        <Grid>
            <Box sx={{Width: '100%', marginTop:'0px'}}>
                <TableContainer component={Paper}>
                    <ThemeProvider theme={theme}>
                        <Table aria-label="simple table" size='small'>
                            <TableHead>
                                <TableRow  sx={{ bgcolor:'#1976D2'}}>
                                    <TableCell sx={{ color: 'white' }} >
                                        <strong>N°</strong>
                                    </TableCell>
                                    <TableCell align='center' sx={{ color: 'white' }} >
                                        <strong>Monto mínimo (s/.)</strong>
                                    </TableCell>
                                    <TableCell align='center' sx={{ color: 'white' }} >
                                        <strong>Puntos</strong>
                                    </TableCell>
                                    <TableCell align='center' sx={{ color: 'white' , minWidth:'8rem', maxWidth:'12rem'}}><strong>Acciones</strong></TableCell>
                                </TableRow>
                            </TableHead>

                            <TableBody>
                                {
                                    beneficiosMonto && beneficiosMonto.map((item, id) =>(
                                        <TableRow key={id}>
                                            <TableCell component="th" scope="row">
                                                {id+1}
                                            </TableCell>
                                            <TableCell align='center' component="th" scope="row">
                                                {item.monto}
                                            </TableCell>
                                            <TableCell align='center' component="th" scope="row">
                                                {item.cantidad_puntos}
                                            </TableCell>
                                            <TableCell align='center'>
                                                <Button
                                                    onClick={() =>  {
                                                        setOpenPopup(true);
                                                        setPopUp('editBeneficioMonto');
                                                        setSelectedBeneficioMonto(item);
                                                    }}
                                                >
                                                    <PencilIcon/>
                                                </Button>

                                                <Button color="error"
                                                    onClick={() =>  {
                                                        openModalDelete()
                                                        setPopUp('delete');
                                                        setSelectedBeneficioMonto(item);
                                                    }}
                                                >
                                                    <DeleteIcon/>
                                                </Button>
                                            </TableCell>
                                        </TableRow>
                                    ))
                                }
                            </TableBody>
                        </Table>
                    </ThemeProvider>
                </TableContainer>
            </Box>
            {
                popUp === 'editBeneficioMonto' && (
                    <Popup
                        largo="sm"
                        title = 'Editar Beneficio por Monto'
                        openPopup = {openPopup}
                        setOpenPopup = {setOpenPopup}
                    >
                        <AddModalMonto
                            popUp={popUp}
                            closeModal={() => {setOpenPopup(false)}}
                            beneficioMonto={selectedBeneficioMonto}
                            onListBeneficioMonto={onListBeneficioMonto}
                        />

                    </Popup>
                )
            }
            {
                popUp === 'delete' && (
                    <DeleteModalMonto
                    open={showModalDelete}
                    closeModal={closeModalDelete}
                    beneficioMonto={selectedBeneficioMonto}
                    onListBeneficioMonto={onListBeneficioMonto}
                    />
                )
            }

        </Grid>
    );
}
