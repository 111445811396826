import {
   ROUTE_HOME,
   ROUTE_CAJA,
   ROUTE_PEDIDOS,
   ROUTE_REPORTE,
   ROUTE_SALAS,
   ROUTE_PRODUCTOS,
   ROUTE_EMPLEADOS,
   ROUTE_EDITOR,
   ROUTE_CLIENTES,
   ROUTE_BENEFICIOS,
   ROUTE_USUARIOS,
   ROUTE_EMPRESAS,
   ROUTE_PROVEEDORES,
   ROUTE_COMPRAS,
   ROUTE_CUADRATURAS,
   ROUTE_SUCURSALES,
   ROUTE_INVENTARIO,
   ROUTE_LISTA_PRECIO,
   ROUTE_PRODUCTOS_INSUMO,
   ROUTE_MIS_PEDIDOS_CLIENTE,
   ROUTE_MIS_COMPRAS_CLIENTE,
   ROUTE_PEDIDOS_PRODUCTOS,
   ROUTE_CLIENTE_ECOMMERCE,
   ROUTE_PRODUCTOS_ECOMMERCE,
   ROUTE_SHOP_ECOMMERCE,
   ROUTE_COCINAS,
   ROUTE_SHOP_ECOMMERCE_COMPANIES,
   ROUTE_SHOP_RESERVE_TABLE,
   ROUTE_MASTER_CONFIG,
   ROUTE_COMPANY_CONFIG,
} from "../constants/route-map";

export const ROLE_SUPER_ADMIN = 'Superadmin';
export const ROLE_ADMIN = 'Administrador';
export const ROLE_CAJERO = 'Cajero';
export const ROLE_VENDEDOR = 'Mesero';
export const ROLE_USUARIO_CLIENTE = 'Cliente';
export const ROLE_USUARIO_ECOMMERCE = 'UserEcommerce';


export const ROUTES_FOR_SUPER_ADMIN = [
   {
      module: ROUTE_HOME,
      navigators: []
   },
   {
      module: ROUTE_CAJA,
      navigators: []
   },
   {
      module: ROUTE_PEDIDOS,
      navigators: []
   },
   {
      module: ROUTE_REPORTE,
      navigators: []
   },
   {
      module: ROUTE_SALAS,
      navigators: []
   },
   {
      module: ROUTE_PRODUCTOS,
      navigators: []
   },
   {
      module: ROUTE_EMPLEADOS,
      navigators: []
   },
   {
      module: ROUTE_EDITOR,
      navigators: []
   },
   {
      module: ROUTE_CLIENTES,
      navigators: []
   },
   {
      module: ROUTE_BENEFICIOS,
      navigators: []
   },
   {
      module: ROUTE_USUARIOS,
      navigators: []
   },
   {
      module: ROUTE_EMPRESAS,
      navigators: []
   },
   {
      module: ROUTE_CUADRATURAS,
      navigators: []
   },
   {
      module: ROUTE_SUCURSALES,
      navigators: []
   },
   {
      module: ROUTE_PRODUCTOS_INSUMO,
      navigators: []
   },
   {
      module: ROUTE_PEDIDOS_PRODUCTOS,
      navigators: []
   },
   {
      module: ROUTE_CLIENTE_ECOMMERCE,
      navigators: []
   },
   {
      module: ROUTE_PRODUCTOS_ECOMMERCE,
      navigators: []
   },
   {
      module: ROUTE_MASTER_CONFIG,
      navigators: []
   },
   
]
export const ROUTES_FOR_ADMIN = [
   {
      module: ROUTE_HOME,
      navigators: []
   },
   {
      module: ROUTE_CAJA,
      navigators: []
   },
   {
      module: ROUTE_PEDIDOS,
      navigators: []
   },
   {
      module: ROUTE_REPORTE,
      navigators: []
   },
   {
      module: ROUTE_SALAS,
      navigators: []
   },
   {
      module: ROUTE_PRODUCTOS,
      navigators: []
   },
   {
      module: ROUTE_EMPLEADOS,
      navigators: []
   },
   {
      module: ROUTE_EDITOR,
      navigators: []
   },
   {
      module: ROUTE_CLIENTES,
      navigators: []
   },
   {
      module: ROUTE_BENEFICIOS,
      navigators: []
   },
   {
      module: ROUTE_USUARIOS,
      navigators: []
   },
   {
      module: ROUTE_PROVEEDORES,
      navigators: []
   },
   {
      module: ROUTE_COMPRAS,
      navigators: []
   },
   {
      module: ROUTE_CUADRATURAS,
      navigators: []
   },
   {
      module: ROUTE_SUCURSALES,
      navigators: []
   },
   {
      module: ROUTE_INVENTARIO,
      navigators: []
   },
   {
      module: ROUTE_LISTA_PRECIO,
      navigators: []
   },
   {
      module: ROUTE_PRODUCTOS_INSUMO,
      navigators: []
   },
   {
      module: ROUTE_COCINAS,
      navigators: []
   },
   {
      module: ROUTE_PEDIDOS_PRODUCTOS,
      navigators: []
   },
   {
      module: ROUTE_CLIENTE_ECOMMERCE,
      navigators: []
   },
   {
      module: ROUTE_PRODUCTOS_ECOMMERCE,
      navigators: []
   },
   {
      module: ROUTE_COMPANY_CONFIG,
      navigators: []
   },
]
export const ROUTES_FOR_CAJERO = [
   {
      module: ROUTE_CAJA,
      navigators: []
   },
   {
      module: ROUTE_PEDIDOS,
      navigators: []
   },
   {
      module: ROUTE_REPORTE,
      navigators: []
   },
   {
      module: ROUTE_SALAS,
      navigators: []
   },
   {
      module: ROUTE_PRODUCTOS,
      navigators: []
   },
   {
      module: ROUTE_CLIENTES,
      navigators: []
   },
   {
      module: ROUTE_INVENTARIO,
      navigators: []
   },
   {
      module: ROUTE_CUADRATURAS,
      navigators: []
   },
   {
      module: ROUTE_BENEFICIOS,
      navigators: []
   },
   {
      module: ROUTE_COMPRAS,
      navigators: []
   },
   {
      module: ROUTE_PROVEEDORES,
      navigators: []
   },
   {
      module: ROUTE_PEDIDOS_PRODUCTOS,
      navigators: []
   },
]
export const ROUTES_FOR_VENDEDOR = [
   {
      module: ROUTE_HOME,
      navigators: []
   },
   {
      module: ROUTE_PEDIDOS,
      navigators: []
   },
   {
      module: ROUTE_SALAS,
      navigators: []
   },
   {
      module: ROUTE_PRODUCTOS,
      navigators: []
   },
   {
      module: ROUTE_CLIENTES,
      navigators: []
   },
   {
      module: ROUTE_INVENTARIO,
      navigators: []
   },
   {
      module: ROUTE_BENEFICIOS,
      navigators: []
   },
   {
      module: ROUTE_PROVEEDORES,
      navigators: []
   },
   {
      module: ROUTE_PEDIDOS_PRODUCTOS,
      navigators: []
   },
   {
      module: ROUTE_CAJA,
      navigators: []
   },
   {
      module: ROUTE_CUADRATURAS,
      navigators: []
   },
]
export const ROUTES_FOR_USER_CLIENTE = [
   {
      module: ROUTE_SHOP_ECOMMERCE,
      navigators: []
   },
   {
      module: ROUTE_SHOP_ECOMMERCE_COMPANIES,
      navigators: []
   },
   {
      module: ROUTE_SHOP_RESERVE_TABLE,
      navigators: []
   },
   {
      module: ROUTE_HOME,
      navigators: []
   },
   {
      module: ROUTE_MIS_PEDIDOS_CLIENTE,
      navigators: []
   },
   {
      module: ROUTE_MIS_COMPRAS_CLIENTE,
      navigators: []
   },
   {
      module: ROUTE_CLIENTE_ECOMMERCE,
      navigators: []
   },
   {
      module: ROUTE_PRODUCTOS_ECOMMERCE,
      navigators: []
   },
]

export const MAIN_REDIRECT_FOR_SUPER_ADMIN = ROUTE_HOME;
export const MAIN_REDIRECT_FOR_ADMIN       = ROUTE_HOME;

