import React, { useEffect } from "react";
import { Props } from "./PedidosProductos.type";
import { Protected } from "@/components/layout/Protected";
import { Loading } from "@/components/common/Loading";
import { Button, CardActionArea, Container, Divider, Grid, MenuItem, Select } from "@mui/material";
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import { productoService } from "@/service/services/Producto.service";
import ContentPasteOffIcon from '@mui/icons-material/ContentPasteOff';
import "./PedidosProductos.css";
import { cocinaService } from "@/service/services/Cocina.service";
import ReactPaginate from 'react-paginate';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import EventAvailableIcon from '@mui/icons-material/EventAvailable';
import ReactCardFlip from 'react-card-flip';
import { STATUS_PENDIENTE, STATUS_POR_ENTREGAR } from "@/toolbox/constants/status-ticket";
import { ticketService } from "@/service/services/Ticket.service";
import moment from "moment";
import { Snackbar, Alert } from '@mui/material';
import ArrowCircleLeftIcon from '@mui/icons-material/ArrowCircleLeft';

import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import { styled } from '@mui/material/styles';
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import MuiAccordion, { AccordionProps } from '@mui/material/Accordion';
import MuiAccordionSummary, {
    AccordionSummaryProps,
} from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import { useHistory } from "react-router-dom";

const Accordion = styled((props: AccordionProps) => (
    <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
    border: `1px solid ${theme.palette.divider}`,
    '&:not(:last-child)': {
        borderBottom: 0,
    },
    '&::before': {
        display: 'none',
    },
}));

const AccordionSummary = styled((props: AccordionSummaryProps) => (
    <MuiAccordionSummary
        expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: '0.9rem' }} />}
        {...props}
    />
))(({ theme }) => ({
    backgroundColor:
        theme.palette.mode === 'dark'
            ? 'rgba(255, 255, 255, .05)'
            : 'rgba(0, 0, 0, .03)',
    flexDirection: 'row-reverse',
    '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
        transform: 'rotate(90deg)',
    },
    '& .MuiAccordionSummary-content': {
        marginLeft: theme.spacing(1),
    },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
    padding: theme.spacing(2),
    borderTop: '1px solid rgba(0, 0, 0, .125)',
}));

const today = new Date();
const year = today.getFullYear();
const month = String(today.getMonth() + 1).padStart(2, '0');
const day = String(today.getDate()).padStart(2, '0');
const formattedDate = `${year}-${month}-${day}`;

export const PedidosProductos: React.FC<Props> = (props: Props): JSX.Element => {

    const history = useHistory()

    const [loading, setLoading] = React.useState<boolean>(false);
    const [productos, setProductos] = React.useState([]);
    const [cocinas, setCocinas] = React.useState([]);
    const [cocinaSelected, setCocinaSelected] = React.useState<any>('cocina general');

    const [isFlipped, setIsFlipped] = React.useState('');
    const [pendingOrders, setPendingOrders] = React.useState<Array<any>>([]);
    const date = new Date();
    const formatFecha = moment(date).format('DD-MM-YYYY');

    const [snackBarConfig, setSnackBarConfig] = React.useState<any>({
        open: false,
        severity: 'error',
        message: 'Error',
        autoHideDuration: 3000,
    });

    const [expanded, setExpanded] = React.useState<string | false>('panel1');

    const handleChange =
        (panel: string) => (event: React.SyntheticEvent, newExpanded: boolean) => {
            setExpanded(newExpanded ? panel : false);
        };

    React.useEffect(() => {
        getProductos();
        getCocinasAvailable();
        getPendingOrders();
    }, []);

    const getProductos = async () => {
        setLoading(true);
        const payload = { date: formattedDate }
        await productoService.getPedidosProductos(payload).then((data) => {
            setProductos(data);
            setLoading(false);
        })
    }

    const getPedidosProductosByCocina = async (idCocina) => {
        setLoading(true);
        await productoService.getPedidosProductosByCocina(idCocina).then((data) => {
            setProductos(data);
            setLoading(false);
        })
    }

    const getCocinasAvailable = async () => {
        setLoading(true);
        await cocinaService.getCocinasAvailable().then((data) => {
            if (data.status === 200) {
                setCocinas(data.cocinas);
            } else {
                setCocinas([]);
            }
            setLoading(false);
        })
    }

    const getPendingOrders = async () => {
        await ticketService.getTicketsFecha(STATUS_PENDIENTE, formatFecha).then((data) => {
            setPendingOrders(data.ticket);
        });
    }

    const deliverOrder = async (idTicket, estado, idPedido) => {
        if (estado === STATUS_PENDIENTE) {
            setLoading(true);
            await ticketService.editPedidoStatus(idTicket, STATUS_POR_ENTREGAR, idPedido).then((data) => {
                if (data.message == 'Ticket updated Successfully') {
                    if (data.status == 200 && data.message == 'Ticket updated Successfully') {
                        getProductos();
                        getPendingOrders();
                        setSnackBarConfig(prev => ({
                            ...prev,
                            open: true,
                            severity: 'success',
                            message: `Estado del ticket cambiado satisfactoriamente`,
                        }));
                    } else {
                        setSnackBarConfig(prev => ({
                            ...prev,
                            open: true,
                            message: `ocurrió algun problema`,
                        }));
                    }
                }
            });
            setLoading(false);
        }
    }

    const handleClick = (idpedido: string) => {
        // e.preventDefault();
        // setIsFlipped(e.target.value);
        setIsFlipped(idpedido)
    }

    const goBack = () => {
        history.push('/pedidos');
    };

    const settings = {
        dots: true,
        infinite: false,
        speed: 500,
        arrows: true,
        slidesToShow: 3.5,
        slidesToScroll: 3.5,
        initialSlide: 0,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 3,
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
        ],
        centerMode: false,
        className: 'slider-container',
    };

    return (
        <Protected>
            {loading && <Loading />}

            <Snackbar
                open={snackBarConfig.open}
                autoHideDuration={snackBarConfig.autoHideDuration}
                onClose={() => setSnackBarConfig(prev => ({ ...prev, open: false }))}
                anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
            >
                <Alert
                    onClose={() => setSnackBarConfig(prev => ({ ...prev, open: false }))}
                    severity={snackBarConfig.severity}
                    variant="filled"
                >
                    {snackBarConfig.message}
                </Alert>
            </Snackbar>

            <Container maxWidth='xl'>
                <Grid className="btn-back-container" onClick={goBack} sx={{ cursor: 'pointer', border: '1px solid #188dcd', borderRadius: '4px', padding: '8px', display: 'flex', alignItems: 'center', width: 'fit-content' }}>
                    <ArrowCircleLeftIcon fontSize='small' sx={{ color: '#188dcd', marginRight: '2px' }} />
                    <Typography className='btn-back-text'>Volver</Typography>
                </Grid>
                <Box sx={{ marginBottom: '15px', display: 'flex', alignItems: 'center' }}>
                    <Typography variant="subtitle1">
                        Listar Productos por cocina:
                    </Typography>
                    <Select
                        id="filtro"
                        size='small'
                        value={cocinaSelected}
                        onChange={(event) => {
                            setCocinaSelected(event.target.value);
                            // Lógica para obtener productos según la cocina seleccionada
                        }}
                        sx={{ width: '250px', marginLeft: '5px' }}
                    >
                        <MenuItem value={''} disabled><em>Seleccionar filtro</em></MenuItem>
                        {cocinas.map((cocina, key) => (
                            <MenuItem key={key} value={cocina.name}>
                                {cocina.name}
                            </MenuItem>
                        ))}
                        <MenuItem value={'cocina general'}>
                            Cocina general
                        </MenuItem>
                    </Select>
                </Box>

                <Divider sx={{ my: 2 }} />

                {/* <Slider {...settings}>
                    {productos.map((producto, i) => (
                        <div key={i} style={{ marginRight: '10px' }}>
                            <Card sx={{ margin: '5px' }}>
                                <CardContent style={{ display: 'flex', alignItems: 'center' }}>
                                    <div style={{ flex: 1 }}>
                                        <Typography variant="subtitle1" component="div">
                                            <b>{producto.nombre}</b>
                                        </Typography>
                                        <Typography color="text.secondary" component="div">
                                            {`${producto.cantidad} por preparar`}
                                        </Typography>
                                    </div>
                                    <CardMedia
                                        component="img"
                                        sx={{ maxWidth: 90, minWidth: 90 }}
                                        image={"https://images.rappi.cl/products/2090193507-1547158222.png?e=webp&d=200x200&q=70"}
                                        alt="img"
                                        style={{ border: "1px solid #b9b8b899" }}
                                    />
                                </CardContent>
                            </Card>
                        </div>
                    ))}
                </Slider> */}

                {/* {!loading && productos.length === 0 && (
                    <Box sx={{ backgroundColor: '#FFF', padding: '15px', borderRadius: '10px', height: '370px' }}>
                        <Grid container justifyContent="center" marginTop="80px">
                            <ContentPasteOffIcon className="iconData" />
                        </Grid>
                    </Box>
                )} */}

                <Grid container spacing={2} style={{ marginTop: '4px' }} sx={{ minHeight: '50vh', maxHeight: '50vh', overflowY: 'scroll' }}>
                    {pendingOrders.map((item) => (
                        <Grid key={item.id} item xs={12} md={4}>
                            <Card className='card_reservation'>
                                <CardContent>
                                    <div >
                                        <Grid item >
                                            <Typography className='card_reservation--club' gutterBottom variant="subtitle1" component="div">
                                                Ticket N°: {item?.n_ticket}
                                            </Typography>
                                        </Grid>

                                        <Divider sx={{ borderTop: '3px solid black' }} />

                                        <Grid item mt={2} mb={2}>
                                            <Typography className='card_reservation--club' gutterBottom variant="subtitle1" component="div">
                                                <EventAvailableIcon className='card_reservation--club--icon' />Lista de productos
                                            </Typography>

                                            <Grid mt={1} item display='flex' justifyContent='space-between'>
                                                <Typography gutterBottom variant="subtitle1" className='card_reservation--precio'>
                                                    Productos
                                                </Typography>
                                                <Typography gutterBottom variant="subtitle1" className='card_reservation--precio'
                                                >
                                                    Cantidad
                                                </Typography>
                                            </Grid>
                                            <Divider sx={{ borderTop: '3px solid black' }} />
                                            {
                                                (item.detalle_ticket).length > 0 && (item.detalle_ticket).map((item, index) => (
                                                    <>
                                                        <Accordion expanded={expanded === item.productos.id} onChange={handleChange(item.productos.id)}>
                                                            <AccordionSummary aria-controls="panel1d-content" id="panel1d-header">
                                                                <Grid container justifyContent="space-between">
                                                                    <Typography>{item.productos?.nombre}</Typography>
                                                                    <Typography>{item.cantidad}</Typography>
                                                                </Grid>
                                                            </AccordionSummary>
                                                            {
                                                                item.productos.receta.length > 0 && (
                                                                    <AccordionDetails>
                                                                        {item.productos.receta.map(insumo =>
                                                                            <Grid container justifyContent="space-between" alignItems="center" onClick={() => console.log('insumo', insumo)}>
                                                                                <Typography>{insumo.insumo?.nombre}</Typography>
                                                                                <Typography>
                                                                                    {insumo.cantidad} {insumo.insumo?.unidad_medida?.abbreviation}
                                                                                </Typography>
                                                                            </Grid>
                                                                        )}
                                                                    </AccordionDetails>

                                                                )
                                                            }
                                                        </Accordion>
                                                    </>
                                                ))
                                            }
                                        </Grid>

                                        <Divider sx={{ borderTop: '3px solid black' }} />
                                        <Grid mt={3} item container display='flex' justifyContent='center'>
                                            <Grid>
                                                <Button variant='outlined' className='card_reservation--change' onClick={() => { deliverOrder(item?.id || 0, item?.state, item?.pedidoDetail?.id) }}>Entregar</Button>
                                            </Grid>
                                        </Grid>
                                    </div>
                                </CardContent>
                            </Card>
                        </Grid>
                    ))}
                </Grid>

            </Container>

        </Protected >
    );
}
