import { ClientesRepository } from "../repositories/Clientes.repository"

export const clientesService = {
    getClientes,
    editState,
    register,
    update,
    diminishPoints,
    getNewClientMes,
    getNewClientDay,
    getNewClientFechas,
    getClienteByRut,
    validateClientesSofnet,
    shoppingHistory,
    downloadTemplateImport,
    importClientes
}

async function getClientes() {
    const res = await ClientesRepository.getClientes();
    return res;
}

async function validateClientesSofnet() {
    const res = await ClientesRepository.validateClientesSofnet();
    return res;
}

async function getClienteByRut(rut: any) {
    const res = await ClientesRepository.getClienteByRut(rut);
    return res;
}

async function editState(idCliente: number, state: any) {
    const res = await ClientesRepository.editState(idCliente, state);
    return res;
}

async function register(data: {}) {
    const res = await ClientesRepository.register(data);
    return res;
}

async function update(data: {}, idCliente: number) {
    const res = await ClientesRepository.update(data, idCliente);
    return res;
}

async function diminishPoints(idCliente: number, points: number) {
    const res = await ClientesRepository.diminishPoints(idCliente, points);
    return res;
}

async function getNewClientMes(fecha: any) {
    const res = await ClientesRepository.getNewClientMes(fecha);
    return res;
}

async function getNewClientDay(fecha: any) {
    const res = await ClientesRepository.getNewClientDay(fecha);
    return res;
}

async function getNewClientFechas(fecha_de: any, fecha_a: any) {
    const res = await ClientesRepository.getNewClientFechas(fecha_de, fecha_a);
    return res;
}

//view ecommerce: historial de compras
async function shoppingHistory() {
    const res = await ClientesRepository.shoppingHistory();
    return res;
}

async function downloadTemplateImport() {
    const res = await ClientesRepository.downloadTemplateImport();
    return res;
}

async function importClientes(data: FormData) {
    const res = await ClientesRepository.import(data);
    return res;
}