import React, {  useEffect, useMemo, useRef, useState } from "react";
import { Button, Grid, useTheme ,Snackbar, Alert } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import { VALIDATORS } from '@toolbox/helpers/validation-rules';
import { Input } from '@components/common/Input';
import { InputRef } from '@components/common/Input/InputInterfaces';
import Paper from '@mui/material/Paper';
import {  ThemeProvider } from '@mui/material/styles';
import { authenticationService } from '@service/services/Authentication.service';
import * as Routes from '@constants/route-map';
import { Link, useLocation } from "react-router-dom"
import "./QRView.sass";
import { ticketService } from "@/service/services/Ticket.service";
import { saveLocalStorage } from "@/toolbox/helpers/local-storage-helper";
import { KEY_EDIT_PEDIDO } from "@/toolbox/constants/local-storage";

export const QRView: React.FC<any> = (props): JSX.Element => {
   const {search} = useLocation();
   const inputRefs = useRef<Array<InputRef | null>>([]);
   const [loading, setLoading] = useState<boolean>(false);
   const [data, setData] = useState({
      name: '',
      rut: '',
      user: '',
      password: '',
      textError: '',
   });

   let query = new URLSearchParams(search);
   let keyMesaClient: any = query.get("key");

   const [snackBarConfig, setSnackBarConfig] = useState<any>({
      open: false,
      severity: 'error',
      message: 'Error',
      autoHideDuration: 3000,
   })

   const rules = useMemo(() => ({
      name: [
         VALIDATORS.REQUIRED
      ],
      rut: [
         VALIDATORS.REQUIRED,
         VALIDATORS.ONLY_LOWERCASE,
      ],
      user: [
         VALIDATORS.REQUIRED
      ],
      password: [
         VALIDATORS.REQUIRED
      ],
   }), []);

   const validate = () => {
      const refs = inputRefs.current;
      const valid = refs.reduce((prev, ref) => {
         const isValid = ref ? ref.validate(true) : false
         return prev && isValid;
      }, true);

      return valid;
   }

   const handleSubmit = (e) => {
      e.preventDefault();
      const { name, rut, user, password } = data;
      try {
         if (validate()) {
            authSubmit( name,rut, user, password);
         }
      } catch (error) {
         setData(prev => ({ ...prev, textError: 'Lo sentimos, ocurrió un error inesperado.' }));
      } finally {
        // setLoading(false);
      }
   }


   const authSubmit = async (name, rut, user, password) => {
      setLoading(true);

      const response = await authenticationService.loginQR(keyMesaClient, name, rut, user, password);

      if (response.token === '') {
         window.location.replace("/login");
         setLoading(false);
      } else {
         const searchPedido = await ticketService.getPedidoDetalleByMesa(keyMesaClient);

         if(searchPedido && searchPedido.status === 200){
            saveLocalStorage(KEY_EDIT_PEDIDO, searchPedido);
            setLoading(false);
         }

         if (response?.user?.main_redirect === Routes.ROUTE_HOME) {
            window.location.reload();
         } else {
            window.location.replace("/home-cliente");
         }
      }

      localStorage.setItem('keysClient', JSON.stringify({
         keyCliente: response?.cliente_id,
         keyMesa: keyMesaClient
     }));
   }

   const theme = useTheme();

   const handleInput = (event: React.ChangeEvent<HTMLInputElement>) => {
      const name = event.target.name;
      const value = event.target.value;

      const formatsRut = {
         rutChile: /^[0-9]{8,9}[-|‐]{1}[0-9kK]{1}$/,
         rutPeru: /^\d{11}$/,
         rutColombia: /^[0-9]{9}[-|‐]{1}[0-9]{1}$/,
      }

      switch (name) {
         case 'name':
            setData(prev => ({ ...prev, name: value, textError: '' }));
            break;
         case 'rut':
            var Fn = {
               // Valida el rut con su cadena completa "XXXXXXXX-X"
               validaRut : function (rutCompleto) {
                  if (!formatsRut.rutChile.test( rutCompleto )) return false;
                  var tmp 	= rutCompleto.split('-');
                  var digv	= tmp[1];
                  var rut 	= tmp[0];
                  if ( digv === 'K' ) digv = 'k' ;
                  return (Fn.dv(rut) === digv );
               },
               dv : function(T){
                  var M=0,S=1;
                  for(;T;T=Math.floor(T/10))
                     S=(S+T%10*(9-M++%6))%11;
                  return S?S-1:'k';
               }
            }
            var foo =value.split("-").join("")
            if(foo.length >0 && foo.length<10){
               foo= foo.match(new RegExp('.{1,8}', 'g')).join("-");
               setData(prev => ({ ...prev, rut: foo, textError: '' }))
            }else if(foo.length === 0){
               setData(prev => ({ ...prev, rut: '', textError: '' }))
            }
            break;
         case 'user':
            setData(prev => ({ ...prev, user: value, textError: '' }));
            break;
         case 'password':
            setData(prev => ({ ...prev, password: value, textError: '' }));
            break;
         default:
            break;
      }
   };

   const formulario=(
      <form >
        <div style={{padding: "20px"}}>
          <Grid container spacing={2} >
              <Grid item xs={12}>
                <h1 className ="textTittle">Forma parte de nuestra familia</h1>
                <Input
                    ref={ref => inputRefs.current[0] = ref}
                    name="name" placeholder="Ingrese su nombre completo"
                    value={data.name} backgroundColor="#ffffff"
                    rules={rules.name} disableElevation validateOnBlur
                    dense onChange={handleInput}
                />
              </Grid>
              <Grid item xs={12}>
                <Input
                    ref={ref => inputRefs.current[1] = ref}
                    name="rut" type="text"
                    placeholder="Ingrese su RUT" value={data.rut}
                    backgroundColor="#ffffff" rules={rules.rut}
                    disableElevation validateOnBlur
                    dense onChange={handleInput}
                />
              </Grid>
              <Grid item xs={12}>
                <Input
                    ref={ref => inputRefs.current[2] = ref}
                    name="user" type="text"
                    placeholder="Ingrese su nombre de usuario" value={data.user}
                    backgroundColor="#ffffff" rules={rules.user}
                    disableElevation validateOnBlur
                    dense onChange={handleInput}
                />
              </Grid>
              <Grid item xs={12}>
                <Input
                    ref={ref => inputRefs.current[2] = ref}
                    name="password" type="password"
                    placeholder="Ingrese su contraseña" value={data.password}
                    backgroundColor="#ffffff" rules={rules.password}
                    disableElevation validateOnBlur
                    dense onChange={handleInput}
                />
              </Grid>
              <Grid item xs={12} container spacing={1}>
                <Grid item xs={6} >
                  <Button
                      className="buttonLogin"
                      style={{ backgroundColor: "#0c879c", fontFamily: "Lato" }}
                      fullWidth={true}
                      variant='contained' onClick={()=>authSubmit("","","","")}
                      disabled={loading} disableElevation
                  >
                      {
                          loading ?
                          <CircularProgress size={24}  style={{color: "#FFFF"}}/> :
                          <span style={{textTransform: "capitalize", fontSize: "14px"}}>Omitir</span>
                      }
                  </Button>
                </Grid>
                <Grid item xs={6} >
                  <Button
                      className="buttonLogin"
                      style={{ backgroundColor: "#0c879c", fontFamily: "Lato" }}
                      fullWidth={true} type='submit'
                      variant='contained' onClick={handleSubmit}
                      disabled={loading} disableElevation
                  >
                      {
                          loading ?
                          <CircularProgress size={24}  style={{color: "#FFFF"}}/> :
                          <span style={{textTransform: "capitalize", fontSize: "14px"}}>Continuar</span>
                      }
                  </Button>
                </Grid>
              </Grid>
              <Grid item xs={12} sx={{textAlign: 'center'}}>
                 <Link to={`login-cliente?key=${keyMesaClient}`} style={{textDecoration: 'none', color: '#176ECA'}}>
                  <span> ¿ ya tienes una cuenta ? </span>
                 </Link>
              </Grid>
          </Grid>
        </div>
   </form>
   )

   useEffect(()=>{
   //  let urlMesa = window.location.search;
    let query = new URLSearchParams(search)
    let keyMesaClient: any = query.get("key");
    if (keyMesaClient) {
      authenticationService.end();
    } else {
      window.location.replace("/login");
    }
    // eslint-disable-next-line
   }, [])
   return (
      <>
      <ThemeProvider theme={theme}>
        <Snackbar
              open={snackBarConfig.open}
              autoHideDuration={snackBarConfig.autoHideDuration}
              onClose={() => setSnackBarConfig(prev => ({ ...prev, open: false }))}
              anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
          >
              <Alert
                onClose={() => setSnackBarConfig(prev => ({ ...prev, open: false }))}
                severity={snackBarConfig.severity}
                variant="filled"
              >
                {snackBarConfig.message}
              </Alert>
        </Snackbar>
        <Grid container component="main" className="App-login" sx={{ height: '100vh' }}>
          <Grid container className="all-heigth" style={{ height: "100%",textAlign: "left", justifyContent: "center", alignItems: "center" }}>
            <Grid container item xs={12} md={3} style={{ textAlign: "left", justifyContent: "center", alignItems: "center" }}>
              <Paper elevation={3} >
                  {formulario}
              </Paper>
            </Grid>
          </Grid>
        </Grid>
      </ThemeProvider>
      </>
   )
}

