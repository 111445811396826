
import React, { useState, useEffect } from "react";
import { Protected } from "@/components/layout/Protected";
import { Box, Button, Card, CardActions, CardContent, Container, Divider, Grid, InputAdornment, TablePagination, TextField, Tooltip, Typography } from "@mui/material";
import { Popup } from '../Editor/components/Popup/Popup';
import { Paper, TableBody, TableCell, TableContainer, TableHead, TableRow, Table, createTheme, ThemeProvider } from '@mui/material';
import { esES } from '@mui/material/locale';
import DeleteIcon from '@mui/icons-material/Delete';
import { PencilIcon } from "@toolbox/constants/icons";
import EditIcon from '@mui/icons-material/Edit';
import { salas } from '../../service/services/Salas.service';
import { Loading } from '../../components/common/Loading/Loading';
import TableRestaurantIcon from '@mui/icons-material/TableRestaurant';
import ContentPasteOffIcon from '@mui/icons-material/ContentPasteOff';
import AssignmentIcon from '@mui/icons-material/Assignment';
import { ModalReservas } from "./../Reservas/components/ModalReservas";
// import LocalPrintshopIcon from '@mui/icons-material/LocalPrintshop';
import { Snackbar, Alert } from '@mui/material';
import QrCodeIcon from '@mui/icons-material/QrCode';
import SettingsIcon from '@mui/icons-material/Settings';
import { AddSala } from "../Salas/components/AddSala";
import { AddMesa } from "../Salas/components/AddMesa";
import { ConfigMesa } from "../Salas/components/ConfigMesa";
import { CodigoQR } from "../Salas/components/CodigoQR";
import { cajas } from "@/service/services/Cajas.service";
import { AddCaja } from "./AddCaja";
import { sucursalesService } from "@/service/services/Sucursales.service";
import SearchIcon from "@mui/icons-material/Search";

export const CajasMantenedor: React.FC<any> = (props: any): JSX.Element => {

    const [popUp, setPopUp] = React.useState<String>('');
    const [openPopup, setOpenPopup] = React.useState(false);
    const [allCajas, setCajas] = React.useState([]);
    const [cajasBackUp, setCajasBackUp] = useState<any[]>([])
    const [sucursales, setSucursales] = React.useState([]);
    const [loadData, setLoadData] = React.useState<boolean>(false);
    const [selectCaja, setSelectCaja] = React.useState<any>('');
    const [textSearch, setTextSearch] = useState<string>("");
    const [filter, setFilter] = useState<Array<any>>([]);
    // eslint-disable-next-line
    const [dataModalSala, setDataModalCaja] = useState<any>(false);

    const [snackBarConfig, setSnackBarConfig] = React.useState<any>({
        open: false,
        severity: 'error',
        message: 'Error',
        autoHideDuration: 3000,
    });
    const [rowsPerPage, setRowsPerPage] = useState(20);
    const [page, setPage] = useState(0);

    React.useEffect(() => {
        async function fetch() {
            setLoadData(true);
            await cajas.cargarCajas().then((data) => {
                if(data.status == 200){
                    setCajas(data.cajas);
                    setCajasBackUp(data.cajas)
                   
                }
                setLoadData(false);
            });
        }
        fetch();
        allSucursales();
    },// eslint-disable-next-line
        []);


    const theme = createTheme(
        {
            palette: {
                primary: { main: '#1976D2' },
            },
        },
        esES,
    );

    const deleteCaja = async (id) => {
        setLoadData(true);
        await cajas.deleteCaja(id);
        cargarCajas();
        setLoadData(false);
    }

    const cargarCajas = async () => {
        setLoadData(true);
        await cajas.cargarCajas().then((data) => {
            setCajas(data.cajas || []);
            setLoadData(false);
        });
    }

    const allSucursales = async () => {
        setLoadData(true);
        await sucursalesService.listAllSucursales().then((data) => {
            setSucursales(data)
        })
    }

    const onSearch = (search: string) => {
        const searchLowerCase = search.trim().toLowerCase()
        if (search !== '') {
            const filtered = cajasBackUp.filter(caja => {
                const nombre = caja.nombre && caja.nombre.toLowerCase()
                const sucursal_name = caja.sucursal_name && caja.sucursal_name.toLowerCase()
                return (
                    (nombre && nombre.includes(searchLowerCase)) ||
                    (sucursal_name && sucursal_name.includes(searchLowerCase))
                )
            })
            setCajas(filtered)
        } else {
            setCajas(cajasBackUp)
        }
    }


    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };


    return (
        <Protected>
            {loadData && <Loading />}
            <Snackbar
                open={snackBarConfig.open}
                autoHideDuration={snackBarConfig.autoHideDuration}
                onClose={() => setSnackBarConfig(prev => ({ ...prev, open: false }))}
                anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
            >
                <Alert
                    onClose={() => setSnackBarConfig(prev => ({ ...prev, open: false }))}
                    severity={snackBarConfig.severity}
                    variant="filled"
                >
                    {snackBarConfig.message}
                </Alert>
            </Snackbar>
            <Grid container spacing={2}>
                <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'center' }}>
                    <Typography variant="h4">Mantenedor de cajas</Typography>
                </Grid>
                <Grid item container xs={12}
                    sx={{
                        alignItems: "center",
                        display: "flex",
                        justifyContent: "space-between",
                        flexWrap: "wrap",
                        marginBottom: "10px",
                    }}
                >
                    <Grid item>
                        <TextField
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <SearchIcon fontSize="small" />
                                    </InputAdornment>
                                ),
                            }}
                            placeholder="Buscar..."
                            size="small"
                            onChange={(e) => onSearch(e.target.value)}
                        />
                    </Grid>
                    <Grid item sx={{ textAlign: "right" }}>
                        <Button variant='outlined' style={{ textTransform: "capitalize" }}
                            onClick={() => {
                                setOpenPopup(true);
                                setDataModalCaja(false);
                                setPopUp('add');
                            }}
                        >Nueva Caja</Button>
                    </Grid>
                </Grid>
                <Grid item xs={12}>
                    <Box sx={{ Width: '100%' }}>
                        <TableContainer component={Paper}>
                            <ThemeProvider theme={theme}>
                                <Table aria-label="simple table" size='small'>
                                    <TableHead >
                                        <TableRow sx={{ bgcolor: '#1976D2' }}>
                                            <TableCell sx={{ color: 'white' }} ><strong>N°</strong></TableCell>
                                            <TableCell sx={{ color: 'white' }} ><strong>Nombre Caja</strong></TableCell>
                                            <TableCell align='center' sx={{ color: 'white' }} ><strong>Sucursal</strong></TableCell>
                                            <TableCell align='center' sx={{ color: 'white', minWidth: '8rem', maxWidth: '12rem' }}><strong>Acciones</strong></TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {!!allCajas.length
                                            ?
                                            allCajas?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((item, index) => (
                                                <TableRow key={item.id}>
                                                    <TableCell component="th" scope="row">
                                                        {index + 1}
                                                    </TableCell>
                                                    <TableCell component="th" scope="row">
                                                        {item.nombre.charAt(0).toUpperCase() + item.nombre.slice(1).toLowerCase()}
                                                    </TableCell>
                                                    <TableCell align='center' component="th" scope="row">
                                                        {item.sucursal_name}
                                                    </TableCell>
                                                    <TableCell align='center'>
                                                        <Button color="error"
                                                            onClick={() => { deleteCaja(item.id) }}
                                                        >
                                                            <DeleteIcon />
                                                        </Button>
                                                        <Button
                                                            onClick={() => {
                                                                setOpenPopup(true);
                                                                setPopUp('edit');
                                                                setSelectCaja(item);
                                                            }}
                                                        >
                                                            <PencilIcon />
                                                        </Button>
                                                    </TableCell>
                                                </TableRow>
                                            ))
                                            :
                                            <TableRow>
                                                <TableCell align="center" colSpan={4}>Sin resultados</TableCell>
                                            </TableRow>
                                        }
                                    </TableBody>
                                </Table>
                            </ThemeProvider>
                        </TableContainer>
                        <TablePagination
                            rowsPerPageOptions={[20, 30, 40]}
                            component="div"
                            count={allCajas?.length || 0}
                            rowsPerPage={rowsPerPage}
                            page={page}
                            onPageChange={handleChangePage}
                            onRowsPerPageChange={handleChangeRowsPerPage}
                        />
                    </Box>
                </Grid>
            </Grid>

            {popUp === 'add' &&
                <Popup
                    largo="sm"
                    title='Crear Caja'
                    openPopup={openPopup}
                    setOpenPopup={setOpenPopup}
                >
                    <AddCaja
                        closeModal={() => setOpenPopup(false)}
                        onListCaja={cargarCajas}
                        sucursales={sucursales}
                    />
                </Popup>
            }

            {popUp === 'edit' &&
                <Popup
                    largo="sm"
                    title='Editar Caja'
                    openPopup={openPopup}
                    setOpenPopup={setOpenPopup}
                >
                    <AddCaja
                        caja={selectCaja}
                        closeModal={() => setOpenPopup(false)}
                        onListCaja={cargarCajas}
                        sucursales={sucursales}
                    />
                </Popup>
            }
        </Protected>
    )

}
