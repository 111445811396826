import { API_URL_NUPY } from '@/toolbox/defaults/app';
import axios from 'axios';

export const NotaVentaRepository = {
    getNotasVenta: async(): Promise<any> => {
        const res = await axios.get(`${API_URL_NUPY}/notaVenta/list`);
        return res.data;
    },

    register: async (data:{}): Promise<any> => {
        const res = await axios.post(`${API_URL_NUPY}/notaVenta/register`, data);
        return res;
    },
}