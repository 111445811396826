import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import { Loading } from '@/components/common/Loading/Loading';
import DialogTitle from '@mui/material/DialogTitle';
import { Divider } from '@mui/material';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import { BeneficioPuntosService } from '@/service/services/BeneficioPuntos.service';

export const PuntosModalDelete: React.FC<any> = (props:any): JSX.Element => {
    const { open, closeModal, beneficio, onListBeneficios } = props;

    const [loading, setLoading] = React.useState<boolean>(false);

    const onDelete = async() => {
        setLoading(true);
        await BeneficioPuntosService.deleteBeneficioPuntos(beneficio.id).then((data) => {
            if(data.status === 200){
                onListBeneficios && onListBeneficios();
                closeModal && closeModal();
                setLoading(false);
            }
        })
    }

    return (
        <div>
            {loading && <Loading/>}
            <Dialog
                open={open}
                onClose={closeModal}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                {"Eliminar Beneficio Monto"}
                </DialogTitle>
                <Divider/>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                    ¿Está seguro de eliminar el Cupón actual?
                    </DialogContentText>
                </DialogContent>
                <Divider/>
                <DialogActions>
                <Button onClick={closeModal}>Cancelar</Button>
                <Button onClick={onDelete} autoFocus>
                    Aceptar
                </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}