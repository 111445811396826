import React, { FunctionComponent } from 'react';
import { Protected } from '@/components/layout/Protected';
import { GenerarVenta } from './GenerarVenta';

export const Caja: FunctionComponent = (props: any) => {

   return(
      <Protected>
         {/* <Grid item>
            <Button variant='outlined'
            component={Link} to={ROUTE_CUADRATURAS}
            >Ver Cuadraturas</Button>
         </Grid>

         <Divider sx={{ mt: 2, mb:2 }} /> */}

         <GenerarVenta/>
      </Protected>

   )
}

