import { Protected } from "@/components/layout/Protected";
import { DatePicker, LocalizationProvider, TabContext, TabList, TabPanel } from "@mui/lab";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import { Button, Grid, IconButton, Paper, Tab, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Tooltip, Typography } from "@mui/material";
import { FC, useEffect, useRef, useState } from "react";
import esLocale from 'date-fns/locale/es';
import { Loading } from "@/components/common/Loading";
import { cuadraturaService } from "@/service/services/Cuadratura.service";
import VisibilityIcon from '@mui/icons-material/Visibility';
import { Popup } from "@/views/Editor/components/Popup";
import { styled } from '@mui/material/styles';
import { moneyFormat, moneyFormatInt } from "@/toolbox/helpers/money.helper";
import { DetalleDescuadre } from "@/views/Reporte/componentes/DetalleDescuadre/DetalleDescuadre";
import { ComponentPrint } from "@/components/common/ComponentPrint";
import { readLocalStorage } from "@/toolbox/helpers/local-storage-helper";
import { KEY_USER_DATA } from "@/toolbox/constants/local-storage";
import { useReactToPrint } from 'react-to-print';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';

const StyledTab = styled(Tab)(({ theme }) => ({
    textTransform: 'none',
}));

export const CierreDiario: FC = () => {

    const [fecha, setFecha] = useState(new Date())
    const [cierres, setCierres] = useState<any[]>([])
    const [ventas, setVentas] = useState<any[]>([])
    const [ajustes, setAjustes] = useState<any[]>([])
    const [detalleDescuadre, setDetalleDescuadre] = useState<any[]>([])
    const [loading, setLoading] = useState<boolean>(false)
    const [showModalDetail, setShowModalDetail] = useState<boolean>(false)
    const [indexTab, setIndexTab] = useState<string>('1');
    const [dataImprimir, setDataImprimir] = useState(null);
    const componentRef = useRef();

    const dataUsuario = readLocalStorage(KEY_USER_DATA);

    const nombreEmpresa = dataUsuario.user?.nombre_empresa;
    const rutEmpresa = dataUsuario.user?.rut_empresa

    useEffect(() => {
        const fechalocal = new Date(fecha)
        const año = fechalocal.getFullYear();
        const mes = String(fechalocal.getMonth() + 1).padStart(2, '0')
        const dia = String(fechalocal.getDate()).padStart(2, '0')

        const fechaFormateada = `${año}-${mes}-${dia}`
        getCierresDiarioApi(fechaFormateada);
    }, [fecha])

    const handlePrint = useReactToPrint({
        content: () => componentRef.current,

    });

    useEffect(() => {
        if (dataImprimir !== null) {
            handlePrint();
        }
        // eslint-disable-next-line
    }, [dataImprimir])

    const getCierresDiarioApi = async (fecha?: any) => {
        setLoading(true)
        let payload = {}

        if (fecha) payload = { ...payload, fecha_apertura: fecha }
        const response = await cuadraturaService.getCierresDiarios(payload)
        setCierres(response.data || [])
        setLoading(false)
    }

    const handleShowModal = (cierre) => {
        setVentas(cierre.ventas || [])
        setDetalleDescuadre(cierre.detalleDescuadre || [])
        setAjustes(cierre.ajustes || [])
        setShowModalDetail(true)
    }

    const handleChangeTab = (event: React.ChangeEvent<{}>, newValue: string) => {
        setIndexTab(newValue);
    };

    const tituloDoc = (tipoDoc) => {
        switch (tipoDoc) {
            case '41':
                return 'BOLETA ELECTRONICA EXENTA';
            case '39':
                return 'BOLETA ELECTRONICA';
            case '34':
                return 'FACTURA ELECTRONICA EXENTA';
            case '33':
                return 'FACTURA ELECTRONICA';
        }
    }

    const showPDF = (venta) => {
        const ventaPDF = {
            detalle: venta.pedido.ticket.detalle_ticket,
            ventaDetalle: venta,
            nombreEmpresa: nombreEmpresa,
            rutEmpresa: rutEmpresa,
            folio: venta?.folio,
            timbre: venta?.timbre_softnet,
            montoNeto: venta.tipo_doc === '41' || venta.tipo_doc === '34' ? 0 : venta?.monto_neto,
            IVA: venta?.monto_total - venta?.monto_neto,
            clienteSelect: venta?.pedido?.cliente || "",
            nombreCajero: venta?.responsable,
            exento: venta?.exento || 0,
            titulo: tituloDoc(venta?.tipo_doc),
            caja: venta?.caja
        }

        setDataImprimir(ventaPDF)
    }

    const showPDFCuadratura = async (id) => {
        try {
            const pdf = await cuadraturaService.getPDFCuadratura(id);
            const blob = new Blob([pdf], { type: 'application/pdf' });
            const blobURL = URL.createObjectURL(blob);
            window.open(blobURL, '_blank');
        } catch (error) {
            console.error('Error al mostrar el PDF:', error);
        }
    };

    const showPDFCierre = async () => {
        try {
            const fechalocal = new Date(fecha)
            const año = fechalocal.getFullYear();
            const mes = String(fechalocal.getMonth() + 1).padStart(2, '0')
            const dia = String(fechalocal.getDate()).padStart(2, '0')

            const fechaFormateada = `${año}-${mes}-${dia}`
            const pdf = await cuadraturaService.getPDFCierreDiario({fecha_apertura: fechaFormateada});
            const blob = new Blob([pdf], { type: 'application/pdf' });
            const blobURL = URL.createObjectURL(blob);
            window.open(blobURL, '_blank');
        } catch (error) {
            console.error('Error al mostrar el PDF:', error);
        }
    };

    return (<Protected>
        {loading && <Loading />}
        <Grid container spacing={2}>
            <Grid item container xs={12}>
                <Grid item xs={4}>
                    <Typography variant="h4">{"Fecha de apertura de cuadratura: "}</Typography>

                </Grid>
                <Grid item xs={6}>
                    <LocalizationProvider dateAdapter={AdapterDateFns} locale={esLocale} >
                        <DatePicker
                            PopperProps={{ placement: "auto-start" }}
                            views={['day']}
                            value={fecha}
                            minDate={new Date('2020-01-01')}
                            maxDate={new Date('2030-01-01')}
                            onChange={(newValue) => {
                                setFecha(newValue);
                            }}
                            renderInput={(params) => <TextField {...params} />}
                        />
                    </LocalizationProvider>
                </Grid>
                {
                    cierres.length > 0 &&
                    <Grid item xs={2}>
                        <Button onClick={() => showPDFCierre()}> <PictureAsPdfIcon />
                            Visualizar PDF</Button>
                    </Grid>
                }
                
            </Grid>
            <Grid item container xs={12}>
                <TableContainer component={Paper}>
                    <Table sx={{ minWidth: 650 }} aria-label="simple table">
                        <TableHead>
                            <TableRow>
                                <TableCell>{`N°`}</TableCell>
                                <TableCell align="left">Fecha apertura</TableCell>
                                <TableCell align="left">Fecha cierre</TableCell>
                                <TableCell align="left">Monto inicial</TableCell>
                                <TableCell align="left">Total ajuste</TableCell>
                                <TableCell align="left">Total descuadre</TableCell>
                                <TableCell align="left">Total ventas efectivo</TableCell>
                                <TableCell align="left">Total descuadre efectivo</TableCell>
                                <TableCell align="left">Monto final sistema</TableCell>
                                <TableCell align="left">Monto final cierre</TableCell>
                                <TableCell align="left">Efectivo final</TableCell>
                                <TableCell align="left">Responsable</TableCell>
                                <TableCell align="left">Caja</TableCell>
                                <TableCell align="center">Detalle</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {cierres?.map((cierre, index) =>
                                <TableRow>
                                    <TableCell align="left">{index + 1}</TableCell>
                                    <TableCell align="left">{cierre.fecha_apertura}</TableCell>
                                    <TableCell align="left">{cierre.fecha_cierre}</TableCell>
                                    <TableCell align="left">{moneyFormatInt(cierre.monto_inicial || 0)}</TableCell>
                                    <TableCell align="left">{moneyFormatInt(cierre.total_ajuste || 0)}</TableCell>
                                    <TableCell align="left">{moneyFormatInt(cierre.total_descuadre || 0)}</TableCell>
                                    <TableCell align="left">{moneyFormatInt(cierre.total_ventas_efectivo || 0)}</TableCell>
                                    <TableCell align="left">{moneyFormatInt(cierre.total_descuadre_efectivo || 0)}</TableCell>
                                    <TableCell align="left">{moneyFormatInt(cierre.monto_final || 0)}</TableCell>
                                    <TableCell align="left">{moneyFormatInt(cierre.monto_final_real || 0)}</TableCell>
                                    <TableCell align="left">{moneyFormatInt(cierre.total_cierre_efectivo || 0)}</TableCell>
                                    <TableCell align="left">{cierre.responsable}</TableCell>
                                    <TableCell align="left">{cierre.caja}</TableCell>
                                    <TableCell align="center">
                                        <Tooltip title={"Ver detalle"}>
                                            <IconButton onClick={() => handleShowModal(cierre)}>
                                                <VisibilityIcon fontSize="small"/>
                                            </IconButton>
                                        </Tooltip>
                                        <Tooltip title={"Ver PDF"}>
                                            <IconButton onClick={() => showPDFCuadratura(cierre.id)}>
                                            <PictureAsPdfIcon fontSize="small"/>
                                            </IconButton>
                                        </Tooltip>
                                    </TableCell>
                                </TableRow>
                            )}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Grid>
        </Grid>
        {showModalDetail &&
            <Popup
                largo="md"
                title={'Detalle de la cuadratura'}
                openPopup={showModalDetail}
                setOpenPopup={setShowModalDetail}
            >
                <Grid container spacing={2}>
                    <TabContext value={indexTab}>
                        <Grid item xs={12}>
                            <TabList onChange={handleChangeTab} aria-label="lab API tabs example">
                                <Tab label="Ventas" value="1" />
                                <Tab label="Ajustes" value="2" />
                                <Tab label="Descuadre" value="3" />
                            </TabList>
                        </Grid>
                        <Grid item xs={12}>
                            <TabPanel value="1" sx={{ width: '100 %' }}>
                                <TableContainer component={Paper}>
                                    <Table sx={{ minWidth: 650 }} aria-label="simple table">
                                        <TableHead>
                                            <TableRow>
                                                <TableCell>{`N°`}</TableCell>
                                                <TableCell align="left">Folio</TableCell>
                                                <TableCell align="left">Fecha</TableCell>
                                                <TableCell align="left">Tipo</TableCell>
                                                <TableCell align="left">Monto neto</TableCell>
                                                <TableCell align="left">Monto total</TableCell>
                                                <TableCell align="left">Monto exento</TableCell>
                                                <TableCell align="left">Total pagado</TableCell>
                                                <TableCell align="left">Responsable</TableCell>
                                                <TableCell align="left">Caja</TableCell>
                                                <TableCell align="center">Detalle</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {ventas?.map((venta, index) =>
                                                <TableRow>
                                                    <TableCell align="left">{index + 1}</TableCell>
                                                    <TableCell align="left">{venta.folio}</TableCell>
                                                    <TableCell align="left">{venta.fecha}</TableCell>
                                                    <TableCell align="left">{venta.tipo_doc_nombre}</TableCell>
                                                    <TableCell align="left">{venta.monto_neto}</TableCell>
                                                    <TableCell align="left">{venta.monto_total}</TableCell>
                                                    <TableCell align="left">{venta.exento}</TableCell>
                                                    <TableCell align="left">{venta.monto_pagado}</TableCell>
                                                    <TableCell align="left">{venta.responsable}</TableCell>
                                                    <TableCell align="left">{venta.caja}</TableCell>
                                                    <TableCell align="center">
                                                        <Tooltip title={"Ver detalle"}>
                                                            <IconButton onClick={() => showPDF(venta)}>
                                                                <VisibilityIcon />
                                                            </IconButton>
                                                        </Tooltip>
                                                    </TableCell>
                                                </TableRow>
                                            )}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </TabPanel>

                            <TabPanel value="2" sx={{ width: '100 %' }}>
                                <TableContainer component={Paper}>
                                    <Table sx={{ minWidth: 650 }} aria-label="simple table">
                                        <TableHead>
                                            <TableRow>
                                                <TableCell>{`N°`}</TableCell>
                                                <TableCell align="left">Tipo</TableCell>
                                                <TableCell align="left">Banco</TableCell>
                                                <TableCell align="left">Monto</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {ajustes?.map((ajuste, index) =>
                                                <TableRow>
                                                    <TableCell align="left">{index + 1}</TableCell>
                                                    <TableCell align="left">{ajuste.tipo_movimiento}</TableCell>
                                                    <TableCell align="left">{ajuste.banco}</TableCell>
                                                    <TableCell align="left">{ajuste.monto}</TableCell>
                                                </TableRow>
                                            )}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </TabPanel>

                            <TabPanel value="3" sx={{ width: '100 %' }}>
                                <DetalleDescuadre detalle={detalleDescuadre} />
                            </TabPanel>

                            {dataImprimir &&
                                <div style={{ display: "none" }}>
                                    <div ref={componentRef}>
                                        <ComponentPrint
                                            data={dataImprimir}
                                        />
                                    </div>
                                </div>
                            }
                        </Grid>
                    </TabContext>
                </Grid>
            </Popup>
        }
    </Protected>)
}