import React, { FC, useEffect, useState } from "react"
import { Popup } from "../Editor/components/Popup"
import { Alert, Autocomplete, CircularProgress, Divider, Grid, Select, Snackbar, TextField, Tooltip, Typography } from "@mui/material"
import { Form, Formik } from "formik"
import PriorityHighIcon from '@mui/icons-material/PriorityHigh';
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import esLocale from 'date-fns/locale/es';
import { DatePicker, LocalizationProvider } from "@mui/lab";
import { distribucionService } from "@/service/services/Distribucion.service";
import { MenuItem } from "react-pro-sidebar";
import { productoService } from "@/service/services/Producto.service";
import { TipoImpuestoService } from "@/service/services/TipoImpuesto.service";
import { readLocalStorage } from "@/toolbox/helpers/local-storage-helper";
import { KEY_EMPRESA } from "@/toolbox/constants/local-storage";
import moment from "moment";

interface ModalProductoProps {
    open: boolean
    setOpenPopup: React.Dispatch<React.SetStateAction<boolean>>
    action: string
    rowSelected: any
    onList: () => Promise<void>
}

export const ModalProducto: FC<ModalProductoProps> = (props) => {
    const { open, action, setOpenPopup, rowSelected: producto, onList } = props

    const [title, setTitle] = useState<string>('')
    const [data, setData] = useState({
        id: producto && producto.id,
        codigo_erp: producto && producto.codigo_erp ? producto.codigo_erp : "",
        nombre: producto && producto.nombre ? producto.nombre : "",
        precio_standar: producto && producto.precio_standar ? producto.precio_standar : "",
        precio_oferta: producto && producto.precio_oferta ? producto.precio_oferta : "",
        caducidad_precio_oferta: producto && producto.caducidad_precio_oferta ? producto.caducidad_precio_oferta : "",
        id_tipo_producto: producto && producto.id_tipo_producto ? producto.id_tipo_producto : 1,
        id_empresa: producto && producto.id_empresa ? producto.id_empresa : "",
        stock: producto && producto.stock ? producto.stock : "",
        is_supply: producto && producto.is_supply ? producto.is_supply : false,
        stock_minimo: producto && producto.stock_minimo ? producto.stock_minimo : 0,
        cantidad_peso: producto && producto.cantidad_peso ? producto.cantidad_peso : 1,
        id_unidad_medida: producto && producto.id_unidad_medida ? producto.id_unidad_medida : 1,
        familia_id: producto && producto.familia_id ? producto.familia_id : 0,
        sub_familia_id: producto && producto.sub_familia_id ? producto.sub_familia_id : 0,
        marca_id: producto && producto.marca_id ? producto.marca_id : 0,
        linea_id: producto && producto.linea_id ? producto.linea_id : 0,
        idtipo_impuesto: producto && producto.idtipo_impuesto ? producto.idtipo_impuesto : 0,
    });
    const [snackBarConfig, setSnackBarConfig] = useState<any>({
        open: false,
        severity: 'error',
        message: 'Error',
        autoHideDuration: 3000,
    });
    const [loading, setLoading] = useState<boolean>(false)
    const [loadingSubmit, setLoadingSubmit] = useState<boolean>(false);
    const [dateCaducidad, setDateCaducidad] = useState(new Date());
    const [selectUniMed, setSelectUniMed] = useState<any>("");
    const [selectFamilia, setSelectFamilia] = useState<any>(null)
    const [selectLinea, setSelectedLinea] = useState<any>(null)
    const [selectTipoImpuesto, setSelectTipoImpuesto] = useState<any>(null)
    const [unidadesMedida, setUnidadesMedida] = useState<any[]>([])
    const [familias, setFamilias] = useState<any[]>([])
    const [lineas, setLineas] = useState<any[]>([])
    const [tiposImpuesto, setTiposImpuesto] = useState<any>([])

    const SessionEmpresa: any = readLocalStorage(KEY_EMPRESA)

    useEffect(() => {
        getComboBoxesAPI()
    }, [])

    useEffect(() => {
        if (action === 'edit' && producto) {
            if (familias.length > 0 && !selectFamilia) {
                const familia = familias.find(familia => familia.id === producto.familia_id)
                setSelectFamilia(familia || null)
            }

            if (lineas.length > 0 && !selectLinea) {
                const linea = lineas.find(linea => linea.id === producto.linea_id)
                setSelectedLinea(linea ||null)
            }

            if (tiposImpuesto.length > 0 && !selectTipoImpuesto) {
                const tipo = tiposImpuesto.find(tipo => tipo.id === producto.idtipo_impuesto)
                setSelectTipoImpuesto(tipo || null)
            }

            if (unidadesMedida.length > 0 && !selectUniMed) {
                const unidad = unidadesMedida.find(unidad => unidad.id === producto.id_unidad_medida)
                setSelectUniMed(unidad || null)
            }
        }
    }, [action, producto, familias, selectFamilia, lineas, selectLinea, tiposImpuesto, selectTipoImpuesto, unidadesMedida, selectUniMed])

    useEffect(() => {
        switch (action) {
            case 'create':
                setTitle('Crear producto')
                break;

            case 'edit':
                setTitle('Editar producto')
                break;

            default:
                break;
        }
    }, [action])

    const getComboBoxesAPI = async () => {
        setLoading(true);
        await distribucionService.ListUnidadMedida().then((data) => {
            setUnidadesMedida(data || []);
        });
        await productoService.getFamiliesAndSubFamilies().then((data) => {
            if (data.status === 200) {
                setFamilias(data.families || []);
            } else {
                setLoading(false);
                return setSnackBarConfig(prev => ({
                    ...prev,
                    open: true,
                    message: `No se cargaron Familia de productos`,
                }));
            }
        });
        await productoService.getLinesProducts().then((data) => {
            if (data.status === 200) {
                setLineas(data.lines || []);
            } else {
                setLoading(false);
                return setSnackBarConfig(prev => ({
                    ...prev,
                    open: true,
                    message: `No se cargaron lineas de productos`,
                }));
            }
        });
        const response = await TipoImpuestoService.getTiposImpuesto()
        if (response.data?.length) {
            setTiposImpuesto(response.data || [])
        }
        setLoading(false);
    }

    const validateForm = (values) => {
        let errors: any = {};

        if (!values.codigo_erp) errors.codigo_erp = "codigo requerido";
        if (!values.nombre) errors.nombre = "nombre requerido";
        if (!values.cantidad_peso) errors.cantidad_peso = "cantidad requerida";
        if (!values.precio_standar) errors.precio_standar = "precio standar requerido";
        if (values.precio_standar && !(Number.isInteger(Number(values.precio_standar)))) errors.precio_standar = "no se permiten decimal";
        if (values.precio_oferta && !(Number.isInteger(Number(values.precio_oferta)))) errors.precio_oferta = "no se permiten decimal";
        if (values.stock_minimo < 0) errors.stock_minimo = "Stock minimo requerido";

        return errors;
    }

    const onSubmit = async (values, { resetForm }) => {
        setLoadingSubmit(true)
        let precio_caducidad;
        if (values.precio_oferta !== "") {
            precio_caducidad = moment(dateCaducidad).format('DD-MM-YYYY');
        } else {
            precio_caducidad = null;
        }

        const product = {
            codigo_erp: values.codigo_erp,
            nombre: values.nombre,
            precio_standar: values.precio_standar,
            precio_oferta: values.precio_oferta,
            id_tipo_producto: values.id_tipo_producto,
            id_empresa: SessionEmpresa.id,
            is_supply: 0,
            stock_minimo: values.stock_minimo,
            cantidad_peso: values.cantidad_peso,
            id_unidad_medida: 1, //Unidades por defecto
            caducidad_precio_oferta: precio_caducidad,
            familia_id: values.familia_id,
            sub_familia_id: values.sub_familia_id,
            marca_id: values.marca_id,
            linea_id: values.linea_id,
            idtipo_impuesto: values.idtipo_impuesto
        }

        const response = await (action === 'create' ? productoService.register(product) : productoService.update(producto.id, product))
        if (response.error) {
            setSnackBarConfig(prev => ({
                ...prev,
                open: true,
                message: response.error || '',
            }));
        } else {
            setOpenPopup(false)
            onList()
        }
        setLoadingSubmit(false)
    }

    return (
        <>
            <Snackbar
                open={snackBarConfig.open}
                autoHideDuration={snackBarConfig.autoHideDuration}
                onClose={() => setSnackBarConfig(prev => ({ ...prev, open: false }))}
                anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
            >
                <Alert
                    onClose={() => setSnackBarConfig(prev => ({ ...prev, open: false }))}
                    severity={snackBarConfig.severity}
                    variant="filled"
                >
                    {snackBarConfig.message}
                </Alert>
            </Snackbar>
            <Popup
                largo="sm"
                title={title}
                openPopup={open}
                setOpenPopup={setOpenPopup}
            >
                <Formik enableReinitialize initialValues={data} validate={(values) => validateForm(values)} onSubmit={onSubmit}>
                    {({ values, errors, touched, handleSubmit, handleChange, setFieldValue }) => {
                        return (
                            <Form onSubmit={handleSubmit}>
                                <Grid container spacing={3} alignItems="center" justifyContent="center">
                                    <Grid item xs={12} container style={{ display: "flex", alignItems: "center" }}>
                                        <Grid item xs={4}>
                                            <Typography variant="subtitle1" className="custom-input"><b>Código:</b></Typography>
                                        </Grid>
                                        <Grid item xs={8} style={{ display: 'flex', alignItems: "center" }}>
                                            {
                                                errors.codigo_erp && (touched.codigo_erp ? true : false) && (
                                                    <Tooltip title={errors.codigo_erp}>
                                                        <PriorityHighIcon className={'iconWarning'} />
                                                    </Tooltip>
                                                )
                                            }
                                            <TextField
                                                id="codigo_erp"
                                                type="text"
                                                name="codigo_erp"
                                                fullWidth
                                                value={values.codigo_erp}
                                                onChange={handleChange}
                                                placeholder="Codigo del producto"
                                                error={errors.codigo_erp && touched.codigo_erp ? true : false}
                                                disabled={action === 'edit'}
                                            />
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={12} container style={{ display: "flex", alignItems: "center" }}>
                                        <Grid item xs={4}>
                                            <Typography variant="subtitle1" className="custom-input"><b>Nombre:</b></Typography>
                                        </Grid>
                                        <Grid item xs={8} style={{ display: 'flex', alignItems: "center" }}>
                                            {
                                                errors.nombre && (touched.nombre ? true : false) && (
                                                    <Tooltip title={errors.nombre}>
                                                        <PriorityHighIcon className={'iconWarning'} />
                                                    </Tooltip>
                                                )
                                            }
                                            <TextField
                                                id="nombre"
                                                type="text"
                                                name="nombre"
                                                fullWidth
                                                value={values.nombre}
                                                onChange={handleChange}
                                                placeholder="Nombre del producto"
                                                error={errors.nombre && touched.nombre ? true : false}
                                                disabled={action === 'edit'}
                                            />
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={12} container style={{ display: "flex", alignItems: "center" }}>
                                        <Grid item xs={4}>
                                            <Typography variant="subtitle1" className="custom-input"><b>Precio standar:</b></Typography>
                                        </Grid>
                                        <Grid item xs={8} style={{ display: 'flex', alignItems: "center" }}>
                                            {
                                                errors.precio_standar && (touched.precio_standar ? true : false) && (
                                                    <Tooltip title={errors.precio_standar}>
                                                        <PriorityHighIcon className={'iconWarning'} />
                                                    </Tooltip>
                                                )
                                            }
                                            <TextField
                                                id="precio_standar"
                                                type="text"
                                                name="precio_standar"
                                                fullWidth
                                                value={values.precio_standar}
                                                onChange={handleChange}
                                                placeholder="precio standar"
                                                error={errors.precio_standar && touched.precio_standar ? true : false}
                                                disabled={action === 'edit'}
                                            />
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={12} container style={{ display: "flex", alignItems: "center" }}>
                                        <Grid item xs={4}>
                                            <Typography variant="subtitle1" className="custom-input"><b>Precio oferta:</b></Typography>
                                        </Grid>
                                        <Grid item xs={8} style={{ display: 'flex', alignItems: "center" }}>
                                            {
                                                errors.precio_oferta && (touched.precio_oferta ? true : false) && (
                                                    <Tooltip title={errors.precio_oferta}>
                                                        <PriorityHighIcon className={'iconWarning'} />
                                                    </Tooltip>
                                                )
                                            }
                                            <TextField
                                                id="precio_oferta"
                                                type="text"
                                                name="precio_oferta"
                                                fullWidth
                                                value={values.precio_oferta}
                                                onChange={handleChange}
                                                error={errors.precio_oferta && touched.precio_oferta ? true : false}
                                                placeholder="precio oferta"
                                                disabled={action === 'edit'}
                                            />
                                        </Grid>
                                    </Grid>
                                    {
                                        values.precio_oferta !== "" && (
                                            <Grid item xs={12} container style={{ display: "flex", alignItems: "center" }}>
                                                <Grid item xs={4}>
                                                    <Typography variant="subtitle1" className="custom-input"><b>Caducidad precio oferta:</b></Typography>
                                                </Grid>
                                                <Grid item xs={8} style={{ display: 'flex', alignItems: "center" }}>
                                                    <LocalizationProvider dateAdapter={AdapterDateFns} locale={esLocale} style={{ width: '300px' }}>
                                                        <DatePicker
                                                            className={'fecha'}
                                                            views={['year', 'month', 'day']}
                                                            minDate={new Date('2022-01-01')}
                                                            maxDate={new Date('2025-01-01')}
                                                            value={dateCaducidad}
                                                            onChange={(newValue) => {
                                                                setDateCaducidad(newValue);
                                                            }}
                                                            renderInput={(params) => <TextField className={'fecha'} {...params} helperText={null} />}
                                                        />
                                                    </LocalizationProvider>
                                                </Grid>
                                            </Grid>
                                        )
                                    }
                                    {/* <Grid container item xs={12} style={{ display: "flex", alignItems: "center" }}>
                                        <Grid item xs={4}>
                                            <Typography variant="subtitle1"><b>Unidad de Medida:</b> </Typography>
                                        </Grid>
                                        <Grid item xs={8} style={{ display: 'flex', alignItems: "center" }}>
                                            <Autocomplete
                                                key='cbx-unidad-medida'
                                                id="cbx-unidad-medida"
                                                size="small"
                                                value={selectUniMed || null}
                                                options={loading ? [] : unidadesMedida}
                                                getOptionLabel={(option) => option?.name || ''}
                                                isOptionEqualToValue={(option, value) => option.id === value?.id}
                                                renderInput={(params) =>
                                                    <TextField {...params}
                                                        label=""
                                                        size="small"
                                                        InputProps={{
                                                            ...params.InputProps,
                                                            endAdornment: (
                                                                <React.Fragment>
                                                                    {loading ? <CircularProgress color="inherit" size={20} /> : null}
                                                                    {params.InputProps.endAdornment}
                                                                </React.Fragment>
                                                            ),
                                                        }}
                                                        error={errors.id_unidad_medida && touched.id_unidad_medida ? true : false}
                                                    />}
                                                onChange={(e, newValue) => {
                                                    setSelectUniMed(newValue)
                                                    setFieldValue('id_unidad_medida', newValue?.id || 0)
                                                }}
                                                fullWidth
                                                noOptionsText={!unidadesMedida ? "Seleccione una unidad de medida" : "Sin opciones"}
                                            />
                                        </Grid>
                                    </Grid> */}
                                    <Grid item xs={12} container style={{ display: "flex", alignItems: "center" }}>
                                        <Grid item xs={4}>
                                            <Typography variant="subtitle1" className="custom-input"><b>Cantidad Peso:</b></Typography>
                                        </Grid>
                                        <Grid item xs={8} style={{ display: 'flex', alignItems: "center" }}>
                                            {
                                                errors.cantidad_peso && (touched.cantidad_peso ? true : false) && (
                                                    <Tooltip title={errors.cantidad_peso}>
                                                        <PriorityHighIcon className={'iconWarning'} />
                                                    </Tooltip>
                                                )
                                            }
                                            <TextField
                                                id="cantidad_peso"
                                                type="number"
                                                name="cantidad_peso"
                                                fullWidth
                                                value={values.cantidad_peso}
                                                onChange={handleChange}
                                                placeholder="Cantidad peso"
                                                error={errors.cantidad_peso && touched.cantidad_peso ? true : false}
                                                disabled={action === 'edit'}
                                            />
                                        </Grid>
                                    </Grid>

                                    <Grid item xs={12} container style={{ display: "flex", alignItems: "center" }}>
                                        <Grid item xs={4}>
                                            <Typography variant="subtitle1" className="custom-input"><b>Stock Minimo:</b></Typography>
                                        </Grid>
                                        <Grid item xs={8} style={{ display: 'flex', alignItems: "center" }}>
                                            {
                                                errors.stock_minimo && (touched.stock_minimo ? true : false) && (
                                                    <Tooltip title={errors.stock_minimo}>
                                                        <PriorityHighIcon className={'iconWarning'} />
                                                    </Tooltip>
                                                )
                                            }
                                            <TextField
                                                id="stock_minimo"
                                                type="number"
                                                name="stock_minimo"
                                                fullWidth
                                                value={values.stock_minimo}
                                                onChange={handleChange}
                                                placeholder="Stock minimo"
                                                error={errors.stock_minimo && touched.stock_minimo ? true : false}
                                                disabled={action === 'edit'}
                                            />
                                        </Grid>
                                    </Grid>

                                    <Grid container item xs={12} style={{ display: "flex", alignItems: "center" }}>
                                        <Grid item xs={4}>
                                            <Typography variant="subtitle1"><b>Familia:</b> </Typography>
                                        </Grid>
                                        <Grid item xs={8} style={{ display: 'flex', alignItems: "center", width: "230px" }}>
                                            <Autocomplete
                                                key='cbx-familia'
                                                id="cbx-familia"
                                                size="small"
                                                value={selectFamilia || null}
                                                options={loading ? [] : familias}
                                                getOptionLabel={(option) => option?.nombre || ''}
                                                isOptionEqualToValue={(option, value) => option.id === value?.id}
                                                renderInput={(params) =>
                                                    <TextField {...params}
                                                        label=""
                                                        size="small"
                                                        InputProps={{
                                                            ...params.InputProps,
                                                            endAdornment: (
                                                                <React.Fragment>
                                                                    {loading ? <CircularProgress color="inherit" size={20} /> : null}
                                                                    {params.InputProps.endAdornment}
                                                                </React.Fragment>
                                                            ),
                                                        }}
                                                        error={errors.familia_id && touched.familia_id ? true : false}
                                                    />}
                                                onChange={(e, newValue) => {
                                                    setSelectFamilia(newValue)
                                                    setFieldValue('familia_id', newValue?.id || 0)
                                                }}
                                                fullWidth
                                                noOptionsText={!familias ? "Seleccione una familia" : "Sin opciones"}
                                            />
                                        </Grid>
                                    </Grid>
                                    <Grid container item xs={12} style={{ display: "flex", alignItems: "center" }}>
                                        <Grid item xs={4}>
                                            <Typography variant="subtitle1"><b>Linea de producto:</b> </Typography>
                                        </Grid>
                                        <Grid item xs={8} style={{ display: 'flex', alignItems: "center", width: "230px" }}>
                                            <Autocomplete
                                                key='cbx-linea'
                                                id="cbx-linea"
                                                size="small"
                                                value={selectLinea || null}
                                                options={loading ? [] : lineas}
                                                getOptionLabel={(option) => option?.nombre || ''}
                                                isOptionEqualToValue={(option, value) => option.id === value?.id}
                                                renderInput={(params) =>
                                                    <TextField {...params}
                                                        label=""
                                                        size="small"
                                                        InputProps={{
                                                            ...params.InputProps,
                                                            endAdornment: (
                                                                <React.Fragment>
                                                                    {loading ? <CircularProgress color="inherit" size={20} /> : null}
                                                                    {params.InputProps.endAdornment}
                                                                </React.Fragment>
                                                            ),
                                                        }}
                                                        error={errors.linea_id && touched.linea_id ? true : false}
                                                    />}
                                                onChange={(e, newValue) => {
                                                    setSelectedLinea(newValue)
                                                    setFieldValue('linea_id', newValue?.id || 0)
                                                }}
                                                fullWidth
                                                noOptionsText={!lineas ? "Seleccione una linea" : "Sin opciones"}
                                            />
                                        </Grid>
                                    </Grid>
                                    <Grid container item xs={12} style={{ display: "flex", alignItems: "center" }}>
                                        <Grid item xs={4}>
                                            <Typography variant="subtitle1"><b>Tipo de impuesto:</b> </Typography>
                                        </Grid>
                                        <Grid item xs={8} style={{ display: 'flex', alignItems: "center", width: "230px" }}>
                                            <Autocomplete
                                                key='cbx-impuesto'
                                                id="cbx-impuesto"
                                                size="small"
                                                value={selectTipoImpuesto || null}
                                                options={loading ? [] : tiposImpuesto}
                                                getOptionLabel={(option) => option?.nombre || ''}
                                                isOptionEqualToValue={(option, value) => option.id === value?.id}
                                                renderInput={(params) =>
                                                    <TextField {...params}
                                                        label=""
                                                        size="small"
                                                        InputProps={{
                                                            ...params.InputProps,
                                                            endAdornment: (
                                                                <React.Fragment>
                                                                    {loading ? <CircularProgress color="inherit" size={20} /> : null}
                                                                    {params.InputProps.endAdornment}
                                                                </React.Fragment>
                                                            ),
                                                        }}
                                                        error={errors.idtipo_impuesto && touched.idtipo_impuesto ? true : false}
                                                    />}
                                                onChange={(e, newValue) => {
                                                    setSelectTipoImpuesto(newValue)
                                                    setFieldValue('idtipo_impuesto', newValue?.id || 0)
                                                }}
                                                fullWidth
                                                noOptionsText={!lineas ? "Seleccione una tipo de impuesto" : "Sin opciones"}
                                            />
                                        </Grid>
                                    </Grid>
                                </Grid>

                                <Divider sx={{ my: 2 }} />

                                <div className="form-group mb-3 buton_container_modal">
                                    <button type="submit" className="btn btn-primary" disabled={loadingSubmit}>
                                        {action === 'create' ? 'Agregar producto' : 'Actualizar producto'}
                                    </button>
                                </div>

                            </Form>
                        )
                    }}
                </Formik>
            </Popup>
        </>
    )
}