import { tarjetaExistenciaService } from "@/service/services/TarjetaExistencia.service";
import { Popup } from "@/views/Editor/components/Popup";
import { Autocomplete, CircularProgress, Divider, Grid, TextField, Typography } from "@mui/material";
import { Form, Formik } from "formik";
import React, { FC, useEffect, useState } from "react";

interface ReposicionSobrantesModalProps {
    open: boolean
    setOpenPopup: React.Dispatch<React.SetStateAction<boolean>>
    setSobrantes: React.Dispatch<React.SetStateAction<any[]>>
}

const optionsFilter = [
    { id: 1, code: 'day', name: 'Por día' },
    { id: 2, code: 'month', name: 'Por mes' },
    { id: 3, code: 'dates', name: 'Por fechas' }
];

const mesActual = new Date().toISOString().slice(0, 7)
const fechaActual = new Date().toISOString().slice(0, 10);

export const ReposicionSobrantesModal: FC<ReposicionSobrantesModalProps> = (props) => {
    const { open, setOpenPopup, setSobrantes } = props

    const [data, setData] = useState({
        date1: 0,
        date2: 0,
    });
    const [loading, setLoading] = useState<boolean>(false)
    const [selectOption, setSelectOption] = useState<any>(optionsFilter[0])
    const [date1, setDate1] = useState<string>('')
    const [date2, setDate2] = useState<string>('')

    useEffect(() => {
        if (selectOption?.id === 2) {
            setDate1(mesActual)
        } else {
            setDate1(fechaActual)
        }
    }, [selectOption])

    const onSubmit = async (values) => {
        setLoading(true)
        try {
            if (date1 === '') return
            let payload: any = {
                date1: date1
            }
            if (date2 !== '') payload = { ...payload, date2: date2 }
            const response = await tarjetaExistenciaService.getSobrantes(payload)
            setSobrantes(response.data || [])
            setOpenPopup(false)
        } catch (error) {
            console.error("Error:", error);
        }
        setLoading(false)
    }

    return (
        <Popup
            largo="md"
            title={'Reponer insumos utilizados'}
            openPopup={open}
            setOpenPopup={setOpenPopup}
        >
            <Formik enableReinitialize initialValues={data} onSubmit={onSubmit}>
                {({ values, errors, touched, handleSubmit, handleChange, setFieldValue }) => {
                    return (
                        <Form onSubmit={handleSubmit}>
                            <Grid container spacing={3} alignItems="center" justifyContent="center">
                                <Grid item container xs={12}>
                                    <Grid item container xs={12} md={6} spacing={2}>
                                        <Grid item xs={4}>
                                            <Typography sx={{
                                                fontSize: '20px',
                                                color: 'black'
                                            }}>Ventas de:</Typography>
                                        </Grid>
                                        <Grid item xs={6}>
                                            <Autocomplete
                                                key='cbx-comuna'
                                                id="cbx-comuna"
                                                size="small"
                                                value={selectOption || null}
                                                options={optionsFilter || []}
                                                getOptionLabel={(option) => option?.name || ''}
                                                isOptionEqualToValue={(option, value) => option.id === value?.id}
                                                renderInput={(params) =>
                                                    <TextField {...params}
                                                        label=""
                                                        size="small"
                                                        InputProps={{
                                                            ...params.InputProps,
                                                            endAdornment: (
                                                                <React.Fragment>
                                                                    {loading ? <CircularProgress color="inherit" size={20} /> : null}
                                                                    {params.InputProps.endAdornment}
                                                                </React.Fragment>
                                                            ),
                                                        }}
                                                    />}
                                                onChange={(e, newValue) => {
                                                    setSelectOption(newValue)
                                                }}
                                                fullWidth
                                                noOptionsText={!selectOption ? "Seleccione una opcion de filtro" : "Sin opciones"}
                                            />
                                        </Grid>
                                    </Grid>
                                    <Grid item container xs={12} md={6}
                                        sx={{
                                            display: 'flex',
                                            alignItems: 'center',
                                            justifyContent: 'space-between',
                                        }}
                                        spacing={3}
                                    >
                                        <Grid item>
                                            {
                                                selectOption?.id !== 2 &&
                                                <TextField
                                                    id="date1"
                                                    type="date"
                                                    size="small"
                                                    value={date1}
                                                    onChange={(e) => setDate1(e.target.value)}
                                                    InputLabelProps={{
                                                        shrink: true,
                                                    }}
                                                />
                                            }
                                            {
                                                selectOption?.id === 2 &&
                                                <TextField
                                                    id="month"
                                                    label="Seleccione un mes"
                                                    type="month"
                                                    size="small"
                                                    value={date1}
                                                    onChange={(e) => setDate1(e.target.value)}
                                                    InputLabelProps={{
                                                        shrink: true,
                                                    }}
                                                    InputProps={{
                                                        inputProps: {
                                                            max: "9999-12",
                                                            min: "1000-01"
                                                        }
                                                    }}
                                                />
                                            }

                                        </Grid>
                                        {
                                            selectOption?.id === 3 && (<>
                                                <Grid item>
                                                    <Typography>hasta</Typography>
                                                </Grid>
                                                <Grid item>
                                                    <TextField
                                                        id="date2"
                                                        type="date"
                                                        size="small"
                                                        value={date2}
                                                        onChange={(e) => setDate2(e.target.value)}
                                                        InputLabelProps={{
                                                            shrink: true,
                                                        }}
                                                    />
                                                </Grid>
                                            </>)
                                        }
                                    </Grid>
                                </Grid>



                            </Grid>

                            <Divider sx={{ my: 2 }} />

                            <div className="form-group mb-3 buton_container_modal">
                                <button type="submit" className="btn btn-primary" disabled={loading}>
                                    BUSCAR
                                </button>
                            </div>

                        </Form>
                    )
                }}
            </Formik>
        </Popup>
    )
}