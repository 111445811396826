import axios from 'axios';
import { API_URL_NUPY } from '@/toolbox/defaults/app';

export const ServicioRepository = {

    getServicios: async(): Promise<any> => {
        const res = await axios.get(`${API_URL_NUPY}/services/list`);
        return res.data;
    },

    register: async(data:{}): Promise<any> => {
        const res = await axios.post(`${API_URL_NUPY}/services/register`, data);
        return res;
    },

    update: async(data:{}, idServicio:number): Promise<any> => {
        const res = await axios.post(`${API_URL_NUPY}/services/update/${idServicio}`, data);
        return res;
    },

    deleteServicio: async(idServicio:number): Promise<any> => {
        const res= await axios.delete(`${API_URL_NUPY}/services/delete/${idServicio}`)
        return res;
    }
}