import { SucursalesRepository } from "../repositories/Sucursales.repository";

export const sucursalesService = {
    register,
    update,
    listAllSucursales,
    editState,
    getSucursalesByEmpresa,
}

async function register(data:{}){
    const res = await SucursalesRepository.register(data);
    return res;
}

async function update(data:{}, idSucursal:number){
    const res = await SucursalesRepository.update(data, idSucursal);
    return res;
}

async function listAllSucursales() {
    const res = await SucursalesRepository.listAllSucursales();
    return res;
}

async function editState(idSucursal:number, state:any){
    const res = await SucursalesRepository.editState(idSucursal, state);
    return res;
}

async function getSucursalesByEmpresa(idCompany: number) {
    const res = await SucursalesRepository.getSucursalesByEmpresa(idCompany);
    return res;
}