import React, { useState } from 'react';
import { Box, createTheme, Grid, Paper,
   Table, TableBody, TableCell, TableContainer, TableHead,
   TableRow, ThemeProvider, Typography, IconButton, Collapse } from "@mui/material";
import { esES } from "@mui/material/locale";
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';

const theme = createTheme(
    {
        palette: {
            primary: { main: '#1976D2' },
        },
    },
    esES,
);


export const ProductosAgrupados = (props) => {
    const {productAgrupado} = props;
    const [open, setOpen] = useState(false);

    return (
        <Grid>
            <Box sx={{Width: '100%', marginTop:'0px'}}>
            <TableContainer component={Paper}>
            <ThemeProvider theme={theme}>
            <Table aria-label="simple table" size='small'>
                <TableHead>
                    <TableRow  sx={{ bgcolor:'#1976D2'}}>
                        <TableCell sx={{ color: 'white' }} >
                            <strong>N°</strong>
                        </TableCell>
                        <TableCell align='center' sx={{ color: 'white' }} >
                            <strong>Código</strong>
                        </TableCell>
                        <TableCell align='center' sx={{ color: 'white' }} >
                            <strong>Nombre</strong>
                        </TableCell>
                        <TableCell align='center' sx={{ color: 'white' }} >
                            <strong>Precio</strong>
                        </TableCell>
                        <TableCell align='center' sx={{ color: 'white' }} >
                            <strong>Cantidad</strong>
                        </TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {
                        productAgrupado && productAgrupado.map((item, id) => (
                           <>
                            <TableRow key={id}>
                                <TableCell>
                                    <IconButton
                                       aria-label="expand row"
                                       size="small"
                                       onClick={() => setOpen(!open)}
                                    >
                                       {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                                    </IconButton>
                                 </TableCell>
                                <TableCell align='center' component="th" scope="row">
                                    {item?.codigo_erp}
                                </TableCell>
                                <TableCell align='center' component="th" scope="row">
                                    {item?.nombre}
                                </TableCell>
                                <TableCell align='center' component="th" scope="row">
                                    {item?.precio_standar}
                                </TableCell>
                                <TableCell align='center' component="th" scope="row">
                                    {item?.cantidad}
                                </TableCell>
                            </TableRow>
                            <TableRow>
                           <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
                              <Collapse in={open} timeout="auto" unmountOnExit>
                                 <Box sx={{ margin: 1 }}>
                                 <Typography variant="h6" gutterBottom component="div">
                                    Items agrupados - {item?.nombre}
                                 </Typography>
                                 <Table size="small" aria-label="purchases">
                                    <TableHead>
                                       <TableRow>
                                          <TableCell>Item</TableCell>
                                       </TableRow>
                                    </TableHead>
                                    <TableBody>
                                       {(item?.agrupados|| []).map((item,index) => (
                                       <TableRow key={`${index}-item`}>
                                          <TableCell>{item?.nombre}</TableCell>
                                       </TableRow>
                                       ))}
                                    </TableBody>
                                 </Table>
                                 </Box>
                              </Collapse>
                           </TableCell>
                           </TableRow>
                            </>
                        ))
                    }
                </TableBody>
            </Table>
            </ThemeProvider>
            </TableContainer>
            </Box>
        </Grid>
    );
}
