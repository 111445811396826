import { API_URL_NUPY } from '@/toolbox/defaults/app';
import axios from 'axios';

export const BeneficioPuntosRepository = {
    getBeneficioPuntos: async(): Promise<any> => {
        const res = await axios.get(`${API_URL_NUPY}/beneficio-puntos/list`);
        return res.data;
    },

    beneficioPuntosAvailable: async(): Promise<any> => {
        const res = await axios.get(`${API_URL_NUPY}/beneficio-puntos/beneficiosAvailable`);
        return res.data;
    },

    register: async(data:{}): Promise<any> => {
        const res = await axios.post(`${API_URL_NUPY}/beneficio-puntos/register`, data);
        return res;
    },

    update: async(data:{}, idBeneficio:number): Promise<any> => {
        const res = await axios.post(`${API_URL_NUPY}/beneficio-puntos/update/${idBeneficio}`, data);
        return res;
    },

    updateStatus: async(idBeneficio:number, state:string): Promise<any> => {
        const res = await axios.patch(`${API_URL_NUPY}/beneficio-puntos/updateStatus/${idBeneficio}/${state}`);
        return res.data;
    },

    deleteBeneficioPuntos: async(idBeneficio:number): Promise<any> => {
        const res= await axios.delete(`${API_URL_NUPY}/beneficio-puntos/delete/${idBeneficio}`)
        return res;
    }
}
