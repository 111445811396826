import { Props } from "./Users.type";
import React, { useEffect, useState } from "react";
import { Loading } from "../../components/common/Loading/Loading";
import { Protected } from "@/components/layout/Protected";
import { Box, Button, Container, createTheme, Divider, FormControlLabel, Grid, InputAdornment, Paper, Switch, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, ThemeProvider, Typography } from "@mui/material";
import { esES } from "@mui/material/locale";
import { PencilIcon } from "@/toolbox/constants/icons";
import VisibilityIcon from '@mui/icons-material/Visibility';
import { Popup } from "../Editor/components/Popup";
import ContentPasteOffIcon from '@mui/icons-material/ContentPasteOff';
import { userService } from "@/service/services/User.service";
import { AddUsuario } from "./components/AddUsuario";
import { useLocalStorage } from '@/toolbox/hooks/local-storage.hook';
import { KEY_USER_DATA } from '@/toolbox/constants/local-storage';
import SearchIcon from "@mui/icons-material/Search";

const theme = createTheme(
    {
        palette: {
            primary: { main: '#1976D2' },
        },
    },
    esES,
);

export const Users: React.FC<Props> = (props: Props): JSX.Element => {

    const [loading, setLoading] = React.useState<boolean>(false);
    const [users, setUsers] = React.useState([]);
    const [usersBackUp, setUsersBackUp] = useState<any[]>([])

    const [selectedUsers, setSelectedUser] = React.useState('');

    const [openPopup, setOpenPopup] = React.useState<boolean>(false);
    const [popUp, setPopUp] = React.useState<String>('');
    const [editMode, setEditMode] = React.useState<boolean>(false);
    const [rowsPerPage, setRowsPerPage] = useState(20);
    const [page, setPage] = useState(0);
    const [dataUser] = useLocalStorage(KEY_USER_DATA, undefined);
    const rol = dataUser && dataUser.user.rol;
    const isSuperAdmin = rol === 'Superadmin';

    useEffect(() => {
        getUsers();
    }, [])


    const getUsers = async () => {
        setLoading(true);
        await userService.getUsers().then((data) => {
            setUsers(data);
            setUsersBackUp(data)
            setLoading(false);
        })
    }

    const funcionSwitch = async (user) => {
        setLoading(true);
        if (user.state === 1) {
            await userService.editState(user.id, 0);
        } else {
            await userService.editState(user.id, 1);
        }
        getUsers();
    }

    const onSearch = (search: string) => {
        const searchLowerCase = search.trim().toLowerCase();
        if (search !== '') {
            const filteredProducts = usersBackUp.filter(user => {
                const nombre = user.nombre && user.nombre.toLowerCase();
                const email = user.email && user.email.toLowerCase();
                const rut = user.rut && user.rut.toLowerCase();
                const usuario = user.usuario && user.usuario.toLowerCase();
                return (
                    (nombre && nombre.includes(searchLowerCase)) ||
                    (email && email.includes(searchLowerCase)) ||
                    (rut && rut.includes(searchLowerCase)) ||
                    (usuario && usuario.includes(searchLowerCase))
                );
            });
            setUsers(filteredProducts);
        } else {
            setUsers(usersBackUp);
        }
    };

    return (
        <Protected>
            {loading && <Loading />}
            <Grid container spacing={2}>
                <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'center' }}>
                    <Typography variant="h4">Gestión de usuarios</Typography>
                </Grid>
                <Grid item container xs={12}
                    sx={{
                        alignItems: "center",
                        display: "flex",
                        justifyContent: "space-between",
                        flexWrap: "wrap",
                        marginBottom: "10px",
                    }}
                >
                    <Grid item>
                        <TextField
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <SearchIcon fontSize="small" />
                                    </InputAdornment>
                                ),
                            }}
                            placeholder="Buscar..."
                            size="small"
                            onChange={(e) => onSearch(e.target.value)}
                        />
                    </Grid>
                    <Grid
                        item
                        sx={{ textAlign: "right" }}
                    >
                        <Button variant='outlined' style={{ textTransform: "capitalize" }}
                            onClick={() => {
                                setOpenPopup(true);
                                setEditMode(true);
                                setPopUp('addUsuario');
                            }}
                        >Nuevo usuario</Button>
                    </Grid>
                </Grid>
                <Grid item xs={12}>
                    <Box sx={{ Width: '100%' }}>
                        <TableContainer component={Paper}>
                            <ThemeProvider theme={theme}>
                                <Table aria-label="simple table" size='small'>
                                    <TableHead>
                                        <TableRow sx={{ bgcolor: '#1976D2' }}>
                                            <TableCell sx={{ color: 'white' }} >
                                                <strong>N°</strong>
                                            </TableCell>
                                            <TableCell align='center' sx={{ color: 'white' }} >
                                                <strong>Nombre</strong>
                                            </TableCell>
                                            <TableCell align='center' sx={{ color: 'white' }} >
                                                <strong>Usuario</strong>
                                            </TableCell>
                                            <TableCell align='center' sx={{ color: 'white' }} >
                                                <strong>Email</strong>
                                            </TableCell>
                                            <TableCell align='center' sx={{ color: 'white' }} >
                                                <strong>RUT</strong>
                                            </TableCell>
                                            {
                                                isSuperAdmin && (
                                                    <TableCell align='center' sx={{ color: 'white' }} >
                                                        <strong>Empresa</strong>
                                                    </TableCell>
                                                )
                                            }
                                            <TableCell align='center' sx={{ color: 'white' }} >
                                                <strong>Sucursal</strong>
                                            </TableCell>
                                            <TableCell align='center' sx={{ color: 'white' }} >
                                                <strong>Estado</strong>
                                            </TableCell>

                                            <TableCell align='center' sx={{ color: 'white', minWidth: '8rem', maxWidth: '12rem' }}><strong>Acciones</strong></TableCell>
                                        </TableRow>
                                    </TableHead>

                                    <TableBody>
                                        {!!users.length
                                            ?
                                            users?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((user, id) => (
                                                <TableRow key={id}>
                                                    <TableCell component="th" scope="row">
                                                        {id + 1}
                                                    </TableCell>
                                                    <TableCell component="th" scope="row">
                                                        {user.nombre?.toLowerCase()}
                                                    </TableCell>
                                                    <TableCell align='center' component="th" scope="row">
                                                        {user?.usuario}
                                                    </TableCell>
                                                    <TableCell align='center' component="th" scope="row">
                                                        {user?.email}
                                                    </TableCell>
                                                    <TableCell align='center' component="th" scope="row">
                                                        {user?.rut}
                                                    </TableCell>
                                                    {
                                                        isSuperAdmin && (
                                                            <TableCell align='center' component="th" scope="row">
                                                                {user?.sucursales?.empresa?.name}
                                                            </TableCell>
                                                        )
                                                    }
                                                    <TableCell align='center' component="th" scope="row">
                                                        {user?.sucursales?.nombre}
                                                    </TableCell>
                                                    <TableCell align='center' component="th" scope="row">
                                                        <Box>
                                                            <FormControlLabel
                                                                control={<Switch onClick={() => {
                                                                    funcionSwitch(user);
                                                                }}
                                                                />}
                                                                checked={user.state === 1 ? true : false}
                                                                label=""
                                                            />
                                                        </Box>
                                                    </TableCell>
                                                    <TableCell align='center'>
                                                        <Button
                                                            onClick={() => {
                                                                setOpenPopup(true);
                                                                setEditMode(true);
                                                                setPopUp('editUsuario');
                                                                setSelectedUser(user);
                                                            }}
                                                        >
                                                            <PencilIcon />
                                                        </Button>

                                                        <Button
                                                            onClick={() => {
                                                                setOpenPopup(true);
                                                                setEditMode(false);
                                                                setPopUp('detailUsuario');
                                                                setSelectedUser(user);
                                                            }}
                                                        >
                                                            <VisibilityIcon />
                                                        </Button>
                                                    </TableCell>
                                                </TableRow>
                                            ))
                                            :
                                            <TableRow>
                                                <TableCell align="center" colSpan={8}>Sin resultados</TableCell>
                                            </TableRow>
                                        }
                                    </TableBody>
                                </Table>
                            </ThemeProvider>
                        </TableContainer>
                    </Box>


                </Grid>
            </Grid>
            {
                popUp === 'addUsuario' && (
                    <Popup
                        largo="sm"
                        title='Agregar Usuario'
                        openPopup={openPopup}
                        setOpenPopup={setOpenPopup}
                    >
                        <AddUsuario
                            closeModal={() => { setOpenPopup(false) }}
                            onListUsuarios={getUsers}
                            editMode={editMode}
                        />

                    </Popup>
                )
            }
            {
                popUp === 'editUsuario' && (
                    <Popup
                        largo="sm"
                        title='Editar Usuario'
                        openPopup={openPopup}
                        setOpenPopup={setOpenPopup}
                    >
                        <AddUsuario
                            closeModal={() => { setOpenPopup(false) }}
                            usuario={selectedUsers}
                            onListUsuarios={getUsers}
                            editMode={editMode}
                        />

                    </Popup>
                )
            }
            {
                popUp === 'detailUsuario' && (
                    <Popup
                        largo="sm"
                        title='Detalle Usuario'
                        openPopup={openPopup}
                        setOpenPopup={setOpenPopup}
                    >
                        <AddUsuario
                            closeModal={() => { setOpenPopup(false) }}
                            usuario={selectedUsers}
                            editMode={editMode}
                        />

                    </Popup>
                )
            }
        </Protected>
    );
}
