import { API_URL_NUPY } from '@/toolbox/defaults/app';
import axios from 'axios';

export const RecetaRepository = {

    get: async (data?: {}): Promise<any> => {
        const res = await axios.get(`${API_URL_NUPY}/receta/list`, { params: data });
        return res.data;
    },

    create: async (data: {}): Promise<any> => {
        const res = await axios.post(`${API_URL_NUPY}/receta/create`, data);
        return res.data;
    },

    update: async (id: number, data: {}): Promise<any> => {
        const res = await axios.patch(`${API_URL_NUPY}/receta/update/${id}`, data);
        return res.data;
    },

    delete: async (id: number): Promise<any> => {
        const res = await axios.delete(`${API_URL_NUPY}/receta/delete/${id}`);
        return res.data;
    },

    getTemplateImport: async (): Promise<any> => {
        const res = await axios.get(`${API_URL_NUPY}/receta/template`);
        return res;
    },

    import: async (data: any): Promise<any> => {
        const res = await axios.post(`${API_URL_NUPY}/receta/import`, data);
        return res;
    },
}