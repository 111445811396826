import {  Dialog, DialogContent, DialogTitle } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import  "./Popup.sass"


export const Popup: React.FC<any> = (props:any): JSX.Element => {
    const {title, children, openPopup, setOpenPopup, largo, closeModal} = props;

    return(
        <Dialog open={openPopup} maxWidth={largo} fullWidth classes={{
            root: 'noseeeeeeeeeeee'
        }}>
            <DialogTitle classes={{
               root: "modal__title"
            }}>
               <div>
                  <span>{title}</span>
                  <IconButton aria-label="delete" size="small" onClick={() =>{
                      setOpenPopup(false);
                      closeModal && closeModal();
                      }}>
                     <CloseIcon sx={{ fontSize: 20 }}/>
                  </IconButton>
               </div>
                {/* <Typography variant="h5" component="div" style={{margin:'0px'}}>
                    {title}
                </Typography>
                <Box position="absolute" top={0} right={0} style={{marginRight:'15px', marginTop:'25px'}}>
                    <IconButton aria-label="delete" size="small" color="error" onClick={() =>{setOpenPopup(false)}}>
                        <CloseIcon sx={{ fontSize: 20 }}/>
                    </IconButton>
                </Box> */}
            </DialogTitle>
            <DialogContent dividers classes={{root:"modal__container"}}>
                <div>
                    {children}
                </div>

            </DialogContent>
        </Dialog>
    );

}
