import axios from 'axios';
import { API_URL_NUPY } from '@/toolbox/defaults/app';
import { readLocalStorage } from '@/toolbox/helpers/local-storage-helper';
import { KEY_BODEGA_SELECT, TOKEN_API } from '@/toolbox/constants/local-storage';
import moment from 'moment';

export const compraRepository = {
    saveCompra: async(folio:number, fecha:string, IVA:number, state:number, id_proveedor:number, id_empresa: number, documento, detalleSofnet, rut_proveedor): Promise<any> =>{
        let fechaSofnet = moment(fecha, 'DD-MM-YYYY').format('YYYY-MM-DD');
        const bodega = readLocalStorage(KEY_BODEGA_SELECT);
        // await addCompraSofnet(fechaSofnet, folio, rut_proveedor, bodega, documento, detalleSofnet);
        const res = await axios.post(`${API_URL_NUPY}/compras/register`, {
            folio,
            fecha,
            IVA: IVA,
            state,
            id_proveedor: id_proveedor,
            id_empresa: id_empresa,
            tipo_doc: documento
        });

        return res.data;
    },

    listCompras: async(): Promise<any> =>{
        const res = await axios.get(`${API_URL_NUPY}/compras/listAll`);
        return res.data;
    },

    update: async(idCompra: number, folio:number, fecha:string, IVA:number, state:number, id_proveedor:number): Promise<any> => {
        const res = await axios.patch(`${API_URL_NUPY}/compras/update/${idCompra}`, {
         folio,
         fecha,
         IVA: IVA,
         state,
         id_proveedor: id_proveedor
     });
        return res.data;
    },

    receivedAt: async(idCompra: number, fecha_received_at:string): Promise<any> => {
      const res = await axios.patch(`${API_URL_NUPY}/compras/received/${idCompra}`, {
         fecha_received_at
      });
         return res.data;
   },
   getValoracion: async(idEmpresa: number): Promise<any> => {
      const res = await axios.get(`${API_URL_NUPY}/compras/valoracion/${idEmpresa}`);
      return res.data;
   },

   addCompraSofnet,
}

function addCompraSofnet(fecha, folio, rut_proveedor, bodega, documento, detalleSofnet){
    const token: any = readLocalStorage(TOKEN_API);
    return fetch(`https://api2.softnet.cl/guiaAjuste`,{
        method: 'POST',
        headers: {
            'Access-Control-Allow-Origin':'*',
            'Content-Type': 'application/x-www-form-urlencoded',
            token: token,
        },
        body: JSON.stringify([{
            Encabezado: {
                "Fecha": `${fecha}`,
                "Operacion": `1`,
                "Bodega": bodega,
                "TipoDocumento": `${documento}`,
                "FolioDocumento": Number(folio),
                "Observacion": `Sin Observacion`,
                "RutProveedor": `${rut_proveedor}`,
            },
            Detalle: detalleSofnet || []
        }])

        }).then(pros => pros.json())
            .then(async pros => {
                return true
            })
            .catch(error => {
            /* alert("REGISTRO MAL EN SOFNET") */
            return false;
        })
}
