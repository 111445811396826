import { Grid, Typography } from "@mui/material";
import { Popup } from "@/views/Editor/components/Popup";
import { ModalFooter } from "../Modal/ModalFooter";

export const ModalConfirm: React.FC<any> = (props): JSX.Element | any => {
    const { open, title, text, closeModal, clearState, onConfirm, status2 = false } = props;

    return (
        <>
            <Popup
                largo="sm"
                title={title}
                openPopup={open}
                setOpenPopup={closeModal}
            >
                <Grid>
                    <Typography variant="subtitle1" className="textColor">
                        {text ||
                            'Si estas seguro, haz click en el boton "aceptar" y el registro será eliminado'}
                    </Typography>
                </Grid>

                <ModalFooter
                    confirmText={"Aceptar"}
                    onConfirm={onConfirm}
                    cancelText={"Cancelar"}
                    onCancel={closeModal}
                    className="modal-confirm-footer"
                    loadingConfirmText={status2}
                />
            </Popup>
        </>
    );
};
