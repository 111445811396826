import React, { useEffect, useState } from 'react';
import { Divider, Grid, MenuItem, Select, TextField, Typography, Tooltip, Autocomplete, CircularProgress, RadioGroup, FormControlLabel, Radio } from '@mui/material';
import { Loading } from '@/components/common/Loading/Loading';
import { clientesService } from '@/service/services/Clientes.service';
import { Props } from './AddCliente.type'
import { Form, Formik } from 'formik';
import { KEY_EMPRESA } from '@constants/local-storage';
import { readLocalStorage } from '@helpers/local-storage-helper';
import PriorityHighIcon from '@mui/icons-material/PriorityHigh';
import { Snackbar, Alert } from '@mui/material';
import { ProvinciaService } from '@/service/services/Provincia.service';

export const AddCliente: React.FC<Props> = (props: Props): JSX.Element => {

    const { closeModal, cliente, onListClientes, editMode } = props;

    const [loading, setLoading] = React.useState<boolean>(false);
    // eslint-disable-next-line
    const [data, setData] = React.useState({
        id: cliente && cliente.id,
        nombre: cliente && cliente.nombre ? cliente.nombre : "",
        rut: cliente && cliente.rut ? cliente.rut : "",
        extranjero: parseInt(cliente?.extranjero) || 0,
        doc_iden: cliente?.doc_iden || '',
        observacion: cliente && cliente.observacion ? cliente.observacion : "",
        id_tipoPersona: cliente && cliente.id_tipoPersona ? cliente.id_tipoPersona : 1,
        nombre_rz: cliente && cliente.nombre_rz ? cliente.nombre_rz : "",
        giro: cliente && cliente.giro ? cliente.giro : "",
        direccion: cliente && cliente.direccion ? cliente.direccion : "",
        idcomuna: cliente && cliente.idcomuna ? cliente.idcomuna : "",
        idprovincia: cliente && cliente.idprovincia ? cliente.idprovincia : "",
    });

    const [snackBarConfig, setSnackBarConfig] = useState<any>({
        open: false,
        severity: 'error',
        message: 'Error',
        autoHideDuration: 3000,
    });
    const [provincias, setProvincias] = useState<any>([])
    const [comunas, setComunas] = useState<any>([])
    const [provinciaSelected, setProvinciaSelected] = useState<any>(null)
    const [comunaSelected, setComunaSelected] = useState<any>(null)

    const SessionEmpresa: any = readLocalStorage(KEY_EMPRESA);
    const pais = SessionEmpresa.country_abbreviation;

    var Fn = {
        // Valida el rut con su cadena completa "XXXXXXXX-X"
        validaRut: function (rutCompleto) {
            rutCompleto = rutCompleto.replace("‐", "-");
            if (!/^[0-9]+[-|‐]{1}[0-9kK]{1}$/.test(rutCompleto))
                return false;
            var tmp = rutCompleto.split('-');
            var digv = tmp[1];
            var rut = tmp[0];
            if (digv === 'K') digv = 'k';

            return (Number(Fn.dv(rut)) === Number(digv));
        },
        dv: function (T) {
            var M = 0, S = 1;
            for (; T; T = Math.floor(T / 10))
                S = (S + T % 10 * (9 - M++ % 6)) % 11;
            return S ? S - 1 : 'k';
        }
    }


    // $("#btnvalida").click(function(){
    //    if (Fn.validaRut( $("#txt_rut").val() )){
    //       $("#msgerror").html("El rut ingresado es válido :D");
    //    } else {
    //       $("#msgerror").html("El Rut no es válido :'( ");
    //    }
    // });

    useEffect(() => {
        getUbigeoApi()
    }, [])

    useEffect(() => {
        if (provinciaSelected) setComunas(provinciaSelected?.comunas || [])
        if (cliente && comunas.length) {
            const comuna = comunas.find(comuna => comuna.id === cliente.idcomuna)
            setComunaSelected(comuna || null)
        }
    }, [cliente, provinciaSelected, comunas])

    useEffect(() => {
        if (cliente && provincias.length) {
            const provincia = provincias.find(provincia => provincia.id === cliente.idprovincia)
            setProvinciaSelected(provincia || null)
        }
    }, [cliente, provincias])

    const getUbigeoApi = async () => {
        setLoading(true)
        const response = await ProvinciaService.getProvincias()
        if (response.data?.length) setProvincias(response.data)
        setLoading(false)
    }

    const validateForm = async (values) => {
        let errors: any = {};

        const formats = {
            name: /^[A-Za-zÑñÁáÉéÍíÓóÚúÜü\s]+$/,
            // rutChile: /^[0-9]{8}[-|‐]{1}[0-9kK]{1}$/,
            rutPeru: /^\d{11}$/,
            rutColombia: /^[0-9]{9}[-|‐]{1}[0-9]{1}$/,
            observacion: /^[A-Za-zÑñÁáÉéÍíÓóÚúÜü\s]+$/,
        }

        if (!values.nombre) errors.nombre = "nombre requerido";
        if (values.nombre && !formats.name.test(values.nombre)) errors.nombre = "nombre inválido";
        if (values.extranjero === 0 && !values.rut) errors.rut = "campo requerido"
        if (values.extranjero === 1 && !values.doc_iden) errors.doc_iden = "campo requerido"
        // if (pais === 'CLP' && values.rut) {
        //  if (!(Fn.validaRut(values.rut)) && !cliente) { errors.rut = "Rut inválido 'xxxxxxxx-x'";}
        // }
        //   if(pais==='CLP' && values.rut && !formats.rutChile.test(values.rut)) errors.rut = "Rut inválido - el formato de RUT debe contener 10 caracteres 11111111-1";
        // if (pais === 'PEN' && values.rut && !formats.rutPeru.test(values.rut)) errors.rut = "Rut inválido  - el formato de RUT debe contener 11 caracteres 11111111111";
        // if (pais === 'COP' && values.rut && !formats.rutColombia.test(values.rut)) errors.rut = "Rut inválido - el formato de RUT debe contener 11 caracteres 111111111-1";
        if (!values.direccion) errors.direccion = "direccion requerido";
        if (values.extranjero === 0 && values.idcomuna < 1) errors.idcomuna = "comuna requerido";
        if (values.extranjero === 0 && values.idprovincia < 1) errors.idprovincia = "provincia requerido";
        if (values.id_tipoPersona === 2) {
            if (!values.nombre_rz) errors.nombre_rz = "Razon social requerido";
            if (!values.giro) errors.giro = "Giro requerido";
        }

        return errors;
    }

    const onSubmit = async (values, { resetForm }) => {
        setLoading(true);

        const { comuna: nombreComuna } = comunas.find(comuna => comuna.id === values.idcomuna) || '';
        const client = {
            nombre: values.nombre,
            observacion: values.observacion,
            nombre_rz: values.nombre_rz,
            giro: values.giro,
            direccion: values.direccion,
            idcomuna: values.idcomuna,
            idprovincia: values.idprovincia,
            id_tipoPersona: values.id_tipoPersona,
            rut: values.rut,
            extranjero: values.extranjero,
            doc_iden: values.doc_iden,
            id_empresa: SessionEmpresa.id,
            comuna: nombreComuna,
            ciudad: nombreComuna
        }

        if (cliente) {
            //editar
            await clientesService.update(client, values.id).then((data) => {
                if (data.status === 200) {
                    onListClientes && onListClientes();
                    closeModal();
                }
            })
        } else {
            //agregar
            await clientesService.register(client).then(async (data) => {
                if (data.status === 400 || data === false) {
                    setSnackBarConfig(prev => ({
                        ...prev,
                        open: true,
                        message: data?.error || 'El cliente ya ha sido registrado o el rut no es correcto.',
                    }));
                }
                if (data.status === 200) {
                    onListClientes && await onListClientes();
                    props.clientSelected && props.clientSelected({
                        "label": data.cliente?.nombre,
                        "key": data.cliente?.id,
                        "type": data.cliente?.id_tipoPersona,
                        "rut": data.cliente?.rut,
                    });
                    props.rutCliente && props.rutCliente(data.cliente?.rut)
                    closeModal();
                }
            })
        }

        setLoading(false);
    }

    return (
        <>
            <div className="container">
                {loading && <Loading />}

                <Snackbar
                    open={snackBarConfig.open}
                    autoHideDuration={snackBarConfig.autoHideDuration}
                    onClose={() => setSnackBarConfig(prev => ({ ...prev, open: false }))}
                    anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                >
                    <Alert
                        onClose={() => setSnackBarConfig(prev => ({ ...prev, open: false }))}
                        severity={snackBarConfig.severity}
                        variant="filled"
                    >
                        {snackBarConfig.message}
                    </Alert>
                </Snackbar>

                <Formik enableReinitialize initialValues={data} validate={(values) => validateForm(values)} onSubmit={onSubmit}>
                    {({ values, errors, touched, handleSubmit, handleChange, setFieldValue }) => {
                        return (
                            <Form onSubmit={handleSubmit}>
                                <Grid container spacing={3} alignItems="center" justifyContent="center">

                                    <Grid item xs={12} container style={{ display: "flex", alignItems: "center" }}>
                                        <Grid item xs={4}>
                                            <Typography variant="subtitle1" className="custom-input"><b>Nombre:</b></Typography>
                                        </Grid>
                                        <Grid item xs={8} style={{ display: 'flex', alignItems: "center" }}>
                                            {
                                                errors.nombre && (touched.nombre ? true : false) && (
                                                    <Tooltip title={errors.nombre}>
                                                        <PriorityHighIcon className={'iconWarning'} />
                                                    </Tooltip>
                                                )
                                            }
                                            <TextField
                                                id="nombre"
                                                type="text"
                                                name="nombre"
                                                fullWidth
                                                value={values.nombre}
                                                onChange={handleChange}
                                                placeholder="Nombre del Usuario"
                                                error={errors.nombre && touched.nombre ? true : false}
                                                disabled={!editMode}
                                            />
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={12} container style={{ display: "flex", alignItems: "center" }}>
                                        <Grid item xs={4}>
                                            <Typography variant="subtitle1" className="custom-input"><b>Extranjero:</b></Typography>
                                        </Grid>
                                        <Grid item xs={8} style={{ display: 'flex', alignItems: "center" }}>
                                            <RadioGroup
                                                aria-labelledby="demo-radio-buttons-group-label"
                                                defaultValue="female"
                                                name="radio-buttons-group"
                                                row
                                            >
                                                <FormControlLabel control={<Radio />} label="No" value={0} checked={values.extranjero === 0} onChange={() => setFieldValue('extranjero', 0)} />
                                                <FormControlLabel control={<Radio />} label="Si" value={1} checked={values.extranjero === 1} onChange={() => setFieldValue('extranjero', 1)} />
                                            </RadioGroup>
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={12} container style={{ display: "flex", alignItems: "center" }}>
                                        <Grid item xs={4}>
                                            <Typography variant="subtitle1" className="custom-input"><b>{values.extranjero === 0 ? 'RUT:' : 'Doc. Ident.:'}</b></Typography>
                                        </Grid>
                                        <Grid item xs={8} style={{ display: 'flex', alignItems: "center" }}>
                                            {
                                                errors.rut && (touched.rut ? true : false) && (
                                                    <Tooltip title={errors.rut}>
                                                        <PriorityHighIcon className={'iconWarning'} />
                                                    </Tooltip>
                                                )
                                            }
                                            {values.extranjero === 0
                                                ?
                                                <TextField
                                                    id="rut"
                                                    type="text"
                                                    name="rut"
                                                    fullWidth
                                                    value={values.rut}
                                                    onChange={handleChange}
                                                    placeholder={pais && (pais === 'PEN' ? 'XXXXXXXXXXX' : (pais === 'CLP' ? 'xxxxxxxx-x' : 'xxxxxxxxx-x'))}
                                                    error={errors.rut && touched.rut ? true : false}
                                                    disabled={!editMode}
                                                    InputProps={{
                                                        inputProps: { maxLength: 10 },
                                                    }}
                                                />
                                                :
                                                <TextField
                                                    id="doc_iden"
                                                    type="text"
                                                    name="doc_iden"
                                                    fullWidth
                                                    value={values.doc_iden}
                                                    onChange={handleChange}
                                                    error={errors.doc_iden && touched.doc_iden ? true : false}
                                                    disabled={!editMode}
                                                    InputProps={{
                                                        inputProps: { maxLength: 15 },
                                                    }}
                                                />
                                            }

                                        </Grid>
                                    </Grid>
                                    <Grid container item xs={12} style={{ display: "flex", alignItems: "center" }}>
                                        <Grid item xs={4}>
                                            <Typography variant="subtitle1"><b>Tipo Cliente:</b> </Typography>
                                        </Grid>
                                        <Grid item xs={8}>
                                            <Select
                                                id="id_tipoPersona"
                                                value={values.id_tipoPersona}
                                                onChange={(event) => {
                                                    setFieldValue("id_tipoPersona", event.target.value);
                                                }}
                                                fullWidth
                                                disabled={!editMode}
                                            >
                                                <MenuItem value={1}>Natural</MenuItem>
                                                <MenuItem value={2}>Júridica</MenuItem>

                                            </Select>
                                        </Grid>
                                    </Grid>

                                    {
                                        values.id_tipoPersona === 2 && (
                                            <>
                                                <Grid item xs={12} container style={{ display: "flex", alignItems: "center" }}>
                                                    <Grid item xs={4}>
                                                        <Typography variant="subtitle1" className="custom-input"><b>Razon Social:</b></Typography>
                                                    </Grid>
                                                    <Grid item xs={8} style={{ display: 'flex', alignItems: "center" }}>
                                                        {
                                                            errors.nombre_rz && (touched.nombre_rz ? true : false) && (
                                                                <Tooltip title={errors.nombre}>
                                                                    <PriorityHighIcon className={'iconWarning'} />
                                                                </Tooltip>
                                                            )
                                                        }
                                                        <TextField
                                                            id="nombre_rz"
                                                            type="text"
                                                            name="nombre_rz"
                                                            fullWidth
                                                            value={values.nombre_rz}
                                                            onChange={handleChange}
                                                            placeholder="Razon Social"
                                                            error={errors.nombre_rz && touched.nombre_rz ? true : false}
                                                            disabled={!editMode}
                                                        />
                                                    </Grid>
                                                </Grid>

                                                <Grid item xs={12} container style={{ display: "flex", alignItems: "center" }}>
                                                    <Grid item xs={4}>
                                                        <Typography variant="subtitle1" className="custom-input"><b>Giro:</b></Typography>
                                                    </Grid>
                                                    <Grid item xs={8} style={{ display: 'flex', alignItems: "center" }}>
                                                        {
                                                            errors.giro && (touched.giro ? true : false) && (
                                                                <Tooltip title={errors.giro}>
                                                                    <PriorityHighIcon className={'iconWarning'} />
                                                                </Tooltip>
                                                            )
                                                        }
                                                        <TextField
                                                            id="giro"
                                                            type="text"
                                                            name="giro"
                                                            fullWidth
                                                            value={values.giro}
                                                            onChange={handleChange}
                                                            placeholder="Giro"
                                                            error={errors.giro && touched.giro ? true : false}
                                                            disabled={!editMode}
                                                        />
                                                    </Grid>
                                                </Grid>
                                            </>
                                        )
                                    }

                                    <Grid item xs={12} container style={{ display: "flex", alignItems: "center" }}>
                                        <Grid item xs={4}>
                                            <Typography variant="subtitle1" className="custom-input"><b>Dirección:</b></Typography>
                                        </Grid>
                                        <Grid item xs={8} style={{ display: 'flex', alignItems: "center" }}>
                                            {
                                                errors.direccion && (touched.direccion ? true : false) && (
                                                    <Tooltip title={errors.direccion}>
                                                        <PriorityHighIcon className={'iconWarning'} />
                                                    </Tooltip>
                                                )
                                            }
                                            <TextField
                                                id="direccion"
                                                type="text"
                                                name="direccion"
                                                fullWidth
                                                value={values.direccion}
                                                onChange={handleChange}
                                                placeholder="Dirección"
                                                error={errors.direccion && touched.direccion ? true : false}
                                                disabled={!editMode}
                                            />
                                        </Grid>
                                    </Grid>

                                    {(values.extranjero === 0 &&
                                        <>
                                            <Grid item xs={12} container style={{ display: "flex", alignItems: "center" }}>
                                                <Grid item xs={4}>
                                                    <Typography variant="subtitle1" className="custom-input"><b>Provincia:</b></Typography>
                                                </Grid>
                                                <Grid item xs={8} style={{ display: 'flex', alignItems: "center" }}>
                                                    <Autocomplete
                                                        key='cbx-provincia'
                                                        id="cbx-provincia"
                                                        size="small"
                                                        value={provinciaSelected || null}
                                                        options={loading ? [] : provincias}
                                                        getOptionLabel={(option) => option?.provincia || ''}
                                                        isOptionEqualToValue={(option, value) => option.id === value?.id}
                                                        renderInput={(params) =>
                                                            <TextField {...params}
                                                                label=""
                                                                size="small"
                                                                InputProps={{
                                                                    ...params.InputProps,
                                                                    endAdornment: (
                                                                        <React.Fragment>
                                                                            {loading ? <CircularProgress color="inherit" size={20} /> : null}
                                                                            {params.InputProps.endAdornment}
                                                                        </React.Fragment>
                                                                    ),
                                                                }}
                                                                error={errors.idprovincia && touched.idprovincia ? true : false}
                                                            />}
                                                        onChange={(e, newValue) => {
                                                            setProvinciaSelected(newValue)
                                                            setFieldValue('idprovincia', newValue?.id || 0)
                                                            if (newValue == null) setComunaSelected(null)
                                                        }}
                                                        fullWidth
                                                        readOnly={!editMode}
                                                    />
                                                </Grid>
                                            </Grid>

                                            <Grid item xs={12} container style={{ display: "flex", alignItems: "center" }}>
                                                <Grid item xs={4}>
                                                    <Typography variant="subtitle1" className="custom-input"><b>Comuna:</b></Typography>
                                                </Grid>
                                                <Grid item xs={8} style={{ display: 'flex', alignItems: "center" }}>
                                                    <Autocomplete
                                                        key='cbx-comuna'
                                                        id="cbx-comuna"
                                                        size="small"
                                                        value={comunaSelected || null}
                                                        options={loading ? [] : comunas}
                                                        getOptionLabel={(option) => option?.comuna || ''}
                                                        isOptionEqualToValue={(option, value) => option.id === value?.id}
                                                        renderInput={(params) =>
                                                            <TextField {...params}
                                                                label=""
                                                                size="small"
                                                                InputProps={{
                                                                    ...params.InputProps,
                                                                    endAdornment: (
                                                                        <React.Fragment>
                                                                            {loading ? <CircularProgress color="inherit" size={20} /> : null}
                                                                            {params.InputProps.endAdornment}
                                                                        </React.Fragment>
                                                                    ),
                                                                }}
                                                                error={errors.idcomuna && touched.idcomuna ? true : false}
                                                            />}
                                                        onChange={(e, newValue) => {
                                                            setComunaSelected(newValue)
                                                            setFieldValue('idcomuna', newValue?.id || 0)
                                                        }}
                                                        fullWidth
                                                        readOnly={!editMode}
                                                        noOptionsText={!comunaSelected ? "Seleccione una provincia" : "Sin opciones"}
                                                    />
                                                </Grid>
                                            </Grid>
                                        </>
                                    )}

                                    <Grid item xs={12} container style={{ display: "flex", alignItems: "center" }}>
                                        <Grid item xs={4}>
                                            <Typography variant="subtitle1" className="custom-input"><b>Observación:</b></Typography>
                                        </Grid>
                                        <Grid item xs={8} style={{ display: 'flex', alignItems: "center" }}>
                                            <TextField
                                                id="observacion"
                                                type="text"
                                                name="observacion"
                                                fullWidth
                                                value={values.observacion}
                                                onChange={handleChange}
                                                placeholder="Observacion"
                                                disabled={!editMode}
                                            />
                                        </Grid>
                                    </Grid>

                                    {
                                        (cliente && !editMode) && (
                                            <Grid item xs={12} container style={{ display: "flex", alignItems: "center" }}>
                                                <Grid item xs={4}>
                                                    <Typography variant="subtitle1" className="custom-input"><b>Estado:</b></Typography>
                                                </Grid>
                                                <Grid item xs={8}>
                                                    <TextField
                                                        id="state"
                                                        type="text"
                                                        name="state"
                                                        fullWidth
                                                        value={cliente.state === 1 ? 'Habilitado' : 'Desabilitado'}
                                                        onChange={handleChange}
                                                        placeholder="Estado"
                                                        disabled
                                                    />
                                                </Grid>
                                            </Grid>
                                        )
                                    }

                                </Grid>
                                <Divider sx={{ my: 2 }} />

                                {
                                    editMode && (
                                        <div className="form-group mb-3 buton_container_modal">
                                            <button type="submit" className="btn btn-primary">
                                                {cliente ? `Editar Cliente` : `Agregar Cliente`}
                                            </button>
                                        </div>
                                    )
                                }
                            </Form>
                        )
                    }}
                </Formik>
            </div>

        </>
    )
}

