import { API_URL_NUPY } from '@/toolbox/defaults/app';
import axios from 'axios';
import { http } from '../http/http';
// import { API_URL_BASE, API_URL_NUPY } from '@/toolbox/defaults/app';

export const productoRepository = {

  register: async (data: {}): Promise<any> => {
    const res = await axios.post(`${API_URL_NUPY}/productos/add_producto`, data);
    return res.data;
  },

  update: async (id: number, data: {}): Promise<any> => {
    const res = await axios.patch(`${API_URL_NUPY}/productos/update_producto/${id}`, data);
    return res.data;
  },

  validateProductsSofnet: async (): Promise<any> => {
    const res = await axios.post(`${API_URL_NUPY}/productos/syncProducts`);
    return res.data;
  },

  syncFamiliaProducts: async (): Promise<any> => {
    const res = await axios.post(`${API_URL_NUPY}/productos/syncFamiliaProducts`);
    return res.data;
  },

  syncSubFamiliaProducts: async (): Promise<any> => {
    const res = await axios.post(`${API_URL_NUPY}/productos/syncSubFamiliaProducts`);
    return res.data;
  },

  syncLineaProducts: async (): Promise<any> => {
    const res = await axios.post(`${API_URL_NUPY}/productos/syncLineaProducts`);
    return res.data;
  },

  getAllProducts: async (): Promise<any> => {
    const resp = await http.get<any>(`https://api2.softnet.cl/producto`,)
    return resp.data;
  },

  getProductsByCompany: async (idCompany: number): Promise<any> => {
    const res = await axios.get(`${API_URL_NUPY}/productos/get_productosE/${idCompany}`);
    return res.data;
  },

  getSimplesWithStock: async (idCompany: number): Promise<any> => {
    const res = await axios.get(`${API_URL_NUPY}/productos/getSimplesWithStock/${idCompany}`);
    return res.data;
  },

  getProductsByCodigo: async (codigo: number): Promise<any> => {
    const res = await axios.get(`${API_URL_NUPY}/productos/get_producto_codigo/${codigo}`);
    return res.data;
  },

  getProductosSimplesCompany: async (idCompany: number): Promise<any> => {
    const res = await axios.get(`${API_URL_NUPY}/productos/productos_simples_empresa/${idCompany}`);
    return res.data;
  },

  getProductosNoInsumo: async (idCompany: number): Promise<any> => {
    const res = await axios.get(`${API_URL_NUPY}/productos/productos_noInsumo_empresa/${idCompany}`);
    return res.data;
  },

  getProductosInsumo: async (idCompany: number): Promise<any> => {
    const res = await axios.get(`${API_URL_NUPY}/productos/productos_insumo_empresa/${idCompany}`);
    return res.data;
  },

  getProductosBalanza: async (): Promise<any> => {
    const res = await axios.get(`${API_URL_NUPY}/productos/productos_balanza`);
    return res.data;
  },

  getProductosNokitchen: async (): Promise<any> => {
    const res = await axios.get(`${API_URL_NUPY}/productos/getProductosNokitchen`);
    return res.data;
  },

  searchProductsByFilters: async (term1: string = "", term2: string = "", term3: string = ""): Promise<any> => {
    const res = await axios.get(`${API_URL_NUPY}/productos/searchProductsByFilters?term1=${term1}&term2=${term2}&term3=${term3}`);
    return res.data;
  },

  getFamiliesProducts: async (): Promise<any> => {
    const res = await axios.get(`${API_URL_NUPY}/productos/getFamiliesProducts`);
    return res.data;
  },

  getSubFamiliesByIdFamilia: async (idFamily: number): Promise<any> => {
    const res = await axios.get(`${API_URL_NUPY}/productos/getSubFamiliesByIdFamilia/${idFamily}`);
    return res.data;
  },

  getLinesProducts: async (): Promise<any> => {
    const res = await axios.get(`${API_URL_NUPY}/productos/getLinesProducts`);
    return res.data;
  },

  getFamiliesAndSubFamilies: async (): Promise<any> => {
    const res = await axios.get(`${API_URL_NUPY}/productos/getFamiliesAndSubFamilies`);
    return res.data;
  },

  downloadTemplateImport: async (): Promise<any> => {
    const res = await axios.get(`${API_URL_NUPY}/productos/downloadTemplateImport`);
    return res;
  },

  downloadInsumoTemplateImport: async (): Promise<any> => {
    const res = await axios.get(`${API_URL_NUPY}/productos/downloadInsumoTemplateImport`);
    return res;
  },

  //REPORTES
  getProductsSimplesByMonth: async (fecha: any): Promise<any> => {
    const res = await axios.get(`${API_URL_NUPY}/productos/list-simples-mes/${fecha}`);
    return res.data;
  },
  getProductsSimplesByDay: async (fecha: any): Promise<any> => {
    const res = await axios.get(`${API_URL_NUPY}/productos/list-simples-day/${fecha}`);
    return res.data;
  },
  getProductsSimplesByDFechas: async (fecha_de: any, fecha_a: any): Promise<any> => {
    const res = await axios.get(`${API_URL_NUPY}/productos/list-simples-fechas/${fecha_de}/${fecha_a}`);
    return res.data;
  },

  getProductsSimplesByDFechasByResponsable: async (fecha_de: any, fecha_a: any, filtro: string, id_responsable: number): Promise<any> => {
    const res = await axios.get(`${API_URL_NUPY}/productos/list-simples-fechas-by-responsable/${fecha_de}/${fecha_a}/${filtro}/${id_responsable}`);
    return res.data;
  },

  getProductsMenuByMonth: async (fecha: any): Promise<any> => {
    const res = await axios.get(`${API_URL_NUPY}/productos/list-menu-mes/${fecha}`);
    return res.data;
  },
  getProductsMenuByDay: async (fecha: any): Promise<any> => {
    const res = await axios.get(`${API_URL_NUPY}/productos/list-menu-day/${fecha}`);
    return res.data;
  },
  getProductsMenuByDFechas: async (fecha_de: any, fecha_a: any): Promise<any> => {
    const res = await axios.get(`${API_URL_NUPY}/productos/list-menu-fechas/${fecha_de}/${fecha_a}`);
    return res.data;
  },
  getProductsMenuByFechasByResponsable: async (fecha_de: any, fecha_a: any, filtro: string, id_responsable: number): Promise<any> => {
    const res = await axios.get(`${API_URL_NUPY}/productos/list-menu-fechas-by-responsable/${fecha_de}/${fecha_a}/${filtro}/${id_responsable}`);
    return res.data;
  },

  getProductsAgrupadoByMonth: async (fecha: any): Promise<any> => {
    const res = await axios.get(`${API_URL_NUPY}/productos/list-agrupado-mes/${fecha}`);
    return res.data;
  },
  getProductsAgrupadoByDay: async (fecha: any): Promise<any> => {
    const res = await axios.get(`${API_URL_NUPY}/productos/list-agrupado-day/${fecha}`);
    return res.data;
  },
  getProductsAgrupadoByDFechas: async (fecha_de: any, fecha_a: any): Promise<any> => {
    const res = await axios.get(`${API_URL_NUPY}/productos/list-agrupado-fechas/${fecha_de}/${fecha_a}`);
    return res.data;
  },

  getProductsAgrupadoByFechasByResponsable: async (fecha_de: any, fecha_a: any, filtro: string, id_responsable: number): Promise<any> => {
    const res = await axios.get(`${API_URL_NUPY}/productos/list-agrupado-fechas-by-responsable/${fecha_de}/${fecha_a}/${filtro}/${id_responsable}`);
    return res.data;
  },
  getPedidosProductos: async (payload?: {}): Promise<any> => {
    const res = await axios.get(`${API_URL_NUPY}/productos/get_pedidos_productos`, { params: payload });
    return res.data;
  },
  getPedidosProductosByCocina: async (idCocina: number): Promise<any> => {
    const res = await axios.get(`${API_URL_NUPY}/productos/get_pedidos_productos_byCocina/${idCocina}`);
    return res.data;
  },
  //DONAS DASHBOARD
  getTypeProductsByMes: async (fecha: any): Promise<any> => {
    const res = await axios.get(`${API_URL_NUPY}/productos/list-typeProducts-mes/${fecha}`);
    return res.data;
  },
  getTypeProductsByDay: async (fecha: any): Promise<any> => {
    const res = await axios.get(`${API_URL_NUPY}/productos/list-typeProducts-day/${fecha}`);
    return res.data;
  },
  getTypeProductsByFechas: async (fecha_de: any, fecha_a: any): Promise<any> => {
    const res = await axios.get(`${API_URL_NUPY}/productos/list-typeProducts-fechas/${fecha_de}/${fecha_a}`);
    return res.data;
  },
  //BARRAS DASHBOARD
  topProductsMoreSelledMes: async (fecha: any): Promise<any> => {
    const res = await axios.get(`${API_URL_NUPY}/productos/list-topProducts-mes/${fecha}`);
    return res.data;
  },
  topProductsMoreSelledDay: async (fecha: any): Promise<any> => {
    const res = await axios.get(`${API_URL_NUPY}/productos/list-topProducts-day/${fecha}`);
    return res.data;
  },
  topProductsMoreSelledFechas: async (fecha_de: any, fecha_a: any): Promise<any> => {
    const res = await axios.get(`${API_URL_NUPY}/productos/list-topProducts-fechas/${fecha_de}/${fecha_a}`);
    return res.data;
  },

  //top product Simples
  topProductSimpleMes: async (fecha: any): Promise<any> => {
    const res = await axios.get(`${API_URL_NUPY}/productos/topProductSimple-mes/${fecha}`);
    return res.data;
  },
  topProductSimpleDay: async (fecha: any): Promise<any> => {
    const res = await axios.get(`${API_URL_NUPY}/productos/topProductSimple-day/${fecha}`);
    return res.data;
  },
  topProductSimpleFechas: async (fecha_de: any, fecha_a: any): Promise<any> => {
    const res = await axios.get(`${API_URL_NUPY}/productos/topProductSimple-fechas/${fecha_de}/${fecha_a}`);
    return res.data;
  },

  //top product Agrupado
  topProductAgrupadoMes: async (fecha: any): Promise<any> => {
    const res = await axios.get(`${API_URL_NUPY}/productos/topProductAgrupado-mes/${fecha}`);
    return res.data;
  },
  topProductAgrupadoDay: async (fecha: any): Promise<any> => {
    const res = await axios.get(`${API_URL_NUPY}/productos/topProductAgrupado-day/${fecha}`);
    return res.data;
  },
  topProductAgrupadoFechas: async (fecha_de: any, fecha_a: any): Promise<any> => {
    const res = await axios.get(`${API_URL_NUPY}/productos/topProductAgrupado-fechas/${fecha_de}/${fecha_a}`);
    return res.data;
  },

  //top product Menu
  topProductMenuMes: async (fecha: any): Promise<any> => {
    const res = await axios.get(`${API_URL_NUPY}/productos/topProductMenu-mes/${fecha}`);
    return res.data;
  },
  topProductMenuDay: async (fecha: any): Promise<any> => {
    const res = await axios.get(`${API_URL_NUPY}/productos/topProductMenu-day/${fecha}`);
    return res.data;
  },
  topProductMenuFechas: async (fecha_de: any, fecha_a: any): Promise<any> => {
    const res = await axios.get(`${API_URL_NUPY}/productos/topProductMenu-fechas/${fecha_de}/${fecha_a}`);
    return res.data;
  },

  //productos mas vendidos por agrupado
  topProductsByAgrupadoMes: async (fecha: any): Promise<any> => {
    const res = await axios.get(`${API_URL_NUPY}/productos/topProductsByAgrupado-mes/${fecha}`);
    return res.data;
  },
  topProductsByAgrupadoDay: async (fecha: any): Promise<any> => {
    const res = await axios.get(`${API_URL_NUPY}/productos/topProductsByAgrupado-day/${fecha}`);
    return res.data;
  },
  topProductsByAgrupadoFechas: async (fecha_de: any, fecha_a: any): Promise<any> => {
    const res = await axios.get(`${API_URL_NUPY}/productos/topProductsByAgrupado-fechas/${fecha_de}/${fecha_a}`);
    return res.data;
  },

  //productos mas vendidos por menu
  topProductsByMenuMes: async (fecha: any): Promise<any> => {
    const res = await axios.get(`${API_URL_NUPY}/productos/topProductsByMenu-mes/${fecha}`);
    return res.data;
  },
  topProductsByMenuDay: async (fecha: any): Promise<any> => {
    const res = await axios.get(`${API_URL_NUPY}/productos/topProductsByMenu-day/${fecha}`);
    return res.data;
  },
  topProductsByMenuFechas: async (fecha_de: any, fecha_a: any): Promise<any> => {
    const res = await axios.get(`${API_URL_NUPY}/productos/topProductsByMenu-fechas/${fecha_de}/${fecha_a}`);
    return res.data;
  },
  import: async (data: FormData): Promise<any> => {
    const res = await axios.post(`${API_URL_NUPY}/productos/import`, data);
    return res.data;
  },
  importInsumo: async (data: FormData): Promise<any> => {
    const res = await axios.post(`${API_URL_NUPY}/productos/insumo/import`, data);
    return res.data;
  },

  getInsumosVenta: async (payload?: {}): Promise<any> => {
    const res = await axios.get(`${API_URL_NUPY}/productos/getInsumosVenta`, { params: payload });
    return res.data;
  },
}
