import { FunctionComponent } from "react";
import TableFooter from '@mui/material/TableFooter';
import React, { useState, useEffect } from "react";
import { Autocomplete, Button, Grid, Input, Pagination, TextField, cardHeaderClasses } from "@mui/material";
import { Protected } from "@/components/layout/Protected";
import { Pagos } from './components/Pagos'
import { editor } from "../../service/services/Editor.service";
import { KEY_USER_DATA, TOKEN_API } from "@/toolbox/constants/local-storage";
import { useLocalStorage } from '@toolbox/hooks/local-storage.hook';
import { Popup } from '../Editor/components/Popup/Popup';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { Loading } from '../../components/common/Loading/Loading';
import Tabs from '@mui/material/Tabs';
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import { styled } from '@mui/material/styles';
import Divider from '@mui/material/Divider';
import TablePagination from '@mui/material/TablePagination';
import IconButton from '@mui/material/IconButton';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { useForm } from 'react-hook-form';
import RemoveShoppingCartIcon from '@mui/icons-material/RemoveShoppingCart';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import { ROUTE_CUADRATURAS, ROUTE_PEDIDOS } from "@/toolbox/constants/route-map";
import { useHistory } from 'react-router-dom';
import { ID_TYPE_RESTAURANTE } from '@constants/type-entity';
import {
    PRODUCTS_CARD, KEY_EDIT_PEDIDO, KEY_EMPRESA
} from '@constants/local-storage';
import {
    readLocalStorage,
    saveLocalStorage
} from '@helpers/local-storage-helper';
import "./Home.sass";
import { productoService } from "@/service/services/Producto.service";
import { ModalConfirm } from "../Compras/components/ModalConfirm";
import { Snackbar, Alert } from '@mui/material';
import { ProductoBalanza } from "./components/ProductsBalanza/ProductoBalanza";
import { listaPrecioService } from '@/service/services/ListaPrecio.service';
import { getNamePorductByListGeneral, validatePrice, getPricePorductByListGeneral, getPricePorductByCarrito, getStockPorductByListGeneral } from './ValidateDataTableGeneral';
import { ValidateCupon } from "./components/ValidateCupon";
import { ValidatePuntos } from "./components/ValidatePuntos";
import { HomePersonalized } from "../HomePersonalized";
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import { HomePersonalized2 } from "../HomePersonalized/HomePersonalized2";
import { PopupComponent } from "@/components/common/Popup";
import { PagarPedido } from "../Caja/Pagar";
import { SalePDF } from "@/components/common/SalePDF";
import moment from "moment";
import { ticketService } from "@/service/services/Ticket.service";
import { STATUS_FINALIZADO } from '@constants/status-ticket';
import { ROLE_ADMIN, ROLE_CAJERO } from "@/toolbox/defaults/static-roles";
import { cuadraturaService } from "@/service/services/Cuadratura.service";
import InputAdornment from '@mui/material/InputAdornment';
import { set } from "date-fns";
import { moneyFormatInt } from "@/toolbox/helpers/money.helper";
import { empresasService } from "@/service/services/Empresas.service";
import { ComandaService } from "@/service/services/Comanda.service";

interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
}

function TabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <Typography variant="h6">{children}</Typography>
                </Box>
            )}
        </div>
    );
}

const StyledToggleButton = styled(ToggleButton)(({ theme }) => ({
    textTransform: 'none',
    '&.MuiToggleButton-root': {
        borderColor: '#0044D0',
        boxShadow: '0 0 0 0.1rem rgba(0,123,255,.5)',
        height: '40px',

        marginRight: '15px',
    },
    '&.Mui-selected': {
        backgroundColor: '#ABC1CC',
        borderColor: '#0062cc',
        boxShadow: '0 0 0 0.1rem rgba(0,87,118)',
        color: 'black',
        '&:hover': {
            background: '#ABC1CC',
        },
    },
}));

const StyledToggleButtonGroup = styled(ToggleButtonGroup)(({ theme }) => ({
    '& .MuiToggleButtonGroup-grouped': {
        // margin: theme.spacing(),
        border: 0,
        borderRadius: 0,
    },
}));

const StyledTab = styled(Tab)(({ theme }) => ({
    textTransform: 'none',
}));


export const HomeV2: FunctionComponent = (props: any) => {

    const history = useHistory();
    // eslint-disable-next-line
    const { register, handleSubmit, formState: { errors }, getValues, setValue: setValueUseForm } = useForm();
    const [value, setValue] = React.useState(0);
    const [modulo, setModulo] = React.useState([]);
    const [submodulo, setSubModulo] = React.useState([]);
    const [submoduloActual, setSubModuloActual] = React.useState([]);
    const [productosActual, setProductosActual] = React.useState(-1);
    const [items, setItems] = useState<Array<any>>([]);
    const [itemsMenu, setItemsMenu] = React.useState([]);
    const [productosItemsMenu, setproductosItemsMenu] = React.useState([]);
    const [alignment, setAlignment] = React.useState('');
    // eslint-disable-next-line
    const [submoduloSelect, setSubmoduloSelect] = React.useState([]);
    const [openPopup, setOpenPopup] = React.useState(false);
    const [popUp, setPopUp] = React.useState<String>('');
    const [itemMenuSelect, setItemMenuSelect] = React.useState<any>({});
    const [nombreProductSelect, setNombreProductSelect] = React.useState<String>('');

    const [cardState, setCardState] = useState([]);
    const [isLoaded, setIsLoaded] = useState(false);
    const [products, setProducts] = useState<Array<any>>([]);
    const [productsShop, setProductsShop] = useState<Array<any>>([]);
    const [textSearch, setTextSearch] = useState<string>("");
    const [filter, setFilter] = useState<Array<any>>([]);
    //const [open, setOpen] = React.useState(false);
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(16);
    const [largo, setLargo] = React.useState(0);
    const [PA, setPA] = React.useState([]);
    const [filteredProducts, setFilteredProducts] = React.useState([]);
    const [useFilters, setUseFilters] = useState(false);
    const [checked, setChecked] = React.useState(false);
    const [indexChecked, setIndexChecked] = React.useState(-1);
    const [arrayChecked, setArrayChecked] = useState<Array<Boolean>>([]);

    // eslint-disable-next-line
    const [showBarras, setShowBarras] = React.useState<boolean>(false);
    const [codigoBarras, setCodigoBarras] = React.useState<any>('');
    const [showModalConfirm, setShowModalConfirm] = React.useState(false);

    const [token] = useLocalStorage(TOKEN_API, "");
    const [editPedido] = useLocalStorage(KEY_EDIT_PEDIDO, []);
    const [card_shop] = useLocalStorage(PRODUCTS_CARD, []);
    // const [beneficioProducto] = useLocalStorage(KEY_BENEFICIO_PRO, []);
    const [dataEmpresa] = useLocalStorage(KEY_EMPRESA, {
        id: 0, id_tipo_empresa: 0, equivalencia_puntos: 0,
        id_country: 0, country_name: "", country_abbreviation: "", id_sucursal: 0
    });

    const [searchSubmodule, setSearchSubmodule] = useState<string>('')
    const [lineSelectedSub, setLineSelectedSub] = React.useState<any>("");
    const [familySelectedSub, setFamilySelectedSub] = React.useState<any>("");

    const [snackBarConfig, setSnackBarConfig] = useState<any>({
        open: false,
        severity: 'error',
        message: 'Error',
        autoHideDuration: 3000,
    })
    const dataUsuario = readLocalStorage(KEY_USER_DATA);
    const role = dataUsuario?.user?.rol;
    const rutCompany = dataUsuario?.user?.rut_empresa;
    const id_usuario = dataUsuario.user.id_usuario;

    const [estadoCuadratura, setEstadoCuadratura] = React.useState<number>(0);
    const [totalCardEdit, setTotalCardEdit] = useState<number>(0);
    const [detallesPedidoEdit, setDetallesPedidoEdit] = useState<any[]>([])


    //lsta precio
    const [listaPrecio, setListaPrecio] = React.useState([]);

    const [dataFamilies, setDataFamilies] = React.useState<Array<any>>([]);
    const [dataLines, setDataLines] = React.useState<Array<any>>([]);
    const [productosSubmodulo, setProductosSubmodulo] = useState<any>([])

    useEffect(() => {
        if (showBarras) {
            let inputT = document.getElementById("mi-codigo-de-barras");
            inputT && inputT.focus();
        }
    }, [showBarras])

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeChecked = (index) => {
        if (arrayChecked[index]) {
            arrayChecked[index] = false;
            setArrayChecked(arrayChecked);
        } else {
            arrayChecked[index] = true;
            setArrayChecked(arrayChecked);
        }
        // setChecked((prev) => !prev);
    };

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const handleChangeToggleButton = (
        event: React.MouseEvent<HTMLElement>,
        newAlignment: string,
    ) => {
        if (newAlignment !== null) {
            setAlignment(newAlignment);
        }
    };

    const onSubmit = (data) => {
        let arrayValues = [];
        for (const property in data) {
            let indexObj = property.split('-');
            let index = Number(indexObj[0] || 0)
            arrayValues[index] = (arrayValues[index] || 0) + Number(data[property] || 0)
        }
        for (let i = 0; i < itemsMenu.length; i++) {
            if (itemsMenu[i].cantidad < arrayValues[i]) {
                setSnackBarConfig(prev => ({
                    ...prev,
                    open: true,
                    message: "La cantidad de productos seleccionados es mayor a la permitida",
                }));
                return;
            } else if (itemsMenu[i].cantidad > arrayValues[i]) {
                setSnackBarConfig(prev => ({
                    ...prev,
                    open: true,
                    message: "La cantidad de productos seleccionados es menor a la requerida",
                }));
                return;
            }
        }


        let arraySubProductos = [];
        for (const property in data) {
            let indexObj = property.split('-');
            let producto_id = Number(indexObj[2] || 0)
            let cantidad = Number(data[property] || 0)
            if (cantidad > 0) {
                arraySubProductos.push({
                    producto_id,
                    cantidad
                })
            }
        };

        if (itemMenuSelect) {
            itemMenuSelect.subProductos = arraySubProductos;
            itemMenuSelect.cantidad = 1;
            // alert("SELECCION UN ITEM")
            handleSelectProduct(itemMenuSelect);
            setItemMenuSelect(null);
            setOpenPopup(false);

            // setCardState([...card])
            // setLargo(cardState.length);
            // saveLocalStorage(PRODUCTS_CARD, card);
        }
        // setCardState([]);
    }

    const arrayProducts = [];

    const getProductosListaPrecio = async (id_empresa) => {
        await listaPrecioService.getListaPrecioByUsuario(id_empresa).then((data) => {
            if (data.status === 200) {
                setListaPrecio(data.data);
            }
        });
    }

    const getProductosEmpresa = async (id_empresa) => {
        await productoService.getProductsByCompany(id_empresa).then((data) => {
            if (data.status === 200) {
                setProductsShop(data.productos);
                setProducts(data.productos)
                setFilter(data.productos)
            }
        });
    }

    const getFamilias = async () => {
        const resp = await productoService.getFamiliesProducts();
        setDataFamilies(resp?.families || [])
    }

    const getLineas = async () => {
        const resp = await productoService.getLinesProducts();
        setDataLines(resp?.lines || [])
    }

    useEffect(() => {
        getFamilias()
        getLineas()
        getProductosListaPrecio(dataUsuario.user.id_usuario);
        getProductosEmpresa(dataEmpresa.id);
        setIsLoaded(true);
        evaluarEstadoCuadratura()
        // fetch(`https://api2.softnet.cl/producto`, {
        //     method: 'GET',
        //     headers: {
        //         'Access-Control-Allow-Origin': '*',
        //         'Content-Type': 'application/x-www-form-urlencoded',
        //         token: token,
        //     },
        // })
        //     .then(dataProducts => dataProducts.json())
        //     .then(async dataProducts => {
        //         // eslint-disable-next-line
        //         dataProducts.map((product) => {
        //             // if(product.stock > 0 && product.estado === 1){
        //             if (product.estado === 1) {
        //                 arrayProducts.push(product)
        //             }
        //         })
        //         setProducts(arrayProducts)
        //         setFilter(arrayProducts)

        //     })
        //     .catch(error => {
        //         setIsLoaded(false)
        //     })
        setCardState(card_shop);
        setLargo(card_shop.length);
        //SI ESTANDO EN EDITAR SE REFRESCA LA PESTAÑA LIMPIAR DATA
        if (!!editPedido) {
            localStorage.removeItem(KEY_EDIT_PEDIDO)
            saveLocalStorage(PRODUCTS_CARD, [])
            setCardState([])
        }
    }, [])

    useEffect(() => {
        const edit: any = editPedido
        setDetallesPedidoEdit(edit.detalle_ticket || [])
    }, [editPedido])

    useEffect(() => {
        cargarProductosEdit()
    }, [detallesPedidoEdit, productsShop])

    const cargarProductosEdit = () => {
        if (!detallesPedidoEdit && !productsShop.length) return
        detallesPedidoEdit.forEach(detalle => {
            const producto = productsShop.find(producto => producto.id === parseInt(detalle.id_producto))
            if (producto) handleSelectProduct(producto, parseInt(detalle.cantidad))
        })
    }


    const evaluarEstadoCuadratura = async () => {
        if (dataUsuario.user.rol === ROLE_ADMIN || dataUsuario.user.rol === ROLE_CAJERO) {
            const res = await cuadraturaService.getActualCuadratura(id_usuario)

            if (res?.status == 200) {
                const fechaApertura = moment(res?.cuadratura.fecha_apertura);
                const fechaCierre = res?.cuadratura.fecha_cierre ? moment(res?.cuadratura.fecha_cierre) : null;
                const hoy = moment();

                const aperturaDiferenteDeHoy = !fechaApertura.isSame(hoy, 'day');
                const cierreEsNulo = fechaCierre === null;

                if (aperturaDiferenteDeHoy && cierreEsNulo) {
                    setEstadoCuadratura(1) //pendiente de cierre
                }
            }

            if (res.status == 404) {
                setEstadoCuadratura(2) // pendiente de apertura
            }
        }
    }

    const handleSelectProductInit = async (product) => {
        if (product.receta.length > 0 && product.stock < 1) {
            setSnackBarConfig(prev => ({
                ...prev,
                open: true,
                message: "No hay suficiente stock",
            }))
            return
        }

        await productoService.getProductsByCodigo(product?.codigo_erp).then(async (data) => {
            if (data.status === 200) {
                // eslint-disable-next-line
                const empresaDatos = await empresasService.findCompany(dataEmpresa.id);
                if (empresaDatos.status == 200) {
                    (data.productos).map((producto) => {
                        if (producto?.id_tipo_producto !== 3) {
                            if (empresaDatos.company.stock_restriction == 1) {
                                if (product.stock <= 0) {
                                    setSnackBarConfig({
                                        ...snackBarConfig, open: true, severity: 'error',
                                        message: "No hay suficiente stock",
                                    })
                                } else {
                                    handleSelectProduct(producto);
                                }
                            } else {
                                handleSelectProduct(producto);
                            }
                        }
                    })
                }

            } else {
                handleSelectProduct(product);
            }
        })
    }
    const handleSelectProduct = async (product, defaultCantidad = 0) => {
        // listaPrecioByProducto(product.id);
        let card: Array<any> = cardState;
        let exits: boolean = false;
        let precio_unitario;
        let indiceExist;
        if (product?.id_tipo_producto !== 3) {
            for (let i = 0; i < card.length; i++) {
                if ((card[i].codigo === product?.codigo || card[i].codigo === product?.codigo_erp || card[i].codigo_erp === product?.codigo || card[i].codigo_erp === product?.codigo_erp) && card[i].nombre === product?.nombre) {
                    //DE MOMEMNTO SE COMENTA LA CONDICION PARA LOS QUE NO TIENEN STOCK
                    // if(card[i].cantidad < card[i].stock){
                    // card[i].cantidad = card[i].cantidad+1
                    // card[i].cantidad = card[i].cantidad ? card[i].cantidad+1 : 2
                    // }
                    // if(card[i].cantidad === card[i].stock){
                    //    setSnackBarConfig(prev => ({
                    //       ...prev,
                    //       open: true,
                    //       message: "no hay suficiente stock",
                    //    }));
                    // }
                    exits = true
                    indiceExist = i;
                }
                if (editPedido.length !== 0) {
                    if (card[i].codigo_erp === product?.codigo && card[i].nombre === product?.nombre) {
                        //DE MOMEMNTO SE COMENTA LA CONDICION PARA LOS QUE NO TIENEN STOCK
                        // if(card[i].cantidad < card[i].stock){
                        card[i].cantidad = card[i].cantidad ? parseInt(card[i].cantidad) + 1 : 1
                        // }
                        // if(card[i].cantidad === card[i].stock){
                        //    setSnackBarConfig(prev => ({
                        //       ...prev,
                        //       open: true,
                        //       message: "no hay suficiente stock",
                        //    }));
                        // }
                        exits = true
                    }
                }
            }
        }
        //MANDAR EL PRECIO UNITARIO
        if (product?.codigo) {
            if (product.precio_oferta !== "" && product.precio_oferta !== "0") {
                precio_unitario = product.precio_oferta;
            } else {
                precio_unitario = product.precio;
            }
        } else {
            precio_unitario = validatePrice(product, listaPrecio);
        }
        if (!exits) {
            card.push({
                ...product,
                cantidad: defaultCantidad > 0 ? defaultCantidad : 1,
                precio_unitario: precio_unitario ? precio_unitario : product?.precio,
                is_balanza: defaultCantidad > 0 ? true : false,
            })
        } else {

            card[indiceExist] = {
                ...card[indiceExist],
                cantidad: card[indiceExist].cantidad + 1
            }

        }
        setCardState([...card])
        setLargo(cardState.length);
        saveLocalStorage(PRODUCTS_CARD, card);
    }

    const handleLoadPago = (e) => {
        setCardState([]);
        saveLocalStorage(PRODUCTS_CARD, []);
        setLargo(0);
        if (localStorage.getItem(KEY_EDIT_PEDIDO) !== null && role !== 'Cliente') {
            localStorage.removeItem(KEY_EDIT_PEDIDO);
            history.push(ROUTE_PEDIDOS);
        }
    }

    const handleDismissProduct = (index: number) => {
        let card: Array<any> = cardState;
        if (!card[index]?.cantidad || card[index].cantidad === 1) {
            card.splice(index, 1);
        } else {
            card[index].cantidad = card[index].cantidad - 1
        }
        setCardState([...card])
        setLargo(cardState.length);
        saveLocalStorage(PRODUCTS_CARD, card);
    }
    const handleMoreProduct = (index: number) => {
        let card: Array<any> = cardState;
        if (!card[index].cantidad) {
            card[index].cantidad = 2;
        } else {
            card[index].cantidad = parseInt(card[index].cantidad);
            if (card[index].cantidad === card[index].stock) {
                setSnackBarConfig(prev => ({
                    ...prev,
                    open: true,
                    message: "no hay suficiente stock",
                }));
            } else {
                card[index].cantidad = card[index].cantidad + 1;
            }
        }
        //SE COMENTA D EMOMENTO SU PALICACION DIRECTA DLE BENEFICIO
        // if(card[index].id){
        //    const descuento = ValiarBeneficio(card[index].id, card[index].cantidad);
        //    if(descuento !== 0){
        //       card[index].descuento = descuento;
        //    }
        // }
        setCardState([...card])
        setLargo(cardState.length);
        saveLocalStorage(PRODUCTS_CARD, card);
    }

    const handleDeleteProduct = (index: number) => {
        let card: Array<any> = cardState;
        card.splice(index, 1);
        setCardState([...card])
        setLargo(cardState.length);
        saveLocalStorage(PRODUCTS_CARD, card);
    }


    useEffect(() => {
        if (textSearch) {
            let copyFilters = []
            copyFilters = products.filter(producto => (producto?.nombre.toLowerCase().includes(textSearch.toLowerCase()) || producto?.codigo_erp.toLowerCase().includes(textSearch.toLowerCase())))
            setFilter([...copyFilters])
        }
    }, // eslint-disable-next-line
        [textSearch])

    useEffect(() => {
        if (!openPopup) {
            productosItemsMenu.length = 0;
            setproductosItemsMenu(productosItemsMenu);
        }
    }, // eslint-disable-next-line
        [openPopup])

    async function cargarProductosClick(ids: any) {
        setIsLoaded(true);
        const res = await editor.cargarProductos(ids);
        if (res.productos) {
            setPA(res);
            // setProductosSubmodulo(res.productos.flat())
            setProductosSubmodulo(res)
            setIsLoaded(false);
        } else {
            setPA(null);
            setIsLoaded(false);
        }

    }

    const cargarItemsPA = async (id_prod) => {
        setIsLoaded(true);

        await editor.getItemsProductoAgrupado(id_prod).then((data) => {
            setIsLoaded(false);
            setItems(data.data);
        });
    }

    const cargarProductosMenu = async (id_prod) => {
        setIsLoaded(true);
        arrayChecked.length = 0;
        setArrayChecked(arrayChecked);
        productosItemsMenu.length = 0;
        setproductosItemsMenu(productosItemsMenu);
        setIndexChecked(-1);

        await editor.getMenu(id_prod).then((data) => {
            setItemsMenu(data.menu);
            for (let i = 0; i < data.menu.length; i++) {
                arrayChecked.push(false);
                setArrayChecked(arrayChecked);
                editor.getItemsMenu(data.menu[i].id).then((itemProductos) => {
                    productosItemsMenu.push(itemProductos.data);
                    setproductosItemsMenu(productosItemsMenu);
                    if (i === data.menu.length - 1) {
                        setIsLoaded(false);
                    }
                });

            }
        })
    }
    const salirEdicion = () => {
        localStorage.removeItem(KEY_EDIT_PEDIDO);
        saveLocalStorage(PRODUCTS_CARD, []);
        history.push(ROUTE_PEDIDOS);
    }

    React.useEffect(() => { setproductosItemsMenu(productosItemsMenu) }, [productosItemsMenu])

    async function cargarSubModulos(ids: any) {
        const res = await editor.cargarSubModulos(ids);
        if (res.status !== 400) {


            const submodulos: any = Object.values(res.submodulos);
            submodulo.push(submodulos)

            setSubModulo(submodulo);
            //   setIsLoaded(false);
        } else {
            if (res.status === 400) {
                setIsLoaded(false);
                return;
            } else {
                setIsLoaded(false);
                return;
            }
        }
    }

    async function cargarSubModulo(index) {
        setSubModuloActual([]);
        if (submodulo[index]) {
            const array = submodulo[index];

            setSubModuloActual(array);
        } else {
            setSubModuloActual([]);
        }
    }

    React.useEffect(() => {
        async function fetch() {
            setIsLoaded(true);
            const res = await editor.cargarModulos();

            if (res.status == 200) {

                const modulos: any = Object.values(res.modulos);

                for (let i = 0; i < modulos.length; i++) {
                    if (modulos[i].submodulos) {
                        cargarSubModulos(modulos[i].submodulos);
                    } else {
                        setIsLoaded(false);
                    }
                }
                setTimeout(() => {
                    setIsLoaded(false);
                }, 2000);
                setModulo(modulos);

            } else {
                if (res.status === 400) {
                    setIsLoaded(false);
                    return;
                } else {
                    setIsLoaded(false);
                    return;
                }
            }
        }
        fetch();
    }, // eslint-disable-next-line
        []);

    const modulo_html = modulo.map((item, index) => {
        return (
            <StyledTab
                key={item.id}
                label={item.nombre.charAt(0).toUpperCase() + item.nombre.slice(1).toLowerCase()}
                onClick={() => {
                    cargarSubModulo(index);
                    setUseFilters(false);
                    setProducts([]);
                    setFilter([]);
                    setPA([]);
                }}
                style={{ width: 'auto', height: '60px', fontSize: '13px' }}

            />
        )
    })

    useEffect(() => {
        if (value) {
            (submoduloActual || []).map((item, index) => {
                const valor = Object.values(item);
                const nombre = valor[0]['nombre'];

                if (nombre === "Para arrendar") {
                    setProductosActual(index);
                    setSubmoduloSelect(valor);
                    cargarProductosClick(valor[0]['id_productos']);
                    setAlignment(valor[0]['nombre']);
                    setUseFilters(false);
                }
            });
        }
    }, [submoduloActual, value]);


    const submodulo_html = () => {
        if (submoduloActual) {
            // eslint-disable-next-line
            const sub = (submoduloActual || []).map((item, index) => {
                const valor = Object.values(item);
                const nombre = valor[0]['nombre'];
                if (item) {
                    return (
                        <Box>
                            <StyledToggleButton onClick={() => {
                                setProductosActual(index);
                                setSubmoduloSelect(valor);
                                cargarProductosClick(valor[0]['id_productos']);
                                setAlignment(valor[0]['nombre']);
                                setUseFilters(false)
                            }}

                                // style={{marginTop: '20px'}}
                                value={nombre}>{nombre.charAt(0).toUpperCase() + nombre.slice(1).toLowerCase()}</StyledToggleButton>
                        </Box>
                    )
                }
            })
            return (sub);
        }
    }

    const filtered_products = () => {
        return (
            <TableContainer component={Paper} sx={{ marginTop: '20px' }} >
                {
                    filteredProducts.length > 0 && (<>
                        <Table sx={{ minWidth: 650 }} aria-label="simple table">
                            <TableHead>
                                <TableRow>
                                    <TableCell>{`N°`}</TableCell>
                                    <TableCell>Código - Nombre</TableCell>
                                    <TableCell align="right">Precio</TableCell>
                                </TableRow>
                            </TableHead>

                            <TableBody>
                                {filteredProducts.length > 0 && filteredProducts.map((item, index) => (
                                    <TableRow
                                        key={index}
                                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                        classes={{
                                            hover: 'hover-row-t'
                                        }}
                                    >
                                        <TableCell component="th" scope="row">
                                            {index + 1}
                                        </TableCell>

                                        <TableCell component="th" scope="row">
                                            <button className="H-card__tableButton"
                                                onClick={() => handleSelectProduct(item)}
                                            >
                                                {item.codigo_erp} - {item.nombre.charAt(0).toUpperCase() + item.nombre.slice(1).toLowerCase()}

                                            </button>
                                        </TableCell>

                                        <TableCell align="right"><span > {validatePrice(item, listaPrecio)} </span></TableCell>

                                    </TableRow>
                                ))}
                            </TableBody>

                        </Table>
                    </>)
                }
            </TableContainer>
        )
    }

    function formatName(name) {
        return name.charAt(0).toUpperCase() + name.slice(1).toLowerCase();
    }

    const producto_html = () => {
        if (!PA || !PA['productos'] || (PA['productos'] && PA['productos'].length === 0)) return

        const productos = PA['productos'];
        for (let i = 0; i < productos.length; i++) {
            productos[i] = Object.values(productos[i]);
        }
        return (
            <Grid container spacing={2} justifyContent="center" mt={2}>
                {
                    productos.length > 0
                        ?
                        (rowsPerPage > 0
                            ? productos.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                            : productos
                        ).map((product) => (
                            <Grid item key={product.id}>
                                <Paper elevation={3}
                                    style={{
                                        width: '150px',
                                        height: '150px',
                                        padding: '10px',
                                        display: 'flex',
                                        flexDirection: 'column',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                        cursor: 'pointer',
                                        borderRadius: '8px',
                                        backgroundColor: '#ffffff',
                                        boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)',
                                        transition: 'transform 0.2s ease-in-out',
                                        textAlign: 'center',
                                        overflow: 'hidden',
                                    }}
                                >
                                    <Grid
                                        onClick={() => {
                                            if (product[0]['id_tipo_producto'] === 3) {
                                                setItemMenuSelect(product[0])
                                                cargarProductosMenu(product[0]['id']);
                                                setPopUp('selectProductosMenu');
                                                setOpenPopup(true);
                                            } else {
                                                handleSelectProductInit(product[0])
                                            }
                                        }}
                                        style={{
                                            display: 'flex',
                                            flexDirection: 'column',
                                            justifyContent: 'center',
                                            alignItems: 'center',
                                            textAlign: 'center',
                                            width: '100%',
                                            height: '100%'
                                        }}
                                    >
                                        <Typography variant="h6" sx={{
                                            fontWeight: 'bold',
                                            fontSize: '1em',
                                            marginBottom: '5px',
                                            color: '#333333',
                                            wordWrap: 'break-word',
                                            overflow: 'hidden',
                                            textOverflow: 'ellipsis',
                                            maxHeight: '80%',
                                        }}>
                                            {formatName(product[0]['nombre'])}
                                        </Typography>
                                        <Typography variant="body1" sx={{ textAlign: 'center', margin: '0 auto' }}>
                                            CLP {moneyFormatInt(validatePrice(product[0], listaPrecio) || 0)}
                                        </Typography>
                                        {
                                            product[0].receta?.length > 0 && product[0].stock < 1 && <Typography variant="body1" sx={{ fontSize: '0.5 em', fontWeight: 'bold', color: '#FF0000' }}>NO DISPONIBLE</Typography>
                                        }
                                    </Grid>
                                    {product[0]['id_tipo_producto'] === 2 &&
                                        <IconButton aria-label="add to shopping cart" color="success" sx={{ marginTop: 'auto' }}
                                            onClick={() => {
                                                setPopUp('showProductoAgrupado');
                                                setOpenPopup(true);
                                                setNombreProductSelect(product[0]['nombre']);
                                                cargarItemsPA(product[0]['id']);
                                            }}
                                        >
                                            <VisibilityIcon />
                                        </IconButton>
                                    }

                                    {product[0]['id_tipo_producto'] === 3 &&
                                        <IconButton aria-label="add to shopping cart" color="secondary" sx={{ marginTop: 'auto' }}
                                            onClick={() => {
                                                cargarProductosMenu(product[0]['id']);
                                                setPopUp('showMenu');
                                                setNombreProductSelect(product[0]['nombre']);
                                                setOpenPopup(true);
                                            }}
                                        >
                                            <VisibilityIcon />
                                        </IconButton>
                                    }
                                </Paper>
                            </Grid>
                        ))
                        :
                        <Grid container justifyContent="center">
                            <Box>
                                <Grid container justifyContent="center">
                                    <RemoveShoppingCartIcon className="iconocarrito" />
                                </Grid>
                                <div>No existen productos agregados a esta subcategoría.</div>
                            </Box>
                        </Grid>
                }
                <Grid item xs={12}>
                    <TableRow>
                        <TablePagination
                            count={PA['productos'].length || 0}
                            rowsPerPage={rowsPerPage}
                            page={page}
                            onPageChange={handleChangePage}
                            onRowsPerPageChange={handleChangeRowsPerPage}
                            rowsPerPageOptions={[16, 20, 24]}
                            labelRowsPerPage={<span className="tabla">Filas:</span>}
                            labelDisplayedRows={({ page }) => {
                                return `Página: ${page + 1}`;
                            }}
                            SelectProps={{
                                inputProps: {
                                    "aria-label": "page number"
                                }
                            }}
                            showFirstButton={true}
                            showLastButton={true}
                        />
                    </TableRow>
                </Grid>
            </Grid >
        )
    }

    const submodulo_tab = () => {
        if (submoduloActual) {
            // eslint-disable-next-line
            const sub = (submoduloActual || []).map((item, index) => {
                const valor = Object.values(item);
                const nombre = valor[0]['nombre'];

                if (index === productosActual) {
                    return (
                        <Box>

                            <Grid sx={{ marginTop: '10px' }}>
                                <Box sx={{
                                    display: 'grid',
                                    gridTemplateColumns: '1fr auto',
                                    alignItems: 'center',
                                    borderBottom: '1.5px solid #000000'
                                }}>
                                    <div style={{ fontFamily: 'Lato' }}>{`Submódulo: ${nombre.charAt(0).toUpperCase() + nombre.slice(1).toLowerCase()}`}</div>
                                    <div className="H-card__fcontainer__filter">
                                        <span>Filtro:</span>
                                        <TextField
                                            type="text"
                                            id="mi-codigo-de-barras"
                                            placeholder="Buscar por nombre o código"
                                            autoFocus
                                            autoComplete="off"
                                            onKeyDown={onSearchSubModule}
                                            // value={searchSubmodule}
                                            onChange={onSearchSubModule}
                                            className="H-card__fcontainer__filter__input"
                                            style={{ backgroundColor: "white", width: "290px", marginLeft: "10px" }}
                                        />
                                    </div>
                                </Box>
                                <Grid sx={{ Width: '100%', bgcolor: 'background.paper', padding: '10px', display: 'flex', alignItems: "center", borderRadius: '10px' }} container>
                                    <Grid item xs={6} style={{ display: "flex", alignItems: "center", marginBottom: "10px", marginTop: "8px", paddingRight: '5px' }}>
                                        <Autocomplete
                                            disablePortal
                                            id="combo-box-demo"
                                            value={familySelectedSub}
                                            options={dataFamilies || []}
                                            getOptionLabel={(option) => option?.nombre || ""}
                                            isOptionEqualToValue={(option, value) => option?.id === value?.id}
                                            fullWidth
                                            renderInput={(params) => <TextField {...params} label="Familias" />}
                                            onChange={(e, newValue) => {
                                                setFamilySelectedSub(newValue)
                                            }}
                                            size="small"
                                        />
                                    </Grid>
                                    <Grid item xs={6} style={{ display: "flex", alignItems: "center", marginBottom: "10px", marginTop: "8px" }}>
                                        <Autocomplete
                                            disablePortal
                                            id="combo-box-demo"
                                            value={lineSelectedSub}
                                            options={dataLines || []}
                                            getOptionLabel={(option) => option?.nombre || ""}
                                            isOptionEqualToValue={(option, value) => option?.id === value?.id}
                                            fullWidth
                                            renderInput={(params) => <TextField {...params} label="Lineas" />}
                                            onChange={(e, newValue) => {
                                                setLineSelectedSub(newValue)
                                            }}
                                            size="small"
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>
                            {
                                producto_html()
                            }
                            {/* {((PA === null && isLoaded === false) || (filteredProducts.length == 0)) &&
                                <Grid container justifyContent="center"> <Box> <Grid container justifyContent="center"> <RemoveShoppingCartIcon className="iconocarrito" /></Grid> <div>No existen productos agregados a esta subcategoría.</div></Box></Grid>
                            } */}
                        </Box>
                    )
                }
            })
            return (sub);
        }
    }

    const openModalConfirm = () => {
        setShowModalConfirm(true);
    }

    const closeModalConfirm = () => {
        setShowModalConfirm(false);
    }

    const handleKeyDown = async (event) => {
        let codigo: any = document.getElementById("mi-codigo-de-barras");
        if (event.keyCode === 13) {
            let newValue = codigo.value || "";
            let arrayValue = newValue.split("xyz");
            setIsLoaded(true);
            await productoService.getProductsByCodigo(arrayValue[0] || "").then((data) => {
                if (data.status === 200) {
                    codigo.value = ""
                    setTextSearch(newValue);
                    // eslint-disable-next-line
                    (data.productos).map((producto) => {
                        if (producto?.id_tipo_producto !== 3) {
                            handleSelectProduct(producto, arrayValue[1] ? Number(arrayValue[1]) : 0);
                        }
                    })
                } else {
                    openModalConfirm();
                    setPopUp('modalConfirm');
                    setCodigoBarras(newValue);
                }
                setIsLoaded(false);
            })

            //alert("codigo de barras "+ newValue)
        } else {
            let texto = `${event.target.value}`;
            setTextSearch(texto);
            if (!texto) {
                setFilter([...products])
            }
        }
    }

    const onSearch = (e) => {
        if (e.key === 'Enter') {
            const barCode = e.target.value;
            const product = products.find(product => product.codigos_barra?.find(codigo => codigo.codigo_barra === barCode && codigo.estado === 1))
            if (product) {
                handleSelectProductInit(product)
            } else {
                setSnackBarConfig({ ...snackBarConfig, open: true, message: 'Codigo de barras no encontrado.', severity: 'error' })
                setFilter(products || [])
            }
        } else {
            const search: string = e.target.value
            if (search !== '') {
                const filteredProducts = products.filter(product => {
                    const searchLowerCase = search.trim().toLowerCase()
                    return product.nombre && product.nombre.toLowerCase().startsWith(searchLowerCase)
                })
                setFilter(filteredProducts || [])
            } else {
                setFilter(products || [])
            }
        }
    }

    const onSearchSubModule = (e) => {
        if (e.key === 'Enter') {
            const barCode = e.target.value.trim(); // Asegúrate de eliminar espacios en blanco alrededor del código de barras

            if (barCode !== '' || lineSelectedSub || familySelectedSub) {
                const allProductos = productosSubmodulo.productos.flat()
                const filteredProducts = allProductos.filter(product => {
                    const matchesSearch = !barCode || (product.codigos_barra && product.codigos_barra.some(codigo => codigo.codigo_barra === barCode && codigo.estado === 1));
                    return matchesSearch;
                })

                if (filteredProducts.length == 1) {
                    handleSelectProductInit(filteredProducts[0])
                } else {
                    setSnackBarConfig({ ...snackBarConfig, open: true, message: 'Codigo de barras no encontrado.', severity: 'error' })
                    let filteredProducts2 = allProductos.map(product => [product]);
                    let productos: any = { ...PA, productos: filteredProducts2 }
                    setPA(productos || [])
                }

            } else {
                setPA(productosSubmodulo || [])
            }
        } else {
            const search: string = e.target.value

            if (search !== '' || lineSelectedSub || familySelectedSub) {
                const allProductos = productosSubmodulo.productos.flat()

                const filteredProducts = allProductos.filter(product => {
                    const matchesSearch = !search || (product.nombre && product.nombre.toLowerCase().startsWith(search.trim().toLowerCase()));
                    const matchesFamily = !familySelectedSub || product.familia_id === familySelectedSub.id;
                    const matchesLine = !lineSelectedSub || product.linea_id === lineSelectedSub.id;

                    return matchesSearch && matchesFamily && matchesLine;
                }).map(product => [product]);

                const productos: any = { ...PA, productos: filteredProducts }
                setPA(productos || [])
            } else {
                setPA(productosSubmodulo || [])
            }
        }
    }

    useEffect(() => {
        onFilters()
    }, [familySelectedSub, lineSelectedSub, searchSubmodule])

    const onFilters = () => {
        if (searchSubmodule !== '' || lineSelectedSub || familySelectedSub) {
            const allProductos = productosSubmodulo.productos.flat()

            const filteredProducts = allProductos.filter(product => {
                const matchesSearch = !searchSubmodule || (product.nombre && product.nombre.toLowerCase().startsWith(searchSubmodule.trim().toLowerCase()));
                const matchesFamily = !familySelectedSub || product.familia_id === familySelectedSub.id;
                const matchesLine = !lineSelectedSub || product.linea_id === lineSelectedSub.id;

                return matchesSearch && matchesFamily && matchesLine;
            }).map(product => [product]);

            const productos: any = { ...PA, productos: filteredProducts }
            setPA(productos || [])
        } else {
            setPA(productosSubmodulo || [])
        }
    }

    const modulo_tab_todos_los_productos = 
    <TabPanel value={0} index={0} key={0} >
    <div className="H-card__fcontainer">
        <div className="H-card__fcontainer__filter">
            <span>Filtro:</span>
            <TextField
                type="text"
                id="mi-codigo-de-barras"
                placeholder="Buscar por nombre o código"
                autoComplete="off"
                onKeyDown={onSearch}
                autoFocus
                onChange={onSearch}
                className="H-card__fcontainer__filter__input"
                style={{ backgroundColor: "white", width: "290px", marginLeft: "10px" }}
            />

            {editPedido.length !== 0 && role !== 'Cliente' && (
                <Box sx={{ display: 'flex', width: '100%', flexDirection: 'row-reverse', marginLeft: '20px', marginRight: '-10px' }}>
                    <Button variant={'outlined'} onClick={() => {
                        salirEdicion()
                    }}>
                        Salir de modo edicion
                    </Button>
                </Box>
            )
            }
        </div>

        {/* <Grid sx={{marginTop:'10px'}}>
<HomePersonalized/>
</Grid> */}

    </div>
    <Grid sx={{ marginTop: '10px' }}>
        <HomePersonalized2 dataFamilies={dataFamilies} dataLines={dataLines} setFilteredProducts={setFilter} setUseFilters={setUseFilters} />
    </Grid>
    <Grid container spacing={2} justifyContent="center" mt={2}>
        {
            (rowsPerPage > 0
                ? filter?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                : filter
            ).map((product) => (
                <Grid item key={product.id}>
                    <Paper elevation={3}
                        style={{
                            width: '150px',
                            height: '150px',
                            padding: '10px',
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'center',
                            alignItems: 'center',
                            cursor: 'pointer',
                            borderRadius: '8px',
                            backgroundColor: '#ffffff',
                            boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)',
                            transition: 'transform 0.2s ease-in-out',
                            textAlign: 'center',
                            overflow: 'hidden',
                        }}
                        onClick={() => handleSelectProductInit(product)}
                    >
                        <Typography variant="h6" sx={{
                            fontWeight: 'bold',
                            fontSize: '1em',
                            marginBottom: '5px',
                            color: '#333333',
                            wordWrap: 'break-word',
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                            maxHeight: '80%',
                        }}>{formatName(product.nombre)}</Typography>
                        <Typography variant="body1" sx={{ fontSize: '1em', color: '#666666' }}>CLP {moneyFormatInt(product.precio_standar || 0)}</Typography>
                        {
                            product.receta?.length > 0 && product.stock < 1 && <Typography variant="body1" sx={{ fontSize: '0.5 em', fontWeight: 'bold', color: '#FF0000' }}>NO DISPONIBLE</Typography>
                        }
                    </Paper>
                </Grid>

            ))
        }
    </Grid>
    <Grid container justifyContent="center" mt={2}>
        <Grid item xs={12}>
            <TableRow>
                <TablePagination
                    count={filter.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                    rowsPerPageOptions={[16, 20, 24]}
                    labelRowsPerPage={<span className="tabla">Filas:</span>}
                    labelDisplayedRows={({ page }) => {
                        return `Página: ${page + 1}`;
                    }}
                    SelectProps={{
                        inputProps: {
                            "aria-label": "page number"
                        }
                    }}
                    showFirstButton={true}
                    showLastButton={true}
                />
            </TableRow>
        </Grid>
    </Grid>
</TabPanel>

    const modulo_tab = modulo.map((item, index) => {
        return (
            <Box >
                {index === 0 && (
                    <TabPanel value={value} index={0} key={0} >
                        <div className="H-card__fcontainer">
                            <div className="H-card__fcontainer__filter">
                                <span>Filtro:</span>
                                <TextField
                                    type="text"
                                    id="mi-codigo-de-barras"
                                    placeholder="Buscar por nombre o código"
                                    autoComplete="off"
                                    onKeyDown={onSearch}
                                    autoFocus
                                    onChange={onSearch}
                                    className="H-card__fcontainer__filter__input"
                                    style={{ backgroundColor: "white", width: "290px", marginLeft: "10px" }}
                                />

                                {editPedido.length !== 0 && role !== 'Cliente' && (
                                    <Box sx={{ display: 'flex', width: '100%', flexDirection: 'row-reverse', marginLeft: '20px', marginRight: '-10px' }}>
                                        <Button variant={'outlined'} onClick={() => {
                                            salirEdicion()
                                        }}>
                                            Salir de modo edicion
                                        </Button>
                                    </Box>
                                )
                                }
                            </div>

                            {/* <Grid sx={{marginTop:'10px'}}>
                  <HomePersonalized/>
               </Grid> */}

                        </div>
                        <Grid sx={{ marginTop: '10px' }}>
                            <HomePersonalized2 dataFamilies={dataFamilies} dataLines={dataLines} setFilteredProducts={setFilter} setUseFilters={setUseFilters} />
                        </Grid>
                        <Grid container spacing={2} justifyContent="center" mt={2}>
                            {
                                (rowsPerPage > 0
                                    ? filter?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                    : filter
                                ).map((product) => (
                                    <Grid item key={product.id}>
                                        <Paper elevation={3}
                                            style={{
                                                width: '150px',
                                                height: '150px',
                                                padding: '10px',
                                                display: 'flex',
                                                flexDirection: 'column',
                                                justifyContent: 'center',
                                                alignItems: 'center',
                                                cursor: 'pointer',
                                                borderRadius: '8px',
                                                backgroundColor: '#ffffff',
                                                boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)',
                                                transition: 'transform 0.2s ease-in-out',
                                                textAlign: 'center',
                                                overflow: 'hidden',
                                            }}
                                            onClick={() => handleSelectProductInit(product)}
                                        >
                                            <Typography variant="h6" sx={{
                                                fontWeight: 'bold',
                                                fontSize: '1em',
                                                marginBottom: '5px',
                                                color: '#333333',
                                                wordWrap: 'break-word',
                                                overflow: 'hidden',
                                                textOverflow: 'ellipsis',
                                                maxHeight: '80%',
                                            }}>{formatName(product.nombre)}</Typography>
                                            <Typography variant="body1" sx={{ fontSize: '1em', color: '#666666' }}>CLP {moneyFormatInt(product.precio_standar || 0)}</Typography>
                                            {
                                                product.receta?.length > 0 && product.stock < 1 && <Typography variant="body1" sx={{ fontSize: '0.5 em', fontWeight: 'bold', color: '#FF0000' }}>NO DISPONIBLE</Typography>
                                            }
                                        </Paper>
                                    </Grid>

                                ))
                            }
                        </Grid>
                        <Grid container justifyContent="center" mt={2}>
                            <Grid item xs={12}>
                                <TableRow>
                                    <TablePagination
                                        count={filter.length}
                                        rowsPerPage={rowsPerPage}
                                        page={page}
                                        onPageChange={handleChangePage}
                                        onRowsPerPageChange={handleChangeRowsPerPage}
                                        rowsPerPageOptions={[16, 20, 24]}
                                        labelRowsPerPage={<span className="tabla">Filas:</span>}
                                        labelDisplayedRows={({ page }) => {
                                            return `Página: ${page + 1}`;
                                        }}
                                        SelectProps={{
                                            inputProps: {
                                                "aria-label": "page number"
                                            }
                                        }}
                                        showFirstButton={true}
                                        showLastButton={true}
                                    />
                                </TableRow>
                            </Grid>
                        </Grid>
                    </TabPanel>
                )
                }

                <TabPanel value={value} index={index + 1} key={item.id} >

                    {/* <Box>
            Módulo: {item.nombre.charAt(0).toUpperCase() + item.nombre.slice(1).toLowerCase()}
          </Box>
          <Divider sx={{marginTop: '10px'}} /> */}

                    <Box >

                        <StyledToggleButtonGroup
                            size="small"
                            color="primary"
                            value={alignment}
                            exclusive
                            onChange={handleChangeToggleButton}
                        >
                            <Box sx={{ display: 'inline-flex', flexWrap: 'wrap' }}>
                                {submodulo_html()}
                            </Box>
                        </StyledToggleButtonGroup>

                        <Divider sx={{ marginTop: '20px' }} />

                        {submodulo_tab()}

                    </Box>

                </TabPanel>
            </Box >
        )

    })

    const cargarItemsProductosMenu = () => {
        return (
            productosItemsMenu[indexChecked].map((itemP, indexP) => (
                <Box >
                    <Box sx={{ marginLeft: '10px', marginBottom: '10px' }}>
                        <div>{itemP.nombre}</div>
                        <input
                            type="number" defaultValue={0}
                            {...register(`${indexChecked}-${indexP}-${itemP.id_producto}`, { min: 0, max: 20 })}
                        />
                    </Box>
                </Box>
            ))

        )
    }

    const evaluacion = (index) => {
        if (index !== indexChecked) {
            return false;
        } else { return true }
    }

    // SE COENTA DE MOMENTO SU PALIACIO NDIRECTA DEL  BENEFICIO
    // const ValiarBeneficio = (id_product: number, cantidad: number) => {
    //    if (id_product){
    //       let result = beneficioProducto.find(prod => prod.id_producto === id_product)
    //       if ( !result ) return 0
    //       if ( cantidad < result.cantidad_productos ) return 0
    //       let multiplo = cantidad / result.cantidad_productos
    //       let descuento = Math.trunc(multiplo) * (result.cantidad_puntos * dataEmpresa.equivalencia_puntos)
    //       return descuento;
    //       // return result.cantidad_puntos;
    //    } else {
    //       return 0;
    //    }
    // }


    const handleChangeInputProduct = (event: any, producto: any, index: number) => {
        if (Number(event.target.value) > 0) {
            let card: Array<any> = cardState;
            card[index].cantidad = event.target.value;
            setCardState([...card]);
            saveLocalStorage(PRODUCTS_CARD, card);
        }
    }

    const restOne = (index: string) => {
        const formData = getValues()
        const valor = parseInt(formData[index])
        if (valor < 1) return
        const nuevoValor = valor - 1
        formData[index] = nuevoValor
        setValueUseForm(index, nuevoValor)
    }

    const addOne = (index: string, grupo: string, cantidad: number) => {
        const formData = getValues()

        const keys = Object.keys(formData)
        const keysConCero = keys.filter(key => key.startsWith(grupo))
        const suma = keysConCero.reduce((total, key) => {
            return total + parseInt(formData[key]);
        }, 0)

        if (suma >= cantidad) return

        const valor = formData[index]
        const nuevoValor = parseInt(valor) + 1

        formData[index] = nuevoValor
        setValueUseForm(index, nuevoValor)
    }

    const handleEditPedido = async () => {
        if (cardState.length === 0) return setSnackBarConfig(prev => ({
            ...prev,
            open: true,
            message: "Seleccione un producto.",
        }))
        const pedido: any = editPedido
        const productos = cardState.map(producto => { return { id: producto.id, cantidad: producto.cantidad } })
        const payload = {
            id_mesa: pedido.pedidoDetail.id_mesa,
            productos: productos
        }
        const response = await ticketService.updatePedido(pedido.pedidoDetail.id, payload)
        if (response.status == 200) {
            if(!!pedido.comandas.length) showComandaPDF(pedido.id)
            salirEdicion()
        }
    }

    const showComandaPDF = async (id: number) => {
        try {
            const pdf = await ComandaService.getPDF(id);
            const blob = new Blob([pdf], { type: 'application/pdf' });
            const blobURL = URL.createObjectURL(blob);
            window.open(blobURL, '_blank');
        } catch (error) {
            console.error('Error al mostrar el PDF:', error);
        }
    }

    useEffect(() => {
        if (!cardState || !editPedido) return
        const total: number = cardState.reduce((accumulator, producto) => {
            return accumulator + (producto.precio_unitario * producto.cantidad);
        }, 0);
        setTotalCardEdit(total || 0)
    }, [cardState, editPedido])

    return (
        <Protected>

            <Snackbar
                open={snackBarConfig.open}
                autoHideDuration={snackBarConfig.autoHideDuration}
                onClose={() => setSnackBarConfig(prev => ({ ...prev, open: false }))}
            >
                <Alert
                    onClose={() => setSnackBarConfig(prev => ({ ...prev, open: false }))}
                    severity={snackBarConfig.severity}
                    variant="filled"
                >
                    {snackBarConfig.message}
                </Alert>
            </Snackbar>

            {openPopup && popUp === 'showProductoAgrupado' &&
                <Popup
                    largo="md"
                    title={`Items del producto: ${nombreProductSelect.toUpperCase()}`}
                    openPopup={openPopup}
                    setOpenPopup={setOpenPopup}
                >
                    <TableContainer component={Paper} sx={{ marginTop: '20px' }} >
                        <Table sx={{ minWidth: 650 }} aria-label="simple table">
                            <TableHead>
                                <TableRow>
                                    <TableCell>{`N°`}</TableCell>
                                    <TableCell>Código - Nombre</TableCell>
                                    <TableCell align="right">Cantidad</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {items.map((item, index) => (
                                    <TableRow
                                        key={item.id}
                                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                        classes={{
                                            hover: 'hover-row-t'
                                        }}
                                    >
                                        <TableCell component="th" scope="row">{index + 1}</TableCell>
                                        <TableCell component="th" scope="row">{item?.producto_agrupado_hijo?.codigo_erp} - {item?.producto_agrupado_hijo?.nombre}</TableCell>
                                        <TableCell align="right">{item.cantidad || "--"}</TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Popup>
            }

            {openPopup && popUp === 'showMenu' &&
                <Popup
                    largo="md"
                    title={`Items del producto: ${nombreProductSelect.toUpperCase()}`}
                    openPopup={openPopup}
                    setOpenPopup={setOpenPopup}
                >
                    {isLoaded === false && itemsMenu.length !== 0 &&
                        <Box sx={{ marginTop: '-20px' }}>
                            {itemsMenu.map((item, index) => (
                                <TableContainer component={Paper} sx={{ marginTop: '20px' }} >
                                    <Box>
                                        <Box sx={{ marginTop: '10px', marginLeft: '10px', marginBottom: '20px' }}>
                                            <strong className="mayus">{item.nombre.toUpperCase()}.-</strong>
                                            &nbsp;&nbsp;Cantidad de productos a seleccionar: {item.cantidad}
                                        </Box>
                                        {isLoaded === false && productosItemsMenu.length !== 0 &&
                                            productosItemsMenu[index].map((itemP, indexP) => (

                                                <Box sx={{ marginLeft: '10px', marginBottom: '10px' }}>
                                                    <div>{indexP + 1}.- {itemP.nombre}</div>
                                                </Box>

                                            ))}
                                    </Box>
                                </TableContainer>
                            )
                            )}
                        </Box>
                    }
                </Popup>
            }

            {openPopup && popUp === 'selectProductosMenu' &&
                <Popup
                    largo="sm"
                    title={'Selecciona los items del menu:'}
                    openPopup={openPopup}
                    setOpenPopup={setOpenPopup}
                >
                    <form onSubmit={handleSubmit(onSubmit)}>
                        {isLoaded === false && itemsMenu.length !== 0 &&
                            itemsMenu.map((item, index) => (
                                <TableContainer component={Paper} sx={{ marginTop: '20px', borderRadius: '10px', boxShadow: '0px 8px 16px rgba(0, 0, 0, 0.2)', border: '1px solid #ccc', padding: '5px' }} key={index}>
                                    <Box>
                                        <Box sx={{ padding: '15px', borderBottom: '1px solid #ccc' }}>
                                            <Typography variant="h6" sx={{ fontWeight: 'bold', color: '#516E80' }}>{item.nombre.toUpperCase()}</Typography>
                                            <Divider sx={{ margin: '10px 0', backgroundColor: '#516E80' }} />
                                            <Typography variant="body1" sx={{ color: '#000' }}>Cantidad de productos a seleccionar: {item.cantidad}</Typography>
                                        </Box>

                                        {isLoaded === false && productosItemsMenu.length !== 0 &&
                                            productosItemsMenu[index].map((itemP, indexP) => (
                                                <Box key={`${index}-${indexP}`} sx={{ padding: '10px', borderBottom: '1px solid #eee' }}>
                                                    <Grid container alignItems="center" spacing={2}>
                                                        <Grid item xs={6}>
                                                            <Typography variant="body1" sx={{ color: '#000' }}>{itemP.nombre}</Typography>
                                                        </Grid>
                                                        <Grid item xs={6} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}>
                                                            <IconButton
                                                                sx={{
                                                                    background: '#516E80',
                                                                    width: '30px',
                                                                    height: '30px',
                                                                    marginRight: '5px',
                                                                    '&:hover': {
                                                                        background: '#516E80'
                                                                    }
                                                                }}
                                                                onClick={() => restOne(`${index}-${indexP}-${itemP.id_producto}`)}>
                                                                <RemoveIcon fontSize="small" sx={{ color: '#ffffff' }} />
                                                            </IconButton>
                                                            <input type="number" defaultValue={0} min={0} readOnly
                                                                style={{
                                                                    width: "50px",
                                                                    textAlign: 'center',
                                                                    marginRight: '5px',
                                                                    borderRadius: '5px',
                                                                    border: '1px solid #ccc',
                                                                    padding: '5px'
                                                                }}
                                                                {...register(`${index}-${indexP}-${itemP.id_producto}`, { min: 0, max: 20 })}
                                                            />
                                                            <IconButton
                                                                sx={{
                                                                    background: '#516E80',
                                                                    width: '30px',
                                                                    height: '30px',
                                                                    marginLeft: '5px',
                                                                    '&:hover': {
                                                                        background: '#516E80'
                                                                    }
                                                                }}
                                                                onClick={() => addOne(`${index}-${indexP}-${itemP.id_producto}`, index.toString(), item.cantidad)}>
                                                                <AddIcon fontSize="small" sx={{ color: '#ffffff' }} />
                                                            </IconButton>
                                                        </Grid>
                                                    </Grid>
                                                </Box>
                                            ))
                                        }
                                    </Box>
                                </TableContainer>
                            ))
                        }

                        {isLoaded === false && (
                            <Box sx={{ marginTop: '20px', display: 'flex', justifyContent: "flex-end" }}>
                                <input
                                    type="submit"
                                    value="Añadir"
                                    style={{
                                        padding: '10px 20px',
                                        fontSize: '16px',
                                        backgroundColor: '#516E80',
                                        color: '#ffffff',
                                        border: 'none',
                                        borderRadius: '5px',
                                        cursor: 'pointer',
                                        boxShadow: '0px 2px 5px rgba(0, 0, 0, 0.2)',
                                        transition: 'background-color 0.3s ease',
                                    }}
                                />
                            </Box>
                        )}
                    </form>
                </Popup>
            }

            {
                (estadoCuadratura == 2) &&
                <div style={{ border: '2px solid #FFA500', padding: '10px', background: '#FFA500', borderRadius: '10px' }}>
                    <span style={{ fontSize: '16px', color: '#fff' }}>No existe una cuadratura abierta para este usuario, debe abrir la cuadratura para poder realizar ventas.   </span>
                    <Button variant="contained" sx={{ ml: 2 }} onClick={() => history.push(ROUTE_CUADRATURAS)}>
                        Abrir cuadratura
                    </Button>
                </div>
            }
            {
                (estadoCuadratura == 1) &&
                <div style={{ border: '2px solid #FFA500', padding: '10px', background: '#FFA500', borderRadius: '10px' }}>
                    <span style={{ fontSize: '16px', color: '#fff' }}>Debe cerrar la cuadratura del dia anterior para poder realizar ventas.   </span>
                    <Button variant="contained" sx={{ ml: 2 }} onClick={() => history.push(ROUTE_CUADRATURAS)}>
                        Cerrar cuadratura
                    </Button>
                </div>
                // <div style={{border:'2px solid black'}}>
                //     <span>No existe una cuadratura abierta para este usuario.</span> <Button>Abrir cuadratura</Button>
                //     <span>Debe cerrar la cuadratura del diario anterior.</span> <Button>Cerrar cuadratura</Button>

                // </div>
            }

            <Grid container>
                <Grid item sm={7} sx={{ minHeight: '60vh', maxHeight: '85vh', overflowY: 'scroll' }}>
                    <Box sx={{ maxWidth: '100%', bgcolor: 'background.paper' }}>
                        <Tabs
                            value={value}
                            onChange={handleChange}
                            variant="scrollable"
                            scrollButtons
                            allowScrollButtonsMobile
                            aria-label="scrollable auto tabs example"
                        >
                            <StyledTab
                                key={0}
                                label='Todos los Productos'
                                style={{
                                    width: 'auto',
                                    height: '60px',
                                    fontSize: '13px',
                                }}
                                onClick={() => getProductosEmpresa(dataEmpresa.id)}
                            />
                            {modulo_html}
                        </Tabs>
                    </Box>
                    <Box sx={{ backgroundColor: '#EDEDED' }}>
                        {modulo_tab.length == 0 ? modulo_tab_todos_los_productos : modulo_tab}
                    </Box>
                </Grid>

                <Grid item sm={5} container direction='row'>
                    <Grid xs={12} sx={{ minHeight: role !== 'Cliente' ? '40vh' : '55vh', maxHeight: '40vh', overflowY: 'scroll', padding: '10px' }}>
                        {
                            (editPedido.length !== 0) && (role === 'Cliente') && (
                                <>
                                    <Grid sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                                        {/* <div className="H-card__fcontainer__infoTable" style={{paddingLeft: '10px', paddingTop:'5px', paddingBottom:'10px'}}>
                              {`N° ticket: ${editPedido['ticket'].n_ticket}`}
                           </div> */}
                                        <div className="H-card__fcontainer__infoTable" style={{ paddingLeft: '10px', paddingTop: '5px', paddingBottom: '10px' }}>
                                            {`Estado del pedido: ${editPedido['pedido'].estado}`}
                                        </div>
                                    </Grid>
                                    <Divider />
                                </>
                            )
                        }

                        {/* {dataEmpresa.id_tipo_empresa !== ID_TYPE_RESTAURANTE &&
                            <>
                                <Grid item xs={12} sx={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                                    <Button variant='contained' color="info" size="small" style={{ textTransform: "capitalize" }}
                                        onClick={() => { setOpenPopup(true); setPopUp('productoBalanza'); }} sx={{ fontSize: "14px !important" }}
                                    >Generar Ticket Balanza
                                    </Button>

                                    <Button variant='contained' color="info" size="small" style={{ textTransform: "capitalize" }}
                                        onClick={() => { setOpenPopup(true); setPopUp('cuponModal'); }} sx={{ fontSize: "14px !important" }}
                                    >Validar Cupón
                                    </Button>

                                    <Button variant='contained' color="info" size="small" style={{ textTransform: "capitalize" }}
                                        onClick={() => { setOpenPopup(true); setPopUp('puntosModal'); }} sx={{ fontSize: "14px !important" }}
                                    >Canjear puntos
                                    </Button>
                                </Grid>
                                <Divider sx={{ mt: 2 }} />
                            </>
                        } */}


                        <div className="H-card__title" style={{ paddingLeft: '10px', paddingTop: '10px' }}>
                            {`Cantidad de productos (${largo}) | Moneda: ${dataEmpresa.country_abbreviation}`}
                        </div>
                        <div className="H-card__separator"></div>

                        <div className="H-card__list">

                            {
                                cardState.map((product, index) => (
                                    <div className="H-card__list__item">
                                        <div className="content-info">
                                            <div className="info">
                                                {(!product.id_unidad_medida || (product.id_unidad_medida && product.id_unidad_medida === 1)) &&
                                                    <>
                                                        <button className="dissmiss-more" onClick={() => handleDismissProduct(index)}>-</button>
                                                        <span className="quantity">{`(${product.cantidad ? product.cantidad : 1})`}</span>
                                                        <button className="dissmiss-more" onClick={() => handleMoreProduct(index)}>+</button>
                                                    </>
                                                }
                                                {
                                                    product.id_unidad_medida && product.id_unidad_medida !== 1 &&
                                                    <>
                                                        <input disabled={product?.is_balanza} value={product.cantidad} type="number" /* dir="rtl" */ onChange={(e) => handleChangeInputProduct(e, product, index)} className="input-quantity" /> <span>{`(${product?.unidad_medida?.abbreviation}) ` || ""}</span>
                                                    </>
                                                }
                                                <span className="name">{product.nombre && product.nombre.charAt(0).toUpperCase() + product.nombre.slice(1).toLowerCase()}</span>
                                            </div>
                                            <span className="price"> {dataEmpresa.country_abbreviation} {moneyFormatInt((getPricePorductByCarrito(product, listaPrecio) * product.cantidad) || 0)}  </span>
                                        </div>
                                        <button className="dissmiss-more delete" onClick={() => handleDeleteProduct(index)}>x</button>
                                    </div>
                                ))
                            }
                        </div>
                    </Grid>
                    {editPedido.length !== 0
                        ?
                        <>
                            <Grid item xs={12}>
                                <strong style={{ marginRight: "10px", marginLeft: "5px" }}>Total a pagar:&nbsp;</strong>
                                <Input
                                    className="input-pago"
                                    disabled
                                    size="small" id="cliente"
                                    name="cliente" type="number"
                                    value={totalCardEdit}
                                    startAdornment={<InputAdornment position="start">{dataEmpresa.country_abbreviation}</InputAdornment>}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <input
                                    type="button"
                                    value="Guardar"
                                    style={{
                                        padding: '10px 20px',
                                        fontSize: '16px',
                                        backgroundColor: '#516E80',
                                        color: '#ffffff',
                                        border: 'none',
                                        borderRadius: '5px',
                                        cursor: 'pointer',
                                        boxShadow: '0px 2px 5px rgba(0, 0, 0, 0.2)',
                                        transition: 'background-color 0.3s ease',
                                        width: '100%'
                                    }}
                                    onClick={() => handleEditPedido()}
                                />
                            </Grid>
                        </>
                        :
                        <Grid xs={12} sx={{ minHeight: '35vh' }}>
                            <Pagos
                                productsCard={cardState}
                                handleLoadPago={handleLoadPago}
                                openPopup={openPopup}
                                estadoCuadratura={estadoCuadratura}
                            />
                        </Grid>
                    }
                </Grid>
            </Grid>

            {isLoaded && <Loading />}
            <Box>
                {
                    popUp === 'modalConfirm' && (
                        <ModalConfirm
                            open={showModalConfirm}
                            closeModal={closeModalConfirm}
                            codigoBarras={codigoBarras}
                            isHome={true}
                        />
                    )
                }
            </Box>
            <Box>
                {
                    openPopup === true && popUp === 'productoBalanza' && (
                        <Popup
                            largo="sm"
                            title='Listado de Productos'
                            openPopup={openPopup}
                            setOpenPopup={setOpenPopup}
                        >
                            <ProductoBalanza
                                closeModal={() => { setOpenPopup(false) }}
                            />
                        </Popup>
                    )
                }
            </Box>
            <Box>
                {
                    openPopup === true && popUp === 'cuponModal' && (
                        <Popup
                            largo="sm"
                            title='Validar Cupón'
                            openPopup={openPopup}
                            setOpenPopup={setOpenPopup}
                        >
                            <ValidateCupon
                                closeModal={() => { setOpenPopup(false) }}
                            />
                        </Popup>
                    )
                }
            </Box>
            <Box>
                {
                    openPopup === true && popUp === 'puntosModal' && (
                        <Popup
                            largo="sm"
                            title='Validar Puntos'
                            openPopup={openPopup}
                            setOpenPopup={setOpenPopup}
                        >
                            <ValidatePuntos
                                closeModal={() => { setOpenPopup(false) }}
                            />
                        </Popup>
                    )
                }
            </Box>
        </Protected>
    )
}
