import { API_URL_NUPY } from "@/toolbox/defaults/app"
import axios from "axios"

export const SucursalesRepository = {

    register: async(data:{}): Promise<any> =>{
        const res = await axios.post(`${API_URL_NUPY}/sucursales/register`, data);
        return res.data;
    },
    update: async(data:{}, idSucursal:number): Promise<any> => {
        const res = await axios.post(`${API_URL_NUPY}/sucursales/update/${idSucursal}`, data);
        return res.data;
    },
    listAllSucursales: async(): Promise<any> =>{
        const res = await axios.get(`${API_URL_NUPY}/sucursales/listAll`);
        return res.data;
    },
    editState: async(idSucursal:number, state:string): Promise<any> => {
        const res = await axios.patch(`${API_URL_NUPY}/sucursales/editState/${idSucursal}/${state}`);
        return res.data;
    },
    getSucursalesByEmpresa: async(idCompany: number): Promise<any> =>{
        const res = await axios.get(`${API_URL_NUPY}/sucursales/listByCompany/${idCompany}`);
        return res.data;
    },
}