import { distribucionRepository } from "../repositories/Distribucion.repository";

export const distribucionService = {
    register,
    update,
    getDistribucionByInsumo,
    deleteByIdProduct,
    deleteByIdInsumo,
    ListUnidadMedida,
}

async function register(cantidad: number, productos_x_cantidad: number, id_unidad_medida: number, id_insumo: number, id_producto: number){
    const res = await distribucionRepository.register(cantidad, productos_x_cantidad, id_unidad_medida, id_insumo, id_producto);
    return res;
}

async function update(id:number, cantidad: number){
    const res = await distribucionRepository.update(id, cantidad);
    return res;
}

async function getDistribucionByInsumo(idInsumo:number){
    const res = await distribucionRepository.getDistribucionByInsumo(idInsumo);
    return res;
}

async function deleteByIdProduct(idProducto:number){
    const res = await distribucionRepository.deleteByIdProduct(idProducto);
    return res;
}

async function deleteByIdInsumo(idInsumo:number){
    const res = await distribucionRepository.deleteByIdInsumo(idInsumo);
    return res;
}

async function ListUnidadMedida(){
    const res = await distribucionRepository.ListUnidadMedida();
    return res;
}