import { RecetaService } from "@/service/services/Receta.service";
import { Alert, Autocomplete, Button, CircularProgress, Divider, Grid, IconButton, Paper, Snackbar, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, TextField, Tooltip, Typography } from "@mui/material";
import React, { FC, useEffect, useState } from "react";
import { Popup } from "../Editor/components/Popup";
import { Form, Formik } from "formik";
import PriorityHighIcon from '@mui/icons-material/PriorityHigh';
import { KEY_EMPRESA } from "@/toolbox/constants/local-storage";
import { readLocalStorage } from "@/toolbox/helpers/local-storage-helper";
import { productoService } from "@/service/services/Producto.service";
import DeleteIcon from '@mui/icons-material/Delete';

interface RecetaModalProps {
    open: boolean
    setOpenPopup: React.Dispatch<React.SetStateAction<boolean>>
    action: string
    rowSelected: any
    onList: () => Promise<void>
    idproducto: number
}

type InsumoTable = {
    id?: number
    idinsumo: number
    insumo: string
    cantidad: number
}

export const RecetaModal: FC<RecetaModalProps> = (props) => {
    const { open, setOpenPopup, action, rowSelected, onList, idproducto } = props

    const dataEmpresa = readLocalStorage(KEY_EMPRESA);

    const [title, setTitle] = useState<string>('')
    const [data, setData] = useState({
        id: rowSelected?.id || 0,
        idproducto: rowSelected?.idproducto || 0,
        idinsumo: rowSelected?.idinsumo || 0,
        cantidad: rowSelected?.cantidad || 0,
    });
    const [snackBarConfig, setSnackBarConfig] = useState<any>({
        open: false,
        severity: 'error',
        message: 'Error',
        autoHideDuration: 3000,
    });
    const [loading, setLoading] = useState<boolean>(false)
    const [loadingSubmit, setLoadingSubmit] = useState<boolean>(false)
    const [insumos, setInsumos] = useState<any[]>([])
    const [insumoSelected, setInsumoSelected] = useState<any>(null)
    const [table, setTable] = useState<InsumoTable[]>([])
    const [page, setPage] = useState(0)
    const [rowsPerPage, setRowsPerPage] = useState(20)

    useEffect(() => {
        switch (action) {
            case 'create':
                setTitle('Crear producto')
                break;

            case 'edit':
                setTitle('Editar producto')
                break;

            default:
                break;
        }
    }, [action])

    useEffect(() => {
        getCombosBoxesAPI()
    }, [])

    useEffect(() => {
        if (action === 'edit' && rowSelected?.idinsumo && insumos.length > 0) {
            const insumo = insumos.find(insumo => insumo.id === rowSelected.idinsumo)
            setInsumoSelected(insumo || null)
        }
    }, [action, rowSelected, insumos])

    const getCombosBoxesAPI = async () => {
        setLoading(true)
        const response = await productoService.getProductosInsumo(dataEmpresa.id)
        setInsumos(response.productos || [])
        setLoading(false)
    }

    const validateForm = (values) => {
        let errors: any = {};

        if (action === 'edit' && values.idinsumo < 1) errors.idinsumo = "campo es requerido";
        if (action === 'edit' && values.cantidad < 0) errors.cantidad = "campo es requerido";

        return errors;
    }

    const onSubmit = async (values) => {
        setLoadingSubmit(true)
        try {
            let method = null
            if (action === 'create') {
                const payload = {
                    idproducto: idproducto,
                    insumos: table.map(value => { return { idinsumo: value.idinsumo, cantidad: value.cantidad } })
                }
                method = RecetaService.addInsumo(payload)
            } else {
                const payload = {
                    idproducto: idproducto,
                    idinsumo: values.idinsumo,
                    cantidad: values.cantidad
                }
                method = RecetaService.updateInsumo(rowSelected.id, payload)
            }
            const response = await method
            if (response?.status !== 200) {
                setSnackBarConfig(prev => ({
                    ...prev,
                    open: true,
                    message: response.message,
                }))
            } else {
                setOpenPopup(false)
                onList()
            }
        } catch (error) {
            console.error("Error:", error);
        }
        setLoadingSubmit(false)
    }

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const addInsumo = (insumo, cantidad: number) => {
        const exist = table.find(row => row.idinsumo === insumo.id)
        if (!exist) {
            const newInsumo = {
                idinsumo: insumo.id,
                insumo: insumo.nombre,
                cantidad: cantidad
            }
            setTable([...table, newInsumo])
            setInsumoSelected(null)
        }
    }

    const deleteInsumo = (idinsumo: number) => {
        const insumos = table.filter(row => row.idinsumo !== idinsumo)
        setTable(insumos || [])
    }

    return (<>
        <Snackbar
            open={snackBarConfig.open}
            autoHideDuration={snackBarConfig.autoHideDuration}
            onClose={() => setSnackBarConfig(prev => ({ ...prev, open: false }))}
            anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        >
            <Alert
                onClose={() => setSnackBarConfig(prev => ({ ...prev, open: false }))}
                severity={snackBarConfig.severity}
                variant="filled"
            >
                {snackBarConfig.message}
            </Alert>
        </Snackbar>

        <Popup
            largo="sm"
            title={title}
            openPopup={open}
            setOpenPopup={setOpenPopup}
        >
            <Formik enableReinitialize initialValues={data} validate={(values) => validateForm(values)} onSubmit={onSubmit}>
                {({ values, errors, touched, handleSubmit, handleChange, setFieldValue }) => {
                    return (
                        <Form onSubmit={handleSubmit}>
                            <Grid container spacing={3} alignItems="center" justifyContent="center">
                                <Grid item xs={12} container style={{ display: "flex", alignItems: "center" }}>
                                    <Grid item xs={4}>
                                        <Typography variant="subtitle1" className="custom-input"><b>Insumo:</b></Typography>
                                    </Grid>
                                    <Grid item xs={8} style={{ display: 'flex', alignItems: "center" }}>
                                        <Autocomplete
                                            key='cbx-insumo'
                                            id="cbx-insumo"
                                            size="small"
                                            value={insumoSelected || null}
                                            options={loading ? [] : insumos}
                                            getOptionLabel={(option) => option?.nombre || ''}
                                            isOptionEqualToValue={(option, value) => option.id === value?.id}
                                            renderInput={(params) =>
                                                <TextField {...params}
                                                    id="txt-insumo"
                                                    size="small"
                                                    InputProps={{
                                                        ...params.InputProps,
                                                        endAdornment: (
                                                            <React.Fragment>
                                                                {loading ? <CircularProgress color="inherit" size={20} /> : null}
                                                                {params.InputProps.endAdornment}
                                                            </React.Fragment>
                                                        ),
                                                    }}
                                                    error={errors.idinsumo && touched.idinsumo ? true : false}
                                                    autoFocus
                                                />}
                                            onChange={(e, newValue) => {
                                                setInsumoSelected(newValue)
                                                setFieldValue('idinsumo', newValue?.id || 0)
                                            }}
                                            fullWidth
                                            noOptionsText={!insumoSelected ? "Seleccione un insumo" : "Sin opciones"}
                                            readOnly={action === 'edit'}
                                        />
                                    </Grid>
                                </Grid>

                                <Grid item xs={12} container style={{ display: "flex", alignItems: "center" }}>
                                    <Grid item xs={4}>
                                        <Typography variant="subtitle1" className="custom-input"><b>Cantidad:</b></Typography>
                                    </Grid>
                                    <Grid item xs={8} style={{ display: 'flex', alignItems: "center" }}>
                                        {
                                            errors.cantidad && (touched.cantidad ? true : false) && (
                                                <Tooltip title={errors.cantidad}>
                                                    <PriorityHighIcon className={'iconWarning'} />
                                                </Tooltip>
                                            )
                                        }
                                        <TextField
                                            id="cantidad"
                                            type="number"
                                            name="cantidad"
                                            fullWidth
                                            value={values.cantidad}
                                            onChange={handleChange}
                                            placeholder="Cantidad del producto"
                                            error={errors.cantidad && touched.cantidad ? true : false}
                                            inputProps={{ min: 0 }}
                                        />
                                    </Grid>
                                </Grid>

                            </Grid>

                            {action === 'create' && <>
                                <Grid item xs={12} sx={{ textAlign: "right" }} mt={2}>
                                    <Button variant='outlined' style={{ textTransform: "capitalize" }} onClick={() => { addInsumo(insumoSelected, values.cantidad); setFieldValue('cantidad', 0) }}>Agregar</Button>
                                </Grid>
                                <Grid item xs={12} mt={2}>
                                    <TableContainer component={Paper}>
                                        <Table aria-label="simple table">
                                            <TableHead>
                                                <TableRow sx={{ bgcolor: '#1976D2' }}>
                                                    <TableCell align="left" sx={{ color: 'white' }}>{`N°`}</TableCell>
                                                    <TableCell align="left" sx={{ color: 'white' }}>Insumo</TableCell>
                                                    <TableCell align="left" sx={{ color: 'white' }}>Cantidad</TableCell>
                                                    <TableCell align="center" sx={{ color: 'white' }}>Opciones</TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {!!table.length
                                                    ?
                                                    table?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((insumo, index) =>
                                                        <TableRow key={index}>
                                                            <TableCell align="left">{index + 1 + page * rowsPerPage}</TableCell>
                                                            <TableCell align="left">{insumo.insumo}</TableCell>
                                                            <TableCell align="left">{insumo.cantidad}</TableCell>
                                                            <TableCell align="center">
                                                                <Tooltip title={"Eliminar insumo"}>
                                                                    <IconButton onClick={() => deleteInsumo(insumo.idinsumo)}>
                                                                        <DeleteIcon color="error" />
                                                                    </IconButton>
                                                                </Tooltip>
                                                            </TableCell>
                                                        </TableRow>
                                                    )
                                                    :
                                                    <TableCell align="center" colSpan={4}>Seleccione un insumo</TableCell>
                                                }
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                    <TablePagination
                                        rowsPerPageOptions={[20, 30, 40]}
                                        component="div"
                                        count={insumos?.length || 0}
                                        rowsPerPage={rowsPerPage}
                                        page={page}
                                        onPageChange={handleChangePage}
                                        onRowsPerPageChange={handleChangeRowsPerPage}
                                    />
                                </Grid>
                            </>}

                            <Divider sx={{ my: 2 }} />

                            <div className="form-group mb-3 buton_container_modal">
                                <button type="submit" className="btn btn-primary" disabled={loadingSubmit}>
                                    {action === 'create' ? 'Guardar' : 'Actualizar'}
                                </button>
                            </div>

                        </Form>
                    )
                }}
            </Formik>
        </Popup>
    </>)
}