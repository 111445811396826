import axios from 'axios';
import { API_URL_NUPY } from '@/toolbox/defaults/app';

export const CocinaRepository = {

    register: async(data:{}): Promise<any> => {
        const res = await axios.post(`${API_URL_NUPY}/cocina/register`, data);
        return res.data;
    },

    update: async(data:{}, idCocina:number): Promise<any> => {
          const res = await axios.post(`${API_URL_NUPY}/cocina/update/${idCocina}`, data);
          return res.data;
    },

    getCocinaById: async(idCocina: number): Promise<any> => {
        const res = await axios.get(`${API_URL_NUPY}/cocina/getCocinaById/${idCocina}`);
        return res.data;
    },

    getCocinas: async(): Promise<any> => {
        const res = await axios.get(`${API_URL_NUPY}/cocina/getAllCocinas`);
        return res.data;
    },

    getCocinasAvailable: async(): Promise<any> => {
        const res = await axios.get(`${API_URL_NUPY}/cocina/getCocinasAvailable`);
        return res.data;
    },

    editStatus: async(idCocina:number, state:number): Promise<any> => {
        const res = await axios.patch(`${API_URL_NUPY}/cocina/editStatus/${idCocina}/${state}`);
        return res.data;
    },
}