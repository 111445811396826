import React, { useState } from "react";
import { salas } from '../../../../service/services/Salas.service';
import { Loading } from '../../../../components/common/Loading/Loading';
import { Form, Formik } from 'formik';
import { Divider, Grid, TextField, Typography, Tooltip } from '@mui/material';
import PriorityHighIcon from '@mui/icons-material/PriorityHigh';
import { Snackbar, Alert } from '@mui/material';


export const AddMesa: React.FC<any> = (props:any): JSX.Element => {
    const [loadData,setLoadData] = React.useState<boolean>(false);
    const {sala, handleLoad, mesa, id_sala}= props;
    // eslint-disable-next-line
    const [data, setData] = useState({
        id: mesa && mesa.id,
        nombre: mesa && mesa.nombre,
        state: mesa && mesa.state,
        submitted: false,
    });

    const [snackBarConfig, setSnackBarConfig] = useState<any>({
        open: false,
        severity: 'error',
        message: 'Error',
        autoHideDuration: 3000,
    });

    const validateForm = (values) => {
        let errors: any = {};
        if(!values.nombre) errors.nombre = "nombre requerido";

        return errors;
    }

    const onSubmit = async(values, {resetForm}) =>{
        setLoadData(true);
        const state = 1;
        let urlMesa = window.location.origin;
        if(mesa){
            await salas.editMesa(values.id, values.nombre, id_sala, values.state, urlMesa).then((data) => {
                setLoadData(false);
                if (data && data.data) {
                   return handleLoad('editMesa');
                }
             })
        }else{
            await salas.addMesa(values.nombre, state, sala.id, urlMesa).then((data)=>{
                if(data.status === 400){
                    setSnackBarConfig(prev => ({
                        ...prev,
                        open: true,
                        message: `${data.error}`,
                    }));
                }else{
                    salas.editSala(sala.id,sala.nombre, sala.mesas+1, sala.state).then(()=>{
                        return handleLoad();
                    });
                }
            });
            
        }
        setLoadData(false);
    }

    return(
        <div className="container">
            {loadData && <Loading/>}

            <Snackbar
                open={snackBarConfig.open}
                autoHideDuration={snackBarConfig.autoHideDuration}
                onClose={() => setSnackBarConfig(prev => ({ ...prev, open: false }))}
                anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                >
                <Alert
                onClose={() => setSnackBarConfig(prev => ({ ...prev, open: false }))}
                severity={snackBarConfig.severity}
                variant="filled"
                >
                {snackBarConfig.message}
                </Alert>
            </Snackbar>

            <Formik enableReinitialize initialValues={data} validate={ (values) => validateForm(values) } onSubmit={onSubmit}>
                {({values, errors, touched, handleSubmit, handleChange, setFieldValue}) => {
                    return (
                        <Form onSubmit={handleSubmit} style={{width: '100%'}}>
                            <Grid container spacing={3} alignItems="center" justifyContent="center">

                                <Grid item xs={12} container style={{display: "flex", alignItems: "center"}}>
                                    <Grid item xs={4}>
                                        <Typography variant="subtitle1" className="custom-input"><b>Nombre:</b></Typography>
                                    </Grid>
                                    <Grid item xs={8} style={{display: 'flex', alignItems: "center"}}>
                                        {
                                            errors.nombre && touched.nombre ? true : false && (
                                                <Tooltip title={errors.nombre}>
                                                    <PriorityHighIcon className={'iconWarning'}/>
                                                </Tooltip>
                                            )
                                        }
                                        <TextField
                                        id="nombre"
                                        type="text"
                                        name="nombre"
                                        fullWidth
                                        value={values.nombre}
                                        onChange={handleChange}
                                        placeholder="Nombre de la mesa"
                                        error={errors.nombre && touched.nombre ? true : false}
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Divider sx={{ my: 2 }} />

                            <div className="form-group mb-3 buton_container_modal">
                                <button type="submit" className="btn btn-primary">
                                {mesa ? "Editar Mesa" : "Agregar Mesa"}
                                </button>
                            </div>
                        </Form>
                    )
                }}
            </Formik>
  </div>
    )
}
