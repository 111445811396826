import { Loading } from "@/components/common/Loading";
import { Alert, Autocomplete, Checkbox, Divider, FormControlLabel, FormGroup, Grid, MenuItem, RadioGroup, Select, Snackbar, TextField, Tooltip, Typography } from "@mui/material";
import { Form, Formik } from "formik";
import { useEffect, useState } from "react";
import PriorityHighIcon from '@mui/icons-material/PriorityHigh';
import { KEY_EMPRESA, TOKEN_API } from "@/toolbox/constants/local-storage";
import { useLocalStorage } from "@/toolbox/hooks/local-storage.hook";
import { productoService } from "@/service/services/Producto.service";
import { CuponesService } from "@/service/services/Cupones.service";

export const CuponModal: React.FC<any> = (props:any): JSX.Element => {
    const { closeModal, onListCupones, cuponSelected, detailMode } = props;
    const [token] = useLocalStorage(TOKEN_API, "");
    const [dataEmpresa] = useLocalStorage(KEY_EMPRESA, {
        id: 0, id_tipo_empresa:0, equivalencia_puntos: 0,
        id_country: 0, country_name: "", country_abbreviation: "", id_sucursal: 0
     });

    const [loading, setLoading] = useState<boolean>(false);
    const [valor, setValor] = useState<any>("");
    const [familias, setFamilia] = useState<Array<any>>([]);
    const [lineas, setLineas] = useState<Array<any>>([]);
    const [familiasSelected, setFamiliasSelected] = useState<any>({
        id: 0,
        nombre: "Familia",
        estado: 0,
        subFamilia: [],
    });
    const [byFamilia, setByFamilia] = useState<boolean>(false);
    const [bySubFamilia, setBySubFamilia] = useState<boolean>(false);
    const [byLinea, setByLinea] = useState<boolean>(false);
    const [byProductos, setByProductos] = useState<boolean>(false);
    const [productsByCompany, setProductsByCompany] = useState([]);

    const [data, setData] = useState<any>({
        id: "",
        fecha_inicio: "",
        fecha_fin: "",
        margen_descuento:  "",
        max_descuento: "",
        cantidad: "",
        monto: "",
        familia_id:"",
        sub_familia_id:"",
        linea_id: "",
        productos: "",
    });

    const [snackBarConfig, setSnackBarConfig] = useState<any>({
        open: false,
        severity: 'error',
        message: 'Error',
        autoHideDuration: 3000,
    });


    useEffect(() => {
        setLoading(true);
        async function fetch(){
            if(cuponSelected){
                if(cuponSelected.familia_id !== null) setByFamilia(true);
                if(cuponSelected.sub_familia_id !== null) setBySubFamilia(true);
                if(cuponSelected.linea_id !== null) setByLinea(true);
                const productos = JSON.parse(cuponSelected.productos);
                if(productos.length > 0) setByProductos(true);

                await productoService.getProductosNoInsumo(dataEmpresa.id).then((data) => {
                    if (data.status === 200) {
                        setProductsByCompany(data.productos);
                        setData({
                            id: cuponSelected ? cuponSelected.id : 0,
                            fecha_inicio: cuponSelected ? cuponSelected.fecha_inicio : "",
                            fecha_fin: cuponSelected ? cuponSelected.fecha_fin : "",
                            margen_descuento: cuponSelected ? cuponSelected.margen_descuento : "",
                            max_descuento: cuponSelected ? cuponSelected.max_descuento : "",
                            cantidad: cuponSelected ? cuponSelected.cantidad : "",
                            monto: cuponSelected ? cuponSelected.monto : "",
                            familia_id: cuponSelected ? cuponSelected.familia_id : "",
                            sub_familia_id: cuponSelected ? cuponSelected.sub_familia_id : "",
                            linea_id: cuponSelected ? cuponSelected.linea_id : "",
                            productos: setCustomProducts(cuponSelected.productos, data.productos) || [],
                        })
                    }
                });
            }else{
                setData({
                    id: "",
                    fecha_inicio: "",
                    fecha_fin: "",
                    margen_descuento:  "",
                    max_descuento: "",
                    cantidad: "",
                    monto: "",
                    familia_id:"",
                    sub_familia_id:"",
                    linea_id: "",
                    productos: "",
                });
            }
            setLoading(false);
        }
        fetch();
        if(!cuponSelected){
            getProductosEmpresa(dataEmpresa.id);
        }// eslint-disable-next-line
    }, []);

    useEffect(() => {
        getFamiliesAndSubFamilies();
        // fetch(`https://api2.softnet.cl/familia`, {
        //    method: 'GET',
        //    headers: {
        //       'Access-Control-Allow-Origin':'*',
        //       'Content-Type': 'application/x-www-form-urlencoded',
        //       token: token,
        //    },
        //    })
        //    .then(familiasData => familiasData.json())
        //    .then(async familiasData => {
        //       setFamilia(familiasData)
        //    })
        //    .catch(error => {
        //       return setSnackBarConfig(prev => ({
        //         ...prev,
        //         open: true,
        //         message: `No se cargaron Familia de productos`,
        //         }));
        //    })
           // eslint-disable-next-line
    }, [])

    const getFamiliesAndSubFamilies = async() =>{
        setLoading(true);
           await productoService.getFamiliesAndSubFamilies().then((data) => {
               if(data.status === 200){
                setFamilia(data.families || []);
               }else{
                setLoading(false);
                   return setSnackBarConfig(prev => ({
                       ...prev,
                       open: true,
                       message: `No se cargaron Familia de productos`,
                   }));
               }
           });
           setLoading(false);
    }

    useEffect(() => {
        getLinesProducts();
        // fetch(`https://api2.softnet.cl/linea_productos`, {
        //    method: 'GET',
        //    headers: {
        //       'Access-Control-Allow-Origin':'*',
        //       'Content-Type': 'application/x-www-form-urlencoded',
        //       token: token,
        //    },
        //    })
        //    .then(lineasData => lineasData.json())
        //    .then(async lineasData => {
        //       setLineas(lineasData)
        //    })
        //    .catch(error => {
        //       return setSnackBarConfig(prev => ({
        //         ...prev,
        //         open: true,
        //         message: `No se cargaron Lineas de productos`,
        //         }));
        //    })
           // eslint-disable-next-line
    }, []);

    const getLinesProducts = async() =>{
        setLoading(true);
        await productoService.getLinesProducts().then((data) => {
            if(data.status === 200){
                setLineas(data.lines || []);
            }else{
                setLoading(false);
                return setSnackBarConfig(prev => ({
                    ...prev,
                    open: true,
                    message: `No se cargaron lineas de productos`,
                }));
            }
        });
        setLoading(false);
    }

    function setCustomProducts(findIdProducts, products): any {
        const arrayIds = JSON.parse(findIdProducts);
        const data = arrayIds ? arrayIds.map( id => products.find(value => value.id === id)) : [];
        return getCustomProducts(data);
    }

    const getCustomProducts = (originalProducts) => {
        return originalProducts ? originalProducts.map(product => ({id: product.id, nombre: (product.nombre)})) : [];
    }

    const customProducts = getCustomProducts(productsByCompany || []);

    const getCustomFamilia = (oldFamily) => {
        const customFamily = familias.find(family => family.nombre === oldFamily);
        return  customFamily ? customFamily.nombre : oldFamily;
    }

    const getCustomSubFamilia = (oldSubFamily) => {
        let subFamilia;
        if(familias){
         // eslint-disable-next-line
            familias.map((familia) => {
                if(familia.subFamilias){
                    const customSubFamily = familia.subFamilias.find(family => family.nombre_subFamilia === oldSubFamily);
                    subFamilia = customSubFamily;
                }
            })
        }
        return subFamilia ? subFamilia : oldSubFamily;
    }

    const getProductosEmpresa = async (id_empresa) => {
        await productoService.getProductosNoInsumo(id_empresa).then((data) => {
           if (data.status === 200) {
            setProductsByCompany(data.productos);
           }
        });
        setLoading(false);
    }

    const handleChangeFamilia = (id) => {
        setFamiliasSelected({
            id: 0,
            nombre: "",
            subFamilia: [],
            estado: 0,
        })
        let familia = familias.find((f: any) => f.id === id);
        if (familia) {
            setFamiliasSelected(familia)
        }
    }

    const validateForm = (values) => {
        let errors: any ={};
        if(!values.margen_descuento) errors.margen_descuento = "valor requerido";
        if(!values.max_descuento) errors.max_descuento = "valor requerido";

        return errors;
    }

    const onSubmit = async(values, {resetForm}) => {
        setLoading(true);
        let arrayIdsProduct = [];
        const formData = new FormData();
        formData.append('fecha_inicio', values.fecha_inicio);
        formData.append('fecha_fin', values.fecha_fin);
        values.cantidad && values.cantidad!=='null' && formData.append('cantidad',values.cantidad);
        values.monto && values.monto!=='null' && formData.append('monto', values.monto);
        formData.append('margen_descuento', values.margen_descuento);
        formData.append('max_descuento', values.max_descuento);
        if(byFamilia) {
         values.familia_id&&values.familia_id!=='null' && formData.append('familia_id', values.familia_id);
        }
        values.sub_familia_id&&values.sub_familia_id!=='null' && formData.append('sub_familia_id', values.sub_familia_id);
        values.linea_id&&values.linea_id!=='null' && formData.append('linea_id', values.linea_id);
         // eslint-disable-next-line
        values.productos && values.productos.map((item) => {
            arrayIdsProduct.push(item.id);
        })
        formData.append('productos', JSON.stringify(arrayIdsProduct));

        if(cuponSelected){
            await CuponesService.update(formData, values.id).then((data) => {
                if(data.status === 200){
                    onListCupones && onListCupones();
                    closeModal && closeModal();
                }
            })
        }else{
            await CuponesService.register(formData).then((data) => {
                if(data.status === 200){
                    onListCupones && onListCupones();
                    closeModal && closeModal();
                }
            })
        }

        setLoading(false);
    }

    return (
        <div className="container">
            {loading && <Loading/>}
            <Snackbar
                open={snackBarConfig.open}
                autoHideDuration={snackBarConfig.autoHideDuration}
                onClose={() => setSnackBarConfig(prev => ({ ...prev, open: false }))}
                anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                >
                <Alert
                onClose={() => setSnackBarConfig(prev => ({ ...prev, open: false }))}
                severity={snackBarConfig.severity}
                variant="filled"
                >
                {snackBarConfig.message}
                </Alert>
            </Snackbar>
            <Formik enableReinitialize initialValues={data} validate={ (values) => validateForm(values) } onSubmit={onSubmit}>
                {({values, errors, touched, handleSubmit, handleChange, setFieldValue})=>{
                    return(
                        <Form onSubmit={handleSubmit}>
                            <Grid container spacing={3} alignItems="center" justifyContent="center">

                                <Grid item xs={12} container style={{ display: "flex", alignItems: "center" }}>
                                    <Grid item xs={4}>
                                        <Typography variant="subtitle1" className="custom-input"><b>Fecha de Inicio:</b></Typography>
                                    </Grid>
                                    <Grid item xs={8} style={{ display: 'flex', alignItems: "center" }}>
                                        <TextField
                                            id="fechaInicio"
                                            type="date"
                                            name="fecha_inicio"
                                            fullWidth
                                            value={values.fecha_inicio}
                                            onChange={handleChange}
                                            placeholder="Fecha de Inicio"
                                            disabled={detailMode}
                                        />
                                    </Grid>
                                </Grid>
                                <Grid item xs={12} container style={{ display: "flex", alignItems: "center" }}>
                                    <Grid item xs={4}>
                                        <Typography variant="subtitle1" className="custom-input"><b>Fecha de Fin:</b></Typography>
                                    </Grid>
                                    <Grid item xs={8} style={{ display: 'flex', alignItems: "center" }}>
                                        <TextField
                                            id="fechaFin"
                                            type="date"
                                            name="fecha_fin"
                                            fullWidth
                                            value={values.fecha_fin}
                                            onChange={handleChange}
                                            placeholder="Fecha de Inicio"
                                            disabled={detailMode}
                                        />
                                    </Grid>
                                </Grid>
                                <Grid item xs={12} container style={{display: "flex", alignItems: "center"}}>
                                    <Grid item xs={4}>
                                        <Typography variant="subtitle1" className="custom-input"><b>Margen desCuento:</b></Typography>
                                    </Grid>
                                    <Grid item xs={8} style={{display: 'flex', alignItems: "center"}}>
                                        {
                                            errors.margen_descuento && touched.margen_descuento ? true : false && (
                                                <Tooltip title={errors.margen_descuento}>
                                                    <PriorityHighIcon className={'iconWarning'}/>
                                                </Tooltip>
                                            )
                                        }
                                        <TextField
                                        id="margen_descuento"
                                        type="number"
                                        name="margen_descuento"
                                        fullWidth
                                        value={values.margen_descuento}
                                        onChange={handleChange}
                                        placeholder="margen de descuento"
                                        error={errors.margen_descuento && touched.margen_descuento ? true : false}
                                        disabled={detailMode}
                                        />
                                    </Grid>
                                </Grid>

                                <Grid item xs={12} container style={{display: "flex", alignItems: "center"}}>
                                    <Grid item xs={4}>
                                        <Typography variant="subtitle1" className="custom-input"><b>Máximo descuento:</b></Typography>
                                    </Grid>
                                    <Grid item xs={8} style={{display: 'flex', alignItems: "center"}}>
                                        {
                                            errors.max_descuento && touched.max_descuento ? true : false && (
                                                <Tooltip title={errors.max_descuento}>
                                                    <PriorityHighIcon className={'iconWarning'}/>
                                                </Tooltip>
                                            )
                                        }
                                        <TextField
                                        id="max_descuento"
                                        type="number"
                                        name="max_descuento"
                                        fullWidth
                                        value={values.max_descuento}
                                        onChange={handleChange}
                                        placeholder="maximo descuento"
                                        error={errors.max_descuento && touched.max_descuento ? true : false}
                                        disabled={detailMode}
                                        />
                                    </Grid>
                                </Grid>
                                {
                                    !cuponSelected && (
                                        <Grid container item xs={12} style={{display: "flex", alignItems: "center"}}>
                                            <Grid item xs={4}>
                                                <Typography variant="subtitle1"><b>Cupón por:</b> </Typography>
                                            </Grid>
                                            <Grid item xs={8}>
                                            <Select
                                            id="valor"
                                            value={values.id_tipoPersona}
                                            onChange={(event) => {
                                                setValor(event.target.value);
                                            }}
                                            fullWidth
                                            >
                                                <MenuItem value={"Monto"}>Monto</MenuItem>
                                                <MenuItem value={"Cantidad"}>Cantidad</MenuItem>
                                            </Select>
                                            </Grid>
                                        </Grid>
                                    )
                                }
                                {
                                    (valor === 'Monto' || (cuponSelected && cuponSelected.monto !== null)) && (
                                        <Grid item xs={12} container style={{display: "flex", alignItems: "center"}}>
                                            <Grid item xs={4}>
                                                <Typography variant="subtitle1" className="custom-input"><b>Monto:</b></Typography>
                                            </Grid>
                                            <Grid item xs={8} style={{display: 'flex', alignItems: "center"}}>
                                                <TextField
                                                id="monto"
                                                type="number"
                                                name="monto"
                                                fullWidth
                                                value={values.monto}
                                                onChange={handleChange}
                                                placeholder="monto"
                                                disabled={detailMode}
                                                />
                                            </Grid>
                                        </Grid>
                                    )
                                }
                                {
                                    (valor === 'Cantidad' || (cuponSelected && cuponSelected.cantidad !== null)) && (
                                        <Grid item xs={12} container style={{display: "flex", alignItems: "center"}}>
                                            <Grid item xs={4}>
                                                <Typography variant="subtitle1" className="custom-input"><b>Cantidad:</b></Typography>
                                            </Grid>
                                            <Grid item xs={8} style={{display: 'flex', alignItems: "center"}}>
                                                <TextField
                                                id="cantidad"
                                                type="number"
                                                name="cantidad"
                                                fullWidth
                                                value={values.cantidad}
                                                onChange={handleChange}
                                                placeholder="cantidad de productos"
                                                disabled={detailMode}
                                                />
                                            </Grid>
                                        </Grid>
                                    )
                                }
                                {
                                    !cuponSelected && (
                                        <Grid item xs={12} container style={{display: "flex", alignItems: "center"}}>
                                            <Grid item xs={4}>
                                                <Typography variant="subtitle1" className="custom-input"><b>Seleccionar Opción:</b></Typography>
                                            </Grid>
                                            <FormGroup>
                                                <RadioGroup
                                                    aria-labelledby="demo-radio-buttons-group-label"
                                                    name="radio-buttons-group"
                                                >
                                                    <FormControlLabel value={byFamilia}
                                                    control={<Checkbox checked={byFamilia} onChange={() => {
                                                        setByFamilia(true)
                                                        setBySubFamilia(false)
                                                        setByLinea(false)
                                                        setByProductos(false)
                                                    }}/>}
                                                    label="Por Familia" />

                                                    <FormControlLabel value="male"
                                                    control={<Checkbox checked={bySubFamilia} onChange={() => {
                                                        setBySubFamilia(true)
                                                        setByFamilia(false)
                                                        setByLinea(false)
                                                        setByProductos(false)
                                                    }}/>}
                                                    label="Por Sub Familia" />

                                                    <FormControlLabel value="other"
                                                    control={<Checkbox checked={byLinea} onChange={() => {
                                                        setByLinea(true)
                                                        setByFamilia(false)
                                                        setBySubFamilia(false)
                                                        setByProductos(false)
                                                    }}/>}
                                                    label="Por linea de Productos" />

                                                    <FormControlLabel value="other"
                                                    control={<Checkbox checked={byProductos} onChange={() => {
                                                        setByProductos(true)
                                                        setByFamilia(false)
                                                        setBySubFamilia(false)
                                                        setByLinea(false)
                                                    }}/>}
                                                    label="Por Productos" />
                                                </RadioGroup>
                                            </FormGroup>
                                        </Grid>
                                    )
                                }
                                {
                                    (byFamilia || bySubFamilia) && (
                                        <Grid container item xs={12} style={{display: "flex", alignItems: "center"}}>
                                            <Grid item xs={4}>
                                                <Typography variant="subtitle1"><b>Familia:</b> </Typography>
                                            </Grid>
                                            <Grid item xs={8} style={{display: 'flex', alignItems: "center", width: "230px"}}>
                                            <Select
                                                id="familia_id"
                                                value={values.familia_id}
                                                onChange={(event) => {
                                                    setFieldValue("familia_id", event.target.value);
                                                    handleChangeFamilia(event.target.value);
                                                }}
                                                disabled={detailMode}
                                                fullWidth
                                            >
                                                {
                                                    (familias || []).map((item, i) => {
                                                        var formatFamily = {...item, nombre: getCustomFamilia(item.nombre)};
                                                        return <MenuItem key={i} value={formatFamily.id}>{formatFamily.nombre}</MenuItem>;
                                                    })
                                                }
                                            </Select>
                                            </Grid>
                                        </Grid>
                                    )
                                }
                                {
                                  // eslint-disable-next-line
                                    (bySubFamilia && familiasSelected?.subFamilias || (cuponSelected && cuponSelected.sub_familia_id !== null)) && (
                                        <Grid container item xs={12} style={{display: "flex", alignItems: "center"}}>
                                            <Grid item xs={4}>
                                                <Typography variant="subtitle1"><b>Sub Familia:</b> </Typography>
                                            </Grid>
                                            <Grid item xs={8} style={{display: 'flex', alignItems: "center", width: "230px"}}>
                                            <Select
                                                id="sub_familia_id"
                                                value={values.sub_familia_id}
                                                onChange={(event) => {
                                                    setFieldValue("sub_familia_id", event.target.value);
                                                }}
                                                fullWidth
                                                disabled={detailMode}
                                            >
                                                {
                                                    (familiasSelected?.subFamilias|| []).map((item, i) => {
                                                        var formatSubFamily = {...item, nombre_subFamilia: getCustomSubFamilia(item.nombre_subFamilia)};
                                                        return <MenuItem key={i} value={formatSubFamily.id}>{formatSubFamily.nombre_subFamilia}</MenuItem>;
                                                    })
                                                }
                                            </Select>
                                            </Grid>
                                        </Grid>
                                    )
                                }
                                {
                                    byLinea && (
                                        <Grid container item xs={12} style={{display: "flex", alignItems: "center"}}>
                                            <Grid item xs={4}>
                                                <Typography variant="subtitle1"><b>Linea de producto:</b> </Typography>
                                            </Grid>
                                            <Grid item xs={8} style={{display: 'flex', alignItems: "center", width: "230px"}}>
                                                <Select
                                                id="linea_id"
                                                value={values.linea_id}
                                                onChange={(event) => {
                                                    setFieldValue("linea_id", event.target.value);
                                                }}
                                                fullWidth
                                                disabled={detailMode}
                                                >
                                                    {
                                                        (lineas|| []).map((item, i) => {
                                                            return <MenuItem key={i} value={item.id}>{item.nombre}</MenuItem>;
                                                        })
                                                    }
                                                </Select>
                                            </Grid>
                                        </Grid>
                                    )
                                }
                                {
                                    byProductos && (
                                        <Grid item xs={12} container style={{display: "flex", alignItems: "center"}}>
                                            <Grid item xs={4}>
                                                <Typography variant="subtitle1" className="custom-input"><b>Productos:</b></Typography>
                                            </Grid>
                                            <Grid item xs={8}>
                                                <Autocomplete
                                                    multiple={true}
                                                    disablePortal
                                                    id="combo-box-demo"
                                                    value={values.productos || []}
                                                    options={customProducts || []}
                                                    getOptionLabel={(option) => option.nombre || ""}
                                                    fullWidth
                                                    renderInput={(params) => <TextField {...params} label="Escriba para buscar" />}
                                                    onChange={(e, newValue) => {
                                                        setFieldValue("productos", newValue);
                                                    }}
                                                    disabled={detailMode}
                                                />
                                            </Grid>
                                        </Grid>
                                    )
                                }

                            </Grid>
                            <Divider sx={{ my: 2 }} />
                            <div className="form-group mb-3 buton_container_modal">
                                <button type="submit" className="btn btn-primary">
                                    {cuponSelected ? "Editar Cupon" :"Agregar Cupon" }
                                </button>
                            </div>
                        </Form>
                    )
                }}
            </Formik>
        </div>
    );
}
