import { Props } from "./Sucursales.type";
import { Box, Button, Container, createTheme, Divider, FormControlLabel, Grid, InputAdornment, Paper, Switch, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, TextField, ThemeProvider, Typography } from "@mui/material";
import { Protected } from "@/components/layout/Protected";
import { esES } from "@mui/material/locale";
import { PencilIcon } from "@/toolbox/constants/icons";
import VisibilityIcon from '@mui/icons-material/Visibility';
import React, { useEffect, useState } from "react";
import { Loading } from "@/components/common/Loading";
import { sucursalesService } from "@/service/services/Sucursales.service";
import { useLocalStorage } from '@/toolbox/hooks/local-storage.hook';
import { KEY_USER_DATA } from '@/toolbox/constants/local-storage';
import { Popup } from "../Editor/components/Popup";
import { AddSucursal } from "./componentes/AddSucursal";
import SearchIcon from "@mui/icons-material/Search";

const theme = createTheme(
    {
        palette: {
            primary: { main: '#1976D2' },
        },
    },
    esES,
);

export const Sucursales: React.FC<Props> = (prop: Props): JSX.Element => {

    const [loading, setLoading] = React.useState<boolean>(false);

    const [openPopup, setOpenPopup] = React.useState<boolean>(false);
    const [popUp, setPopUp] = React.useState<String>('');
    const [editMode, setEditMode] = React.useState<boolean>(false);

    const [sucursales, setSucursales] = React.useState([]);
    const [sucursalesBackUp, setSucursalesBackUp] = useState<any[]>([])
    const [selectedSucursal, setSelectedSucursal] = React.useState<any>('');
    const [rowsPerPage, setRowsPerPage] = useState(20);
    const [page, setPage] = useState(0);
    const [dataUser] = useLocalStorage(KEY_USER_DATA, undefined);
    const rol = dataUser && dataUser.user.rol;
    const isSuperAdmin = rol === 'Superadmin';

    useEffect(() => {
        getSucursales();
    }, []);

    const getSucursales = async () => {
        setLoading(true);
        await sucursalesService.listAllSucursales().then((data) => {
            setSucursales(data);
            setSucursalesBackUp(data)
            setLoading(false);
        })
    }

    const funcionSwitch = async (sucursal) => {
        setLoading(true);
        if (sucursal.state === 1) {
            await sucursalesService.editState(sucursal.id, 0);
        } else {
            await sucursalesService.editState(sucursal.id, 1);
        }
        getSucursales();

    }

    const onSearch = (search: string) => {
        const searchLowerCase = search.trim().toLowerCase();
        if (search !== '') {
            const filteredProducts = sucursalesBackUp.filter(product => {
                const nombre = product.nombre && product.nombre.toLowerCase();
                return (
                    (nombre && nombre.includes(searchLowerCase))
                );
            });
            setSucursales(filteredProducts);
        } else {
            setSucursales(sucursalesBackUp);
        }
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    return (
        <Protected>
            {loading && <Loading />}
            <Grid container spacing={2}>
                <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'center' }}>
                    <Typography variant="h4">Sucursales</Typography>
                </Grid>
                <Grid item container xs={12}
                    sx={{
                        alignItems: "center",
                        display: "flex",
                        justifyContent: "space-between",
                        flexWrap: "wrap",
                        marginBottom: "10px",
                    }}
                >
                    <Grid item>
                        <TextField
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <SearchIcon fontSize="small" />
                                    </InputAdornment>
                                ),
                            }}
                            placeholder="Buscar..."
                            size="small"
                            onChange={(e) => onSearch(e.target.value)}
                        />
                    </Grid>
                    <Grid
                        item
                        sx={{ textAlign: "right" }}
                    >
                        <Button variant='outlined' style={{ textTransform: "capitalize" }}
                            onClick={() => {
                                setOpenPopup(true);
                                setEditMode(true);
                                setPopUp('addSucursal');
                            }}
                        >Nueva sucursal</Button>
                    </Grid>
                </Grid>
                <Grid item xs={12}>
                    <Box sx={{ Width: '100%' }}>
                        <TableContainer component={Paper}>
                            <ThemeProvider theme={theme}>
                                <Table aria-label="simple table" size='small'>
                                    <TableHead>
                                        <TableRow sx={{ bgcolor: '#1976D2' }}>

                                            <TableCell sx={{ color: 'white' }} >
                                                <strong>N°</strong>
                                            </TableCell>
                                            <TableCell align='center' sx={{ color: 'white' }} >
                                                <strong>Nombre</strong>
                                            </TableCell>
                                            <TableCell align='center' sx={{ color: 'white' }} >
                                                <strong>Dirección</strong>
                                            </TableCell>
                                            {
                                                isSuperAdmin && (
                                                    <TableCell align='center' sx={{ color: 'white' }} >
                                                        <strong>Empresa</strong>
                                                    </TableCell>
                                                )
                                            }
                                            <TableCell align='center' sx={{ color: 'white' }} >
                                                <strong>Estado</strong>
                                            </TableCell>
                                            <TableCell align='center' sx={{ color: 'white', minWidth: '8rem', maxWidth: '12rem' }}><strong>Acciones</strong></TableCell>

                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {!!sucursales.length
                                            ?
                                            sucursales?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((sucursal, id)  => (
                                                <TableRow key={id}>
                                                    <TableCell component="th" scope="row">
                                                        {id + 1}
                                                    </TableCell>
                                                    <TableCell align='center' component="th" scope="row">
                                                        {sucursal.nombre.toLowerCase()}
                                                    </TableCell>
                                                    <TableCell align='center' component="th" scope="row">
                                                        {sucursal.direccion}
                                                    </TableCell>
                                                    {
                                                        isSuperAdmin && (
                                                            <TableCell align='center' component="th" scope="row">
                                                                {sucursal.empresa.name}
                                                            </TableCell>
                                                        )
                                                    }

                                                    <TableCell align='center' component="th" scope="row">
                                                        <Box>
                                                            <FormControlLabel
                                                                control={<Switch onClick={() => {
                                                                    funcionSwitch(sucursal);
                                                                }}
                                                                />}
                                                                checked={sucursal.state === 1 ? true : false}
                                                                label=""
                                                            />
                                                        </Box>
                                                    </TableCell>
                                                    <TableCell align='center'>
                                                        <Button
                                                            onClick={() => {
                                                                setOpenPopup(true);
                                                                setEditMode(true);
                                                                setPopUp('editSucursal');
                                                                setSelectedSucursal(sucursal);
                                                            }}
                                                        >
                                                            <PencilIcon />
                                                        </Button>

                                                        <Button
                                                            onClick={() => {
                                                                setOpenPopup(true);
                                                                setEditMode(false);
                                                                setPopUp('detailSucursal');
                                                                setSelectedSucursal(sucursal);
                                                            }}
                                                        >
                                                            <VisibilityIcon />
                                                        </Button>
                                                        {/* <Button
                                                                onClick={() =>  {
                                                                    setOpenPopup(true);
                                                                    setEditMode(true);
                                                                    setPopUp('addListPrice');
                                                                    setSelectedSucursal(sucursal);
                                                                }}
                                                            >
                                                                <ListAltIcon color='secondary'/>
                                                            </Button>
                                                            <Button
                                                                onClick={() =>  {
                                                                    setOpenPopup(true);
                                                                    setEditMode(true);
                                                                    setPopUp('listPrice');
                                                                    setSelectedSucursal(sucursal);
                                                                }}
                                                            >
                                                                <SettingsIcon color='secondary'/>
                                                            </Button> */}
                                                    </TableCell>
                                                </TableRow>
                                            ))
                                            :
                                            <TableRow>
                                                <TableCell align="center" colSpan={5}>Sin resultados</TableCell>
                                            </TableRow>
                                        }
                                    </TableBody>
                                </Table>
                            </ThemeProvider>
                        </TableContainer>
                        <TablePagination
                            rowsPerPageOptions={[20, 30, 40]}
                            component="div"
                            count={sucursales?.length || 0}
                            rowsPerPage={rowsPerPage}
                            page={page}
                            onPageChange={handleChangePage}
                            onRowsPerPageChange={handleChangeRowsPerPage}
                        />
                    </Box>
                </Grid>

            </Grid>
            {
                popUp === 'addSucursal' && (
                    <Popup
                        largo="xs"
                        title='Agregar Sucursal'
                        openPopup={openPopup}
                        setOpenPopup={setOpenPopup}
                    >
                        <AddSucursal
                            closeModal={() => { setOpenPopup(false) }}
                            onListSucursales={getSucursales}
                            editMode={editMode}
                        />

                    </Popup>
                )
            }
            {
                popUp === 'editSucursal' && (
                    <Popup
                        largo="xs"
                        title='Editar Sucursal'
                        openPopup={openPopup}
                        setOpenPopup={setOpenPopup}
                    >
                        <AddSucursal
                            closeModal={() => { setOpenPopup(false) }}
                            sucursal={selectedSucursal}
                            onListSucursales={getSucursales}
                            editMode={editMode}
                        />

                    </Popup>
                )
            }
            {
                popUp === 'detailSucursal' && (
                    <Popup
                        largo="sm"
                        title='Detalle'
                        openPopup={openPopup}
                        setOpenPopup={setOpenPopup}
                    >
                        <AddSucursal
                            closeModal={() => { setOpenPopup(false) }}
                            sucursal={selectedSucursal}
                            editMode={editMode}
                        />

                    </Popup>
                )
            }
            {/* {
                popUp === 'addListPrice' && (
                    <Popup
                        largo="md"
                        title = {'Lista de Precios - '+ selectedSucursal.nombre.toLowerCase()}
                        openPopup = {openPopup}
                        setOpenPopup = {setOpenPopup}
                    >
                        <AddListPrice
                            closeModal={() => {setOpenPopup(false)}}
                            sucursal = {selectedSucursal}
                            editMode = {editMode}
                        />

                    </Popup>
                )
            }
            {
                popUp === 'listPrice' && (
                    <Popup
                        largo="sm"
                        title = {'Ajustes Lista de Precios'}
                        openPopup = {openPopup}
                        setOpenPopup = {setOpenPopup}
                    >
                        <ListPrice
                            closeModal={() => {setOpenPopup(false)}}
                            sucursal = {selectedSucursal}
                            editMode = {editMode}
                        />

                    </Popup>
                )
            } */}
        </Protected>
    );
}
