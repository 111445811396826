import axios from 'axios';
import { API_URL_NUPY } from '@/toolbox/defaults/app';

export const cuadraturaRepository = {
    abrirCuadratura: async (responsable_id: number, type: string, monto: number, empresa_id: number, id_caja): Promise<any> => {
        const res = await axios.post(`${API_URL_NUPY}/cuadraturas/abrir_cuadratura`, {
            monto_inicial: monto,
            id_responsable: responsable_id ? responsable_id : null,
            type: type,
            id_empresa: empresa_id,
            id_caja: id_caja
        });

        return res.data;
    },
    getActualCuadratura: async (id_empresa: number, id_responsable: number): Promise<any> => {
        const res = await axios.get(`${API_URL_NUPY}/cuadraturas/get_cuadratura_actual/${id_empresa}/${id_responsable}`);
        return res.data;
    },
    getActualCuadraturaRooms: async (id_empresa: number, id_sala: number): Promise<any> => {
        const res = await axios.get(`${API_URL_NUPY}/cuadraturas/get_cuadratura_actual_salas/${id_empresa}/${id_sala}`);
        return res.data;
    },
    getUltimaCuadratura: async (id_empresa: number, id_responsable: number): Promise<any> => {
        const res = await axios.get(`${API_URL_NUPY}/cuadraturas/get_cuadratura_ultima/${id_empresa}/${id_responsable}`);
        return res.data;
    },
    getUltimaCuadraturaRooms: async (id_empresa: number, id_sala: number): Promise<any> => {
        const res = await axios.get(`${API_URL_NUPY}/cuadraturas/get_cuadratura_ultima_salas/${id_empresa}/${id_sala}`);
        return res.data;
    },
    getCuadraturaAjuste: async (id_cuadratura: number): Promise<any> => {
        const res = await axios.get(`${API_URL_NUPY}/cuadraturas/get_cuadratura_ajuste/${id_cuadratura}`);
        return res.data;
    },
    getCuadraturaDescuadre: async (id_cuadratura: number): Promise<any> => {
        const res = await axios.get(`${API_URL_NUPY}/cuadraturas/get_cuadratura_descuadre/${id_cuadratura}`);
        return res.data;
    },

    cerrarCuadratura: async (id: number, monto_final: number, idsVentasJSON, total_ajuste: number, total_cierre_efectivo: number, detalleMov, detalleDescuadre,
        monto_final_real, total_descuadre, total_descuadre_efectivo, total_ventas, total_ventas_efectivo): Promise<any> => {
        const res = await axios.patch(`${API_URL_NUPY}/cuadraturas/cerrar_cuadratura/${id}`, {
            monto_final: monto_final,
            ids_ventas: idsVentasJSON,
            total_ajuste: total_ajuste,
            total_cierre_efectivo: total_cierre_efectivo,
            detalleMov: detalleMov,
            detalleDescuadre: detalleDescuadre,
            monto_final_real, total_descuadre, total_descuadre_efectivo, total_ventas, total_ventas_efectivo
        });
        return res.data;
    },

    montoCuadratura: async (id_responsable: number, id_cuadratura): Promise<any> => {
        const res = await axios.get(`${API_URL_NUPY}/cuadraturas/montoCuadratura/${id_responsable}/${id_cuadratura}`);
        return res.data;
    },

    getCierresDiarios: async (data?: any): Promise<any> => {
        const res = await axios.get(`${API_URL_NUPY}/cuadraturas/cierreDiario`, { params: data })
        return res.data;
    },

    getPDF: async (id: number) => {
        const res = await axios.get(`${API_URL_NUPY}/cuadraturas/getPDF/${id}`, { responseType: 'blob' })
        return res.data
    },

    getPDFCierreDiario: async (data?: any) => {
        const res = await axios.get(`${API_URL_NUPY}/cuadraturas/getCierreDiarioPDF`, { responseType: 'blob',  params: data })
        return res.data
    },
}
