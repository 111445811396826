import { API_URL_NUPY } from '@/toolbox/defaults/app';
import axios from 'axios';

export const listaPrecioRepository = {
    register: async(data:{}): Promise<any> => {
        const res = await axios.post(`${API_URL_NUPY}/listaPrecios/addListaPrecio`, data);
        return res.data;
    },
    addDetalleProductoPrecio: async(data:{}): Promise<any> => {
        const res = await axios.post(`${API_URL_NUPY}/listaPrecios/addDetalleProductoPrecio`, data);
        return res.data;
    },
    getAjustesListaPrecio: async(id): Promise<any> => {
        const res = await axios.get(`${API_URL_NUPY}/listaPrecios/getAjustesListaPrecio/${id}`);
        return res.data;
    },
    getListaPrecioEmpresa: async(id_empresa): Promise<any> => {
        const res = await axios.get(`${API_URL_NUPY}/listaPrecios/getListaPrecio/empresa/${id_empresa}`);
        return res.data;
    },
    getListaPrecio: async(id): Promise<any> => {
        const res = await axios.get(`${API_URL_NUPY}/listaPrecios/getListaPrecio/${id}`);
        return res.data
    },
    getListaPrecioByProduct: async(idProducto): Promise<any> => {
        const res = await axios.get(`${API_URL_NUPY}/listaPrecios/getListaPrecioByProduct/${idProducto}`);
        return res.data
    },
    editAjusteListaPrecios: async(data, id): Promise<any> => {
        const res = await axios.put(`${API_URL_NUPY}/listaPrecios/editAjusteListaPrecio/${id}`,data);
        return res.data;
    },
    editListaPrecioDetalle: async(data, id): Promise<any> => {
        const res = await axios.put(`${API_URL_NUPY}/listaPrecios/editListaPrecio/detalleProducto/${id}`,data);
        return res.data;
    },
    getCompraDetalleEmpresa: async(id_empresa): Promise<any> => {
        const res = await axios.get(`${API_URL_NUPY}/compraDetalle/listCompraByEmpresa/${id_empresa}`);
        return res.data;
    },
    getProductosListaPrecio: async(id_empresa, id_usuario): Promise<any> => {
        const res = await axios.get(`${API_URL_NUPY}/listaPrecios/getProductosListaPrecio?id_empresa=${id_empresa}&id_usuario=${id_usuario}`);
        return res.data;
    },
    getListaPrecioByUsuario: async(id_usuario): Promise<any> => {
        const res = await axios.get(`${API_URL_NUPY}/listaPrecios/getListaPrecioByUsuario/${id_usuario}`);
        return res.data;
    }
}
