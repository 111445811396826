import axios from 'axios';
import { API_URL_NUPY } from '@/toolbox/defaults/app';
import { KEY_USER_DATA } from '@constants/local-storage';
import { readLocalStorage } from '@helpers/local-storage-helper';

export const ticketRepository = {
    saveTicket: async (n_ticket: string, monto_pedido: number, state: string): Promise<any> => {
        const res = await axios.post(`${API_URL_NUPY}/ticket/crear_ticket`, {
            n_ticket,
            monto_pedido,
            state,
            id_user: readLocalStorage(KEY_USER_DATA).user?.id_usuario,
            // id_user: 1,
        });
        return res.data;
    },
    editTicket: async (idTicket: number, monto_pedido: number, state: number): Promise<any> => {
        const res = await axios.put(`${API_URL_NUPY}/ticket/edit_ticket/${idTicket}`, {
            monto_pedido,
            state,
        });
        return res.data;
    },
    getTicket: async (idTicket: number): Promise<any> => {
        const res = await axios.get(`${API_URL_NUPY}/ticket/get_ticket/${idTicket}`);
        return res.data;
    },
    deleteTicket: async (idTicket: number): Promise<any> => {
        const res = await axios.delete(`${API_URL_NUPY}/ticket/delete_ticket/${idTicket}`);
        return res.data;
    },

    // TICKET DETAIL
    saveTicketDetail: async (id_ticket_pedido: number, id_producto: number, cantidad: number, state: number, descuento: number, precio_unitario: number, subProductos = ""): Promise<any> => {
        const res = await axios.post(`${API_URL_NUPY}/ticketDetalle/crear_detalle`, {
            id_ticket_pedido,
            id_producto,
            cantidad,
            state,
            descuento,
            precio_unitario,
            subProductos: subProductos ? JSON.stringify(subProductos) : null,
        });
        return res.data;
    },
    editTicketDetail: async (idTicket: number, cantidad: number, state: number): Promise<any> => {
        const res = await axios.put(`${API_URL_NUPY}/ticketDetalle/editar_detalle/${idTicket}`, {
            cantidad,
            state,
        });
        return res.data;
    },
    getTicketDetail: async (idTicket: number): Promise<any> => {
        const res = await axios.get(`${API_URL_NUPY}/ticketDetalle/get_detalle/${idTicket}`);
        return res.data;
    },
    deleteTicketDetail: async (idTicket: number): Promise<any> => {
        const res = await axios.delete(`${API_URL_NUPY}/ticketDetalle/delete_detalle/${idTicket}`);
        return res.data;
    },

    getTickets: async (state: number, fecha: any): Promise<any> => {
        const res = await axios.get(`${API_URL_NUPY}/ticket/get_tickets/${state}/${fecha}`);
        return res.data;
    },

    getTicketsFecha: async (state: any, fecha: any): Promise<any> => {
        const res = await axios.get(`${API_URL_NUPY}/pedido/get_ticket_fecha/${state}/${fecha}`);
        return res.data;
    },

    getPedidos: async (state: any, fecha: any): Promise<any> => {
        const res = await axios.get(`${API_URL_NUPY}/pedido/get_pedido_fecha/${state}/${fecha}`);
        return res.data;
    },

    deleteDetalleTicket: async (id_ticket: number): Promise<any> => {
        const res = await axios.delete(`${API_URL_NUPY}/ticketDetalle/delete_detalle_ticket/${id_ticket}`);
        return res.data;
    }
}


