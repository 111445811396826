import React from "react";
import { Divider, Grid, TextField, Typography, Tooltip } from "@mui/material";
import { Form, Formik } from "formik";
import { Loading } from "@/components/common/Loading";
import { useLocalStorage } from '@/toolbox/hooks/local-storage.hook';
import { KEY_USER_DATA } from '@/toolbox/constants/local-storage';
import {KEY_EMPRESA} from '@constants/local-storage';
import {readLocalStorage} from '@helpers/local-storage-helper';
import { proveedorService } from "@/service/services/Proveedor.service";
import PriorityHighIcon from '@mui/icons-material/PriorityHigh';
import { Snackbar, Alert } from '@mui/material';

export const ModalProveedor: React.FC<any> = (props:any): JSX.Element => {
    const {closeModal, onListProveedor, proveedor, editMode} = props;

    const [loading, setLoading] = React.useState<boolean>(false);
    // eslint-disable-next-line
    const [data, setData] = React.useState({
        id: proveedor && proveedor.id ? proveedor.id : "",
        razon_social: proveedor && proveedor.razon_social ?proveedor.razon_social : "",
        rut: proveedor && proveedor.rut ? proveedor.rut : "",
        state: proveedor && proveedor.state ? proveedor.state : "",
        observacion: proveedor && proveedor.observacion ? proveedor.observacion : "",
        id_empresa: proveedor && proveedor.id_empresa ? proveedor.id_empresa : "",
    });

    const [dataUser] = useLocalStorage(KEY_USER_DATA, undefined);
    const userSession = dataUser && dataUser.user;

    const [snackBarConfig, setSnackBarConfig] = React.useState<any>({
        open: false,
        severity: 'error',
        message: 'Error',
        autoHideDuration: 3000,
     });

    const SessionEmpresa: any = readLocalStorage(KEY_EMPRESA);
    const pais = SessionEmpresa.country_abbreviation;

    var Fn = {
      // Valida el rut con su cadena completa "XXXXXXXX-X"
      validaRut : function (rutCompleto) {
         rutCompleto = rutCompleto.replace("‐","-");
         if (!/^[0-9]+[-|‐]{1}[0-9kK]{1}$/.test( rutCompleto ))
            return false;
         var tmp 	= rutCompleto.split('-');
         var digv	= tmp[1];
         var rut 	= tmp[0];
         if ( digv === 'K' ) digv = 'k' ;

         return (Number(Fn.dv(rut)) === Number(digv) );
      },
      dv : function(T){
         var M=0,S=1;
         for(;T;T=Math.floor(T/10))
            S=(S+T%10*(9-M++%6))%11;
         return S?S-1:'k';
      }
   }

    const validateForm = (values) => {
        let errors: any = {};

        const formats = {
            name: /^[A-Za-zÑñÁáÉéÍíÓóÚúÜü\s]+$/,
            rutChile: /^[0-9]{8,9}[-|‐]{1}[0-9kK]{1}$/,
            rutPeru: /^\d{11}$/,
            rutColombia: /^[0-9]{9}[-|‐]{1}[0-9]{1}$/,
        }

        if(!values.razon_social) errors.razon_social = "razon social requerido";
        if(values.razon_social && !formats.name.test(values.razon_social)) errors.razon_social = "razon social inválido";

        if(!values.rut) errors.rut = "RUT requerido";
        // if(pais==='CLP' && values.rut){
        //     if (!(Fn.validaRut(values.rut)) && !proveedor) { errors.rut = "Rut inválido 'xxxxxxxx-x'";}
        // };
        // if(pais==='CLP' && values.rut && !formats.rutChile.test(values.rut)) errors.rut = "Rut inválido";
        // if(pais==='PEN' && values.rut && !formats.rutPeru.test(values.rut)) errors.rut = "Rut inválido";
        // if(pais==='COP' && values.rut && !formats.rutColombia.test(values.rut)) errors.rut = "Rut inválido";

        return errors;
    }

    const onSubmit = async(values, {resetForm}) => {
        const formData = new FormData();

        formData.append('razon_social', values.razon_social);
        formData.append('observacion', values.observacion);

        setLoading(true);
        if(proveedor && editMode){
            //Editar
            await proveedorService.update(formData, values.id).then((data) => {
                onListProveedor && onListProveedor();
                closeModal();
            })
        }else{
            //Agregar
            formData.append('rut', values.rut);
            formData.append('id_empresa', userSession.id_empresa);
            await proveedorService.register(formData).then((data) => {
                if(data.status === 400){
                    setSnackBarConfig(prev => ({
                        ...prev,
                        open: true,
                        message: `${data.error}`,
                    }));
                }else{
                    onListProveedor && onListProveedor();
                    props.proveedorSelected && props.proveedorSelected({
                        "label": data?.razon_social,
                        "key": data?.id,
                     });
                    closeModal();
                }

            })
        }

        setLoading(false);
    }

    return (
        <div className="container">
            {loading && <Loading/>}

            <Snackbar
                open={snackBarConfig.open}
                autoHideDuration={snackBarConfig.autoHideDuration}
                onClose={() => setSnackBarConfig(prev => ({ ...prev, open: false }))}
                anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                >
                <Alert
                onClose={() => setSnackBarConfig(prev => ({ ...prev, open: false }))}
                severity={snackBarConfig.severity}
                variant="filled"
                >
                {snackBarConfig.message}
                </Alert>
            </Snackbar>

            <Formik enableReinitialize initialValues={data} validate={ (values) => validateForm(values) } onSubmit={onSubmit}>
                {({values, errors, touched, handleSubmit, handleChange, setFieldValue}) => {
                    return(
                        <Form onSubmit={handleSubmit}>
                            <Grid container spacing={3} alignItems="center" justifyContent="center">

                            <Grid item xs={12} container style={{display: "flex", alignItems: "center"}}>
                                <Grid item xs={4}>
                                    <Typography variant="subtitle1" className="custom-input"><b>Razon Social:</b></Typography>
                                </Grid>
                                <Grid item xs={8} style={{display: 'flex', alignItems: "center"}}>
                                    {
                                        errors.razon_social && (touched.razon_social ? true : false) && (
                                            <Tooltip title={errors.razon_social}>
                                                <PriorityHighIcon className={'iconWarning'}/>
                                            </Tooltip>
                                        )
                                    }
                                    <TextField
                                    id="razon_social"
                                    type="text"
                                    name="razon_social"
                                    fullWidth
                                    value={values.razon_social}
                                    onChange={handleChange}
                                    placeholder="Razon social del proveedor"
                                    error={errors.razon_social && touched.razon_social ? true : false}
                                    disabled = {!editMode}
                                    />
                                </Grid>
                            </Grid>

                            {
                                (!proveedor || !editMode) && (
                                    <Grid item xs={12} container style={{display: "flex", alignItems: "center"}}>
                                        <Grid item xs={4}>
                                            <Typography variant="subtitle1" className="custom-input"><b>RUT:</b></Typography>
                                        </Grid>
                                        <Grid item xs={8} style={{display: 'flex', alignItems: "center"}}>
                                            {
                                                errors.rut && (touched.rut ? true : false) && (
                                                    <Tooltip title={errors.rut}>
                                                        <PriorityHighIcon className={'iconWarning'}/>
                                                    </Tooltip>
                                                )
                                            }
                                            <TextField
                                            id="rut"
                                            type="text"
                                            name="rut"
                                            fullWidth
                                            value={values.rut}
                                            onChange={handleChange}
                                            placeholder={pais && (pais==='PEN' ? 'XXXXXXXXXXX' : (pais=== 'CLP' ? 'xxxxxxxx-x' : 'xxxxxxxxx-x') )}
                                            error={errors.rut && touched.rut ? true : false}
                                            disabled = {!editMode}
                                            />
                                        </Grid>
                                    </Grid>
                                )
                            }

                            {
                                proveedor && (
                                <Grid item xs={12} container style={{display: "flex", alignItems: "center"}}>
                                    <Grid item xs={4}>
                                        <Typography variant="subtitle1" className="custom-input"><b>Estado:</b></Typography>
                                    </Grid>
                                    <Grid item xs={8}>
                                        <TextField
                                        id="state"
                                        type="text"
                                        name="state"
                                        fullWidth
                                        value={proveedor.state === 1 ? 'Habilitado' : 'Desabilitado'}
                                        onChange={handleChange}
                                        placeholder="Estado"
                                        disabled
                                        />
                                    </Grid>
                                </Grid>
                                )
                            }

                            <Grid item xs={12} container style={{display: "flex", alignItems: "center"}}>
                                <Grid item xs={4}>
                                    <Typography variant="subtitle1" className="custom-input"><b>Observación:</b></Typography>
                                </Grid>
                                <Grid item xs={8} style={{display: 'flex', alignItems: "center"}}>
                                    <TextField
                                    id="observacion"
                                    type="text"
                                    name="observacion"
                                    fullWidth
                                    value={values.observacion}
                                    onChange={handleChange}
                                    placeholder="Observacion"
                                    disabled = {!editMode}
                                    />
                                </Grid>
                            </Grid>

                            </Grid>
                            <Divider sx={{ my: 2 }} />
                            {
                                editMode && (
                                    <div className="form-group mb-3 buton_container_modal">
                                        <button type="submit" className="btn btn-primary">
                                        {
                                        proveedor ? `Editar Proveedor` : `Agregar Proveedor`
                                        }
                                        </button>
                                    </div>
                                )
                            }
                        </Form>
                    )
                }}
            </Formik>
        </div>
    );
}
