import React from "react";
import { Button, Chip, Divider, Grid, Stack, TextField, Typography } from "@mui/material";
import { Snackbar, Alert } from '@mui/material';
import {
   PRODUCTS_CARD, KEY_APPLY_BENEF_POINTS, KEY_CLIENT_POINTS
} from '@constants/local-storage';
import { useLocalStorage } from '@toolbox/hooks/local-storage.hook';
import { saveLocalStorage } from '@helpers/local-storage-helper';
import { Props } from "./ValidatePuntos.type";
import { clientesService } from "@/service/services/Clientes.service";
import { Loading } from "@/components/common/Loading";
import { BeneficioPuntosService } from "@/service/services/BeneficioPuntos.service";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import TableHead from '@mui/material/TableHead';
import { styled } from '@mui/material/styles';

const Titulo = styled('div')(({ theme }) => ({
    ...theme.typography.button,
    padding: theme.spacing(1),
    fontWeight: 'bold',
    fontSize: '18px',
    color: 'black',
}));

export const ValidatePuntos: React.FC<Props> = (props: Props): JSX.Element => {
    const {closeModal} = props;

    const [loading, setLoading] = React.useState<boolean>(false);
    const [card_shop] = useLocalStorage(PRODUCTS_CARD, []);

    const [beneficios, setBeneficios] = React.useState<any>([]);

    const [cliente, setCliente] = React.useState<any>({});
    const [validated, setValidated] = React.useState(false);
    const [showBotonCanje, setShowBotonCanje] = React.useState(false);
    const [showBoton, setShowBoton] = React.useState(true);
    const [rutCliente, setRutCliente] = React.useState('');

    const [listProducts, setListProducts] = React.useState<any>([]);
    const [benefitsSelected, setBenefitsSelected] = React.useState<any>([]);
    const [clientPoints, setClientPoints] = React.useState<any>(0);

    const [snackBarConfig, setSnackBarConfig] = React.useState<any>({
        open: false,
        severity: 'error',
        message: 'Error',
        autoHideDuration: 3000,
    });

    React.useEffect(() => {
        benefitByPoints();
    }, []);

    const benefitByPoints = async() => {
        setLoading(true);
        await BeneficioPuntosService.BeneficioPuntosAvailable().then((data) =>{
            setBeneficios(data.beneficios);
            setLoading(false);
        })
    }

    const handlePrintError = (message: string) => {
        setSnackBarConfig(prev => ({
           ...prev,
           open: true,
           message: message,
        }));
        setTimeout(function(){
           props.closeModal();
       }, 3000);
    }

    const handleValidateClient = async (event) => {
        if(rutCliente){
            setLoading(true);
            await clientesService.getClienteByRut(rutCliente).then((data) => {
                if(data.status === 200){
                    const pointsClient = data.cliente.puntos;
                    if(pointsClient > 0){
                        setValidated(true);
                    }else{
                        setValidated(false);
                    }
                    setCliente(data.cliente);
                    setClientPoints(data.cliente.puntos);
                }else{
                    setCliente({});
                    setValidated(false);
                    handlePrintError(`El cliente no se encuentra registrado`);
                }
            });
            setLoading(false);
        }
    }

    const validateBenefits = () => {
        setShowBoton(false);
        let beneficiosFiltro = [];
        if (card_shop && card_shop.length > 0) {
            // eslint-disable-next-line
            beneficios.length > 0 && beneficios.map((beneficio) => {
                let exits = card_shop.find(product => (product.id || product.id_producto) === beneficio.id_producto);
                if(exits){
                    beneficiosFiltro.push(beneficio);
                }
            });

            if(beneficiosFiltro.length > 0){
                let validated = beneficiosFiltro.filter(beneficio => beneficio.puntos <= cliente.puntos);
                if(validated.length > 0){
                    setListProducts(validated);
                }else{
                    handlePrintError(`Los puntos acumulados no aplican a ningun descuento`);
                }
            }else{
                handlePrintError(`Los productos del carrito, no aplican ningun descuento`);
            }
        }else{
            handlePrintError(`No cuenta con ningun producto en su carrito`);
        }
    }

    const applyBenefit = (beneficio, key) => {
        setShowBotonCanje(true);
        if(beneficio && clientPoints >= beneficio.puntos){
            let exits = false;
            if(benefitsSelected.length > 0){
                for (let i = 0; i < benefitsSelected.length; i++) {
                    if(benefitsSelected[i].id === beneficio.id){
                        exits = true;
                    }
                }
            }
            if(!exits){
                setBenefitsSelected([
                    ...benefitsSelected,
                    beneficio
                ])
                setClientPoints(clientPoints - beneficio.puntos);
            }else{
                setSnackBarConfig(prev => ({
                    ...prev,
                    open: true,
                    message: 'No puede seleccionar el mismo beneficio',
                }));
            }

        }else{
            setSnackBarConfig(prev => ({
                ...prev,
                open: true,
                message: 'No tiene puntos suficientes',
            }));
        }
    }

    const removeBenefit = (beneficio, index) => {
        let benefits: Array<any> = benefitsSelected;
        if(benefits.length > 0){
            benefits.splice(index, 1);
            setClientPoints(clientPoints + beneficio.puntos);
        }else{
            setSnackBarConfig(prev => ({
                ...prev,
                open: true,
                message: 'No tiene ningun beneficio seleccionado',
            }));
        }
        setBenefitsSelected([...benefits])
    }

    const canjearBenefits = () => {
        if(benefitsSelected.length > 0){
            saveLocalStorage(KEY_APPLY_BENEF_POINTS, benefitsSelected);
            saveLocalStorage(KEY_CLIENT_POINTS, cliente);
            closeModal && closeModal();
        }
    }

    return(
        <div className="container">
            {loading && <Loading/>}
            <Snackbar
                open={snackBarConfig.open}
                autoHideDuration={snackBarConfig.autoHideDuration}
                onClose={() => setSnackBarConfig(prev => ({ ...prev, open: false }))}
                anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                >
                <Alert
                onClose={() => setSnackBarConfig(prev => ({ ...prev, open: false }))}
                severity={snackBarConfig.severity}
                variant="filled"
                >
                {snackBarConfig.message}
                </Alert>
            </Snackbar>
            <Grid container >
                <Grid item style={{width: "100%"}}>
                    <div className="H-card__fcontainer" style={{ width: "100%", alignItems: "center"}}>
                        <Grid sx={{display: 'flex', justifyContent: 'space-between', alignItems: "center"}}>
                            <Grid sx={{display:'flex', alignItems: "center"}}>
                                <span>Rut de Cliente:</span>
                                <TextField
                                    type="text"
                                    id="mi-codigo-de-barras"
                                    placeholder="Escribir rut..."
                                    className="H-card__fcontainer__filter__input"
                                    onChange={(e) => {setRutCliente(e.target.value)}}
                                    style={{ backgroundColor: "white", width: "290px", marginLeft: "10px" }}
                                />
                            </Grid>
                            <Button variant='contained' color="info" size="small" sx={{fontSize: "14px !important", textTransform: "capitalize", justifyContent: "end"}}
                            onClick={ handleValidateClient }
                                >Validar
                            </Button>
                        </Grid>
                    </div>
                </Grid>
                {
                    (!validated && cliente?.puntos <= 0) ? (
                        <Grid xs={12} sx={{padding:'5px', borderRadius:'5px', backgroundColor:'red', color: 'white', textAlign: 'center'}}>
                            <span>El cliente no cuenta con puntos</span>
                        </Grid>
                    ) : (validated && cliente?.puntos > 0) && (
                        <Grid xs={12} sx={{padding:'5px', borderRadius:'5px', backgroundColor:'green', color: 'white', textAlign: 'center'}}>
                            <span>Cliente válido</span>
                        </Grid>
                    )
                }
                {
                    ((cliente && cliente.nombre) && clientPoints >= 0 ) && (
                        <>
                        <Grid container sx={{marginTop: '10px', marginBottom: '10px'}}>
                            <Grid item xs={8}>
                                <Typography sx={{textAlign:"left",fontSize:'1em !important'}}>
                                    Nombre del Cliente:
                                </Typography>
                            </Grid>
                            <Grid item xs={4}>
                                <Typography sx={{textAlign:"right",fontSize:'1em !important'}}>
                                    {cliente?.nombre}
                                </Typography>
                            </Grid>
                        </Grid>
                        <Grid container sx={{marginTop: '10px', marginBottom: '10px'}}>
                            <Grid item xs={8}>
                                <Typography sx={{textAlign:"left",fontSize:'1em !important'}}>
                                    Puntos acumulados:
                                </Typography>
                            </Grid>
                            <Grid item xs={4}>
                                <Typography sx={{textAlign:"right",fontSize:'1em !important'}}>
                                    {clientPoints}
                                </Typography>
                            </Grid>
                        </Grid>
                        </>
                    )
                }
                <Divider style={{ width:'100%'}}/>
                {
                    (listProducts && listProducts.length > 0) && (
                        <>
                            <Grid item xs={3}>
                                <Titulo>{"Productos"}</Titulo>
                            </Grid>
                            <TableContainer component={Paper}>
                                <Table sx={{ minWidth: 550 }} aria-label="simple table">
                                    <TableHead>
                                        <TableRow>
                                            <TableCell align="left">Nombre</TableCell>
                                            <TableCell align="left">Puntos requeridos</TableCell>
                                            <TableCell align="center">Acciones</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {
                                            listProducts.map((beneficio, key) => (
                                                <TableRow key={key}>
                                                <TableCell align="left">{beneficio.producto?.nombre}</TableCell>
                                                <TableCell align="left">{beneficio.puntos}</TableCell>
                                                <TableCell align="center">
                                                    <>
                                                    <Button
                                                        id="basic-button"
                                                        variant='contained'
                                                        size="small"
                                                        color="error"
                                                        style={{textTransform: "capitalize"}}
                                                        sx={{fontSize: "14px !important"}}
                                                        onClick={(e) => {
                                                            applyBenefit(beneficio, key);
                                                        }}
                                                    >  Aplicar
                                                    </Button>
                                                    </>
                                                </TableCell>
                                                </TableRow>
                                            ))
                                        }
                                    </TableBody>
                                </Table>
                            </TableContainer>
                            <Grid sx={{marginTop:'10px'}}>
                                <Stack direction="row" spacing={2}>
                                <Divider/>
                                { benefitsSelected && benefitsSelected.map((item,indexProd) =>
                                    <Stack
                                        direction="row" spacing={2}>
                                        <Chip label={item.producto?.nombre} onDelete={()=>removeBenefit(item,indexProd)} color="warning"/>
                                        </Stack>
                                )}
                                </Stack>
                                <Divider sx={{marginTop: '10px', marginBottom: '10px'}} />
                            </Grid>
                        </>
                    )
                }

                <Divider style={{ width:'100%'}}/>

                {
                    showBoton && (
                        <Grid style={{textAlign: 'right', justifyContent:'end', width: '100%', marginTop:'5px'}}>
                            <div className="form-group mb-3 buton_container_modal">
                                <button  className="btn btn-primary" onClick={() => {
                                if (validated) {
                                    validateBenefits();
                                }
                                }}>
                                    Ver beneficios posibles
                                </button>
                            </div>
                        </Grid>
                    )
                }
                {
                    showBotonCanje && (
                        <Grid style={{textAlign: 'right', justifyContent:'end', width: '100%', marginTop:'5px'}}>
                            <div className="form-group mb-3 buton_container_modal">
                                <button  className="btn btn-primary" onClick={() => { canjearBenefits() }}>
                                    Canjear Beneficios
                                </button>
                            </div>
                        </Grid>
                    )
                }

            </Grid>
        </div>
    );
}
