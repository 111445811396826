import { Alert, Autocomplete, Button, FormControl, Grid, IconButton, MenuItem, Select, Snackbar, TextField } from "@mui/material";
import PersonAddAltIcon from '@mui/icons-material/PersonAddAlt';
import React, { useEffect, useRef, useState } from "react";
import { Popup } from "@/views/Editor/components/Popup";
import { styled } from '@mui/material/styles';
import { AddCliente } from "@/views/Clientes/componentes/AddCliente";
import { clientesService } from "@/service/services/Clientes.service";
import { useLocalStorage } from "@/toolbox/hooks/local-storage.hook";
import { KEY_USER_DATA, TOKEN_API } from "@/toolbox/constants/local-storage";
import { ticketService } from "@/service/services/Ticket.service";
import moment from "moment";
import { ROLE_ADMIN, ROLE_CAJERO } from "@/toolbox/defaults/static-roles";
import { readLocalStorage } from "@/toolbox/helpers/local-storage-helper";
import { ventaService } from "@/service/services/Venta.service";
import { cajas } from "@/service/services/Cajas.service";
import { ComponentPrint } from "@/components/common/ComponentPrint";
import { STATUS_FINALIZADO } from "@/toolbox/constants/status-ticket";
import { useReactToPrint } from 'react-to-print';
import { ROUTE_CUADRATURAS } from "@/toolbox/constants/route-map";
import { useHistory } from "react-router-dom";
import { userService } from "@/service/services/User.service";

const StyledButton = styled(Button)(({ theme }) => ({
    textTransform: 'none',
}));

const TipoPago = [
    { id: 1, nombre: 'Cash' },
];

export const PagarHome: React.FC<any> = (props: any): JSX.Element => {

   const history = useHistory();

    const { totalCard, realizarPedido, idPedido, onList, setIsLoaded, realizarVentaRapida, estadoCuadratura } = props
    
    const componentRef = useRef();
    
    const dataUsuario = readLocalStorage(KEY_USER_DATA);
    const idUser = dataUsuario?.user?.id_usuario;

    const fechaActual = new Date();
    const año = fechaActual.getFullYear();
    const mes = String(fechaActual.getMonth() + 1).padStart(2, '0');
    const dia = String(fechaActual.getDate()).padStart(2, '0');
 
    const fechaFormateada = `${año}-${mes}-${dia}`;
    const [snackBarConfig, setSnackBarConfig] = useState<any>({
        open: false,
        severity: 'error',
        message: 'Error',
        autoHideDuration: 3000,
     });

    const [clientes, setClientes] = useState<Array<any>>([]);
    const [clienteSelect, setClienteSelect] = useState<any>("");
    const [rutCliente, setRutCliente] = useState<any>('');
    const [openModalClient, setOpenModalClient] = useState<boolean>(false);

    const [pagos, setPagos] = useState<Array<any>>(TipoPago);
    const [pagosSelect, setPagoSelect] = useState<number>(0);
    const [namepagosSelect, setNamePagoSelect] = useState<string>('EFECTIVO');

    const [recibido, setRecibido] = React.useState<any>(0);
    const [vuelto, setVuelto] = React.useState<number>(0);
    const [propina, setPropina] = React.useState<number>(0);

    const [token] = useLocalStorage(TOKEN_API, "");

    const [detalle, setDetalle] = React.useState<Array<any>>([]);

    const [pedido, setPedido] = React.useState<any>(null);
    const [ticket, setTicket] = React.useState<any>(null);

    const [arrayIdsProducto, setArrayIdsProducto] = useState<any>([]);
    const [codigoTransbank, setCodigoTransbank] = useState<any>('');

   const [cajaSelected, setCajaSelected] =  React.useState<any>(null);
   const [cajasDisponibles, setCajasDisponible] =  React.useState<any>([]);
   
   const [dataImprimir, setDataImprimir] = useState(null);
   const [tipoImpresion, setTipoImpresion] = React.useState<string>('')

   const [idPedidoPagar, setIdPedidoPagar] = React.useState<number>(0);
   const [cuadraturaCierre, setCuadraturaCierre] = React.useState<any>(null);
    const [showPDF, setShowPDF] = useState({
        open: false,
        timbre: '',
        montoNeto: 0,
        folio: '',
        idVenta: 0
    });

    const validatePrice = (product) => {
    const dateToday = moment();
    const value = product.caducidad_precio_oferta;
    const expirationPrice = moment(value, 'DD-MM-YYYY');

    const standarPrice = product.precio_standar || product.precio;
    const offerPrice = product.precio_oferta;

    if (expirationPrice !== null && offerPrice !== null) {
       if (expirationPrice.isSameOrAfter(dateToday)) {
          return offerPrice;
       } else {
          return standarPrice;
       }
    } else {
       return standarPrice;
    }
 }

    useEffect(() => {
        getClientes()
        cajas.cargarCajas().then((data)=>{
            setCajasDisponible(data?.cajas?.filter(item => item.en_uso == "1"))
         })
        fetch(`https://api2.softnet.cl/formaPago`, {
            method: 'GET',
            headers: {
                'Access-Control-Allow-Origin': '*',
                'Content-Type': 'application/x-www-form-urlencoded',
                token: token,
            },
        })
            .then(data => data.json())
            .then(data => {
                setPagos([...data])
                setPagoSelect(data[0].id || 0)
                const formaPagoInitial = data.find(item => item.nombre == "DEBITO O CREDITO")
                if (!!formaPagoInitial) {
                    setPagoSelect(formaPagoInitial.id)
                    setNamePagoSelect(formaPagoInitial.nombre)
                }
            })
            .catch(error => {
                console.log(error);
            });
            
    }, [])

    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
  
     });

    useEffect(() => {
        if (dataImprimir !== null) {
           handlePrint();
        }
        // eslint-disable-next-line
     }, [dataImprimir])

    useEffect(() => {
        if (recibido - totalCard > 0) {
            setVuelto(recibido - totalCard)
        } else {
            setVuelto(0)
        }
        // eslint-disable-next-line
    }, [recibido])

    useEffect(()=> {
         if(cajaSelected){
            validateEstadoCaja()
         }
    }, [cajaSelected])

    const validateEstadoCaja = async() => {
      console.log(cajaSelected.id)
      if(cajaSelected.en_uso == "1"){
         const resp = await cajas.getCierreByCaja(cajaSelected.id)
         if(resp.status == 404){
            setCuadraturaCierre(resp.cuadratura)
         }
      }
    }

    const getClientes = async () => {
        // setIsLoaded(true);
        await clientesService.getClientes().then((data) => {
            setClientes(data.cliente.map((el) => {
                return { label: el.nombre, key: el.id, type: el.id_tipoPersona, rut: el.rut, direccion: el.direccion, comuna: el.comuna, ciudad: el.ciudad, giro: el.giro };
            }));
            const clientFind = data.cliente.find(item => item.nombre == 'GENERICO')
            if (!!clientFind) {
                setClienteSelect({
                    label: clientFind.nombre, key: clientFind.id, type: clientFind.id_tipoPersona, rut: clientFind.rut, direccion: clientFind.direccion, comuna: clientFind.comuna, ciudad: clientFind.ciudad, giro: clientFind.giro
                })
            }
        })
        // setIsLoaded(false);
    }

    const handlePagoChange = (e) => {
        setPagoSelect(e.target.value || 0);
    }

    const handleChangeMontoRecibido = (e) => {
        if (e.target.value >= 0) {
            setRecibido(e.target.value);
        }
    }

    const handleKeyMontoRecibido = (e) => {
        var key = e.keyCode || e.charCode;
        if (key === 96 && recibido[0] === 0) {
            setRecibido(recibido.replace(/^0+/, ''));
        }
    }

    const handleChangePropina = (e) => {
        if (e.target.value >= 0) {
            setPropina(e.target.value);
        }
    }

    const handleGenerarBoletaSoftnet = async (arrayProducts, pedidoData) => {
        if (!clienteSelect) {
           setSnackBarConfig(prev => ({
              ...prev,
              open: true,
              message: 'Seleccione un cliente.',
           }))
           return
        }
        
        if (namepagosSelect === '') {
           return setSnackBarConfig(prev => ({
              ...prev,
              open: true,
              message: 'Seleccione un método de pago',
           }))
        }
  
        if (detalle.find(item => item.idtipo_impuesto === null)) {
           setSnackBarConfig(prev => ({
              ...prev,
              open: true,
              message: 'Algunos productos no tienen el tipo de impuesto declarado',
           }))
           return
        }
  
        if (dataUsuario.user.rol === ROLE_CAJERO && !dataUsuario.user.idcaja) {
           setSnackBarConfig(prev => ({
              ...prev,
              open: true,
              message: 'El cajero no tiene una caja asignada.',
           }))
           return
        }
  
        setIsLoaded(true)
  
        let copyProducts: Array<any> = []
        let monto_neto_calculado: number = 0
        let monto_exento: number = 0
  
        for (let i = 0; i < arrayProducts.length; i++) {
  
           let precio = 0;
  
           if (arrayProducts[i].precio_oferta) {
              precio = validatePrice(arrayProducts[i]);
           } else {
              if (arrayProducts[i].precio) {
                 precio = arrayProducts[i].precio;
              } else {
                 precio = arrayProducts[i].precio_standar;
              }
           }
  
           let filtro_cantidad = 0;
  
           let monto_neto_producto1 = 0;
  
           if (arrayProducts[i].cantidad_peso) {
              monto_neto_producto1 = precio
              if (arrayProducts[i].id_unidad_medida !== 1) {
                 filtro_cantidad = Math.ceil(arrayProducts[i].cantidad);
              } else {
                 filtro_cantidad = arrayProducts[i].cantidad;
              }
           } else {
              monto_neto_producto1 = precio;
              filtro_cantidad = arrayProducts[i].cantidad;
           }
  
           copyProducts.push({
              "VlrCodigo": arrayProducts[i].codigo_erp,
              "NmbItem": arrayProducts[i].nombre,
              "PrcItem": monto_neto_producto1,
              "QtyItem": filtro_cantidad,
              "MontoItem": monto_neto_producto1 * filtro_cantidad,
              "Afecto": (arrayProducts[i]?.idtipo_impuesto === 2) ? false : true
           })
  
           /*AFECTO */
           if (arrayProducts[i]?.idtipo_impuesto === 1) {
              if (arrayProducts[i].id_unidad_medida && arrayProducts[i].id_unidad_medida !== 1) {
                 monto_neto_calculado = monto_neto_calculado + monto_neto_producto1 * filtro_cantidad;
              } else {
                 monto_neto_calculado = monto_neto_calculado + monto_neto_producto1 * arrayProducts[i].cantidad;
              }
           }
  
           /*EXENTO */
           if (arrayProducts[i]?.idtipo_impuesto === 2) {
              monto_exento += monto_neto_producto1 * filtro_cantidad
           }
  
           arrayIdsProducto.push(arrayProducts[i].id_producto);
           setArrayIdsProducto(arrayIdsProducto);
        }
  
        const tipo_boleta: string = arrayProducts.every(item => item?.idtipo_impuesto === 2) ? '41' : '39'
        let MntNeto: number = 0
        let IVA: number = 0
        let MntExe: number = 0
        let MntTotal: number = 0
  
        if (tipo_boleta === '39') {
           MntNeto = Math.round(monto_neto_calculado / 1.19);
           IVA = Math.round(MntNeto * 0.19);
           MntExe = monto_exento
           MntTotal = MntNeto + IVA + MntExe
        }
  
        if (tipo_boleta === '41') {
           MntNeto = monto_exento
           IVA = 0
           MntExe = monto_exento
           MntTotal = monto_exento
        }
  
        const DTE = {
           "DTE": {
              "Documento": {
                 "Encabezado": {
                    "IdDoc": {
                       "TipoDTE": tipo_boleta,
                       "FchEmis": fechaFormateada
                    },
                    "Receptor": {
                       "RUTRecep": clienteSelect?.rut || '66666666-6',
                       "RznSocRecep": clienteSelect?.label || '---',
                       "GiroRecep": clienteSelect?.giro || '-',
                       "DirRecep": clienteSelect?.direccion || '-',
                       "CmnaRecep": clienteSelect?.comuna || '-',
                       "CiudadRecep": clienteSelect?.ciudad || '-'
                    },
                    "Totales": {
                       "MntNeto": MntNeto,
                       "MntExe": MntExe,
                       "IVA": IVA,
                       "MntTotal": MntTotal
                    }
                 },
                 "Detalle": copyProducts
              },
              "Adicionales": {
                 "Uno": namepagosSelect
              }
           }
        }
  
        const documentoGenerado = await ventaService.createBoletaSoftenet(token, DTE)
  
        if (!documentoGenerado.mensaje || documentoGenerado.mensaje !== 'Dte generado con exito.') {
           setSnackBarConfig(prev => ({
              ...prev,
              open: true,
              message: 'Los datos a procesar son inválidos',
           }))
           setIsLoaded(false)
           return
        }
  
        const idcaja = dataUsuario.user.rol === ROLE_CAJERO ? dataUsuario.user.idcaja : cajaSelected?.id
        const nombreCaja = dataUsuario.user.rol === ROLE_CAJERO ? dataUsuario.user.caja : cajaSelected?.nombre
  
        const ventaID = await ventaService.generarBoleta(pedidoData?.id, pedidoData?.monto_total,
           recibido, 0,
           arrayProducts, (codigoTransbank || null), documentoGenerado.folio, 1, documentoGenerado.urlTimbre,
           (documentoGenerado.pdf || ""), MntNeto, namepagosSelect, propina, false, (pedidoData?.id_mesa || null),
           monto_exento, idcaja
        )
  
        setShowPDF({
           idVenta: ventaID,
           montoNeto: MntNeto,
           timbre: documentoGenerado?.urlTimbre || "",
           folio: documentoGenerado?.folio,
           open: true
        })
  
        ventaService.getVentaDetalle(ventaID).then((res) => {
  
           if (res.status === 200) {
              const arrayProducts = res.tickets.flatMap(item =>
                 item.detalle_ticket.flatMap(detalle => {
                    const productos = detalle.productos;
                    return {
                       "cantidad": detalle.cantidad,
                       "precio_unitario": detalle.precio_unitario,
                       "id_producto": detalle.id_producto,
                       "codigo_erp": productos?.codigo_erp,
                       "nombre": productos?.nombre,
                       "id_tipo_producto": productos?.id_tipo_producto,
                       "precio_standar": productos?.precio_standar,
                       "precio_oferta": productos && validatePrice(productos),
                       "unidad_medida": productos?.unidad_medida,
                    };
                 })
              );
  
              const venta = {
                 detalle: arrayProducts,
                 ventaDetalle: res.venta,
                 folio: documentoGenerado.folio,
                 timbre: documentoGenerado.urlTimbre,
                 montoNeto: tipo_boleta === '41' || tipo_boleta === '34' ? 0 : MntNeto,
                 IVA: IVA,
                 clienteSelect: clienteSelect,
                 nombreCajero: dataUsuario.user.rol === ROLE_CAJERO ? dataUsuario.user.nombre : null,
                 exento: MntExe,
                 titulo: tituloDoc(tipo_boleta),
                 caja: nombreCaja || ''
              }
  
              setDataImprimir(venta)
           }
        })
        await ticketService.editarPedidoV2(pedidoData?.id, { estado: STATUS_FINALIZADO })
        await onList()
        setIsLoaded(false);
     }

     const tituloDoc = (tipoDoc) => {
      switch(tipoDoc){
         case '41':
            return 'BOLETA ELECTRONICA EXENTA';
         case '39': 
            return 'BOLETA ELECTRONICA';
         case '34':
            return 'FACTURA ELECTRONICA EXENTA';
         case '33':
            return 'FACTURA ELECTRONICA';
      }        
   }

     const ventaFacturacionRapida = async(tipoDoc) => {
      //   if (dataUsuario.user.rol === ROLE_CAJERO && !dataUsuario.user.idcaja) {
      //      setSnackBarConfig(prev => ({
      //         ...prev,
      //         open: true,
      //         message: 'El cajero no tiene una caja asignada.',
      //      }))
      //      return
      //   }
      //   if(dataUsuario.user.rol === ROLE_ADMIN && !cajaSelected){
      //       setSnackBarConfig(prev => ({
      //          ...prev,
      //          open: true,
      //          message: 'Debe de seleccionar una caja.',
      //       }))
      //       return
      //   }
        if (!clienteSelect) {
           setSnackBarConfig(prev => ({
              ...prev,
              open: true,
              message: 'Seleccione un cliente.',
           }))
           return
        }

        if (namepagosSelect === '') {
           return setSnackBarConfig(prev => ({
              ...prev,
              open: true,
              message: 'Seleccione un método de pago',
           }))
        }
          await realizarVentaRapida(clienteSelect, namepagosSelect, cajaSelected, recibido, propina, token, tipoDoc)
     }

     const ventaBoleta = async() => {
        const idPedidoT = await realizarPedido(setIdPedidoPagar);
        if (idPedidoT) {
            setIsLoaded(true);
            ticketService.getPedidoDetalle(idPedidoT).then(async (data) => {
               if (data?.status === 200) {
                  setPedido(data.pedido);
                  setRutCliente(data.pedido.cliente?.rut || '');
                  setRecibido(data.pedido?.monto_total);
                  setTicket((data.tickets).length);
   
                  let arrayProducts = [];
                  // eslint-disable-next-line
                  (data.tickets).map((item) => {
                     for (let i = 0; i < item.detalle_ticket.length; i++) {
                        const productos = item.detalle_ticket[i].productos;
                        arrayProducts.push({
                           "cantidad": item.detalle_ticket[i].cantidad,
                           "id_producto": item.detalle_ticket[i].id_producto,
                           "codigo_erp": productos?.codigo_erp,
                           "nombre": productos?.nombre,
                           "id_tipo_producto": productos?.id_tipo_producto,
                           "precio_standar": productos?.precio_standar,
                           // "precio_oferta": productos?.precio_oferta,
                           "precio_oferta": productos && validatePrice(productos),
                           "cantidad_peso": productos?.cantidad_peso,
                           "precio_unitario": item.detalle_ticket[i].precio_unitario,
                           "subProductos": item.detalle_ticket[i].subProductos,
                           "id_unidad_medida": productos?.id_unidad_medida,
                           "unidad_medida": productos?.unidad_medida?.abbreviation,
                           "familia_id": productos?.familia_id,
                           "sub_familia_id": productos?.sub_familia_id,
                           "linea_id": productos?.linea_id,
                           "idtipo_impuesto": productos?.idtipo_impuesto
                        });
                     }
                  });
                  setDetalle(arrayProducts);
                  // validateCupones(cupones, arrayProducts, secTipoCupon, allFamilias);
                  //saveLocalStorage(PRODUCTS_CARD, arrayProducts);
                  await handleGenerarBoletaSoftnet(arrayProducts, data.pedido);

                  setIsLoaded(false)
               } else {
                  setIsLoaded(false)
               }
            })
         }
     }

    return (
        <>
          <Snackbar
             open={snackBarConfig.open}
             autoHideDuration={snackBarConfig.autoHideDuration}
             onClose={() => setSnackBarConfig(prev => ({ ...prev, open: false }))}
          >
             <Alert
                onClose={() => setSnackBarConfig(prev => ({ ...prev, open: false }))}
                severity={snackBarConfig.severity}
                variant="filled"
             >
                {snackBarConfig.message}
             </Alert>
          </Snackbar>
            <Grid item xs={6} style={{ display: "flex", alignItems: "center", marginBottom: "10px", marginTop: "8px", paddingRight: '8px' }}>
                <span style={{ marginRight: "10px", width: '100px' }}><b>Clientes:</b></span>
                <Autocomplete
                    disablePortal id="combo-box-demo"
                    options={clientes}
                    sx={{ width: '232px' }}
                    renderInput={(params) => <TextField {...params} label="Seleccione un cliente" />}
                    value={clienteSelect}
                    onChange={(e, newValue) => { setClienteSelect(newValue); setRutCliente(newValue?.rut ? newValue?.rut : '') }}
                    size='small'
                />
                <IconButton aria-label="delete" size="small"
                    onClick={() => {
                        setOpenModalClient(true)
                    }}>
                    <PersonAddAltIcon sx={{ fontSize: 20 }} />
                </IconButton>
            </Grid>

            {
                <Popup
                    largo="sm"
                    title='Agregar Cliente'
                    openPopup={openModalClient}
                    setOpenPopup={setOpenModalClient}
                >
                    <AddCliente
                        closeModal={() => { setOpenModalClient(false) }}
                        onListClientes={getClientes}
                        editMode={true}
                    />

                </Popup>
            }

            <Grid item xs={6} style={{ display: "flex", alignItems: "center", marginBottom: "10px" }}>
                <span style={{ marginRight: "10px", width: "150px" }}><b>Forma de pago:</b></span>
                <FormControl fullWidth>
                    <Select
                        labelId="tipoPago" id="tipoPago" size='small' name="tipoPago" value={pagosSelect}
                        onChange={handlePagoChange}
                    >
                        {pagos.map((option, i) => {
                            return <MenuItem key={i} value={option.id} onClick={() => { setNamePagoSelect(option.nombre) }}>{option.nombre}</MenuItem>
                        })
                        }
                    </Select>
                </FormControl>
            </Grid>
            {/* {
                  dataUsuario.user.rol == ROLE_ADMIN && (
                     <Grid item xs={6} style={{ display: "flex", alignItems: "center", marginBottom: "10px", marginTop: "8px" }}>
                     
                        <span style={{ marginRight: "10px", width: '170px' }}><b>Caja:</b></span>

                         <Autocomplete
                            disablePortal
                            id="combo-box-demo"
                            value={cajaSelected}
                            options={cajasDisponibles || []}
                            getOptionLabel={(option: any) => option?.nombre || ""}
                            isOptionEqualToValue={(option, value) => option?.id === value?.id}
                            fullWidth
                            renderInput={(params) => <TextField {...params} label="" />}
                            onChange={(e, newValue) => {
                               setCajaSelected(newValue);
                            }}
                            size="small"
                         />
   
                  </Grid>
                  )
            }
            */}
            <Grid item xs={6} style={{ display: "flex", alignItems: "center", marginTop: "20px" }}>
                <span style={{ marginRight: "10px", width: "150px" }}>Propina:</span>
                <TextField
                    size="small"
                    type={"number"}
                    value={propina}
                    onChange={handleChangePropina}
                />
            </Grid>
            {
                namepagosSelect == 'EFECTIVO' &&
                <>
                    <Grid item xs={6} style={{ display: "flex", alignItems: "center", marginTop: "20px" }}>
                        <span style={{ marginRight: "10px", width: "150px" }}>Monto Recibido: </span>
                        <TextField
                            size="small"
                            type={"number"}
                            value={recibido}
                            onChange={handleChangeMontoRecibido}
                            onKeyUp={handleKeyMontoRecibido}
                        />
                    </Grid>
                    <Grid item xs={6} style={{ display: "flex", alignItems: "center", marginTop: "20px" }}>
                        <span style={{ marginRight: "10px", width: "150px" }}>Vuelto:</span>
                        <TextField
                            size="small"
                            type={"number"}
                            value={vuelto}
                        />
                    </Grid>
                </>
            }
            <Grid container spacing={1}>
            {
               !!cuadraturaCierre && 
               <>
               <div style={{ border: '2px solid #FFA500', padding: '10px', background:'#FFA500', borderRadius:'10px' }}>
                <span style={{fontSize:'16px', color:'#fff'}}>Para la caja seleccionada existe una cuadratura abierta del dia anterior, debe cerrarla.   </span>
                <Button variant="contained" sx={{ml:2}} onClick={() => history.push(ROUTE_CUADRATURAS,{userSelected: cuadraturaCierre?.id_responsable})}>
                  Cerrar cuadratura
                </Button>
              </div>
               </>
            }
            <Grid item xs={6}>
                <StyledButton
                    variant="contained" color='success' fullWidth
                    // onClick={() => { console.log('hola') }}
                    onClick={() => { ventaFacturacionRapida('Boleta'); setTipoImpresion('Boleta') }}
                    sx={{ mt: "10px", bgcolor: '#229954' }}
                    disabled={(!!estadoCuadratura && estadoCuadratura != 0) || !!cuadraturaCierre}
                >
                    {"Boletear"}
                </StyledButton>
            </Grid>
            <Grid item xs={6}>
                <StyledButton
                    variant="contained" color='success' fullWidth
                    onClick={() => { ventaFacturacionRapida('Factura'); }}
                    sx={{ mt: "10px", bgcolor: '#229954' }}
                    disabled={(!!estadoCuadratura && estadoCuadratura != 0) || !!cuadraturaCierre}
                >
                    {"Facturar"}
                </StyledButton>
            </Grid>
            </Grid>
            

            {dataImprimir &&
               <div style={{ display: "none" }}>
                  <div ref={componentRef}>
                     <ComponentPrint
                        data={dataImprimir}
                        tipoImpresion={tipoImpresion}
                     />
                  </div>
               </div >
            }
        </>
    )
}