import React from "react";
import { Chart } from "react-google-charts";

export const BarChart = (props) => {

    const {data, type} = props;

    const options = {
        title: "",
        chartArea: { width: "50%" },
        hAxis: {
          title: type === "producto" ? "Unidades ventidas" : "Monto total",
          minValue: 0,
        },
        vAxis: {
          title: type === "producto" ? "Top 5 productos": "Top 5 ventas",
        },
    };

    return (
        <Chart
            chartType="BarChart"
            width="100%"
            height="400px"
            data={data ? data : []}
            options={options}
        />
    );
}
