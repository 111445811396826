import { ProSidebar, Menu, SubMenu, MenuItem, SidebarHeader, SidebarFooter, SidebarContent } from 'react-pro-sidebar';
import { Link } from 'react-router-dom';
import CancelIcon from '@mui/icons-material/Cancel';
import './drawer.scss';

import 'react-pro-sidebar/dist/css/styles.css';
import { Button, Drawer, Grid, Typography } from '@mui/material';
import { ROUTE_BENEFICIOS, ROUTE_CAJA, ROUTE_CLIENTES, ROUTE_COCINAS, ROUTE_COMPANY_CONFIG, ROUTE_COMPRAS, ROUTE_CREAR_LISTA_PRECIO, ROUTE_CUADRATURAS, ROUTE_EDITOR, ROUTE_EMPRESAS, ROUTE_HOME, ROUTE_INVENTARIO, ROUTE_LISTA_PRECIO, ROUTE_MANTENEDOR_CAJAS, ROUTE_PEDIDOS, ROUTE_PEDIDOS_PRODUCTOS, ROUTE_PRODUCTOS_INSUMO, ROUTE_PRODUCTOS_LIST, ROUTE_PROVEEDORES, ROUTE_REPORTE, ROUTE_REPORTE_VENTA, ROUTE_SALAS, ROUTE_SUCURSALES, ROUTE_TARJETA_EXISTENCIA_DIARIA, ROUTE_USUARIOS, ROUTE_VENTAS } from '@/toolbox/constants/route-map';
import PointOfSaleIcon from '@mui/icons-material/PointOfSale';
import ReactTooltip from 'react-tooltip';
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import ReceiptLongIcon from '@mui/icons-material/ReceiptLong';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import PeopleIcon from '@mui/icons-material/People';
import MapsHomeWorkIcon from '@mui/icons-material/MapsHomeWork';
import FactoryIcon from '@mui/icons-material/Factory';
import EqualizerIcon from '@mui/icons-material/Equalizer';
import FormatListNumberedIcon from '@mui/icons-material/FormatListNumbered';
import PaidIcon from '@mui/icons-material/Paid';
import StoreIcon from '@mui/icons-material/Store';
import { KEY_EMPRESA, KEY_USER_DATA } from '@/toolbox/constants/local-storage';
import { useLocalStorage } from '@/toolbox/hooks/local-storage.hook';
import { readLocalStorage } from '@/toolbox/helpers/local-storage-helper';
import MiscellaneousServicesIcon from '@mui/icons-material/MiscellaneousServices';
import { ROLE_VENDEDOR } from '@/toolbox/defaults/static-roles';
import LocalGroceryStoreIcon from '@mui/icons-material/LocalGroceryStore';

type Props = {
    showDrawer?: any
    setShowDrawer?: any
}

export const DrawerLateral: React.FC<Props> = (props: Props): JSX.Element => {

    const [dataEmpresa] = useLocalStorage(KEY_EMPRESA, { mesas: 0, id_tipo_empresa: 0 });
    const dataUsuario = readLocalStorage(KEY_USER_DATA);
    const role = dataUsuario?.user?.rol;

    return (
        <div id="header" style={{ position: "absolute" }}>
            <Drawer
                anchor="left"
                open={props.showDrawer}
                onClose={() => props.setShowDrawer(false)}
                transitionDuration={500}
                className='pro-sidebar .pro-sidebar-inner .pro-sidebar-layout'
                style={{ backgroundColor: 'red !important' }}
            >
                {/* <ProSidebar className='pro-sidebar'> */}
                <SidebarHeader>
                    <Grid sx={{ display: 'flex', justifyContent: 'space-between' }}>
                        <Button color="inherit" component={Link} to={role && role == 'Cajero' ? ROUTE_CAJA : ROUTE_HOME}>
                            <Typography
                                variant="h6"

                                component="div"
                                sx={{ display: { xs: 'block', sm: 'block' } }}
                            >
                                NUPY
                            </Typography>
                        </Button>
                        <Button color="inherit" onClick={() => { props.setShowDrawer(false) }}>
                            <CancelIcon />
                        </Button>
                    </Grid>
                </SidebarHeader>

                <SidebarContent>
                    <Menu >

                        {
                            role && role !== 'Mesero' && (
                                <>
                                    <SubMenu title='PRODUCTOS' icon={<StoreIcon />}>
                                        <MenuItem data-tip data-for="tooltipImportant">
                                            GESTIONAR PRODUCTOS
                                            <Link to={ROUTE_EDITOR} />
                                        </MenuItem>
                                        <MenuItem data-tip data-for="tooltipImportant">
                                            MIS PRODUCTOS
                                            <Link to={ROUTE_PRODUCTOS_LIST} />
                                        </MenuItem>
                                        {/* <MenuItem onClick={() => { console.log('menu') }} data-tip data-for="tooltipImportant">
                                            PRODUCTOS INSUMOS
                                            <Link to={ROUTE_PRODUCTOS_INSUMO} />
                                        </MenuItem> */}
                                    </SubMenu>
                                </>
                            )
                        }

                        {
                            role && (role !== 'Cajero' && role !== 'Mesero') && (
                                <MenuItem data-tip data-for="tooltipImportant" icon={<StoreIcon />}>
                                    INSUMOS
                                    <Link to={ROUTE_PRODUCTOS_INSUMO}></Link>
                                </MenuItem>
                            )
                        }

                        <SubMenu title='PEDIDOS' icon={<ReceiptLongIcon />}>
                            <MenuItem onClick={() => { console.log('menu') }} data-tip data-for="tooltipImportant">
                                GESTIONAR PEDIDOS
                                <Link to={ROUTE_PEDIDOS} />
                            </MenuItem>
                            <MenuItem onClick={() => { console.log('menu') }} data-tip data-for="tooltipImportant">
                                VISTA COCINA
                                <Link to={ROUTE_PEDIDOS_PRODUCTOS} />
                            </MenuItem>
                        </SubMenu>

                        {
                            role && (role !== 'Cajero' && role !== 'Mesero') && (
                                <MenuItem data-tip data-for="tooltipMyFolder" icon={<ShoppingCartIcon />}>
                                    COMPRAS
                                    <Link to={ROUTE_COMPRAS}></Link>
                                </MenuItem>
                            )
                        }

                        {
                            role !== ROLE_VENDEDOR &&
                            <SubMenu title='CAJA' icon={<PointOfSaleIcon />}>
                                <MenuItem onClick={() => { console.log('menu') }} data-tip data-for="tooltipImportant">
                                    {/* <StarIcon className="ico" /> */}
                                    GESTIONAR CAJA
                                    <Link to={ROUTE_CAJA} />
                                </MenuItem>
                                <MenuItem onClick={() => { console.log('menu') }} data-tip data-for="tooltipImportant">
                                    CAJAS
                                    <Link to={ROUTE_MANTENEDOR_CAJAS} />
                                </MenuItem>
                                <MenuItem onClick={() => { console.log('menu') }} data-tip data-for="tooltipImportant">
                                    VER CUADRATURA
                                    <Link to={ROUTE_CUADRATURAS} />
                                </MenuItem>
                            </SubMenu>
                        }


                        {
                            role !== ROLE_VENDEDOR &&
                            <>
                                <MenuItem data-tip data-for="tooltipMyFolder" icon={<ShoppingCartIcon />}>
                                    VENTAS
                                    <Link to={ROUTE_VENTAS}></Link>
                                </MenuItem>
                                <MenuItem data-tip data-for="tooltipMyFolder" icon={<ShoppingCartIcon />}>
                                    TARJETA DE EXISTENCIA
                                    <Link to={ROUTE_TARJETA_EXISTENCIA_DIARIA}></Link>
                                </MenuItem>
                            </>
                        }


                        {
                            role && (role !== 'Cajero' && role !== 'Mesero') && (
                                <>
                                    {/* <MenuItem onClick={() => { console.log('menu') }} data-tip data-for="tooltipMyFolder" icon={<PaidIcon />}>
                                        BENEFICIOS
                                        <Link to={ROUTE_BENEFICIOS}></Link>
                                    </MenuItem> */}

                                    {/* <SubMenu title='LISTA DE PRECIOS' icon={<FormatListNumberedIcon />}>
                                        <MenuItem onClick={() => { console.log('menu') }} data-tip data-for="tooltipImportant">
                                            VER LISTAS
                                            <Link to={ROUTE_LISTA_PRECIO} />
                                        </MenuItem>
                                        <MenuItem onClick={() => { console.log('menu') }} data-tip data-for="tooltipImportant">
                                            CREAR LISTA DE PRECIAS
                                            <Link to={ROUTE_CREAR_LISTA_PRECIO} />
                                        </MenuItem>
                                    </SubMenu> */}

                                    <SubMenu title='GESTIONAR EMPRESA' icon={<MapsHomeWorkIcon />}>
                                        <MenuItem onClick={() => { console.log('menu') }} data-tip data-for="tooltipImportant">
                                            SUCURSALES
                                            <Link to={ROUTE_SUCURSALES} />
                                        </MenuItem>
                                        {/* <MenuItem onClick={() => { console.log('menu') }} data-tip data-for="tooltipImportant">
                                            EMPRESAS
                                            <Link to={ROUTE_EMPRESAS} />
                                        </MenuItem> */}
                                    </SubMenu>

                                    <MenuItem onClick={() => { console.log('menu') }} data-tip data-for="tooltipMyFolder" icon={<ManageAccountsIcon />}>
                                        GESTIONAR USUARIOS
                                        <Link to={ROUTE_USUARIOS}></Link>
                                    </MenuItem>

                                    <MenuItem onClick={() => { console.log('menu') }} data-tip data-for="tooltipMyFolder" icon={<PeopleIcon />}>
                                        GESTIONAR CLIENTES
                                        <Link to={ROUTE_CLIENTES}></Link>
                                    </MenuItem>
                                </>
                            )
                        }

{
                            role && (role !== 'Cajero' && role !== 'Mesero') && (
                                <>
                                    <MenuItem onClick={() => { console.log('menu') }} data-tip data-for="tooltipMyFolder" icon={<FactoryIcon />}>
                                        GESTIONAR PROVEEDORES
                                        <Link to={ROUTE_PROVEEDORES}></Link>
                                    </MenuItem>
                                </>
                            )
                        }

                        {
                            dataEmpresa && dataEmpresa.mesas === 1 && role !== ROLE_VENDEDOR && (
                                <SubMenu title='GESTIONAR SALAS' icon={<MapsHomeWorkIcon />}>
                                    <MenuItem onClick={() => { console.log('menu') }} data-tip data-for="tooltipImportant">
                                        SALAS / MESAS
                                        <Link to={ROUTE_SALAS} />
                                    </MenuItem>
                                    {
                                        (dataEmpresa.id_tipo_empresa === 2 || dataEmpresa.id_tipo_empresa === 3) && (
                                            <MenuItem onClick={() => { console.log('menu') }} data-tip data-for="tooltipImportant">
                                                COCINAS
                                                <Link to={ROUTE_COCINAS} />
                                            </MenuItem>
                                        )
                                    }
                                </SubMenu>
                            )
                        }

                        {
                            role && (role !== 'Cajero' && role !== 'Mesero') && (
                                <>
                                    <SubMenu title='REPORTES' icon={<EqualizerIcon />}>
                                        <MenuItem onClick={() => { console.log('menu') }} data-tip data-for="tooltipImportant">
                                            VER REPORTES
                                            <Link to={ROUTE_REPORTE} />
                                        </MenuItem>
                                        <MenuItem onClick={() => { console.log('menu') }} data-tip data-for="tooltipImportant">
                                            REPORTE DE VENTAS
                                            <Link to={ROUTE_REPORTE_VENTA} />
                                        </MenuItem>
                                        {/* <MenuItem onClick={() => { console.log('menu') }} data-tip data-for="tooltipImportant">
                                            INVENTARIO
                                            <Link to={ROUTE_INVENTARIO} />
                                        </MenuItem> */}
                                    </SubMenu>
                                </>
                            )
                        }

                        {
                            role && (role == 'Administrador') && (
                                <>
                                    <MenuItem data-tip data-for="tooltipMyFolder" icon={<MiscellaneousServicesIcon />}>
                                        CONFIGURACIONES
                                        <Link to={ROUTE_COMPANY_CONFIG}></Link>
                                    </MenuItem>
                                </>
                            )
                        }

                    </Menu>

                </SidebarContent>
                {/* </ProSidebar> */}
            </Drawer>
        </div>
    );
}