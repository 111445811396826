import { Loading } from "@/components/common/Loading";
import { empresasService } from "@/service/services/Empresas.service";
import { Divider, Grid, TextField, Typography, Tooltip } from "@mui/material";
import { Form, Formik } from "formik";
import React from "react";
import { Props } from "./EditEquivalencia.type";
import { useLocalStorage } from '@toolbox/hooks/local-storage.hook';
import { KEY_USER_DATA } from '@toolbox/constants/local-storage';
import PriorityHighIcon from '@mui/icons-material/PriorityHigh';
import {
   KEY_EMPRESA
} from '@constants/local-storage';
import {
   saveLocalStorage
} from '@helpers/local-storage-helper';


export const EditEquivalencia: React.FC<any> = (props: Props): JSX.Element => {
    const {equivalencia, onListEquivalencia, closeModal} = props;
    const [dataEmpresa] = useLocalStorage(KEY_EMPRESA, {
      id: 0, id_tipo_empresa:0, equivalencia_puntos: 0,
      id_country: 0, country_name: "", country_abbreviation: "",
   });

    const [loading, setLoading] = React.useState<boolean>(false);
    // eslint-disable-next-line
    const [data, setData] = React.useState<any>({
        equivalencia_puntos: equivalencia ? equivalencia : ""
    });

    const [dataUser] = useLocalStorage(KEY_USER_DATA, undefined);
    const idCompany = dataUser.user.id_empresa;

    const validateForm = (values) => {
        let errors: any = {};
        if(!values.equivalencia_puntos) errors.equivalencia_puntos = "equivalencia requerida";
        return errors;
    }

    const onSubmit = async(values, {resetForm}) => {
        setLoading(true);
        const formData = new FormData();
        formData.append('equivalencia_puntos', values.equivalencia_puntos);
        await empresasService.editEquivalencia(formData, idCompany).then((data)=>{
            if (data && data?.status === 200) {
               let newDta = dataEmpresa;
               newDta.equivalencia_puntos = values.equivalencia_puntos;
               saveLocalStorage(KEY_EMPRESA, newDta);
               onListEquivalencia && onListEquivalencia();
               setLoading(false);
               closeModal();
            }
        })
    }

    return (
        <>
        {loading && <Loading/>}
        <Formik enableReinitialize initialValues={data} validate={ (values) => validateForm(values) } onSubmit={onSubmit}>
            {({values, errors, touched, handleSubmit, handleChange, setFieldValue}) => {
                return (
                    <Form onSubmit={handleSubmit}>

                        <Grid container  spacing={3} alignItems="center" justifyContent="center" >

                        <Grid item xs={12} style={{display: "flex", alignItems: "center"}}>
                            <Grid item xs={4}>
                                <Typography variant="subtitle1" className="custom-input"><b>Equivalencia:</b></Typography>
                            </Grid>
                            <Grid item xs={8} style={{display: 'flex', alignItems: "center"}}>
                                {
                                    errors.equivalencia_puntos && (
                                        <Tooltip title={errors.equivalencia_puntos}>
                                            <PriorityHighIcon className={'iconWarning'}/>
                                        </Tooltip>
                                    )
                                }
                                <TextField
                                id="equivalencia_puntos"
                                type="number"
                                name="equivalencia_puntos"
                                fullWidth
                                value={values.equivalencia_puntos}
                                onChange={handleChange}
                                placeholder="Valor por puntos"
                                error={errors.equivalencia_puntos && touched.equivalencia_puntos ? true : false}
                                />
                            </Grid>
                        </Grid>

                        </Grid>

                        <Divider sx={{ my: 2 }} />

                        <div className="form-group mb-3 buton_container_modal">
                            <button type="submit" className="btn btn-primary">
                                Editar
                            </button>
                        </div>
                    </Form>
                )
            }}
        </Formik>
        </>
    );
}
