import { CodigoBarraRepository } from "../repositories/CodigoBarra.repository";

export const CodigoBarraService = {
    registerBarCode,
    updateBarCode,
    deleteBarCode
}

async function registerBarCode(idproducto: number, data: {}) {
    const res = await CodigoBarraRepository.register(idproducto, data);
    return res;
}

async function updateBarCode(id: number, data: {}) {
    const res = await CodigoBarraRepository.update(id, data);
    return res;
}

async function deleteBarCode(id: number) {
    const res = await CodigoBarraRepository.delete(id);
    return res;
}