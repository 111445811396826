import * as React from 'react';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import { editor } from "../../service/services/Editor.service";
import { AddCategoria } from "./components/AddCategoria";
import { AddSubCategoria } from "./components/AddSubCategoria";
import { AddProducto } from "./components/AddProducto";
import { EditProducto } from "./components/EditProducto";
import { EditProductoAgrupado } from "./components/EditProductoAgrupado";
import { EditProductoMenu } from "./components/EditProductoMenu";
import { Loading } from '../../components/common/Loading/Loading';
import { Protected } from '@/components/layout/Protected';
import { FunctionComponent } from "react";
import { Button, Menu, MenuItem, Tooltip } from "@mui/material";
import { Popup } from './components/Popup/Popup';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import { styled } from '@mui/material/styles';
import Divider from '@mui/material/Divider';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert, { AlertProps } from '@mui/material/Alert';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import IconButton from '@mui/material/IconButton';
import { Grid } from "@mui/material";
import { useState } from "react";
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';
import { TransitionProps } from '@mui/material/transitions';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import VisibilityIcon from '@mui/icons-material/Visibility';
import RemoveShoppingCartIcon from '@mui/icons-material/RemoveShoppingCart';
import "./../Home/Home.sass"
import { removeLocalStorage } from '@/toolbox/helpers/local-storage-helper';
import { DeletedProduct } from './components/DeletedProduct';
import { Link, useHistory } from 'react-router-dom';
import { ROUTE_PRODUCTOS_INSUMO } from '@/toolbox/constants/route-map';
import { useLocalStorage } from '@/toolbox/hooks/local-storage.hook';
import { TOKEN_API } from '@/toolbox/constants/local-storage';
import { productoService } from '@/service/services/Producto.service';
import useMediaQuery from '@mui/material/useMediaQuery';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { Try } from '@mui/icons-material';
import { moneyFormatInt } from '@/toolbox/helpers/money.helper';



const StyledTab = styled(Tab)(({ theme }) => ({
   textTransform: 'none',
}));

const StyledButton = styled(Button)(({ theme }) => ({
   textTransform: 'none',
}));

const Transition = React.forwardRef(function Transition(
   props: TransitionProps & {
      children: React.ReactElement<any, any>;
   },
   ref: React.Ref<unknown>,
) {
   return <Slide direction="up" ref={ref} {...props} />;
});


interface TabPanelProps {
   children?: React.ReactNode;
   index: number;
   value: number;
}

function TabPanel(props: TabPanelProps) {
   const { children, value, index, ...other } = props;

   return (
      <div
         role="tabpanel"
         hidden={value !== index}
         id={`simple-tabpanel-${index}`}
         aria-labelledby={`simple-tab-${index}`}
         {...other}
      >
         {value === index && (
            <Box sx={{ p: 3 }}>
               <Typography variant="h6">{children}</Typography>
            </Box>
         )}
      </div>
   );
}

const StyledToggleButton = styled(ToggleButton)(({ theme }) => ({
   textTransform: 'none',
   '&.MuiToggleButton-root': {
      borderColor: '#0044D0',
      boxShadow: '0 0 0 0.1rem rgba(0,123,255,.5)',
      height: '40px',

      marginRight: '15px',
   },
   '&.Mui-selected': {
      backgroundColor: '#ABC1CC',
      borderColor: '#0062cc',
      boxShadow: '0 0 0 0.1rem rgba(0,87,118)',
      color: 'black',
      '&:hover': {
         background: '#ABC1CC',
      },
   },
}));

const StyledToggleButtonGroup = styled(ToggleButtonGroup)(({ theme }) => ({
   '& .MuiToggleButtonGroup-grouped': {
      // margin: theme.spacing(),
      border: 0,
      borderRadius: 0,
      textTransform: 'none',

   },
}));

const Alert = React.forwardRef<HTMLDivElement, AlertProps>(function Alert(
   props,
   ref,
) {
   return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});


export const Editor: FunctionComponent = (props: any) => {
   // const { register, handleSubmit, formState: { errors }, } = useForm();
   const [open, setOpen] = React.useState(false);
   const [openPopup, setOpenPopup] = React.useState(false);
   // eslint-disable-next-line
   const [alerta, setAlerta] = React.useState<String>('');
   const [value, setValue] = React.useState(1);
   const [modulo, setModulo] = React.useState([]);
   const [submodulo, setSubModulo] = React.useState([]);
   const [submoduloActual, setSubModuloActual] = React.useState([]);
   const [productosActual, setProductosActual] = React.useState(-1);
   const [submoduloSelect, setSubmoduloSelect] = React.useState([]);
   const [indice, setIndice] = React.useState(0);
   const [popUp, setPopUp] = React.useState<String>('');
   const [PA, setPA] = React.useState([]);
   const [items, setItems] = useState<Array<any>>([]);
   const [loadData, setLoadData] = React.useState<boolean>(false);
   const [alignment, setAlignment] = React.useState('');
   const [itemsMenu, setItemsMenu] = React.useState([]);
   const [productosItemsMenu, setproductosItemsMenu] = React.useState([]);

   const [openEditProducto, setOpenEditProducto] = useState<boolean>(false)
   const [editProducto, setEditProducto] = useState({
      id: -1,
      type: -1,
   })
   const [nombreProductSelect, setNombreProductSelect] = React.useState<String>('');

   const [showModalDelete, setShowModalDelete] = React.useState(false);
   const [idProduct, setIdProduct] = React.useState('');
   const [idTipoProduct, setIdTipoProduct] = React.useState('');
   const [isSupply, setIsSupply] = React.useState('');

   const [snackBarConfig, setSnackBarConfig] = useState<any>({
      open: false,
      severity: 'error',
      message: 'Error',
      autoHideDuration: 3000,
   });

   const history = useHistory();
   const [token] = useLocalStorage(TOKEN_API, "");

   const theme = createTheme();
   const isSmallScreen = useMediaQuery(theme.breakpoints.down('md'));

   const handleClickOpen = () => {
      setOpen(true);
   };

   const closeModalDelete = () => {
      setShowModalDelete(false);
   }

   React.useEffect(() => {
      if (!openEditProducto) {
         removeLocalStorage('productsArray');
      }
      // eslint-disable-next-line
   }, [openEditProducto])

   const handleChangeToggleButton = (
      event: React.MouseEvent<HTMLElement>,
      newAlignment: string,
   ) => {
      if (newAlignment !== null) {
         setAlignment(newAlignment);
      }
   };

   const handleChange = (event, newValue) => {
      setProductosActual(-1);
      setValue(newValue);
      setAlignment(null);
   };

   const handleClose = (event?: React.SyntheticEvent | Event, reason?: string) => {
      if (reason === 'clickaway') {
         return;
      }

      setOpen(false);
   };


   async function eliminarModulo(item) {
      setLoadData(true);
      if (item.submodulos) {
         await editor.deleteSubmodulos(item.submodulos);
      }
      await editor.deleteModulo(item.id);
      //DELETE PRODUCTOS
      window.location.reload();
   }

   async function eliminarSubmodulo(id) {
      setLoadData(true);
      const id_modulo = modulo[indice]['id'];
      await editor.deleteSubmodulo(id, id_modulo);
      window.location.reload();
   }

   async function editarProducto(id_prod, idTipo) {
      setOpenEditProducto(true)
      if (idTipo && idTipo === 1) {
         setEditProducto({
            id: Number(id_prod),
            type: 1,
         })
      } else if (idTipo && idTipo === 2) {
         setEditProducto({
            id: Number(id_prod),
            type: 2,
         })
      } else if (idTipo && idTipo === 3) {
         setEditProducto({
            id: Number(id_prod),
            type: 3,
         })
      }
   }

   const getSubmoduloById = async (id) => {
      await editor.getSubmoduloById(id).then((data) => {
         cargarProductosClick(data.id_productos);
      })
   }

   async function cargarProductosClick(ids: any) {
      const res = await editor.cargarProductos(ids);
      if (res.productos) {
         setPA(res);
         setLoadData(false);
      } else {
         setPA(null);
         setLoadData(false);
      }

   }

   React.useEffect(() => { setPA(PA) }, [PA])
   React.useEffect(() => { setValue(value) }, [value])

   // React.useEffect(() => {
   // setSubModuloActual(submoduloActual);
   // cargarSubModulo(indice)
   // }, [submoduloActual])
   React.useEffect(() => { cargarSubModulo(indice) }, [indice])

   async function cargarSubModulos(ids: any) {
      const res = await editor.cargarSubModulos(ids);
      if (res.status === 200) {
         const submodulos: any = res.submodulos;
         //   for(let i = 0; i<submodulos.length;i++){
         //     //const array = Object.values(submodulos[i])
         //   }
         submodulo.push(submodulos)

         setSubModulo(submodulo);
      } else {
         if (res.status === 400) {
            setLoadData(false);
            return;
         } else {
            //setLoadData(false);
            console.log('error');
            return;
         }
      }
   }

   React.useEffect(() => {
      cargarData()
   }, [])

   const cargarData = async () => {
      await fetchData()
      cargarSubModulo(0)
   }

   async function fetchData() {
      setLoadData(true);
      // setCardState(readLocalStorage(PRODUCTS_CARD));
      // setLargo(readLocalStorage(PRODUCTS_CARD).length);
      const res = await editor.cargarModulos();
      if (res.status === 200) {

         const modulos: any = Object.values(res.modulos);

         for (let i = 0; i < modulos.length; i++) {

            if (modulos[i].submodulos) {
               //const pruebaf = [];
               await cargarSubModulos(modulos[i].submodulos);
            }
         }

         setTimeout(() => {
            setLoadData(false);
         }, 2000);

         setModulo(modulos)
      } else {
         if (res.status === 400) {
            setLoadData(false);
            return;
         } else {
            console.log('error');
            setLoadData(false);
            return;
         }
      }
   }

   async function cargarSubModulo(index) {
      setIndice(index);
      setSubModuloActual([]);
      if (submodulo[index]) {
         const array = submodulo[index];

         setSubModuloActual(array);
      } else {
         setSubModuloActual([]);
      }
   }

   const handleLoad = (e) => {
      localStorage.setItem('indice_mod', indice.toString());
      if (submoduloSelect) {
         // eslint-disable-next-line
         submoduloSelect.map((item) => {
            getSubmoduloById(item.id);
         })
      }
      setOpenPopup(false);
   }

   const cargarItemsPA = async (id_prod) => {
      setLoadData(true);

      await editor.getItemsProductoAgrupado(id_prod).then((data) => {
         setLoadData(false);
         setItems(data.data);
      });
   }

   const cargarProductosMenu = async (id_prod) => {
      setLoadData(true);
      await editor.getMenu(id_prod).then((data) => {
         setItemsMenu(data.menu);
         for (let i = 0; i < data.menu.length; i++) {
            editor.getItemsMenu(data.menu[i].id).then((itemProductos) => {
               productosItemsMenu.push(itemProductos.data);
               setproductosItemsMenu(productosItemsMenu);
               if (i === data.menu.length - 1) {
                  setLoadData(false);
               }
            });

         }
      })
   }

   // const getAndValidateProductSofnet = async () => {
   //    let productsSofnet = [];
   //    const cli = await fetch(`https://api2.softnet.cl/producto`, {
   //    method: 'GET',
   //    headers: {
   //       'Access-Control-Allow-Origin':'*',
   //       'Content-Type': 'application/x-www-form-urlencoded',
   //       token: token,
   //    },
   //    })
   //    .then(dataProducts => dataProducts.json())
   //    .then(async dataProducts => {
   //       // eslint-disable-next-line
   //       dataProducts.map((product) => {
   //          // if(product.stock > 0 && product.estado === 1){
   //          if(product.estado === 1 && product.nombre !== ""){
   //             productsSofnet.push(product)
   //          }
   //       })

   //       return productsSofnet;

   //    })
   //    .catch(error => {
   //       return [];
   //    })

   //    return cli;
   // }

   React.useEffect(() => { setproductosItemsMenu(productosItemsMenu) }, [productosItemsMenu])

   if (modulo) {

      /* TAB=> PRODUCTOS GENERALES */
      const modulo_html = modulo.map((item, index) => {
         return (
            <StyledTab
               key={item.id}
               label={item.nombre.charAt(0).toUpperCase() + item.nombre.slice(1).toLowerCase()}
               onClick={() => { cargarSubModulo(index) }}
               style={{ width: 'auto', height: '60px', fontSize: '13px', }}
            />
         )
      })

      /* BOTONES=> SIMPLE | AGRUPADO | MENU */
      const submodulo_html = () => {
         if (submoduloActual) {
            // eslint-disable-next-line
            const sub = (submoduloActual || []).map((item, index) => {
               const valor = Object.values(item);
               const nombre = valor[0]['nombre'];
               if (item) {
                  return (
                     <Box>
                        <StyledToggleButton
                           onClick={() => {
                              setProductosActual(index);
                              setSubmoduloSelect(valor);
                              getSubmoduloById(valor[0]['id']);
                              //cargarProductosClick(valor[0]['id_productos']);
                              setAlignment(valor[0]['nombre']);
                           }}
                           style={{ margin: '10px' }}
                           value={nombre}
                        >
                           {nombre.charAt(0).toUpperCase() + nombre.slice(1).toLowerCase()}
                        </StyledToggleButton>
                     </Box>
                  )
               }
            })
            return (sub);
         }
      }

      /* TABLA PRODUCTOS POR SUBMODULO*/
      const producto_html = () => {
         const productos = PA['productos'];
         if (productos) {
            for (let i = 0; i < productos.length; i++) {
               productos[i] = Object.values(productos[i]);
            }
         }
         return (
            <TableContainer component={Paper} sx={{ marginTop: '20px' }} >
               <Table sx={{ minWidth: 650 }} aria-label="simple table">
                  <TableHead>
                     <TableRow>
                        <TableCell>{`N°`}</TableCell>
                        <TableCell>Código - Nombre</TableCell>
                        <TableCell align="right">Precio</TableCell>
                        <TableCell align="right">Precio oferta&nbsp;</TableCell>
                        <TableCell align="right">Acciones&nbsp;</TableCell>
                     </TableRow>
                  </TableHead>
                  <TableBody>
                     {(productos || []).map((item, index) => (
                        item[0]['is_supply'] === 0 && (
                           <TableRow
                              key={item[0]['id']}
                              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                              classes={{
                                 hover: 'hover-row-t'
                              }}
                           >
                              <TableCell component="th" scope="row">
                                 {index + 1}
                              </TableCell>
                              {item[0]['id_tipo_producto'] !== 3 &&
                                 <TableCell component="th" scope="row">
                                    {item[0]['codigo_erp']} - {item[0]['nombre'].charAt(0).toUpperCase() + item[0]['nombre'].slice(1).toLowerCase()}

                                 </TableCell>
                              }
                              {item[0]['id_tipo_producto'] === 3 &&
                                 <TableCell component="th" scope="row">
                                    {item[0]['codigo_erp']} - {item[0]['nombre'].charAt(0).toUpperCase() + item[0]['nombre'].slice(1).toLowerCase()}
                                 </TableCell>
                              }
                              <TableCell align="right">{moneyFormatInt(item[0]['precio_standar'] || 0)}</TableCell>
                              <TableCell align="right">{item[0]['precio_oferta'] ? moneyFormatInt(item[0]['precio_oferta']) :  "--"}</TableCell>
                              <TableCell align="right" sx={{ width: '150px' }}>
                                 {item[0]['id_tipo_producto'] === 2 &&
                                    <IconButton aria-label="add to shopping cart" color="success" sx={{ marginRight: '5px' }}
                                       onClick={() => {
                                          setPopUp('showProductoAgrupado');
                                          setOpenPopup(true);
                                          setNombreProductSelect(item[0]['nombre']);
                                          cargarItemsPA(item[0]['id']);
                                       }}
                                    >
                                       <VisibilityIcon />
                                    </IconButton>
                                 }

                                 {item[0]['id_tipo_producto'] === 3 &&
                                    <IconButton aria-label="add to shopping cart" color="secondary" sx={{ marginRight: '5px' }}
                                       onClick={() => {
                                          cargarProductosMenu(item[0]['id']);
                                          setPopUp('showMenu');
                                          setNombreProductSelect(item[0]['nombre']);
                                          setOpenPopup(true);
                                       }}
                                    >
                                       <VisibilityIcon />
                                    </IconButton>
                                 }
                                 {/* <IconButton aria-label="add to shopping cart" color="primary" sx={{ marginRight: '5px' }}
                                    onClick={() => editarProducto(item[0]['id'], item[0]['id_tipo_producto'])}
                                 >
                                    <EditIcon />
                                 </IconButton> */}
                                 <IconButton aria-label="add to shopping cart" color="error"
                                    onClick={() => {
                                       //eliminarProducto(item[0]['id'], item[0]['id_tipo_producto'])
                                       setShowModalDelete(true);
                                       setPopUp('deleteProduct');
                                       setIdProduct(item[0]['id']);
                                       setIsSupply(item[0]['is_supply']);
                                       setIdTipoProduct(item[0]['id_tipo_producto']);
                                    }}
                                 >
                                    <DeleteIcon />
                                 </IconButton >
                              </TableCell>
                           </TableRow>
                        )
                     ))}
                  </TableBody>
               </Table>
            </TableContainer>
         )
      }

      /* CONTENT => SUBMODULO: SIMPLE | AGRUPADO | MENU */
      const submodulo_tab = () => {
         if (submoduloActual) {
            // eslint-disable-next-line
            const sub = (submoduloActual || []).map((item, index) => {
               const valor = Object.values(item);
               const nombre = valor[0]['nombre'].charAt(0).toUpperCase() + valor[0]['nombre'].slice(1).toLowerCase();

               if (index === productosActual) {
                  return (
                     <Box>
                        <Box sx={{
                           display: 'grid',
                           gridTemplateColumns: 'repeat(4, 1fr)',
                           gap: 1,
                           gridTemplateRows: 'auto',
                           gridTemplateAreas: `"main main . sidebar"`,
                           marginTop: '20px'
                        }}
                        >
                           <Box sx={{ gridArea: 'main' }}>{`Submódulo: ${nombre}`}&nbsp;&nbsp;</Box>

                           <Box sx={{ gridArea: 'sidebar', display: 'flex', flexDirection: 'row-reverse' }}>
                              <Box>
                                 <StyledButton
                                    onClick={() => eliminarSubmodulo(valor[0]['id'])}
                                    variant="outlined" color="error" style={{ width: '190px', height: '40px', padding: '0', marginRight: '10px' }}>
                                    Eliminar Submódulo
                                 </StyledButton>
                              </Box>

                              <Box sx={{ gridArea: 'sidebar', display: 'flex', flexDirection: 'row-reverse' }}>
                                 <StyledButton
                                    onClick={() => { setOpenPopup(true); setPopUp('producto') }}
                                    variant="outlined" color="success" style={{ width: '190px', height: '40px', padding: '0', marginRight: '10px' }}>
                                    Agregar Producto
                                 </StyledButton>
                              </Box>
                           </Box>

                        </Box>
                        {PA !== null && loadData === false && producto_html()}

                        {PA === null && loadData === false &&
                           <Grid container justifyContent="center"> <Box> <Grid container justifyContent="center"> <RemoveShoppingCartIcon className="iconocarrito" /></Grid> <div>No existen productos agregados a esta subcategoría.</div></Box></Grid>
                        }
                     </Box>
                  )
               }
            })
            return (sub);
         }
      }

      const transformDataProduct = (data) => {
         const productos = data;
         if (productos.length < 1) return

         if (productos) {
            for (let i = 0; i < productos.length; i++) {
               productos[i] = Object.values(productos[i]);
            }
         }
         return productos
      }

      /* CONTENT => SUBMODULO */
      const modulo_tab = modulo.map((item, index) => {
         return (
            <Box >
               <TabPanel value={value} index={index + 1} key={item.id} >
                  <Box sx={{
                     display: 'grid',
                     gridTemplateColumns: 'repeat(4, 1fr)',
                     gap: 1,
                     gridTemplateRows: 'auto',
                     gridTemplateAreas:
                        `"main main . sidebar"`,
                  }}>
                     <Box sx={{ gridArea: 'main' }}>Submódulos:</Box>
                     <Box sx={{ gridArea: 'sidebar', display: 'flex', flexDirection: 'row-reverse' }}>
                        <StyledButton onClick={handleClickOpen} variant="outlined" color="error" size="small" sx={{ marginRight: '10px' }}>
                           Eliminar Módulo
                        </StyledButton>
                     </Box>
                     <Dialog
                        open={open}
                        TransitionComponent={Transition}
                        keepMounted
                        onClose={handleClose}
                        aria-describedby="alert-dialog-slide-description"
                     >
                        <DialogTitle>{"¿Estás seguro de eliminar esta categoría?"}</DialogTitle>
                        <DialogContent>
                           <DialogContentText id="alert-dialog-slide-description">
                              <span>Esta acción es irreversible.</span>
                           </DialogContentText>
                        </DialogContent>
                        <DialogActions>
                           <Button
                              classes={{
                                 root: "styleButonPrimary"
                              }}
                              variant="contained" onClick={handleClose}>Cancelar</Button>
                           <Button variant="contained" startIcon={<DeleteIcon />} color="error" onClick={() => eliminarModulo(item)} >Eliminar</Button>

                        </DialogActions>
                     </Dialog>
                  </Box>
                  {/* <Divider sx={{marginTop: '10px'}} /> */}
                  <Box>
                     {openPopup && popUp === 'submodulo' &&
                        <Popup
                           largo="sm"
                           title='Agregar Submodulo'
                           openPopup={openPopup}
                           setOpenPopup={setOpenPopup}
                        >
                           <AddSubCategoria id_modulo={item.id} handleLoad={handleLoad} indice={indice} />
                        </Popup>}
                     <StyledToggleButtonGroup
                        size="small"
                        color="primary"
                        value={alignment}
                        exclusive
                        onChange={handleChangeToggleButton}
                     >
                        <Box sx={{ display: 'inline-flex', flexWrap: 'wrap' }}>
                           <StyledButton size="small" style={{ marginRight: '20px', color: 'white', marginTop: '20px', background: '#2e7d32' }} variant="outlined"
                              onClick={() => { setOpenPopup(true); setPopUp('submodulo') }}
                           >
                              Agregar
                              <AddCircleIcon style={{ verticalAlign: 'middle', marginLeft: '4px', marginTop: '-3px', fontSize: '25px' }} sx={{ color: '#FFF' }} />
                           </StyledButton>

                           {submodulo_html()}
                        </Box>
                     </StyledToggleButtonGroup>
                     <Divider sx={{ marginTop: '20px' }} />
                     {loadData === false && submodulo_tab()}
                     <Box>
                        {openPopup && popUp === 'producto' &&
                           <Popup
                              title='Agregar Producto'
                              largo="md"
                              openPopup={openPopup}
                              setOpenPopup={setOpenPopup}
                           >
                              <AddProducto
                                 id_modulo={item.id}
                                 submodulo={submoduloSelect}
                                 handleLoad={handleLoad}
                                 indice_mod={indice}
                                 indice_sub={productosActual}
                                 nameSubmodulo={alignment}
                                 productsExist={PA && PA['productos'] && transformDataProduct(PA['productos'])}
                                 closeModal={() => { setOpenPopup(false) }}
                              />
                           </Popup>}
                     </Box>
                  </Box>
               </TabPanel>
            </Box>

         )
      });

      /* MAIN-PAGE */
      return (

         <Protected>

            <Snackbar
               open={snackBarConfig.open}
               autoHideDuration={snackBarConfig.autoHideDuration}
               onClose={() => setSnackBarConfig(prev => ({ ...prev, open: false }))}
               anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
            >
               <Alert
                  onClose={() => setSnackBarConfig(prev => ({ ...prev, open: false }))}
                  severity={snackBarConfig.severity}
                  variant="filled"
               >
                  {snackBarConfig.message}
               </Alert>
            </Snackbar>


            <Divider sx={{ mt: 2, mb: 2 }} />

            {alerta !== '' &&
               <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
                  <Alert onClose={handleClose} severity="success" sx={{ width: '100%' }}>
                     {alerta}
                  </Alert>
               </Snackbar>
            }
            {openPopup && popUp === 'modulo' &&
               <Popup
                  largo="sm"
                  title='Agregar Modulo'
                  openPopup={openPopup}
                  setOpenPopup={setOpenPopup}
               >
                  <AddCategoria />
               </Popup>
            }
            {openPopup && popUp === 'showProductoAgrupado' &&
               <Popup
                  largo="md"
                  title={`Items del producto: ${nombreProductSelect.toUpperCase()}`}
                  openPopup={openPopup}
                  setOpenPopup={setOpenPopup}
               >
                  <TableContainer component={Paper} sx={{ marginTop: '20px' }} >
                     <Table sx={{ minWidth: 650 }} aria-label="simple table">
                        <TableHead>
                           <TableRow>
                              <TableCell>{`N°`}</TableCell>
                              <TableCell>Código - Nombre</TableCell>
                              <TableCell align="right">Precio</TableCell>
                              <TableCell align="right">Precio oferta&nbsp;</TableCell>
                              <TableCell align="right">Cantidad</TableCell>
                           </TableRow>
                        </TableHead>
                        <TableBody>
                           {items.map((item, index) => (
                              <TableRow
                                 key={item.id}
                                 sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                 classes={{
                                    hover: 'hover-row-t'
                                 }}
                              >
                                 <TableCell component="th" scope="row">{index + 1}</TableCell>
                                 <TableCell component="th" scope="row">{item?.producto_agrupado_hijo?.codigo_erp} - {item?.producto_agrupado_hijo?.nombre.charAt(0).toUpperCase() + item?.producto_agrupado_hijo?.nombre.slice(1).toLowerCase()}</TableCell>

                                 <TableCell align="right">{item.producto_agrupado_hijo?.precio_standar || "--"}</TableCell>
                                 <TableCell align="right">{item?.producto_agrupado_hijo?.precio_oferta || "--"}</TableCell>
                                 <TableCell align="right">
                                    {`${item.cantidad || "--"} (${item?.producto_agrupado_hijo?.unidad_medida?.abbreviation})`}
                                 </TableCell>
                              </TableRow>
                           ))}
                        </TableBody>
                     </Table>
                  </TableContainer>
               </Popup>
            }
            {openPopup && popUp === 'showMenu' &&
               <Popup
                  largo="md"
                  title={`Items del producto: ${nombreProductSelect.toUpperCase()}`}
                  openPopup={openPopup}
                  setOpenPopup={setOpenPopup}
               >
                  {loadData === false && itemsMenu.length !== 0 &&
                     <Box sx={{ marginTop: '-20px' }}>
                        {itemsMenu.map((item, index) => (
                           <TableContainer component={Paper} sx={{ marginTop: '20px' }} >
                              <Box>
                                 <Box sx={{ marginTop: '10px', marginLeft: '10px', marginBottom: '20px' }}>
                                    <strong className="mayus">{item.nombre.toUpperCase()}.-</strong>
                                    &nbsp;&nbsp;Cantidad de productos a seleccionar: {item.cantidad}
                                 </Box>
                                 {loadData === false && productosItemsMenu.length !== 0 &&
                                    productosItemsMenu[index].map((itemP, indexP) => (
                                       <Box sx={{ marginLeft: '10px', marginBottom: '10px' }}>
                                          <div> {indexP + 1}.- {itemP.nombre}</div>
                                       </Box>
                                    ))}
                              </Box>
                           </TableContainer>
                        )
                        )}
                     </Box>
                  }
               </Popup>
            }

            {loadData && <Loading />}

            <Grid container>
               <Grid item sm={12} sx={{ minHeight: '60vh', maxHeight: '80vh', overflowY: 'scroll' }}>
                  <Box sx={{ maxWidth: '100%', bgcolor: 'background.paper' }}>

                     <Tabs
                        value={value}
                        onChange={handleChange}
                        variant="scrollable"
                        scrollButtons
                        allowScrollButtonsMobile
                        aria-label="scrollable auto tabs example"
                     >

                        <StyledTab
                           onClick={() => { setOpenPopup(true); setPopUp('modulo') }}
                           label={
                              <div><AddCircleIcon style={{ verticalAlign: 'middle', marginTop: '-3px', fontSize: '25px' }}
                                 sx={{ color: 'green' }} /> Nuevo </div>
                           }
                           style={{
                              width: 'auto',
                              height: '60px',
                              fontSize: '13px',
                           }} />

                        {modulo_html}
                     </Tabs>

                  </Box>

                  <Box sx={{ backgroundColor: '#EDEDED' }}>
                     {modulo_tab}
                  </Box>
               </Grid>
            </Grid>

            {
               showModalDelete && popUp === "deleteProduct" && (
                  <DeletedProduct
                     open={showModalDelete}
                     closeModal={closeModalDelete}
                     ProductId={idProduct}
                     TipoProductId={idTipoProduct}
                     id_sub={submoduloSelect[0]['id']}
                     isSupply={isSupply}
                     handleLoad={handleLoad}
                  />
               )
            }

            {openEditProducto && editProducto.type === 1 &&
               <Popup
                  largo="sm"
                  title='Editar Producto'
                  openPopup={openEditProducto}
                  setOpenPopup={setOpenEditProducto}
               >
                  <EditProducto idProducto={editProducto.id} handleLoad={handleLoad} closeModal={() => { setOpenEditProducto(false) }} />
               </Popup>}

            {openEditProducto && editProducto.type === 2 &&
               <Popup
                  largo="md"
                  title='Editar Producto'
                  openPopup={openEditProducto}
                  setOpenPopup={setOpenEditProducto}
               >
                  <EditProductoAgrupado idProducto={editProducto.id} handleLoad={handleLoad} closeModal={() => { setOpenEditProducto(false) }} />
               </Popup>}

            {openEditProducto && editProducto.type === 3 &&
               <Popup
                  largo="md"
                  title='Editar Menu'
                  openPopup={openEditProducto}
                  setOpenPopup={setOpenEditProducto}
               >
                  <EditProductoMenu idProducto={editProducto.id} handleLoad={handleLoad} closeModal={() => { setOpenEditProducto(false) }} />
               </Popup>}

         </Protected>
      );
   }
}

