import { Protected } from "@/components/layout/Protected";
import React from "react";
import {Props} from './Empleados.type'




export const Empleados: React.FC<Props> = (props:Props): JSX.Element => {

  



   return (
      <Protected></Protected>
   )

}
