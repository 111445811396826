import axios from 'axios';
import { API_URL_NUPY } from '@/toolbox/defaults/app';
import { readLocalStorage } from '@/toolbox/helpers/local-storage-helper';
import { KEY_USER_DATA } from '@/toolbox/constants/local-storage';

export const PedidoRepository = {
    savePedido: async (id_Tikeck: number, id_mesa: string, id_cliente: string, state: string,
        tipo: string, montoTotal: number, descuentoMonto: number, descuentoPorductos: number, /* idForma: number, */ idUser: number, puntos: number
    ): Promise<any> => {
        const res = await axios.post(`${API_URL_NUPY}/pedido/crear_pedido`, {
            id_ticket: id_Tikeck,
            id_mesa: id_mesa ? id_mesa : null,
            id_cliente: id_cliente ? id_cliente : null,
            // nombre_cliente: cliente ? cliente : "ClienteGeneral",
            estado: state,
            tipo,
            monto_total: montoTotal,
            descuento_monto_total: descuentoMonto,
            descuento_productos_total: descuentoPorductos,
            //id_forma_pago: idForma,
            id_user: idUser,
            puntos: puntos
        });
        return res.data;
    },
    editPedido: async (idPedido: number, cliente: string, estado: string, montoTotal: number, descuentoMonto: number, descuentoPorducto: number/* , formaPago: number */): Promise<any> => {
        const res = await axios.put(`${API_URL_NUPY}/pedido/editar_pedido/${idPedido}`, {
            //  nombre_cliente: cliente,
            id_cliente: cliente || null,
            estado,
            monto_total: montoTotal || null,
            //id_forma_pago: formaPago,
            descuento_monto_total: descuentoMonto || null,
            descuento_productos_total: descuentoPorducto || null,
        });
        return res.data;
    },
    editPedidoStatus: async (idTicket: number, estado: string, idPedido: number): Promise<any> => {
        const res = await axios.patch(`${API_URL_NUPY}/pedido/editar_pedido_status/${idTicket}`, {
            estado,
            idPedido
        });
        return res.data;
    },
    backState: async (idTicket: number, estado: string): Promise<any> => {
        const res = await axios.patch(`${API_URL_NUPY}/pedido/backState/${idTicket}`, {
            estado,
        });
        return res.data;
    },
    getPedido: async (idTicket: number): Promise<any> => {
        const res = await axios.get(`${API_URL_NUPY}/pedido/get_pedido/${idTicket}`);
        return res.data;
    },

    getPedidoDetalle: async (idPedido: number): Promise<any> => {
        const res = await axios.get(`${API_URL_NUPY}/pedido/get_pedido_detail/${idPedido}`);
        return res.data;
    },

    getTicketDetalle: async (idTicket: number): Promise<any> => {
        const res = await axios.get(`${API_URL_NUPY}/pedido/get_ticket_detail/${idTicket}`);
        return res.data;
    },

    getPedidoDetalleByMesa: async (idMesa: number): Promise<any> => {
        const res = await axios.get(`${API_URL_NUPY}/pedido/get_pedido_detail_byMesa/${idMesa}`);
        return res.data;
    },

    getPedidoXFinalizar: async (): Promise<any> => {
        const res = await axios.get(`${API_URL_NUPY}/pedido/get_pedido_por_finalizar`);
        return res.data;
    },

    getPedidoFinalizadoByClient: async (): Promise<any> => {
        const res = await axios.get(`${API_URL_NUPY}/pedido/get_pedido_finalizado_byClient`);
        return res.data;
    },
    getPedidoDetailByClient: async (): Promise<any> => {
        const res = await axios.get(`${API_URL_NUPY}/pedido/get_pedido_detail_byCliente`);
        return res.data;
    },

    getTicketsByClient: async (): Promise<any> => {
        const res = await axios.get(`${API_URL_NUPY}/pedido/get_tickets_byClient`);
        return res.data;
    },

    editMontoPedido: async (mesaId: any, clientId: number, idPedido: number, montoTotal: number, descuentoMonto: number, descuentoProductos: number): Promise<any> => {
        const res = await axios.put(`${API_URL_NUPY}/pedido/editar_pedido/${idPedido}`, {
            monto_total: montoTotal,
            descuento_monto_total: descuentoMonto,
            descuento_productos_total: descuentoProductos,
            id_cliente: clientId ? clientId : null,
            id_mesa: mesaId ? mesaId : null
        });
        return res.data;
    },

    addNewTicket: async (idPedido: number, idNewTicket: number, montoTicket: number): Promise<any> => {
        const res = await axios.post(`${API_URL_NUPY}/pedido/add_new_ticket/${idPedido}/${idNewTicket}`, {
            montoTicket: montoTicket,
        });
        return res.data;
    },

    changeTablePedido: async (idPedido: number, idMesa: number): Promise<any> => {
        const res = await axios.post(`${API_URL_NUPY}/pedido/changeTablePedido/${idPedido}/${idMesa}`);
        return res.data;
    },
    editPedidoV2: async (idPedido: number, payload: {}): Promise<any> => {
        const res = await axios.put(`${API_URL_NUPY}/pedido/editar_pedido/${idPedido}`, payload);
        return res.data;
    },
    update: async (idpedido: number, payload: {}): Promise<any> => {
        const res = await axios.post(`${API_URL_NUPY}/pedido/update/${idpedido}`, payload);
        return res.data;
    },
}


