import { connect } from "react-redux";
import { bindActionCreators, compose } from "redux";
import { withRouter } from "react-router-dom";
import { actionSetEcommerce } from '@/redux/actions';
import { HeaderEcommerce as headerclient } from './HeaderEcommerce';

const mapStateToProps = ({ ecommerceReducer }) => ({
    $store: {
      ecommerceReducer,
    },
 });
 const mapDispatchToProps = (dispatch) => ({
    $action: bindActionCreators(
       {
        actionSetEcommerce: actionSetEcommerce
       },
       dispatch
    ),
 });
 
 
 export const HeaderEcommerce: any = compose(
    withRouter,
    connect(mapStateToProps, mapDispatchToProps)
 )(headerclient);
