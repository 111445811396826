import { http } from '../http/http';
import { LogoutDTO, LoginDTO, UserDTO} from '../http/dto/AuthenticationDTO';
import {  Logout, Authentication } from '../models/Authentication';
import { Login } from '../models/Authentication';
import { API_URL_BASE, API_URL_NUPY } from '@/toolbox/defaults/app';
import axios from 'axios';

export const authenticationRepository = {
  login: async (user_nick: string, rut_empresa: string, password: string): Promise<Login>=> {
      const resp = await http.post<LoginDTO>(`${API_URL_BASE}/auth/login`, {
        usuario: user_nick,
        rut_empresa: rut_empresa,
        password: password
      })

      return {
        error: resp.error,
        status: resp.status,
        message: resp.message,
        data: !!resp.data ? {
              token: resp.data?.access_token || '',
              expires_in: resp.data?.expires_in || 0,
              token_type: resp.data?.token_type || '',
              message: resp.data?.message || '',
              token_softnet: resp.data?.token_softnet || '',
        } : undefined
      }
  },

  loginEcommerce: async (name: string, rut:string, username:string, email:string, password:string): Promise<Login>=> {
  const resp = await http.post<LoginDTO>(`${API_URL_BASE}/auth/loginEcommerce`, {
      name: name,
      rut: rut,
      username: username,
      email: email,
      password: password
  })

  return {
      error: resp.errors,
      status: resp.status,
      message: resp.message,
      data: !!resp.data ? {
            token: resp.data?.access_token || '',
            expires_in: resp.data?.expires_in || 0,
            token_type: resp.data?.token_type || '',
            message: resp.data?.message || '',
            cliente_id: 0,
            error: resp.data?.error || '',
      } : undefined
    }
  },

  loginQR: async (mesaID: string, name: string, rut:string, user:string, password:string): Promise<Login>=> {
  const resp = await http.post<LoginDTO>(`${API_URL_BASE}/auth/login-client`, {
      name: name,
      rut: rut,
      mesaID: mesaID,
      user: user,
      password: password
  })

  return {
      error: resp.error,
      status: resp.status,
      message: resp.message,
      data: !!resp.data ? {
            token: resp.data?.access_token || '',
            expires_in: resp.data?.expires_in || 0,
            token_type: resp.data?.token_type || '',
            message: resp.data?.message || '',
            token_softnet: resp.data?.token_softnet || '',
            cliente_id: resp.data?.cliente_id || 0,
      } : undefined
    }
  },

  loginClient: async (mesaID: string, user:string, password:string): Promise<Login>=> {
    const resp = await http.post<LoginDTO>(`${API_URL_BASE}/auth/login-client-user`, {
       mesaID: mesaID,
       user: user,
       password: password
    })

    return {
       error: resp.error,
       status: resp.status,
       message: resp.message,
       data: !!resp.data ? {
             token: resp.data?.access_token || '',
             expires_in: resp.data?.expires_in || 0,
             token_type: resp.data?.token_type || '',
             message: resp.data?.message || '',
             token_softnet: resp.data?.token_softnet || '',
             cliente_id: resp.data?.cliente_id || 0,
       } : undefined
    }
  },

   loginSoftnet: async (username: string, rut: string, password: string): Promise<any>=> {
      const resp = await http.post<any>(`https://api2.softnet.cl/login`, {
         username: username,
         rut: rut,
         password: password
      })
      return resp;
   },
   logout: async (): Promise<Logout>=> {
      const resp = await http.post<LogoutDTO>(`${API_URL_BASE}/auth/logout`)
      return {
         error: resp?.error,
         status: resp?.status,
         message: resp?.message
      }
   },
   profile: async (token:string): Promise<Authentication>=> {
      const resp = await http.get<UserDTO>(`${API_URL_BASE}/auth/me`)
      return {
         user: {
            id_usuario: resp.data?.id,
            rut_usuario: resp.data?.rut,
            nombre: resp.data?.nombre,
            usuario: resp.data?.usuario,
            email: resp.data?.email,
            rol: resp.data?.role?.rol,
            id_empresa: resp.data?.sucursales?.id_empresa,
            id_sucursal: resp.data?.id_sucursal,
            nombre_empresa: resp.data?.sucursales?.empresa.name,
            rut_empresa: resp.data?.sucursales?.empresa.rut,
            id_tipo_empresa: resp.data?.sucursales?.empresa.id_tipo_empresa,
            mesas: resp.data?.sucursales?.empresa.mesas,
            equivalencia_puntos_empresa: resp.data?.sucursales?.empresa.equivalencia_puntos,
            id_country: resp.data?.sucursales?.empresa?.country?.id,
            country_name: resp.data?.sucursales?.empresa?.country?.nombre,
            country_abbreviation: resp.data?.sucursales?.empresa?.country?.abbreviation,
            main_redirect: '/',
            idcaja: resp.data?.idcaja,
            caja: resp.data.caja
         },
         token: token,
         error: resp.error,
         status: resp?.status,
      }
   },

   //registro para empresa
   registro: async ( id: Number, nombre: String, rut: String , formas_de_pago: String, state: Number): Promise<any> =>{
      const resp = await axios.post(`${API_URL_NUPY}/registro_empresa`, {id,nombre,rut,formas_de_pago,state});
      return resp;
    },

    registroUsuario: async ( id: Number, nombre: String, rut: String , formas_de_pago: String, state: Number): Promise<any> =>{
      const resp = await axios.post(`${API_URL_NUPY}/registro_empresa`, {id,nombre,rut,formas_de_pago,state});
      return resp;
    },
   dataUser,
   dataEmpresa,
  //  bodegasPorSucursal,
}

function dataUser(token){
   return fetch(`https://api2.softnet.cl/datoUsuario`, {
     method: 'GET',
     headers: {
       'Access-Control-Allow-Origin':'*',
       'Content-Type': 'application/x-www-form-urlencoded',
       token: token,
     },
   })
     .then(datosUsuarios => datosUsuarios.json())
     .then(datosUsuarios => {
       return datosUsuarios[0];
     })
     .catch(error => {
       return false;
     })
 }

 function dataEmpresa(token){
   return fetch(`https://api2.softnet.cl/datoEmpresa`, {
     method: 'GET',
     headers: {
       'Access-Control-Allow-Origin':'*',
       'Content-Type': 'application/x-www-form-urlencoded',
       token: token,
     },
   })
     .then(datosEmpresa => datosEmpresa.json())
     .then(datosEmpresa => {
       return datosEmpresa[0];
     })
     .catch(error => {
       return false;
     })
 }

 function bodegasPorSucursal(token, id_sucursal){
  return fetch(`https://api2.softnet.cl/bodega/${id_sucursal}`, {
    method: 'GET',
    headers: {
      'Access-Control-Allow-Origin':'*',
      'Content-Type': 'application/x-www-form-urlencoded',
      token: token,
      //idsucursal: id_sucursal,
    },
  })
    .then(Bodega => Bodega.json())
    .then(Bodega => {
      localStorage.setItem('bodegas', JSON.stringify(Bodega))
      return Bodega;
    })
    .catch(error => {
      return false;
    })
}

