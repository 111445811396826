import { productoService } from "@/service/services/Producto.service";
import { KEY_EMPRESA, PRODUCTS_CARD } from "@/toolbox/constants/local-storage";
import { useLocalStorage } from "@/toolbox/hooks/local-storage.hook";
import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material";
import React, { useEffect } from "react";
import {Props} from './Productos.type'
import { Loading } from '@/components/common/Loading';


export const Productos: React.FC<Props> = (props:Props): JSX.Element => {

  const {indexProducts, closeModal, arrayProducts, setArrayProducts} = props;

  const [productos, setProductos] = React.useState([]);
  // eslint-disable-next-line
  const [filter, setFilter] = React.useState([]);
  const [loading, setLoading] = React.useState<boolean>(false);
  const [dataEmpresa] = useLocalStorage(KEY_EMPRESA, {
    id: 0, id_tipo_empresa:0,  country_abbreviation: "",
  });

  const [dataLocal] = useLocalStorage(PRODUCTS_CARD, []);
  const [dataProducts, setDataProducts] = React.useState([]);

  useEffect(() => {
    getProducts();
    setDataProducts(dataLocal || []);
    // eslint-disable-next-line
  }, []);

  const getProducts = async() => {
    setLoading(true);

    await productoService.getProductosSimplesCompany(dataEmpresa.id).then((data) => {
      if(data.status === 200){
          let arrayProducts = [];
          // eslint-disable-next-line
          (data.productos).map((item) => {
              arrayProducts.push({
              "id_producto": item.id,
              "codigo_erp": item?.codigo_erp,
              "id_unidad_medida": item?.id_unidad_medida,
              "nombre": item?.nombre,
              "id_tipo_producto": item?.id_tipo_producto,
              "precio_standar": item?.precio_standar,
              "precio_oferta": item?.precio_oferta,
              "cantidad_peso": item?.cantidad_peso,
              });
          });
          setProductos(arrayProducts);
          setFilter(arrayProducts);
      }
        setLoading(false);
    });

  }

  const handleChangeProduct = (product) => {
      if (arrayProducts) {
         let exist = arrayProducts.find(e => (e.codigo_erp === product?.codigo_erp && !(e?.wasDeleted)));
         if(exist){
            alert('el producto ya se encuentra en la compra');
         }else{
            if (arrayProducts[indexProducts]) {
               let lasProduct: any;
               if (arrayProducts[indexProducts]?.lasProduct) {
                  lasProduct = arrayProducts[indexProducts]?.lasProduct;
               } else {
                  lasProduct =  arrayProducts[indexProducts];
               }
               arrayProducts[indexProducts] = {
                  ...product,
                  cantidad: 1,
                  lasProduct: lasProduct
               }
               setArrayProducts([...arrayProducts])
            } else {
               alert('ocurrio algun error');
            }
         }
         closeModal && closeModal();
      }
  }

  return (
    <>
    {loading && <Loading/>}

    <TableContainer component={Paper} sx={{ marginTop: '20px' }} >
      <Table sx={{ minWidth: 650 }} aria-label="simple table">

        <TableHead>
            <TableRow>
              <TableCell>{`N°`}</TableCell>
              <TableCell>Código</TableCell>
              <TableCell>Nombre</TableCell>
              <TableCell align="right">Precio</TableCell>
            </TableRow>
        </TableHead>

        <TableBody>
            {productos.map((item,index) => (
              <TableRow
                key={index}
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                classes={{
                  hover: 'hover-row-t'
                }}
              >
                <TableCell component="th" scope="row">
                  {index+1}
                </TableCell>

                <TableCell component="th" scope="row">
                  {item.codigo_erp}
                </TableCell>

                <TableCell component="th" scope="row">
                  <button className="H-card__tableButton"
                    onClick={()=>{handleChangeProduct(item)}}
                  >
                  {item.nombre.charAt(0).toUpperCase() + item.nombre.slice(1).toLowerCase()}

                  </button>
                </TableCell>

                <TableCell align="right"><span >{ item.precio_oferta ? item.precio_oferta : item.precio_standar}</span></TableCell>

              </TableRow>
            ))}
        </TableBody>

      </Table>
    </TableContainer>
    </>
  )
}




