import React, { useEffect, useState } from "react";
import { Form, Formik } from 'formik';
import { Divider, Grid, TextField, Typography, Tooltip, Autocomplete } from '@mui/material';
import PriorityHighIcon from '@mui/icons-material/PriorityHigh';
import { Snackbar, Alert } from '@mui/material';
import { KEY_EMPRESA, KEY_USER_DATA } from "@/toolbox/constants/local-storage";
import { readLocalStorage } from "@/toolbox/helpers/local-storage-helper";
import { cajas } from "@/service/services/Cajas.service";
import { Loading } from "@/components/common/Loading";

export const AddCaja: React.FC<any> = (props:any): JSX.Element => {
  
    const {onListCaja, closeModal, caja, sucursales} = props;
    console.log(sucursales)
    const [loadData,setLoadData] = React.useState<boolean>(false);
    const [sucursalSelected, setSucursalSelected] = React.useState<any>(null);
    // eslint-disable-next-line
    const [data, setData] = useState({
        id : caja && caja.id ? caja.id : 0,
        nombre: caja && caja.nombre ? caja.nombre : '',
        id_sucursal: caja && caja.id_sucursal ? caja.id_sucursal : ''
    });

    const [snackBarConfig, setSnackBarConfig] = useState<any>({
        open: false,
        severity: 'error',
        message: 'Error',
        autoHideDuration: 3000,
    });

    const SessionUser: any = readLocalStorage(KEY_USER_DATA);
    const idSucursal = SessionUser.user?.id_sucursal;

    const validateForm = (values) => {
        let errors: any = {};

        if(!values.nombre) errors.nombre = "nombre requerido";

        return errors;
    }

    const onSubmit = async(values, {resetForm}) =>{
        const state = 1;
        setLoadData(true);
        if(caja){
            await cajas.editCaja(caja.id, values.nombre, sucursalSelected.id).then((data) => {
                setLoadData(false);
                if (data && data.data) {
                    onListCaja && onListCaja();
                    closeModal();
                }
             })
        }else{
            await cajas.addCaja(values.nombre, sucursalSelected.id).then((data)=>{
                if(data.status === 400){
                    setSnackBarConfig(prev => ({
                        ...prev,
                        open: true,
                        message: `${data.error}`,
                    }));
                }else{
                    onListCaja && onListCaja();
                    closeModal();
                }
            });
        }
        setLoadData(false);
    }

    useEffect(()=> {
        if(caja){
            const sucursalInitial = sucursales.find((item)=> item.id == caja.id_sucursal)
            if(!!sucursalInitial){
                setSucursalSelected(sucursalInitial)
            }
        }
    }, [caja])

    return(
        <div className="container">
            {loadData && <Loading/>}

            <Snackbar
                open={snackBarConfig.open}
                autoHideDuration={snackBarConfig.autoHideDuration}
                onClose={() => setSnackBarConfig(prev => ({ ...prev, open: false }))}
                anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                >
                <Alert
                onClose={() => setSnackBarConfig(prev => ({ ...prev, open: false }))}
                severity={snackBarConfig.severity}
                variant="filled"
                >
                {snackBarConfig.message}
                </Alert>
            </Snackbar>

            <Formik enableReinitialize initialValues={data} validate={ (values) => validateForm(values) } onSubmit={onSubmit}>

            {({values, errors, touched, handleSubmit, handleChange, setFieldValue}) => {
                return (
                    <Form onSubmit={handleSubmit}>
                        <Grid container spacing={3} alignItems="center" justifyContent="center">

                        <Grid item xs={12} container style={{display: "flex", alignItems: "center"}}>
                            <Grid item xs={4}>
                                <Typography variant="subtitle1" className="custom-input"><b>Nombre:</b></Typography>
                            </Grid>
                            <Grid item xs={8} style={{display: 'flex', alignItems: "center"}}>
                                {
                                    errors.nombre && touched.nombre ? true : false && (
                                        <Tooltip title={errors.nombre}>
                                            <PriorityHighIcon className={'iconWarning'}/>
                                        </Tooltip>
                                    )
                                }
                                <TextField
                                id="nombre"
                                type="text"
                                name="nombre"
                                fullWidth
                                value={values.nombre}
                                onChange={handleChange}
                                placeholder="Nombre de la sala"
                                error={errors.nombre && touched.nombre ? true : false}
                                />
                            </Grid>
                        </Grid>
                        <Grid item xs={12} container style={{display: "flex", alignItems: "center"}}>
                            <Grid item xs={4}>
                                <Typography variant="subtitle1" className="custom-input"><b>Sucursal:</b></Typography>
                            </Grid>
                            <Grid item xs={8} style={{display: 'flex', alignItems: "center"}}>
                            <Autocomplete
                    disablePortal
                    id="combo-box-demo"
                    value={sucursalSelected}
                    options={sucursales || []}
                    getOptionLabel={(option:any) => option?.nombre || ""}
                    isOptionEqualToValue={(option, value) => option?.id === value?.id}
                    fullWidth
                    renderInput={(params) => <TextField {...params} label="" />}
                    onChange={(e, newValue) => {
                        setSucursalSelected(newValue);
                    }}
                    size="small"
                />
                            </Grid>
                        </Grid>

                        </Grid>
                        <Divider sx={{ my: 2 }} />

                        <div className="form-group mb-3 buton_container_modal">
                            <button type="submit" className="btn btn-primary">
                            {caja ? "Editar Caja" : "Agregar Caja"}
                            </button>
                        </div>

                    </Form>
                )
            }}

            </Formik>
  </div>
    )
}
