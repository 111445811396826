import { BeneficioMontoRepository } from "../repositories/BeneficioMonto.repository"

export const beneficioMontoService = {
    getBeneficioMonto,
    register,
    update,
    deleteBeneficio,
}

async function getBeneficioMonto(){
    const res = await BeneficioMontoRepository.getBeneficioMonto();
    return res;
}

async function register(data:{}){
    const res = await BeneficioMontoRepository.register(data);
    return res;
}

async function update(data:{}, idbeneficioMonto:number){
    const res = await BeneficioMontoRepository.update(data, idbeneficioMonto);
    return res;
}

async function deleteBeneficio(idbeneficioMonto:number){
    const res = await BeneficioMontoRepository.deleteBeneficio(idbeneficioMonto);
    return res;
}