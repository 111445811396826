import { editor } from "@/service/services/Editor.service";
import React, { useState, useEffect } from "react";
import  "./../AddCategoria/AddCategoria.sass";
import { Loading } from '@/components/common/Loading/Loading';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { Button, Divider, Grid } from "@mui/material";
import { TOKEN_API } from "@/toolbox/constants/local-storage";
import { useLocalStorage } from '@toolbox/hooks/local-storage.hook';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import { useForm } from 'react-hook-form';
import Chip from '@mui/material/Chip';
import Stack from '@mui/material/Stack';
import  "./EditProductNmenu.sass"
import { DatePicker, LocalizationProvider } from "@mui/lab";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import esLocale from 'date-fns/locale/es';
import moment from "moment";


export const EditProductoMenu: React.FC<any> = ({idProducto, handleLoad, closeModal}): JSX.Element => {
   // eslint-disable-next-line
   const { register, handleSubmit, formState: { errors }, } = useForm();
    const [token] = useLocalStorage(TOKEN_API, "");
    const [loadData,setLoadData] = React.useState<boolean>(false);
    const [code ,setCode] = React.useState<string>("");
    const [name ,setName] = React.useState<string>("");
    const [price ,setPrice] = React.useState<string | number>("");
    const [priceOferta ,setPriceOferta] = React.useState<string | number>("");
    const [stockMinimo ,setstockMinimo] = React.useState<string | number>("");
    const [products, setProducts] = useState<Array<any>>([]);
    const [filter, setFilter] = useState<Array<any>>([]);
    const [textSearch, setTextSearch] = useState<string>("");
    const [cargarProd, setCargarProd] = React.useState({
       open: false,
       groupId: -1
    });
    const [dateCaducidad, setDateCaducidad] = React.useState<any>(new Date());

    const [menuGroup, setMenuGroup] = useState<Array<any>>([]);
    const [itemsMenu, setItemsMenu] = useState<Array<any>>([]);
    const [editGroup, setEditGroup] = useState<number>(-1);

    useEffect(()=> {
      if (idProducto && idProducto>=0) {
         cargar();
      }
      fetch(`https://api2.softnet.cl/producto`, {
        method: 'GET',
        headers: {
           'Access-Control-Allow-Origin':'*',
           'Content-Type': 'application/x-www-form-urlencoded',
           token: token,
        },
        })
        .then(dataProducts => dataProducts.json())
        .then(dataProducts => {
            let arrayProducts = [];
            // eslint-disable-next-line
            (dataProducts|| []).map((product) => {
               if(product.stock > 0 && product.estado === 1){
                  arrayProducts.push(product)
               }
            })
           setProducts(arrayProducts)
           setFilter(arrayProducts)
        })
        .catch(error => {
           console.log(error);
        })
    },  // eslint-disable-next-line
    [])



    const handleChangeSearch = (e) => {
        let texto = `${e.target.value}`;
        setTextSearch(texto);
        if (!texto) {
           setFilter([...products])
        }
     }

     React.useEffect(() => {
        if (textSearch) {
           let copyFilters = []
           copyFilters = products.filter(producto => (producto?.nombre.toLowerCase().includes(textSearch.toLowerCase()) || producto?.codigo.toLowerCase().includes(textSearch.toLowerCase())) )
           setFilter([...copyFilters])
        }
     }, // eslint-disable-next-line
     [textSearch])

     const agregarItem = async (products) => {
        setLoadData(true);
        const valores = {
            ...products,
            codigo_erp: products.codigo,
            nombre: products.nombre,
            precio_standar: products.precio,
            id_producto_menu: cargarProd.groupId,
        }

        await editor.addItemMenu(valores);
        await cargar();
        setLoadData(false);
     }

     const cargar = async ()=>{
      setLoadData(true);
         await editor.getProductoById(idProducto).then(async (data) => {
            if (data?.data?.productos) {
               setCode(data.data.productos[0]?.codigo_erp || '')
               setName(data.data.productos[0]?.nombre || '')
               setPrice(data.data.productos[0]?.precio_standar || '')
               setPriceOferta(data.data.productos[0]?.precio_oferta || '')
               setstockMinimo(data.data.productos[0]?.stock_minimo || '')

               let date = data.data.productos[0]?.caducidad_precio_oferta;
               if(date){
                let format = date.split("-").reverse().join("/");
                let fecha = new Date(format);
                setDateCaducidad(fecha || '');
               }

               await editor.getMenu(idProducto).then( async (dataM) => {
                  if (dataM?.menu) {
                     setMenuGroup([...dataM?.menu])
                     let datas = dataM?.menu || [];
                     let copyItems = []
                     for (let i = 0; i < datas.length; i++) {
                        await editor.getItemsMenu(datas[i].id || -1).then((itemProductos)=>{
                           copyItems.push(itemProductos.data);
                           // await setItemsMenu([...itemsMenu,itemProductos.data]);
                        })
                     }
                     setItemsMenu([...copyItems])
                     setLoadData(false);
                  } else { setLoadData(false); }
               })
            } else { setLoadData(false); }
         })

     }

     const handleChangeNameEdit = (e) => {
      setName(e.target.value || '')
    }
    const handleChangePriceEdit = (e) => {
      if (e.target.value) {
         setPrice(e.target.value || 0)
      }
    }
    const handleChangePriceOferta = (e) => {
      setPriceOferta(e.target.value || '')
    }

   const handleEditGeneralInfo = async () => {
      setLoadData(true);

      let precio_caducidad;
        if(priceOferta !== ""){
            precio_caducidad = moment(dateCaducidad).format('DD-MM-YYYY');
        }else{
            precio_caducidad = null;
        }

      await editor.editarProducto(idProducto, {
         "nombre": name,
         "precio_standar": price,
         "precio_oferta": priceOferta || null,
         "caducidad_precio_oferta": precio_caducidad|| null,
         "is_supply": false,
         "stock_minimo": stockMinimo || 0,
      });
      setLoadData(false);
      handleLoad && handleLoad();
      closeModal && closeModal();
   }

   const handleDeleteItemMenu = async(idItem: number) => {
      setLoadData(true);
      await editor.deleteItemMenu(idItem)
      await cargar();
      setLoadData(false);
   }

   const handleAddItempProduct = (idGroup: number) => {
      setCargarProd({open:true, groupId: idGroup})
   }

   const onSubmit = async (data) => {
      setLoadData(true);
      let name = "";
      let precio = 0;
      for (const property in data) {
         let indexObj = property.split('-');
         let index = Number(indexObj[0] || 0)
         if (index === editGroup) {
            if (indexObj[1] === 'nombre') {
               name = data[property] || '';
            } else if (indexObj[1] === 'cantidad') {
               precio = Number(data[property]) || 0;
            }
         }
      }
      await editor.editMenu(editGroup, name, precio);
      await cargar();
      setLoadData(false);
   }

    return(
        <div>
        {loadData && <Loading/>}
        {cargarProd.open &&
            <div>
            <div className="H-card__fcontainer">
                <Box sx={{marginTop:'10px',marginBottom: '10px'}}>
            <div className="H-card__fcontainer__filter">

                <span>Filtro:</span>

                <TextField
                    type="text"
                    value={textSearch}
                    placeholder="Buscar por código o nombre del producto"
                    onChange={handleChangeSearch}
                    classes={{
                    root: "H-card__fcontainer__filter__input"
                    }}
                    sx={{ flexGrow: 1 }}
                />
                <Box
                    sx={{
                        marginLeft: '30px'
                    }}
                >
                <Button
                    onClick={() => setCargarProd({open:false, groupId: -1})}
                    color="error"
                    size="small"
                    sx={{
                        display: 'flex',
                        justifyContent: 'flex-end',
                    }}
                ><KeyboardBackspaceIcon/>atrás</Button>
                </Box>

            </div>
            </Box>
            <span className="H-card__fcontainer__infoTable">*Para agregar un producto, de "click" sobre el nombre.</span>
            </div>
            <TableContainer component={Paper}>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
                <TableHead>
                    <TableRow>
                    <TableCell>{`N°`}</TableCell>
                    <TableCell>Código - Nombre</TableCell>
                    <TableCell align="right">Precio</TableCell>
                    <TableCell align="right">Precio oferta&nbsp;</TableCell>
                    <TableCell align="right">Stock&nbsp;</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {filter.map((products, index) => (
                    <TableRow
                        key={products.id}
                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                        classes={{
                            hover: 'hover-row-t'
                        }}
                    >
                            <TableCell component="th" scope="row">
                                {index+1}
                            </TableCell>
                            <TableCell component="th" scope="row">
                                <button className="H-card__tableButton" onClick={()=>{
                                    //setSeleccionado(products);
                                    setCargarProd({open:false,groupId:-1});


                                    agregarItem(products);

                                    }}>
                                {products.codigo} - {products.nombre}
                                </button>
                            </TableCell>
                            <TableCell align="right">{products.precio}</TableCell>
                            <TableCell align="right">{products.precio_oferta || "--"}</TableCell>
                            <TableCell align="right">{products.stock}</TableCell>
                    </TableRow>
                    ))}
                </TableBody>
            </Table>
            </TableContainer>
            </div>
         }

        {!cargarProd.open &&

         <div className="container">
            <div className="row">
               <div className="col-md-6">
                  <div className="card-body">
                     <Grid container sx={{justifyContent:"space-between", alignItems: "center"}}>
                        <Grid sx={{display:"flex", alignItems:"center", justifyContent: "center", marginBottom: "10px"}}>
                           <Box sx={{ marginRight:'8px'}}>Código Producto: </Box>
                                 <Box sx={{ display: 'flex'}}>
                                    <Box sx={{ flexGrow: 1, marginRight:'20px'}}>
                                    <TextField
                                       id="outlined-size-small"
                                       size="small"
                                       style={{marginTop: '20px'}}
                                       value={code}
                                       disabled />
                                 </Box>
                           </Box>
                        </Grid>
                        <Grid sx={{display:"flex", alignItems:"center", justifyContent: "center", marginBottom: "10px"}}>
                           <Box sx={{ marginRight:'8px'}}>Nombre Producto: </Box>
                                 <Box sx={{ display: 'flex'}}>
                                    <Box sx={{ flexGrow: 1, marginRight:'20px'}}>
                                    <TextField
                                       id="outlined-size-small"
                                       size="small"
                                       style={{marginTop: '20px'}}
                                       value={name}
                                       onChange={handleChangeNameEdit}/>
                                 </Box>
                           </Box>
                        </Grid>
                        <Grid sx={{display:"flex", alignItems:"center", justifyContent: "center", marginBottom: "10px"}}>
                           <Box sx={{ marginRight:'8px'}}>Precio Producto: </Box>
                                 <Box sx={{ display: 'flex'}}>
                                    <Box sx={{ flexGrow: 1, marginRight:'20px'}}>
                                    <TextField
                                       id="outlined-size-small"
                                       size="small"
                                       style={{marginTop: '20px'}}
                                       value={price}
                                       onChange={handleChangePriceEdit}/>
                                 </Box>
                           </Box>
                        </Grid>
                        <Grid sx={{display:"flex", alignItems:"center", justifyContent: "center", marginBottom: "10px"}}>
                           <Box sx={{ marginRight:'8px'}}>Precio oferta: </Box>
                           <Box sx={{ display: 'flex'}}>
                              <Box sx={{ flexGrow: 1, marginRight:'20px'}}>
                                    <TextField
                                       id="outlined-size-small"
                                       size="small"
                                       style={{marginTop: '20px'}}
                                       value={priceOferta}
                                       onChange={handleChangePriceOferta} />
                              </Box>
                           </Box>
                        </Grid>
                        {
                           priceOferta !== "" && (
                           <Grid sx={{display:"flex", alignItems:"center", justifyContent: "center", marginBottom: "10px", marginTop: "10px"}}>
                              <Box sx={{ marginRight:'8px'}}>Caducidad precio: </Box>
                              <Box sx={{display: 'flex'}}>
                                 <Box sx={{ flexGrow: 1, marginRight:'20px'}}>
                                    <LocalizationProvider dateAdapter={AdapterDateFns} locale={esLocale} style={{width: '300px'}}>
                                       <DatePicker
                                       views={['year', 'month', 'day']}
                                       minDate={new Date('2022-01-01')}
                                       maxDate={new Date('2025-01-01')}
                                       value={dateCaducidad}
                                       onChange={(newValue) => {
                                          setDateCaducidad(newValue);
                                       }}
                                       renderInput={(params) => <TextField {...params} helperText={null} />}
                                       />
                                    </LocalizationProvider>
                                 </Box>
                              </Box>
                           </Grid>
                           )
                        }
                     </Grid>
                     <Divider sx={{ my: 2 }} />
                     <div className="form-group mb-3 buton_container_modal">
                        <button onClick={handleEditGeneralInfo} className="btn btn-primary">Actualizar Información general</button>
                     </div>
                  </div>

                  <Divider sx={{ my: 2 }} />

                  { menuGroup.length !== 0 &&
                   <Box sx={{marginTop: '20px', alignItems: 'center', textAlign:'center'}}>
                    {menuGroup.map((item,index) => (
                        <TableContainer component={Paper} sx={{ marginTop: '20px' }} >
                           <Box>
                              <form onSubmit={handleSubmit(onSubmit)}>
                                 <Box sx={{marginTop: '10px', marginLeft: '10px',marginBottom: '20px'}}>
                                    {/* <strong className="mayus">{item.nombre.toUpperCase()}.-</strong>
                                    &nbsp;&nbsp;Cantidad de productos a seleccionar: {item.cantidad} */}
                                    <TextField
                                       label="Nombre"
                                       className="input__name"
                                       defaultValue={item.nombre}
                                       type="text"
                                       {...register(`${item.id}-nombre`)}
                                    />
                                    <TextField
                                       label="Cantidad"
                                       className="input__count"
                                       type="number"
                                       defaultValue={item.cantidad}
                                       {...register(`${item.id}-cantidad`, { min: 1, max: 20 })}
                                    />
                                    <button className="saveChange" type="submit" onClick={()=>setEditGroup(item.id)}>Actualizar</button>
                                 </Box>
                              </form>
                              <Box margin={2}><h4>Items</h4></Box>
                              <Grid sx={{ alignItems: 'center', textAlign:'center'}}>
                                 <Stack direction="row" spacing={2} margin={2} >
                                    { itemsMenu.length !== 0 &&
                                    (itemsMenu[index]|| []).map((itemP,indexP) => (
                                       <Stack
                                          direction="row" spacing={2}>
                                          <Chip label={itemP.nombre} onDelete={()=>handleDeleteItemMenu(itemP.id)} color="warning"/>
                                       </Stack>
                                    ))}
                                    <button className="addItem" onClick={()=>handleAddItempProduct(item.id)}>+</button>
                                 </Stack>
                              </Grid>
                           </Box>
                        </TableContainer>
                    )
                  )}
                  </Box>
                  }
               </div>
            </div>
         </div>
        }

        </div>

    );
}
