import React, { useState } from "react";
import {
  Box,
  Drawer,
  Typography,
  Autocomplete,
  TextField,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  FormControl,
  Select,
  MenuItem,
  Grid,
  Paper,
  IconButton,
} from "@mui/material";
import { moneyFormatInt } from "@/toolbox/helpers/money.helper";
import DeleteIcon from '@mui/icons-material/Delete';

const optionsBanco = [
  { id: 1, name: "ITAU" },
  { id: 2, name: "SANTANDER" },
]

export const CierreCuadratura = (props) => {
  const { openDrawer, setOpenDrawer, cuadratura, formasPago, gananciaTotal, handleCloseCuadratura } = props;

  const [tipoMovSelected, setTipoMovSelected] = useState(null);
  const [bancoSelected, setBancoSelected] = useState(null);
  const [monto, setMonto] = useState("");
  const [movimientos, setMovimientos] = useState([]);
  const [esCorrecto, setEsCorrecto] = useState({});
  const [montosReales, setMontosReales] = useState([]);
  const [openDescuadre, setOpenDescuadre] = useState<boolean>(false);
  const [openAjustes, setOpenAjustes] = useState<boolean>(false)

  // Función para manejar el cambio en el input de los valores reales
  const handleInputChange = (value, formaPago) => {
    
    // Verificar si ya hay un monto real para esta forma de pago
    const existingIndex = montosReales.findIndex(item => item.formaPago === formaPago);
    if (existingIndex !== -1) {
      // Si existe, actualizamos el monto real
      const updatedMontosReales = [...montosReales];
      updatedMontosReales[existingIndex].montoReal = value;
      setMontosReales(updatedMontosReales);
    } else {
      // Si no existe, agregamos el nuevo monto real
      setMontosReales(prevMontos => [...prevMontos, { formaPago, montoReal: value }]);
    }
  };


  const agregarMovimiento = () => {
    if (tipoMovSelected && monto) {
      const nuevoMovimiento = {
        tipoMovimiento: tipoMovSelected,
        banco: bancoSelected,
        monto: monto,
      };
      setMovimientos([...movimientos, nuevoMovimiento]);
      // Limpiar los campos después de agregar el movimiento
      setTipoMovSelected("");
      setBancoSelected("");
      setMonto("");
    }
  };

  const eliminarMovimiento = (index) => {
    const nuevosMovimientos = [...movimientos];
    nuevosMovimientos.splice(index, 1);
    setMovimientos(nuevosMovimientos);
  };

  const calcularTotalAjuste = () => {
    let totalIngresos = 0;
    let totalEgresos = 0;

    movimientos.forEach((movimiento) => {
      const montoNumerico = parseFloat(movimiento.monto); // Convertir a número
      if (movimiento.tipoMovimiento === "INGRESO") {
        totalIngresos += montoNumerico;
      } else {
        totalEgresos += montoNumerico;
      }
    });

    return totalIngresos - totalEgresos;
  };


  function transformData(data) {
    return data.map(item => {
      const detalleKeys = Object.keys(item.detalle);
      const nuevoDetalle = detalleKeys.map(key => {
        if (key === "total") {
          return null; // Ignorar el campo "total"
        } else {
          return {
            nombre: key,
            total: item.detalle[key].total,
            detalle: item.detalle[key].detail
          };
        }
      }).filter(detalle => detalle !== null); // Filtrar para eliminar valores nulos

      return {
        forma_pago: item.forma_pago,
        total: item.detalle.total,
        detalle: nuevoDetalle
      };
    });
  }
  const newData = transformData(formasPago);

  const calculateVerticalTotals = () => {
    const totals = {};

    // Iterar sobre cada forma de pago y sus detalles
    newData.forEach(formaPago => {
      formaPago.detalle.forEach(detalle => {
        // Inicializar el total de la forma de pago si es la primera vez que se encuentra
        if (!totals[formaPago.forma_pago]) {
          totals[formaPago.forma_pago] = 0;
        }
        // Sumar al total de la forma de pago
        totals[formaPago.forma_pago] += detalle.total;
      });
    });

    return totals;
  };

  const verticalTotals = calculateVerticalTotals();

  const data = newData.map(item => item.forma_pago);

  const structureDataDescuadre = () => {
    const newData = data.map(formaPago => {
      const totalSistema = verticalTotals[formaPago] || 0;
      const montoRealObj = montosReales.find(item => item.formaPago === formaPago);
      const montoReal = montoRealObj ? parseInt(montoRealObj.montoReal) : totalSistema;

      return {
        formaPago,
        totalSistema,
        montoReal,
        coincide: montoReal == totalSistema ? '1' : '0',
        montoDescuadre : montoReal - totalSistema
      };
    });

    return newData;
  };

  const calcularTotalDescuadreEfectivo = () => {
    const totalEfectivoSistema = verticalTotals["EFECTIVO"] || 0
    const totalEfectivoReal =  montosReales.find(item => item.formaPago == "EFECTIVO")?.montoReal || verticalTotals["EFECTIVO"] || 0
    return totalEfectivoReal - totalEfectivoSistema
  }

  const calcularTotalDescuadres = () => {
    let descuadreTotal = 0;
    montosReales.map((itemMontoReal) => {
      const totalReal = itemMontoReal.montoReal || verticalTotals[itemMontoReal.formaPago]
      const totalSistema = verticalTotals[itemMontoReal.formaPago] || 0
      const descuadre = totalReal - totalSistema
      descuadreTotal += descuadre
    })
    return descuadreTotal
  }

  const handleOpenAjustes = () => {
    if(!openAjustes){
      setOpenDescuadre(false)
    }
    setOpenAjustes(!openAjustes)
  }

  const mantenedorDescuadre = (
    <ul style={{ listStyle: "none", padding: 0, margin: "10px" }}>
      {data.map((formaPago, index) => (
        <li key={index} style={{ display: "flex", paddingTop:2 ,justifyContent: "space-between", alignItems: "center" }}>
          <span style={{ width: "250px", fontSize: "14px" }}>{formaPago}</span>
          <span style={{ width: "250px", fontSize: "14px", textAlign: "right" }}>{moneyFormatInt(verticalTotals[formaPago] || 0)}</span>
          <span style={{ width: "250px", fontSize: "14px", textAlign: "right" }}>
            <TextField
              type="number"
              variant="outlined"
              size="small"
              value={montosReales.find(item => item.formaPago === formaPago)?.montoReal || verticalTotals[formaPago] || 0}
              onChange={(e) => handleInputChange(e.target.value, formaPago)}
              style={{ width: "100px" }}
            />
          </span>
        </li>
      ))}
    </ul>
  );
    

  return (
    <Drawer
      anchor={"right"}
      open={openDrawer}
      onClose={() => setOpenDrawer(false)}
    >
      <Box
        sx={{
          width: !openAjustes && !openDescuadre ? "550px" : "1000px",
          padding: "20px",
          paddingX: "40px",
          display: "flex",
          flexDirection: "row",
          borderRadius: "12px",
          backgroundColor: "#fff",
        }}
      >
        <Box
          sx={{
            width: "500px",
            padding: "20px",
            paddingX: "40px",
            display: "flex",
            flexDirection: "column",
            borderRadius: "12px",
            backgroundColor: "#fff",
          }}
        >
          <Typography variant="h4" sx={{ fontWeight: 700 }}>Cierre de Cuadratura</Typography>

          <Grid item xs={12} style={{ display: "flex", alignItems: "center", marginTop: "30px" }}>
            <span style={{ fontSize: "14px" }}><b>Monto inicial: </b> {moneyFormatInt(cuadratura?.monto_inicial)}</span>
          </Grid>
          {/* <Grid item xs={12} style={{ display: "flex", alignItems: "center", marginTop: "10px" }}>
            <span style={{ width: "250px", fontSize: "18px" }}><b>Total ventas:</b> </span>
            <span style={{ width: "200px", fontSize: "18px", textAlign: "right" }}>{moneyFormatInt(gananciaTotal)}</span>
          </Grid>
          <Grid item xs={12} style={{ display: "flex", alignItems: "center", marginTop: "10px" }}>
            <span style={{ width: "250px", fontSize: "18px" }}><b>Total descuadre:</b> </span>
            <span style={{ width: "200px", fontSize: "18px", textAlign: "right" }}>{moneyFormatInt(calcularTotalDescuadres())}</span>
          </Grid> */}
          <Grid item xs={12} style={{ display: "flex", alignItems: "center", marginTop: "10px" }}>
           <span style={{ width: "250px", fontSize: "14px" }}><b>Resumen de caja</b> </span>
           <span style={{ width: "250px", fontSize: "14px", textAlign: "right" }}><b>Sistema: </b> {moneyFormatInt(((cuadratura?.monto_inicial || 0) + gananciaTotal))} </span>
           <span style={{ width: "250px", fontSize: "14px", textAlign: "right"  }}><b>Cierre: </b>  {moneyFormatInt((cuadratura?.monto_inicial || 0) + gananciaTotal + calcularTotalDescuadres())}</span>
          </Grid>
          {mantenedorDescuadre}
          {/* <Grid item xs={12} style={{ display: "flex", alignItems: "center", marginTop: "10px" }}>
            <span style={{ width: "250px", fontSize: "18px" }}><b>Sistema:</b> </span>
            <span style={{ width: "200px", fontSize: "18px", textAlign: "right" }}>{moneyFormatInt(((cuadratura?.monto_inicial || 0) + gananciaTotal))}</span>
          </Grid>
          <Grid item xs={12} style={{ display: "flex", alignItems: "center", marginTop: "10px" }}>
            <span style={{ width: "250px", fontSize: "18px" }}><b>Cierre:</b> </span>
            <span style={{ width: "200px", fontSize: "18px", textAlign: "right" }}>{moneyFormatInt((cuadratura?.monto_inicial || 0) + gananciaTotal + calcularTotalDescuadres())}</span>
          </Grid> */}
          {/* <Grid item xs={12} style={{ display: "flex", alignItems: "center", marginTop: "10px" }}>
            <span style={{ width: "250px", fontSize: "18px" }}><b>Total ventas (EFECTIVO):</b> </span>
            <span style={{ width: "200px", fontSize: "18px", textAlign: "right" }}>{moneyFormatInt(verticalTotals["EFECTIVO"] || 0)}</span>
          </Grid> */}
          {/* <Grid item xs={12} style={{ display: "flex", alignItems: "center", marginTop: "10px" }}>
            <span style={{ width: "250px", fontSize: "18px" }}><b>Total ajuste (EFECTIVO):</b> </span>
            <span style={{ width: "200px", fontSize: "18px", textAlign: "right" }}>{moneyFormatInt(calcularTotalAjuste())}</span>
          </Grid> */}
          <Grid item xs={12} style={{ display: "flex", alignItems: "center", marginTop: "20px" }}>
            <span style={{ fontSize: "14px" }}><b>Descuadre efectivo: </b>{moneyFormatInt(calcularTotalDescuadreEfectivo())}</span>
          </Grid>
          <Grid item xs={12} style={{ display: "flex", alignItems: "center", marginTop: "20px" }}>
            <Button onClick={handleOpenAjustes} > {openAjustes ? "Ocultar movimientos" : "Añadir movimientos"} </Button>
          </Grid>
          <Grid item xs={12} style={{ display: "flex", alignItems: "center", marginTop: "20px" }}>
            <span style={{ fontSize: "14px" }}><b>Movimientos: </b>{moneyFormatInt(calcularTotalAjuste())}</span>
          </Grid>
          <Grid item xs={12} style={{ display: "flex", alignItems: "center", marginTop: "20px" }}>
            <span style={{ fontSize: "16px" }}><b>Efectivo final: </b>{moneyFormatInt((verticalTotals["EFECTIVO"] || 0) + calcularTotalAjuste() + cuadratura?.monto_inicial + calcularTotalDescuadreEfectivo())}</span>
          </Grid>

          <Button variant='contained' color={"error"} size="medium" 
          style={{ textTransform: "capitalize", marginTop: '30px' }}
            onClick={() => handleCloseCuadratura(calcularTotalAjuste(),
              (verticalTotals["EFECTIVO"] || 0) + calcularTotalAjuste() + cuadratura?.monto_inicial + calcularTotalDescuadreEfectivo(),
              movimientos,
              structureDataDescuadre(),
              (cuadratura?.monto_inicial || 0) + gananciaTotal + calcularTotalDescuadres(),
              calcularTotalDescuadres(),
              calcularTotalDescuadreEfectivo(),
              gananciaTotal,
              verticalTotals["EFECTIVO"] || 0
            )}>Cerrar cuadratura</Button>
          {/* <Grid item xs={12} style={{ display: "flex", alignItems: "center", marginTop: "10px" }}>
            <span style={{ width: "250px", fontSize: "18px" }}><b>Total descuadre (EFECTIVO):</b> </span>
            <span style={{ width: "200px", fontSize: "18px", textAlign: "right" }}>{moneyFormatInt(calcularTotalDescuadreEfectivo())}</span>
          </Grid> */}
          {/* <Grid item xs={12} style={{ display: "flex", alignItems: "center", marginTop: "10px" }}>
            <span style={{ width: "250px", fontSize: "18px" }}><b>Total cierre (EFECTIVO):</b> </span>
            <span style={{ width: "200px", fontSize: "18px", textAlign: "right" }}>{moneyFormatInt((verticalTotals["EFECTIVO"] || 0) + calcularTotalAjuste() + cuadratura?.monto_inicial + calcularTotalDescuadreEfectivo())}</span>
          </Grid> */}
          {/* <Grid >
            <Typography sx={{ mt: 4 }}>En caso de existir un descuadre, puedo marcarlos dando click en el siguiente botón</Typography>
            <Button onClick={handleOpenDescuadre}>{openDescuadre ? "Ocultar" : "Ver descuadre"}</Button>
          </Grid>
          <Grid>
            <Typography sx={{ mt: 2 }}>
              En caso de haber ingresado o retirado efectivo que no sea por concepto de
              venta, agregarlo en el siguiente mantenedor:
            </Typography>
            <Button onClick={handleOpenAjustes} > {openAjustes ? "Ocultar" : "Ajustar caja"} </Button>
          </Grid> */}

        </Box>
        {openDescuadre && 
       <></>}
        {
          openAjustes && <Box
            sx={{
              width: "500px",
              padding: "20px",
              paddingX: "40px",
              display: "flex",
              flexDirection: "column",
              borderRadius: "12px",
              backgroundColor: "#fff",
            }}
          >
            <>
              <Grid item sx={{ marginTop: '15px' }}>
                <span style={{ marginRight: "10px", width: '170px', fontSize:'14px' }}><b>{'Tipo de movimiento'}:</b></span>

                <FormControl fullWidth sx={{ mt: 2 }}>
                  <Select
                    value={tipoMovSelected}
                    onChange={(e) => setTipoMovSelected(e.target.value)}
                    displayEmpty
                    size="small"
                    inputProps={{ "aria-label": "Tipo de Movimiento" }}
                  >
                    <MenuItem value="" disabled>
                      Tipo de Movimiento
                    </MenuItem>
                    <MenuItem value="INGRESO">Ingreso</MenuItem>
                    <MenuItem value="EGRESO">Egreso</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <Grid item sx={{ marginTop: '10px' }}>
                <span style={{ marginRight: "10px", width: '170px', fontSize:'14px' }}><b>{tipoMovSelected == 'EGRESO' ? 'Banco destino' : 'Banco origen'}:</b></span>
                <Autocomplete
                  disablePortal
                  id="combo-box-demo"
                  value={bancoSelected}
                  options={optionsBanco || []}
                  getOptionLabel={(option: any) => option?.name || ""}
                  isOptionEqualToValue={(option, value) => option?.id === value?.id}
                  fullWidth
                  renderInput={(params) => <TextField {...params} label="" />}
                  onChange={(e, newValue) => {
                    setBancoSelected(newValue);
                  }}
                  size="small"
                />

              </Grid>
              <Grid item sx={{ marginTop: '10px' }}>
                <span style={{ marginRight: "10px", width: '170px', fontSize:'14px' }}><b>{'Monto'}:</b></span>
                <TextField
                  id="monto"
                  type="number"
                  name="monto"
                  fullWidth
                  size="small"
                  value={monto}
                  onChange={(e) => setMonto(e.target.value)}
                  placeholder="Monto"
                  sx={{ mt: 2 }}
                />
              </Grid>

              <Button onClick={agregarMovimiento} variant="contained"
                color={"info"} size="medium" style={{ textTransform: "capitalize", width: '200px', marginTop: '10px' }}
              >
                Agregar
              </Button>

              {/* Tabla de Movimientos */}
             { movimientos.length > 0 && <TableContainer sx={{ mt: 4 }}>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>Tipo</TableCell>
                      <TableCell>Banco</TableCell>
                      <TableCell>Monto</TableCell>
                      <TableCell>Acciones</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {movimientos.map((movimiento, index) => (
                      <TableRow key={index}>
                        <TableCell>{movimiento.tipoMovimiento}</TableCell>
                        <TableCell>{movimiento.banco?.name}</TableCell>
                        <TableCell>{moneyFormatInt(parseInt(movimiento.monto))}</TableCell>
                        <TableCell>
                          <IconButton
                            onClick={() => eliminarMovimiento(index)}
                          >
                            <DeleteIcon color="error"/>
                          </IconButton>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>}
            </>
          </Box>
        }
      </Box>


    </Drawer>
  );
};

