import { Autocomplete, Box, FormControl, FormControlLabel, FormLabel, Grid, IconButton, Input, InputLabel, MenuItem, Paper, Radio, RadioGroup, Select, Table, TableBody, TableCell, TableContainer, TableRow, TextField, Typography } from "@mui/material";
import Button from '@mui/material/Button';
import React, { useEffect, useState, useRef } from "react";
import { Props } from './Pagos.type'
import '../../../Caja/Productos/Productos'
import { Loading } from '@/components/common/Loading/Loading'
import { useLocalStorage } from '@toolbox/hooks/local-storage.hook';
import { readLocalStorage, removeLocalStorage, saveLocalStorage } from '@helpers/local-storage-helper';
import { TOKEN_API, RUC_EMPRESA, KEY_BODEGA_SELECT, KEY_EMPRESA, KEY_USER_DATA, KEY_APPLY_CUPONS, KEY_APPLY_BENEF_POINTS, KEY_CLIENT_POINTS } from "@/toolbox/constants/local-storage";
import { ID_TYPE_RESTAURANTE, ID_TYPE_RESTAURANTE_SOLO_ENTREGA } from '@constants/type-entity';
import { STATUS_PENDIENTE } from '@constants/status-ticket';
import InputAdornment from '@mui/material/InputAdornment';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert, { AlertProps } from '@mui/material/Alert';
import { editor } from "@/service/services/Editor.service";
import { ticketService } from "@/service/services/Ticket.service";
import { ventaService } from "@/service/services/Venta.service";
import { Popup } from '../../../Editor/components/Popup/Popup';
import { AddCliente } from '../../../Clientes/componentes/AddCliente';
import { styled } from '@mui/material/styles';
import { KEY_EDIT_PEDIDO } from '@constants/local-storage';
import { salas } from '../../../../service/services/Salas.service';
import { clientesService } from "@/service/services/Clientes.service";
import { useReactToPrint } from 'react-to-print';
import { useLocation } from "react-router-dom"
import { STATUS_FINALIZADO, STATUS_POR_ENTREGAR } from '@constants/status-ticket';
import "./Pagos.sass";
import { ComponentPrint } from "@/components/common/ComponentPrint";
import { ComponentPrintTicket } from "@/components/common/ComponentPrintTicket";
import { ComponentPrintFactura } from "@/components/common/ComponentFactura";
import { listaPrecioService } from '@/service/services/ListaPrecio.service';
import moment from "moment";
import { CuponesService } from "@/service/services/Cupones.service";
import { ComponentCuponTicket } from "@/components/common/ComponentCuponTicket";
import { validateCupon } from "@/toolbox/helpers/validateCoupons";
import { verifyCuponesApplyPrice, verifyCuponApplyPrice } from "@/toolbox/helpers/verifyCuponApplyPrice";
import { verifyBenefitPrice, verifyBenefitsPrice } from "@/toolbox/helpers/verifyBenefitsPointsPrice";
import { ComponentComanda } from "@/components/common/ComponentComanda";
import { productoService } from "@/service/services/Producto.service";
import CloseIcon from '@mui/icons-material/Close';
import TableRestaurantIcon from '@mui/icons-material/TableRestaurant';
import RestaurantIcon from '@mui/icons-material/Restaurant';
import { ROLE_ADMIN, ROLE_CAJERO, ROLE_VENDEDOR } from "@/toolbox/defaults/static-roles";
import { PopupComponent } from "@/components/common/Popup";
import { PagarPedido } from "@/views/Caja/Pagar";
import { PagarHome } from "./PagarHome";
import { cuadraturaService } from "@/service/services/Cuadratura.service";
import { userService } from "@/service/services/User.service";
import PersonAddAltIcon from '@mui/icons-material/PersonAddAlt';
import { moneyFormat, moneyFormatInt } from "@/toolbox/helpers/money.helper";
import { ComandaService } from "@/service/services/Comanda.service";

const Alert = React.forwardRef<HTMLDivElement, AlertProps>(function Alert(props, ref) {
   return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});
const TipoPago = [{ id: 1, nombre: 'Cash' }];
const StyledButton = styled(Button)(({ theme }) => ({
   textTransform: 'none',
}));

export const Pagos: React.FC<Props> = (props: Props): JSX.Element => {
   const { search } = useLocation();
   const userData = readLocalStorage(KEY_USER_DATA)
   const [dataImprimir, setDataImprimir] = useState(null);
   // eslint-disable-next-line
   const [dataImprimirTicket, setDataImprimirTicket] = useState(null);
   const [dataImprimirFactura, setDataImprimirFactura] = useState(null);
   const [dataImprimirComanda, setDataImprimirComanda] = useState(null);
   const componentRef = useRef();
   const { productsCard, handleLoadPago, openPopup, estadoCuadratura } = props;
   const [openPopupPedido, setOpenPopupPedido] = useState<boolean>(false);
   const [openSnack, setOpenSnack] = useState<boolean>(false);
   const [openSnackError, setOpenSnackError] = useState<boolean>(false);
   const [totalCard, setTotalCard] = useState<number>(0);
   const [isLoaded, setIsLoaded] = useState(false);
   const [ruc_empresa] = useLocalStorage(RUC_EMPRESA, "");
   const [token] = useLocalStorage(TOKEN_API, "");
   const [dataEmpresa] = useLocalStorage(KEY_EMPRESA, {
      id: 0, id_tipo_empresa: 0, equivalencia_puntos: 0,
      id_country: 0, country_name: "", country_abbreviation: "", mesas: 0,
   });
   const [pagos, setPagos] = useState<Array<any>>(TipoPago);
   const [pagosSelect, setPagoSelect] = useState<number>(0);
   const [namepagosSelect, setNamePagoSelect] = useState<string>('1234567');
   const [mesaSelect, setMesaSelect] = useState<any>("");
   const [arrayIdsProducto, setArrayIdsProducto] = useState<any>([]);
   const [editPedido] = useLocalStorage(KEY_EDIT_PEDIDO, null);
   const [salasE, setSalas] = useState<Array<any>>([]);
   const [mesas, setMesas] = useState<Array<any>>([]);
   const [clientes, setClientes] = useState<Array<any>>([]);
   const [clienteSelect, setClienteSelect] = useState<any>("");
   // const [beneficioProducto] = useLocalStorage(KEY_BENEFICIO_PRO, []);
   // const [beneficioMonto] = useLocalStorage(KEY_BENEFICIO_MON, []);
   const [descuentoMonto, setDescuentoMonto] = useState<number>(0);
   const [descuentoProductos, setDescuentoProductos] = useState<number>(0);
   const [openPopupClient, setOpenPopupClient] = React.useState<boolean>(false);// eslint-disable-next-line
   const [showPDF, setShowPDF] = useState({
      open: false, timbre: '', montoNeto: 0, folio: '', idVenta: 0
   });
   const [codigoTransbank, setCodigoTransbank] = useState<any>('');
   const [errorTransB, setErrorTranB] = useState<boolean>(false);
   const [snackBarConfig, setSnackBarConfig] = useState<any>({
      open: false,
      severity: 'error',
      message: 'Error',
      autoHideDuration: 3000,
   });
   const [keysClient, setKeysClient] = useState<any>(null)
   const [listaPrecio, setListaPrecio] = React.useState([]);
   const [cupones, setCupones] = React.useState([]);
   const [tipoCupon, secTipoCupon] = React.useState([]);
   // const [Currentcupon, setCurrentCupon] = React.useState({});
   const dataUsuario = readLocalStorage(KEY_USER_DATA);
   const id_usuario = dataUsuario.user.id_usuario;
   const role = dataUsuario.user.rol;
   const nombreEmpresa = dataUsuario.user?.nombre_empresa;
   const rutEmpresa = dataUsuario.user?.rut_empresa
   const [ImprimirCupon, setImprimirCupon] = React.useState<boolean>(false);
   const [allFamilias, setAllFamilias] = React.useState([]);
   const [areaNegocio, setAreaNegocio] = useState<any>({});
   const [notaVenta, setNotaVenta] = useState<boolean>(false);
   const [folioNotaVenta, setFolioNotaVenta] = useState<any>('');
   const [rutCliente, setRutCliente] = useState<any>('');
   const [showSnackBar, setShowSnackBar] = useState<boolean>(false)
   const [showModalSalas, setShowModalSalas] = useState<boolean>(false)
   const [selectedSala, setSelectedSala] = useState<any>(null)
   const [selectedMesa, setSelectedMesa] = useState<any>(null)
   const [optionSale, setOptionSale] = useState<number>(1)

   //cupone saplicados
   const cuponesApply = readLocalStorage(KEY_APPLY_CUPONS);
   //beneficios por puntos
   const benefitPoints = readLocalStorage(KEY_APPLY_BENEF_POINTS);
   //cliente con puntos
   const clientPoints = readLocalStorage(KEY_CLIENT_POINTS);

   const handlePrint = useReactToPrint({
      content: () => componentRef.current,

   });

   const [loadData, setLoadData] = React.useState<boolean>(false);
   const [filterPedidos, setFilterPedidos] = useState<Array<any>>([]);
   const [pedidos, setPedidos] = React.useState<Array<any>>([]);
   const [filterFinalizados, setFilterFinalizados] = useState<Array<any>>([]);
   const [finalizados, setFinalizados] = React.useState<Array<any>>([]);
   const [pedidoSelected, setPedidoSelect] = React.useState<number>(0);
   const [idPedidoPagar, setIdPedidoPagar] = React.useState<number>(0);

   const [openModalClient, setOpenModalClient] = useState<boolean>(false);

   const fechaActual = new Date();
   const año = fechaActual.getFullYear();
   const mes = String(fechaActual.getMonth() + 1).padStart(2, '0');
   const dia = String(fechaActual.getDate()).padStart(2, '0');
   const fechaFormateada = `${año}-${mes}-${dia}`;

   const handleSubmit = () => { }

   useEffect(() => {
      // getProductosListaPrecio();
      // getCupones();
      const objetKeys = localStorage.getItem('keysClient');
      const keys = JSON.parse(objetKeys);
      if (keys) {
         setKeysClient(keys);
      }

      salas.cargarSalas().then((data) => {
         setSalas(data.salas || [])
      })

      salas.cargarAllMesas().then((data) => {
         setMesas(data.mesas || [])
      })

   },// eslint-disable-next-line
      [])

   useEffect(() => {
      if (mesas.length > 0) {
         const sala = salasE.find(sala => sala.id === mesas[0].id_sala)
         setSelectedSala(sala)
      }
   }, [mesas, salasE])

   const getProductosListaPrecio = async () => {
      await listaPrecioService.getListaPrecioByUsuario(dataUsuario.user.id_usuario).then((data) => {
         if (data.status === 200) {
            setListaPrecio(data.data);
         }
      });
   }

   const getCupones = async () => {
      await CuponesService.couponsAvailable().then((data) => {
         if (data.status === 200) {
            setCupones(data.cupones);
         }
      });
   }

   const handleSelectMesa = (mesa) => {
      setMesaSelect(mesa.id || "")
      setSelectedMesa(mesa)
      setShowModalSalas(false)
      setSnackBarConfig(prev => ({
         ...prev,
         open: true,
         severity: 'success',
         message: `Mesa seleccionada: ${mesa.nombre}`,
      }));
   }

   useEffect(() => {
      validateCupones();
      // eslint-disable-next-line
   }, [productsCard])

   const validateCupones = () => {
      validateCupon(cupones, productsCard, secTipoCupon, allFamilias);
   }

   const validatePrice = (product) => {
      let existList = (listaPrecio || []).find(p => p.codigo_erp === product.codigo_erp);
      if (existList) {
         return existList?.precio_ajustado || 0;
      }
      const dateToday = moment();
      const value = product.caducidad_precio_oferta;
      const expirationPrice = moment(value, 'DD-MM-YYYY');

      const standarPrice = product.precio_standar || product.precio;
      const offerPrice = product.precio_oferta;

      if (expirationPrice !== null && offerPrice !== null) {
         if (expirationPrice.isSameOrAfter(dateToday)) {
            return offerPrice;
         } else {
            return standarPrice;
         }
      } else {
         return standarPrice;
      }
   }

   useEffect(() => {
      let total: number = 0;
      let descuento: number = 0;
      for (let i = 0; i < productsCard.length; i++) {
         if (!productsCard[i]?.cantidad) {
            if (productsCard[i].precio) {
               if (productsCard[i].precio_oferta) {
                  total = (total + Number(validatePrice(productsCard[i])))
               } else {
                  total = (total + Number(productsCard[i].precio))
               }
            } else {
               /* if(productsCard[i].precio_oferta){
                  total = (total + Number(validatePrice(productsCard[i])))
               }else{
                  total = (total + Number(productsCard[i].precio_standar))
               } */
               total = (total + Number(validatePrice(productsCard[i])))
            }
         } else {
            if (productsCard[i].precio) {
               if (productsCard[i].precio_oferta) {
                  if (productsCard[i].id_unidad_medida) {
                     total = (total + ((Number(validatePrice(productsCard[i])) * Number(productsCard[i].cantidad)) / Number(productsCard[i].cantidad_peso)))
                  } else {
                     total = (total + (Number(validatePrice(productsCard[i])) * productsCard[i].cantidad))
                  }
               } else {
                  if (productsCard[i].id_unidad_medida) {
                     total = (total + ((Number(productsCard[i].precio) * productsCard[i].cantidad) / Number(productsCard[i].cantidad_peso)))
                  } else {
                     total = (total + (Number(productsCard[i].precio) * productsCard[i].cantidad))
                  }
               }
            } else {
               /* if(productsCard[i].precio_oferta){
                  if (productsCard[i].id_unidad_medida) {
                     total = (total + ((Number(validatePrice(productsCard[i]))*productsCard[i].cantidad) / Number(productsCard[i].cantidad_peso)))
                  } else {
                     total = (total + (Number(validatePrice(productsCard[i]))*productsCard[i].cantidad))
                  }
               }else{
                  if (productsCard[i].id_unidad_medida) {
                     total = (total + ((Number(productsCard[i].precio_standar)*productsCard[i].cantidad) / Number(productsCard[i].cantidad_peso)))
                  } else {
                     total = (total + (Number(productsCard[i].precio_standar)*productsCard[i].cantidad))
                  }
               } */
               if (productsCard[i].id_unidad_medida) {
                  total = (total + ((Number(validatePrice(productsCard[i])) * productsCard[i].cantidad) / Number(productsCard[i].cantidad_peso)))
               } else {
                  total = (total + (Number(validatePrice(productsCard[i])) * productsCard[i].cantidad))
               }
            }
         }
         //si tiene beneficio se comenta de momento su aplicacion directa
         // if (productsCard[i].id) {
         //    let result = beneficioProducto.find(prod => prod.id_producto === productsCard[i].id);
         //    if ( result ) {
         //       let cantidadProd = productsCard[i]?.cantidad || 1;
         //       if (cantidadProd >= result.cantidad_productos) {
         //          let multiplo = cantidadProd / result.cantidad_productos
         //          let descuentoProd = (result.cantidad_puntos * dataEmpresa.equivalencia_puntos) * Math.trunc(multiplo)
         //          descuento += descuentoProd;
         //          // descuento += result.cantidad_puntos;
         //       }
         //    }
         // }
      }

      //obtener descuento monto
      // eslint-disable-next-line
      let mayorMonto = 0;
      let puntos = 0;
      //se comenta de momento su palicacion directa del beneficoi en la venta
      // for (let i = 0; i < beneficioMonto.length; i++) {
      //    const beneficio = beneficioMonto[i];
      //    if ((total-descuento) >= beneficio.monto) {
      //       if (mayorMonto < beneficio.monto) {
      //          mayorMonto = beneficio.monto
      //          puntos = beneficio.cantidad_puntos
      //       }
      //    }
      // }
      setDescuentoMonto(puntos * dataEmpresa.equivalencia_puntos);
      setDescuentoProductos(descuento)

      setTotalCard(total - (descuento + (puntos * dataEmpresa.equivalencia_puntos)))
   },// eslint-disable-next-line
      [productsCard])

   useEffect(() => {
      if (clientPoints) {
         setClienteSelect({
            "key": clientPoints?.id,
            "label": clientPoints?.nombre,
            "type": clientPoints?.id_tipoPersona,
         })
      }
   }, // eslint-disable-next-line
      [!openPopup]);

   useEffect(() => {
      if (dataUsuario && role !== 'Cliente') {
         getClientes();
      }
      if (editPedido?.ticket?.pedidoDetail?.cliente) {
         setClienteSelect({
            "key": editPedido?.ticket?.pedidoDetail?.cliente?.id,
            "label": editPedido?.ticket?.pedidoDetail?.cliente?.nombre,
            "type": editPedido?.ticket?.pedidoDetail?.cliente?.id_tipoPersona || 0,
         })
      }
      if (editPedido?.ticket?.pedidoDetail?.mesa) {
         setMesaSelect(editPedido?.ticket?.pedidoDetail?.mesa?.id)
      }
   }, // eslint-disable-next-line
      []);

   useEffect(() => {
      if (dataImprimir !== null) {
         handlePrint();
      }
      // eslint-disable-next-line
   }, [dataImprimir])

   useEffect(() => {
      if (dataImprimirTicket !== null) {
         handlePrint();
      }
      // eslint-disable-next-line
   }, [dataImprimirTicket])

   useEffect(() => {
      if (dataImprimirFactura !== null) {
         handlePrint();
      }// eslint-disable-next-line
   }, [dataImprimirFactura])

   useEffect(() => {
      if (dataImprimirComanda !== null) {
         handlePrint();
      }
      // eslint-disable-next-line
   }, [dataImprimirComanda])

   useEffect(() => {
      if (ImprimirCupon !== null) {
         handlePrint();
      }
      // eslint-disable-next-line
   }, [ImprimirCupon])

   const handleChangeRadio = (event) => {
      setOptionSale(event.target.value);
   }

   const getClientes = async () => {
      //1: persona natural
      //2: persona juridica
      await clientesService.getClientes().then((data) => {
         setClientes(data.cliente.map((el) => {
            return { label: el.nombre, key: el.id, type: el.id_tipoPersona, rut: el.rut, direccion: el.direccion, comuna: el.comuna, ciudad: el.ciudad, giro: el.giro, doc_iden: el.doc_iden };
         }));
         const clientFind = data.cliente.find(item => item.nombre == 'GENERICO')
         if (!!clientFind) {
            setClienteSelect({
               label: clientFind.nombre, key: clientFind.id, type: clientFind.id_tipoPersona, rut: clientFind.rut, direccion: clientFind.direccion, comuna: clientFind.comuna, ciudad: clientFind.ciudad, giro: clientFind.giro, doc_iden: clientFind.doc_iden
            })
         }
      })
   }

   async function tipoBoletaSoftnet(token: any) {
      const res = await ventaService.tipoBoletaSoftnet(token);
      return res;
   }

   const handleGenerateDocumento = async (TipoDocumento) => {
      const pais = dataEmpresa.country_abbreviation;
      const formats = {
         codigoPeru: /^\d{6}$/,
         codigoChile: /^\d{6}$/,
         codigoColombia: /^\d{6}$/,
      }
      let error: any;
      if (pais === 'CLP' && !formats.codigoChile.test(codigoTransbank)) error = true;
      if (pais === 'PEN' && formats.codigoPeru.test(codigoTransbank)) error = true;
      if (pais === 'COP' && formats.codigoColombia.test(codigoTransbank)) error = true;

      if ((dataEmpresa.id_tipo_empresa !== 2 && pagosSelect === 2677 && codigoTransbank === "") ||
         (dataEmpresa.id_tipo_empresa !== 2 && pagosSelect === 2677 && error === true)) {
         setErrorTranB(true);
         setSnackBarConfig(prev => ({
            ...prev,
            open: true,
            message: `código de transbank inválido`,
         }));
         setIsLoaded(false);
      } else {
         if (productsCard.length === 0) {
            setSnackBarConfig(prev => ({
               ...prev,
               open: true,
               severity: 'warning',
               message: `Agregar almenos un producto al carrito`,
            }));
            setIsLoaded(false);
         } else {
            // eslint-disable-next-line
            if (notaVenta && rutCliente == "") {
               return setSnackBarConfig(prev => ({
                  ...prev,
                  open: true,
                  message: `Seleccione un cliente`,
               }));
            }
            // eslint-disable-next-line
            if (notaVenta && folioNotaVenta == '') {
               return setSnackBarConfig(prev => ({
                  ...prev,
                  open: true,
                  message: `el campo Folio es requerido`,
               }));
            }

            arrayIdsProducto.length = 0;
            setArrayIdsProducto(arrayIdsProducto);
            setIsLoaded(true);

            let confProducto: any
            confProducto = await tipoBoletaSoftnet(token);
            let tipoBoleta = '';
            // eslint-disable-next-line
            confProducto?.conf.map((item) => {
               if (item.tipo_doc === '39') {
                  tipoBoleta = item.tipo;
               }
            })

            const bodega = readLocalStorage(KEY_BODEGA_SELECT);
            let copyProducts: Array<any> = []
            let monto_neto_calculado = 0;
            for (let i = 0; i < productsCard.length; i++) {
               let precio;
               let codigo;
               if (productsCard[i].precio_oferta) {
                  // precio = productsCard[i].precio_oferta;
                  precio = validatePrice(productsCard[i]);
               } else {
                  if (productsCard[i].precio) {
                     precio = productsCard[i].precio;
                  } else {
                     // precio = productsCard[i].precio_standar;
                     precio = validatePrice(productsCard[i]);
                  }
               }

               if (productsCard[i].precio) {
                  codigo = productsCard[i].codigo;
               } else {
                  codigo = productsCard[i].codigo_erp;
               }

               let monto_neto_producto1 = 0;
               let filtro_cantidad = 0;
               if (productsCard[i].id_unidad_medida) {
                  monto_neto_producto1 = tipoBoleta === 'N' ? Math.ceil((precio / productsCard[i].cantidad_peso) / 1.19) : precio;
                  if (productsCard[i].id_unidad_medida !== 1) {
                     filtro_cantidad = Math.ceil(productsCard[i].cantidad);
                  } else {
                     filtro_cantidad = productsCard[i].cantidad;
                  }
               } else {
                  monto_neto_producto1 = tipoBoleta === 'N' ? Math.ceil(precio / 1.19) : precio;
                  filtro_cantidad = productsCard[i].cantidad;
               }
               copyProducts.push({
                  "Afecto": tipoBoleta === 'N' ? true : false,
                  "Descuento": 0,
                  // "Descuento": descuento,
                  "Bodega": bodega,
                  "Cantidad": filtro_cantidad,
                  "Codigo": codigo,
                  "Precio": monto_neto_producto1,
               })
               if (productsCard[i].id_unidad_medida && productsCard[i].id_unidad_medida !== 1) {
                  monto_neto_calculado = monto_neto_calculado + monto_neto_producto1 * filtro_cantidad;
               } else {
                  monto_neto_calculado = monto_neto_calculado + monto_neto_producto1 * productsCard[i].cantidad;
               }

               const data: any = readLocalStorage(KEY_EMPRESA);
               const id_empresa = data.id;

               if (productsCard[i].precio) {
                  let body_form_data = new FormData()
                  body_form_data.append("codigo_erp", productsCard[i].codigo)
                  body_form_data.append("nombre", productsCard[i].nombre)
                  body_form_data.append("precio_standar", productsCard[i].precio)
                  body_form_data.append("precio_oferta", null)
                  body_form_data.append("id_tipo_producto", "1")
                  body_form_data.append("id_empresa", id_empresa)
                  body_form_data.append("stock", productsCard[i].stock)
                  body_form_data.append("id_unidad_medida", productsCard[i].unidad_medida_id || '1')
                  body_form_data.append("familia_id", productsCard[i].familia_id || 0)
                  body_form_data.append("sub_familia_id", productsCard[i].sub_familia_id || 0)
                  body_form_data.append("marca_id", productsCard[i].marca_id || 0)
                  body_form_data.append("linea_id", productsCard[i].linea_id || 0)
                  await editor.postProductBoleta(body_form_data).then((data) => {
                     if (data.id_producto[0]) {
                        arrayIdsProducto.push(data.id_producto[0].id);
                        setArrayIdsProducto(arrayIdsProducto);
                     } else {
                        arrayIdsProducto.push(data.id_producto.id);
                        setArrayIdsProducto(arrayIdsProducto);
                     }
                  })

               } else {
                  arrayIdsProducto.push(productsCard[i].id);
                  setArrayIdsProducto(arrayIdsProducto);
               }
            }

            (tipoCupon && tipoCupon.length > 0) && setImprimirCupon(true);

            let descuentoTotal = 0;
            if (descuentoMonto > 0) {
               descuentoTotal = descuentoMonto;
            }
            if (descuentoProductos > 0) {
               descuentoTotal = descuentoProductos
            }
            if (descuentoMonto > 0 && descuentoProductos > 0) {
               descuentoTotal = descuentoMonto + descuentoProductos;
            }

            let puntos = 0;
            if (benefitPoints && benefitPoints.length > 0) {
               // eslint-disable-next-line
               benefitPoints.map((benefit) => {
                  puntos = puntos + benefit.puntos;
               })
            }

            let descuentoBeneficios = 0;
            let descuentoCupones = verifyCuponesApplyPrice(cuponesApply, productsCard);
            let descuentoPuntos = verifyBenefitsPrice(benefitPoints, productsCard);

            if (descuentoCupones > 0) descuentoBeneficios = descuentoBeneficios + descuentoCupones;
            if (descuentoPuntos > 0) descuentoBeneficios = descuentoPuntos + descuentoBeneficios;

            if (descuentoBeneficios > 0) {
               descuentoTotal = descuentoTotal + descuentoBeneficios;
            }

            let sendDataBoleta = [{
               "Encabezado": {
                  "Receptor": '66666666-6',
                  "MontoNeto": monto_neto_calculado,
                  "Descuento": descuentoTotal,
                  "TipoDocumento": '39',
                  "AreaNegocio": areaNegocio ? areaNegocio.id : "3831",
               },
               "Detalle": copyProducts,
               "cliente": 'cliente',
               "Adicional": {
                  "Uno": pagosSelect,
                  "Dos": ruc_empresa,
               }
            }];

            let sendNotaVenta = [{
               "Encabezado": {
                  "Receptor": rutCliente ? rutCliente : '66666666-6',
                  "MontoNeto": monto_neto_calculado,
                  "MontoExento": "0",
                  "Descuento": descuentoTotal || 0,
                  "TipoDocumento": '780',
                  "Folio": folioNotaVenta ? folioNotaVenta : "324",
                  "AreaNegocio": areaNegocio ? areaNegocio.id : "3831",
                  "CondicionPago": pagosSelect,
                  "Vendedor": '16887941-5',
                  "Observacion": "observacion",
                  "Direccion": "123"
               },
               "Detalle": copyProducts,
               "Adicional": {
                  "Uno": pagosSelect,
                  "Dos": ruc_empresa,
               }
            }];

            let sendDataFactura = [{
               "Encabezado": {
                  "Receptor": "66666666-6",
                  "MontoNeto": 100,
                  "MontoExento": 0,
                  "Descuento": 0,
                  "TipoDocumento": 33,
                  "AreaNegocio": areaNegocio ? areaNegocio.id : "4"
               },
               "Detalle": [{
                  "Afecto": true,
                  "Descuento": 0,
                  "Bodega": 85,
                  "Cantidad": 1,
                  "Codigo": "1",
                  "Precio": 100
               }],
               "Adicional": {
                  "Uno": 5357,
                  "Dos": "11111111-1",
                  "Treinta": "123"
               }
            }];

            const token_api_softnet = '14af3bd557763fc7733730f8e0c84240b92082e5fe8710d39186b3a9c60114cbb2c0939d7b21e02f7d62c8721593b0982154f4e975ad5707a12e21cb70246953'
            let documentoGenerado;
            if (TipoDocumento === 'Boleta') {
               documentoGenerado = await ventaService.enviarBoleta(token, sendDataBoleta);
            }
            if (TipoDocumento === 'Factura') {
               //const boletagenerada = await ventaService.enviarFactura(token, sendDataFactura);
               documentoGenerado = await ventaService.enviarFactura(token_api_softnet, sendDataFactura)
            }
            if (TipoDocumento === 'notaVenta') {
               documentoGenerado = await ventaService.enviarNotaVenta(token, sendNotaVenta);
            }

            if (!documentoGenerado[0]) {
               setSnackBarConfig(prev => ({
                  ...prev,
                  open: true,
                  message: 'Los datos a procesar son inválidos',
               }));
               setIsLoaded(false);
            } else {
               if (!notaVenta && (documentoGenerado[0].mensaje !== 'Item guardado con exito.')) {
                  setSnackBarConfig(prev => ({
                     ...prev,
                     open: true,
                     message: `${documentoGenerado[0].mensaje}`,
                  }));
                  setIsLoaded(false);
               } else if (notaVenta && (documentoGenerado[0].mensaje !== "Documento generado con exito.")) {
                  setSnackBarConfig(prev => ({
                     ...prev,
                     open: true,
                     message: `${documentoGenerado[0].mensaje}`,
                  }));
                  setIsLoaded(false);
               } else {
                  let ventaID = 0;
                  if (dataEmpresa.id_tipo_empresa === ID_TYPE_RESTAURANTE_SOLO_ENTREGA) {
                     ventaID = await ventaService.generarBoletaVentaDirect(mesaSelect, clienteSelect.key || null, (descuentoBeneficios > 0 ? (totalCard - descuentoBeneficios) : totalCard), (descuentoBeneficios ? (descuentoMonto + descuentoBeneficios) : descuentoMonto), descuentoProductos, productsCard, (codigoTransbank || null),
                        namepagosSelect, documentoGenerado[0].folio, 1, bodega, arrayIdsProducto, TipoDocumento === 'Boleta' ? documentoGenerado[0].urlTimbre : documentoGenerado[0].timbre || "", documentoGenerado[0].pdf || "", monto_neto_calculado,
                        STATUS_POR_ENTREGAR, puntos
                     )
                  } else {
                     ventaID = await ventaService.generarBoletaVentaDirect(mesaSelect, clienteSelect.key || null, (descuentoBeneficios > 0 ? (totalCard - descuentoBeneficios) : totalCard), (descuentoBeneficios > 0 ? (descuentoMonto + descuentoBeneficios) : descuentoMonto), descuentoProductos, productsCard, (codigoTransbank || null),
                        namepagosSelect, documentoGenerado[0].folio, 1, bodega, arrayIdsProducto, TipoDocumento === 'Boleta' ? documentoGenerado[0].urlTimbre : documentoGenerado[0].timbre || "", documentoGenerado[0].pdf || "", monto_neto_calculado,
                        STATUS_FINALIZADO, puntos
                     )
                  }
                  setShowPDF({
                     idVenta: ventaID,
                     montoNeto: monto_neto_calculado,
                     timbre: documentoGenerado[0].urlTimbre || "",
                     folio: documentoGenerado[0].folio,
                     open: true
                  })

                  ventaService.getVentaDetalle(ventaID).then((res) => {
                     if (res.status === 200) {
                        let arrayProducts = [];
                        // eslint-disable-next-line
                        (res.tickets).map((item) => {
                           for (let i = 0; i < item.detalle_ticket.length; i++) {
                              const productos = item.detalle_ticket[i].productos;
                              arrayProducts.push({
                                 "cantidad": item.detalle_ticket[i].cantidad,
                                 "precio_unitario": item.detalle_ticket[i].precio_unitario || "",
                                 "id_producto": item.detalle_ticket[i].id_producto,
                                 "codigo_erp": productos?.codigo_erp,
                                 "nombre": productos?.nombre,
                                 "id_tipo_producto": productos?.id_tipo_producto,
                                 // "precio_standar": productos?.precio_standar,
                                 "precio_standar": productos && validatePrice(productos),
                                 // "precio_oferta": productos?.precio_oferta,
                                 "precio_oferta": productos && validatePrice(productos),
                                 "cantidad_peso": productos?.cantidad_peso,
                                 "unidad_medida": item?.detalle_ticket[i]?.productos?.unidad_medida
                              });
                           }
                        });

                        const venta = {
                           detalle: arrayProducts,
                           ventaDetalle: res.venta,
                           folio: documentoGenerado[0].folio,
                           timbre: TipoDocumento === ('Boleta' ? documentoGenerado[0].urlTimbre : documentoGenerado[0].timbre) || "",
                           montoNeto: monto_neto_calculado,
                        }
                        saveLocalStorage(KEY_APPLY_CUPONS, []);
                        saveLocalStorage(KEY_APPLY_BENEF_POINTS, []);
                        removeLocalStorage(KEY_CLIENT_POINTS);
                        if (TipoDocumento === 'Boleta') {
                           setDataImprimir(venta)
                        }
                        if (TipoDocumento === 'Factura') {
                           setDataImprimirFactura(venta)
                        }
                        if (TipoDocumento === 'notaVenta') {
                           setDataImprimir(venta);
                           setSnackBarConfig(prev => ({
                              ...prev,
                              open: true,
                              severity: 'success',
                              message: `Nota de venta creada con éxito.`,
                           }));
                        }
                     }
                  })

               }
            }
            setClienteSelect('');
            setMesaSelect('');
            setCodigoTransbank('')
            setIsLoaded(false);
            setTotalCard(0);
            secTipoCupon([]);
            setNotaVenta(false);
            // setCurrentCupon({});
            handleLoadPago && handleLoadPago();
         }
      }
   }

   // eslint-disable-next-line
   const probarVisualFactura = () => {
      const venta = {
         "detalle": [
            {
               "cantidad": "1",
               "id_producto": "2",
               "codigo_erp": "0001",
               "nombre": "papas",
               "id_tipo_producto": 1,
               "precio_standar": 32,
               "precio_oferta": null,
               "cantidad_peso": 1
            }
         ],
         "ventaDetalle": {
            "id": 1,
            "folio": 42005,
            "timbre_softnet": "https://s3.amazonaws.com/files.dtestorage.com/bmp_re76017114_k_t33_f42009.bmp?AWSAccessKeyId=AKIAJNOZQJM5SRFSWSBQ&Expires=1655421436&Signature=Wj%2B5xj1umTpPafmqR1atDdqINOM%3D",
            "pdf_softnet": "https://s3.amazonaws.com/files.dtestorage.com/pdf_re76017114_k_t33_f42005_tirdl1.pdf?AWSAccessKeyId=AKIAJNOZQJM5SRFSWSBQ&Expires=1655361805&Signature=hsaQSHCrPpfQzUFCxV1VY%2FPRWMk%3D",
            "monto_neto": "0",
            "cantidad_productos": 1,
            "monto_total": 0,
            "monto_pagado": 0,
            "descuento_x_puntos": 0,
            "codigo_transbank": null,
            "fecha": "15-06-2022",
            "id_pedido": 2,
            "tipo_doc": 1,
            "sucursal": 1,
            "propina": null,
            "state": null,
            "forma_pago": "1234567",
            "created_at": "2022-06-16T03:43:31.000000Z",
            "updated_at": "2022-06-16T03:43:31.000000Z",
            "pedido": {
               "id": 2,
               "id_ticket": "2",
               "id_mesa": null,
               "id_cliente": null,
               "estado": "finalizado",
               "tipo": "boleta",
               "monto_total": 0,
               "descuento_monto_total": 0,
               "descuento_productos_total": 0,
               "id_user": 2,
               "created_at": "2022-06-16T03:43:30.000000Z",
               "updated_at": "2022-06-16T03:43:30.000000Z",
               "mesa": null,
               "cliente": {
                  'ciudad': "huacho",
                  'comuna': "avenida",
                  'created_at': "2022-07-08T18:44:10.000000Z",
                  'direccion': "sevilla #196",
                  'giro': "Solucion de problemas informatico",
                  'id': 3,
                  'id_empresa': 1,
                  'id_tipoPersona': 2,
                  'nombre': "Pierre Carreño",
                  'nombre_rz': "PCdev Solution",
                  'observacion': null,
                  'puntos': 0,
                  'rut': "63534356-k",
                  'state': 1,
                  'unidad_sii': "VALPARAISO",
                  'updated_at': "2022-07-08T18:46:47.000000Z",
               },
               "usuario": {
                  "id": 2,
                  "nombre": "Demo ERP - BODEGA",
                  "rut": "15134572-5",
                  "rut_empresa": "22222222-2",
                  "usuario": "usuarioB",
                  "email": "desarrollo@softnet.cl",
                  "password": "$2y$10$zvWnOU4xeOTkbfki/cETCO1/SKgqEICcL6VFLPFJvNcE6D39.oxze",
                  "state": 1,
                  "id_sucursal": 1,
                  "rol": 2,
                  "created_at": "2022-06-16T02:13:33.000000Z",
                  "updated_at": "2022-06-16T02:13:33.000000Z",
                  "sucursales": {
                     "id": 1,
                     "nombre": "Demo bodega",
                     "state": 1,
                     "direccion": "lima",
                     "id_empresa": 1,
                     "created_at": "2022-06-16T02:13:33.000000Z",
                     "updated_at": "2022-06-16T02:13:33.000000Z",
                     "empresa": {
                        'ciudad': "VALPARAISO",
                        'comuna': "VALPARAISO",
                        'created_at': "2022-07-08T16:56:58.000000Z",
                        'direccion': "YUNGAY 1942",
                        'equivalencia_puntos': 1,
                        'formas_de_pago': "efectivo",
                        'giro1': "VENTA AL POR MENOS DE PRODUCTOS EN COMERCIOS ESPECIALIZADOS",
                        'giro2': "VENTAS AL POR MENOR DE PROUCTOS ELECTRODOMESTICOS",
                        'id': 1,
                        'id_country': 2,
                        'id_tipo_empresa': 1,
                        'mesas': 0,
                        'name': "DEMO ERP SOFTNET - BODEGA",
                        'rut': "22222222-2",
                        'state': 1
                     }
                  }
               }
            }
         },
         "folio": "42005",
         "montoNeto": 0,
         "timbre": "https://s3.amazonaws.com/files.dtestorage.com/bmp_re76017114_k_t33_f42009.bmp?AWSAccessKeyId=AKIAJNOZQJM5SRFSWSBQ&Expires=1655421436&Signature=Wj%2B5xj1umTpPafmqR1atDdqINOM%3D"
      }

      setDataImprimirFactura(venta)
   }

   const handleClose = (event?: React.SyntheticEvent | Event, reason?: string) => {
      if (reason === 'clickaway') { return; }
      setOpenSnack(false);
   };
   const handleCloseError = (event?: React.SyntheticEvent | Event, reason?: string) => {
      if (reason === 'clickaway') { return; }
      setOpenSnackError(false);
   };

   const handlePagoChange = (e) => {
      setPagoSelect(e.target.value || 0);
   }

   const handleMesaChange = (e) => {
      setMesaSelect(e.target.value || "");
   }

   const realizarVentaRapida = async (client, namePago, caja, recibido, propina, token, tipoDoc) => {
      setIsLoaded(true);

      // if (dataUsuario.user.rol === ROLE_CAJERO) {
      const res = await cuadraturaService.getActualCuadratura(id_usuario)

      if (res?.status == 200) {
         const fechaApertura = moment(res?.cuadratura.fecha_apertura);
         const fechaCierre = res?.cuadratura.fecha_cierre ? moment(res?.cuadratura.fecha_cierre) : null;
         const hoy = moment();

         const aperturaDiferenteDeHoy = !fechaApertura.isSame(hoy, 'day');
         const cierreEsNulo = fechaCierre === null;

         if (aperturaDiferenteDeHoy && cierreEsNulo) {
            setSnackBarConfig({
               ...snackBarConfig,
               open: true,
               severity: 'warning',
               message: 'Para poder realizar pedidos, debe de cerrar la cuadratura del dia anterior.'
            })

            setIsLoaded(false);
            return;
         }
      }
      // }


      if (dataUsuario.user.rol === ROLE_ADMIN) {


      }


      let data = readLocalStorage(KEY_USER_DATA)
      let company = readLocalStorage(KEY_EMPRESA)

      const fecha = new Date();
      const n_ticket = `T-${fecha.getTime()}-${data.user?.id_empresa || company?.id}`;

      const año = fecha.getFullYear();
      const mes = String(fecha.getMonth() + 1).padStart(2, '0');
      const dia = String(fecha.getDate()).padStart(2, '0');
      const fechaFormateada = `${año}-${mes}-${dia}`;

      //revisar productsCard

      let copyProducts: Array<any> = []
      let monto_neto_calculado: number = 0
      let monto_exento: number = 0

      for (let i = 0; i < productsCard.length; i++) {

         let precio = 0;

         if (productsCard[i].precio_oferta) {
            precio = validatePrice(productsCard[i]);
         } else {
            if (productsCard[i].precio) {
               precio = productsCard[i].precio;
            } else {
               precio = productsCard[i].precio_standar;
            }
         }

         let filtro_cantidad = 0;

         let monto_neto_producto1 = 0;

         if (productsCard[i].cantidad_peso) {
            monto_neto_producto1 = precio
            if (productsCard[i].id_unidad_medida !== 1) {
               filtro_cantidad = Math.ceil(productsCard[i].cantidad);
            } else {
               filtro_cantidad = productsCard[i].cantidad;
            }
         } else {
            monto_neto_producto1 = precio;
            filtro_cantidad = productsCard[i].cantidad;
         }

         /*AFECTO */
         if (productsCard[i]?.idtipo_impuesto === 1) {
            if (productsCard[i].id_unidad_medida && productsCard[i].id_unidad_medida !== 1) {
               monto_neto_calculado = monto_neto_calculado + monto_neto_producto1 * filtro_cantidad;
            } else {
               monto_neto_calculado = monto_neto_calculado + monto_neto_producto1 * productsCard[i].cantidad;
            }
         }

         /*EXENTO */
         if (productsCard[i]?.idtipo_impuesto === 2) {
            monto_exento += monto_neto_producto1 * filtro_cantidad
         }

         arrayIdsProducto.push(productsCard[i].id_producto);
         setArrayIdsProducto(arrayIdsProducto);
      }

      let MntNeto: number = 0
      let IVA: number = 0
      let MntExe: number = 0
      let MntTotal: number = 0
      let tipo_boleta: string = ''
      let tipo_factura: string = ''

      if (tipoDoc == 'Boleta') {
         tipo_boleta = productsCard.every(item => item?.idtipo_impuesto === 2) ? '41' : '39'
         if (tipo_boleta === '39') {
            MntNeto = Math.round(monto_neto_calculado / 1.19);
            IVA = Math.round(MntNeto * 0.19);
            MntExe = monto_exento
            MntTotal = MntNeto + IVA + MntExe
         }

         if (tipo_boleta === '41') {
            MntNeto = monto_exento
            IVA = 0
            MntExe = monto_exento
            MntTotal = monto_exento
         }
      }
      if (tipoDoc == 'Factura') {
         tipo_factura = productsCard.every(item => item?.idtipo_impuesto === 2) ? '34' : '33'
         if (tipo_factura === '33') {
            MntNeto = Math.round(monto_neto_calculado / 1.19);
            IVA = Math.round(MntNeto * 0.19);
            MntExe = monto_exento
            MntTotal = MntNeto + IVA + MntExe
         }

         if (tipo_factura === '34') {
            MntNeto = monto_exento
            IVA = 0
            MntExe = monto_exento
            MntTotal = monto_exento
         }
      }

      let dataEmpresa = readLocalStorage(KEY_EMPRESA)

      const dataUser = await userService.getUserById(dataUsuario.user.id_usuario)

      const resp = await ventaService.generarVentaRapida(
         n_ticket, totalCard, data.user?.id_usuario,
         productsCard, client.key || null, "boleta",
         totalCard, descuentoMonto, descuentoProductos,
         tipoDoc == 'Boleta' ? tipo_boleta : tipo_factura, fechaFormateada, client, MntNeto,
         MntExe, IVA, MntTotal, namePago, productsCard.length,
         recibido, '', fecha, dataEmpresa.id, 0, propina,
         namePago, monto_exento, caja?.id || null, token
      )

      if (!resp) {
         setSnackBarConfig(prev => ({
            ...prev,
            open: true,
            message: 'Los datos a procesar son inválidos',
         }))
         setIsLoaded(false)
         return
      }

      const idcaja = dataUsuario.user.rol === ROLE_CAJERO ? dataUser.user.idcaja : caja?.id
      const nombreCaja = dataUsuario.user.rol === ROLE_CAJERO ? dataUser.user.caja : caja?.nombre

      setShowPDF({
         idVenta: resp.venta.id,
         montoNeto: MntNeto,
         timbre: resp.documento?.urlTimbre || "",
         folio: resp.documento?.folio,
         open: true
      })

      const venta = {
         detalle: productsCard,
         ventaDetalle: resp.venta,
         nombreEmpresa: nombreEmpresa,
         rutEmpresa: rutEmpresa,
         folio: resp.documento.folio,
         timbre: resp.documento.urlTimbre,
         montoNeto: tipo_boleta === '41' ? 0 : MntNeto,
         IVA: IVA,
         clienteSelect: client,
         nombreCajero: dataUsuario.user.rol === ROLE_CAJERO ? dataUsuario.user.nombre : null,
         exento: MntExe,
         titulo: tipo_boleta === '41' ? 'BOLETA ELECTRONICA EXENTA' : 'BOLETA ELECTRONICA',
         caja: nombreCaja || ''
      }

      setDataImprimir(venta)
      handleLoadPago && handleLoadPago();
      setIsLoaded(false);
   }

   const realizarPedido = async () => {
      console.log('productsCard', productsCard)

      arrayIdsProducto.length = 0;
      setArrayIdsProducto(arrayIdsProducto);
      setIsLoaded(true);

      if (productsCard.length === 0) {
         setSnackBarConfig(prev => ({
            ...prev,
            open: true,
            severity: 'warning',
            message: `Agregar almenos un producto al carrito.`,
         }));
         setIsLoaded(false);
         return;

      }

      if (role !== ROLE_ADMIN) {
         const res = await cuadraturaService.getActualCuadratura(id_usuario)
         if (res?.status == 200) {
            const fechaApertura = moment(res?.cuadratura.fecha_apertura);
            const fechaCierre = res?.cuadratura.fecha_cierre ? moment(res?.cuadratura.fecha_cierre) : null;
            const hoy = moment();

            const aperturaDiferenteDeHoy = !fechaApertura.isSame(hoy, 'day');
            const cierreEsNulo = fechaCierre === null;

            if (aperturaDiferenteDeHoy && cierreEsNulo) {
               setSnackBarConfig({
                  ...snackBarConfig,
                  open: true,
                  severity: 'warning',
                  message: 'Para poder realizar pedidos, debe de cerrar la cuadratura del dia anterior.'
               })

               setIsLoaded(false);
               return;
            }
         }
      }

      // eslint-disable-next-line
      if (mesaSelect == "" && optionSale == 1) {
         setSnackBarConfig(prev => ({
            ...prev,
            open: true,
            severity: 'warning',
            message: `Debe elegir  una mesa.`,
         }));
         setIsLoaded(false);
         return;

      }

      for (let i = 0; i < productsCard.length; i++) {
         let precio;
         let codigo;
         if (productsCard[i].precio_oferta) {
            // precio = productsCard[i].precio_oferta;
            precio = validatePrice(productsCard[i]);
         } else {
            if (productsCard[i].precio) {
               precio = productsCard[i].precio;
            } else {
               // eslint-disable-next-line
               precio = validatePrice(productsCard[i]);
            }
         }
         if (productsCard[i].precio) {
            codigo = productsCard[i].codigo;
         } else {
            // eslint-disable-next-line
            codigo = productsCard[i].codigo_erp;
         }

         const data: any = readLocalStorage(KEY_EMPRESA);
         const id_empresa = data.id;


         if (productsCard[i].precio) {
            let body_form_data = new FormData()
            body_form_data.append("codigo_erp", productsCard[i].codigo)
            body_form_data.append("nombre", productsCard[i].nombre)
            body_form_data.append("precio_standar", productsCard[i].precio)
            body_form_data.append("precio_oferta", null)
            body_form_data.append("id_tipo_producto", "1")
            body_form_data.append("id_empresa", id_empresa) // sacar del readlocalstorage la id empresa
            body_form_data.append("stock", productsCard[i].stock)
            body_form_data.append("id_unidad_medida", productsCard[i].unidad_medida_id || '1')
            body_form_data.append("familia_id", productsCard[i].familia_id || 0)
            body_form_data.append("sub_familia_id", productsCard[i].sub_familia_id || 0)
            body_form_data.append("marca_id", productsCard[i].marca_id || 0)
            body_form_data.append("linea_id", productsCard[i].linea_id || 0)
            await editor.postProductBoleta(body_form_data).then((data) => {
               if (data.id_producto[0]) {
                  arrayIdsProducto.push(data.id_producto[0].id);
                  setArrayIdsProducto(arrayIdsProducto);
               } else {
                  arrayIdsProducto.push(data.id_producto.id);
                  setArrayIdsProducto(arrayIdsProducto);
               }
            })
         } else {
            arrayIdsProducto.push(productsCard[i].id);
            setArrayIdsProducto(arrayIdsProducto);
         }
      }
      let query = new URLSearchParams(search)
      let resp: any;
      let keyMesaClient: any = query.get("key");

      if (keyMesaClient || keysClient !== null) {
         let keyClienteClient: any = query.get("key2");
         resp = await ticketService.generarTicket(keyMesaClient || keysClient?.keyMesa, keyClienteClient || keysClient?.keyCliente || null, totalCard, descuentoMonto, descuentoProductos, STATUS_PENDIENTE, productsCard/* , pagosSelect */, arrayIdsProducto)
         /* authenticationService.end(); */
      } else {
         resp = await ticketService.generarTicket(mesaSelect, clienteSelect?.key || null, totalCard, descuentoMonto, descuentoProductos, optionSale == 1 ? STATUS_PENDIENTE : 'entregado', productsCard/* , pagosSelect */, arrayIdsProducto)
      }
      console.log(resp)
      if (resp?.resTicket.status === 200) {
         setShowSnackBar(true)
         // const ticketGenerado = await ticketService.getTicketDetalle(respTicket?.ticketID);
         // const data = {
         //    n_ticket : ticketGenerado?.ticket?.n_ticket,
         //    monto_pedido: ticketGenerado?.ticket?.monto_pedido,
         //    monto_descuento: ticketGenerado?.ticket?.pedidoDetail?.descuento_monto_total,
         //    usuario: ticketGenerado.ticket?.pedidoDetail?.usuario,
         //    cliente: ticketGenerado.ticket?.pedidoDetail?.cliente,
         //    mesa: ticketGenerado.ticket?.pedidoDetail?.mesa,
         //    fecha: ticketGenerado?.ticket?.created_at,
         //    productos: productsCard
         // }
         // setDataImprimirTicket(data);

         // const dataComanda = {
         //    productos: productsCard,
         //    mesa: ticketGenerado.ticket?.pedidoDetail?.mesa,
         // }
         // setDataImprimirComanda(dataComanda);

      }

      if (resp?.respPedido.status == 200) {
         setPedidoSelect(resp?.respPedido.pedidoID)
         // setIdPedidoPagar(resp?.respPedido.pedidoID)
      }

      //MOSTRAR PDF DE COMANDA
      if (resp?.resTicket?.ticketID) {
         let payload: any = {
            idticket: resp?.resTicket?.ticketID,
            idmesa: mesaSelect,
            idcliente: clienteSelect.key,
            productos: productsCard.map(product => { return { idproducto: product.id, cantidad: product.cantidad, idfamilia: product.familia_id } })
         }
         if (role === ROLE_VENDEDOR) {
            payload = {
               ...payload,
               iduser: userData?.id
            }
         }

         const response = await ComandaService.register(payload)
         if (response.status === 200) {
            if (!!response.data.length) {
               response.data.forEach(element => {
                  showComandaPDF(element.id)
               });
            }
         } else {
            setSnackBarConfig(prev => ({
               ...prev,
               open: true,
               severity: 'error',
               message: response.message,
            }));
         }
      }

      salas.cargarAllMesas().then((data) => {
         setMesas(data.mesas || []);
      })
      setClienteSelect('');
      setMesaSelect('');
      setCodigoTransbank('')
      setTotalCard(0);
      setIsLoaded(false);
      secTipoCupon([]);
      setSelectedMesa(null)
      setNotaVenta(false);
      handleLoadPago && handleLoadPago();
      if (resp?.respPedido.status == 200) {
         return resp?.respPedido.pedidoID
      }
      // setOpenPopupPedido(true)
   }

   const showComandaPDF = async (id: number) => {
      try {
         const pdf = await ComandaService.getPDF(id)
         const blob = new Blob([pdf], { type: 'application/pdf' })
         const blobURL = URL.createObjectURL(blob)

         const pdfWindow = window.open(blobURL, '_blank')
         setTimeout(() => {
            if (pdfWindow) {
               pdfWindow.print()
               pdfWindow.close()
            }
         }, 500);
      } catch (error) {
         console.error('Error al mostrar el PDF:', error);
      }
   }

   const editarPedido = async () => {
      setIsLoaded(true);
      const id_ticket = editPedido?.ticket?.id;
      const id_pedido = editPedido?.ticket?.pedidoDetail?.id || editPedido?.pedido?.id;

      if (productsCard.length === 0) {
         setIsLoaded(false);
         return;
      }

      for (let i = 0; i < productsCard.length; i++) {
         let precio;
         let codigo;
         if (productsCard[i].precio_oferta) {
            // precio = productsCard[i].precio_oferta;
            precio = validatePrice(productsCard[i]);
         } else {
            if (productsCard[i].precio) {
               precio = productsCard[i].precio;
            } else {
               // eslint-disable-next-line
               precio = validatePrice(productsCard[i]);
            }
         }
         if (productsCard[i].precio) {
            codigo = productsCard[i].codigo;
         } else {
            // eslint-disable-next-line
            codigo = productsCard[i].codigo_erp;
         }

         const data: any = readLocalStorage(KEY_EMPRESA);
         const id_empresa = data.id;

         if (productsCard[i].precio) {
            let body_form_data = new FormData()
            body_form_data.append("codigo_erp", productsCard[i].codigo)
            body_form_data.append("nombre", productsCard[i].nombre)
            body_form_data.append("precio_standar", productsCard[i].precio)
            body_form_data.append("precio_oferta", null)
            body_form_data.append("id_tipo_producto", "1")
            body_form_data.append("id_empresa", id_empresa) // sacar del readlocalstorage la id empresa
            body_form_data.append("stock", productsCard[i].stock)
            body_form_data.append("familia_id", productsCard[i].familia_id || 0)
            body_form_data.append("sub_familia_id", productsCard[i].sub_familia_id || 0)
            body_form_data.append("marca_id", productsCard[i].marca_id || 0)
            body_form_data.append("linea_id", productsCard[i].linea_id || 0)
            await editor.postProductBoleta(body_form_data).then((data) => {
               if (data.id_producto[0]) {
                  arrayIdsProducto.push(data.id_producto[0].id);
                  setArrayIdsProducto(arrayIdsProducto);
               } else {
                  arrayIdsProducto.push(data.id_producto.id);
                  setArrayIdsProducto(arrayIdsProducto);
               }
            })
         } else {
            if (productsCard[i].id_producto) {
               arrayIdsProducto.push(productsCard[i].id_producto);
               setArrayIdsProducto(arrayIdsProducto);
            } else {
               arrayIdsProducto.push(productsCard[i].id);
               setArrayIdsProducto(arrayIdsProducto);
            }

         }
      }

      await ticketService.editarDetallePedido(mesaSelect, clienteSelect.key || null, id_ticket, totalCard, descuentoMonto, descuentoProductos, productsCard, arrayIdsProducto, id_pedido).then(() => {
         setIsLoaded(false);
         return (handleLoadPago());
      });

      setClienteSelect('');
      setMesaSelect('');
      setCodigoTransbank('')
      setTotalCard(0);
      handleLoadPago && handleLoadPago();
   }

   //    useEffect(() => {
   //       fetch(`https://api2.softnet.cl/familia`, {
   //          method: 'GET',
   //          headers: {
   //             'Access-Control-Allow-Origin':'*',
   //             'Content-Type': 'application/x-www-form-urlencoded',
   //             token: token,
   //          },
   //          })
   //          .then(familiasData => familiasData.json())
   //          .then(async familiasData => {
   //             setAllFamilias(familiasData)
   //          })
   //          .catch(error => {
   //             alert("NO HAY FAMILIA");
   //          })// eslint-disable-next-line
   //   }, [])

   useEffect(() => {
      // getFamiliesAndSubFamilies();
   }, [])

   const getFamiliesAndSubFamilies = async () => {
      setIsLoaded(true);
      await productoService.getFamiliesAndSubFamilies().then((data) => {
         if (data.status === 200) {
            setAllFamilias(data.families || []);
         } else {
            setIsLoaded(false);
            return setSnackBarConfig(prev => ({
               ...prev,
               open: true,
               message: `No se cargaron Familia de productos`,
            }));
         }
      });
      setIsLoaded(false);
   }

   const handleDeleteCupon = (cupon) => {
      let filtros = (cuponesApply || []).filter(c => c.id !== cupon.id);

      saveLocalStorage(KEY_APPLY_CUPONS, filtros);
      setSnackBarConfig(prev => ({
         ...prev,
         open: true,
         message: `El cupon ya no se aplicará`,
      }));
   }

   const handleDeletePuntos = (beneficio) => {
      let filtros = (benefitPoints || []).filter(b => b.id !== beneficio.id);

      saveLocalStorage(KEY_APPLY_BENEF_POINTS, filtros);
      setSnackBarConfig(prev => ({
         ...prev,
         open: true,
         message: `El beneficio ya no se aplicará`,
      }));
   }

   const cargarPedidos = async () => {
      let newValue = new Date();
      const formatFecha = moment(newValue).format('DD-MM-YYYY');
      setLoadData(true);
      await ticketService.getPedidoXFinalizar().then((data) => {
         if (data.status === 200) {
            setPedidos(data.pedidos || []);
            setFilterPedidos(data.pedidos || []);
            setLoadData(false);
         }
      });
      setLoadData(true)
      await ticketService.getPedidos(STATUS_FINALIZADO, formatFecha).then((data) => {
         if (data.status === 200) {
            setFinalizados(data.ticket || []);
            setFilterFinalizados(data.ticket || []);
            setLoadData(false);
         }
      });
   }

   const [diseñoSala, setDiseñoSala] = useState<any[]>([])

   useEffect(() => {
      if (selectedSala) actualizarDiseñoSala(selectedSala.filas, selectedSala.columnas)
   }, [selectedSala])

   const actualizarDiseñoSala = (numFilas: number, numColumnas: number) => {
      const nuevoDiseñoSala = [];
      for (let i = 0; i < numFilas; i++) {
         for (let j = 0; j < numColumnas; j++) {
            const mesaExistente = selectedSala.all_mesas.find(mesa => mesa.fila === i && mesa.columna === j);
            if (mesaExistente) {
               nuevoDiseñoSala.push(mesaExistente);
            } else {
               nuevoDiseñoSala.push({
                  nombre: '',
                  fila: i,
                  columna: j,
               });
            }
         }
      }
      setDiseñoSala(nuevoDiseñoSala);
   };

   const renderMesas = () => {
      const rows = [];
      const numFilas = Math.max(...diseñoSala.map(mesa => mesa.fila)) + 1;
      const numColumnas = Math.max(...diseñoSala.map(mesa => mesa.columna)) + 1;

      for (let i = 0; i < numFilas; i++) {
         const cells = [];
         for (let j = 0; j < numColumnas; j++) {
            const mesa = diseñoSala.find(m => m.fila === i && m.columna === j);

            if (mesa.id) {
               cells.push(
                  <TableCell key={`cell-${i}-${j}`} sx={{ border: 'none', width: 'fit-content' }}>
                     <Paper elevation={3}
                        style={{
                           width: '75px',
                           height: '75px',
                           display: 'flex',
                           flexDirection: 'column',
                           justifyContent: 'center',
                           alignItems: 'center',
                           cursor: 'pointer',
                           background: mesa?.state === 0 ? '#990000' : (selectedMesa?.id === mesa?.id ? '#E67300' : '#229954'),
                           color: '#fff',
                           margin: '0px auto'
                        }}
                        onClick={() => mesa?.state === 0 ? null : handleSelectMesa(mesa)}
                     >
                        <Typography variant="h6" sx={{ textAlign: 'center', fontSize: 'calc(10px + 0.5vw)', color: 'white' }}>{mesa?.nombre.toUpperCase().split(" DE ")[0]}</Typography>
                        <TableRestaurantIcon />
                     </Paper>
                  </TableCell>
               );
            } else {
               cells.push(
                  <TableCell key={`cell-${i}-${j}`} sx={{ border: 'none' }}>
                     <div
                        style={{
                           width: '75px',
                           height: '75px',
                           display: 'flex',
                           flexDirection: 'column',
                           justifyContent: 'center',
                           alignItems: 'center',
                           background: '#efefef',
                           color: '#fff'
                        }}
                     >
                     </div>
                  </TableCell>
               );
            }
         }
         rows.push(<TableRow key={`row-${i}`} sx={{ border: 'none' }}>{cells}</TableRow>);
      }
      return rows;
   };

   return (
      <Box sx={{ padding: 2 }}>
         <Snackbar
            open={snackBarConfig.open}
            autoHideDuration={snackBarConfig.autoHideDuration}
            onClose={() => setSnackBarConfig(prev => ({ ...prev, open: false }))}
            anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
         >
            <Alert
               onClose={() => setSnackBarConfig(prev => ({ ...prev, open: false }))}
               severity={snackBarConfig.severity}
               variant="filled"
            >
               {snackBarConfig.message}
            </Alert>
         </Snackbar>
         <form onSubmit={handleSubmit}>
            {/* <Button onClick={()=>{probarVisualFactura()}} >probando</Button> */}
            <Grid container spacing={2}>
               {
                  tipoCupon.length > 0 && tipoCupon.map((cupon) => (
                     <Grid container xs={12} sx={{ display: "flex", alignItems: "center", justifyContent: "center", textAlign: 'center', marginBottom: '5px' }}>
                        <Grid xs={12} sx={{ padding: '5px', borderRadius: '5px', backgroundColor: 'green', color: 'white', textAlign: 'center' }}>
                           <span>{cupon.name}</span>
                        </Grid>
                     </Grid>

                  ))
               }
               {
                  dataUsuario && role !== 'Cliente' && (
                     <>
                        {/* <Grid item xs={12} style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
                              <Grid item xs={7}>
                                 <Autocomplete
                                    disablePortal id="combo-box-demo"
                                    options={clientes} sx={{ width: '100%' }}
                                    renderInput={(params) => <TextField {...params} label="Seleccione un cliente" />}
                                    value={clienteSelect}
                                    onChange={(e, newValue) => { setClienteSelect(newValue); setRutCliente(newValue?.rut ? newValue?.rut : '')}}
                                    size= 'small'
                                 />
                              </Grid>
                              <Grid item xs={4}>
                                 <Button variant='contained' color="primary" size="small"  style={{textTransform: "capitalize"}}
                                    onClick={()=>{  setOpenPopupClient(true) }} sx={{fontSize: "14px !important"}}
                                 >Agregar Cliente
                                 </Button>
                              </Grid>
                           </Grid> */}

                        {
                           dataEmpresa && dataEmpresa.mesas === 1 && !editPedido && (
                              <Grid item container spacing={2} >
                                 {/* <Grid item xs={12} >
                                    <FormControl sx={{ display: dataEmpresa.id_tipo_empresa === ID_TYPE_RESTAURANTE || dataEmpresa.id_tipo_empresa === ID_TYPE_RESTAURANTE_SOLO_ENTREGA ? "block" : "none" }}>
                                       <InputLabel htmlFor="grouped-native-select">Mesas</InputLabel>
                                       <Select sx={{ textAlign: 'center', width: '290px' }}
                                          native defaultValue=""
                                          id="grouped-native-select"
                                          label="Mesas"
                                          fullWidth
                                          value={mesaSelect}
                                          onChange={handleMesaChange}
                                          size="small"
                                       >
                                          <option arial-label="" value=""></option>

                                          {
                                             (salasE.length !== 0) && (
                                                salasE.map((sala, index) => (
                                                   <optgroup label={`${sala.nombre}`}>
                                                      {
                                                         (mesas.length !== 0) && (
                                                            // eslint-disable-next-line
                                                            mesas.map((mesa, index) => {
                                                               if (mesa.id_sala === sala.id) {
                                                                  return (<option key={index} value={mesa.id}>{`${mesa.nombre}`}</option>)
                                                               }
                                                            })
                                                         )
                                                      }
                                                   </optgroup>
                                                ))
                                             )
                                          }
                                       </Select>
                                    </FormControl>
                                 </Grid> */}
                                 {
                                    role !== ROLE_VENDEDOR && <Grid item xs={12}>
                                       <FormControl component="fieldset">
                                          <RadioGroup value={optionSale} onChange={handleChangeRadio} sx={{ display: 'flex', flexDirection: 'row' }}>
                                             <FormControlLabel
                                                value={2}
                                                control={<Radio sx={{ '&:hover': { bgcolor: 'transparent' }, fontSize: '1.2rem' }} />}
                                                label={<span style={{ fontSize: '1.2rem' }}>Venta</span>}
                                             />
                                             <FormControlLabel
                                                value={1}
                                                control={<Radio sx={{ '&:hover': { bgcolor: 'transparent' }, fontSize: '1.2rem' }} />}
                                                label={<span style={{ fontSize: '1.2rem' }}>Pedido</span>}
                                             />
                                          </RadioGroup>
                                       </FormControl>
                                    </Grid>
                                 }

                                 {optionSale == 1 &&
                                    <>
                                       <Grid item xs={12}>
                                          <input
                                             type="button"
                                             value="Seleccionar mesa"
                                             style={{
                                                padding: '10px 20px',
                                                fontSize: '16px',
                                                backgroundColor: '#516E80',
                                                color: '#ffffff',
                                                border: 'none',
                                                borderRadius: '5px',
                                                cursor: 'pointer',
                                                boxShadow: '0px 2px 5px rgba(0, 0, 0, 0.2)',
                                                transition: 'background-color 0.3s ease',
                                                width: '100%'
                                             }}
                                             onClick={() => setShowModalSalas(true)}
                                          />
                                       </Grid>
                                       <Grid item xs={12} style={{ display: "flex", alignItems: "center", marginBottom: "10px", marginTop: "8px", paddingRight: '8px' }}>
                                          <span style={{ marginRight: "10px", width: '100px' }}><b>Clientes:</b></span>
                                          <Autocomplete
                                             key='cbx-cliente'
                                             id="cbx-cliente"
                                             size="small"
                                             sx={{ width: '232px' }}
                                             value={clienteSelect || null}
                                             options={clientes || []}
                                             getOptionLabel={(option) => option?.label || ''}
                                             isOptionEqualToValue={(option, value) => option.key === value?.key}
                                             renderInput={(params) =>
                                                <TextField {...params}
                                                   label=""
                                                   size="small"
                                                />}
                                             onChange={(e, newValue) => {
                                                setClienteSelect(newValue);
                                                setRutCliente(newValue?.rut ? newValue?.rut : '')
                                             }}
                                             filterOptions={(options, { inputValue }) => {
                                                const inputValueLower = inputValue.toLowerCase();
                                                return options.filter((option) => {
                                                   const label = option.label ? option.label.toLowerCase() : '';
                                                   const rut = option.rut ? option.rut.toLowerCase() : '';
                                                   const docIden = option.doc_iden ? option.doc_iden.toLowerCase() : '';

                                                   return label.includes(inputValueLower) ||
                                                      rut.includes(inputValueLower) ||
                                                      docIden.includes(inputValueLower);
                                                });
                                             }}
                                          />
                                          <IconButton aria-label="delete" size="small"
                                             onClick={() => {
                                                setOpenModalClient(true)
                                             }}>
                                             <PersonAddAltIcon sx={{ fontSize: 20 }} />
                                          </IconButton>
                                       </Grid>
                                       {
                                          <Popup
                                             largo="sm"
                                             title='Agregar Cliente'
                                             openPopup={openModalClient}
                                             setOpenPopup={setOpenModalClient}
                                          >
                                             <AddCliente
                                                closeModal={() => { setOpenModalClient(false) }}
                                                onListClientes={getClientes}
                                                editMode={true}
                                             />

                                          </Popup>
                                       }
                                       <Grid item xs={12} spacing={2} style={{ padding: '12px' }} justifyContent="flex-end" >
                                          <strong style={{ marginRight: "10px", marginLeft: "5px" }}>Mesa:&nbsp;</strong>
                                          <Input
                                             id="selectedSala"
                                             name="selectedSala"
                                             className="input-pago"
                                             disabled
                                             size="small"
                                             type="text"
                                             value={selectedMesa?.nombre || 'No seleccionado'}
                                             sx={{ width: '80%' }}
                                          />
                                          <IconButton aria-label="delete" size="small"
                                             onClick={() => {
                                                setMesaSelect("")
                                                setSelectedMesa(null)
                                             }}>
                                             <CloseIcon sx={{ fontSize: 20 }} />
                                          </IconButton>
                                       </Grid>
                                    </>
                                 }
                              </Grid>
                           )
                        }
                     </>
                  )
               }

               {
                  descuentoMonto > 0 && (
                     <Grid item xs={12} spacing={2} style={{ padding: '12px' }} justifyContent="flex-end" >
                        <strong>Descuento al monto total&nbsp;</strong>
                        <Input
                           disabled
                           size="small"
                           id="cliente"
                           name="cliente"
                           type="number"
                           value={descuentoMonto}
                        />
                     </Grid>)
               }
               {
                  dataEmpresa.id_tipo_empresa !== ID_TYPE_RESTAURANTE &&
                  <Grid item container spacing={2}>

                     <Grid item xs={6}>
                        <FormControl fullWidth>
                           <InputLabel id="grupopago">Tipo de Pago</InputLabel>
                           <Select
                              labelId="tipoPago" id="tipoPago" size='small'
                              fullWidth name="tipoPago" value={pagosSelect}
                              label="Tipo de Pago " onChange={handlePagoChange}
                           >
                              {pagos.map((option, i) => {
                                 return <MenuItem key={i} value={option.id} onClick={() => { setNamePagoSelect(option.nombre) }}>{option.nombre}</MenuItem>
                              })
                              }
                           </Select>
                        </FormControl>
                     </Grid>
                     {
                        notaVenta && (
                           <Grid item xs={6}>
                              <Input
                                 placeholder="Folio nota venta"
                                 className="input-pago"
                                 size="small" id="folio_nota"
                                 name="folio_nota" type="number"
                                 value={folioNotaVenta}
                                 onChange={(e) => { setFolioNotaVenta(e.target.value) }}
                              />
                           </Grid>
                        )
                     }
                     {
                        pagosSelect === 2677 && (
                           <Grid item xs={6}>
                              <Input
                                 placeholder="Código Transbank"
                                 className="input-pago"
                                 size="small" id="codigo_transbank"
                                 name="codigo_transbank" type="number"
                                 value={codigoTransbank}
                                 onChange={(e) => { setCodigoTransbank(e.target.value); codigoTransbank !== "" && setErrorTranB(false) }}
                                 error={errorTransB}
                              />
                           </Grid>
                        )
                     }

                  </Grid>
               }
               <Grid item xs={12} spacing={2} style={{ padding: '12px' }} justifyContent="flex-end" >
                  <strong style={{ marginRight: "10px", marginLeft: "5px" }}>Total a pagar:&nbsp;</strong>
                  <span> {dataEmpresa.country_abbreviation} {moneyFormatInt(totalCard || 0)}</span>
               </Grid>

               {
                  (cuponesApply || []).length > 0 &&
                  <Grid item xs={12} sx={{ display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center" }}>
                     <span>Cupones Aplicados al carrito</span>
                     <Grid container item xs={12} sx={{ display: "flex", alignItems: "center", justifyContent: "left", textAlign: 'center', marginBottom: '5px' }}>
                        {
                           cuponesApply.map((cupon) => (
                              <Grid sx={{ margin: '10px', padding: '10px', borderRadius: '5px', border: "1px solid green", color: 'black', textAlign: 'center' }}>
                                 <span>"{cupon.id}:{cupon.fecha_inicio}:{cupon.fecha_fin}" {`  ===>  `} {verifyCuponApplyPrice(cupon, productsCard)} {dataEmpresa.country_abbreviation}</span>
                                 <span
                                    onClick={() => handleDeleteCupon(cupon)}
                                    style={{
                                       display: "inline-flex", width: 25, height: 25, borderRadius: "50%", background: "red",
                                       justifyContent: "center", alignItems: "center", color: "white", cursor: "pointer", marginLeft: 10
                                    }}
                                 >X</span>
                              </Grid>
                           ))
                        }
                     </Grid>
                  </Grid>
               }
               {
                  (benefitPoints || []).length > 0 && (
                     <Grid item xs={12} sx={{ display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center" }}>
                        <span>Descuento por puntos acumulados</span>
                        <Grid container item xs={12} sx={{ display: "flex", alignItems: "center", justifyContent: "left", textAlign: 'center', marginBottom: '5px' }}>
                           {
                              benefitPoints.map((benefit) => (
                                 <Grid sx={{ margin: '10px', padding: '10px', borderRadius: '5px', border: "1px solid green", color: 'black', textAlign: 'center' }}>

                                    <span>"{benefit.producto?.nombre}" {`  ===>  `} {verifyBenefitPrice(benefit, productsCard)} {dataEmpresa.country_abbreviation}</span>
                                    <span
                                       onClick={() => handleDeletePuntos(benefit)}
                                       style={{
                                          display: "inline-flex", width: 25, height: 25, borderRadius: "50%", background: "red",
                                          justifyContent: "center", alignItems: "center", color: "white", cursor: "pointer", marginLeft: 10
                                       }}
                                    >X</span>

                                 </Grid>
                              ))
                           }
                        </Grid>
                     </Grid>
                  )
               }
               <Grid item xs={12} container flexDirection={'row'} spacing={1}>
                  {
                     dataEmpresa.id_tipo_empresa === ID_TYPE_RESTAURANTE ?
                        (
                           optionSale == 2
                              ?
                              <PagarHome setIsLoaded={setIsLoaded} totalCard={totalCard} realizarPedido={realizarPedido} realizarVentaRapida={realizarVentaRapida} idPedido={pedidoSelected} setOpenPopup={setOpenPopupPedido} loadDataList={cargarPedidos} detailMode={false} anularMode={false} cupones={[]} pagarProducts={false} onList={cargarPedidos} estadoCuadratura={estadoCuadratura} />
                              :
                              <StyledButton
                                 variant="contained" color='success' fullWidth
                                 onClick={() => { editPedido ? editarPedido() : realizarPedido() }}
                                 sx={{ mt: "10px", bgcolor: '#229954' }}
                                 disabled={(!!estadoCuadratura && estadoCuadratura != 0)}

                              >
                                 {editPedido ? "Actualizar" : "Continuar"}
                              </StyledButton>
                        ) : (
                           <>
                              {
                                 notaVenta ? (
                                    <>
                                       <StyledButton
                                          variant="contained" color='warning' fullWidth
                                          onClick={() => { handleGenerateDocumento('notaVenta') }}
                                          sx={{ mt: "10px", mr: 2, bgcolor: '#ed6c02', width: '45%' }}
                                       >
                                          Enviar Nota Venta
                                       </StyledButton>
                                       <StyledButton
                                          variant="contained" color='error' fullWidth
                                          onClick={() => { setNotaVenta(false) }}
                                          sx={{ mt: "10px", bgcolor: 'red', width: '45%' }}
                                       >
                                          Cancelar Nota
                                       </StyledButton>
                                    </>
                                 ) : (
                                    <>

                                       <StyledButton
                                          variant="contained" color='success' fullWidth
                                          onClick={() => { setNotaVenta(true) }}
                                          sx={{ mt: "10px", mr: 2, bgcolor: '#229954', width: '30%' }}
                                       >
                                          {"Nota Venta"}
                                       </StyledButton>
                                       <StyledButton
                                          variant="contained" color='success' fullWidth
                                          onClick={() => { editPedido ? editarPedido() : handleGenerateDocumento('Boleta') }}
                                          sx={{ mt: "10px", mr: 2, bgcolor: '#229954', width: '30%' }}
                                       >
                                          {editPedido ? "Actualizar" : "Boleta"}
                                       </StyledButton>
                                       <StyledButton
                                          disabled={clienteSelect?.type === 2 ? false : true}
                                          variant="contained" color='success' fullWidth
                                          onClick={() => { editPedido ? editarPedido() : handleGenerateDocumento('Factura') }}
                                          sx={{ mt: "10px", bgcolor: '#229954', width: '30%' }}
                                       >
                                          {editPedido ? "Actualizar" : "Factura"}
                                       </StyledButton>
                                    </>
                                 )
                              }

                           </>
                        )
                  }

               </Grid>
            </Grid>
         </form>
         {isLoaded && <Loading />}
         <Snackbar open={openSnack} autoHideDuration={4000} onClose={handleClose}>
            <Alert onClose={handleClose} severity="success" sx={{ width: '100%' }}>
               Boleta generada correctamente!
            </Alert>
         </Snackbar>
         <Snackbar open={openSnackError} autoHideDuration={4000} onClose={handleCloseError}>
            <Alert onClose={handleCloseError} severity="error" sx={{ width: '100%' }}>
               Ocurrió algun error al generar la boleta!
            </Alert>
         </Snackbar>
         <Popup
            largo="sm"
            title='Agregar Cliente'
            openPopup={openPopupClient}
            setOpenPopup={setOpenPopupClient}
         >
            <AddCliente
               closeModal={() => { setOpenPopupClient(false) }}
               onListClientes={getClientes}
               clientSelected={setClienteSelect}
               rutCliente={setRutCliente}
               editMode={true}
            />

         </Popup>

         {dataImprimirComanda &&
            <div style={{ display: "none" }}>
               <div ref={componentRef}>
                  <ComponentComanda
                     data={dataImprimirComanda}
                  />
               </div>
            </div>
         }
         {dataImprimir &&
            <div style={{ display: "none" }}>
               <div ref={componentRef}>
                  <ComponentPrint
                     data={dataImprimir}
                     listaPrecio={listaPrecio}
                  />
               </div>
            </div>
         }
         {dataImprimirTicket &&
            <div style={{ display: "none" }}>
               <div ref={componentRef}>
                  <ComponentPrintTicket
                     data={dataImprimirTicket}
                     listaPrecio={listaPrecio}
                  />
               </div>
            </div>
         }
         {dataImprimirFactura &&
            <div style={{ display: "none" }}>
               <div ref={componentRef}>
                  <ComponentPrintFactura
                     data={dataImprimirFactura}
                  />
               </div>
            </div>
         }
         {ImprimirCupon &&
            <div>
               <div ref={componentRef}>
                  <ComponentCuponTicket
                     data={tipoCupon}
                     closeModal={() => { setImprimirCupon(false) }}
                  />
               </div>
            </div>
         }
         {
            showSnackBar &&
            <Snackbar
               open={showSnackBar}
               autoHideDuration={2000}
               onClose={() => setShowSnackBar(false)}
               anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'right',
               }}
            >
               <Alert onClose={() => setShowSnackBar(false)} severity="success">
                  ¡Pedido generado con éxito!
               </Alert>
            </Snackbar>
         }
         {
            showModalSalas &&
            <Popup
               largo="lg"
               title={'Seleccione una mesa'}
               openPopup={showModalSalas}
               setOpenPopup={setShowModalSalas}
            >
               <Grid container spacing={2}>
                  <Grid item container xs={12} spacing={2} sx={{ display: 'flex', justifyContent: 'center', alignContent: 'center', overflowX: 'auto' }}>
                     {salasE?.map((sala) => (
                        salasE.length !== 0 &&
                        <Grid item key={sala.id}>
                           <Paper elevation={3}
                              style={{
                                 width: '175px',
                                 height: '100px',
                                 padding: '30px',
                                 display: 'flex',
                                 flexDirection: 'column',
                                 justifyContent: 'center',
                                 alignItems: 'center',
                                 cursor: 'pointer',
                                 background: selectedSala?.id === sala.id ? '#2b3a47' : '#516E80',
                                 color: 'white'
                              }}
                              onClick={() => setSelectedSala(sala)}
                           >
                              <Typography variant="h6" sx={{ textAlign: 'center', fontSize: 'calc(12px + 0.5vw)', color: 'white' }}>{sala.nombre.toUpperCase()}</Typography>
                              <RestaurantIcon />
                           </Paper>
                        </Grid>
                     ))}
                  </Grid>

                  <Grid item container xs={12} spacing={2} sx={{ display: 'flex', justifyContent: 'center', alignContent: 'center' }}>
                     <Grid item xs={12}>
                        <TableContainer component={Paper}>
                           <Table sx={{ borderCollapse: 'separate', borderSpacing: '0', borderRadius: '5px', background: '#efefef' }}>
                              <TableBody>
                                 {renderMesas()}
                              </TableBody>
                           </Table>
                        </TableContainer>
                     </Grid>
                  </Grid>
               </Grid>
            </Popup>
         }
         {openPopupPedido &&
            <PopupComponent
               largo="md"
               title={'Pagar el pedido'}
               openPopup={openPopupPedido}
               setOpenPopup={setOpenPopupPedido}
            >
               <PagarPedido idPedido={pedidoSelected} setOpenPopup={setOpenPopupPedido} loadDataList={cargarPedidos} detailMode={false} anularMode={false} cupones={[]} pagarProducts={false} onList={cargarPedidos} />
            </PopupComponent>
         }
      </Box>
   )

}
