import React, {  useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { KEY_TOOGLE_MENU } from '@constants/local-storage';
import { ROUTE_LOGIN } from '@constants/route-map';
import { useLocalStorage } from '@hooks/local-storage.hook';
import { authenticationService } from '@service/services/Authentication.service';
import './Layout.sass';
import { Header } from '@/components/common/Header';
import { DrawerLateral } from '@/components/common/Header/DrawerLateral';
import { Popup } from '@/views/Editor/components/Popup';

interface ProtectedProps {
   className?: string,
   children?: React.ReactNode | React.ReactNode[] | null,
}

let cleanInterval: any;

export const Protected: React.FC<ProtectedProps> = (
   props: ProtectedProps
) : JSX.Element | any => {

   const [toogleMenu, changeMenu] = useLocalStorage<Boolean>(KEY_TOOGLE_MENU, false);
   // const [loadData, setLoadData]  = useState<boolean>(false);
   // const modalStatus = useRef<any>(null);
   const dataUser    = authenticationService.currentUserValue;
   const authUser    = authenticationService.authCookie();

   const history  = useHistory();

   const didMount = async () : Promise<void> => {
      //(screen.width < APP_DESKTOP_WIDTH) && changeMenu(false);
      if (!dataUser || !authUser) {
         authenticationService.logout();
         history.replace(ROUTE_LOGIN);
      }
   }
   const didUnmount = async () => {
      clearInterval(cleanInterval);
   }

   useEffect(() => {
      didMount();
      return () => { didUnmount() }
   }, // eslint-disable-next-line
   []);

   return (
      <>
         <div className = "l-protected">
            <div
               className = {"l-protected__bg" + (toogleMenu ? ' --open' : '')}
               onClick = {() => changeMenu(false)}
            />
               <div className={"l-protected__page "+ props.className} >
                  <Header />
                  <div className="l-protected__main-page">
                     <div className="l-protected__content">
                        {props.children}
                     </div>
                  </div>
               </div>
         </div>
      </>
   );
}
