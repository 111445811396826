import { Loading } from "@/components/common/Loading";
import { Protected } from "@/components/layout/Protected";
import { productoService } from "@/service/services/Producto.service";
import { KEY_EMPRESA, KEY_USER_DATA } from "@/toolbox/constants/local-storage";
import { readLocalStorage } from "@/toolbox/helpers/local-storage-helper";
import { Alert, Button, Grid, IconButton, InputAdornment, Menu, MenuItem, Paper, Snackbar, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, TextField, Tooltip, Typography } from "@mui/material";
import { FC, useEffect, useState } from "react";
import SearchIcon from "@mui/icons-material/Search";
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { ModalProducto } from "./ModalProducto";
import { DeletedProduct } from "../Editor/components/DeletedProduct";
import ViewColumnIcon from '@mui/icons-material/ViewColumn';
import { useHistory } from 'react-router-dom';
import ReceiptIcon from '@mui/icons-material/Receipt';
import { ROUTE_PRODUCTOS_INSUMO } from "@/toolbox/constants/route-map";
import SyncIcon from '@mui/icons-material/Sync';
import SimCardDownloadIcon from '@mui/icons-material/SimCardDownload';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import { styled } from '@mui/material/styles';
import excelImage from '../../assets/fileIcons/xlsx.png';
import { moneyFormatInt } from "@/toolbox/helpers/money.helper";

const VisuallyHiddenInput = styled('input')({
    clip: 'rect(0 0 0 0)',
    clipPath: 'inset(50%)',
    height: 2,
    overflow: 'hidden',
    position: 'absolute',
    bottom: 0,
    left: 0,
    whiteSpace: 'nowrap',
    width: 1,
});

export const MisProductos: FC = () => {

    const dataUsuario = readLocalStorage(KEY_USER_DATA)
    const dataEmpresa = readLocalStorage(KEY_EMPRESA);
    const history = useHistory()

    const [productos, setProductos] = useState<any[]>([])
    const [productosBackUp, setProductosBackUp] = useState<any[]>([])
    const [loading, setLoading] = useState<boolean>(false)
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(20);
    const [showModalProduct, setShowModalProduct] = useState<boolean>(false)
    const [action, setAction] = useState<string>('')
    const [rowSelected, setRowSelected] = useState<any>(null)
    const [showModalDelete, setShowModalDelete] = useState<boolean>(false)
    const [anchorEl, setAnchorEl] = useState(null);
    const [loadData, setLoadData] = useState<boolean>(false);
    const [snackBarConfig, setSnackBarConfig] = useState<any>({
        open: false,
        severity: 'error',
        message: 'Error',
        autoHideDuration: 3000,
    });
    const [menuSinc, setMenuSinc] = useState(null)

    useEffect(() => {
        getProductosApi()
    }, [])

    const getProductosApi = async () => {
        setLoading(true)
        const response = await productoService.getProductsByCompany(dataUsuario.user.id_empresa)
        setProductos(response.productos || [])
        setProductosBackUp(response.productos || [])
        setLoading(false)
    }

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const onSearch = (search: string) => {
        const searchLowerCase = search.trim().toLowerCase();
        if (search !== '') {
            const filteredProducts = productosBackUp.filter(product => (
                (product.nombre && product.nombre.toLowerCase().includes(searchLowerCase)) ||
                (product.codigo_erp && product.codigo_erp.toLowerCase().includes(searchLowerCase))
            ));
            setProductos(filteredProducts);
        } else {
            setProductos(productosBackUp);
        }
    }

    const onDelete = (rowSelected) => {
        setRowSelected(rowSelected)
        setShowModalDelete(true)
    }

    const onShowCodigoBarras = (rowSelected) => {
        history.replace('/codigo-barras/' + rowSelected.id)
    }

    const onShowReceta = (rowSelected) => {
        history.replace('/receta/' + rowSelected.id)
    }

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleCloseMenu = () => {
        setAnchorEl(null);
    };

    const syncFamily = async () => {
        setLoadData(true);
        await productoService.syncFamiliaProducts().then((data) => {
            if (data.message == "Sync successfull") {
                setSnackBarConfig(prev => ({
                    ...prev,
                    open: true,
                    severity: 'success',
                    message: `${data.message}`,
                }));
            }
        });
        setLoadData(false);
    }

    const syncSubFamily = async () => {
        setLoadData(true);
        await productoService.syncSubFamiliaProducts().then((data) => {

            if (data.message == "Sync successfull") {
                setSnackBarConfig(prev => ({
                    ...prev,
                    open: true,
                    severity: 'success',
                    message: `${data.message}`,
                }));
            }

            if (data.status == '500') {
                setSnackBarConfig(prev => ({
                    ...prev,
                    open: true,
                    severity: 'warning',
                    message: `${data.message}`,
                }));
            }
        });
        setLoadData(false);
    }
    const syncLine = async () => {
        setLoadData(true);
        await productoService.syncLineaProducts().then((data) => {
            if (data.message == "Sync successfull") {
                setSnackBarConfig(prev => ({
                    ...prev,
                    open: true,
                    severity: 'success',
                    message: `${data.message}`,
                }));
            }
        });
        setLoadData(false);
    }

    const downloadTemplateProductImport = async () => {
        try {
            const url = await productoService.downloadTemplateImport()
            if (!url || !url.data) return
            window.open(url.data)
        } catch (error) {
            console.error('Error al descargar el archivo:', error);
        }
    };

    const handleImport = async (e) => {
        try {
            const file = e.target.files[0];
            if (!file) return

            const formData = new FormData()
            formData.append('document', file)
            e.target.value = null

            await productoService.importProducts(formData)
            window.location.reload()
        } catch (error) {
            console.error('Error al importar el archivo', error)
        }
    }

    const syncProducts = async () => {
        setLoadData(true);
        await productoService.validateProductsSofnet().then((data) => {
            if (data.message == "Sync successfull") {
                setSnackBarConfig(prev => ({
                    ...prev,
                    open: true,
                    severity: 'success',
                    message: `${data.message}`,
                }));
            }

            if (data.status == '500') {
                setSnackBarConfig(prev => ({
                    ...prev,
                    open: true,
                    severity: 'warning',
                    message: `${data.message}`,
                }));
            }
        });
        setLoadData(false);
    }

    return (<Protected>
        {loading && <Loading />}
        <Snackbar
            open={snackBarConfig.open}
            autoHideDuration={snackBarConfig.autoHideDuration}
            onClose={() => setSnackBarConfig(prev => ({ ...prev, open: false }))}
            anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        >
            <Alert
                onClose={() => setSnackBarConfig(prev => ({ ...prev, open: false }))}
                severity={snackBarConfig.severity}
                variant="filled"
            >
                {snackBarConfig.message}
            </Alert>
        </Snackbar>
        <Grid container spacing={2}>
            <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'center' }}>
                <Typography variant="h4">MIS PRODUCTOS</Typography>
            </Grid>
            <Grid item container xs={12}
                sx={{
                    alignItems: "center",
                    display: "flex",
                    justifyContent: "space-between",
                    flexWrap: "wrap",
                    marginBottom: "10px",
                }}
            >
                <Grid item>
                    <TextField
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <SearchIcon fontSize="small" />
                                </InputAdornment>
                            ),
                        }}
                        placeholder="Buscar..."
                        size="small"
                        onChange={(e) => onSearch(e.target.value)}
                    />
                </Grid>
                <Grid
                    item
                    sx={{ textAlign: "right" }}
                >
                    <Button variant='outlined' onClick={(e) => { setMenuSinc(e.currentTarget) }} sx={{ marginRight: '10px' }}>
                        Sincronizar
                    </Button>
                    <Menu
                        id="simple-menu-1"
                        anchorEl={menuSinc}
                        open={Boolean(menuSinc)}
                        onClose={() => setMenuSinc(null)}
                    >
                        <MenuItem onClick={() => { syncFamily(); setMenuSinc(null); }}>
                            <Tooltip title="Importar familias del erp softnet">
                                <Button variant='outlined' className="btnSync" fullWidth>
                                    <SyncIcon fontSize="small" /> Familia Erp
                                </Button>
                            </Tooltip>
                        </MenuItem>
                        <MenuItem onClick={() => { syncSubFamily(); setMenuSinc(null); }}>
                            <Tooltip title="Importar subfamilias del erp softnet">
                                <Button variant='outlined' className="btnSync" fullWidth>
                                    <SyncIcon fontSize="small" /> Sub familia Erp
                                </Button>
                            </Tooltip>
                        </MenuItem>
                        <MenuItem onClick={() => { syncLine(); setMenuSinc(null); }}>
                            <Tooltip title="Importar lineas del erp softnet">
                                <Button variant='outlined' className="btnSync" fullWidth>
                                    <SyncIcon fontSize="small" /> Linea Erp
                                </Button>
                            </Tooltip>
                        </MenuItem>
                        <MenuItem onClick={() => { syncProducts(); setMenuSinc(null); }}>
                            <Tooltip title="Importar productos del erp softnet">
                                <Button variant='outlined' className="btnSync" fullWidth>
                                    <SyncIcon fontSize="small" /> Productos Erp
                                </Button>
                            </Tooltip>
                        </MenuItem>
                    </Menu>
                    <Button variant='outlined' onClick={handleClick} >
                        Opciones
                    </Button>
                    <Menu
                        id="simple-menu-2"
                        anchorEl={anchorEl}
                        open={Boolean(anchorEl)}
                        onClose={handleCloseMenu}
                    >
                        <MenuItem onClick={() => { setAction('create'); setRowSelected(null); setShowModalProduct(true) ; handleCloseMenu();}}>
                            <Button variant='outlined' className="btnSync" fullWidth>
                                Nuevo producto
                            </Button>
                        </MenuItem>
                        <MenuItem onClick={() => { downloadTemplateProductImport(); handleCloseMenu(); }}>
                            <Tooltip title={'Descargar plantilla'}>
                                <Button
                                    component="label"
                                    variant="outlined"
                                    className="btn-dwtemplate"
                                    fullWidth
                                    startIcon={<SimCardDownloadIcon fontSize="small" />}
                                    endIcon={<img src={excelImage} alt="Icon" width={20} height={20} />}
                                >
                                    Descargar plantilla
                                </Button>
                            </Tooltip>
                        </MenuItem>
                        <MenuItem>
                            <Tooltip title={'Importar productos'}>
                                <Button
                                    component="label"
                                    variant='outlined'
                                    className="btn-import"
                                    fullWidth
                                    startIcon={<CloudUploadIcon fontSize='small' />}
                                    endIcon={<img src={excelImage} alt="Icon" width={20} height={20} />}
                                >
                                    Importar productos
                                    <VisuallyHiddenInput type="file" accept=".xlsx" onChange={handleImport} />
                                </Button>
                            </Tooltip>
                        </MenuItem>
                        <MenuItem onClick={() => { history.push(ROUTE_PRODUCTOS_INSUMO) }}>
                            <Button variant='outlined' className="btnSync" fullWidth>
                                Ir a Productos Insumos
                            </Button>
                        </MenuItem>
                    </Menu>
                </Grid>
            </Grid>
            <Grid item xs={12}>
                <TableContainer component={Paper}>
                    <Table sx={{ minWidth: 650 }} aria-label="simple table">
                        <TableHead>
                            <TableRow sx={{ bgcolor: '#1976D2' }}>
                                <TableCell sx={{ color: 'white' }}>{`N°`}</TableCell>
                                <TableCell align="left" sx={{ color: 'white' }}>Codigo</TableCell>
                                <TableCell align="left" sx={{ color: 'white' }}>Nombre</TableCell>
                                <TableCell align="left" sx={{ color: 'white' }}>Stock</TableCell>
                                <TableCell align="left" sx={{ color: 'white' }}>Precio standar</TableCell>
                                <TableCell align="left" sx={{ color: 'white' }}>Precio oferta</TableCell>
                                <TableCell align="left" sx={{ color: 'white' }}>Tipo de producto</TableCell>
                                <TableCell align="left" sx={{ color: 'white' }}>Familia</TableCell>
                                <TableCell align="left" sx={{ color: 'white' }}>Subfamilia</TableCell>
                                <TableCell align="left" sx={{ color: 'white' }}>Linea</TableCell>
                                <TableCell align="left" sx={{ color: 'white' }}>Tipo de impuesto</TableCell>
                                <TableCell align="center" sx={{ color: 'white' }}>Opciones</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {!!productos.length
                                ?
                                productos?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((producto, index) =>
                                    <TableRow key={index}>
                                        <TableCell align="left">{index + 1 + page * rowsPerPage}</TableCell>
                                        <TableCell align="left">{producto.codigo_erp}</TableCell>
                                        <TableCell align="left">{producto.nombre}</TableCell>
                                        <TableCell align="left">{producto.stock || '-'}</TableCell>
                                        <TableCell align="left">{moneyFormatInt(producto.precio_standar)}</TableCell>
                                        <TableCell align="left">{producto.precio_oferta || 0}</TableCell>
                                        <TableCell align="left">{producto.tipo_producto}</TableCell>
                                        <TableCell align="left">{producto.familia || '-'}</TableCell>
                                        <TableCell align="left">{producto.subfamilia || '-'}</TableCell>
                                        <TableCell align="left">{producto.linea || '-'}</TableCell>
                                        <TableCell align="left">{producto.tipo_impuesto}</TableCell>
                                        <TableCell align="center">
                                            <Tooltip title={"Editar producto"}>
                                                <IconButton onClick={() => { setAction('edit'); setRowSelected(producto); setShowModalProduct(true) }}>
                                                    <EditIcon color="primary" />
                                                </IconButton>
                                            </Tooltip>
                                            <Tooltip title={"Eliminar producto"}>
                                                <IconButton onClick={() => onDelete(producto)}>
                                                    <DeleteIcon color="error" />
                                                </IconButton>
                                            </Tooltip>
                                            <Tooltip title={"Ver codigos de barras"}>
                                                <IconButton onClick={() => onShowCodigoBarras(producto)}>
                                                    <ViewColumnIcon />
                                                </IconButton>
                                            </Tooltip>
                                            {
                                                dataEmpresa?.id_tipo_empresa !== 1 && (
                                                    <Tooltip title={"Ver receta"}>
                                                        <IconButton onClick={() => onShowReceta(producto)}>
                                                            <ReceiptIcon />
                                                        </IconButton>
                                                    </Tooltip>
                                                )
                                            }
                                        </TableCell>
                                    </TableRow>
                                )
                                :
                                <TableRow>
                                    <TableCell align="center" colSpan={12}>Sin resultados</TableCell>
                                </TableRow>
                            }
                        </TableBody>
                    </Table>
                </TableContainer>
                <TablePagination
                    rowsPerPageOptions={[20, 30, 40]}
                    component="div"
                    count={productos?.length || 0}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                />
            </Grid>
        </Grid>
        {
            showModalProduct &&
            <ModalProducto
                open={showModalProduct}
                setOpenPopup={setShowModalProduct}
                action={action}
                rowSelected={rowSelected}
                onList={getProductosApi}
            />
        }
        {
            showModalDelete &&
            <DeletedProduct
                open={showModalDelete}
                closeModal={() => setShowModalDelete(false)}
                ProductId={rowSelected.id}
                TipoProductId={1} //productos simples
                isSupply={0} //no es insumo
                getProductos={getProductosApi}
                text={'¿Está seguro de eliminar el producto?'}
            />
        }
    </Protected>)
}
