import { useEffect, useState } from "react";
import { Card, CardContent, CardMedia, Container, Grid, Modal, Typography } from "@mui/material";
import { HeaderEcommerce } from "../Header";
import { Props } from "./ProductsEcommerce.type";
import { SidebarEcommerce } from "../Sidebar/SidebarEcommerce";
import { editor } from "@/service/services/Editor.service";
import { useLocalStorage } from "@/toolbox/hooks/local-storage.hook";
import { KEY_EMPRESA, PRODUCTS_CARD } from "@/toolbox/constants/local-storage";
import { Loading } from "@/components/common/Loading";
import { readLocalStorage, saveLocalStorage } from "@/toolbox/helpers/local-storage-helper";
import { ModalCartShop } from "../ModalCartShop";
import moment from "moment";
import ReactPaginate from 'react-paginate';


export const ProductsEcommerce: React.FC<Props> = (props: any): JSX.Element => {
   const sizePage = 20;
    const idsProducts = props.location?.state?.id_products;
    const categoryName = props.location?.state?.categoryName;

    const [loading, setLoading] = useState<boolean>(false);
    const [products, setProducts] = useState<Array<any>>([]);
    const [productsSecondary, setProductsSecondary] = useState<Array<any>>([]);
    const [filterPrice, setFilterPrice] = useState<string>("");
    const [filterSubModulo, setFilterSubModulo] = useState<string>("");
    const [showPaginate, setShowPaginate] = useState<boolean>(true);
    const [showFiltersMobile, setShowFiltersMobile] = useState<boolean>(false);
    const [page, setPage] = useState<any>(0);
    const [dataEmpresa] = useLocalStorage(KEY_EMPRESA, {
        country_abbreviation: "",
        nombre: ""
    });

    //carrito
    const [cardState, setCardState] = useState([]);
    // eslint-disable-next-line
    const [countProductsSelect, setCountProductsSelect] = useState(0);
    //lista precio
    // eslint-disable-next-line
    const [listaPrecio, setListaPrecio] = useState([]);

    const handleClose = () => {
        props.$action.actionSetEcommerce({
            ...props.$store.ecommerceReducer,
            showModal: false,
        })
    };

    const stateModalShop = props?.$store?.ecommerceReducer?.showModal;

    useEffect(() => {
        const storeProducts = readLocalStorage(PRODUCTS_CARD);
        setCardState(storeProducts || []);
        // eslint-disable-next-line
    }, [!stateModalShop]);

    const getProductos = async(filterSubModulo) => {
        setLoading(true);
        let arrayProducts = [];
        if(filterSubModulo){
            await editor.cargarProductos(filterSubModulo).then((data) => {
                if(data.productos){
                    for(let i=0; i<data.productos.length;i++){
                        const valor = Object.values(data.productos[i]);
                        arrayProducts.push(valor[0]);
                    }
                }
                setProducts(arrayProducts);
            });
        }
        setLoading(false);
    }

    const validatePrice = (product, listaPrecio) => {
        //validar si existe en la lista de precio
        let existList = (listaPrecio|| []).find(p => p.codigo_erp === product.codigo_erp);
        if (existList) {
           return existList?.precio_ajustado || 0;
        }

        const dateToday = moment();
        const value = product.caducidad_precio_oferta;
        const expirationPrice = moment(value, 'DD-MM-YYYY');

        const standarPrice = product.precio_standar >= 0 ? product.precio_standar : Number(product.precio);
        const offerPrice = product.precio_oferta;

        if(expirationPrice !== null && offerPrice !== null){
           if(expirationPrice.isSameOrAfter(dateToday)){
              return offerPrice;
           }else{
              return standarPrice;
           }
        }else{
           return standarPrice;
        }
    }

    const handleSelectProduct = (product) => {
        let card: Array<any> = cardState;
        let exits: boolean = false;
        let precio_unitario;
        if(product){
            for(let i = 0; i<card.length; i++){
                if(card[i].codigo_erp === product.codigo_erp && card[i].nombre === product.nombre){
                    if(card[i].cantidad < card[i].stock){
                        card[i].cantidad = card[i].cantidad ? card[i].cantidad+1 : 2
                    }
                    if(card[i].cantidad === card[i].stock){
                        alert('no hay suficiente stock');
                    }
                    exits = true;
                }
            }
            if (product?.codigo_erp) {
                precio_unitario = validatePrice(product, listaPrecio);
            }
            if (!exits) {
                card.push({
                    ...product,
                    cantidad: 1,
                    precio_unitario: precio_unitario
                });
            }
            setCardState([...card]);
            saveLocalStorage(PRODUCTS_CARD, card);
            saveLocalStorage('showCart', card);

            let products = card.length;
            props.$action.actionSetEcommerce({
                ...props.$store.ecommerceReducer,
                productsCount: products,
            });
            saveLocalStorage('countCart', products);
        }
    }

    const handleChangeSubModulo = (newValue) => {
        setFilterSubModulo(newValue);
        getProductos(newValue);
    }

    const handleChangePrice = (newValue: string) => {
      const price = Number(newValue);

      const newProducts = [];
      for (let i = 0; i < products.length; i++) {
         const producto = products[i];
         let precioFinal = Number(validatePrice(producto, listaPrecio));

         switch (price) {
            case 8001:
               (precioFinal >= price) && newProducts.push(producto)
               break;
            case 100:
               (precioFinal <= price && precioFinal > 0) && newProducts.push(producto)
               break;
            case 500:
               (precioFinal <= price && precioFinal > 100) && newProducts.push(producto)
               break;
            case 1000:
               (precioFinal <= price && precioFinal > 500) && newProducts.push(producto)
               break;
            case 3000:
               (precioFinal <= price && precioFinal > 1000) && newProducts.push(producto)
               break;
            case 8000:
               (precioFinal <= price && precioFinal > 3000) && newProducts.push(producto)
               break;
         }
      }
      setPage(0);
      setProductsSecondary([...newProducts])
      setFilterPrice(newValue);

      setShowPaginate(false);
      setTimeout(() => {
         setShowPaginate(true);
     }, 1000);
    }

    const handleCleanFilters = () => {
      setProductsSecondary([]);
      setPage(0);
      setFilterPrice("");
      setShowPaginate(false);
      setTimeout(() => {
         setShowPaginate(true);
     }, 1000);
    }

    return (
        <Grid>
            {loading && <Loading/>}
            <Grid container sx={{ marginBottom: '25px', alignContent: 'center'}}>

                <HeaderEcommerce/>

                <Grid item container spacing={2} xs={12} >

                    <Grid item container p={1} md={2} className="container_filters" >
                        <SidebarEcommerce
                           valuePrice={filterPrice}
                           valueSubModulo={filterSubModulo}
                           handleChangePrice={handleChangePrice}
                           handleChangeSubModulo = {(handleChangeSubModulo)}
                           handleCleanFilters={handleCleanFilters}
                        />
                    </Grid>
                    <Modal
                        open={showFiltersMobile}
                        onClose={()=>setShowFiltersMobile(false)}
                        aria-labelledby="parent-modal-title"
                        aria-describedby="parent-modal-description"
                        className='modalCard'
                     >
                        <div className="container_filters-mob">
                           <SidebarEcommerce
                                 valuePrice={filterPrice}
                                 valueSubModulo={filterSubModulo}
                                 handleChangePrice={handleChangePrice}
                                 handleChangeSubModulo = {(handleChangeSubModulo)}
                                 handleCleanFilters={handleCleanFilters}
                              />
                        </div>
                    </Modal>

                    <Grid item container xs={12} md={10} >
                        <Grid container xs={12} sx={{display: "flex", justifyContent: "center"}}>
                              {/* <Typography variant="h2" textAlign={'left'} className="textSubTitle">
                                 Productos de la categoria - {categoryName || ""}
                              </Typography> */}
                              <span onClick={()=>setShowFiltersMobile(!showFiltersMobile)} className="buttonFiltros">Filtros</span>

                                <Grid container xs={12}>
                                    {// eslint-disable-next-line
                                    (productsSecondary.length ? productsSecondary : products).map((product, i) => {
                                    if (i >= (page)*sizePage && i < (page+1)*sizePage ){
                                       return (
                                          <Grid xs={12} sm={12} md={12} lg={6} p={3} key={i} >
                                          <Grid item  xs={12} style={{height: "100%"}}>
                                                <Card sx={{ display: 'flex', justifyContent: 'space-between', alignItems: "center"}} className={"card-contenedor"} >
                                                    <Grid sx={{ display: 'flex', flexDirection: 'column' }}>
                                                        <CardContent >
                                                            <Typography variant="subtitle1" component="div" mb={1} >
                                                            {product.nombre}
                                                            </Typography>
                                                            <Typography className='product-description' color="text.secondary"   mb={1} >
                                                            Lizards are a widespread group of squamate reptiles, with over 6,000
                                                            species, ranging across all lhefiugefbkjrebfkwrebfw kjbdyef fkbfjkbren elfhwerno
                                                            </Typography>
                                                            {/* <Typography variant="subtitle1" textAlign={'left'} mb={1} >
                                                                    Stock:
                                                                    <span style={{marginLeft: "5px", fontWeight: "bold", color: "rgb(180, 21, 100)"}}>
                                                                    {product.stock}
                                                                    </span>
                                                            </Typography> */}
                                                            <span className="card-contenedor__oferta">
                                                            {`${dataEmpresa.country_abbreviation} ${validatePrice(product, listaPrecio)}`}
                                                            </span>
                                                            <span className="card-contenedor__carritoOferta" onClick={() => handleSelectProduct(product)}>
                                                                Lo quiero
                                                            </span>
                                                        </CardContent>
                                                   </Grid>
                                                   <CardMedia
                                                        component="img"
                                                        // image={product.imagen}
                                                        image={"https://images.rappi.cl/products/2090193507-1547158222.png?e=webp&d=200x200&q=70"}
                                                        alt="product"
                                                        style={{ backgroundColor: '#ffc800' }}
                                                        sx={{ minWidth: '100px', maxWidth: '100px', minHeight: '100px', maxHeight: '100px', margin: '10px', borderRadius: '5px' }}
                                                   />
                                                </Card>
                                          </Grid>
                                          </Grid>
                                       )
                                    }
                                    })}

                                </Grid>
                                {
                                 showPaginate &&
                                 <ReactPaginate
                                    breakLabel="..."
                                    nextLabel=" > "
                                    onPageChange={(e)=>setPage(e?.selected || 0)}
                                    pageCount={productsSecondary.length ? Math.ceil(((productsSecondary).length)/sizePage) : Math.ceil(((products).length)/sizePage)}
                                    previousLabel=" < "
                                    renderOnZeroPageCount={null}
                                    containerClassName="pag__container"
                                    pageLinkClassName="pag__page-link"
                                    nextLinkClassName="pag__page-link"
                                    previousLinkClassName="pag__page-link"
                                    activeLinkClassName="pag__page-active"
                                    initialPage={page}
                                 />
                                }
                        </Grid>
                    </Grid>

                </Grid>
                <Modal
                    open={props.$store.ecommerceReducer.showModal}
                    onClose={handleClose}
                    aria-labelledby="parent-modal-title"
                    aria-describedby="parent-modal-description"
                    className='modalCard'
                >
                    <ModalCartShop listaPrecio={listaPrecio}/>
                </Modal>
            </Grid>
        </Grid>
    )
}
