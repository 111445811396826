import { API_URL_NUPY } from '@/toolbox/defaults/app';
import axios from 'axios';

export const BeneficioMontoRepository = {
    getBeneficioMonto: async(): Promise<any> => {
        const res = await axios.get(`${API_URL_NUPY}/puntos-monto/list`);
        return res.data;
    },

    register: async(data:{}): Promise<any> => {
        const res = await axios.post(`${API_URL_NUPY}/puntos-monto/register`, data);
        return res;
    },

    update: async(data:{}, idbeneficioMonto:number): Promise<any> => {
        const res = await axios.post(`${API_URL_NUPY}/puntos-monto/update/${idbeneficioMonto}`, data);
        return res;
    },

    deleteBeneficio: async(idbeneficioMonto:number): Promise<any> => {
        const res= await axios.delete(`${API_URL_NUPY}/puntos-monto/delete/${idbeneficioMonto}`)
        return res;
    }
}