import axios from 'axios';
import { API_URL_NUPY } from '@/toolbox/defaults/app';

export const salasRepository = {

    getSalas: async (): Promise<any> => {
        const res = await axios.get(`${API_URL_NUPY}/salas/get_salas`);
        return res.data;
    },

    getSalasByIdCompany: async (idCompany: number, idSucursal: number): Promise<any> => {
        const res = await axios.get(`${API_URL_NUPY}/salas/getSalasByIdCompany/${idCompany}/${idSucursal}`);
        return res.data;
    },

    getMesas: async (): Promise<any> => {
        const res = await axios.get(`${API_URL_NUPY}/mesas/get_mesas`);
        return res.data;
    },

    addSala: async (nombre: any, mesas: any, state: any, id_empresa: any, id_sucursal: number): Promise<any> => {
        const res = await axios.post(`${API_URL_NUPY}/salas/crear_sala`, { nombre, id_empresa, mesas, state, id_sucursal });
        return res.data;
    },

    deleteSala: async (id: any): Promise<any> => {
        const res = await axios.delete(`${API_URL_NUPY}/salas/delete_sala/${id}`);
        return res;
    },

    editSala: async (id: number, nombre: any, mesas: any, state: any): Promise<any> => {
        const res = await axios.put(`${API_URL_NUPY}/salas/editar_sala/${id}`, {
            nombre,
            mesas,
            state,
        });
        return res;
    },

    getMesa: async (id_sala: any): Promise<any> => {
        const res = await axios.get(`${API_URL_NUPY}/mesas/get_mesas_sala/${id_sala}`);
        return res.data;
    },

    addMesas: async (nombre: any, state: any, cantidad: any, id_sala: any, mesaUrl: string): Promise<any> => {
        const res = await axios.post(`${API_URL_NUPY}/mesas/crear_mesas`, { nombre, cantidad, state, id_sala, mesaUrl });
        return res;
    },

    addMesa: async (nombre: any, state: any, id_sala: any, mesaUrl: string): Promise<any> => {
        const res = await axios.post(`${API_URL_NUPY}/mesas/crear_mesa`, { nombre, id_sala, state, mesaUrl });
        return res.data;
    },

    deleteMesa: async (id: any): Promise<any> => {
        const res = await axios.delete(`${API_URL_NUPY}/mesas/delete_mesa/${id}`);
        return res;
    },

    editMesa: async (id: number, nombre: any, id_sala: any, state: any, mesaUrl: string): Promise<any> => {
        const res = await axios.put(`${API_URL_NUPY}/mesas/editar_mesa/${id}`, {
            nombre,
            id_sala,
            state,
            mesaUrl,
        });
        return res;
    },

    editMesaStatus: async (idMesa: number, state: string): Promise<any> => {
        const res = await axios.patch(`${API_URL_NUPY}/mesas/editar_mesa_status/${idMesa}/${state}`);
        return res.data;
    },

    editMesaReserva: async (idMesa: number, fecha: any): Promise<any> => {
        const res = await axios.patch(`${API_URL_NUPY}/mesas/reserva_mesa/${idMesa}`, {
            reserva: fecha ? fecha : null
        });
        return res.data;
    },

    disponibilizar: async (id_sala: any): Promise<any> => {
        const res = await axios.patch(`${API_URL_NUPY}/mesas/disponibilizar/${id_sala}`);
        return res.data;
    },

    createV2: async (payload: {}): Promise<any> => {
        const res = await axios.post(`${API_URL_NUPY}/salas/crear_sala_v2`, payload);
        return res.data;
    },

    updateV2: async (id: number, payload: {}): Promise<any> => {
        const res = await axios.patch(`${API_URL_NUPY}/salas/update_v2/${id}`, payload);
        return res.data;
    },
}
