import { Props } from "./Inventario.type";
import { Loading } from "../../components/common/Loading/Loading";
import { Protected } from "@/components/layout/Protected";
import { Box, Button, Container, createTheme, Divider, Grid, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, ThemeProvider, Typography } from "@mui/material";
import { Card, CardContent } from '@mui/material';
import { esES } from "@mui/material/locale";
import VisibilityIcon from '@mui/icons-material/Visibility';
import React, { useEffect } from "react";
import { productoService } from "@/service/services/Producto.service";
import { compraService } from "@/service/services/Compra.service";
import {KEY_EMPRESA} from '@constants/local-storage';
import {readLocalStorage} from '@helpers/local-storage-helper';
import { Popup } from "../Editor/components/Popup";
import { AddProductoS } from "../Productos/AddProductoS";
import ContentPasteOffIcon from '@mui/icons-material/ContentPasteOff';
import { useLocalStorage } from '@toolbox/hooks/local-storage.hook';
import  "./Inventario.sass";

const theme = createTheme(
    {
        palette: {
            primary: { main: '#1976D2' },
        },
    },
    esES,
);

export const Inventario: React.FC<Props> = (props: Props): JSX.Element => {

   const [dataEmpresa] = useLocalStorage(KEY_EMPRESA, {
      id: 0, id_tipo_empresa:0, equivalencia_puntos: 0,
      id_country: 0, country_name: "", country_abbreviation: "",
   });
    const [loading, setLoading] = React.useState<boolean>(false);
    const [productos, setProductos] = React.useState([]);
    const [selectedProducto, setSelectedProducto] = React.useState<any>('');

    const [openPopup, setOpenPopup] = React.useState<boolean>(false);
    const [popUp, setPopUp] = React.useState<String>('');
    const [editMode, setEditMode] = React.useState<boolean>(false);
    const [valoracion, setValoracion] = React.useState({
       ponderado: 0,
       fifo: 0,
    });

    const SessionEmpresa: any = readLocalStorage(KEY_EMPRESA);
    const idCompany = SessionEmpresa.id;

    useEffect(() => {
        getProductos();
        getValoracion();
    },  // eslint-disable-next-line
    []);

    const getProductos = async() => {
        setLoading(true);
        await productoService.getProductosSimplesCompany(idCompany).then((data) => {
            if(data.status === 200){
                setProductos(data.productos);
            }else{
                setProductos([]);
            }
            setLoading(false);
        })
    }

    const getValoracion = async() => {
      setLoading(true);
      await compraService.getValoracion(dataEmpresa.id).then((data) => {
          if(data.status === 200){
            setValoracion({
               ponderado: data?.ponderado || 0,
               fifo: data?.fifo || 0,
            });
          }else{
              setProductos([]);
          }
          setLoading(false);
      })
  }

    return (
        <Protected>
            {loading && <Loading/>}
            <Container maxWidth='xl' sx={{ mt: 1, mb: 2 }}>

            <Grid container justifyContent='space-between' spacing={2}>
                <Grid item>
                    <Typography variant='h4' >Inventario</Typography>
                </Grid>
            </Grid>

            <Divider sx={{ mt: 2 }} />
            <Typography variant='h6' >Valoración de inventario</Typography>
            <Grid container  className="container-card" style={{ marginBottom: '20px', marginTop:'30px', alignItems: 'center'}}>
               <Grid item xs={6} style={{ display: "flex", alignItems: 'center', justifyContent: "center"}}>
                  <Card className="card" style={{backgroundColor: "#F0F8FF"}}>
                     <CardContent>
                     <Typography sx={{ fontSize: 16, textAlign: 'center' }} color="text.secondary" gutterBottom>
                        {`${dataEmpresa.country_abbreviation} ${(valoracion.ponderado).toFixed(4)}`}
                     </Typography>
                     <Typography color="text.secondary" sx={{ mb: 1.5, textAlign: 'center'  }}>
                           Ponderado
                     </Typography>
                     </CardContent>
                  </Card>
               </Grid>
               <Grid item xs={6} style={{ display: "flex", alignItems: 'center', justifyContent: "center"}}>
                  <Card className="card" style={{backgroundColor: "#F0F8FF"}}>
                     <CardContent>
                     <Typography sx={{ fontSize: 16, textAlign: 'center' }} color="text.secondary" gutterBottom>
                        {`${dataEmpresa.country_abbreviation} ${valoracion.fifo}`}
                     </Typography>
                     <Typography color="text.secondary" sx={{ mb: 1.5, textAlign: 'center'  }}>
                     FIFO
                     </Typography>
                     </CardContent>
                  </Card>
               </Grid>
               {/* <Grid item xs={6} style={{ display: "flex", alignItems: 'center', justifyContent: "center"}}>
                  <Card className="card"  style={{backgroundColor: "#F0F8FF"}}>
                     <CardContent>
                     <Typography sx={{ fontSize: 16, textAlign: 'center'  }} color="text.secondary" gutterBottom>
                        "HOLAAAAAAAA"
                     </Typography>
                     <Typography sx={{ mb: 1.5, textAlign: 'center'  }} color="text.secondary">
                        Total Ventas
                     </Typography>
                     </CardContent>
                  </Card>
               </Grid> */}
            </Grid>
            <Divider sx={{ mt: 2 }} />

            <Grid container spacing={1} >
                <Grid item xs={12}>
                    <Typography variant="body2" sx={{ mt: '20px' }} >
                        {
                            (productos.length)  ? `${productos.length} registros encontrados`
                            : `0` && !loading
                        }
                    </Typography>
                </Grid>
            </Grid>

            <Grid>
                {
                !loading && productos.length > 0 && (
                    <Box sx={{Width: '100%', marginTop:'30px'}}>
                        <TableContainer component={Paper}>
                        <ThemeProvider theme={theme}>
                        <Table aria-label="simple table" size='small'>
                            <TableHead>
                                <TableRow  sx={{ bgcolor:'#1976D2'}}>
                                    <TableCell sx={{ color: 'white' }} >
                                        <strong>N°</strong>
                                    </TableCell>
                                    <TableCell align='center' sx={{ color: 'white' }} >
                                        <strong>Código</strong>
                                    </TableCell>
                                    <TableCell align='center' sx={{ color: 'white' }} >
                                        <strong>Nombre</strong>
                                    </TableCell>
                                    <TableCell align='center' sx={{ color: 'white' }} >
                                        <strong>Stock</strong>
                                    </TableCell>
                                    <TableCell align='center' sx={{ color: 'white' }} >
                                        <strong>Stock Mínimo Requerido</strong>
                                    </TableCell>
                                    <TableCell align='center' sx={{ color: 'white' }} >
                                        <strong>Costro de compra</strong>
                                    </TableCell>
                                    <TableCell align='center' sx={{ color: 'white' , minWidth:'8rem', maxWidth:'12rem'}}><strong>Acciones</strong></TableCell>
                                </TableRow>
                            </TableHead>

                            <TableBody>
                                {
                                    productos.map((producto, id) =>(
                                        <TableRow key={id}>
                                            <TableCell component="th" scope="row">
                                                {id+1}
                                            </TableCell>
                                            <TableCell align='center' component="th" scope="row">
                                                {producto.codigo_erp}
                                            </TableCell>
                                            <TableCell component="th" scope="row" align='center'>
                                                {producto.nombre.toLowerCase()}
                                            </TableCell>
                                            <TableCell align='center' component="th" scope="row" className={ producto.stock < 1 ? 'textRed' : 'textBlack'}>
                                                {producto.stock < 1 ? "Sin Stock" : producto.stock} {`(${producto?.unidad_medida?.abbreviation})`}
                                            </TableCell>
                                            <TableCell align='center' component="th" scope="row">
                                               { !producto.stock_minimo ? "Sin Stock minimo requerido" : producto.stock_minimo}
                                            </TableCell>
                                            <TableCell align='center' component="th" scope="row">
                                               { !producto.precio_compra ? "Sin costo" : `${dataEmpresa.country_abbreviation} ${producto.precio_compra}`}
                                            </TableCell>
                                            <TableCell align='center'>
                                                <Button
                                                    onClick={() =>  {
                                                        setOpenPopup(true);
                                                        setEditMode(false);
                                                        setPopUp('detailProduct');
                                                        setSelectedProducto(producto);
                                                    }}
                                                >
                                                    <VisibilityIcon/>
                                                </Button>
                                            </TableCell>
                                        </TableRow>
                                    ))
                                }
                            </TableBody>
                        </Table>
                        </ThemeProvider>
                        </TableContainer>
                    </Box>
                )
                }
                {
                    !loading && productos.length === 0 &&
                        <Grid container justifyContent="center">
                            <Box sx={{marginTop: '50px'}}>
                            <Grid container justifyContent="center"> <ContentPasteOffIcon className="iconocarrito"/></Grid>
                                <Box sx={{Width: '100%', marginBottom:'30px', textAlign:'center'}}>No hay Productos registrados</Box>
                            </Box>
                        </Grid>

                }
                {loading &&
                    <Box sx={{backgroundColor: 'rgba(224, 224, 224, 0.5)', padding: '15px', borderRadius: '10px', height:'100px'}}>
                    </Box>
                }
            </Grid>

            </Container>
            {
                popUp === 'detailProduct' && (
                    <Popup
                    largo="sm"
                    title = 'Detalle del Producto'
                    openPopup = {openPopup}
                    setOpenPopup = {setOpenPopup}
                    >
                        <AddProductoS
                            closeModal={() => {setOpenPopup(false)}}
                            editMode = {editMode}
                            producto={selectedProducto}
                        />
                    </Popup>

                )
            }
        </Protected>
    )
}
