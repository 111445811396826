import { Box, createTheme, Grid, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, ThemeProvider } from "@mui/material";
import { esES } from "@mui/material/locale";

const theme = createTheme( {
        palette: {  primary: { main: '#1976D2' }, },
    },
    esES,
);


export const ProductosSimples = (props) => {
    const {productSimple} = props;

    return (
        <Grid>
            <Box sx={{Width: '100%', marginTop:'0px'}}>
            <TableContainer component={Paper}>
            <ThemeProvider theme={theme}>
            <Table aria-label="simple table" size='small'>
                <TableHead>
                    <TableRow  sx={{ bgcolor:'#1976D2'}}>
                        <TableCell sx={{ color: 'white' }} >
                            <strong>N°</strong>
                        </TableCell>
                        <TableCell align='center' sx={{ color: 'white' }} >
                            <strong>Código</strong>
                        </TableCell>
                        <TableCell align='center' sx={{ color: 'white' }} >
                            <strong>Nombre</strong>
                        </TableCell>
                        <TableCell align='center' sx={{ color: 'white' }} >
                            <strong>Precio</strong>
                        </TableCell>
                        <TableCell align='center' sx={{ color: 'white' }} >
                            <strong>Cantidad</strong>
                        </TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {
                        productSimple && productSimple.map((item, id) => (
                            <TableRow key={id}>
                                <TableCell component="th" scope="row">
                                    {id+1}
                                </TableCell>
                                <TableCell align='center' component="th" scope="row">
                                    {item?.codigo_erp}
                                </TableCell>
                                <TableCell align='center' component="th" scope="row">
                                    {item?.nombre}
                                </TableCell>
                                <TableCell align='center' component="th" scope="row">
                                    {item?.precio_standar}
                                </TableCell>
                                <TableCell align='center' component="th" scope="row">
                                    {item?.cantidad}
                                </TableCell>
                            </TableRow>
                        ))
                    }
                </TableBody>
            </Table>
            </ThemeProvider>
            </TableContainer>
            </Box>
        </Grid>
    );
}
