import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { Divider } from '@mui/material';
import { beneficioProductoService } from "@/service/services/BeneficioProducto.service";

export const DeleteModalProducto: React.FC<any> = (props:any): JSX.Element => {
    const { open, closeModal, beneficioProducto, onListBeneficioProducto } = props;

    const onDelete = async() => {
        await beneficioProductoService.deleteBeneficio(beneficioProducto.id).then((data) => {
            if(data.status === 200){
                /* window.location.reload(); */
                onListBeneficioProducto && onListBeneficioProducto();
                closeModal && closeModal();
            }
        })
    }


    return (
        <div>
            <Dialog
                open={open}
                onClose={closeModal}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                {"Eliminar Beneficio Producto"}
                </DialogTitle>
                <Divider/>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                    ¿Está seguro de eliminar la oferta actual?
                    </DialogContentText>
                </DialogContent>
                <Divider/>
                <DialogActions>
                <Button onClick={closeModal}>Cancelar</Button>
                <Button onClick={onDelete} autoFocus>
                    Aceptar
                </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}