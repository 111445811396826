import React, { FunctionComponent, useState, } from 'react';
import { Protected } from '@/components/layout/Protected';
import { PopupComponent } from '@/components/common/Popup';
import { Loading } from '../../components/common/Loading/Loading';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import TableHead from '@mui/material/TableHead';
import Tooltip from '@mui/material/Tooltip';
import { PencilIcon } from "@toolbox/constants/icons";
import VisibilityIcon from '@mui/icons-material/Visibility';
import { STATUS_PENDIENTE, STATUS_POR_ENTREGAR, STATUS_EMTREGADO, STATUS_FINALIZADO } from '@constants/status-ticket';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DatePicker from '@mui/lab/DatePicker';
import Grid from '@mui/material/Grid';
import esLocale from 'date-fns/locale/es';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import IconButton from '@mui/material/IconButton';
import { ticketService } from '../../service/services/Ticket.service';
import { KEY_EMPRESA } from "@/toolbox/constants/local-storage";
import { useLocalStorage } from '@toolbox/hooks/local-storage.hook';
import { ID_TYPE_RESTAURANTE_SOLO_ENTREGA } from '@constants/type-entity';
import moment from 'moment';
import { styled } from '@mui/material/styles';
import { PedidoDetail } from './PopupPedido';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import { SendAndArchive } from '@mui/icons-material';
import { KEY_EDIT_PEDIDO } from '@constants/local-storage';
import { saveLocalStorage } from '@helpers/local-storage-helper';
import { Link, useHistory } from 'react-router-dom';
import { ROUTE_HOME, ROUTE_PEDIDOS_PRODUCTOS } from "@/toolbox/constants/route-map";
import { Alert, Button, Divider, Snackbar, Typography } from '@mui/material';
import CancelScheduleSendIcon from '@mui/icons-material/CancelScheduleSend';
import TableRestaurantIcon from '@mui/icons-material/TableRestaurant';
import { ModalListTable } from './ModalListTable';
import UploadIcon from '@mui/icons-material/Upload';
import DownloadIcon from '@mui/icons-material/Download';
import { Popup } from '../Editor/components/Popup';
import { moneyFormatInt } from '@/toolbox/helpers/money.helper';
import { ComandaService } from '@/service/services/Comanda.service';
import PrintIcon from '@mui/icons-material/Print';

const Div = styled('div')(({ theme }) => ({
   ...theme.typography.button,
   padding: theme.spacing(1),
   fontWeight: 'bold',
   fontSize: '20px',
   color: 'rgba(102, 102, 102, 0.4)',
}));

const Titulo = styled('div')(({ theme }) => ({
   ...theme.typography.button,
   padding: theme.spacing(1),
   fontWeight: 'bold',
   fontSize: '20px',
   color: 'black',
}));

export const Pedidos: FunctionComponent = (props: any) => {
   // const [card_shop] = useLocalStorage(PRODUCTS_CARD, []);
   const history = useHistory();
   const [pedidosPendientes, setPedidosPendientes] = React.useState<Array<any>>([]);
   const [filterPedidosPendientes, setfilterPedidosPendientes] = React.useState<Array<any>>([]);

   const [pedidosFinalizados, setPedidosFinalizados] = React.useState([]);
   const [filterPedidosFinalizados, setfilterPedidosFinalizados] = React.useState<Array<any>>([]);

   const [pedidosPorEntregar, setPedidosPorEntregar] = React.useState([]);
   const [filterPedidosPorEntregar, setfilterPedidosPorEntregar] = React.useState<Array<any>>([]);

   const [pedidosEntregados, setPedidosEntregados] = React.useState([]);
   const [filterPedidosEntregados, setfilterPedidosEntregados] = React.useState<Array<any>>([]);

   const [fecha, setFecha] = React.useState(new Date());
   const [loadData, setLoadData] = React.useState<boolean>(false);
   const [openPopup, setOpenPopup] = React.useState(false);
   const [showModalTable, setShowModalTable] = React.useState(false);
   const [dataEmpresa] = useLocalStorage(KEY_EMPRESA, {
      id: 0, id_tipo_empresa: 0, mesas: 0
   });
   const [pedidoSelected, setPedidoSelect] = React.useState<number>(0);
   const [textSearch, setTextSearch] = React.useState<string>("");
   const [snackBarConfig, setSnackBarConfig] = useState<any>({
      open: false,
      severity: 'error',
      message: 'Error',
      autoHideDuration: 3000,
   })

   React.useEffect(() => {
      cargarPedidosPorFecha(fecha);
   },// eslint-disable-next-line
      []);

   const handleSearch = (e) => {
      let text = `${e.target.value}`;
      let name = `${e.target.name}`;
      console.log(name)

      if (text) {
         let filterPedientes = []
         let filterPorEntregar = []
         let filterEntregados = []
         let filterFinalizados = []

         if (name == 'pendientes') {
            filterPedientes = pedidosPendientes.filter(pendiente => (
               pendiente?.pedidoDetail?.cliente?.nombre.toLowerCase().includes(text.toLowerCase()) ||
               pendiente?.n_ticket.toLowerCase().includes(text.toLowerCase()) ||
               pendiente.mesa?.nombre.toLowerCase().includes(text.toLowerCase())
            ));
            setfilterPedidosPendientes([...filterPedientes]);
         }

         if (name == 'porEntregar') {
            filterPorEntregar = pedidosPorEntregar.filter(pendiente => (
               pendiente?.pedidoDetail?.cliente?.nombre.toLowerCase().includes(text.toLowerCase()) ||
               pendiente?.n_ticket.toLowerCase().includes(text.toLowerCase()) ||
               pendiente.mesa?.nombre.toLowerCase().includes(text.toLowerCase())
            ));
            setfilterPedidosPorEntregar([...filterPorEntregar]);
         }

         if (name == 'entregados') {
            filterEntregados = pedidosEntregados.filter(pendiente => (
               pendiente?.pedidoDetail?.cliente?.nombre.toLowerCase().includes(text.toLowerCase()) ||
               pendiente?.n_ticket.toLowerCase().includes(text.toLowerCase()) ||
               pendiente.mesa?.nombre.toLowerCase().includes(text.toLowerCase())
            ));
            setfilterPedidosEntregados([...filterEntregados]);
         }

         if (name == 'finalizados') {
            filterFinalizados = pedidosFinalizados.filter(pendiente => (
               pendiente?.pedidoDetail?.cliente?.nombre.toLowerCase().includes(text.toLowerCase()) ||
               pendiente?.n_ticket.toLowerCase().includes(text.toLowerCase()) ||
               pendiente.mesa?.nombre.toLowerCase().includes(text.toLowerCase())
            ));
            setfilterPedidosFinalizados([...filterFinalizados]);
         }

      }
      if (!text) {
         setfilterPedidosPendientes([...pedidosPendientes])
         setfilterPedidosFinalizados([...pedidosFinalizados]);
         setfilterPedidosEntregados([...pedidosEntregados]);
         setfilterPedidosPorEntregar([...pedidosPorEntregar]);
      }
   }


   const cargarPedidosPorFecha = async (newValue) => {
      setLoadData(true);
      const formatFecha = moment(newValue).format('DD-MM-YYYY');

      // if (dataEmpresa.id_tipo_empresa === ID_TYPE_RESTAURANTE) {
      //    await ticketService.getTicketsFecha(STATUS_PENDIENTE,formatFecha).then((data)=>{
      //       setPedidosPendientes(data.ticket);
      //       setfilterPedidosPendientes(data.ticket);
      //    });
      //    await ticketService.getTicketsFecha(STATUS_POR_ENTREGAR,formatFecha).then((data)=>{
      //       setPedidosPorEntregar(data.ticket);
      //       setfilterPedidosPorEntregar(data.ticket);
      //    });
      //    await ticketService.getTicketsFecha(STATUS_EMTREGADO,formatFecha).then((data)=>{
      //       setPedidosEntregados(data.ticket);
      //       setfilterPedidosEntregados(data.ticket);
      //    });
      // }
      // if (dataEmpresa.id_tipo_empresa === ID_TYPE_RESTAURANTE_SOLO_ENTREGA) {
      //    await ticketService.getTicketsFecha(STATUS_POR_ENTREGAR,formatFecha).then((data)=>{
      //       setPedidosPorEntregar(data.ticket);
      //       setfilterPedidosPorEntregar(data.ticket);
      //    });
      // }
      // if (dataEmpresa.id_tipo_empresa === ID_TYPE_BODEGA) {
      //    await ticketService.getTicketsFecha(STATUS_FINALIZADO,formatFecha).then((data)=>{
      //       setPedidosFinalizados(data.ticket);
      //       setfilterPedidosFinalizados(data.ticket);
      //    });
      // }
      await ticketService.getTicketsFecha(STATUS_PENDIENTE, formatFecha).then((data) => {
         setPedidosPendientes(data.ticket);
         setfilterPedidosPendientes(data.ticket);
      });
      await ticketService.getTicketsFecha(STATUS_POR_ENTREGAR, formatFecha).then((data) => {
         setPedidosPorEntregar(data.ticket);
         setfilterPedidosPorEntregar(data.ticket);
      });
      await ticketService.getTicketsFecha(STATUS_EMTREGADO, formatFecha).then((data) => {
         setPedidosEntregados(data.ticket);
         setfilterPedidosEntregados(data.ticket);
      });
      await ticketService.getTicketsFecha(STATUS_FINALIZADO, formatFecha).then((data) => {
         setPedidosFinalizados(data.ticket);
         setfilterPedidosFinalizados(data.ticket);
      });
      setLoadData(false);
   }

   const handleEntregarPedido = async (idTicket: number, estado: string, idPedido: number) => {
      if (idTicket) {
         setLoadData(true);
         if (estado === STATUS_PENDIENTE) await ticketService.editPedidoStatus(idTicket, STATUS_POR_ENTREGAR, idPedido);
         if (estado === STATUS_POR_ENTREGAR) {
            if (dataEmpresa.id_tipo_empresa === ID_TYPE_RESTAURANTE_SOLO_ENTREGA) {
               await ticketService.editPedidoStatus(idTicket, STATUS_EMTREGADO, idPedido);
               await ticketService.editPedidoStatus(idTicket, STATUS_FINALIZADO, idPedido);
            } else {
               await ticketService.editPedidoStatus(idTicket, STATUS_EMTREGADO, idPedido);
            }
         }
         await cargarPedidosPorFecha(fecha);
         setLoadData(false);
      }
   }

   const backStatePedido = async (idTicket: number, estado: string) => {
      if (idTicket) {
         setLoadData(true);
         if (estado === STATUS_POR_ENTREGAR) await ticketService.backState(idTicket, STATUS_PENDIENTE);
         if (estado === STATUS_EMTREGADO) await ticketService.backState(idTicket, STATUS_POR_ENTREGAR);
         await cargarPedidosPorFecha(fecha);
         setLoadData(false);
      }
   }

   const handleEditarPedido = async (idTicket: number) => {
      if (idTicket) {
         setLoadData(true);
         ticketService.getTicketDetalle(idTicket).then(async (data) => {
            if (data?.status === 200) {
               /* for(let i = 0; i < data.detalleTicket.length; i++){
                  await editor.getProductoById(data.detalleTicket[i].id_producto).then((producto)=>{
                     const prod = producto.data.productos;
                     console.log(prod);
                     if (prod) {
                        data.detalleTicket[i] = {
                           ...data.detalleTicket[i],
                           "codigo_erp": prod[0]?.codigo_erp,
                           "nombre": prod[0]?.nombre,
                           "id_tipo_producto": prod[0]?.id_tipo_producto,
                           "precio_standar": prod[0]?.precio_standar,
                           "precio_oferta": prod[0]?.precio_oferta,
                           "stock": prod[0]?.stock,
                        }
                     }
                  });
               }
               saveLocalStorage(PRODUCTS_CARD, [...data.detalleTicket]); */
               saveLocalStorage(KEY_EDIT_PEDIDO, (data?.ticket || []));
               setLoadData(false);
               history.push(ROUTE_HOME);
            } else {
               setLoadData(false);
            }
         })
      }
   }

   const showComandaPDF = async (idcomanda: number) => {
      try {
         const pdf = await ComandaService.getPDF(idcomanda);
         const blob = new Blob([pdf], { type: 'application/pdf' });
         const blobURL = URL.createObjectURL(blob);
         window.open(blobURL, '_blank');
      } catch (error) {
         console.error('Error al mostrar el PDF:', error);
      }
   }

   const imprimirPDF = async (idticket: number) => {
      try {
         const response = await ComandaService.imprimir(idticket)
         if (response?.status !== 400) {
            return setSnackBarConfig(prev => ({
               ...prev,
               open: true,
               message: response?.message || 'Error al imprimir.',
            }))
         }
      } catch (error) {
         console.error('Error al imprimir el PDF:', error);
      }
   }


   const mostrarPedidos = (type: 'pendiente' | 'finalizado' | 'entregado' | 'por-entregar') => {
      let array = [];
      if (type === STATUS_PENDIENTE) {
         array = filterPedidosPendientes;
      } else if (type === STATUS_POR_ENTREGAR) {
         array = filterPedidosPorEntregar;
      } else if (type === STATUS_FINALIZADO) {
         array = filterPedidosFinalizados;
      } else {
         array = filterPedidosEntregados;
      }
      return (
         <TableContainer component={Paper}>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
               <TableHead>
                  <TableRow>
                     <TableCell>{`N°`}</TableCell>
                     <TableCell>Cliente</TableCell>
                     {
                        dataEmpresa && dataEmpresa.mesas === 1 && (
                           <TableCell align="left">Mesa</TableCell>
                        )
                     }
                     <TableCell align="left">Usuario</TableCell>
                     <TableCell align="left">N° ticket</TableCell>
                     <TableCell align="left">Monto total&nbsp;</TableCell>
                     <TableCell align="center">Detalle&nbsp;</TableCell>
                  </TableRow>
               </TableHead>
               <TableBody>
                  {array.map((item, index) => (
                     <TableRow
                        key={item.id}
                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                        classes={{ hover: 'hover-row-t' }}
                     >
                        <TableCell component="th" scope="row">
                           {index + 1}
                        </TableCell>

                        <TableCell >{item.pedidoDetail?.cliente?.nombre || 'Cliente General'}</TableCell>
                        {
                           dataEmpresa && dataEmpresa.mesas === 1 && (
                              <TableCell align="left" sx={{ width: '210px' }}>
                                 {item.pedidoDetail?.mesa?.nombre || '---'}
                                 {
                                    (dataEmpresa.id_tipo_empresa === 2 && type !== STATUS_FINALIZADO) && (
                                       <IconButton aria-label="add to shopping cart" color="secondary" sx={{ justifyContent: 'end' }}
                                          onClick={() => {
                                             setShowModalTable(true);
                                             setPedidoSelect(item.pedidoDetail?.id);
                                          }}
                                       >
                                          <TableRestaurantIcon />
                                       </IconButton>
                                    )
                                 }
                              </TableCell>
                           )
                        }
                        <TableCell align="left">{item.pedidoDetail?.usuario?.nombre}</TableCell>
                        <TableCell align="left">{item.n_ticket}</TableCell>
                        <TableCell align="left">{moneyFormatInt(item.monto_pedido)}</TableCell>
                        <TableCell align="center" sx={{ width: '200px' }}>
                           <IconButton aria-label="add to shopping cart" color="secondary" sx={{ marginRight: '5px' }}
                              onClick={() => {
                                 setOpenPopup(true);
                                 setPedidoSelect(item.id);
                              }}
                           >
                              <VisibilityIcon />
                           </IconButton>
                           {
                              type !== STATUS_FINALIZADO &&
                              <Tooltip title="Editar">
                                 <IconButton aria-label="add to shopping cart" color="secondary" sx={{ marginRight: '5px' }}
                                    onClick={() => {
                                       handleEditarPedido(item.id || 0)
                                    }}
                                 >
                                    <PencilIcon />
                                 </IconButton>
                              </Tooltip>
                           }
                           {
                              (type === STATUS_PENDIENTE || type === STATUS_POR_ENTREGAR) &&

                              <Tooltip title="Entregar">
                                 <IconButton aria-label="add to shopping cart" color="secondary" sx={{ marginRight: '5px' }}
                                    onClick={() => {
                                       (handleEntregarPedido(item.id || 0, type, item.pedidoDetail?.id))
                                    }}
                                 >
                                    {/* <SendAndArchive/> */}
                                    <DownloadIcon />
                                 </IconButton>
                              </Tooltip>

                           }
                           {
                              (type === STATUS_POR_ENTREGAR || type === STATUS_EMTREGADO) &&

                              <Tooltip title="Regresar">
                                 <IconButton aria-label="add to shopping cart" color="error" sx={{ marginRight: '5px' }}
                                    onClick={() => {
                                       (backStatePedido(item.id || 0, type))
                                    }}
                                 >
                                    {/* <CancelScheduleSendIcon/> */}
                                    <UploadIcon />
                                 </IconButton>
                              </Tooltip>
                           }
                           {
                              !!item.comandas.length && (
                                 <Tooltip title={"Ver comandas"}>
                                    <IconButton onClick={() => showComandaPDF(item.id)}>
                                       <PictureAsPdfIcon fontSize="small" />
                                    </IconButton>
                                 </Tooltip>
                              )
                           }
                           {
                              !!item.comandas.length && (
                                 <Tooltip title={"Imprimir"}>
                                    <IconButton onClick={() => imprimirPDF(item.id)}>
                                       <PrintIcon fontSize="small" />
                                    </IconButton>
                                 </Tooltip>
                              )
                           }
                        </TableCell>
                     </TableRow>
                  ))}
               </TableBody>
            </Table>
         </TableContainer>
      )
   }

   return (
      <Protected>
         {loadData && <Loading />}
         <Box sx={{ marginLeft: '10px', marginRight: '10px', marginBottom: '20px' }}>

            <Grid sx={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>

               <Button variant='outlined'
                  component={Link} to={ROUTE_PEDIDOS_PRODUCTOS}
               >Vista Cocina</Button>

               <Grid sx={{ display: "flex", alignItems: "center" }}>
                  <Box sx={{ marginBottom: '10px', marginRight: '15px' }}>
                     {/* <Titulo>{"Fecha de visualización:"}</Titulo> */}
                     <span>{"Fecha de visualización:"}</span>
                  </Box>
                  <Box >
                     <LocalizationProvider dateAdapter={AdapterDateFns} locale={esLocale} >
                        <DatePicker
                           PopperProps={{ placement: "auto-start" }}
                           views={['day', 'month', 'year']}
                           value={fecha}
                           minDate={new Date('2020-01-01')}
                           maxDate={new Date('2030-01-01')}
                           onChange={(newValue) => {
                              setFecha(newValue);
                              cargarPedidosPorFecha(newValue);
                           }}
                           renderInput={(params) => <TextField {...params} />}
                        />
                     </LocalizationProvider>
                  </Box>
               </Grid>
            </Grid>

            <Divider sx={{ mt: 2, mb: 2 }} />

            {/* { dataEmpresa.id_tipo_empresa === ID_TYPE_RESTAURANTE && */}
            <>
               <Grid
                  container spacing={0} direction="column"
                  alignItems="center" justifyContent="center"
                  style={{ marginBottom: '20px', marginTop: '30px' }}
               >
                  <Grid item xs={3}>
                     <Titulo>{"Pedidos Pendientes"}</Titulo>
                  </Grid>

               </Grid>
               {!loadData && (pedidosPendientes || []).length !== 0 &&
                  <Box sx={{ backgroundColor: 'rgba(224, 224, 224, 0.5)', padding: '15px', borderRadius: '10px' }}>
                     <Grid sx={{ display: "flex", alignItems: "center", marginBottom: '10px' }}>
                        <Box sx={{ marginBottom: '10px', marginRight: '5px' }}>
                           <span>{"Buscar pedido:"}</span>
                        </Box>
                        <TextField
                           type="text"
                           id="pendientes"
                           name="pendientes"
                           placeholder="Escriba para buscar..."
                           onChange={handleSearch}
                           className="H-card__fcontainer__filter__input"
                           style={{ backgroundColor: "white", width: "290px", marginLeft: "10px" }}
                        />
                     </Grid>
                     {mostrarPedidos(STATUS_PENDIENTE)}
                  </Box>
               }
               {!loadData && (pedidosPendientes || []).length === 0 &&
                  <Box sx={{
                     backgroundColor: 'rgba(224, 224, 224, 0.5)', padding: '15px', borderRadius: '10px', height: '100px', justifyContent: 'center',
                     alignItems: 'center', display: 'flex'
                  }} >
                     <Div>{"SIN PEDIDOS ENCONTRADOS"}</Div>
                  </Box>
               }
               {loadData &&
                  <Box sx={{ backgroundColor: 'rgba(224, 224, 224, 0.5)', padding: '15px', borderRadius: '10px', height: '100px' }}>
                  </Box>
               }
            </>
            {/* } */}

            {/* { (dataEmpresa.id_tipo_empresa === ID_TYPE_RESTAURANTE || dataEmpresa.id_tipo_empresa === ID_TYPE_RESTAURANTE_SOLO_ENTREGA) && */}
            <>
               <Grid
                  container spacing={0} direction="column"
                  alignItems="center" justifyContent="center"
                  style={{ marginBottom: '20px', marginTop: '30px' }}
               >
                  <Grid item xs={3}>
                     <Titulo>{"Pedidos por Entregar"}</Titulo>
                  </Grid>

               </Grid>
               {!loadData && (pedidosPorEntregar || []).length !== 0 &&
                  <Box sx={{ backgroundColor: 'rgba(224, 224, 224, 0.5)', padding: '15px', borderRadius: '10px' }}>
                     <Grid sx={{ display: "flex", alignItems: "center", marginBottom: '10px' }}>
                        <Box sx={{ marginBottom: '10px', marginRight: '5px' }}>
                           <span>{"Buscar pedido:"}</span>
                        </Box>
                        <TextField
                           type="text"
                           id="porEntregar"
                           name="porEntregar"
                           placeholder="Escriba para buscar..."
                           onChange={handleSearch}
                           className="H-card__fcontainer__filter__input"
                           style={{ backgroundColor: "white", width: "290px", marginLeft: "10px" }}
                        />
                     </Grid>
                     {mostrarPedidos(STATUS_POR_ENTREGAR)}
                  </Box>
               }
               {!loadData && (pedidosPorEntregar || []).length === 0 &&
                  <Box sx={{
                     backgroundColor: 'rgba(224, 224, 224, 0.5)', padding: '15px', borderRadius: '10px', height: '100px', justifyContent: 'center',
                     alignItems: 'center', display: 'flex'
                  }} >
                     <Div>{"SIN PEDIDOS ENCONTRADOS"}</Div>
                  </Box>
               }
               {loadData &&
                  <Box sx={{ backgroundColor: 'rgba(224, 224, 224, 0.5)', padding: '15px', borderRadius: '10px', height: '100px' }}>
                  </Box>
               }
            </>
            {/* } */}

            {/* { dataEmpresa.id_tipo_empresa === ID_TYPE_RESTAURANTE && */}
            <>
               <Grid
                  container spacing={0} direction="column"
                  alignItems="center" justifyContent="center"
                  style={{ marginBottom: '20px', marginTop: '30px' }}
               >
                  <Grid item xs={3}>
                     <Titulo>{"Pedidos Entregados"}</Titulo>
                  </Grid>

               </Grid>
               {!loadData && (pedidosEntregados || []).length !== 0 &&
                  <Box sx={{ backgroundColor: 'rgba(224, 224, 224, 0.5)', padding: '15px', borderRadius: '10px' }}>
                     <Grid sx={{ display: "flex", alignItems: "center", marginBottom: '10px' }}>
                        <Box sx={{ marginBottom: '10px', marginRight: '5px' }}>
                           <span>{"Buscar pedido:"}</span>
                        </Box>
                        <TextField
                           type="text"
                           id="entregados"
                           name="entregados"
                           placeholder="Escriba para buscar..."
                           onChange={handleSearch}
                           className="H-card__fcontainer__filter__input"
                           style={{ backgroundColor: "white", width: "290px", marginLeft: "10px" }}
                        />
                     </Grid>
                     {mostrarPedidos(STATUS_EMTREGADO)}
                  </Box>
               }
               {!loadData && (pedidosEntregados || []).length === 0 &&
                  <Box sx={{
                     backgroundColor: 'rgba(224, 224, 224, 0.5)', padding: '15px', borderRadius: '10px', height: '100px', justifyContent: 'center',
                     alignItems: 'center', display: 'flex'
                  }} >
                     <Div>{"SIN PEDIDOS ENCONTRADOS"}</Div>
                  </Box>
               }
               {loadData &&
                  <Box sx={{ backgroundColor: 'rgba(224, 224, 224, 0.5)', padding: '15px', borderRadius: '10px', height: '100px' }}>
                  </Box>
               }
            </>
            {/* } */}

            {/* { dataEmpresa.id_tipo_empresa === ID_TYPE_BODEGA && */}
            {/* <>
               <Grid
                  container spacing={0} direction="column"
                  alignItems="center" justifyContent="center"
                  style={{ marginBottom: '20px', marginTop: '30px' }}
               >
                  <Grid item xs={3}>
                     <Titulo>{"Pedidos finalizados"}</Titulo>
                  </Grid>
               </Grid>
               {!loadData && (pedidosFinalizados || []).length !== 0 &&
                  <Box sx={{ backgroundColor: 'rgba(224, 224, 224, 0.5)', padding: '15px', borderRadius: '10px' }}>
                     <Grid sx={{ display: "flex", alignItems: "center", marginBottom: '10px' }}>
                        <Box sx={{ marginBottom: '10px', marginRight: '5px' }}>
                           <span>{"Buscar pedido:"}</span>
                        </Box>
                        <TextField
                           type="text"
                           id="finalizados"
                           name="finalizados"
                           placeholder="Escriba para buscar..."
                           onChange={handleSearch}
                           className="H-card__fcontainer__filter__input"
                           style={{ backgroundColor: "white", width: "290px", marginLeft: "10px" }}
                        />
                     </Grid>
                     {mostrarPedidos(STATUS_FINALIZADO)}
                  </Box>
               }
               {!loadData && (pedidosFinalizados || []).length === 0 &&
                  <Box sx={{
                     backgroundColor: 'rgba(224, 224, 224, 0.5)', padding: '15px', borderRadius: '10px', height: '100px', justifyContent: 'center',
                     alignItems: 'center', display: 'flex'
                  }} >
                     <Div>{"SIN PEDIDOS ENCONTRADOS"}</Div>
                  </Box>
               }
               {loadData &&
                  <Box sx={{ backgroundColor: 'rgba(224, 224, 224, 0.5)', padding: '15px', borderRadius: '10px', height: '100px' }}>
                  </Box>
               }
            </> */}
            {/* } */}

         </Box>
         {openPopup &&
            <PopupComponent
               largo="md"
               title={`Detalle del pedido`}
               openPopup={openPopup}
               setOpenPopup={setOpenPopup}
            >
               <PedidoDetail idTicket={pedidoSelected} />
            </PopupComponent>
         }
         {showModalTable &&
            <PopupComponent
               largo="lg"
               title={`Mesas Disponibles`}
               openPopup={showModalTable}
               setOpenPopup={setShowModalTable}
            >
               <ModalListTable
                  idPedido={pedidoSelected}
                  loadPedidos={cargarPedidosPorFecha}
                  closeModal={() => { setShowModalTable(false) }}
               />
            </PopupComponent>
         }
         {
            <Snackbar
               open={snackBarConfig.open}
               autoHideDuration={snackBarConfig.autoHideDuration}
               onClose={() => setSnackBarConfig(prev => ({ ...prev, open: false }))}
            >
               <Alert
                  onClose={() => setSnackBarConfig(prev => ({ ...prev, open: false }))}
                  severity={snackBarConfig.severity}
                  variant="filled"
               >
                  {snackBarConfig.message}
               </Alert>
            </Snackbar>
         }
      </Protected>
   )
}
