import { notaCreditoRepository } from "../repositories/NotaCredito.repository";

export const notaCreditoService = {
    anularVentaTotal,
    anularVentaParcial,
}

async function anularVentaTotal (idPedido: number) {
    const res = await notaCreditoRepository.anularVentaTotal(idPedido);
    return res;
 }

async function anularVentaParcial (idPedidoAnulado: any, data: any, idVentaNueva: any) {
const res = await notaCreditoRepository.anularVentaParcial(idPedidoAnulado, data, idVentaNueva);
return res;
}
