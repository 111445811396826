import { Props } from "./ClienteEcommerce.type";
import { Button, Card, CardActions, CardContent, CardMedia, Container, Grid, Typography } from "@mui/material";
import { HeaderEcommerce } from "./components/Header/HeaderEcommerce";
import { useEffect, useState } from "react";
import { Loading } from "@/components/common/Loading";
import { servicioService } from "@/service/services/Servicio.service";
import { useHistory } from 'react-router-dom';
import { ROUTE_SHOP_ECOMMERCE_COMPANIES } from '@/toolbox/constants/route-map';
import './viewHome.css';

import Slider from "react-slick";
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";

export const ClienteEcommerce: React.FC<Props> = (props:Props):JSX.Element => {

    const [loading, setLoading] = useState<boolean>(false);
    const [services, setServices] = useState([]);

    const idService = useHistory();

    useEffect(()=> {
        getServices();
     }, []);

    const getServices = async() => {
        setLoading(true);
        await servicioService.getServicios().then((data) => {
            setServices(data.services || []);
        });
        setLoading(false);
    }

    var settings = {
      dots: true,
      infinite: false,
      speed: 500,
      arrows: true,
      slidesToShow: 3.6,
      slidesToScroll: 3.6,
      initialSlide: 0,
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 3,
            infinite: true,
            dots: true
          }
        },
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 2,
            initialSlide: 2
          }
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1
          }
        }
      ]
    };

    return (
        <>
        <Grid container sx={{ marginBottom: '25px', alignContent: 'center'}}>
            <HeaderEcommerce/>
            <Grid style={{marginBottom: '30px', width: '100%', paddingLeft: '40px', paddingRight: '40px'}}>
                <Slider {...settings}>
                    <div>
                        <Card className='card-content'>
                        <Grid sx={{ display: 'flex', flexDirection: 'column' }}>
                            <CardContent sx={{ flex: '1 0 auto' }}>
                                <Typography component="div" variant="subtitle1">
                                    <b>{'Duo Queso Tocino'}</b>
                                </Typography>
                                <Typography variant="body1" color="text.secondary" component="div">
                                    {'$ 36'}
                                </Typography>
                            </CardContent>
                        </Grid>
                        <CardMedia
                            component="img"
                            sx={{ maxWidth: 90, minWidth: 90 }}
                            image={"https://images.rappi.cl/products/2090193507-1547158222.png?e=webp&d=200x200&q=70"}
                            alt="img"
                            style={{border: "1px solid #b9b8b899"}}
                        />
                        </Card>
                    </div>
                    <div>
                        <Card className='card-content'>
                        <Grid sx={{ display: 'flex', flexDirection: 'column' }}>
                            <CardContent sx={{ flex: '1 0 auto' }}>
                                <Typography component="div" variant="subtitle1">
                                    <b>{'Duo Queso Tocino'}</b>
                                </Typography>
                                <Typography variant="body1" color="text.secondary" component="div">
                                    {'$ 36'}
                                </Typography>
                            </CardContent>
                        </Grid>
                        <CardMedia
                            component="img"
                            sx={{ maxWidth: 90, minWidth: 90 }}
                            image={"https://images.rappi.cl/products/2090193507-1547158222.png?e=webp&d=200x200&q=70"}
                            alt="img"
                            style={{border: "1px solid #b9b8b899"}}
                        />
                        </Card>
                    </div>
                    <div>
                        <Card className='card-content'>
                        <Grid sx={{ display: 'flex', flexDirection: 'column' }}>
                            <CardContent sx={{ flex: '1 0 auto' }}>
                                <Typography component="div" variant="subtitle1">
                                    <b>{'Duo Queso Tocino'}</b>
                                </Typography>
                                <Typography variant="body1" color="text.secondary" component="div">
                                    {'$ 36'}
                                </Typography>
                            </CardContent>
                        </Grid>
                        <CardMedia
                            component="img"
                            sx={{ maxWidth: 90, minWidth: 90 }}
                            image={"https://images.rappi.cl/products/2090193507-1547158222.png?e=webp&d=200x200&q=70"}
                            alt="img"
                            style={{border: "1px solid #b9b8b899"}}
                        />
                        </Card>
                    </div>
                    <div>
                        <Card className='card-content'>
                        <Grid sx={{ display: 'flex', flexDirection: 'column' }}>
                            <CardContent sx={{ flex: '1 0 auto' }}>
                                <Typography component="div" variant="subtitle1">
                                    <b>{'Duo Queso Tocino'}</b>
                                </Typography>
                                <Typography variant="body1" color="text.secondary" component="div">
                                    {'$ 36'}
                                </Typography>
                            </CardContent>
                        </Grid>
                        <CardMedia
                            component="img"
                            sx={{ maxWidth: 90, minWidth: 90 }}
                            image={"https://images.rappi.cl/products/2090193507-1547158222.png?e=webp&d=200x200&q=70"}
                            alt="img"
                            style={{border: "1px solid #b9b8b899"}}
                        />
                        </Card>
                    </div>
                    <div>
                        <Card className='card-content'>
                        <Grid sx={{ display: 'flex', flexDirection: 'column' }}>
                            <CardContent sx={{ flex: '1 0 auto' }}>
                                <Typography component="div" variant="subtitle1">
                                    <b>{'Duo Queso Tocino'}</b>
                                </Typography>
                                <Typography variant="body1" color="text.secondary" component="div">
                                    {'$ 36'}
                                </Typography>
                            </CardContent>
                        </Grid>
                        <CardMedia
                            component="img"
                            sx={{ maxWidth: 90, minWidth: 90 }}
                            image={"https://images.rappi.cl/products/2090193507-1547158222.png?e=webp&d=200x200&q=70"}
                            alt="img"
                            style={{border: "1px solid #b9b8b899"}}
                        />
                        </Card>
                    </div>
                </Slider>
            </Grid>
        </Grid>
        <Container>
            {loading && <Loading/>}

            <Grid container sx={{ marginBottom: '25px', alignContent: 'center', marginTop: '20px' }}>

                <Grid item xs={12} textAlign='center' mb={2}>
                    <Typography variant="h2" gutterBottom mb={3} className="textTitle">
                    ¿ Qué tienes pensado hacer hoy ? 
                    </Typography>
                </Grid>

                <Grid container spacing={2} item xs={12} sx={{ display: 'flex', margin: '0px', width: '100%', justifyContent: 'space-between'}}>

                    {
                        services.length > 0 && services.map((service, key) => (
                            <Grid key={key} item xs={12} sm={6} md={4} lg={4} p={2} onClick = {() => {
                                    // setSelectedService(service);
                                }}>
                                <Card className={"card-container-home"} sx={{ borderRadius: '25px' }}>
                                    <CardContent sx={{ height: '100px', padding: '20px', boxSizing: "border-box" }} >
                                        <Typography gutterBottom variant="h5" component="div" textAlign={'center'}>
                                            {service?.name}
                                        </Typography>
                                        <Typography variant="subtitle1" color="text.secondary" textAlign={'center'} style={{height: '110px'}}>
                                            {service?.description}
                                        </Typography>
                                        <Grid display='flex' justifyContent='center' style={{marginTop: '30px'}}>
                                            <Grid>
                                                <Button variant='outlined' className='card_btn' 
                                                onClick= {() => {idService.push(ROUTE_SHOP_ECOMMERCE_COMPANIES , {idService: service.id} )}}>
                                                    Seleccionar ahora
                                                </Button>
                                            </Grid>
                                        </Grid>
                                    </CardContent>
                                </Card>
                            </Grid>
                        ))
                    }
                </Grid>
            </Grid>
        </Container>
        </>
    )
}
