import axios from 'axios';
import { API_URL_NUPY } from '@/toolbox/defaults/app';
import { TOKEN_API } from '@/toolbox/constants/local-storage';
import { useLocalStorage } from '@/toolbox/hooks/local-storage.hook';

export const VentaRepository = {

  saveVenta: async (folio: number, cantProd: number,
    montoTotal: number, montoPagado: number, montoDescuento: number, codigo_transbank: string, fecha: string, idPedido: number,
    tipoDoc: number, sucursal: number, timbre_softnet: any, pdf_softnet: any, monto_neto: number, formaPago: string, propina = 0, id_mesa, exento?:number, id_caja=null
  ): Promise<any> => {
    const res = await axios.post(`${API_URL_NUPY}/ventas/crear_venta`, {
      folio,
      cantidad_productos: cantProd,
      monto_total: montoTotal,
      monto_pagado: montoPagado,
      fecha,
      codigo_transbank,
      id_pedido: idPedido,
      tipo_doc: tipoDoc,
      sucursal: sucursal,
      descuento_x_puntos: montoDescuento,
      timbre_softnet: timbre_softnet,
      pdf_softnet: pdf_softnet,
      monto_neto: monto_neto,
      forma_pago: formaPago,
      propina: propina === 0 ? null : propina,
      id_mesa,
      exento: exento || null,
      id_caja: id_caja || null
    });
    return res.data;
  },

  saveVentaRapida: async ( 
    n_ticket, monto_pedido, id_user,
    productos, id_cliente, tipo_pedido, 
    monto_total, descuento_monto_total, descuento_productos_total,
    tipo_doc, fechaFormateada, clienteSelect, MntNeto,
    MntExe, IVA, MntTotal, namepagosSelect, cantidad_productos,
    monto_pagado, codigo_transbank, fecha, sucursal, descuento_x_puntos, propina,
    forma_pago, exento, id_caja, token
    ): Promise<any> => {

    const res = await axios.post(`${API_URL_NUPY}/ventas/crear_venta_rapida`, {
      n_ticket, monto_pedido, id_user,
    productos, id_cliente, tipo_pedido, 
    monto_total, descuento_monto_total, descuento_productos_total,
    tipo_doc, fechaFormateada, clienteSelect, MntNeto,
    MntExe, IVA, MntTotal, namepagosSelect, cantidad_productos,
    monto_pagado, codigo_transbank, fecha, sucursal, descuento_x_puntos, propina: propina === 0 ? null : propina,
    forma_pago, exento: exento || null,
    id_caja: id_caja || null, token
    });
    return res.data;
  },

  saveOeUpdateVenta: async (folio: number, cantProd: number,
    montoTotal: number, montoPagado: number, montoDescuento: number, codigo_transbank: string, fecha: string, idPedido: number,
    tipoDoc: number, sucursal: number, timbre_softnet: any, pdf_softnet: any, monto_neto: number, formaPago: string, propina = 0, exento?:number
  ): Promise<any> => {
    const res = await axios.post(`${API_URL_NUPY}/ventas/crear_venta_multiple`, {
      folio,
      cantidad_productos: cantProd,
      monto_total: montoTotal,
      monto_pagado: montoPagado,
      fecha,
      codigo_transbank,
      id_pedido: idPedido,
      tipo_doc: tipoDoc,
      sucursal: sucursal,
      descuento_x_puntos: montoDescuento,
      timbre_softnet: timbre_softnet,
      pdf_softnet: pdf_softnet,
      monto_neto: monto_neto,
      forma_pago: formaPago,
      propina: propina === 0 ? null : propina,
      exento: exento || null,
    });
    return res.data;
  },

  editVenta: async (idVenta: number, cantProd: number,
    montoTotal: number, montoPagado: number, montoDescuento: number, fecha: string,
    tipoDoc: number
  ): Promise<any> => {
    const res = await axios.put(`${API_URL_NUPY}/ventas/editar_venta/${idVenta}`, {
      cantidad_productos: cantProd,
      monto_total: montoTotal,
      monto_pagado: montoPagado,
      fecha,
      tipo_doc: tipoDoc,
      descuento_x_puntos: montoDescuento
    });
    return res.data;
  },
  getAllVentas: async (): Promise<any> => {
    const res = await axios.get(`${API_URL_NUPY}/ventas/getAllVentas`);
    return res.data;
  },
  getVenta: async (idVenta: number): Promise<any> => {
    const res = await axios.get(`${API_URL_NUPY}/ventas/get_venta/${idVenta}`);
    return res.data;
  },
  getVentaByPedido: async (idPedido: number): Promise<any> => {
    const res = await axios.get(`${API_URL_NUPY}/ventas/get_venta_ByPedido/${idPedido}`);
    return res.data;
  },
  getVentaByTicket: async (idTicket: number): Promise<any> => {
    const res = await axios.get(`${API_URL_NUPY}/ventas/get_venta_ByTicket/${idTicket}`);
    return res.data;
  },
  getVentaDetalle: async (idVenta: number): Promise<any> => {
    const res = await axios.get(`${API_URL_NUPY}/ventas/get_venta_datalle/${idVenta}`);
    return res.data;
  },
  getVentasByMonth: async (fecha: any): Promise<any> => {
    const res = await axios.get(`${API_URL_NUPY}/ventas/listByMes/${fecha}`);
    return res.data;
  },
  getVentasByDay: async (fecha: any): Promise<any> => {
    const res = await axios.get(`${API_URL_NUPY}/ventas/listByDay/${fecha}`);
    return res.data;
  },
  getVentasByDFechas: async (fecha_de: any, fecha_a: any): Promise<any> => {
    const res = await axios.get(`${API_URL_NUPY}/ventas/listByFechas/${fecha_de}/${fecha_a}`);
    return res.data;
  },
  getVentasByDFechasByResponsable: async (fecha_de: any, fecha_a: any, filtro: string, id_responsable: number): Promise<any> => {
    const res = await axios.get(`${API_URL_NUPY}/ventas/listByFechasByResponsable/${fecha_de}/${fecha_a}/${filtro}/${id_responsable}`);
    return res.data;
  },
  getVentasByResponsableCuadratura: async ( id_responsable: number, id_cuadratura:number): Promise<any> => {
    const res = await axios.get(`${API_URL_NUPY}/ventas/listByResponsableCuadratura/${id_responsable}/${id_cuadratura}`);
    return res.data;
  },
  listProductByVenta: async (idVenta: number): Promise<any> => {
    const res = await axios.get(`${API_URL_NUPY}/ventas/listProductByVenta/${idVenta}`);
    return res.data;
  },
  //ganancia dashboard
  gananciaTotalMes: async (fecha: any): Promise<any> => {
    const res = await axios.get(`${API_URL_NUPY}/ventas/ganancia-total-mes/${fecha}`);
    return res.data;
  },
  gananciaTotalDay: async (fecha: any): Promise<any> => {
    const res = await axios.get(`${API_URL_NUPY}/ventas/ganancia-total-day/${fecha}`);
    return res.data;
  },
  gananciaTotalFechas: async (fecha_de: any, fecha_a: any): Promise<any> => {
    const res = await axios.get(`${API_URL_NUPY}/ventas/ganancia-total-fechas/${fecha_de}/${fecha_a}`);
    return res.data;
  },
  //venta dashboard
  ventaTotalMes: async (fecha: any): Promise<any> => {
    const res = await axios.get(`${API_URL_NUPY}/ventas/ventas-total-mes/${fecha}`);
    return res.data;
  },
  ventaTotalDay: async (fecha: any): Promise<any> => {
    const res = await axios.get(`${API_URL_NUPY}/ventas/ventas-total-day/${fecha}`);
    return res.data;
  },
  ventaTotalFechas: async (fecha_de: any, fecha_a: any): Promise<any> => {
    const res = await axios.get(`${API_URL_NUPY}/ventas/ventas-total-fechas/${fecha_de}/${fecha_a}`);
    return res.data;
  },
  //cliente dashboard
  clientesRegistradoMes: async (fecha: any): Promise<any> => {
    const res = await axios.get(`${API_URL_NUPY}/ventas/cliente-registrado-mes/${fecha}`);
    return res.data;
  },
  clientesRegistradoDay: async (fecha: any): Promise<any> => {
    const res = await axios.get(`${API_URL_NUPY}/ventas/cliente-registrado-day/${fecha}`);
    return res.data;
  },
  clientesRegistradoFechas: async (fecha_de: any, fecha_a: any): Promise<any> => {
    const res = await axios.get(`${API_URL_NUPY}/ventas/cliente-registrado-fechas/${fecha_de}/${fecha_a}`);
    return res.data;
  },
  //empleado dashboard
  empleadoMes: async (fecha: any): Promise<any> => {
    const res = await axios.get(`${API_URL_NUPY}/ventas/empleado-mes/${fecha}`);
    return res.data;
  },
  empleadoDay: async (fecha: any): Promise<any> => {
    const res = await axios.get(`${API_URL_NUPY}/ventas/empleado-day/${fecha}`);
    return res.data;
  },
  empleadoFechas: async (fecha_de: any, fecha_a: any): Promise<any> => {
    const res = await axios.get(`${API_URL_NUPY}/ventas/empleado-fechas/${fecha_de}/${fecha_a}`);
    return res.data;
  },
  //ventas top
  getVentasTopByMonth: async (fecha: any): Promise<any> => {
    const res = await axios.get(`${API_URL_NUPY}/ventas/list-topVentas-mes/${fecha}`);
    return res.data;
  },
  getVentasTopByDay: async (fecha: any): Promise<any> => {
    const res = await axios.get(`${API_URL_NUPY}/ventas/list-topVentas-day/${fecha}`);
    return res.data;
  },
  getVentasTopByDFechas: async (fecha_de: any, fecha_a: any): Promise<any> => {
    const res = await axios.get(`${API_URL_NUPY}/ventas/list-topVentas-fechas/${fecha_de}/${fecha_a}`);
    return res.data;
  },
  getReporteVentas: async (data: {}): Promise<any> => {
    const res = await axios.get(`${API_URL_NUPY}/ventas/reporteVentas`, { params: data});
    return res.data;
  },

  //tipos de pago dashboard
  tiposPagosMasUsadosMes: async (fecha: any): Promise<any> => {
    const res = await axios.get(`${API_URL_NUPY}/ventas/tipos-pagos-usados-mes/${fecha}`);
    return res.data;
  },
  tiposPagosMasUsadosDay: async (fecha: any): Promise<any> => {
    const res = await axios.get(`${API_URL_NUPY}/ventas/tipos-pagos-usados-day/${fecha}`);
    return res.data;
  },
  tiposPagosMasUsadosFechas: async (fecha_de: any, fecha_a: any): Promise<any> => {
    const res = await axios.get(`${API_URL_NUPY}/ventas/tipos-pagos-usados-fechas/${fecha_de}/${fecha_a}`);
    return res.data;
  },

  //tipos de pago table
  tiposPagosTableMes: async (fecha: any): Promise<any> => {
    const res = await axios.get(`${API_URL_NUPY}/ventas/tipos-pagos-table-mes/${fecha}`);
    return res.data;
  },
  tiposPagosTableDay: async (fecha: any): Promise<any> => {
    const res = await axios.get(`${API_URL_NUPY}/ventas/tipos-pagos-table-day/${fecha}`);
    return res.data;
  },
  tiposPagosTableFechas: async (fecha_de: any, fecha_a: any): Promise<any> => {
    const res = await axios.get(`${API_URL_NUPY}/ventas/tipos-pagos-table-fechas/${fecha_de}/${fecha_a}`);
    return res.data;
  },
  tiposPagosTableFechasByResponsable: async ( id_responsable: number, id_cuadratura:number, token): Promise<any> => {
    const res = await axios.get(`${API_URL_NUPY}/ventas/tipos-pagos-table-fechas-by-responsable/${id_responsable}/${id_cuadratura}/${token}`);
    return res.data;
  },
  ventasCuadraturaByResponsableFamiliaFormaPago: async ( id_responsable: number, id_cuadratura:number, token): Promise<any> => {
    const res = await axios.get(`${API_URL_NUPY}/ventas/cuadratura-by-responsable-familia-formapago/${id_responsable}/${id_cuadratura}/${token}`);
    return res.data;
  },
  enviarBoletaSoftnet,
  tipoBoletaSoftnet,
  enviarFacturaSoftnet,
  enviarNotaVentaSoftnet,
  crearFacturaSoftnet2,
  crearBoletaSoftnet2
}

function enviarBoletaSoftnet(token, boleta) {
  return fetch('https://api2.softnet.cl/boleta', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
      token: token,
    },
    body: JSON.stringify(boleta),
  })
    .then(pros => pros.json())
    .then(async pros => {
      return pros
    })
}

function enviarFacturaSoftnet(token, factura) {
  return fetch('https://api2.softnet.cl/factura', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
      token: token,
    },
    body: JSON.stringify(factura),
  })
    .then(pros => pros.json())
    .then(async pros => {
      return pros
    })
}

function enviarNotaVentaSoftnet(token, data) {
  return fetch('https://api2.softnet.cl/notaVenta', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
      token: token,
    },
    body: JSON.stringify(data),
  })
    .then(pros => pros.json())
    .then(async pros => {
      return pros
    })
}

function tipoBoletaSoftnet(token) {
  return fetch('https://api2.softnet.cl/configuracionProducto', {
    method: 'GET',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
      token: token,
    },
  })
    .then(pros => pros.json())
    .then(async pros => {
      return pros
    })
}


function crearFacturaSoftnet2(token: string, factura: {}) {
  return fetch('https://api2.softnet.cl/facturaResto', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'token': token,
    },
    body: JSON.stringify(factura),
  })
    .then(response => response.json())
    .then(async data => {
      return data
    });
}

function crearBoletaSoftnet2(token: string, boleta: {}) {
  return fetch('https://api2.softnet.cl/boletaResto', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'token': token,
    },
    body: JSON.stringify(boleta),
  })
    .then(response => response.json())
    .then(async data => {
      return data
    });
}