import React, { useEffect } from "react";
import { Loading } from "../../components/common/Loading/Loading";
import { Protected } from "@/components/layout/Protected";
import { Box, Button, Container, createTheme, Divider, FormControlLabel, Grid, InputAdornment, Paper, Switch, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, ThemeProvider, Typography } from "@mui/material";
import { esES } from "@mui/material/locale";
import { PencilIcon } from "@/toolbox/constants/icons";
import VisibilityIcon from '@mui/icons-material/Visibility';
import { Popup } from "../Editor/components/Popup";
import ContentPasteOffIcon from '@mui/icons-material/ContentPasteOff';
import { Props } from "./Proveedor.type";
import { ModalProveedor } from './componentes/Modal/ModalProveedor';
import { proveedorService } from "@/service/services/Proveedor.service";
import { TOKEN_API } from "@/toolbox/constants/local-storage";
import { useLocalStorage } from '@toolbox/hooks/local-storage.hook';
import SearchIcon from "@mui/icons-material/Search";

const theme = createTheme(
    {
        palette: {
            primary: { main: '#1976D2' },
        },
    },
    esES,
);

export const Proveedor: React.FC<Props> = (props: Props): JSX.Element => {

    const [loading, setLoading] = React.useState<boolean>(false);

    const [openPopup, setOpenPopup] = React.useState<boolean>(false);
    const [editMode, setEditMode] = React.useState<boolean>(false);
    const [popUp, setPopUp] = React.useState<String>('');

    const [selectedProveedor, setSelectedProveedor] = React.useState<any>('');
    const [proveedores, setProveedores] = React.useState([]);
    const [proveedoresBackUp, setProveedoresBackUp] = React.useState([]);
    const [token] = useLocalStorage(TOKEN_API, "");

    useEffect(() => {
        getProveedor();
        // eslint-disable-next-line
    }, []);

    const syncProveedores = async () => {
        setLoading(true);
        await proveedorService.validateProveedoresSofnet();
        await getProveedor();
    }

    // const getAndValidateClientSofnet = async () => {
    //   const cli = await fetch(`https://api2.softnet.cl/cliente`, {
    //   method: 'GET',
    //   headers: {
    //      'Access-Control-Allow-Origin':'*',
    //      'Content-Type': 'application/x-www-form-urlencoded',
    //      token: token,
    //   },
    //   })
    //   .then(proveedores => proveedores.json())
    //   .then(async proveedores => {
    //      let proveedoresPush = [];
    //   // eslint-disable-next-line
    //      proveedores.map((cli) => {
    //         if(cli.proveedor === "SI"){
    //            proveedoresPush.push(cli)
    //         }
    //      })

    //      return proveedoresPush;
    //   })
    //   .catch(error => {
    //      return []
    //   })
    //   return cli
    // }

    const getProveedor = async () => {
        setLoading(true);
        await proveedorService.getProveedores().then((data) => {
            setProveedores(data);
            setProveedoresBackUp(data)
            setLoading(false);
        })
    }

    const funcionSwitch = async (proveedor) => {
        setLoading(true);
        if (proveedor.state === 1) {
            await proveedorService.editState(proveedor.id, 0);
        } else {
            await proveedorService.editState(proveedor.id, 1);
        }
        getProveedor();
    }

    const onSearch = (search: string) => {
        const searchLowerCase = search.trim().toLowerCase();
        if (search !== '') {
            const filteredProducts = proveedoresBackUp.filter(proveedor => {
                const razon_social = proveedor.razon_social && proveedor.razon_social.toLowerCase();
                const rut = proveedor.rut && proveedor.rut.toLowerCase();
                return (
                    (razon_social && razon_social.includes(searchLowerCase)) ||
                    (rut && rut.includes(searchLowerCase))
                );
            });
            setProveedores(filteredProducts);
        } else {
            setProveedores(proveedoresBackUp);
        }
    };

    return (
        <Protected>
            {loading && <Loading />}
            <Grid container spacing={2}>
                <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'center' }}>
                    <Typography variant="h4">Gestión de proveedores</Typography>
                </Grid>
                <Grid item container xs={12}
                    sx={{
                        alignItems: "center",
                        display: "flex",
                        justifyContent: "space-between",
                        flexWrap: "wrap",
                        marginBottom: "10px",
                    }}
                >
                    <Grid item>
                        <TextField
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <SearchIcon fontSize="small" />
                                    </InputAdornment>
                                ),
                            }}
                            placeholder="Buscar..."
                            size="small"
                            onChange={(e) => onSearch(e.target.value)}
                        />
                    </Grid>
                    <Grid item sx={{ textAlign: "right" }}>
                        <Button variant='outlined' style={{ textTransform: "capitalize", marginRight: '5px' }}
                            onClick={() => {
                                syncProveedores();
                            }}
                        >Sincronizar Proveedor</Button>

                        <Button variant='outlined' style={{ textTransform: "capitalize" }}
                            onClick={() => {
                                setOpenPopup(true);
                                setEditMode(true);
                                setPopUp('addProveedor');
                            }}
                        >Crear Proveedor</Button>
                    </Grid>
                </Grid>

                <Grid item xs={12}>
                    <Box sx={{ Width: '100%' }}>
                        <TableContainer component={Paper}>
                            <ThemeProvider theme={theme}>
                                <Table aria-label="simple table" size='small'>
                                    <TableHead>
                                        <TableRow sx={{ bgcolor: '#1976D2' }}>
                                            <TableCell sx={{ color: 'white' }} >
                                                <strong>N°</strong>
                                            </TableCell>
                                            <TableCell align='center' sx={{ color: 'white' }} >
                                                <strong>Razon Social</strong>
                                            </TableCell>
                                            <TableCell align='center' sx={{ color: 'white' }} >
                                                <strong>RUT</strong>
                                            </TableCell>
                                            <TableCell align='center' sx={{ color: 'white' }} >
                                                <strong>Estado</strong>
                                            </TableCell>
                                            <TableCell align='center' sx={{ color: 'white' }} >
                                                <strong>Observacion</strong>
                                            </TableCell>
                                            <TableCell align='center' sx={{ color: 'white', minWidth: '8rem', maxWidth: '12rem' }}><strong>Acciones</strong></TableCell>
                                        </TableRow>
                                    </TableHead>

                                    <TableBody>
                                        {!!proveedores.length
                                            ?
                                            proveedores.map((proveedor, id) => (
                                                <TableRow key={id}>
                                                    <TableCell component="th" scope="row">
                                                        {id + 1}
                                                    </TableCell>
                                                    <TableCell component="th" scope="row">
                                                        {proveedor.razon_social.toLowerCase()}
                                                    </TableCell>
                                                    <TableCell align='center' component="th" scope="row">
                                                        {proveedor.rut}
                                                    </TableCell>

                                                    <TableCell align='center' component="th" scope="row">
                                                        <Box>
                                                            <FormControlLabel
                                                                control={<Switch onClick={() => {
                                                                    funcionSwitch(proveedor);
                                                                }}
                                                                />}
                                                                checked={proveedor.state === 1 ? true : false}
                                                                label=""
                                                            />
                                                        </Box>
                                                    </TableCell>

                                                    <TableCell align='center' component="th" scope="row">
                                                        {proveedor.observacion}
                                                    </TableCell>
                                                    <TableCell align='center'>
                                                        <Button
                                                            onClick={() => {
                                                                setOpenPopup(true);
                                                                setEditMode(true);
                                                                setPopUp('editProveedor');
                                                                setSelectedProveedor(proveedor);
                                                            }}
                                                        >
                                                            <PencilIcon />
                                                        </Button>

                                                        <Button
                                                            onClick={() => {
                                                                setOpenPopup(true);
                                                                setEditMode(false);
                                                                setPopUp('detailProveedor');
                                                                setSelectedProveedor(proveedor);
                                                            }}
                                                        >
                                                            <VisibilityIcon />
                                                        </Button>
                                                    </TableCell>
                                                </TableRow>
                                            ))
                                            :
                                            <TableRow>
                                                <TableCell align="center" colSpan={6}>Sin resultados</TableCell>
                                            </TableRow>
                                        }
                                    </TableBody>
                                </Table>
                            </ThemeProvider>
                        </TableContainer>
                    </Box>
                </Grid>
            </Grid>
            {
                popUp === 'addProveedor' && (
                    <Popup
                        largo="sm"
                        title='Agregar Proveedor'
                        openPopup={openPopup}
                        setOpenPopup={setOpenPopup}
                    >
                        <ModalProveedor
                            closeModal={() => { setOpenPopup(false) }}
                            onListProveedor={getProveedor}
                            editMode={editMode}
                        />

                    </Popup>
                )
            }
            {
                popUp === 'editProveedor' && (
                    <Popup
                        largo="sm"
                        title='Editar Proveedor'
                        openPopup={openPopup}
                        setOpenPopup={setOpenPopup}
                    >
                        <ModalProveedor
                            closeModal={() => { setOpenPopup(false) }}
                            proveedor={selectedProveedor}
                            onListProveedor={getProveedor}
                            editMode={editMode}
                        />

                    </Popup>
                )
            }
            {
                popUp === 'detailProveedor' && (
                    <Popup
                        largo="sm"
                        title='Detalle Proveedor'
                        openPopup={openPopup}
                        setOpenPopup={setOpenPopup}
                    >
                        <ModalProveedor
                            closeModal={() => { setOpenPopup(false) }}
                            proveedor={selectedProveedor}
                            editMode={editMode}

                        />

                    </Popup>
                )
            }
        </Protected>
    );
}
