import {
    ACTION_ECOMMERCE_CLIENT
 } from '@constants/action-type'

 const initialState = {
    showModal:false,
    productsCount: 0,
    isLoading: false,
 }

 // eslint-disable-next-line
 export default function(state = initialState, action:any){
    switch(action.type){
        case ACTION_ECOMMERCE_CLIENT:
            return {
                ...state,
                showModal: action.payload.showModal,
                isLoading: action.payload.isLoading,
                productsCount: action.payload.productsCount
            }
        default: return state
    }
 }
