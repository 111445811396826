import axios from 'axios';
import { API_URL_NUPY } from '@/toolbox/defaults/app';

export const notaCreditoRepository = {

    anularVentaTotal: async( idPedido: number ): Promise<any> =>{
        const res = await axios.post(`${API_URL_NUPY}/notasCredito/anular-venta-total/${idPedido}`);
        return res.data;
    },

    anularVentaParcial: async( idPedidoAnulado: any, data: any, idVentaNueva: any ): Promise<any> =>{
      let newData = {
         data: data,
         idNuevo: idVentaNueva,
      }
    const res = await axios.post(`${API_URL_NUPY}/notasCredito/anular-venta-parcial/${idPedidoAnulado}`, newData);
    return res.data;
    },
}
