export const ROUTE_HOME                             = '/';
export const ROUTE_LOGIN                            = '/login';
export const ROUTE_LOGIN_CLIENT                     = '/login-cliente';
export const ROUTE_SCANNER_CLIENT                   = '/scanner-qr';
export const ROUTE_GENERATOR_TICKET                 = '/generar-ticket';
export const ROUTE_GENERATOR_DETAIL                 = '/generar-detalle';
export const ROUTE_GENERATOR_NOT_ISSUED             = '/generar-no-emitido';
export const ROUTE_GENERATOR_CANCELED               = '/generar-ticket-anulado';
export const ROUTE_GENERATOR_PROCESSED_CANCELED     = '/generar-ticket-procesado-anulado';
export const ROUTE_CONSULT_BALLOT                   = '/consultar-datos';
export const ROUTE_CAJA                             = '/caja';
export const ROUTE_MANTENEDOR_CAJAS                 = '/caja-mantenedor';
export const ROUTE_TARJETA_EXISTENCIA_DIARIA        = '/tarjeta-existencia-diaria';
export const ROUTE_VENTAS                           = '/ventas';
export const ROUTE_PEDIDOS                          = '/pedidos';
export const ROUTE_PEDIDOS_PRODUCTOS                = '/pedidos-productos';
export const ROUTE_REPORTE                          = '/reporte';
export const ROUTE_REPORTE_VENTA                    = '/reporte-venta';
export const ROUTE_REPORTE_VENTA_VENTAS             = '/reporte-venta-ventas';
export const ROUTE_RECETA                           = '/receta/:id';
export const ROUTE_REPORTE_TABLE                    = '/reporte-table';
export const ROUTE_CLIENTES                         = '/clientes';
export const ROUTE_BENEFICIOS                       = '/beneficios';
export const ROUTE_SALAS                            = '/salas';
export const ROUTE_PRODUCTOS                        = '/productos';
export const ROUTE_EMPLEADOS                        = '/empleados';
export const ROUTE_EDITOR                           ='/Editor';
export const ROUTE_USUARIOS                         = '/usuarios';
export const ROUTE_EMPRESAS                         = '/empresas';
export const ROUTE_PROVEEDORES                      = '/proveedores';
export const ROUTE_COMPRAS                          = '/compras';
export const ROUTE_NUEVA_COMPRA                     = '/nueva-compra';
export const ROUTE_CUADRATURAS                      = '/cuadraturas';
export const ROUTE_SUCURSALES                       = '/sucursales';
export const CIERRE_DIARIO                          = '/cierre-diario';
export const ROUTE_INVENTARIO                       = '/inventario';
export const ROUTE_LISTA_PRECIO                     = '/lista-precios';
export const ROUTE_CREAR_LISTA_PRECIO               = '/lista-precios-crear';
export const ROUTE_PRODUCTOS_INSUMO                 = '/productos-insumo';
export const ROUTE_COCINAS                          = '/cocinas';
export const ROUTE_COMPANY_CONFIG                   = '/configuracion';
export const ROUTE_MASTER_CONFIG                    = '/master-configuracion';
export const ROUTE_PRODUCTOS_LIST                   = '/mis-productos';
export const ROUTE_CODIGO_BARRA                     = '/codigo-barras/:id';

// ecommerce
export const ROUTE_SHOP_ECOMMERCE                   = '/shop-ecommerce';
export const ROUTE_SHOP_ECOMMERCE_COMPANIES         = '/shop-ecommerce/companies';
export const ROUTE_SHOP_RESERVE_TABLE               = '/shop-ecommerce/reserve-table';

export const ROUTE_CLIENTE_ECOMMERCE                = '/home-cliente';

export const ROUTE_MIS_PEDIDOS_CLIENTE              = '/mis-pedidos';
export const ROUTE_MIS_COMPRAS_CLIENTE              = '/mis-compras';
export const ROUTE_PRODUCTOS_ECOMMERCE              = '/productos-ecommerce';

