import { API_URL_NUPY } from "@/toolbox/defaults/app";
import axios from "axios";

export const CodigoBarraRepository = {

    register: async (idproducto: number, data: {}): Promise<any> => {
        const res = await axios.post(`${API_URL_NUPY}/productos/post_relacion_codigo_barra/${idproducto}`, data);
        return res.data;
    },

    update: async (id:number, data: {}): Promise<any> => {
        const res = await axios.patch(`${API_URL_NUPY}/productos/edit_relacion_codigo_barra/${id}`, data);
        return res.data;
    },

    delete: async (id:number): Promise<any> => {
        const res = await axios.delete(`${API_URL_NUPY}/productos/delete_relacion_codigo_barra/${id}`);
        return res.data;
    },
}