import React from 'react';
import { Grid, Typography } from '@mui/material';
import { moneyFormatInt } from '@/toolbox/helpers/money.helper';
import {Props} from './ComponentPrintTicket.type'
import { dateFormat } from '@/toolbox/helpers/date.helper';
import { useBarcode } from '@createnextapp/react-barcode';
import moment from 'moment';

export const ComponentPrintTicket: React.FC<Props> = (props: Props) => {
   function BarCode({ textobarcode }) {
      const { inputRef } = useBarcode({
        value: textobarcode === "" ? "vacio" : textobarcode,
        options: {
           width:1.5
        }
      });
      return (<img ref={inputRef} alt="barcodeIMG"/>);
   }

   const validatePrice = (product) => {
      //validar si existe en la lista de precio
      let existList = (props.listaPrecio|| []).find(p => p.codigo_erp === product.codigo_erp);
      if (existList) {
         return existList?.precio_ajustado || 0;
      }

      const dateToday = moment();
      const value = product.caducidad_precio_oferta;
      const expirationPrice = moment(value, 'DD-MM-YYYY');

      const standarPrice = product.precio_standar || product.precio;
      const offerPrice = product.precio_oferta;

      if(expirationPrice !== null && offerPrice !== null){
         if(expirationPrice.isSameOrAfter(dateToday)){
            return offerPrice;
         }else{
            return standarPrice;
         }
      }else{
         return standarPrice;
      }
   }

    return (
        <div style={{  marginBottom: 30, width: '300px', margin: 'auto', marginTop: 30 ,padding:"10px"}}>
               <div style={{width:"100%"}} >
                  <div style={{textAlign:"center"}}>
                     <Typography
                     sx={{fontSize:'1.3em !important', fontFamily:'sans-serif',fontWeight:'700'}}
                     >{props.data.n_ticket? "TICKET ":''}</Typography>
                       <Typography
                     sx={{fontSize:'1.3em !important', fontFamily:'sans-serif',fontWeight:'700'}}
                     >{props.data.n_ticket? "N° "+ props.data.n_ticket:' '}</Typography>
                  </div>
                  <hr/>
                  <div>
                  { props.data?.direccion &&
                        <Typography sx={{fontSize:'1.2em !important'}}>
                          <strong>SUCURSAL:</strong>  {props.data?.direccion} </Typography>
                  }
                        <Typography sx={{fontSize:'1.2em !important'}}
                        > <strong>Cliente:</strong> {props.data.cliente?.rut || "66666666-6"}{''}</Typography>
                        <Typography sx={{fontSize:'1.2em !important'}}
                        ><strong>Empleado:</strong> {props.data?.usuario.nombre || "Empleado General"}</Typography>
                        <Typography sx={{fontSize:'1.2em !important'}}
                        ><strong>Fecha:</strong>  {dateFormat(props.data.fecha) || "--------"}{' '}</Typography>
                  </div>
                  <hr style={{border: '0', height: '2px', borderTop: '1px dashed black', borderBottom: '1px dashed black'}}/>
                  <div>
                     <Grid container>
                        <Grid item xs={6}>
                           <Typography sx={{textAlign:"left",fontSize:'1.2em !important'}}>Artículo</Typography>
                        </Grid>
                        <Grid item xs={6}>
                           <Typography sx={{textAlign:"right",fontSize:'1.2em !important'}}>Valor</Typography>
                        </Grid>
                     </Grid>
                  </div>
                  <div>
                  <hr style={{border: '0', height: '2px', borderTop: '1px dashed black', borderBottom: '1px dashed black'}}/>
                  </div>
                  <div>
                     <Grid container>
                     {(props.data.productos||[]).map((item, index) => (
                           <>
                           <Grid item xs={12}>
                              <Typography sx={{textAlign:"left",fontSize:'1em !important'}}>{item?.nombre}</Typography>
                           </Grid>
                           <Grid item xs={8}>
                              <Typography sx={{textAlign:"left",fontSize:'1em !important'}}>
                                 {item.cantidad} {item?.unidad_medida?.abbreviation ? item?.unidad_medida?.abbreviation : ''} x {moneyFormatInt(Math.ceil(validatePrice(item) || item?.precio))}</Typography>
                           </Grid>
                           <Grid item xs={4}>
                              <Typography sx={{textAlign:"right",fontSize:'1em !important'}}>
                                 {moneyFormatInt(Math.ceil(Number(validatePrice(item) || item?.precio)*Number(item.cantidad)/Number(item.cantidad_peso || 1)))}</Typography>
                           </Grid>
                           </>
                        ))}
                     </Grid>
                  </div>
                  <hr/>
                  <div>
                     <Grid container xs={12} style={{padding:"5px",display:"flex", alignItems:"center",color:"#000"}}>
                        <Grid item xs={8}>
                           <Typography sx={{ textAlign:"right",fontSize:'1em !important'}}><strong>Descuento</strong></Typography>
                        </Grid>
                        <Grid item xs={4} >
                           <Typography sx={{textAlign:"right", fontWeight:"bold", fontSize:'1em !important'}}><strong>{moneyFormatInt(Math.ceil(props.data.monto_descuento))}</strong></Typography>
                        </Grid>
                        <Grid item xs={8}>
                           <Typography sx={{ textAlign:"right",fontSize:'1em !important'}}><strong>Total a Pagar</strong></Typography>
                        </Grid>
                        <Grid item xs={4} >
                           <Typography sx={{textAlign:"right", fontWeight:"bold", fontSize:'1em !important'}}><strong>{moneyFormatInt(Math.ceil(props.data.monto_pedido))}</strong></Typography>
                        </Grid>
                        <Grid item xs={12}>
                           <BarCode textobarcode={props.data.n_ticket} />
                        </Grid>
                     </Grid>
                  </div>
               </div>
            </div>
    )
}
