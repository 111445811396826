import { ServicioRepository } from "../repositories/Servicio.repository";

export const servicioService = {
    getServicios,
    register,
    update,
    deleteServicio,
}

async function getServicios(){
    const res = await ServicioRepository.getServicios();
    return res;
}

async function register(data:{}){
    const res = await ServicioRepository.register(data);
    return res;
}

async function update(data:{}, idServicio:number){
    const res = await ServicioRepository.update(data, idServicio);
    return res;
}

async function deleteServicio(idServicio:number){
    const res = await ServicioRepository.deleteServicio(idServicio);
    return res;
}