import { CuponesRepository } from "../repositories/Cupones.repository";

export const CuponesService = {
    getCupones,
    showCupon,
    register,
    update,
    updateStatus,
    deleteCupon,
    couponsAvailable,
}

async function couponsAvailable(){
    const res = await CuponesRepository.couponsAvailable();
    return res;
}

async function getCupones(){
    const res = await CuponesRepository.getCupones();
    return res;
}

async function showCupon(id: number){
    const res = await CuponesRepository.showCupon(id);
    return res;
}

async function register(data: any){
    const res = await CuponesRepository.register(data);
    return res;
}

async function update(data:{}, idCupon:number){
    const res = await CuponesRepository.update(data, idCupon);
    return res;
}

async function updateStatus(idCupon:number, state:any){
    const res = await CuponesRepository.updateStatus(idCupon, state);
    return res;
}

async function deleteCupon(idCupon:number){
    const res = await CuponesRepository.deleteCupon(idCupon);
    return res;
}
