import { Container, Grid, Typography } from "@mui/material";
import '../Categories/CategoryProducts_style.css';
import { Props } from "./HomeEcommerce.type";
import { CategoryProducts } from "../Categories/CategoryProducts";
import { HeaderEcommerce } from "../Header/HeaderEcommerce";
import { readLocalStorage } from "@/toolbox/helpers/local-storage-helper";
import { KEY_EMPRESA } from "@/toolbox/constants/local-storage";

export const HomeEcommerce: React.FC<Props> = (props:Props):JSX.Element => {
   const dataEmpresa = readLocalStorage(KEY_EMPRESA);

    return (
        <Container>
            <Grid container sx={{ marginBottom: '25px', alignContent: 'center', marginTop: '20px' }}>

                <HeaderEcommerce/>

                <Grid item xs={12} textAlign='center' mb={2}>
                    {
                     dataEmpresa?.nombre &&
                     <Typography variant="h2" gutterBottom mb={3} className="textTitle">
                        {dataEmpresa?.nombre}
                     </Typography>
                    }
                    <Typography variant="h2" gutterBottom mb={3} className="textTitle">
                    ¡Encuentra aquí todo lo que necesitas!
                    </Typography>
                    <Typography variant="subtitle2" style={{ color: '#3F3F3F' }}>
                    Para empezar selecciona una categoría para encontrar más rápido tu producto
                    </Typography>
                </Grid>

                <CategoryProducts/>

            </Grid>
        </Container>
    )
}
