import { ticketRepository } from "../repositories/Ticket.repository";
import { PedidoRepository } from "../repositories/Pedido.repository";
import { VentaRepository } from "../repositories/Venta.repository";
import { STATUS_FINALIZADO } from '@constants/status-ticket';
// import axios from "axios";
import moment from 'moment';
import {KEY_USER_DATA, KEY_EMPRESA} from '@constants/local-storage';
import {readLocalStorage} from '@helpers/local-storage-helper';

export const ventaService = {
    generarBoletaVentaDirect,
    generarVentaRapida,
    generarBoleta,
    enviarBoleta,
    enviarFactura,
    enviarNotaVenta,
    tipoBoletaSoftnet,
    getAllVentas,
    getVentaByPedido,
    getVentaByTicket,
    getVentaDetalle,
    getVentasByMonth,
    getVentasByDay,
    getVentasByDFechas,
    getVentasByDFechasByResponsable,
    getVentasByResponsableCuadratura,
    listProductByVenta,

    gananciaTotalMes,
    gananciaTotalDay,
    gananciaTotalFechas,

    ventaTotalMes,
    ventaTotalDay,
    ventaTotalFechas,

    clientesRegistradoMes,
    clientesRegistradoDay,
    clientesRegistradoFechas,

    empleadoMes,
    empleadoDay,
    empleadoFechas,

    getVentasTopByMonth,
    getVentasTopByDay,
    getVentasTopByDFechas,

    tiposPagosMasUsadosMes,
    tiposPagosMasUsadosDay,
    tiposPagosMasUsadosFechas,

    tiposPagosTableMes,
    tiposPagosTableDay,
    tiposPagosTableFechas,
    tiposPagosTableFechasByResponsable,
    ventasCuadraturaByResponsableFamiliaFormaPago,

    crearFacturaSoftnet,
    createBoletaSoftenet,
    getReporteVentas
}


// async function generarBoletaVentaDirect (totalCard: number, state: number, products: Array<any>, cliente: string,
async function generarBoletaVentaDirect (id_mesa: string, id_cliente: string, totalCard: number, descuentoMonto: number, descuentoProducto: number, products: Array<any>, codigo_transbank:string,
   formaPago: string, folio: number, tipoDocId: number, bodegaId: number, arrayIdsProducto:Array<any>,  timbre_softnet:any, pdf_softnet:any, monto_neto:number, status_pedido: string, puntos: number
   ) {
      let data = readLocalStorage(KEY_USER_DATA)
      let dataE = readLocalStorage(KEY_EMPRESA)

      let productCount = products.length;
      let fecha = moment(new Date()).format('DD-MM-YYYY');

      const numBase = new Date();
      const num_ticket = `T-${numBase.getTime()}-${data.user?.id_empresa}`;

      const ticket_estado = dataE.id_tipo_empresa === 3 ? 'por-entregar' : 'finalizado';

    try {
      const res = await ticketRepository.saveTicket(num_ticket , totalCard, ticket_estado);
      if (res?.ticketID) {
         for (let i = 0; i < products.length; i++) {
            const element = products[i];
            await ticketRepository.saveTicketDetail(res.ticketID, arrayIdsProducto[i], (element?.cantidadD) ? element.cantidadD : element.cantidad , 1, element?.descuento, element?.precio_unitario, element?.subProductos);
         }
         const resPed = await PedidoRepository.savePedido(res.ticketID, id_mesa, id_cliente, status_pedido,
            "boleta", totalCard, descuentoMonto, descuentoProducto/* , idForma */, data.user?.id_usuario, puntos
         )
         if (resPed?.pedidoID) {
            const resVenta = await VentaRepository.saveVenta(folio, productCount, totalCard, totalCard, descuentoMonto+descuentoProducto, codigo_transbank, fecha, resPed?.pedidoID, tipoDocId, dataE.id, timbre_softnet, pdf_softnet, monto_neto, formaPago, 0 , id_mesa)
            if (resVenta?.ventaID) {
               return resVenta.ventaID;
            } else { return false; }
         } else { return false; }
      } else { return false }
    } catch (error) {
       return false;
    }

}

async function generarVentaRapida(
   n_ticket, monto_pedido, id_user,
    productos, id_cliente, tipo_pedido, 
    monto_total, descuento_monto_total, descuento_productos_total,
    tipo_boleta, fechaFormateada, clienteSelect, MntNeto,
    MntExe, IVA, MntTotal, namepagosSelect, cantidad_productos,
    monto_pagado, codigo_transbank, fecha, sucursal, descuento_x_puntos, propina,
    forma_pago, exento, id_caja, token
){
   const resVenta = await VentaRepository.saveVentaRapida(
      n_ticket, monto_pedido, id_user,
    productos, id_cliente, tipo_pedido, 
    monto_total, descuento_monto_total, descuento_productos_total,
    tipo_boleta, fechaFormateada, clienteSelect, MntNeto,
    MntExe, IVA, MntTotal, namepagosSelect, cantidad_productos,
    monto_pagado, codigo_transbank, fecha, sucursal, descuento_x_puntos, propina,
    forma_pago, exento, id_caja, token
   )
   if (resVenta?.status == 200) {
      return resVenta;
   } else { return false; }
}

async function generarBoleta (
   pedidoId: number, totalCard: number, montoRecibido: number, descuentoMonto: number, products: Array<any>, codigo_transbank:string,
   folio: number, tipoDocId: number, timbre_softnet:any, pdf_softnet:any, monto_neto:number, formaPago:string, propina = 0, isMultiPago = false, id_mesa, exento: number, id_caja=null
   ) {
      let data = readLocalStorage(KEY_EMPRESA)

      let productCount = products.length;
      let fecha = moment(new Date()).format('DD-MM-YYYY');
    try {
         let resVenta: any;
         await PedidoRepository.editPedidoStatus(pedidoId, STATUS_FINALIZADO, 0)
         if (!isMultiPago) {
            resVenta = await VentaRepository.saveVenta(folio, productCount, totalCard, montoRecibido, descuentoMonto,
               codigo_transbank, fecha, pedidoId, tipoDocId, data.id, timbre_softnet, pdf_softnet, monto_neto, formaPago, propina, id_mesa, exento, id_caja
            )
         } else {
            resVenta = await VentaRepository.saveOeUpdateVenta(folio, productCount, totalCard, montoRecibido, descuentoMonto,
               codigo_transbank, fecha, pedidoId, tipoDocId, data.id, timbre_softnet, pdf_softnet, monto_neto, formaPago, propina, exento
            )
         }

         if (resVenta?.ventaID) {
            return resVenta.ventaID;
         } else { return false; }
    } catch (error) {
       return false;
    }

}

async function getAllVentas () {
   const res = await VentaRepository.getAllVentas();
   return res;
}

async function getVentaByPedido (idPedido: number) {
   const res = await VentaRepository.getVentaByPedido(idPedido);
   return res;
}

async function getVentaByTicket (idTicket: number) {
   const res = await VentaRepository.getVentaByTicket(idTicket);
   return res;
}

async function getVentaDetalle (id: number) {
   const res = await VentaRepository.getVentaDetalle(id);
   return res;
}

async function getVentasByMonth (fecha: any) {
   const res = await VentaRepository.getVentasByMonth(fecha);
   return res;
}

async function getVentasByDay (fecha: any) {
   const res = await VentaRepository.getVentasByDay(fecha);
   return res;
}

async function getVentasByDFechas (fecha_de: any, fecha_a: any) {
   const res = await VentaRepository.getVentasByDFechas(fecha_de, fecha_a);
   return res;
}

async function getVentasByDFechasByResponsable (fecha_de: any, fecha_a: any, filtro:string, id_responsable: number) {
   const res = await VentaRepository.getVentasByDFechasByResponsable(fecha_de, fecha_a, filtro, id_responsable);
   return res;
}

async function getVentasByResponsableCuadratura ( id_responsable: number, id_cuadratura:number) {
   const res = await VentaRepository.getVentasByResponsableCuadratura(id_responsable, id_cuadratura);
   return res;
}

async function listProductByVenta (idVenta: number) {
   const res = await VentaRepository.listProductByVenta(idVenta);
   return res;
}

//ganancia dashboard
async function gananciaTotalMes (fecha: any) {
   const res = await VentaRepository.gananciaTotalMes(fecha);
   return res;
}
async function gananciaTotalDay (fecha: any) {
   const res = await VentaRepository.gananciaTotalDay(fecha);
   return res;
}

// boleta softnet

async function enviarBoleta (token: any, enviarBoleta:any) {
   const res = await VentaRepository.enviarBoletaSoftnet(token, enviarBoleta);
   return res;
}

async function enviarFactura (token: any, enviarFactura:any) {
   const res = await VentaRepository.enviarFacturaSoftnet(token, enviarFactura);
   return res;
}

async function enviarNotaVenta (token: any, data:any) {
   const res = await VentaRepository.enviarNotaVentaSoftnet(token, data);
   return res;
}

//tipoBoletaSoftnet
async function tipoBoletaSoftnet (token: any) {
   const res = await VentaRepository.tipoBoletaSoftnet(token);
   return res;
}


//
async function gananciaTotalFechas (fecha_de: any, fecha_a: any) {
   const res = await VentaRepository.gananciaTotalFechas(fecha_de, fecha_a);
   return res;
}

//venta dashboard
async function ventaTotalMes (fecha: any) {
   const res = await VentaRepository.ventaTotalMes(fecha);
   return res;
}
async function ventaTotalDay (fecha: any) {
   const res = await VentaRepository.ventaTotalDay(fecha);
   return res;
}

async function ventaTotalFechas (fecha_de: any, fecha_a: any) {
   const res = await VentaRepository.ventaTotalFechas(fecha_de, fecha_a);
   return res;
}
//cliente dashboard
async function clientesRegistradoMes (fecha: any) {
   const res = await VentaRepository.clientesRegistradoMes(fecha);
   return res;
}
async function clientesRegistradoDay (fecha: any) {
   const res = await VentaRepository.clientesRegistradoDay(fecha);
   return res;
}

async function clientesRegistradoFechas (fecha_de: any, fecha_a: any) {
   const res = await VentaRepository.clientesRegistradoFechas(fecha_de, fecha_a);
   return res;
}
//empleado dashboard
async function empleadoMes (fecha: any) {
   const res = await VentaRepository.empleadoMes(fecha);
   return res;
}
async function empleadoDay (fecha: any) {
   const res = await VentaRepository.empleadoDay(fecha);
   return res;
}

async function empleadoFechas (fecha_de: any, fecha_a: any) {
   const res = await VentaRepository.empleadoFechas(fecha_de, fecha_a);
   return res;
}
//ventas top
async function getVentasTopByMonth (fecha: any) {
   const res = await VentaRepository.getVentasTopByMonth(fecha);
   return res;
}

async function getVentasTopByDay (fecha: any) {
   const res = await VentaRepository.getVentasTopByDay(fecha);
   return res;
}

async function getVentasTopByDFechas (fecha_de: any, fecha_a: any) {
   const res = await VentaRepository.getVentasTopByDFechas(fecha_de, fecha_a);
   return res;
}
//tipos de pago dashboard
async function tiposPagosMasUsadosMes (fecha: any) {
   const res = await VentaRepository.tiposPagosMasUsadosMes(fecha);
   return res;
}

async function tiposPagosMasUsadosDay (fecha: any) {
   const res = await VentaRepository.tiposPagosMasUsadosDay(fecha);
   return res;
}

async function tiposPagosMasUsadosFechas (fecha_de: any, fecha_a: any) {
   const res = await VentaRepository.tiposPagosMasUsadosFechas(fecha_de, fecha_a);
   return res;
}
//tipos de pago table
async function tiposPagosTableMes (fecha: any) {
   const res = await VentaRepository.tiposPagosTableMes(fecha);
   return res;
}

async function tiposPagosTableDay (fecha: any) {
   const res = await VentaRepository.tiposPagosTableDay(fecha);
   return res;
}

async function tiposPagosTableFechas (fecha_de: any, fecha_a: any) {
   const res = await VentaRepository.tiposPagosTableFechas(fecha_de, fecha_a);
   return res;
}

async function tiposPagosTableFechasByResponsable ( id_responsable: number, id_cuadratura, token) {
   const res = await VentaRepository.tiposPagosTableFechasByResponsable( id_responsable, id_cuadratura, token);
   return res;
}

async function ventasCuadraturaByResponsableFamiliaFormaPago ( id_responsable: number, id_cuadratura, token) {
   const res = await VentaRepository.ventasCuadraturaByResponsableFamiliaFormaPago( id_responsable, id_cuadratura, token);
   return res;
}

async function crearFacturaSoftnet (token: string, factura: {}) {
   const res = await VentaRepository.crearFacturaSoftnet2(token, factura);
   return res;
}

async function createBoletaSoftenet (token: string, boleta: {}) {
   const res = await VentaRepository.crearBoletaSoftnet2(token, boleta);
   return res;
}

async function getReporteVentas (data: {}) {
   const res = await VentaRepository.getReporteVentas(data);
   return res;
}