import axios from 'axios';
import { API_URL_NUPY } from '@/toolbox/defaults/app';

export const tarjetaExistenciaRepository = {

    getTarjetaExistencia: async (fecha, idCompany): Promise<any> => {
        const res = await axios.get(`${API_URL_NUPY}/tarjetaExistenciaDiaria/get/${fecha}/${idCompany}`);
        return res.data;
    },

    crearTarjetaExistencia: async (fecha, idCompany, payload): Promise<any> => {
        const res = await axios.post(`${API_URL_NUPY}/tarjetaExistenciaDiaria/crear/${fecha}/${idCompany}`, { productos: payload });
        return res.data;
    },

    getPDF: async (fecha, idCompany) => {
        const res = await axios.get(`${API_URL_NUPY}/tarjetaExistenciaDiaria/getPDF/${fecha}/${idCompany}`, { responseType: 'blob' })
        return res.data
    },

    getSobrantes: async (data: {}): Promise<any> => {
        const res = await axios.get(`${API_URL_NUPY}/tarjetaExistenciaDiaria/getSobrantes`, { params: data });
        return res.data;
    },

    createDet: async (payload: {}): Promise<any> => {
        const res = await axios.post(`${API_URL_NUPY}/detTarjetaExistencia/register`, payload);
        return res.data;
    },
}
