export const verifyCuponesApplyPrice = (cupones, productsCard) => {
   let priceTotal = 0;
   if(cupones){
      for(let i = 0; i < cupones.length; i++) {
         const cupon = cupones[i];
         const familia = Number(cupon.familia_id);
         const subFamilia = Number(cupon.sub_familia_id);
         const linea = cupon.linea_id;
         const productos = JSON.parse(cupon.productos);

         if(familia !== null){
               let productsFamilia = productsCard.filter(e => e.familia_id === familia);
               let totalCompra = 0;
               if(productsFamilia){
                  productsFamilia.forEach(e => {
                     totalCompra = totalCompra + (Number(e.cantidad || 1) * e.precio_unitario);
                  });
               }

               let descuento = Math.floor((totalCompra*Number(cupon.margen_descuento))/100);

               if (descuento > Number(cupon.max_descuento)) {
                  priceTotal = priceTotal+Number(cupon.max_descuento);
               } else {
                  priceTotal = priceTotal+descuento;
               }
         }

         if(subFamilia !== null){
               let productsSubFamilia = productsCard.filter(e => e.sub_familia_id === subFamilia);
               let totalCompra = 0;
               if(productsSubFamilia){
                  productsSubFamilia.forEach(e => {
                     totalCompra = totalCompra + (Number(e.cantidad || 1) * e.precio_unitario);
                  });
               }
               let descuento = Math.floor((totalCompra*Number(cupon.margen_descuento))/100);

               if (descuento > Number(cupon.max_descuento)) {
                  priceTotal = priceTotal+Number(cupon.max_descuento);
               } else {
                  priceTotal = priceTotal+descuento;
               }
         }

         if(linea !== null){
               let productsLinea = productsCard.filter(e => e.linea_id === linea);
               let totalCompra = 0;
               if(productsLinea){
                  productsLinea.forEach(e => {
                     totalCompra = totalCompra + (Number(e.cantidad || 1) * e.precio_unitario);
                  });
               }
               let descuento = Math.floor((totalCompra*Number(cupon.margen_descuento))/100);

               if (descuento > Number(cupon.max_descuento)) {
                  priceTotal = priceTotal+Number(cupon.max_descuento);
               } else {
                  priceTotal = priceTotal+descuento;
               }
         }

         if(productos !== null){
            let totalCompra = 0;
            productsCard.forEach(e => {
               let existProduct = productos.find(id => id === (e.id || Number(e.id_producto)));
               if(existProduct){
                  totalCompra = totalCompra + (Number(e.cantidad || 1) * e.precio_unitario);
               }
            });
            let descuento = Math.floor((totalCompra*Number(cupon.margen_descuento))/100);

            if (descuento > Number(cupon.max_descuento)) {
               priceTotal = priceTotal+Number(cupon.max_descuento);
            } else {
               priceTotal = priceTotal+descuento;
            }
         }
      }
      return priceTotal;
   }
}

export const verifyCuponApplyPrice = (cupon, productsCard) => {
   let priceTotal = 0;
   const familia = Number(cupon.familia_id);
   const subFamilia = Number(cupon.sub_familia_id);
   const linea = cupon.linea_id;
   const productos = JSON.parse(cupon.productos);

   if(familia !== null){
         let productsFamilia = productsCard.filter(e => e.familia_id === familia);
         let totalCompra = 0;
         if(productsFamilia){
            productsFamilia.forEach(e => {
               totalCompra = totalCompra + (Number(e.cantidad || 1) * e.precio_unitario);
            });
         }

         let descuento = Math.floor((totalCompra*Number(cupon.margen_descuento))/100);

         if (descuento > Number(cupon.max_descuento)) {
            priceTotal = priceTotal+Number(cupon.max_descuento);
         } else {
            priceTotal = priceTotal+descuento;
         }
   }

   if(subFamilia !== null){
         let productsSubFamilia = productsCard.filter(e => e.sub_familia_id === subFamilia);
         let totalCompra = 0;
         if(productsSubFamilia){
            productsSubFamilia.forEach(e => {
               totalCompra = totalCompra + (Number(e.cantidad || 1) * e.precio_unitario);
            });
         }
         let descuento = Math.floor((totalCompra*Number(cupon.margen_descuento))/100);

         if (descuento > Number(cupon.max_descuento)) {
            priceTotal = priceTotal+Number(cupon.max_descuento);
         } else {
            priceTotal = priceTotal+descuento;
         }
   }

   if(linea !== null){
         let productsLinea = productsCard.filter(e => e.linea_id === linea);
         let totalCompra = 0;
         if(productsLinea){
            productsLinea.forEach(e => {
               totalCompra = totalCompra + (Number(e.cantidad || 1) * e.precio_unitario);
            });
         }
         let descuento = Math.floor((totalCompra*Number(cupon.margen_descuento))/100);

         if (descuento > Number(cupon.max_descuento)) {
            priceTotal = priceTotal+Number(cupon.max_descuento);
         } else {
            priceTotal = priceTotal+descuento;
         }
   }

   if(productos !== null){
      let totalCompra = 0;
      productsCard.forEach(e => {
         let existProduct = productos.find(id => id === (e.id || Number(e.id_producto)));
         if(existProduct){
            totalCompra = totalCompra + (Number(e.cantidad || 1) * e.precio_unitario);
         }
      });
      let descuento = Math.floor((totalCompra*Number(cupon.margen_descuento))/100);

      if (descuento > Number(cupon.max_descuento)) {
         priceTotal = priceTotal+Number(cupon.max_descuento);
      } else {
         priceTotal = priceTotal+descuento;
      }
   }
    return priceTotal;
}
