import React, { useEffect, useState } from "react";
import { salas } from '../../../../service/services/Salas.service';
import { Loading } from '../../../../components/common/Loading/Loading';
import { Form, Formik } from 'formik';
import { Divider, Grid, TextField, Typography, Tooltip, FormLabel, TableContainer, Table, TableBody, Paper, TableCell, TableRow } from '@mui/material';
import PriorityHighIcon from '@mui/icons-material/PriorityHigh';
import { Snackbar, Alert } from '@mui/material';
import { KEY_EMPRESA, KEY_USER_DATA } from "@/toolbox/constants/local-storage";
import { readLocalStorage } from "@/toolbox/helpers/local-storage-helper";

type Mesa = {
    id?: number
    nombre: string
    fila: number
    columna: number
}

export const AddSala: React.FC<any> = (props: any): JSX.Element => {

    const { onListSala, closeModal, sala } = props;
    const [loadData, setLoadData] = React.useState<boolean>(false);
    // eslint-disable-next-line
    const [data, setData] = useState({
        id: sala && sala.id ? sala.id : 0,
        nombre: sala && sala.nombre ? sala.nombre : '',
        // mesas: sala && sala.mesas ? sala.mesas : 0,
        columnas: sala?.columnas || 1,
        filas: sala?.filas || 1,
        state: sala && sala.state ? sala.state : '',
    });

    const [snackBarConfig, setSnackBarConfig] = useState<any>({
        open: false,
        severity: 'error',
        message: 'Error',
        autoHideDuration: 3000,
    });
    const [diseñoSala, setDiseñoSala] = useState<Mesa[]>([])

    const SessionUser: any = readLocalStorage(KEY_USER_DATA);
    const idSucursal = SessionUser.user?.id_sucursal;

    useEffect(() => {
        actualizarDiseñoSala(data.filas, data.columnas)
    }, [])

    const validateForm = (values) => {
        let errors: any = {};

        if (!values.nombre) errors.nombre = "nombre requerido";
        if (!values.columnas) errors.columnas = "cantidad requerido";

        return errors;
    }

    const onSubmit = async (values, { resetForm }) => {

        const mesas = diseñoSala.filter(mesa => mesa.nombre !== '')
        if (!mesas.length) return setSnackBarConfig(prev => ({
            ...prev,
            open: true,
            message: 'Ingrese una mesa.',
        }));

        setLoadData(true);
        const payload = {
            nombre: values.nombre,
            n_mesas: mesas.length,
            filas: values.filas,
            columnas: values.columnas,
            mesas: mesas
        }

        const response = await (!sala ? salas.createV2(payload) : salas.updateV2(sala.id, payload))
        if (response?.status !== 200) {
            setSnackBarConfig(prev => ({
                ...prev,
                open: true,
                message: `${response?.message || ''}`,
            }))
        } else {
            onListSala()
            closeModal()
        }
        setLoadData(false);
    }

    const actualizarDiseñoSala = (numFilas: number, numColumnas: number) => {
        if (numFilas === 10 && numColumnas === 10) {
            setSnackBarConfig(prev => ({
                ...prev,
                open: true,
                severity: 'warning',
                message: 'Limite alcanzado!. Si desea crear más mesas cree otra sala.',
            }))
        }
        
        const nuevoDiseñoSala = [];
        for (let i = 0; i < numFilas; i++) {
            for (let j = 0; j < numColumnas; j++) {
                const mesaExistente = (!sala ? diseñoSala : sala?.all_mesas).find(mesa => mesa.fila === i && mesa.columna === j);
                if (mesaExistente) {
                    nuevoDiseñoSala.push(mesaExistente);
                } else {
                    nuevoDiseñoSala.push({
                        nombre: '',
                        fila: i,
                        columna: j,
                    });
                }
            }
        }
        setDiseñoSala(nuevoDiseñoSala);
    };

    const renderTabla = () => {
        const rows = [];
        const numFilas = Math.max(...diseñoSala.map(mesa => mesa.fila)) + 1;
        const numColumnas = Math.max(...diseñoSala.map(mesa => mesa.columna)) + 1;

        for (let i = 0; i < numFilas; i++) {
            const cells = [];
            for (let j = 0; j < numColumnas; j++) {
                const mesa = diseñoSala.find(m => m.fila === i && m.columna === j);
                cells.push(
                    <TableCell key={`cell-${i}-${j}`}>
                        <TextField
                            fullWidth
                            variant="outlined"
                            name={`celdas[${i}][${j}]`}
                            value={mesa ? mesa.nombre : ''}
                            onChange={(event) => {
                                const newValue = event.target.value;
                                const updatedDiseñoSala = [...diseñoSala];
                                const index = updatedDiseñoSala.findIndex(m => m.fila === i && m.columna === j);
                                if (index !== -1) {
                                    updatedDiseñoSala[index] = { ...updatedDiseñoSala[index], nombre: newValue.toUpperCase() };
                                    setDiseñoSala(updatedDiseñoSala);
                                }
                            }}
                            autoComplete="off"
                            sx={{
                                backgroundColor: mesa.nombre !== '' ? '#229954' : ''
                            }}
                            InputProps={{
                                style: { color: mesa.nombre !== '' ? '#ffffff' : '' }
                            }}
                        />
                    </TableCell>
                );
            }
            rows.push(<TableRow key={`row-${i}`}>{cells}</TableRow>);
        }
        return rows;
    };

    return (
        <>
            {loadData && <Loading />}
            <Snackbar
                open={snackBarConfig.open}
                autoHideDuration={snackBarConfig.autoHideDuration}
                onClose={() => setSnackBarConfig(prev => ({ ...prev, open: false }))}
                anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
            >
                <Alert
                    onClose={() => setSnackBarConfig(prev => ({ ...prev, open: false }))}
                    severity={snackBarConfig.severity}
                    variant="filled"
                >
                    {snackBarConfig.message}
                </Alert>
            </Snackbar>

            <Formik enableReinitialize initialValues={data} validate={(values) => validateForm(values)} onSubmit={onSubmit}>
                {({ values, errors, touched, handleSubmit, handleChange, setFieldValue }) => {
                    return (
                        <Form onSubmit={handleSubmit}>
                            <Grid container spacing={2}>
                                <Grid item xs={12}>
                                    <Typography variant="subtitle1" className="custom-input"><b>Nombre:</b></Typography>
                                    <TextField
                                        id="nombre"
                                        type="text"
                                        name="nombre"
                                        fullWidth
                                        value={values.nombre}
                                        onChange={handleChange}
                                        placeholder="Nombre de la sala"
                                        error={errors.nombre && touched.nombre ? true : false}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <Divider>Diseño</Divider>
                                </Grid>
                                <Grid item xs={6}>
                                    <Typography variant="subtitle1" className="custom-input"><b>Columnas:</b></Typography>
                                    <TextField
                                        id="columnas"
                                        type="number"
                                        name="columnas"
                                        fullWidth
                                        value={values.columnas}
                                        onChange={(event) => {
                                            const numColumnas = parseInt(event.target.value);
                                            if (numColumnas < 1) return
                                            handleChange(event);
                                            actualizarDiseñoSala(values.filas, numColumnas);
                                        }}
                                        placeholder="N° columnas"
                                        inputProps={{ min: 1, max: 10 }}
                                    />
                                </Grid>
                                <Grid item xs={6}>
                                    <Typography variant="subtitle1" className="custom-input"><b>Filas:</b></Typography>
                                    <TextField
                                        id="filas"
                                        type="number"
                                        name="filas"
                                        fullWidth
                                        value={values.filas}
                                        onChange={(event) => {
                                            const numFilas = parseInt(event.target.value);
                                            if (numFilas < 1) return
                                            handleChange(event);
                                            actualizarDiseñoSala(numFilas, values.columnas);
                                        }}
                                        placeholder="N° filas"
                                        inputProps={{ min: 1, max: 10 }}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <TableContainer component={Paper}>
                                        <Table sx={{ background: '' }}>
                                            <TableBody>
                                                {renderTabla()}
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                </Grid>
                            </Grid>
                            <Divider sx={{ my: 2 }} />
                            <div className="form-group mb-3 buton_container_modal">
                                <button type="submit" className="btn btn-primary">
                                    {sala ? "Editar Sala" : "Agregar Sala"}
                                </button>
                            </div>
                        </Form>
                    )
                }}

            </Formik>
        </>
    )
}
