import React, { useEffect, useState, useRef } from 'react';
import { useLocalStorage } from '@toolbox/hooks/local-storage.hook';
import { TOKEN_API, RUC_EMPRESA, KEY_BENEFICIO_PRO, KEY_EMPRESA, KEY_BENEFICIO_MON, KEY_USER_DATA } from "@/toolbox/constants/local-storage";
import { FormControl, createTheme, MenuItem, Select, Button, ThemeProvider, Autocomplete, Stack, Chip, RadioGroup, FormControlLabel, Radio } from "@mui/material";
import { Loading } from '@/components/common/Loading/Loading';
import TextField from '@mui/material/TextField';
import TableHead from '@mui/material/TableHead';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert, { AlertProps } from '@mui/material/Alert';
import Grid from '@mui/material/Grid';
import { Divider } from '@mui/material';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { styled } from '@mui/material/styles';
import { ticketService } from '@/service/services/Ticket.service';
import { ventaService } from '@/service/services/Venta.service';
import { esES } from "@mui/material/locale";
import { useReactToPrint } from 'react-to-print';
import { ComponentPrint } from '@/components/common/ComponentPrint';
import { PopupComponent } from '@/components/common/Popup';
import { Productos } from '../Productos';
import { STATUS_FINALIZADO } from '@/toolbox/constants/status-ticket';
import { generateAnuladoParcial } from './AnuladoParcial';
import { handleGenerateBoleta } from './GenerarBoleta';
import { ComponentPrintFactura } from '@/components/common/ComponentFactura';
import moment from 'moment';

import Box from '@mui/material/Box';
import Collapse from '@mui/material/Collapse';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { validateCupon } from '@/toolbox/helpers/validateCoupons';
import { ComponentCuponTicket } from '@/components/common/ComponentCuponTicket';
import { clientesService } from '@/service/services/Clientes.service';
import { Popup } from '@/views/Editor/components/Popup';
import { AddCliente } from '@/views/Clientes/componentes/AddCliente';
import { productoService } from '@/service/services/Producto.service';
import { readLocalStorage } from '@/toolbox/helpers/local-storage-helper';
import PersonAddAltIcon from '@mui/icons-material/PersonAddAlt';
import { ROLE_ADMIN, ROLE_CAJERO } from '@/toolbox/defaults/static-roles';
import { cajas } from '@/service/services/Cajas.service';
import { userService } from '@/service/services/User.service';

type Props = {
   idPedido: number,
   setOpenPopup: any,
   loadDataList: any,
   selected: "faltantes" | "finalizado",
   detailMode: boolean,
   anularMode?: boolean,
   cupones?: any
   pagarProducts?: boolean
   onList?: () => Promise<void>
   clientePedido: any
}

const theme = createTheme(
   {
      palette: {
         primary: { main: '#1976D2' },
      },
   },
   esES,
);

const StyledButton = styled(Button)(({ theme }) => ({
   textTransform: 'none',
}));

const Titulo = styled('div')(({ theme }) => ({
   ...theme.typography.button,
   padding: theme.spacing(1),
   fontWeight: 'bold',
   fontSize: '18px',
   color: 'black',
}));

const TipoPago = [
   { id: 1, nombre: 'Cash' },
];

const Alert = React.forwardRef<HTMLDivElement, AlertProps>(function Alert(
   props,
   ref,
) {
   return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});


export const PagarPedido: React.FC<any> = (props: Props): JSX.Element => {

   const { idPedido, loadDataList, setOpenPopup, detailMode, anularMode, cupones, pagarProducts, clientePedido, onList } = props;
   const userData = readLocalStorage(KEY_USER_DATA)
   const fechaActual = new Date();
   const año = fechaActual.getFullYear();
   const mes = String(fechaActual.getMonth() + 1).padStart(2, '0');
   const dia = String(fechaActual.getDate()).padStart(2, '0');

   const fechaFormateada = `${año}-${mes}-${dia}`;

   const [dataEmpresa] = useLocalStorage(KEY_EMPRESA, {
      id: 0, id_tipo_empresa: 0, equivalencia_puntos: 0, country_abbreviation: ""
   });
   const [ruc_empresa] = useLocalStorage(RUC_EMPRESA, "");
   const [token] = useLocalStorage(TOKEN_API, "");
   const componentRef = useRef();

   const [pedido, setPedido] = React.useState<any>(null);
   const [ticket, setTicket] = React.useState<any>(null);
   const [recibido, setRecibido] = React.useState<any>(0);
   const [propina, setPropina] = React.useState<number>(0);
   const [vuelto, setVuelto] = React.useState<number>(0);
   const [detalle, setDetalle] = React.useState<Array<any>>([]);
   const [loadData, setLoadData] = React.useState<boolean>(false);
   const [dataImprimir, setDataImprimir] = useState(null);
   const [dataImprimirFactura, setDataImprimirFactura] = useState(null);
   const [pagos, setPagos] = useState<Array<any>>(TipoPago);
   const [pagosSelect, setPagoSelect] = useState<number>(0);
   const [namepagosSelect, setNamePagoSelect] = useState<string>('EFECTIVO');
   const [arrayIdsProducto, setArrayIdsProducto] = useState<any>([]);
   const [openSnackError, setOpenSnackError] = useState<boolean>(false);
   const [areaNegocio, setAreaNegocio] = useState<any>({});
   const [notaVenta, setNotaVenta] = useState<boolean>(false);
   const [folioNotaVenta, setFolioNotaVenta] = useState<any>('');
   const [clientes, setClientes] = useState<Array<any>>([]);
   const [clienteSelect, setClienteSelect] = useState<any>("");
   const [rutCliente, setRutCliente] = useState<any>('');
   const [openPopupClient, setOpenPopupClient] = React.useState<boolean>(false);
   // eslint-disable-next-line
   const [showPDF, setShowPDF] = useState({
      open: false,
      timbre: '',
      montoNeto: 0,
      folio: '',
      idVenta: 0
   });
   const [beneficioProducto] = useLocalStorage(KEY_BENEFICIO_PRO, []);
   const [codigoTransbank, setCodigoTransbank] = useState<any>('');
   const [errorTransB, setErrorTranB] = useState<boolean>(false);

   // const [cantidadProduct, setCantidadProduct] = React.useState<number>();
   // const [newProducts, setNewProducts] = React.useState<any>([]);
   const [showModalProducts, setShowModalProducts] = React.useState(false);
   const [selectIndex, setSelectIndex] = React.useState<any>(0);
   // const [dataLocal] = useLocalStorage(PRODUCTS_CARD, []);
   const [beneficioMonto] = useLocalStorage(KEY_BENEFICIO_MON, []);
   const [snackBarConfig, setSnackBarConfig] = useState<any>({
      open: false,
      severity: 'error',
      message: 'Error',
      autoHideDuration: 3000,
   });

   const [tipoCupon, secTipoCupon] = React.useState([]);
   const [ImprimirCupon, setImprimirCupon] = React.useState<boolean>(false);
   const [arraySelectedProducts, setArraySelectedProducts] = React.useState([]);
   const [tipoImpresion, setTipoImpresion] = React.useState<string>('')
   const [tituloImpresion, setTituloImpresion] = React.useState<string>('')
   const [openModalClient, setOpenModalClient] = React.useState<boolean>(false);
   const dataUsuario = readLocalStorage(KEY_USER_DATA);

   const [cajasDisponibles, setCajasDisponible] = React.useState<any>([]);
   const [cajaSelected, setCajaSelected] = React.useState<any>(null);

   const handlePrint = useReactToPrint({
      content: () => componentRef.current,

   });

   useEffect(() => {
      getClientes()
      cajas.cargarCajas().then((data) => {
         setCajasDisponible(data.cajas)
      })
   }, [])

   useEffect(() => {
      if (pedido?.cliente) {
         setClienteSelect({
            label: pedido?.cliente?.nombre, key: pedido?.cliente?.id, type: pedido?.cliente?.id_tipoPersona, rut: pedido?.cliente?.rut, direccion: pedido?.cliente?.direccion, comuna: pedido?.cliente?.comuna, ciudad: pedido?.cliente?.ciudad, giro: pedido?.cliente?.giro,
            extranjero: pedido?.cliente?.extranjero, doc_iden: pedido?.cliente?.doc_iden
         })
      } else {
         const clientFind = clientes.find(item => item.nombre == 'GENERICO')
            if (!!clientFind) {
               setClienteSelect({
                  label: clientFind.nombre, key: clientFind.id, type: clientFind.id_tipoPersona, rut: clientFind.rut, direccion: clientFind.direccion, comuna: clientFind.comuna, ciudad: clientFind.ciudad, giro: clientFind.giro,
                  extranjero: clientFind.extranjero, doc_iden: clientFind.doc_iden
               })
            }
      }
   }, [pedido, clientes])

   useEffect(() => {
      if (dataImprimir !== null) {
         handlePrint();
      }
      // eslint-disable-next-line
   }, [dataImprimir])

   useEffect(() => {
      if (dataImprimirFactura !== null) {
         handlePrint();
      }
      // eslint-disable-next-line
   }, [dataImprimirFactura])

   useEffect(() => {
      if (ImprimirCupon !== null) {
         handlePrint();
      }
      // eslint-disable-next-line
   }, [ImprimirCupon])

   useEffect(() => {
      if (recibido - pedido?.monto_total > 0) {
         setVuelto(recibido - pedido?.monto_total)
      } else {
         setVuelto(0)
      }
      // eslint-disable-next-line
   }, [recibido])

   useEffect(() => {
      let allFamilias = [];
      fetch(`https://api2.softnet.cl/formaPago`, {
         method: 'GET',
         headers: {
            'Access-Control-Allow-Origin': '*',
            'Content-Type': 'application/x-www-form-urlencoded',
            token: token,
         },
      })
         .then(data => data.json())
         .then(data => {
            setPagos([...data])
            setPagoSelect(data[0].id || 0)
            const formaPagoInitial = data.find(item => item.nombre == "DEBITO O CREDITO")
            if (!!formaPagoInitial) {
               setPagoSelect(formaPagoInitial.id)
               setNamePagoSelect(formaPagoInitial.nombre)
            }
         })
         .catch(error => {
            console.log(error);
         });

      // fetch(`https://api2.softnet.cl/areaNegocio`, {
      //    method: 'GET',
      //    headers: {
      //       'Access-Control-Allow-Origin': '*',
      //       'Content-Type': 'application/x-www-form-urlencoded',
      //       token: token,
      //    },
      // })
      //    .then(data => data.json())
      //    .then(async data => {
      //       if (data.length > 0) {
      //          setAreaNegocio(data[0]);
      //       }
      //    })
      //    .catch(error => {
      //       console.log(error)
      //    });

      // fetch(`https://api2.softnet.cl/familia`, {
      //    method: 'GET',
      //    headers: {
      //       'Access-Control-Allow-Origin':'*',
      //       'Content-Type': 'application/x-www-form-urlencoded',
      //       token: token,
      //    },
      //    })
      //    .then(familiasData => familiasData.json())
      //    .then(async familiasData => {
      //       allFamilias = familiasData;
      //    })
      //    .catch(error => {
      //       alert("NO HAY FAMILIA");
      // });
      productoService.getFamiliesAndSubFamilies().then((data) => {
         if (data.status === 200) {
            allFamilias = data.families || [];
         } else {
            alert("NO HAY FAMILIA");
         }
      });

      if (idPedido) {
         setLoadData(true);
         ticketService.getPedidoDetalle(idPedido).then(async (data) => {
            if (data?.status === 200) {
               setPedido(data.pedido);
               setRutCliente(data.pedido.cliente?.rut || '');
               setRecibido(data.pedido?.monto_total);
               setTicket((data.tickets).length);

               let arrayProducts = [];
               // eslint-disable-next-line
               (data.tickets).map((item) => {
                  for (let i = 0; i < item.detalle_ticket.length; i++) {
                     const productos = item.detalle_ticket[i].productos;
                     arrayProducts.push({
                        "cantidad": item.detalle_ticket[i].cantidad,
                        "id_producto": item.detalle_ticket[i].id_producto,
                        "codigo_erp": productos?.codigo_erp,
                        "nombre": productos?.nombre,
                        "id_tipo_producto": productos?.id_tipo_producto,
                        "precio_standar": productos?.precio_standar,
                        // "precio_oferta": productos?.precio_oferta,
                        "precio_oferta": productos && validatePrice(productos),
                        "cantidad_peso": productos?.cantidad_peso,
                        "precio_unitario": item.detalle_ticket[i].precio_unitario,
                        "subProductos": item.detalle_ticket[i].subProductos,
                        "id_unidad_medida": productos?.id_unidad_medida,
                        "unidad_medida": productos?.unidad_medida?.abbreviation,
                        "familia_id": productos?.familia_id,
                        "sub_familia_id": productos?.sub_familia_id,
                        "linea_id": productos?.linea_id,
                        "idtipo_impuesto": productos?.idtipo_impuesto
                     });
                  }
               });
               setDetalle(arrayProducts);
               validateCupones(cupones, arrayProducts, secTipoCupon, allFamilias);
               //saveLocalStorage(PRODUCTS_CARD, arrayProducts);
               setLoadData(false)
            } else {
               setLoadData(false)
            }
         })
      }
   }, // eslint-disable-next-line
      []);

   const getClientes = async () => {
      setLoadData(true);
      //1: persona natural
      //2: persona juridica
      await clientesService.getClientes().then((data) => {
         setClientes(data.cliente.map((el) => {
            return {
               label: el.nombre, key: el.id, type: el.id_tipoPersona, rut: el.rut, direccion: el.direccion, comuna: el.comuna, ciudad: el.ciudad, giro: el.giro,
               extranjero: el.extranjero, doc_iden: el.doc_iden
            };
         }));
         // if (pedido?.cliente?.nombre) {
         //    setClienteSelect({
         //       label: pedido?.cliente?.nombre, key: pedido?.cliente?.id, type: pedido?.cliente?.id_tipoPersona, rut: pedido?.cliente?.rut, direccion: pedido?.cliente?.direccion, comuna: pedido?.cliente?.comuna, ciudad: pedido?.cliente?.ciudad, giro: pedido?.cliente?.giro,
         //       extranjero: pedido?.cliente?.extranjero, doc_iden: pedido?.cliente?.doc_iden
         //    })
         // } else {
         //    const clientFind = data.cliente.find(item => item.nombre == 'GENERICO')
         //    if (!!clientFind) {
         //       setClienteSelect({
         //          label: clientFind.nombre, key: clientFind.id, type: clientFind.id_tipoPersona, rut: clientFind.rut, direccion: clientFind.direccion, comuna: clientFind.comuna, ciudad: clientFind.ciudad, giro: clientFind.giro,
         //          extranjero: clientFind.extranjero, doc_iden: clientFind.doc_iden
         //       })
         //    }
         // }

      })
      setLoadData(false);
   }

   const validateCupones = (cupones, products, secTipoCupon, allFamilias) => {
      validateCupon(cupones, products, secTipoCupon, allFamilias);
   }

   const validatePrice = (product) => {
      const dateToday = moment();
      const value = product.caducidad_precio_oferta;
      const expirationPrice = moment(value, 'DD-MM-YYYY');

      const standarPrice = product.precio_standar || product.precio;
      const offerPrice = product.precio_oferta;

      if (expirationPrice !== null && offerPrice !== null) {
         if (expirationPrice.isSameOrAfter(dateToday)) {
            return offerPrice;
         } else {
            return standarPrice;
         }
      } else {
         return standarPrice;
      }
   }

   async function tipoBoletaSoftnet(token: any) {
      const res = await ventaService.tipoBoletaSoftnet(token);
      return res;
   }

   const handlePagoChange = (e) => {
      setPagoSelect(e.target.value || 0);
   }

   const handleChangeMontoRecibido = (e) => {
      if (e.target.value >= 0) {
         setRecibido(e.target.value);
      }
   }

   const handleKeyMontoRecibido = (e) => {
      var key = e.keyCode || e.charCode;
      if (key === 96 && recibido[0] === 0) {
         setRecibido(recibido.replace(/^0+/, ''));
      }

   }

   const handleChangePropina = (e) => {
      if (e.target.value > 0) {
         setPropina(e.target.value);
      }
   }

   const handleChangeCantidad = (e, index) => {
      let card: Array<any> = detalle;
      card[index].newCantidad = e.target.value;
      // setNewProducts([...card]);
      setDetalle([...card]);
      // generateAnuladoParcial()
      // saveLocalStorage(PRODUCTS_CARD, card);
   }
   const handleDeleteProduct = (index) => {
      let card: Array<any> = detalle;
      card[index].wasDeleted = true;
      setDetalle([...card]);
   }

   const handleChangeProduct = (index) => {
      setShowModalProducts(true);
      setSelectIndex(index);
   }

   const handleCloseError = (event?: React.SyntheticEvent | Event, reason?: string) => {
      if (reason === 'clickaway') { return; }
      setOpenSnackError(false);
   };

   const saveProducts = (product, index) => {
      let exits = false;
      if (arraySelectedProducts.length > 0) {
         for (let i = 0; i < arraySelectedProducts.length; i++) {
            if (arraySelectedProducts[i].id_producto === product.id_producto) {
               exits = true;
            }
         }
      }
      if (!exits) {
         setArraySelectedProducts([
            ...arraySelectedProducts,
            product
         ])
         let card: Array<any> = detalle;
         card.splice(index, 1);
         setDetalle([...card]);

      } else {
         setSnackBarConfig(prev => ({
            ...prev,
            open: true,
            message: 'No puede seleccionar el mismo producto',
         }));
      }
   }

   const removeProductSelected = (product, index) => {
      let products: Array<any> = arraySelectedProducts;
      if (products.length > 0) {
         products.splice(index, 1);
         let card: Array<any> = detalle;
         card.push(product)
         setDetalle([...card]);
      } else {
         setSnackBarConfig(prev => ({
            ...prev,
            open: true,
            message: 'No tiene ningun producto seleccionado',
         }));
      }
      setArraySelectedProducts([...products])
   }

   const amountSelectedProducts = () => {
      let monto_neto_calculado = 0;
      if (arraySelectedProducts.length > 0) {
         for (let i = 0; i < arraySelectedProducts.length; i++) {
            let monto_neto_producto1 = 0;
            let cantidad = 0;

            if (arraySelectedProducts[i].id_unidad_medida) {
               monto_neto_producto1 = /* Math.ceil((arraySelectedProducts[i].precio_unitario/arraySelectedProducts[i].cantidad_peso) /1.19) ||  */arraySelectedProducts[i].precio_unitario;

               if (arraySelectedProducts[i].id_unidad_medida !== 1) {
                  cantidad = Math.ceil(arraySelectedProducts[i].cantidad);
               } else {
                  cantidad = arraySelectedProducts[i].cantidad;
               }
            } else {
               monto_neto_producto1 = /* Math.ceil(arraySelectedProducts[i].precio_unitario /1.19) ||  */arraySelectedProducts[i].precio_unitario;
               cantidad = arraySelectedProducts[i].cantidad;
            }

            if (arraySelectedProducts[i].id_unidad_medida && arraySelectedProducts[i].id_unidad_medida !== 1) {
               monto_neto_calculado = monto_neto_calculado + monto_neto_producto1 * cantidad;
            } else {
               monto_neto_calculado = monto_neto_calculado + monto_neto_producto1 * arraySelectedProducts[i].cantidad;
            }
         }
      }

      return monto_neto_calculado;
   }

   const handleGenerateDocumentoPorParte = (tipo_doc) => {
      handleGenerateBoleta(
         dataEmpresa, codigoTransbank, pagosSelect, setErrorTranB, setSnackBarConfig,
         setLoadData, arrayIdsProducto, setArrayIdsProducto, tipoBoletaSoftnet, token,
         arraySelectedProducts, beneficioProducto, pedido, ruc_empresa, recibido, setShowPDF, setDataImprimir, setDataImprimirFactura,
         setOpenPopup, loadDataList, namepagosSelect, propina, tipo_doc, setImprimirCupon, secTipoCupon, areaNegocio, notaVenta, folioNotaVenta, rutCliente, idPedido, clienteSelect,
         true, true, amountSelectedProducts(), setArraySelectedProducts, !Boolean(detalle.length)
      )
   }

   // const ValiarBeneficio = (id: number, cantidad: number) => {
   //    if (Number(id)){
   //       let result = beneficioProducto.find(prod => prod.id_producto === Number(id))
   //       if ( !result ) return 0
   //       if (  Number(cantidad) < result.cantidad_productos ) return 0
   //       let multiplo = Number(cantidad) / result.cantidad_productos
   //       let descuento = Math.trunc(multiplo) * (result.cantidad_puntos * dataEmpresa.equivalencia_puntos)
   //       return descuento;
   //       // return result.cantidad_puntos;
   //    } else {
   //       return 0;
   //    }
   // }

   // const handleGenerateNotaVenta = (paid:any) => {
   //    if (paid == 1) {
   //       console.log('pagadoooooo')
   //       handleGenerateDocumento('notaVenta')
   //    } else {
   //       console.log('no pagadooooooo')
   //    }
   // }

   const handleGenerateDocumento = (tipo_doc: string) => {
      handleGenerateBoleta(
         dataEmpresa, codigoTransbank, pagosSelect, setErrorTranB, setSnackBarConfig,
         setLoadData, arrayIdsProducto, setArrayIdsProducto, tipoBoletaSoftnet, token,
         detalle, beneficioProducto, pedido, ruc_empresa, recibido, setShowPDF, setDataImprimir, setDataImprimirFactura,
         setOpenPopup, loadDataList, namepagosSelect, propina, tipo_doc, setImprimirCupon, secTipoCupon, areaNegocio, notaVenta, folioNotaVenta, rutCliente, idPedido, clienteSelect,
         false, true, 0, setArraySelectedProducts, true, paid
      )
   }

   const handleGenerateFactura = async () => {
      if (!clienteSelect) {
         setSnackBarConfig(prev => ({
            ...prev,
            open: true,
            message: 'Seleccione un cliente.',
         }))
         return
      }

      if (namepagosSelect === '') {
         setSnackBarConfig(prev => ({
            ...prev,
            open: true,
            message: 'Seleccione un método de pago',
         }))
         return
      }

      if (detalle.find(item => item.idtipo_impuesto === null)) {
         setSnackBarConfig(prev => ({
            ...prev,
            open: true,
            message: 'Algunos productos no tienen el tipo de impuesto declarado',
         }))
         return
      }


      setLoadData(true)

      const dataUser = await userService.getUserById(dataUsuario.user.id_usuario)

      let copyProducts: Array<any> = []
      let monto_neto_calculado: number = 0
      let monto_exento: number = 0

      for (let i = 0; i < detalle.length; i++) {

         let precio = 0;

         if (detalle[i].precio_oferta) {
            precio = validatePrice(detalle[i]);
         } else {
            if (detalle[i].precio) {
               precio = detalle[i].precio;
            } else {
               precio = detalle[i].precio_standar;
            }
         }

         let filtro_cantidad = 0;

         let monto_neto_producto1 = 0;

         if (detalle[i].cantidad_peso) {
            monto_neto_producto1 = precio
            if (detalle[i].id_unidad_medida !== 1) {
               filtro_cantidad = Math.ceil(detalle[i].cantidad);
            } else {
               filtro_cantidad = detalle[i].cantidad;
            }
         } else {
            monto_neto_producto1 = precio;
            filtro_cantidad = detalle[i].cantidad;
         }

         copyProducts.push({
            "VlrCodigo": detalle[i].codigo_erp,
            "NmbItem": detalle[i].nombre,
            "PrcItem": monto_neto_producto1,
            "QtyItem": filtro_cantidad,
            "MontoItem": monto_neto_producto1 * filtro_cantidad,
            "Afecto": (detalle[i]?.idtipo_impuesto === 2) ? false : true
         })

         /*AFECTO */
         if (detalle[i]?.idtipo_impuesto === 1) {
            if (detalle[i].id_unidad_medida && detalle[i].id_unidad_medida !== 1) {
               monto_neto_calculado = monto_neto_calculado + monto_neto_producto1 * filtro_cantidad;
            } else {
               monto_neto_calculado = monto_neto_calculado + monto_neto_producto1 * detalle[i].cantidad;
            }
         }

         /*EXENTO */
         if (detalle[i]?.idtipo_impuesto === 2) {
            monto_exento += monto_neto_producto1 * filtro_cantidad
         }

         arrayIdsProducto.push(detalle[i].id_producto);
         setArrayIdsProducto(arrayIdsProducto);
      }

      const tipo_factura: string = detalle.every(item => item?.idtipo_impuesto === 2) ? '34' : '33'
      let MntNeto: number = 0
      let IVA: number = 0
      let MntExe: number = 0
      let MntTotal: number = 0

      if (tipo_factura === '33') {
         MntNeto = Math.round(monto_neto_calculado / 1.19);
         IVA = Math.round(MntNeto * 0.19);
         MntExe = monto_exento
         MntTotal = MntNeto + IVA + MntExe
      }

      if (tipo_factura === '34') {
         MntNeto = monto_exento
         IVA = 0
         MntExe = monto_exento
         MntTotal = monto_exento
      }

      const DTE = {
         "DTE": {
            "Documento": {
               "Encabezado": {
                  "IdDoc": {
                     "TipoDTE": tipo_factura,
                     "FchEmis": fechaFormateada
                  },
                  "Receptor": {
                     "RUTRecep": clienteSelect?.rut || '66666666-6',
                     "RznSocRecep": clienteSelect?.label || '---',
                     "GiroRecep": clienteSelect?.giro || '-',
                     "DirRecep": clienteSelect?.direccion || '-',
                     "CmnaRecep": clienteSelect?.comuna || '-',
                     "CiudadRecep": clienteSelect?.ciudad || '-'
                  },
                  "Totales": {
                     "MntNeto": MntNeto,
                     "MntExe": MntExe,
                     "IVA": IVA,
                     "MntTotal": MntTotal
                  }
               },
               "Detalle": copyProducts
            },
            "Adicionales": {
               "Uno": namepagosSelect
            }
         }
      }

      const documentoGenerado = await ventaService.crearFacturaSoftnet(token, DTE)

      if (!documentoGenerado.mensaje || documentoGenerado.mensaje !== 'Dte generado con exito.') {
         setSnackBarConfig(prev => ({
            ...prev,
            open: true,
            message: 'Los datos a procesar son inválidos',
         }))
         return setLoadData(false)
      }
      const idcaja = dataUser?.user?.idcaja
      const nombreCaja = dataUser?.user?.caja

      const ventaID = await ventaService.generarBoleta(pedido?.id, pedido?.monto_total,
         recibido, 0,
         detalle, (codigoTransbank || null), documentoGenerado.folio, 1, documentoGenerado.timbre,
         (documentoGenerado.pdf || ""), MntNeto, namepagosSelect, propina, false, (pedido?.id_mesa || null),
         monto_exento, idcaja
      )

      setShowPDF({
         idVenta: ventaID,
         montoNeto: MntNeto,
         timbre: documentoGenerado?.timbre || "",
         folio: documentoGenerado?.folio,
         open: true
      })

      ventaService.getVentaDetalle(ventaID).then((res) => {

         if (res.status === 200) {
            const arrayProducts = res.tickets.flatMap((item) =>
               item.detalle_ticket.map((detalle) => {
                  const productos = detalle.productos
                  return {
                     "cantidad": detalle.cantidad,
                     "precio_unitario": detalle.precio_unitario,
                     "id_producto": detalle.id_producto,
                     "codigo_erp": productos?.codigo_erp,
                     "nombre": productos?.nombre,
                     "id_tipo_producto": productos?.id_tipo_producto,
                     "precio_standar": productos?.precio_standar,
                     "precio_oferta": productos && validatePrice(productos),
                     "unidad_medida": productos?.unidad_medida,
                  }
               })
            )

            const venta = {
               detalle: arrayProducts,
               ventaDetalle: res.venta,
               folio: documentoGenerado.folio,
               timbre: documentoGenerado.timbre,
               montoNeto: tipo_factura === '34' ? 0 : MntNeto,
               IVA: IVA,
               clienteSelect: clienteSelect,
               nombreCajero: dataUsuario.user.rol === ROLE_CAJERO ? dataUsuario.user.nombre : null,
               exento: MntExe,
               titulo: tipo_factura === '34' ? 'FACTURA ELECTRONICA EXENTA' : 'FACTURA ELECTRONICA',
               caja: nombreCaja || ''
            }

            setDataImprimir(venta)
            setOpenPopup(false)
         }
      })
      await ticketService.editarPedidoV2(pedido?.id, { estado: STATUS_FINALIZADO, id_cliente: clienteSelect?.key })
      await onList()
      setLoadData(false);
   }

   const handleGenerarBoletaSoftnet = async () => {
      if (!clienteSelect) {
         setSnackBarConfig(prev => ({
            ...prev,
            open: true,
            message: 'Seleccione un cliente.',
         }))
         return
      }

      if (namepagosSelect === '') {
         return setSnackBarConfig(prev => ({
            ...prev,
            open: true,
            message: 'Seleccione un método de pago',
         }))
      }

      if (detalle.find(item => item.idtipo_impuesto === null)) {
         setSnackBarConfig(prev => ({
            ...prev,
            open: true,
            message: 'Algunos productos no tienen el tipo de impuesto declarado',
         }))
         return
      }

      const dataUser = await userService.getUserById(dataUsuario.user.id_usuario)

      setLoadData(true)

      let copyProducts: Array<any> = []
      let monto_neto_calculado: number = 0
      let monto_exento: number = 0

      for (let i = 0; i < detalle.length; i++) {

         let precio = 0;

         if (detalle[i].precio_oferta) {
            precio = validatePrice(detalle[i]);
         } else {
            if (detalle[i].precio) {
               precio = detalle[i].precio;
            } else {
               precio = detalle[i].precio_standar;
            }
         }

         let filtro_cantidad = 0;

         let monto_neto_producto1 = 0;

         if (detalle[i].cantidad_peso) {
            monto_neto_producto1 = precio
            if (detalle[i].id_unidad_medida !== 1) {
               filtro_cantidad = Math.ceil(detalle[i].cantidad);
            } else {
               filtro_cantidad = detalle[i].cantidad;
            }
         } else {
            monto_neto_producto1 = precio;
            filtro_cantidad = detalle[i].cantidad;
         }

         copyProducts.push({
            "VlrCodigo": detalle[i].codigo_erp,
            "NmbItem": detalle[i].nombre,
            "PrcItem": monto_neto_producto1,
            "QtyItem": filtro_cantidad,
            "MontoItem": monto_neto_producto1 * filtro_cantidad,
            "Afecto": (detalle[i]?.idtipo_impuesto === 2) ? false : true
         })

         /*AFECTO */
         if (detalle[i]?.idtipo_impuesto === 1) {
            if (detalle[i].id_unidad_medida && detalle[i].id_unidad_medida !== 1) {
               monto_neto_calculado = monto_neto_calculado + monto_neto_producto1 * filtro_cantidad;
            } else {
               monto_neto_calculado = monto_neto_calculado + monto_neto_producto1 * detalle[i].cantidad;
            }
         }

         /*EXENTO */
         if (detalle[i]?.idtipo_impuesto === 2) {
            monto_exento += monto_neto_producto1 * filtro_cantidad
         }

         arrayIdsProducto.push(detalle[i].id_producto);
         setArrayIdsProducto(arrayIdsProducto);
      }

      const tipo_boleta: string = detalle.every(item => item?.idtipo_impuesto === 2) ? '41' : '39'
      let MntNeto: number = 0
      let IVA: number = 0
      let MntExe: number = 0
      let MntTotal: number = 0

      if (tipo_boleta === '39') {
         MntNeto = Math.round(monto_neto_calculado / 1.19);
         IVA = Math.round(MntNeto * 0.19);
         MntExe = monto_exento
         MntTotal = MntNeto + IVA + MntExe
      }

      if (tipo_boleta === '41') {
         MntNeto = monto_exento
         IVA = 0
         MntExe = monto_exento
         MntTotal = monto_exento
      }

      const DTE = {
         "DTE": {
            "Documento": {
               "Encabezado": {
                  "IdDoc": {
                     "TipoDTE": tipo_boleta,
                     "FchEmis": fechaFormateada
                  },
                  "Receptor": {
                     "RUTRecep": clienteSelect?.rut || '66666666-6',
                     "RznSocRecep": clienteSelect?.label || '---',
                     "GiroRecep": clienteSelect?.giro || '-',
                     "DirRecep": clienteSelect?.direccion || '-',
                     "CmnaRecep": clienteSelect?.comuna || '-',
                     "CiudadRecep": clienteSelect?.ciudad || '-'
                  },
                  "Totales": {
                     "MntNeto": MntNeto,
                     "MntExe": MntExe,
                     "IVA": IVA,
                     "MntTotal": MntTotal
                  }
               },
               "Detalle": copyProducts
            },
            "Adicionales": {
               "Uno": namepagosSelect
            }
         }
      }

      const documentoGenerado = await ventaService.createBoletaSoftenet(token, DTE)

      if (!documentoGenerado.mensaje || documentoGenerado.mensaje !== 'Dte generado con exito.') {
         setSnackBarConfig(prev => ({
            ...prev,
            open: true,
            message: 'Los datos a procesar son inválidos',
         }))
         setLoadData(false)
         return
      }

      const idcaja = dataUser?.user?.idcaja
      const nombreCaja = dataUser?.user?.caja
      const ventaID = await ventaService.generarBoleta(pedido?.id, pedido?.monto_total,
         recibido, 0,
         detalle, (codigoTransbank || null), documentoGenerado.folio, 1, documentoGenerado.urlTimbre,
         (documentoGenerado.pdf || ""), MntNeto, namepagosSelect, propina, false, (pedido?.id_mesa || null),
         monto_exento, idcaja
      )

      setShowPDF({
         idVenta: ventaID,
         montoNeto: MntNeto,
         timbre: documentoGenerado?.urlTimbre || "",
         folio: documentoGenerado?.folio,
         open: true
      })

      ventaService.getVentaDetalle(ventaID).then((res) => {

         if (res.status === 200) {
            const arrayProducts = res.tickets.flatMap(item =>
               item.detalle_ticket.flatMap(detalle => {
                  const productos = detalle.productos;
                  return {
                     "cantidad": detalle.cantidad,
                     "precio_unitario": detalle.precio_unitario,
                     "id_producto": detalle.id_producto,
                     "codigo_erp": productos?.codigo_erp,
                     "nombre": productos?.nombre,
                     "id_tipo_producto": productos?.id_tipo_producto,
                     "precio_standar": productos?.precio_standar,
                     "precio_oferta": productos && validatePrice(productos),
                     "unidad_medida": productos?.unidad_medida,
                  };
               })
            );

            const venta = {
               detalle: arrayProducts,
               ventaDetalle: res.venta,
               folio: documentoGenerado.folio,
               timbre: documentoGenerado.urlTimbre,
               montoNeto: tipo_boleta === '41' ? 0 : MntNeto,
               IVA: IVA,
               clienteSelect: clienteSelect,
               nombreCajero: dataUsuario.user.rol === ROLE_CAJERO ? dataUsuario.user.nombre : null,
               exento: MntExe,
               titulo: tipo_boleta === '41' ? 'BOLETA ELECTRONICA EXENTA' : 'BOLETA ELECTRONICA',
               caja: nombreCaja || ''
            }

            setDataImprimir(venta)
            setOpenPopup(false)
         }
      })
      await ticketService.editarPedidoV2(pedido?.id, { estado: STATUS_FINALIZADO, id_cliente: clienteSelect?.key })
      await onList()
      setLoadData(false);
   }

   const handleAnularParcial = () => {
      generateAnuladoParcial(dataEmpresa, codigoTransbank, setLoadData, detalle, setSnackBarConfig,
         arrayIdsProducto, setArrayIdsProducto, tipoBoletaSoftnet, token, beneficioProducto, KEY_EMPRESA,
         beneficioMonto, pedido, pagosSelect, namepagosSelect, STATUS_FINALIZADO, ruc_empresa, setShowPDF,
         setDataImprimir, setOpenPopup, loadDataList, idPedido
      )
   }

   const [paid, setPaid] = useState<any>(1)

   const handleOptionChange = (event) => {
      setPaid(event.target.value)
   }

   const Row = (props: { row: any, index: number }) => {
      const { row, index } = props;
      const [open, setOpen] = React.useState(false);

      return (
         <React.Fragment>
            <TableRow sx={{ '& > *': { borderBottom: 'unset' } }}>
               <TableCell>
                  <IconButton
                     aria-label="expand row"
                     size="small"
                     onClick={() => setOpen(!open)}
                     disabled={row.subProductos ? false : true}
                  >
                     {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                  </IconButton>
               </TableCell>
               <TableCell align="center">{row.codigo_erp}</TableCell>
               <TableCell align="center">{row.nombre}</TableCell>
               <TableCell align="center">
                  <span >
                     {
                        row.precio_unitario ? (row.precio_unitario / (row.cantidad_peso || 1)) :
                           (row.precio_oferta ? (row.precio_oferta / (row.cantidad_peso || 1)) : (row.precio_standar / (row.cantidad_peso || 1)))
                     }
                  </span>
                  {/*
               //se comenta de momento la aplicaicon del beneicio directaente
               <span className="descuento" style={{color: "red"}}>
                  {
                     ValiarBeneficio(row.id_producto, row?.newCantidad ? row.newCantidad : row.cantidad ?  row.cantidad : 1) ?
                     `  - ${ValiarBeneficio(row.id_producto, row?.newCantidad ? row.newCantidad : row.cantidad ?  row.cantidad : 1)}` : ''
                  }
               </span> */}
               </TableCell>
               {
                  // eslint-disable-next-line
                  anularMode && (
                     <>
                        <TableCell align="center">
                           <input type="number"
                              value={row.newCantidad ? row.newCantidad : row.cantidad}
                              onChange={(e) => { handleChangeCantidad(e, index) }} />
                        </TableCell>
                        <TableCell align="center">

                           <Button
                              variant='contained'
                              size="small"
                              color="warning"
                              sx={{ fontSize: "14px !important", marginRight: "5px" }}
                              onClick={(e) => {
                                 handleChangeProduct(index)
                              }}
                           >  Cambiar
                           </Button>

                           <Button
                              variant='contained'
                              size="small"
                              color="error"
                              sx={{ fontSize: "14px !important" }}
                              onClick={() => handleDeleteProduct(index)}
                           >  Eliminar
                           </Button>

                        </TableCell>
                     </>// eslint-disable-next-line
                  ) || (
                     <>
                        <TableCell align="center">{`${row.cantidad} (${row.unidad_medida})`}</TableCell>
                     </>
                  )
               }
               {
                  pagarProducts && (
                     <TableCell align="center">
                        <Button
                           id="basic-button"
                           variant='contained'
                           size="small"
                           style={{ textTransform: "capitalize" }}
                           sx={{ fontSize: "14px !important" }}
                           onClick={(e) => {
                              saveProducts(row, index);
                           }}
                        >
                           Elegir
                        </Button>
                     </TableCell>
                  )
               }
            </TableRow>

            {row.subProductos &&
               <TableRow>
                  <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
                     <Collapse in={open} timeout="auto" unmountOnExit>
                        <Box sx={{ margin: 1 }}>
                           <Typography variant="h6" gutterBottom component="div">
                              Items del menu
                           </Typography>
                           <Table size="small" aria-label="purchases">
                              <TableHead>
                                 <TableRow>
                                    <TableCell>Codigo</TableCell>
                                    <TableCell>Nombre</TableCell>
                                    <TableCell>Cantidad</TableCell>
                                 </TableRow>
                              </TableHead>
                              <TableBody>
                                 {row.subProductos.map((subPro) => (
                                    <TableRow key={subPro.id}>
                                       <TableCell component="th" scope="row">
                                          {subPro.codigo_erp}
                                       </TableCell>
                                       <TableCell>{subPro.nombre}</TableCell>
                                       <TableCell>{subPro.cantidad}</TableCell>
                                    </TableRow>
                                 ))}
                              </TableBody>
                           </Table>
                        </Box>
                     </Collapse>
                  </TableCell>
               </TableRow>
            }
         </React.Fragment>
      );
   }
   return (
      <>
         <Snackbar
            open={snackBarConfig.open}
            autoHideDuration={snackBarConfig.autoHideDuration}
            onClose={() => setSnackBarConfig(prev => ({ ...prev, open: false }))}
            anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
         >
            <Alert
               onClose={() => setSnackBarConfig(prev => ({ ...prev, open: false }))}
               severity={snackBarConfig.severity}
               variant="filled"
            >
               {snackBarConfig.message}
            </Alert>
         </Snackbar>

         {
            !detailMode && tipoCupon.length > 0 && tipoCupon.map((cupon) => (
               <Grid container sx={{ justifyContent: "center", textAlign: 'center', marginBottom: '5px' }}>
                  <Grid sx={{ padding: '2px', borderRadius: '5px', backgroundColor: 'green', color: 'white', textAlign: 'center', width: "100%" }}>
                     <span style={{ fontFamily: 'sans-serif', fontSize: '14px' }}>{cupon.name}</span>
                  </Grid>
               </Grid>
            ))
         }

         <Grid
            container spacing={0} direction="column"
            style={{ marginBottom: '15px', marginTop: '5px' }}
         >
            <Grid container style={{ justifyContent: "space-between", display: "flex", alignItems: "center" }}>

               {
                  !pagarProducts && (
                     <Grid item xs={6} style={{ display: "flex", alignItems: "center", justifyContent: "start", marginBottom: "10px" }}>
                        <span style={{ marginRight: "10px", width: '150x' }}><b>Cantidad de Tickets:</b></span>
                        <span>{ticket}</span>
                     </Grid>
                  )
               }

               {
                  (!detailMode || anularMode || pagarProducts) && (
                     <Grid item xs={6} style={{ display: "flex", alignItems: "center", marginBottom: "10px" }}>
                        <span style={{ marginRight: "10px", width: "150px" }}><b>Tipo de pago:</b></span>
                        <FormControl fullWidth>
                           <Select
                              labelId="tipoPago" id="tipoPago" size='small' name="tipoPago" value={pagosSelect}
                              onChange={handlePagoChange}
                              disabled={detailMode && !anularMode && !pagarProducts}
                           >
                              {pagos.map((option, i) => {
                                 return <MenuItem key={i} value={option.id} onClick={() => { setNamePagoSelect(option.nombre) }}>{option.nombre}</MenuItem>
                              })
                              }
                           </Select>
                        </FormControl>
                     </Grid>
                  )
               }

               {!!detailMode && !pagarProducts && !!pedido?.cliente?.nombre && (
                  <Grid item xs={6} style={{ display: "flex", alignItems: "center", marginBottom: "10px", marginTop: "8px" }}>
                     <span style={{ marginRight: "10px", width: '180px' }}><b>Nombre del Cliente:</b></span>
                     <span style={{ fontSize: "18px" }}>{pedido?.cliente?.nombre || 'Cliente General'}</span>
                  </Grid>
               )
               }

               {(!detailMode || !!pagarProducts) && (
                  // (!pedido?.cliente?.nombre) ? (
                  <>
                     <Grid item xs={6} style={{ display: "flex", alignItems: "center", marginBottom: "10px", marginTop: "8px" }}>
                        <span style={{ marginRight: "10px", width: '170px' }}><b>Clientes:</b></span>
                        <Autocomplete
                           disablePortal id="combo-box-demo"
                           options={clientes}
                           sx={{ width: '232px' }}
                           renderInput={(params) => <TextField {...params} label="Seleccione un cliente" />}
                           value={clienteSelect}
                           onChange={(e, newValue) => { setClienteSelect(newValue); setRutCliente(newValue?.rut ? newValue?.rut : '') }}
                           size='small'
                        />
                        <IconButton aria-label="delete" size="small"
                           onClick={() => { setOpenModalClient(true) }}>
                           <PersonAddAltIcon sx={{ fontSize: 20 }} />
                        </IconButton>
                     </Grid>

                     {
                        <Popup
                           largo="sm"
                           title='Agregar Cliente'
                           openPopup={openModalClient}
                           setOpenPopup={setOpenModalClient}
                        >
                           <AddCliente
                              closeModal={() => { setOpenModalClient(false) }}
                              onListClientes={getClientes}
                              editMode={true}
                           />

                        </Popup>
                     }
                  </>
                  // ) : (
                  //    <Grid item xs={6} style={{ display: "flex", alignItems: "center", marginBottom: "10px", marginTop: "8px" }}>
                  //       <span style={{ marginRight: "10px", width: '180px' }}><b>Nombre del Cliente:</b></span>
                  //       <span style={{ fontSize: "18px" }}>{pedido?.cliente?.nombre || 'Cliente General'}</span>
                  //    </Grid>
                  // )
               )
               }

               {notaVenta && (
                  <>

                     <Grid item xs={6} style={{ display: "flex", alignItems: "center", marginBottom: "10px", marginTop: "8px" }}>
                        <span style={{ marginRight: "10px", width: '150px' }}><b>Rut Cliente:</b></span>
                        <TextField
                           fullWidth
                           size="small"
                           type={"text"}
                           value={rutCliente || 'seleccione un cliente'}
                           disabled={rutCliente}
                           error={!rutCliente}
                        />
                     </Grid>

                     <Grid item xs={6} style={{ display: "flex", alignItems: "center", marginBottom: "10px", marginTop: "8px" }}>
                        <span style={{ marginRight: "10px", width: '170px' }}><b>Folio nota venta:</b></span>
                        <TextField
                           size="small"
                           type={"text"}
                           value={folioNotaVenta}
                           onChange={(e) => {
                              setFolioNotaVenta(e.target.value)
                           }} />
                     </Grid>

                     <Grid item xs={6} style={{ display: "flex", alignItems: "center", marginBottom: "10px", marginTop: "8px" }}>
                        <span style={{ marginRight: "10px", width: '170px' }}><b>Pagada:</b></span>
                        <RadioGroup
                           aria-label="Opción"
                           name="opcion"
                           value={paid}
                           onChange={handleOptionChange}
                           row
                        >
                           <FormControlLabel
                              value={1}
                              control={<Radio color="primary" />}
                              label="Sí"
                           />
                           <FormControlLabel
                              value={2}
                              control={<Radio color="primary" />}
                              label="No"
                           />
                        </RadioGroup>
                     </Grid>

                  </>
               )
               }

               {
                  pagosSelect === 2677 && (
                     <Grid item xs={6} style={{ display: "flex", alignItems: "center", marginBottom: "10px", marginTop: "8px" }}>
                        {pagarProducts ? (
                           <span style={{ marginLeft: "15px", width: '100px' }}><b>Código:</b></span>
                        ) : (
                           <span style={{ marginRight: "10px", width: '150px' }}><b>Código:</b></span>
                        )}

                        <TextField
                           placeholder="Código Transbank"
                           className="input-pago"
                           size="small" id="codigo_transbank"
                           name="codigo_transbank" type="number"
                           value={codigoTransbank}
                           onChange={(e) => { setCodigoTransbank(e.target.value); codigoTransbank !== "" && setErrorTranB(false) }}
                           error={errorTransB}
                           fullWidth
                        />
                     </Grid>
                  )
               }

               {
                  (notaVenta && !rutCliente) && (
                     <StyledButton
                        variant="contained" color='primary' size='small'
                        onClick={() => { setOpenPopupClient(true) }}
                        sx={{ mt: "10px", bgcolor: '#1976d2', width: '30%', fontSize: '10px !mportant' }}
                     >
                        Agregar Cliente
                     </StyledButton>
                  )
               }

            </Grid>

            {
               detailMode && (
                  <>
                     <Divider style={{ width: "100%", height: "3px", marginTop: "15px" }} />

                     <Grid item xs={3} style={{ textAlign: "center" }}>

                        <Titulo>{pagarProducts ? "Seleccione los productos a cancelar" : "Productos"}</Titulo>
                     </Grid>

                     <TableContainer component={Paper}>
                        <ThemeProvider theme={theme}>
                           <Table sx={{ minWidth: 650 }} aria-label="simple table">
                              <TableHead>
                                 <TableRow>
                                    <TableCell align="center" width={10}></TableCell>
                                    <TableCell align="center">Codigo</TableCell>
                                    <TableCell align="center">Nombre</TableCell>
                                    <TableCell align="center">Precio</TableCell>
                                    <TableCell align="center">Cantidad</TableCell>
                                    {
                                       pagarProducts && (
                                          <TableCell align="center">Seleccionar</TableCell>
                                       )
                                    }
                                    {
                                       anularMode && (
                                          <TableCell align="center">Acciones</TableCell>
                                       )
                                    }
                                 </TableRow>
                              </TableHead>
                              <TableBody>
                                 {// eslint-disable-next-line
                                    (detalle || []).map((item, index) => {
                                       if (!(item?.wasDeleted)) {
                                          return (
                                             <Row index={index} row={item} key={`row-ped${index}`} />
                                          )
                                       }
                                    }
                                    )}
                              </TableBody>
                           </Table>
                        </ThemeProvider>
                     </TableContainer>
                  </>
               )
            }
            <Divider style={{ width: "100%", height: "3px", margin: "15px 0" }} />

            <Stack direction="row" spacing={2}>
               {arraySelectedProducts && arraySelectedProducts.map((item, indexProd) =>
                  <Stack
                     direction="row" spacing={2}>
                     <Chip label={item?.nombre} onDelete={() => removeProductSelected(item, indexProd)} color="warning" />
                  </Stack>
               )}
            </Stack>

            <Divider style={{ width: "100%", height: "3px", margin: "15px 0" }} />

            <Grid container >
               {!detailMode && /*  pedido?.descuento_monto_total && */
                  <Grid item xs={6} style={{ display: "flex", alignItems: "center", marginTop: "20px" }}>
                     <span style={{ width: "180px" }}>Descuento por Monto Total: </span>
                     <TextField
                        size="small"
                        type={"number"}
                        value={pedido?.descuento_monto_total}
                        disabled={detailMode} />
                  </Grid>
               }
               {!detailMode && /* pedido?.descuento_productos_total && */
                  <Grid item xs={6} style={{ display: "flex", alignItems: "center", marginTop: "20px" }}>
                     <span style={{ width: "180px", marginLeft: "10px" }}>Monto por productos: </span>
                     <TextField
                        size="small"
                        type={"number"}
                        value={pedido?.descuento_productos_total}
                        disabled={detailMode} />
                  </Grid>
               }
               {
                  !detailMode && (
                     <>

                        <Grid item xs={6} style={{ display: "flex", alignItems: "center", marginTop: "20px" }}>
                           <span style={{ width: "180px" }}>Monto Recibido: </span>
                           <TextField
                              size="small"
                              type={"number"}
                              value={recibido}
                              onChange={handleChangeMontoRecibido}
                              onKeyUp={handleKeyMontoRecibido}
                              disabled={detailMode} />
                        </Grid>
                        <Grid item xs={6} style={{ display: "flex", alignItems: "center", marginTop: "20px" }}>
                           <span style={{ width: "180px", marginLeft: "10px" }}><b>Monto a Pagar: </b></span>
                           <TextField
                              size="small"
                              type={"number"}
                              value={pedido?.monto_total}
                              disabled={detailMode} />
                        </Grid>
                        <Grid item xs={6} style={{ display: "flex", alignItems: "center", marginTop: "20px" }}>
                           <span style={{ width: "180px" }}>Vuelto:</span>
                           <TextField
                              size="small"
                              type={"number"}
                              value={vuelto}
                              disabled={detailMode} />
                        </Grid>
                        <Grid item xs={6} style={{ display: "flex", alignItems: "center", marginTop: "20px" }}>
                           <span style={{ width: "180px", marginLeft: "10px" }}>Propina:</span>
                           <TextField
                              size="small"
                              type={"number"}
                              value={propina}
                              onChange={handleChangePropina}
                              disabled={detailMode} />
                        </Grid>
                     </>
                  )
               }

               {
                  detailMode && !anularMode && (
                     <Grid item xs={12} sm={12} md={4} lg={4} xl={4} style={{ display: "flex", alignItems: "center", marginTop: "20px", justifyContent: "start", marginRight: "5px" }}>
                        <span style={{ width: '150px' }}><b>Total a Pagar:</b></span>
                        <span style={{ fontSize: "20px" }}>{pedido?.monto_total}</span>
                     </Grid>
                  )
               }
               {
                  arraySelectedProducts.length > 0 && detailMode && pagarProducts && (
                     <>
                        <Grid item xs={12} sm={12} md={4} lg={4} xl={4} style={{ display: "flex", alignItems: "center", marginTop: "20px", justifyContent: "start", marginRight: "5px", paddingLeft: '5px', borderLeft: 'solid 1px #8e8e8e' }}>
                           <span style={{ width: '150px' }}><b>Monto a Pagar:</b></span>
                           <span style={{ fontSize: "20px" }}>{amountSelectedProducts()}</span>
                        </Grid>
                        <StyledButton
                           variant="contained" color='success' fullWidth
                           onClick={() => { handleGenerateDocumentoPorParte('Boleta') }}
                           sx={{ mt: "20px", mr: '3px', bgcolor: '#229954', width: '15%' }}
                        >
                           Boleta
                        </StyledButton>
                        <StyledButton
                           variant="contained" color='success' fullWidth
                           onClick={() => { handleGenerateDocumentoPorParte('Factura') }}
                           sx={{ mt: "20px", bgcolor: '#229954', width: '15%' }}
                        >
                           Factura
                        </StyledButton>
                     </>
                  )
               }

               {
                  anularMode && (
                     <Grid sx={{ display: "flex", alignItems: "center", justifyContent: "space-between", width: "100%" }}>
                        <Grid item xs={12} style={{ display: "flex", alignItems: "right", marginTop: "20px", justifyContent: "end" }}>
                           <Button
                              variant='contained'
                              size="small"
                              color="success"
                              style={{ textTransform: "capitalize" }}
                              sx={{ fontSize: "14px !important", width: "200px", height: "50px" }}
                              onClick={handleAnularParcial}
                           >  Generar nueva Boleta
                           </Button>
                        </Grid>

                     </Grid>
                  )
               }

               {
                  !detailMode && (
                     <>
                        <Divider style={{ width: "100%", height: "3px", marginTop: "15px", marginBottom: "15px" }} />
                        {
                           notaVenta ? (
                              <>
                                 <StyledButton
                                    variant="contained" color='warning' fullWidth
                                    onClick={() => { handleGenerateDocumento('notaVenta'); setTipoImpresion('notaVenta') }}
                                    sx={{ mt: "10px", mx: 4, bgcolor: '#ed6c02', width: '45%' }}
                                 >
                                    {"Enviar Nota Venta"}
                                 </StyledButton>
                                 <StyledButton
                                    variant="contained" color='error' fullWidth
                                    onClick={() => { setNotaVenta(false); setClienteSelect('') }}
                                    sx={{ mt: "10px", bgcolor: 'red', width: '45%' }}
                                 >
                                    {"Cancelar Nota Venta"}
                                 </StyledButton>
                              </>
                           ) : (
                              <Grid xs={12} sx={{ display: 'flex', justifyContent: 'center' }}>
                                 {/* <StyledButton
                                    variant="contained" color='success' fullWidth
                                    onClick={() => { setNotaVenta(true); getClientes() }}
                                    sx={{ mt: "10px", mx: 2, bgcolor: '#229954', width: '30%' }}
                                 >
                                    {"Nota Venta"}
                                 </StyledButton> */}
                                 <StyledButton
                                    variant="contained" color='success' fullWidth
                                    // onClick={() => { handleGenerateDocumento('Boleta'); setTipoImpresion('Boleta') }}
                                    onClick={() => { handleGenerarBoletaSoftnet(); setTipoImpresion('Boleta') }}
                                    sx={{ mt: "10px", mx: 2, bgcolor: '#229954', width: '30%' }}
                                    disabled={notaVenta}
                                 >
                                    {"Boletear"}
                                 </StyledButton>
                                 <StyledButton
                                    variant="contained" color='success' fullWidth
                                    // onClick={() => { handleGenerateDocumento('Factura') }}
                                    onClick={handleGenerateFactura}
                                    sx={{ mt: "10px", bgcolor: '#229954', width: '30%' }}
                                    disabled={notaVenta}
                                 >
                                    {"Facturar"}
                                 </StyledButton>
                              </Grid>
                           )
                        }
                     </>
                  )
               }

            </Grid>
            {loadData && <Loading />}
            <Snackbar open={openSnackError} autoHideDuration={4000} onClose={handleCloseError}>
               <Alert onClose={handleCloseError} severity="error" sx={{ width: '100%' }}>
                  Ocurrió algun error al generar la boleta!
               </Alert>
            </Snackbar>

            {dataImprimir &&
               <div style={{ display: "none" }}>
                  <div ref={componentRef}>
                     <ComponentPrint
                        data={dataImprimir}
                        tipoImpresion={tipoImpresion}
                     />
                  </div>
               </div >
            }
            {dataImprimirFactura &&
               <div style={{ display: "none" }}>
                  <div ref={componentRef}>
                     <ComponentPrintFactura
                        data={dataImprimirFactura}
                     />
                  </div>
               </div >
            }
            {(ImprimirCupon && tipoCupon.length > 0) &&
               <div>
                  <div ref={componentRef}>
                     <ComponentCuponTicket
                        data={tipoCupon}
                        closeModal={() => { setImprimirCupon(false) }}
                     />
                  </div>
               </div>
            }
            {showModalProducts &&
               <PopupComponent
                  largo="md"
                  title={'Seleccionar producto'}
                  openPopup={showModalProducts}
                  setOpenPopup={setShowModalProducts}
               >
                  <Productos indexProducts={selectIndex} arrayProducts={detalle} setArrayProducts={setDetalle} closeModal={() => {
                     setShowModalProducts(false);
                     //removeLocalStorage(PRODUCTS_CARD);
                  }} />
               </PopupComponent>
            }
            {openPopupClient &&
               <Popup
                  largo="sm"
                  title='Agregar Cliente'
                  openPopup={openPopupClient}
                  setOpenPopup={setOpenPopupClient}
               >
                  <AddCliente
                     closeModal={() => { setOpenPopupClient(false) }}
                     onListClientes={getClientes}
                     clientSelected={setClienteSelect}
                     rutCliente={setRutCliente}
                     editMode={true}
                  />

               </Popup>
            }
         </Grid>
      </>
   );

}
