import React from 'react';
import { Grid, Typography } from '@mui/material';
import { readLocalStorage } from '@/toolbox/helpers/local-storage-helper';
import { KEY_EMPRESA } from '@/toolbox/constants/local-storage';
import {Props} from './ComponentComanda.type'

export const ComponentComanda: React.FC<Props> = (props: Props) => {

    const {data} = props;

    const SessionEmpresa: any = readLocalStorage(KEY_EMPRESA);

    return(
        <div style={{  marginBottom: 30, width: '300px', margin: 'auto', marginTop: 30 ,padding:"10px"}}>
            <div style={{width:"100%"}} >

                <div style={{textAlign:"center"}}>
                    <Typography sx={{fontSize:'1.3em !important', fontFamily:'sans-serif',fontWeight:'700'}}>
                        {SessionEmpresa?.nombre}
                    </Typography>
                </div>

                <hr style={{border: '0', height: '2px', borderTop: '1px dashed black', borderBottom: '1px dashed black'}}/>

                <div>
                    <Grid container>
                        <Grid item xs={6}>
                            <Typography sx={{textAlign:"left",fontSize:'1.2em !important'}}>Artículo</Typography>
                        </Grid>
                        <Grid item xs={6}>
                            <Typography sx={{textAlign:"right",fontSize:'1.2em !important'}}>Cantidad</Typography>
                        </Grid>
                    </Grid>
                </div>

                <hr style={{border: '0', height: '2px', borderTop: '1px dashed black', borderBottom: '1px dashed black'}}/>
                {
                    data.productos?.length > 0 && data.productos.map((product, key) => (
                        <div key={key}>
                            <Grid container>
                                <Grid item xs={8}>
                                    <Typography sx={{textAlign:"left",fontSize:'1em !important'}}>{product?.nombre}</Typography>
                                </Grid>
                                <Grid item xs={4}>
                                    <Typography sx={{textAlign:"right",fontSize:'1em !important'}}>{product?.cantidad} {` ${product?.unidad_medida?.abbreviation}`}</Typography>
                                </Grid>
                            </Grid>
                        </div>
                    ))
                }
                <hr style={{border: '0', height: '2px', borderTop: '1px black', borderBottom: '1px dashed black'}}/>

                <div >
                    <Grid container>
                        <Grid item xs={4}>
                            <Typography sx={{textAlign:"left",fontSize:'1em !important'}}>N° Mesa:</Typography>
                        </Grid>
                        <Grid item xs={8}>
                            <Typography sx={{textAlign:"right",fontSize:'1em !important'}}>{data.mesa ? data.mesa.nombre : 'no definido'}</Typography>
                        </Grid>
                    </Grid>
                </div>

            </div>
        </div>
    )
}
