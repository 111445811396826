import { readLocalStorage } from '@helpers/local-storage-helper';
import { KEY_BODEGA_SELECT, KEY_USER_DATA } from "@/toolbox/constants/local-storage";

import { ventaService } from '@/service/services/Venta.service';
import moment from 'moment';
import { ticketService } from '@/service/services/Ticket.service';
import { NotaVentaService } from '@/service/services/NotaVenta.service';

export const handleGenerateBoleta = async (
   dataEmpresa, codigoTransbank, pagosSelect, setErrorTranB, setSnackBarConfig,
   setLoadData, arrayIdsProducto, setArrayIdsProducto, tipoBoletaSoftnet, token,
   detalle, beneficioProducto, pedido, ruc_empresa, recibido, setShowPDF, setDataImprimir, setDataImprimirFactura,
   setOpenPopup, loadDataList, namepagosSelect, propina, tipo_doc, setImprimirCupon, secTipoCupon, areaNegocio, notaVenta, folioNotaVenta, rutCliente, idPedido, clienteSelect,
   isMultiPago = false, applyDescuento = false, montoTotalParte = 0, setArraySelectedProducts, isFinishPart, paid = 1
) => {

   const sessionUsuario = readLocalStorage(KEY_USER_DATA);
   const idSucursal = sessionUsuario.user?.id_sucursal;

   // const dataUsuario = readLocalStorage(KEY_USER_DATA);
   // const rutUsuario = dataUsuario?.user?.rut_usuario;
   const pais = dataEmpresa.country_abbreviation;
   const formats = {
      codigoPeru: /^\d{6}$/,
      codigoChile: /^\d{6}$/,
      codigoColombia: /^\d{6}$/,
   }
   let error: any;
   if (pais === 'CLP' && !formats.codigoChile.test(codigoTransbank)) error = true;
   if (pais === 'PEN' && formats.codigoPeru.test(codigoTransbank)) error = true;
   if (pais === 'COP' && formats.codigoColombia.test(codigoTransbank)) error = true;

   const validatePrice = (product) => {
      const dateToday = moment();
      const value = product.caducidad_precio_oferta;
      const expirationPrice = moment(value, 'DD-MM-YYYY');

      const standarPrice = product.precio_standar || product.precio;
      const offerPrice = product.precio_oferta;

      if (expirationPrice !== null && offerPrice !== null) {
         if (expirationPrice.isSameOrAfter(dateToday)) {
            return offerPrice;
         } else {
            return standarPrice;
         }
      } else {
         return standarPrice;
      }
   }

   if (notaVenta && rutCliente === '') {
      return setSnackBarConfig(prev => ({
         ...prev,
         open: true,
         message: `El usuario debe ser registrado`,
      }));
   }

   if (notaVenta && folioNotaVenta === '') {
      return setSnackBarConfig(prev => ({
         ...prev,
         open: true,
         message: `el campo Folio es requerido`,
      }));
   }

   if ((dataEmpresa.id_tipo_empresa === 2 && pagosSelect === 2677 && codigoTransbank === "") ||
      (dataEmpresa.id_tipo_empresa === 2 && pagosSelect === 2677 && error === true)) {
      setErrorTranB(true);
      setSnackBarConfig(prev => ({
         ...prev,
         open: true,
         message: `código de transbank inválido`,
      }));
      setLoadData(false);
   } else {
      arrayIdsProducto.length = 0;
      setArrayIdsProducto(arrayIdsProducto);
      setLoadData(true);

      let confProducto: any
      confProducto = await tipoBoletaSoftnet(token);
      let tipoBoleta = '';
      // eslint-disable-next-line
      confProducto?.conf.map((item) => {
         if (item.tipo_doc === '39') {
            tipoBoleta = item.tipo;
         }
      })
      const bodega = readLocalStorage(KEY_BODEGA_SELECT);
      let copyProducts: Array<any> = []
      let monto_neto_calculado = 0;
      for (let i = 0; i < detalle.length; i++) {

         let precio = 0;
         let codigo = 0;
         if (detalle[i].precio_oferta) {
            // precio = detalle[i].precio_oferta;
            precio = validatePrice(detalle[i]);
         } else {
            if (detalle[i].precio) {
               precio = detalle[i].precio;
            } else {
               precio = detalle[i].precio_standar;
            }
         }

         if (detalle[i].precio) {
            codigo = detalle[i].codigo;
         } else {
            codigo = detalle[i].codigo_erp;
         }

         /* //si tiene una lista de precio
         let precioList = 0;
         if (detalle[i].id_producto) {
            const resp = await listaPrecioService.getListaPrecioByProduct(detalle[i].id_producto);
            console.log(resp);
         } */

         //si tiene beneficio se comenta de manera moentanea la aplicacion de los beneficios
         let descuento = 0;
         let filtro_cantidad = 0;
         // if (detalle[i].id_producto) {
         //    let result = beneficioProducto.find(prod => prod.id_producto === detalle[i].id_producto);
         //    if ( result ) {
         //       let cantidadProd = detalle[i]?.cantidad || 1;
         //       if (cantidadProd >= result.cantidad_productos) {
         //          let multiplo = cantidadProd / result.cantidad_productos
         //          descuento = Math.trunc(multiplo) * (result.cantidad_puntos*dataEmpresa.equivalencia_puntos);
         //       }
         //    }
         // }
         let monto_neto_producto1 = 0;
         if (detalle[i].cantidad_peso) {
            monto_neto_producto1 = tipoBoleta === 'N' ? Math.ceil((precio / detalle[i].cantidad_peso) / 1.19) : precio
            if (detalle[i].id_unidad_medida !== 1) {
               filtro_cantidad = Math.ceil(detalle[i].cantidad);
            } else {
               filtro_cantidad = detalle[i].cantidad;
            }
         } else {
            monto_neto_producto1 = tipoBoleta === 'N' ? Math.ceil(precio / 1.19) : precio;
            filtro_cantidad = detalle[i].cantidad;
         }
         copyProducts.push({
            "Afecto": tipoBoleta === 'N' ? true : false,
            "Descuento": descuento,
            "Bodega": bodega,
            "Cantidad": filtro_cantidad,
            "Codigo": codigo,
            "Precio": monto_neto_producto1,
         })
         if (detalle[i].id_unidad_medida && detalle[i].id_unidad_medida !== 1) {
            monto_neto_calculado = monto_neto_calculado + monto_neto_producto1 * filtro_cantidad;
         } else {
            monto_neto_calculado = monto_neto_calculado + monto_neto_producto1 * detalle[i].cantidad;
         }

         arrayIdsProducto.push(detalle[i].id_producto);
         setArrayIdsProducto(arrayIdsProducto);
      }

      setImprimirCupon(true);

      let sendDataBoleta = [{
         "Encabezado": {
            // "Receptor": rutCliente ?  rutCliente : '66666666-6',
            "Receptor": '66666666-6',
            "MontoNeto": monto_neto_calculado,
            "Descuento": isMultiPago ? (applyDescuento ? pedido?.descuento_monto_total || 0 : 0) : (pedido?.descuento_monto_total || 0),
            "TipoDocumento": '39',
            "AreaNegocio": areaNegocio ? areaNegocio.id : "3831",
         },
         "Detalle": copyProducts,
         "cliente": 'cliente',
         "Adicional": {
            "Uno": pagosSelect,
            "Dos": ruc_empresa,
         }
      }];

      let sendDataFactura = [{
         "Encabezado": {
            "Receptor": "66666666-6",
            "MontoNeto": 100,
            "MontoExento": 0,
            "Descuento": 0,
            "TipoDocumento": 33,
            "AreaNegocio": areaNegocio ? areaNegocio.id : "4"
         },
         "Detalle": [{
            "Afecto": true,
            "Descuento": 0,
            "Bodega": 85,
            "Cantidad": 1,
            "Codigo": "1",
            "Precio": 100
         }],
         "Adicional": {
            "Uno": 5357,
            "Dos": "11111111-1",
            "Treinta": "123"
         }
      }];

      let sendNotaVenta = [{
         "Encabezado": {
            "Receptor": rutCliente ? rutCliente : '66666666-6',
            "MontoNeto": monto_neto_calculado,
            "MontoExento": "0",
            "Descuento": isMultiPago ? (applyDescuento ? pedido?.descuento_monto_total || 0 : 0) : (pedido?.descuento_monto_total || 0),
            "TipoDocumento": '780',
            "Folio": folioNotaVenta ? folioNotaVenta : "324",
            "AreaNegocio": areaNegocio ? areaNegocio.id : "3831",
            "CondicionPago": pagosSelect,
            // "Vendedor": rutUsuario,
            "Vendedor": '15272583-3',
            "Observacion": "observacion",
            "Direccion": "123"
         },
         "Detalle": copyProducts,
         "cliente": 'cliente',
         "Adicional": {
            "Uno": pagosSelect,
            "Dos": ruc_empresa,
         }
      }];

      // const token_api_softnet = '14af3bd557763fc7733730f8e0c84240b92082e5fe8710d39186b3a9c60114cbb2c0939d7b21e02f7d62c8721593b0982154f4e975ad5707a12e21cb70246953'
      let documentoGenerado;
      if (tipo_doc === 'Boleta') {
         documentoGenerado = await ventaService.enviarBoleta(token, sendDataBoleta);
      }
      if (tipo_doc === 'Factura') {
         documentoGenerado = await ventaService.enviarFactura(token, sendDataFactura);
         // documentoGenerado = await ventaService.enviarFactura(token_api_softnet, sendDataFactura)
      }
      if (tipo_doc === 'notaVenta') {
         documentoGenerado = await ventaService.enviarNotaVenta(token, sendNotaVenta);
      }
      if (!documentoGenerado[0]) {
         setSnackBarConfig(prev => ({
            ...prev,
            open: true,
            message: 'Los datos a procesar son inválidos',
         }));
         setLoadData(false);
      } else {
         const payload = {
            receptor: rutCliente ? rutCliente : '66666666-6',
            monto_neto: monto_neto_calculado,
            monto_excento: 0,
            descuento: isMultiPago ? (applyDescuento ? pedido?.descuento_monto_total || 0 : 0) : (pedido?.descuento_monto_total || 0),
            tipo_documento: 780,
            folio: folioNotaVenta ? folioNotaVenta : "324",
            area_negocio: areaNegocio ? areaNegocio.id : "3831",
            condicion_pago: pagosSelect,
            vendedor: "15272583-3",
            observacion: 'observacion',
            direccion: '123',
            id_empresa: dataEmpresa.id,
            id_sucursal: idSucursal,
            emitido: paid === 1 ? 1 : 2,
            detalle: copyProducts
         }
         if (tipo_doc === 'notaVenta') {
            if (clienteSelect !== "") await ticketService.editarPedido(idPedido, clienteSelect.key, null, null, null, null)
            await NotaVentaService.register(payload)
         }
         if (!notaVenta && (documentoGenerado[0].mensaje !== 'Item guardado con exito.')) {
            setSnackBarConfig(prev => ({
               ...prev,
               open: true,
               message: `${documentoGenerado[0].mensaje}`,
            }));
            setLoadData(false);
         } else if (notaVenta && (documentoGenerado[0].mensaje !== "Documento generado con exito.")) {
            setSnackBarConfig(prev => ({
               ...prev,
               open: true,
               message: `${documentoGenerado[0].mensaje}`,
            }));
            setLoadData(false);
         } else {
            // let ventaID = await ventaService.generarBoleta(pedido?.id, pedido?.monto_total, recibido, ((pedido?.descuento_monto_total || 0)+(pedido?.descuento_productos_total || 0)) , detalle, (codigoTransbank || null),  documentoGenerado[0].folio, 1,  (tipo_doc === 'Boleta'?documentoGenerado[0].urlTimbre:documentoGenerado[0].timbre || ""), (documentoGenerado[0].pdf || "") ,monto_neto_calculado, namepagosSelect, propina)
            let ventaID = await ventaService.generarBoleta(pedido?.id, isMultiPago ? montoTotalParte : pedido?.monto_total,
               recibido, applyDescuento ? ((pedido?.descuento_monto_total || 0) + (pedido?.descuento_productos_total || 0)) : 0,
               detalle, (codigoTransbank || null), documentoGenerado[0].folio, 1, (tipo_doc === 'Boleta' ? documentoGenerado[0].urlTimbre : documentoGenerado[0].timbre || ""),
               (documentoGenerado[0].pdf || ""), monto_neto_calculado, namepagosSelect, propina, isMultiPago, (pedido?.id_mesa || null), 0
            )
            setShowPDF({
               idVenta: ventaID,
               montoNeto: monto_neto_calculado,
               timbre: documentoGenerado[0].urlTimbre || "",
               folio: documentoGenerado[0].folio,
               open: true
            })
            ventaService.getVentaDetalle(ventaID).then((res) => {

               if (res.status === 200) {
                  let arrayProducts = [];
                  // eslint-disable-next-line
                  (res.tickets).map((item) => {
                     for (let i = 0; i < item.detalle_ticket.length; i++) {
                        const productos = item.detalle_ticket[i].productos;
                        if (isMultiPago) {
                           let exists = detalle.find(prod => ((prod.codigo_erp == productos?.codigo_erp && prod.id_producto == item.detalle_ticket[i].id_producto)))

                           if (exists) {
                              arrayProducts.push({
                                 "cantidad": item.detalle_ticket[i].cantidad,
                                 "precio_unitario": item.detalle_ticket[i].precio_unitario,
                                 "id_producto": item.detalle_ticket[i].id_producto,
                                 "codigo_erp": productos?.codigo_erp,
                                 "nombre": productos?.nombre,
                                 "id_tipo_producto": productos?.id_tipo_producto,
                                 "precio_standar": productos?.precio_standar,
                                 // "precio_oferta": productos?.precio_oferta
                                 "precio_oferta": productos && validatePrice(productos),
                                 "unidad_medida": item?.detalle_ticket[i]?.productos?.unidad_medida,
                              });
                           }
                        } else {
                           arrayProducts.push({
                              "cantidad": item.detalle_ticket[i].cantidad,
                              "precio_unitario": item.detalle_ticket[i].precio_unitario,
                              "id_producto": item.detalle_ticket[i].id_producto,
                              "codigo_erp": productos?.codigo_erp,
                              "nombre": productos?.nombre,
                              "id_tipo_producto": productos?.id_tipo_producto,
                              "precio_standar": productos?.precio_standar,
                              // "precio_oferta": productos?.precio_oferta
                              "precio_oferta": productos && validatePrice(productos),
                              "unidad_medida": item?.detalle_ticket[i]?.productos?.unidad_medida,
                           });
                        }
                     }
                  });

                  let venta = {
                     detalle: arrayProducts,
                     ventaDetalle: res.venta,
                     folio: documentoGenerado[0].folio,
                     timbre: (tipo_doc === 'Boleta' ? documentoGenerado[0].urlTimbre : documentoGenerado[0].timbre) || "",
                     montoNeto: monto_neto_calculado,
                  }

                  if (isMultiPago) {
                     venta.ventaDetalle.monto_total = montoTotalParte;

                  }

                  if (tipo_doc === 'Boleta') {
                     setDataImprimir(venta)
                  }
                  if (tipo_doc === 'Factura') {
                     setDataImprimirFactura(venta)
                  }
                  if (tipo_doc === 'notaVenta') {
                     setDataImprimir(venta);
                     setSnackBarConfig(prev => ({
                        ...prev,
                        open: true,
                        severity: 'success',
                        message: `Nota de venta creada con éxito.`,
                     }));
                  }
                  isFinishPart && setOpenPopup(false)
               }
            })
            setArraySelectedProducts([])
            secTipoCupon([]);
            isFinishPart && await loadDataList()
            setLoadData(false);

         }
      }
   }
}