export const verifyBenefitsPrice = (beneficios, productsCard) => {
    let priceTotalDescuento = 0;

    if(beneficios){
        for(let i = 0; i < beneficios.length; i++) {
            let priceTotalProduct = 0;
            const beneficio = beneficios[i];

            let exitsProduct = productsCard.find(product => (product.id || product.id_producto) === beneficio.id_producto);
            if(exitsProduct){
                priceTotalProduct = priceTotalProduct + (Number(exitsProduct.cantidad || 1) * exitsProduct.precio_unitario);
            }
            let descuento = Math.floor((priceTotalProduct*Number(beneficio.descuento))/100);

            if (descuento > Number(beneficio.max_descuento)) {
                priceTotalDescuento = priceTotalDescuento+Number(beneficio.max_descuento);
            } else {
                priceTotalDescuento = priceTotalDescuento+descuento;
            }
        
        }
        return priceTotalDescuento;
    }
}

export const verifyBenefitPrice = (beneficio, productsCard) => {
    let priceTotalDescuento = 0;
    let priceTotalProduct = 0;

    let exitsProduct = productsCard.find(product => (product.id || product.id_producto) === beneficio.id_producto);
    if(exitsProduct){
        priceTotalProduct = priceTotalProduct + (Number(exitsProduct.cantidad || 1) * exitsProduct.precio_unitario);
    }
    let descuento = Math.floor((priceTotalProduct*Number(beneficio.descuento))/100);

    if (descuento > Number(beneficio.max_descuento)) {
        priceTotalDescuento = priceTotalDescuento+Number(beneficio.max_descuento);
    } else {
        priceTotalDescuento = priceTotalDescuento+descuento;
    }

    return priceTotalDescuento;
}