import { editorRepository } from "../repositories/Editor.repository";
// import axios from "axios";
import {KEY_EMPRESA} from '@constants/local-storage';
import {readLocalStorage} from '@helpers/local-storage-helper';
import { distribucionRepository } from "../repositories/Distribucion.repository";

export const editor = {
    cargarModulos,
    addModulo,
    deleteModulo,
    cargarSubModulos,
    getSubmoduloById,
    addSubmodulo,
    deleteSubmodulo,
    deleteSubmodulos,
    addProducto,
    cargarProductos,
    eliminarProducto,
    addProductoAgrupado,
    addItemsProductoAgrupado,
    getItemsProductoAgrupado,
    deleteItemsProductoAgrupado,
    editarProducto,
    getProductoById,
    addItemProductoAgrupado,
    deleteItemProductoAgrupado,
    editItemProductoAgrupado,
    addProductoMenu,
    addItemsMenu,
    getMenu,
    getItemsMenu,
    deleteItemsMenu,
    editMenu,
    addItemMenu,
    deleteItemMenu,
    postProductBoleta,
    listAllSubmodulos,
}


async function cargarModulos () {
    const data: any = readLocalStorage(KEY_EMPRESA);
    const res = await editorRepository.cargarModulos(data.id);
    return res;
}

async function addModulo (nombre:string , state: any) {
    const data: any = readLocalStorage(KEY_EMPRESA);
    const res = await editorRepository.addModulo(nombre, state, data.id);
    return res;
}

async function deleteModulo (id:Number) {
    const res = await editorRepository.deleteModulo(id);
    return res;
}

async function cargarSubModulos (id) {
    const res = await editorRepository.cargarSubModulos(id);
    return res;
}

async function getSubmoduloById (id) {
    const res = await editorRepository.getSubmoduloById(id);
    return res;
}

async function addSubmodulo (nombre:string , state: any, id_modulo:any) {
    const res = await editorRepository.addSubmodulo(nombre, state);
    const resp = await editorRepository.addSubmoduloAModulo(id_modulo, res.data.submoduloID);
    return resp;
}

async function deleteSubmodulo (id:Number, id_modulo:Number) {
   // const res = await editorRepository.deleteSubmodulo(id);
    const resp = await editorRepository.deleteSubmoduloAModulo(id_modulo, id);
    return resp;
}

async function deleteSubmodulos (ids:Number) {
    const res = await editorRepository.deleteSubmodulos(ids);
    return res;
}

//add producto -> add detalle -> add producto a det.producto -> add det.producto a submodulo.

async function addProducto (producto:any , id_submodulo: any, is_new = false, idproduct = null) {
    const data: any = readLocalStorage(KEY_EMPRESA);
    const id_empresa = data.id;

    var arrayProducts: any = localStorage.getItem('productsArray');
    arrayProducts = JSON.parse(arrayProducts);
    var UM: any = localStorage.getItem('UM');
    UM = JSON.parse(UM);

    if(!!!idproduct){
        const resP = await editorRepository.addProducto(producto, id_empresa, is_new);
        if(!resP){
            return resP;
        }
        if(arrayProducts && arrayProducts.length > 0 ){
            for (let i = 0; i < arrayProducts.length; i++) {
               const element = arrayProducts[i];
               await distribucionRepository.register(element.cantidad, element.productos_x_cantidad, UM.id, resP.data.productoID, element.id);
            }
          }
          await editorRepository.addProductoASubmodulo(id_submodulo, resP.data.productoID);
          return resP;
    }else{
        await editorRepository.addProductoASubmodulo(id_submodulo, idproduct);
        return producto;
    }
    
    
}

async function cargarProductos (id) {
    const res = await editorRepository.cargarProductos(id);
    return res;
}


async function eliminarProducto (id_prod,id_sub) {
    //const res = await editorRepository.deleteProducto(id_prod);
    const res = await editorRepository.deleteProductoASubmodulo(id_sub,id_prod);
    return res;
}


async function getProductoById (id_prod) {
    const res = await editorRepository.getProductoById(id_prod);
    return res;
}
async function editarProducto (id_prod,producto) {
    var arrayProducts: any = localStorage.getItem('productsArray');
    arrayProducts = JSON.parse(arrayProducts);

    var UM: any = localStorage.getItem('UM');
    UM = JSON.parse(UM);

    const res = await editorRepository.editProducto(id_prod, producto);
    if(arrayProducts && arrayProducts.length > 0 && res?.data?.productoID){
       await distribucionRepository.deleteByIdInsumo(id_prod);
        for (let i = 0; i < arrayProducts.length; i++) {
            const element = arrayProducts[i];
            // if(element.id_distribucion && element.id_distribucion != null){
            //     await distribucionRepository.update(element.id_distribucion, element.cantidad);
            // }else{
            await distribucionRepository.register(element.cantidad, element.productos_x_cantidad, UM.id, res.data.productoID, element.id_producto || element.id);
            // }
        }
    }
    return res;
}

async function addProductoAgrupado (producto:any , id_submodulo: any, combo:any) {
    const data: any = readLocalStorage(KEY_EMPRESA);
    const id_empresa = data.id;
    //codigo_erp = AG0001

    const resP = await editorRepository.addProducto(producto, id_empresa);

    return resP;
}

async function addItemsProductoAgrupado (combo:Array<any> , productoId: number) {
    const res = await editorRepository.addItemsProductoAgrupado(combo, productoId);

    return res;
}
async function getItemsProductoAgrupado (productoId: number) {
    const res = await editorRepository.getItemsProductoAgrupado(productoId);
    return res;
}

async function deleteItemsProductoAgrupado (productoId: number, subModuloId: number) {
    //const res = await editorRepository.deleteItemsProductoAgrupado(productoId);
    //const res2 = await editorRepository.deleteProducto(productoId);
    const resp3 = await editorRepository.deleteProductoASubmodulo(subModuloId,productoId);

    return resp3;
}

async function addItemProductoAgrupado (item: any) {
    const res = await editorRepository.addItemProductoAgrupado(item);
    return res;
}

async function deleteItemProductoAgrupado (id: number) {
    const res = await editorRepository.deleteItemProductoAgrupado(id);
    return res;
}

async function editItemProductoAgrupado (id:any, cantidad:any) {
    const res = await editorRepository.editItemProductoAgrupado(id, cantidad);
    return res;
}

//ProductoMenu
async function addProductoMenu (producto:any , id_submodulo: any, itemsMenu:any, is_new = false) {
    const id_menu = [];
    const data: any = readLocalStorage(KEY_EMPRESA);
    const id_empresa = data.id;

    const resP = await editorRepository.addProducto(producto, id_empresa, is_new);

    if(resP.data.status === 400){
        return resP.data;
    }

    await editorRepository.addProductoASubmodulo(id_submodulo, resP.data.productoID);

    for(let i=0; i< itemsMenu.length; i++){
        id_menu.push(await editorRepository.addMenu(itemsMenu[i], resP.data.productoID));
    }

    return id_menu;
}

async function addItemsMenu (items: any , menuId: number) {
    await editorRepository.addItemsMenu(items, menuId);
    return;
}

async function getMenu (productoId: number) {
    const res = await editorRepository.getMenu(productoId);
    return res;
}

async function getItemsMenu (menuId: any) {
    const res = await editorRepository.getItemsMenu(menuId);
    return res;
}

async function deleteItemsMenu (productoId: number, subModuloId: number) {
    const res = await editorRepository.deleteMenu(productoId);
    // const res2 = await editorRepository.deleteItemsMenu(menuId);



    //const res3 = await editorRepository.deleteProducto(productoId);
    //const res4 = await editorRepository.deleteProductoASubmodulo(subModuloId,productoId);

    return res;
}

async function editMenu (id:any, nombre:any, cantidad:any) {
    const res = await editorRepository.editMenu(id, nombre, cantidad);
    return res;
}

async function addItemMenu (item: any) {
    const res = await editorRepository.addItemMenu(item);
    return res;
}

async function deleteItemMenu (id: number) {
    const res = await editorRepository.deleteItemMenu(id);
    return res;
}
async function postProductBoleta(form:any){

    const res = await editorRepository.postProductBoleta(form);
    return res;
}

async function listAllSubmodulos () {
    const res = await editorRepository.listAllSubmodulos();
    return res;
}
