import React, { useEffect, useState } from "react";
import { Loading } from "../../components/common/Loading/Loading";
import { Protected } from "@/components/layout/Protected";
import { Props } from "./Clientes.type";
import { Box, Button, Container, createTheme, Divider, FormControlLabel, Grid, InputAdornment, Paper, Switch, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, TextField, ThemeProvider, Tooltip, Typography } from "@mui/material";
import { esES } from "@mui/material/locale";
import { clientesService } from "@/service/services/Clientes.service";
import { PencilIcon } from "@/toolbox/constants/icons";
import VisibilityIcon from '@mui/icons-material/Visibility';
import { Popup } from "../Editor/components/Popup";
import { AddCliente } from "./componentes/AddCliente";
import ContentPasteOffIcon from '@mui/icons-material/ContentPasteOff';
import { TOKEN_API } from "@/toolbox/constants/local-storage";
import { useLocalStorage } from '@toolbox/hooks/local-storage.hook';
import SimCardDownloadIcon from '@mui/icons-material/SimCardDownload';
import excelImage from '../../assets/fileIcons/xlsx.png';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import { styled } from '@mui/material/styles';
import SearchIcon from "@mui/icons-material/Search";

const theme = createTheme(
    {
        palette: {
            primary: { main: '#1976D2' },
        },
    },
    esES,
);

const VisuallyHiddenInput = styled('input')({
    clip: 'rect(0 0 0 0)',
    clipPath: 'inset(50%)',
    height: 2,
    overflow: 'hidden',
    position: 'absolute',
    bottom: 0,
    left: 0,
    whiteSpace: 'nowrap',
    width: 1,
});

export const Clientes: React.FC<Props> = (props: Props): JSX.Element => {

    const [loading, setLoading] = React.useState<boolean>(false);

    const [openPopup, setOpenPopup] = React.useState<boolean>(false);
    const [popUp, setPopUp] = React.useState<String>('');
    const [editMode, setEditMode] = React.useState<boolean>(false);

    const [selectedCliente, setSelectedCliente] = React.useState<any>('');
    const [clientes, setClientes] = React.useState([]);
    const [clientesBackUp, setClientesBackUp] = React.useState([]);
    const [token] = useLocalStorage(TOKEN_API, "");
    const [rowsPerPage, setRowsPerPage] = useState(20);
    const [page, setPage] = useState(0);

    useEffect(() => {
        getClientes();
        // eslint-disable-next-line
    }, []);

    const syncCliente = async () => {
        setLoading(true);
        await clientesService.validateClientesSofnet();
        await getClientes();
    }

    // const getAndValidateClientSofnet = async () => {
    //   const cli = await fetch(`https://api2.softnet.cl/cliente`, {
    //   method: 'GET',
    //   headers: {
    //      'Access-Control-Allow-Origin':'*',
    //      'Content-Type': 'application/x-www-form-urlencoded',
    //      token: token,
    //   },
    //   })
    //   .then(clientes => clientes.json())
    //   .then(async clientes => {
    //      let clientesPush = [];
    //   // eslint-disable-next-line
    //      clientes.map((cli) => {
    //         if(cli.cliente === "SI"){
    //            clientesPush.push(cli)
    //         }
    //      })

    //      return clientesPush;
    //   })
    //   .catch(error => {
    //      return []
    //   })
    //   return cli
    // }

    const getClientes = async () => {
        setLoading(true);
        await clientesService.getClientes().then((data) => {
            setClientes(data.cliente);
            setClientesBackUp(data.cliente)
            setLoading(false);
        })
    }

    const funcionSwitch = async (cliente) => {
        setLoading(true);
        if (cliente.state === 1) {
            await clientesService.editState(cliente.id, 0);
        } else {
            await clientesService.editState(cliente.id, 1);
        }
        getClientes();

    }

    const downloadTemplateProductImport = async () => {
        try {
            const url = await clientesService.downloadTemplateImport()
            if (!url) return
            window.open(url)
        } catch (error) {
            console.error('Error al descargar el archivo:', error);
        }
    }

    const handleImport = async (e) => {
        try {
            const file = e.target.files[0];
            if (!file) return

            const formData = new FormData()
            formData.append('document', file)

            await clientesService.importClientes(formData)
            e.target.value = null
            getClientes()
        } catch (error) {
            console.error('Error al importar el archivo', error)
        }
    }

    const onSearch = (search: string) => {
        const searchLowerCase = search.trim().toLowerCase();
        if (search !== '') {
            const filteredProducts = clientesBackUp.filter(cliente => {
                const nombre = cliente.nombre && cliente.nombre.toLowerCase();
                const rut = cliente.rut && cliente.rut.toLowerCase();
                return (
                    (nombre && nombre.includes(searchLowerCase)) ||
                    (rut && rut.includes(searchLowerCase))
                );
            });
            setClientes(filteredProducts);
        } else {
            setClientes(clientesBackUp);
        }
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };
    
    return (
        <Protected>
            {loading && <Loading />}
            <Grid container spacing={2}>
                <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'center' }}>
                    <Typography variant="h4">Gestion de clientes</Typography>
                </Grid>
                <Grid item container xs={12}
                    sx={{
                        alignItems: "center",
                        display: "flex",
                        justifyContent: "space-between",
                        flexWrap: "wrap",
                        marginBottom: "10px",
                    }}
                >
                    <Grid item>
                        <TextField
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <SearchIcon fontSize="small" />
                                    </InputAdornment>
                                ),
                            }}
                            placeholder="Buscar..."
                            size="small"
                            onChange={(e) => onSearch(e.target.value)}
                        />
                    </Grid>
                    <Grid
                        item
                        sx={{ textAlign: "right" }}
                    >
                        <Button variant='outlined' style={{ textTransform: "capitalize", marginRight: '5px' }}
                            onClick={() => {
                                syncCliente();
                            }}
                        >Sincronizar Clientes</Button>

                        <Button variant='outlined' style={{ textTransform: "capitalize" }}
                            onClick={() => {
                                setOpenPopup(true);
                                setEditMode(true);
                                setPopUp('addCliente');
                            }}
                        >Crear Cliente</Button>
                    </Grid>
                </Grid>
                <Grid item xs={12}>
                    <Box sx={{ Width: '100%' }}>
                        <TableContainer component={Paper}>
                            <ThemeProvider theme={theme}>
                                <Table aria-label="simple table" size='small'>
                                    <TableHead>
                                        <TableRow sx={{ bgcolor: '#1976D2' }}>
                                            <TableCell sx={{ color: 'white' }} >
                                                <strong>N°</strong>
                                            </TableCell>
                                            <TableCell align='center' sx={{ color: 'white' }} >
                                                <strong>Nombre</strong>
                                            </TableCell>
                                            <TableCell align='center' sx={{ color: 'white' }} >
                                                <strong>RUT</strong>
                                            </TableCell>
                                            <TableCell align='center' sx={{ color: 'white' }} >
                                                <strong>Extranjero</strong>
                                            </TableCell>
                                            <TableCell align='center' sx={{ color: 'white' }} >
                                                <strong>Estado</strong>
                                            </TableCell>
                                            <TableCell align='center' sx={{ color: 'white' }} >
                                                <strong>Tipo Persona</strong>
                                            </TableCell>
                                            <TableCell align='center' sx={{ color: 'white', minWidth: '8rem', maxWidth: '12rem' }}><strong>Acciones</strong></TableCell>
                                        </TableRow>
                                    </TableHead>

                                    <TableBody>
                                        {!!clientes.length
                                            ?
                                            clientes?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((cliente, id) => (
                                                <TableRow key={id}>
                                                    <TableCell component="th" scope="row">
                                                        {id + 1}
                                                    </TableCell>
                                                    <TableCell component="th" scope="row">
                                                        {cliente.nombre.toLowerCase()}
                                                    </TableCell>
                                                    <TableCell align='center' component="th" scope="row">
                                                        {cliente.extranjero === '1' ? cliente.doc_iden : cliente.rut}
                                                    </TableCell>
                                                    <TableCell align='center' component="th" scope="row">
                                                        {cliente.extranjero === '1' ? 'Si' : 'No'}
                                                    </TableCell>
                                                    <TableCell align='center' component="th" scope="row">
                                                        <Box>
                                                            <FormControlLabel
                                                                control={<Switch onClick={() => {
                                                                    funcionSwitch(cliente);
                                                                }}
                                                                />}
                                                                checked={cliente.state === 1 ? true : false}
                                                                label=""
                                                            />
                                                        </Box>
                                                    </TableCell>

                                                    <TableCell align='center' component="th" scope="row">
                                                        {cliente.tipo_cliente.tipo}
                                                    </TableCell>
                                                    <TableCell align='center'>
                                                        <Button
                                                            onClick={() => {
                                                                setOpenPopup(true);
                                                                setEditMode(true);
                                                                setPopUp('editCliente');
                                                                setSelectedCliente(cliente);
                                                            }}
                                                        >
                                                            <PencilIcon />
                                                        </Button>

                                                        <Button
                                                            onClick={() => {
                                                                setOpenPopup(true);
                                                                setEditMode(false);
                                                                setPopUp('detailCliente');
                                                                setSelectedCliente(cliente);
                                                            }}
                                                        >
                                                            <VisibilityIcon />
                                                        </Button>
                                                    </TableCell>
                                                </TableRow>
                                            ))
                                            :
                                            <TableRow>
                                                <TableCell align="center" colSpan={6}>Sin resultados</TableCell>
                                            </TableRow>
                                        }
                                    </TableBody>
                                </Table>
                            </ThemeProvider>
                        </TableContainer>
                        <TablePagination
                            rowsPerPageOptions={[20, 30, 40]}
                            component="div"
                            count={clientes?.length || 0}
                            rowsPerPage={rowsPerPage}
                            page={page}
                            onPageChange={handleChangePage}
                            onRowsPerPageChange={handleChangeRowsPerPage}
                        />
                    </Box>
                </Grid>
            </Grid>

            {
                popUp === 'addCliente' && (
                    <Popup
                        largo="sm"
                        title='Agregar Cliente'
                        openPopup={openPopup}
                        setOpenPopup={setOpenPopup}
                    >
                        <AddCliente
                            closeModal={() => { setOpenPopup(false) }}
                            onListClientes={getClientes}
                            editMode={editMode}
                        />

                    </Popup>
                )
            }
            {
                popUp === 'editCliente' && (
                    <Popup
                        largo="sm"
                        title='Editar Cliente'
                        openPopup={openPopup}
                        setOpenPopup={setOpenPopup}
                    >
                        <AddCliente
                            closeModal={() => { setOpenPopup(false) }}
                            cliente={selectedCliente}
                            onListClientes={getClientes}
                            editMode={editMode}
                        />

                    </Popup>
                )
            }
            {
                popUp === 'detailCliente' && (
                    <Popup
                        largo="sm"
                        title='Detalle Cliente'
                        openPopup={openPopup}
                        setOpenPopup={setOpenPopup}
                    >
                        <AddCliente
                            closeModal={() => { setOpenPopup(false) }}
                            cliente={selectedCliente}
                            editMode={editMode}
                        />

                    </Popup>
                )
            }
        </Protected>
    );
}
