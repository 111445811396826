import { Props } from "./Compras.type";
import React, { useEffect, useState } from "react";
import { Loading } from "../../components/common/Loading/Loading";
import { Protected } from "@/components/layout/Protected";
import { Box, Button, Grid, IconButton, InputAdornment, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Tooltip, Typography } from "@mui/material";
import { styled } from '@mui/material/styles';
import { ROUTE_NUEVA_COMPRA } from "@/toolbox/constants/route-map";
import { compraService } from "@/service/services/Compra.service";
import { ComprasTable } from "./components/ComprasTable";
import { useHistory } from 'react-router-dom';
import { KEY_EDIT_COMPRA, PRODUCTS_CARD, KEY_EDIT_COMPRA_ID, KEY_EDIT_COMPRA_PROVEEDOR_ID } from '@constants/local-storage';
import {
   saveLocalStorage
} from '@helpers/local-storage-helper';
import SearchIcon from "@mui/icons-material/Search";
import VisibilityIcon from '@mui/icons-material/Visibility';
import { Popup } from "../Editor/components/Popup";
import { ModalDetalle } from "./components/ModalDetalle";

const Div = styled('div')(({ theme }) => ({
   ...theme.typography.button,
   padding: theme.spacing(1),
   fontWeight: 'bold',
   fontSize: '20px',
   color: 'rgba(102, 102, 102, 0.4)',
}));

const Titulo = styled('div')(({ theme }) => ({
   ...theme.typography.button,
   padding: theme.spacing(1),
   fontWeight: 'bold',
   fontSize: '20px',
   color: 'black',
}));


export const Compras: React.FC<Props> = (props: Props): JSX.Element => {
   const [loading, setLoading] = React.useState<boolean>(false);
   const history = useHistory();
   const [compras, setCompras] = React.useState<Array<any>>([]);
   const [comprasBackUp, setComprasBackUp] = useState<any[]>([])
   //  const [orden, setOrder] = React.useState<Array<any>>([]);
   //  const [comprasOnly, setComprasOnly] = React.useState<Array<any>>([]);
   const [page, setPage] = useState(0);
   const [rowsPerPage, setRowsPerPage] = useState(20);
   const [openPopup, setOpenPopup] = React.useState(false);
   const [compraSelected, setCompraSelect] = React.useState<number>(0);
   const [showModalDelete, setShowModalDelete] = React.useState(false);

   useEffect(() => {
      listCompras();
   }, [])

   const listCompras = async () => {
      setLoading(true);
      await compraService.listCompras().then((data) => {
         // if (data.length > 0) {
         //    let filterNumbers = data.filter(i => i.received_at === null);
         //    setOrder(filterNumbers)
         //    let filters = data.filter(i => i.received_at !!== null);
         //    setComprasOnly(filters)
         // }
         setCompras(data);
         setComprasBackUp(data)
         setLoading(false);
      })
   }

   const handleNewCompra = () => {
      localStorage.removeItem(KEY_EDIT_COMPRA);
      localStorage.removeItem(PRODUCTS_CARD);
      localStorage.removeItem(KEY_EDIT_COMPRA_ID);
      localStorage.removeItem(KEY_EDIT_COMPRA_PROVEEDOR_ID);
      saveLocalStorage(PRODUCTS_CARD, []);
      history.push(ROUTE_NUEVA_COMPRA);
   }

   const onSearch = (search: string) => {
      const searchLowerCase = search.trim().toLowerCase();
      if (search !== '') {
         const filteredProducts = comprasBackUp.filter(compra => (
            compra.folio.toString().includes(searchLowerCase) ||
            (compra?.proveedor?.razon_social?.toLowerCase().includes(searchLowerCase))
         ));
         setCompras(filteredProducts);
      } else {
         setCompras(comprasBackUp);
      }
   }
   
   return (
      <Protected>
         {loading && <Loading />}
         {/* <Box sx={{ marginLeft:'10px',marginRight:'10px', marginBottom:'20px'}}>
                <Grid container justifyContent='space-between' spacing={2}>
                    <Grid item>
                        <Button variant='outlined' onClick={handleNewCompra}
                        >Crear Nueva Compra</Button>
                    </Grid>
                </Grid>
               <Grid
                  container spacing={0} direction="column"
                  alignItems="center" justifyContent="center"
                  style={{ marginBottom: '20px', marginTop:'30px'}}
                  >
                  <Grid item xs={3}>
                     <Titulo>{"Compras realizadas"}</Titulo>
                  </Grid>
               </Grid>

               {!loading && compras.length !== 0 &&
                  <Box sx={{backgroundColor: 'rgba(224, 224, 224, 0.5)', padding: '15px', borderRadius: '10px'}}>
                     <ComprasTable compras={compras} onListCompras={listCompras} type="compra" />
                  </Box>
               }
               {!loading && compras.length === 0 &&
                  <Box sx={{backgroundColor: 'rgba(224, 224, 224, 0.5)', padding: '15px', borderRadius: '10px', height:'100px', justifyContent: 'center',
                  alignItems: 'center', display: 'flex' }} >
                     <Div>{"No se Encontraron Compras"}</Div>
                  </Box>
               }
               {loading &&
                  <Box sx={{backgroundColor: 'rgba(224, 224, 224, 0.5)', padding: '15px', borderRadius: '10px', height:'100px'}}>
                  </Box>
               }
            </Box> */}
         <Grid container spacing={2}>
            <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'center' }}>
               <Typography variant="h4">Compras realizadas</Typography>
            </Grid>
            <Grid item container xs={12}
               sx={{
                  alignItems: "center",
                  display: "flex",
                  justifyContent: "space-between",
                  flexWrap: "wrap",
                  marginBottom: "10px",
               }}
            >
               <Grid item>
                  <TextField
                     InputProps={{
                        startAdornment: (
                           <InputAdornment position="start">
                              <SearchIcon fontSize="small" />
                           </InputAdornment>
                        ),
                     }}
                     placeholder="Buscar..."
                     size="small"
                     onChange={(e) => onSearch(e.target.value)}
                  />
               </Grid>
               <Grid item sx={{ textAlign: "right" }}>
                  <Button variant='outlined' onClick={handleNewCompra}
                  >Nueva compra</Button>
               </Grid>
            </Grid>
            <Grid item xs={12}>
               <TableContainer component={Paper}>
                  <Table sx={{ minWidth: 650 }} aria-label="simple table">
                     <TableHead>
                        <TableRow sx={{ bgcolor: '#1976D2' }}>
                           <TableCell align="left" sx={{ color: 'white' }}>{`N°`}</TableCell>
                           <TableCell align="left" sx={{ color: 'white' }}>Folio</TableCell>
                           <TableCell align="left" sx={{ color: 'white' }}>Proveedor</TableCell>
                           <TableCell align="left" sx={{ color: 'white' }}>Fecha de registro</TableCell>
                           <TableCell align="left" sx={{ color: 'white' }}>Acciones</TableCell>
                        </TableRow>
                     </TableHead>
                     <TableBody>
                        {!!compras.length
                           ?
                           compras?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((compra, index) =>
                              <TableRow key={index}>
                                 <TableCell align="left">{index + 1 + page * rowsPerPage}</TableCell>
                                 <TableCell align='left' component="th" scope="row">
                                    {compra.folio}
                                 </TableCell>
                                 <TableCell align='left' component="th" scope="row">
                                    {compra?.proveedor?.razon_social || '---'}
                                 </TableCell>
                                 <TableCell align='left' component="th" scope="row">
                                    {compra.fecha}
                                 </TableCell>
                                 <TableCell align='left'>
                                    <IconButton aria-label="add to shopping cart" color="secondary" sx={{ marginRight: '5px' }}
                                       onClick={() => {
                                          setOpenPopup(true);
                                          setCompraSelect(compra.id);
                                       }}
                                    >
                                       <VisibilityIcon />
                                    </IconButton>
                                 </TableCell>
                              </TableRow>
                           )
                           :
                           <TableRow>
                              <TableCell align="center" colSpan={12}>Sin resultados</TableCell>
                           </TableRow>
                        }
                     </TableBody>
                  </Table>
               </TableContainer>
            </Grid>
         </Grid>
         {openPopup &&
            <Popup
               largo="md"
               title='Detalle de la Venta'
               openPopup={openPopup}
               setOpenPopup={setOpenPopup}
            >
               <ModalDetalle
                  closeModal={() => setShowModalDelete(false)}
                  compraId={compraSelected}
               />

            </Popup>
         }
      </Protected>
   );
}
