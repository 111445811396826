import React, { useState } from 'react';
import { Grid, TextField, Typography, Button } from '@mui/material';
import { Loading } from '@/components/common/Loading/Loading';
import { Form, Formik } from 'formik';
import {CLIENTE_SHOP, KEY_EMPRESA} from '@constants/local-storage';
import {saveLocalStorage} from '@helpers/local-storage-helper';
import { Snackbar, Alert } from '@mui/material';
import { Props } from './RegisterModal.type';
import { authenticationService } from '@/service/services/Authentication.service';
import { userService } from '@/service/services/User.service';

export const RegisterModal: React.FC<Props> = (props: Props): JSX.Element => {
    const { selectedCompany } = props;
    const [loading, setLoading] = React.useState<boolean>(false);
    // eslint-disable-next-line
    const [data, setData] = React.useState({
        name: '',
        rut: '',
        email: '',
        username: '',
        password: '',
    });

    const [snackBarConfig, setSnackBarConfig] = useState<any>({
        open: false,
        severity: 'error',
        message: 'Error',
        autoHideDuration: 3000,
    });

    const validateForm = async (values) => {
        let errors: any = {};
        if(!values.name) errors.name = "nombre requerido";
        if(!values.rut) errors.rut = "rut requerido";
        if(!values.email) errors.email = "email requerido";
        if(!values.username) errors.username = "nombre de usuario requerido";
        if(!values.password) errors.password = "contraseña requerido";

        return errors;
    }

    const onSubmit = async(values, {resetForm}) => {
        setLoading(true);
        const response = await authenticationService.loginEcommerce( values.name, values.rut, values.username, values.email, values.password);

        if (response.token === '') {
            setSnackBarConfig(prev => ({
               ...prev,
               open: true,
               message: response?.error?.message || "Acceso no autorizado",
            }));
        } else {
            if (selectedCompany?.empresa?.id) {
               const userClient = {
                  id_user: response?.user.id_usuario,
                  nombre_user: response?.user.nombre,
                  rut_user: response?.user.rut_usuario,
                  id_sucursal: selectedCompany?.id,
                  id_empresa: selectedCompany?.empresa?.id,
               }
               await userService.validateDetalleClienteUser(userClient).then((data) => {
                  if(data.status === 200){
                     saveLocalStorage(CLIENTE_SHOP, data?.clienteID);
                     saveLocalStorage(KEY_EMPRESA, {
                        "id": selectedCompany?.empresa?.id,
                        "id_tipo_empresa": selectedCompany?.empresa?.id_tipo_empresa,
                        "nombre": selectedCompany?.empresa?.name,
                        "equivalencia_puntos": selectedCompany?.empresa?.equivalencia_puntos,
                        "id_country": selectedCompany?.empresa?.id_country,
                        "country_name": selectedCompany?.empresa?.country?.nombre,
                        "country_abbreviation": selectedCompany?.empresa?.country?.abbreviation,
                        "mesas": selectedCompany?.empresa?.mesas,
                     });
                     window.location.replace("/productos-ecommerce");
                  }
               });
            } else {
               window.location.replace("/shop-ecommerce");
            }
        }
        setLoading(false);
    }

    return (
        <div className="container">
            {loading && <Loading/>}
            <Snackbar
                open={snackBarConfig.open}
                autoHideDuration={snackBarConfig.autoHideDuration}
                onClose={() => setSnackBarConfig(prev => ({ ...prev, open: false }))}
                anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                >
                <Alert
                onClose={() => setSnackBarConfig(prev => ({ ...prev, open: false }))}
                severity={snackBarConfig.severity}
                variant="filled"
                >
                {snackBarConfig.message}
                </Alert>
            </Snackbar>

            <Formik enableReinitialize initialValues={data} validate={ (values) => validateForm(values) } onSubmit={onSubmit}>
                {({values, errors, touched, handleSubmit, handleChange, setFieldValue}) => {
                    return (
                        <Form onSubmit={handleSubmit} style={{width:'100%'}}>
                            <Grid container spacing={3} alignItems="center" justifyContent="center">

                            <Grid item xs={12} container style={{display: "flex", alignItems: "center"}}>
                                <Grid item xs={4}>
                                    <Typography variant="subtitle1" className="custom-input"><b>Nombre Completo:</b></Typography>
                                </Grid>
                                <Grid item xs={8} style={{display: 'flex', alignItems: "center"}}>
                                    {/* {
                                        errors.nombre && (touched.nombre ? true : false) && (
                                            <Tooltip title={errors.nombre}>
                                                <PriorityHighIcon className={'iconWarning'}/>
                                            </Tooltip>
                                        )
                                    } */}
                                    <TextField
                                    variant="standard"
                                    id="name"
                                    type="text"
                                    name="name"
                                    size='small'
                                    fullWidth
                                    value={values.name}
                                    onChange={handleChange}
                                    placeholder="name"
                                    error={errors.name && touched.name ? true : false}
                                    />
                                </Grid>
                            </Grid>

                            <Grid item xs={12} container style={{display: "flex", alignItems: "center"}}>
                                <Grid item xs={4}>
                                    <Typography variant="subtitle1" className="custom-input"><b>Rut Usuario:</b></Typography>
                                </Grid>
                                <Grid item xs={8} style={{display: 'flex', alignItems: "center"}}>
                                    {/* {
                                        errors.nombre && (touched.nombre ? true : false) && (
                                            <Tooltip title={errors.nombre}>
                                                <PriorityHighIcon className={'iconWarning'}/>
                                            </Tooltip>
                                        )
                                    } */}
                                    <TextField
                                    variant="standard"
                                    id="rut"
                                    type="text"
                                    name="rut"
                                    size='small'
                                    fullWidth
                                    value={values.rut}
                                    onChange={handleChange}
                                    placeholder="rut"
                                    error={errors.rut && touched.rut ? true : false}
                                    />
                                </Grid>
                            </Grid>

                            <Grid item xs={12} container style={{display: "flex", alignItems: "center"}}>
                                <Grid item xs={4}>
                                    <Typography variant="subtitle1" className="custom-input"><b>Correo Usuario:</b></Typography>
                                </Grid>
                                <Grid item xs={8} style={{display: 'flex', alignItems: "center"}}>
                                    {/* {
                                        errors.nombre && (touched.nombre ? true : false) && (
                                            <Tooltip title={errors.nombre}>
                                                <PriorityHighIcon className={'iconWarning'}/>
                                            </Tooltip>
                                        )
                                    } */}
                                    <TextField
                                    variant="standard"
                                    id="email"
                                    type="email"
                                    name="email"
                                    size='small'
                                    fullWidth
                                    value={values.email}
                                    onChange={handleChange}
                                    placeholder="email"
                                    error={errors.email && touched.email ? true : false}
                                    />
                                </Grid>
                            </Grid>

                            <Grid item xs={12} container style={{display: "flex", alignItems: "center"}}>
                                <Grid item xs={4}>
                                    <Typography variant="subtitle1" className="custom-input"><b>Nombre de Usuario:</b></Typography>
                                </Grid>
                                <Grid item xs={8} style={{display: 'flex', alignItems: "center"}}>
                                    {/* {
                                        errors.nombre && (touched.nombre ? true : false) && (
                                            <Tooltip title={errors.nombre}>
                                                <PriorityHighIcon className={'iconWarning'}/>
                                            </Tooltip>
                                        )
                                    } */}
                                    <TextField
                                    variant="standard"
                                    id="username"
                                    type="text"
                                    name="username"
                                    size='small'
                                    fullWidth
                                    value={values.username}
                                    onChange={handleChange}
                                    placeholder="username"
                                    error={errors.username && touched.username ? true : false}
                                    />
                                </Grid>
                            </Grid>

                            <Grid item xs={12} container style={{display: "flex", alignItems: "center"}}>
                                <Grid item xs={4}>
                                    <Typography variant="subtitle1" className="custom-input"><b>Contraseña:</b></Typography>
                                </Grid>
                                <Grid item xs={8} style={{display: 'flex', alignItems: "center"}}>
                                    {/* {
                                        errors.nombre && (touched.nombre ? true : false) && (
                                            <Tooltip title={errors.nombre}>
                                                <PriorityHighIcon className={'iconWarning'}/>
                                            </Tooltip>
                                        )
                                    } */}
                                    <TextField
                                    variant="standard"
                                    id="password"
                                    type="password"
                                    name="password"
                                    size='small'
                                    fullWidth
                                    value={values.password}
                                    onChange={handleChange}
                                    placeholder="password"
                                    error={errors.password && touched.password ? true : false}
                                    />
                                </Grid>
                            </Grid>

                            <Grid item container sx={{marginLeft:'22px', display:'flex', justifyContent:'center'}}>
                                <Button type="submit">
                                    Ingresar
                                </Button>
                            </Grid>

                            </Grid>
                        </Form>
                    )
                }}
            </Formik>
        </div>
    );
}
