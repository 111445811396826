import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogTitle from '@mui/material/DialogTitle';
import { Divider } from '@mui/material';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';

export const ModalConfigConfirm: React.FC<any> = (props:any): JSX.Element => {
    const { open, closeModal, title, context, onConfirm } = props;

    return(
        <div>
            <Dialog
                open={open}
                onClose={closeModal}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    {title ? title : "Modal de confirmación"}
                </DialogTitle>
                <Divider/>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        <span style={{fontSize: '16px'}}>{context ? context : ''}</span>
                    </DialogContentText>
                </DialogContent>
                <Divider/>
                <DialogActions>
                <Button onClick={closeModal}>Cancelar</Button>
                <Button onClick={onConfirm}>
                    Aceptar
                </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}