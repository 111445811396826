import { moneyFormatInt } from "@/toolbox/helpers/money.helper";
import { Box, Checkbox, createTheme, Grid, Paper, Tab, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, ThemeProvider } from "@mui/material";
import { esES } from "@mui/material/locale";
import { useState } from "react";

const theme = createTheme( {
        palette: {  primary: { main: '#1976D2' }, },
    },
    esES,
);


export const DetalleDescuadre = (props) => {
    const {detalle} = props;


    return (
        <Grid>
        <Box sx={{Width: '100%', marginTop:'0px'}}>
            <TableContainer component={Paper}>
            <ThemeProvider theme={theme}>
            <Table aria-label="simple table" size='small'>
            <TableHead>
                <TableRow  sx={{ bgcolor:'#1976D2'}}>
                    <TableCell sx={{ color: 'white' }} >
                        <strong>Forma de Pago</strong>
                    </TableCell>
                    <TableCell align='center' sx={{ color: 'white' }} >
                        <strong>Monto sistema</strong>
                    </TableCell>
                    <TableCell align='center' sx={{ color: 'white' }} >
                        <strong>Es correcto</strong>
                    </TableCell>
                    <TableCell align='center' sx={{ color: 'white' }} >
                        <strong>Monto cierre</strong>
                    </TableCell>
                    <TableCell align='center' sx={{ color: 'white' }} >
                        <strong>Descuadre</strong>
                    </TableCell>
                </TableRow>
            </TableHead>
            <TableBody>
                {
                    detalle && detalle.map((item, id) =>(
                        <TableRow key={id}>
                            <TableCell component="th" scope="row">
                                {item.forma_pago}
                            </TableCell>
                            <TableCell align='center' component="th" scope="row">
                               {moneyFormatInt(parseInt(item?.monto_sistema))}
                            </TableCell>
                            <TableCell align='center' component="th" scope="row">
                             {item?.es_correcto == 1? 'SI': 'NO'}
                            </TableCell> 
                            <TableCell align='center' component="th" scope="row">
                               {moneyFormatInt(parseInt(item?.monto_real))}
                            </TableCell>
                            <TableCell align='center' component="th" scope="row">
                               {moneyFormatInt(parseInt(item?.monto_descuadre))}
                            </TableCell>
                        </TableRow>
                    ))
                }
            </TableBody>
            </Table>
            </ThemeProvider>
            </TableContainer>
        </Box>
    </Grid>
    );
}