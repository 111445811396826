import { Loading } from "@/components/common/Loading";
import { Protected } from "@/components/layout/Protected";
import { RecetaService } from "@/service/services/Receta.service";
import { Button, Grid, IconButton, Menu, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, Tooltip, Typography, styled } from "@mui/material";
import { FC, useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import ArrowCircleLeftIcon from '@mui/icons-material/ArrowCircleLeft';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { ModalConfirm } from "@/components/common/ModalConfirm/ModalConfirm";
import { RecetaModal } from "./RecetaModal";
import { MenuItem } from "react-pro-sidebar";
import SimCardDownloadIcon from '@mui/icons-material/SimCardDownload';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import excelImage from '../../assets/fileIcons/xlsx.png';

const VisuallyHiddenInput = styled('input')({
    clip: 'rect(0 0 0 0)',
    clipPath: 'inset(50%)',
    height: 2,
    overflow: 'hidden',
    position: 'absolute',
    bottom: 0,
    left: 0,
    whiteSpace: 'nowrap',
    width: 1,
});

export const Receta: FC = () => {

    const params: { id: string } = useParams()
    const { id } = params
    const history = useHistory()

    const [loading, setLoading] = useState<boolean>(false)
    const [insumos, setInsumos] = useState<any[]>([])
    const [nombreProducto, setNombreProducto] = useState<string>('')
    const [action, setAction] = useState<string>('')
    const [rowSelected, setRowSelected] = useState<any>(null)
    const [showModalReceta, setShowModalReceta] = useState<boolean>(false)
    const [page, setPage] = useState(0)
    const [rowsPerPage, setRowsPerPage] = useState(20)
    const [showModalDelete, setShowModalDelete] = useState<boolean>(false)
    const [loadingSubmit, setLoadingSubmit] = useState<boolean>(false)
    const [anchorEl, setAnchorEl] = useState(null);

    useEffect(() => {
        getRecetaAPI()
    }, [])

    const getRecetaAPI = async () => {
        setLoading(true)
        const response = await RecetaService.getRecetas({ idproducto: id })
        if (response.data) {
            setNombreProducto(response.data[0]?.producto || '')
            setInsumos(response.data || [])
        }
        setLoading(false)
    }

    const goBack = () => {
        history.push('/mis-productos');
    };

    const onDelete = (rowSelected) => {
        setRowSelected(rowSelected)
        setShowModalDelete(true)
    }

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const confirmDelete = async () => {
        setLoadingSubmit(true)
        await RecetaService.deleteInsumo(rowSelected.id)
        getRecetaAPI()
        setShowModalDelete(false)
        setLoadingSubmit(false)
    }

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleCloseMenu = () => {
        setAnchorEl(null);
    };

    const downloadTemplateImport = async () => {
        try {
            const url = await RecetaService.getTemplateImport()
            if (!url || !url.data) return
            window.open(url.data)
        } catch (error) {
            console.error('Error al descargar el archivo:', error);
        }
    }

    const handleImport = async (e) => {
        try {
            const file = e.target.files[0];
            if (!file) return

            const formData = new FormData()
            formData.append('document', file)
            e.target.value = null

            await RecetaService.importRecetas(formData)
            window.location.reload()
        } catch (error) {
            console.error('Error al importar el archivo', error)
        }
    }

    return (<Protected>
        {loading && <Loading />}
        <Grid container spacing={2}>
            <Grid item xs={12} display='flex' alignItems='center'>
                <Grid className="btn-back-container" onClick={goBack} sx={{ cursor: 'pointer', border: '1px solid #188dcd', borderRadius: '4px', padding: '8px', display: 'flex', alignItems: 'center' }}>
                    <ArrowCircleLeftIcon fontSize='small' sx={{ color: '#188dcd', marginRight: '2px' }} />
                    <Typography className='btn-back-text'>Volver</Typography>
                </Grid>
                <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'center' }}>
                    <Typography variant="h4">RECETA: {nombreProducto}</Typography>
                </Grid>
            </Grid>
            <Grid item container xs={12}
                sx={{
                    alignItems: "center",
                    display: "flex",
                    justifyContent: "space-between",
                    flexWrap: "wrap",
                    marginBottom: "10px",
                }}
            >
                <Grid item>

                </Grid>
                <Grid
                    item
                    sx={{ textAlign: "right" }}
                >
                    <Button variant='outlined' onClick={handleClick} >
                        Opciones
                    </Button>
                    <Menu
                        id="simple-menu-2"
                        anchorEl={anchorEl}
                        open={Boolean(anchorEl)}
                        onClose={handleCloseMenu}
                    >
                        <MenuItem onClick={() => { setAction('create'); setRowSelected(null); setShowModalReceta(true); handleCloseMenu(); }} style={{ padding: '5px' }}>
                            <Button variant='outlined' className="btnSync" fullWidth>
                                Agregar insumo
                            </Button>
                        </MenuItem>
                        <MenuItem onClick={() => { downloadTemplateImport(); handleCloseMenu(); }} style={{ padding: '5px' }}>
                            <Tooltip title={'Descargar plantilla'}>
                                <Button
                                    component="label"
                                    variant="outlined"
                                    className="btn-dwtemplate"
                                    fullWidth
                                    startIcon={<SimCardDownloadIcon fontSize="small" />}
                                    endIcon={<img src={excelImage} alt="Icon" width={20} height={20} />}
                                >
                                    Descargar plantilla
                                </Button>
                            </Tooltip>
                        </MenuItem>
                        <MenuItem style={{ padding: '5px' }}>
                            <Tooltip title={'Importar productos'}>
                                <Button
                                    component="label"
                                    variant='outlined'
                                    className="btn-import"
                                    fullWidth
                                    startIcon={<CloudUploadIcon fontSize='small' />}
                                    endIcon={<img src={excelImage} alt="Icon" width={20} height={20} />}
                                >
                                    Importar productos
                                    <VisuallyHiddenInput type="file" accept=".xlsx" onChange={handleImport} />
                                </Button>
                            </Tooltip>
                        </MenuItem>
                    </Menu>
                </Grid>
            </Grid>
            {<Grid item xs={12}>
                <TableContainer component={Paper}>
                    <Table sx={{ minWidth: 650 }} aria-label="simple table">
                        <TableHead>
                            <TableRow sx={{ bgcolor: '#1976D2' }}>
                                <TableCell align="left" sx={{ color: 'white' }}>{`N°`}</TableCell>
                                <TableCell align="left" sx={{ color: 'white' }}>Código</TableCell>
                                <TableCell align="left" sx={{ color: 'white' }}>Insumo</TableCell>
                                <TableCell align="left" sx={{ color: 'white' }}>Cantidad</TableCell>
                                <TableCell align="center" sx={{ color: 'white' }}>Opciones</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {insumos?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((insumo, index) =>
                                <TableRow key={index}>
                                    <TableCell align="left">{index + 1 + page * rowsPerPage}</TableCell>
                                    <TableCell align="left">{insumo.codigo_insumo}</TableCell>
                                    <TableCell align="left">{insumo.insumo}</TableCell>
                                    <TableCell align="left">{insumo.cantidad}</TableCell>
                                    <TableCell align="center">
                                        <Tooltip title={"Editar insumo"}>
                                            <IconButton onClick={() => { setAction('edit'); setRowSelected(insumo); setShowModalReceta(true) }}>
                                                <EditIcon color="primary" />
                                            </IconButton>
                                        </Tooltip>
                                        <Tooltip title={"Eliminar insumo"}>
                                            <IconButton onClick={() => onDelete(insumo)}>
                                                <DeleteIcon color="error" />
                                            </IconButton>
                                        </Tooltip>
                                    </TableCell>
                                </TableRow>
                            )}
                        </TableBody>
                    </Table>
                </TableContainer>
                <TablePagination
                    rowsPerPageOptions={[20, 30, 40]}
                    component="div"
                    count={insumos?.length || 0}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                />
            </Grid>}
        </Grid>
        {showModalDelete &&
            <ModalConfirm
                open={showModalDelete}
                closeModal={() => setShowModalDelete(false)}
                onCancel={() => setShowModalDelete(false)}
                onConfirm={confirmDelete}
                status2={loadingSubmit}
                title={"¿Esta seguro de eliminar el insumo de la receta?"}
            />
        }
        {showModalReceta &&
            <RecetaModal
                open={showModalReceta}
                setOpenPopup={setShowModalReceta}
                action={action}
                onList={getRecetaAPI}
                rowSelected={rowSelected}
                idproducto={parseInt(id)}
            />
        }
    </Protected>)
}