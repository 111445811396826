import { BeneficioPuntosRepository } from "../repositories/BeneficioPuntos.repository";

export const BeneficioPuntosService = {
    getBeneficioPuntos,
    // showCupon,
    register,
    update,
    updateStatus,
    deleteBeneficioPuntos,
    BeneficioPuntosAvailable,
}

async function BeneficioPuntosAvailable(){
    const res = await BeneficioPuntosRepository.beneficioPuntosAvailable();
    return res;
}

async function getBeneficioPuntos(){
    const res = await BeneficioPuntosRepository.getBeneficioPuntos();
    return res;
}

// async function showCupon(id: number){
//     const res = await CuponesRepository.showCupon(id);
//     return res;
// }

async function register(data: any){
    const res = await BeneficioPuntosRepository.register(data);
    return res;
}

async function update(data:{}, idBeneficio:number){
    const res = await BeneficioPuntosRepository.update(data, idBeneficio);
    return res;
}

async function updateStatus(idBeneficio:number, state:any){
    const res = await BeneficioPuntosRepository.updateStatus(idBeneficio, state);
    return res;
}

async function deleteBeneficioPuntos(idBeneficio:number){
    const res = await BeneficioPuntosRepository.deleteBeneficioPuntos(idBeneficio);
    return res;
}