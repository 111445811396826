import React from 'react';
import { Grid, Typography } from '@mui/material';
import { moneyFormatInt } from '@/toolbox/helpers/money.helper';
import { Props } from './ComponentPrint.type';
import moment from 'moment';

export const ComponentPrint: React.FC<Props> = (props: Props) => {

   return (
      <div style={{ marginBottom: 30, width: '300px', margin: 'auto', marginTop: 30, padding: "10px" }}>
         <div style={{ width: "100%" }} >
            <div style={{ textAlign: "center" }}>
               <Typography
                  sx={{ fontSize: '1.6em !important', fontFamily: 'sans-serif', fontWeight: '700' }}
               >{props.data?.nombreEmpresa || "Empresa"}</Typography>
               <Typography
                  sx={{ fontSize: '1.3em !important', fontFamily: 'sans-serif', fontWeight: '700' }}
               >{props.data?.rutEmpresa ? "RUT " + props.data?.rutEmpresa : ''}</Typography>
               <Typography
                  sx={{ fontSize: '1.3em !important', fontFamily: 'sans-serif', fontWeight: '700' }}
               >{props.data.titulo ? props.data.titulo : 'BOLETA ELECTRONICA'}</Typography>
               <Typography
                  sx={{ fontSize: '1.3em !important', fontFamily: 'sans-serif', fontWeight: '700' }}
               >{props.data.folio ? "N° " + props.data.folio : ' '}</Typography>
               {/* <Typography
                     sx={{fontSize:'1.4em !important', fontFamily:'sans-serif',fontWeight:'700'}}
                     >{ventaDetalle?.pedido?.ticket?.n_ticket || "--------"}</Typography> */}
            </div>
            <hr />
            <div>
               {props.data?.direccion &&
                  <Typography sx={{ fontSize: '1.2em !important' }}>
                     <strong>SUCURSAL:</strong>  {props.data?.direccion} </Typography>
               }
               <Typography sx={{ fontSize: '1.2em !important' }}
               > <strong>RUT:</strong> {(props.data.clienteSelect?.extranjero === '1' ? props.data.clienteSelect?.doc_iden : props.data.clienteSelect?.rut) || ""}{''}</Typography>
               <Typography sx={{ fontSize: '1.2em !important' }}
               > <strong>Cliente:</strong> {props.data.clienteSelect?.label || props.data.clienteSelect?.nombre || ""}{''}</Typography>

               {props.data.nombreCajero && (<>
                  <Typography sx={{ fontSize: '1.2em !important' }}
                  > <strong>Cajero:</strong> {props.data.nombreCajero || ""}</Typography>
                  <Typography sx={{ fontSize: '1.2em !important' }}
                  > <strong>Caja:</strong> {props.data.caja || ""}</Typography>
               </>)}

               {/* <Typography sx={{fontSize:'1.2em !important'}}
                        ><strong>Empleado:</strong> {props.data.ventaDetalle?.pedido?.usuario?.nombre || "Empleado General"}</Typography> */}
               <Typography sx={{ fontSize: '1.2em !important' }}
               ><strong>Fecha:</strong>  { props.data.ventaDetalle?.created_at ? moment(props.data.ventaDetalle?.created_at).format('YYYY-MM-DD HH:mm') : "--------"}{' '}</Typography>
               {/* <b>HORA:</b> {props.data?.horareporte}{' '} */}
            </div>
            <hr style={{ border: '0', height: '2px', borderTop: '1px dashed black', borderBottom: '1px dashed black' }} />
            <div>
               <Grid container>
                  <Grid item xs={6}>
                     <Typography sx={{ textAlign: "left", fontSize: '1.2em !important' }}>Artículo</Typography>
                  </Grid>
                  <Grid item xs={6}>
                     <Typography sx={{ textAlign: "right", fontSize: '1.2em !important' }}>Valor</Typography>
                  </Grid>
               </Grid>
            </div>
            <div>
               <hr style={{ border: '0', height: '2px', borderTop: '1px dashed black', borderBottom: '1px dashed black' }} />
            </div>
            <div>
               <Grid container>
                  {(props.data.detalle || []).map((item, index) => (
                     <>
                        <Grid item xs={12}>
                           <Typography sx={{ textAlign: "left", fontSize: '1em !important' }}>{item?.nombre}</Typography>
                        </Grid>
                        <Grid item xs={8}>
                           <Typography sx={{ textAlign: "left", fontSize: '1em !important' }}>
                              {item.cantidad} {item?.unidad_medida?.abbreviation ? item?.unidad_medida?.abbreviation : ''} x {moneyFormatInt(Math.ceil(item?.precio_unitario))}</Typography>
                        </Grid>
                        <Grid item xs={4}>
                           <Typography sx={{ textAlign: "right", fontSize: '1em !important' }}>
                              {moneyFormatInt(Math.ceil(Number(item?.precio_unitario) * Number(item.cantidad) / Number(item.cantidad_peso || 1)))}</Typography>
                        </Grid>
                     </>
                  ))}
               </Grid>
            </div>
            <hr />
            <div>
               <Grid container xs={12} style={{ padding: "5px", display: "flex", alignItems: "center", color: "#000" }}>
                  {/* MONTO TOTAL */}
                  <Grid item xs={8}>
                     <Typography sx={{ textAlign: "right", fontSize: '1em !important' }}><strong>Descuento</strong></Typography>
                  </Grid>
                  <Grid item xs={4} >
                     <Typography sx={{ textAlign: "right", fontWeight: "bold", fontSize: '1em !important' }}><strong>{moneyFormatInt(Math.ceil(props.data.ventaDetalle?.descuento_x_puntos))}</strong></Typography>
                  </Grid>
                  <Grid item xs={8}>
                     <Typography sx={{ textAlign: "right", fontSize: '1em !important' }}><strong>Monto Neto</strong></Typography>
                  </Grid>
                  <Grid item xs={4} >
                     <Typography sx={{ textAlign: "right", fontWeight: "bold", fontSize: '1em !important' }}><strong>{moneyFormatInt(Math.ceil(props.data.montoNeto))}</strong></Typography>
                  </Grid>
                  {
                     props.data.exento > 0 && (<>
                        <Grid item xs={8}>
                           <Typography sx={{ textAlign: "right", fontSize: '1em !important' }}><strong>Monto Exento</strong></Typography>
                        </Grid>
                        <Grid item xs={4} >
                           <Typography sx={{ textAlign: "right", fontWeight: "bold", fontSize: '1em !important' }}><strong>{moneyFormatInt(Math.ceil(props.data.exento))}</strong></Typography>
                        </Grid>
                     </>)
                  }
                  <Grid item xs={8}>
                     <Typography sx={{ textAlign: "right", fontSize: '1em !important' }}><strong>IVA (19%)</strong></Typography>
                  </Grid>
                  <Grid item xs={4} >
                     <Typography sx={{ textAlign: "right", fontWeight: "bold", fontSize: '1em !important' }}><strong>{moneyFormatInt(Math.ceil(props.data.IVA))}</strong></Typography>
                  </Grid>
                  <Grid item xs={8}>
                     <Typography sx={{ textAlign: "right", fontSize: '1em !important' }}><strong>Total a Pagar</strong></Typography>
                  </Grid>
                  <Grid item xs={4} >
                     <Typography sx={{ textAlign: "right", fontWeight: "bold", fontSize: '1em !important' }}><strong>{moneyFormatInt(Math.ceil(props.data.ventaDetalle?.monto_total))}</strong></Typography>
                  </Grid>
               </Grid>
            </div>
            <hr />
            <div>
               <Grid container alignItems="center">
                  {<img
                     width='100%'
                     alt='Timbre Fiscal'
                     className='timbrefiscal'
                     src={props?.data.timbre}
                  //src={props.data.timbre!=''?props.data.timbre:`data:image/png;base64, ${timbre}`}
                  />}
                  <p style={{ textAlign: 'center', fontSize: 12, margin: 'auto' }}>
                     Timbre Electrónico S.I.I. · Verifique Documento: http://www.sii.cl
                  </p>
               </Grid>
            </div>
         </div>
      </div>
   )
}
