import './App.css';
import AllRoutes from './routes/AllRoutes';
import { Provider } from 'react-redux';
import store from './redux/store';
import { ThemeProvider, createTheme } from '@mui/material';

const theme = createTheme({
   typography: {
       fontFamily: [
           'Lato',
           'sans-serif',
       ].join(','),
   },
});

function App() {
   return (
      <ThemeProvider theme={theme}>
         <Provider store={store}>
            <AllRoutes />
         </Provider>
      </ThemeProvider>
   );
   /* return (
      <AllRoutes/>
   );*/
} 

export default App;
