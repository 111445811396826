import { Props } from "./AddProductoS.type";
import React, { useEffect, useState } from 'react';
import { Divider, Grid, TextField, Typography, Tooltip, Button, Select, MenuItem } from '@mui/material';
import { Loading } from '@/components/common/Loading/Loading';
import { Form, Formik } from 'formik';
import { KEY_EMPRESA } from '@constants/local-storage';
import { readLocalStorage, removeLocalStorage } from '@helpers/local-storage-helper';
import { editor } from "@/service/services/Editor.service";
import PriorityHighIcon from '@mui/icons-material/PriorityHigh';
import { Snackbar, Alert } from '@mui/material';
import { Popup } from "@/views/Editor/components/Popup";
import { ProductsNoInsumo } from "@/views/Editor/components/ProductsNoInsumo";
import { distribucionService } from "@/service/services/Distribucion.service";
import { useLocalStorage } from '@toolbox/hooks/local-storage.hook';
import { DatePicker, LocalizationProvider } from "@mui/lab";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import { TOKEN_API } from "@/toolbox/constants/local-storage";
import esLocale from 'date-fns/locale/es';
import moment from "moment";
import '../Productos.css';
import { productoService } from "@/service/services/Producto.service";
import { TipoImpuestoService } from "@/service/services/TipoImpuesto.service";

export const AddProductoS: React.FC<Props> = (props: Props): JSX.Element => {
    const [token] = useLocalStorage(TOKEN_API, "");
    const { codigoBarras, closeModal, producto, editMode, getProducts, id_sub, handleLoad, is_supply, isSupplyOrProduct } = props;

    const [loading, setLoading] = React.useState<boolean>(false);
    // eslint-disable-next-line
    const [openPopup, setOpenPopup] = React.useState<boolean>(false);
    // eslint-disable-next-line
    const [data, setData] = React.useState({
        id: producto && producto.id,
        codigo_erp: producto && producto.codigo_erp ? producto.codigo_erp : codigoBarras ? codigoBarras : "",
        nombre: producto && producto.nombre ? producto.nombre : "",
        precio_standar: producto && producto.precio_standar ? producto.precio_standar : "",
        precio_oferta: producto && producto.precio_oferta ? producto.precio_oferta : "",
        caducidad_precio_oferta: producto && producto.caducidad_precio_oferta ? producto.caducidad_precio_oferta : "",
        id_tipo_producto: producto && producto.id_tipo_producto ? producto.id_tipo_producto : 1,
        id_empresa: producto && producto.id_empresa ? producto.id_empresa : "",
        stock: producto && producto.stock ? producto.stock : "",
        is_supply: producto && producto.is_supply ? producto.is_supply : false,
        stock_minimo: producto && producto.stock_minimo ? producto.stock_minimo : 0,
        cantidad_peso: producto && producto.cantidad_peso ? producto.cantidad_peso : 1,
        id_unidad_medida: producto && producto.id_unidad_medida ? producto.id_unidad_medida : 1,
        familia_id: producto && producto.familia_id ? producto.familia_id : 0,
        sub_familia_id: producto && producto.sub_familia_id ? producto.sub_familia_id : 0,
        marca_id: producto && producto.marca_id ? producto.marca_id : 0,
        linea_id: producto && producto.linea_id ? producto.linea_id : 0,
        idtipo_impuesto: producto && producto.idtipo_impuesto ? producto.idtipo_impuesto : 0,
    });
    const [ProductsSelect, setProductsSelect] = React.useState([]);
    const [submodulo, setSubModulo] = React.useState([]);
    const [UM, setUM] = React.useState<any>('');
    const [unidadesMedida, setUnidadesMedida] = React.useState([]);
    const [tipo, setTipo] = React.useState([
        {id: 6, name:'Producto'},
        {id: 10, name:'Insumo'}
    ]);
    const [selectedTipo, setSelctedTipo] = React.useState<string>('6')
    const [familias, setFamilia] = React.useState<Array<any>>([]);
    console.log(familias);
    const [lineas, setLineas] = React.useState<Array<any>>([]);
    const [familiasSelected, setFamiliasSelected] = React.useState<any>({
        id: 0,
        nombre: "Familia",
        estado: 0,
        subFamilia: [],
    });
    // eslint-disable-next-line
    const [selectUniMed, setSelectUniMed] = React.useState<any>("");
    const [dateCaducidad, setDateCaducidad] = React.useState(new Date());

    const [snackBarConfig, setSnackBarConfig] = useState<any>({
        open: false,
        severity: 'error',
        message: 'Error',
        autoHideDuration: 3000,
    });
    const [dataEmpresa] = useLocalStorage(KEY_EMPRESA, {
        id: 0, id_tipo_empresa: 0, equivalencia_puntos: 0,
        id_country: 0, country_name: "", country_abbreviation: "",
    });
    const [tiposImpuesto, setTiposImpuesto] = useState<any>([])


    const SessionEmpresa: any = readLocalStorage(KEY_EMPRESA);

    useEffect(() => {
        getUnidadesMedia();
        getFamiliesAndSubFamilies();
        getLinesProducts();
        getTiposImpuestoApi()
    }, []);

    useEffect(() => {
        const array = localStorage.getItem('productsArray');
        setProductsSelect(JSON.parse(array));

        const uMedida = localStorage.getItem('UM');
        setUM(JSON.parse(uMedida));
        cargarModulos();
        // eslint-disable-next-line
    }, [openPopup]);

    const cargarSubModulos = async (ids: any) => {
        setLoading(true);
        const res = await editor.cargarSubModulos(ids);
        if (res.status === 200) {
            const submodulos: any = res.submodulos;
            // eslint-disable-next-line
            submodulos.map((item) => {
                const valor = Object.values(item);
                if (valor[0]['nombre'] === 'Simple') {
                    setSubModulo(valor)
                }
            })
        }
        setLoading(false);
    }

    const getTiposImpuestoApi = async () => {
        setLoading(true)
        const response = await TipoImpuestoService.getTiposImpuesto()
        if (response.data?.length) {
            setTiposImpuesto(response.data)
        }
        setLoading(false)
    }

    const cargarModulos = async () => {
        setLoading(true);
        const res = await editor.cargarModulos();
        if (res.status === 200) {
            const modulos: any = Object.values(res.modulos);
            for (let i = 0; i < modulos.length; i++) {
                if (modulos[i].submodulos) {
                    cargarSubModulos(modulos[i].submodulos);
                }
            }
        }
        setLoading(false);
    }

    const getUnidadesMedia = async () => {
        setLoading(true);
        await distribucionService.ListUnidadMedida().then((data) => {
            setUnidadesMedida(data || []);
        });
        setLoading(false);
    }

    const getLinesProducts = async () => {
        setLoading(true);
        await productoService.getLinesProducts().then((data) => {
            if (data.status === 200) {
                setLineas(data.lines || []);
            } else {
                setLoading(false);
                return setSnackBarConfig(prev => ({
                    ...prev,
                    open: true,
                    message: `No se cargaron lineas de productos`,
                }));
            }
        });
        setLoading(false);
    }
    const getFamiliesAndSubFamilies = async () => {
        setLoading(true);
        await productoService.getFamiliesAndSubFamilies().then((data) => {
            if (data.status === 200) {
                setFamilia(data.families || []);
            } else {
                setLoading(false);
                return setSnackBarConfig(prev => ({
                    ...prev,
                    open: true,
                    message: `No se cargaron Familia de productos`,
                }));
            }
        });
        setLoading(false);
    }

    const validateForm = (values) => {
        let errors: any = {};

        if (!values.codigo_erp) errors.codigo_erp = "codigo requerido";
        if (!values.nombre) errors.nombre = "nombre requerido";
        if (!values.cantidad_peso) errors.cantidad_peso = "cantidad requerida";
        if (!values.precio_standar) errors.precio_standar = "precio standar requerido";
        if (values.precio_standar && !(Number.isInteger(Number(values.precio_standar)))) errors.precio_standar = "no se permiten decimal";
        if (values.precio_oferta && !(Number.isInteger(Number(values.precio_oferta)))) errors.precio_oferta = "no se permiten decimal";
        if (values.stock_minimo < 0) errors.stock_minimo = "Stock minimo requerido";

        return errors;
    }

    const onSubmit = async (values, { resetForm }) => {


        if (dataEmpresa.country_name === "Chile") {
            let standar = Math.abs(Number(values.precio_standar || 0));
            let oferta = Math.abs(Number(values.precio_oferta || 0));

            if (((standar - Math.floor(standar)) !== 0) || ((oferta - Math.floor(oferta)) !== 0)) {
                return setSnackBarConfig(prev => ({
                    ...prev,
                    open: true,
                    message: `Los precios deben ser enteros`,
                }));
            }

            let standar_t = standar / 10;
            let oferta_t = oferta / 10;

            if (((standar_t - Math.floor(standar_t)) !== 0) || ((oferta_t - Math.floor(oferta_t)) !== 0)) {
                return setSnackBarConfig(prev => ({
                    ...prev,
                    open: true,
                    message: `Los precios deben terminar en 0`,
                }));
            }

        }

        let precio_caducidad;
        if (values.precio_oferta !== "") {
            precio_caducidad = moment(dateCaducidad).format('DD-MM-YYYY');
        } else {
            precio_caducidad = null;
        }

        setLoading(true);

        const product = {
            codigo_erp: values.codigo_erp,
            nombre: values.nombre,
            precio_standar: values.precio_standar,
            precio_oferta: values.precio_oferta,
            id_tipo_producto: values.id_tipo_producto,
            id_empresa: SessionEmpresa.id,
            is_supply: isSupplyOrProduct ? ( selectedTipo == '10' ? true :false) : is_supply,
            stock: values.stock_minimo || 0,
            cantidad_peso: values.cantidad_peso,
            id_unidad_medida: 1, //UNIDAD por defecto
            caducidad_precio_oferta: precio_caducidad,
            familia_id: values.familia_id,
            sub_familia_id: values.sub_familia_id,
            marca_id: values.marca_id,
            linea_id: values.linea_id,
            idtipo_impuesto: values.idtipo_impuesto
        }
        
        if (id_sub) {
            await editor.addProducto(product, id_sub, true).then((data) => {
                if (data.data.status === 400) {
                    setSnackBarConfig(prev => ({
                        ...prev,
                        open: true,
                        message: `${data.data.error}`,
                    }));
                } else {
                    getProducts && getProducts();
                    closeModal();
                    handleLoad && handleLoad();
                }
            });
        } else {
            await editor.addProducto(product, submodulo[0]?.id, true).then((data) => {
                if (data === false) {
                    setSnackBarConfig(prev => ({
                        ...prev,
                        open: true,
                        message: `No se pudo realizar el registro`,
                    }));
                    setLoading(false);
                }
                if (data.data.status === 400) {
                    setSnackBarConfig(prev => ({
                        ...prev,
                        open: true,
                        message: `${data.data.error}`,
                    }));
                } else {
                    getProducts && getProducts();
                    closeModal();
                }
            })
        }
        removeLocalStorage('productsArray');
        removeLocalStorage('UM');
        setLoading(false);
    }

    const handleChangeFamilia = (id) => {
        setFamiliasSelected({
            id: 0,
            nombre: "",
            subFamilia: [],
            estado: 0,
        })
        let familia = familias.find((f: any) => f.id === id);
        if (familia) {
            setFamiliasSelected(familia)
        }
    }

    return (
        <div className="container">
            {loading && <Loading />}

            <Snackbar
                open={snackBarConfig.open}
                autoHideDuration={snackBarConfig.autoHideDuration}
                onClose={() => setSnackBarConfig(prev => ({ ...prev, open: false }))}
                anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
            >
                <Alert
                    onClose={() => setSnackBarConfig(prev => ({ ...prev, open: false }))}
                    severity={snackBarConfig.severity}
                    variant="filled"
                >
                    {snackBarConfig.message}
                </Alert>
            </Snackbar>

            <Formik enableReinitialize initialValues={data} validate={(values) => validateForm(values)} onSubmit={onSubmit}>
                {({ values, errors, touched, handleSubmit, handleChange, setFieldValue }) => {
                    return (
                        <Form onSubmit={handleSubmit}>
                            <Grid container spacing={3} alignItems="center" justifyContent="center">

                                {
                                    isSupplyOrProduct && 
                                    
                                    <Grid container item xs={12} style={{ display: "flex", alignItems: "center" }}>
                                    <Grid item xs={4}>
                                        <Typography variant="subtitle1"><b>Tipo:</b> </Typography>
                                    </Grid>
                                    <Grid item xs={8} style={{ display: 'flex', alignItems: "center" }}>
                                        <Select
                                            id="id_tipo"
                                            value={selectedTipo}
                                            onChange={(event) => {
                                                setSelctedTipo(event.target.value);
                                            }}
                                            fullWidth
                                            disabled={!editMode}
                                        >
                                            {
                                                tipo.map((item, i) => {
                                                    return <MenuItem key={i} value={item.id}>{item.name}</MenuItem>;
                                                })
                                            }

                                        </Select>
                                    </Grid>
                                </Grid>
                                }

                                <Grid item xs={12} container style={{ display: "flex", alignItems: "center" }}>
                                    <Grid item xs={4}>
                                        <Typography variant="subtitle1" className="custom-input"><b>Código:</b></Typography>
                                    </Grid>
                                    <Grid item xs={8} style={{ display: 'flex', alignItems: "center" }}>
                                        {
                                            errors.codigo_erp && (touched.codigo_erp ? true : false) && (
                                                <Tooltip title={errors.codigo_erp}>
                                                    <PriorityHighIcon className={'iconWarning'} />
                                                </Tooltip>
                                            )
                                        }
                                        <TextField
                                            id="codigo_erp"
                                            type="text"
                                            name="codigo_erp"
                                            fullWidth
                                            value={values.codigo_erp}
                                            onChange={handleChange}
                                            placeholder="Codigo del producto"
                                            error={errors.codigo_erp && touched.codigo_erp ? true : false}
                                            disabled={!editMode}
                                        />
                                    </Grid>
                                </Grid>

                                <Grid item xs={12} container style={{ display: "flex", alignItems: "center" }}>
                                    <Grid item xs={4}>
                                        <Typography variant="subtitle1" className="custom-input"><b>Nombre:</b></Typography>
                                    </Grid>
                                    <Grid item xs={8} style={{ display: 'flex', alignItems: "center" }}>
                                        {
                                            errors.nombre && (touched.nombre ? true : false) && (
                                                <Tooltip title={errors.nombre}>
                                                    <PriorityHighIcon className={'iconWarning'} />
                                                </Tooltip>
                                            )
                                        }
                                        <TextField
                                            id="nombre"
                                            type="text"
                                            name="nombre"
                                            fullWidth
                                            value={values.nombre}
                                            onChange={handleChange}
                                            placeholder="Nombre del producto"
                                            error={errors.nombre && touched.nombre ? true : false}
                                            disabled={!editMode}
                                        />
                                    </Grid>
                                </Grid>

                                <Grid item xs={12} container style={{ display: "flex", alignItems: "center" }}>
                                    <Grid item xs={4}>
                                        <Typography variant="subtitle1" className="custom-input"><b>Precio standar:</b></Typography>
                                    </Grid>
                                    <Grid item xs={8} style={{ display: 'flex', alignItems: "center" }}>
                                        {
                                            errors.precio_standar && (touched.precio_standar ? true : false) && (
                                                <Tooltip title={errors.precio_standar}>
                                                    <PriorityHighIcon className={'iconWarning'} />
                                                </Tooltip>
                                            )
                                        }
                                        <TextField
                                            id="precio_standar"
                                            type="text"
                                            name="precio_standar"
                                            fullWidth
                                            value={values.precio_standar}
                                            onChange={handleChange}
                                            placeholder="precio standar"
                                            error={errors.precio_standar && touched.precio_standar ? true : false}
                                            disabled={!editMode}
                                        />
                                    </Grid>
                                </Grid>

                                <Grid item xs={12} container style={{ display: "flex", alignItems: "center" }}>
                                    <Grid item xs={4}>
                                        <Typography variant="subtitle1" className="custom-input"><b>Precio oferta:</b></Typography>
                                    </Grid>
                                    <Grid item xs={8} style={{ display: 'flex', alignItems: "center" }}>
                                        {
                                            errors.precio_oferta && (touched.precio_oferta ? true : false) && (
                                                <Tooltip title={errors.precio_oferta}>
                                                    <PriorityHighIcon className={'iconWarning'} />
                                                </Tooltip>
                                            )
                                        }
                                        <TextField
                                            id="precio_oferta"
                                            type="text"
                                            name="precio_oferta"
                                            fullWidth
                                            value={values.precio_oferta}
                                            onChange={handleChange}
                                            error={errors.precio_oferta && touched.precio_oferta ? true : false}
                                            placeholder="precio oferta"
                                            disabled={!editMode}
                                        />
                                    </Grid>
                                </Grid>

                                {
                                    values.precio_oferta !== "" && (
                                        <Grid item xs={12} container style={{ display: "flex", alignItems: "center" }}>
                                            <Grid item xs={4}>
                                                <Typography variant="subtitle1" className="custom-input"><b>Caducidad precio oferta:</b></Typography>
                                            </Grid>
                                            <Grid item xs={8} style={{ display: 'flex', alignItems: "center" }}>
                                                <LocalizationProvider dateAdapter={AdapterDateFns} locale={esLocale} style={{ width: '300px' }}>
                                                    <DatePicker
                                                        className={'fecha'}
                                                        views={['year', 'month', 'day']}
                                                        minDate={new Date('2022-01-01')}
                                                        maxDate={new Date('2025-01-01')}
                                                        value={dateCaducidad}
                                                        onChange={(newValue) => {
                                                            setDateCaducidad(newValue);
                                                        }}
                                                        renderInput={(params) => <TextField className={'fecha'} {...params} helperText={null} />}
                                                    />
                                                </LocalizationProvider>
                                            </Grid>
                                        </Grid>
                                    )
                                }

                                {/* <Grid container item xs={12} style={{ display: "flex", alignItems: "center" }}>
                                    <Grid item xs={4}>
                                        <Typography variant="subtitle1"><b>Unidad Medida:</b> </Typography>
                                    </Grid>
                                    <Grid item xs={8} style={{ display: 'flex', alignItems: "center" }}>
                                        <Select
                                            id="id_unidad_medida"
                                            value={values.id_unidad_medida}
                                            onChange={(event) => {
                                                setFieldValue("id_unidad_medida", event.target.value);
                                                setSelectUniMed(event.target.value);
                                            }}
                                            fullWidth
                                            disabled={!editMode}
                                        >
                                            {
                                                unidadesMedida.map((item, i) => {
                                                    return <MenuItem key={i} value={item.id}>{item.name}</MenuItem>;
                                                })
                                            }

                                        </Select>
                                    </Grid>
                                </Grid> */}

                                <Grid item xs={12} container style={{ display: "flex", alignItems: "center" }}>
                                    <Grid item xs={4}>
                                        <Typography variant="subtitle1" className="custom-input"><b>Cantidad Peso:</b></Typography>
                                    </Grid>
                                    <Grid item xs={8} style={{ display: 'flex', alignItems: "center" }}>
                                        {
                                            errors.cantidad_peso && (touched.cantidad_peso ? true : false) && (
                                                <Tooltip title={errors.cantidad_peso}>
                                                    <PriorityHighIcon className={'iconWarning'} />
                                                </Tooltip>
                                            )
                                        }
                                        <TextField
                                            id="cantidad_peso"
                                            type="number"
                                            name="cantidad_peso"
                                            fullWidth
                                            value={values.cantidad_peso}
                                            onChange={handleChange}
                                            placeholder="Cantidad peso"
                                            error={errors.cantidad_peso && touched.cantidad_peso ? true : false}
                                            disabled={!editMode}
                                        />
                                    </Grid>
                                </Grid>

                                <Grid item xs={12} container style={{ display: "flex", alignItems: "center" }}>
                                    <Grid item xs={4}>
                                        <Typography variant="subtitle1" className="custom-input"><b>Stock:</b></Typography>
                                    </Grid>
                                    <Grid item xs={8} style={{ display: 'flex', alignItems: "center" }}>
                                        {
                                            errors.stock_minimo && (touched.stock_minimo ? true : false) && (
                                                <Tooltip title={errors.stock_minimo}>
                                                    <PriorityHighIcon className={'iconWarning'} />
                                                </Tooltip>
                                            )
                                        }
                                        <TextField
                                            id="stock_minimo"
                                            type="number"
                                            name="stock_minimo"
                                            fullWidth
                                            value={values.stock_minimo}
                                            onChange={handleChange}
                                            placeholder="Stock minimo"
                                            error={errors.stock_minimo && touched.stock_minimo ? true : false}
                                            disabled={!editMode}
                                        />
                                    </Grid>
                                </Grid>

                                {
                                    // window.location.pathname === '/productos-insumo' && (
                                    //     <Grid item xs={12} container style={{ display: "flex", alignItems: "center" }}>
                                    //         <Grid item xs={5} className="custom-input">
                                    //             <Typography variant="subtitle1" className="custom-input"><b>Productos que lo contienen: </b></Typography>
                                    //         </Grid>
                                    //         <Grid item xs={7}>
                                    //             <Button variant='contained' color="primary" size="small" style={{ textTransform: "capitalize" }}
                                    //                 onClick={() => { setOpenPopup(true) }} sx={{ fontSize: "14px !important" }}
                                    //             >Seleccionar
                                    //             </Button>
                                    //             {/* <Checkbox
                                    //             id="is_supply"
                                    //             name="is_supply"
                                    //             checked={checked}
                                    //             onChange={(event) => {
                                    //                 setChecked(event.target.checked)
                                    //             }}
                                    //             disabled={!editMode}
                                    //         /> */}
                                    //         </Grid>
                                    //     </Grid>
                                    // )
                                }

                                {
                                    !editMode && (
                                        <Grid item xs={12} container style={{ display: "flex", alignItems: "center" }}>
                                            <Grid item xs={4}>
                                                <Typography variant="subtitle1" className="custom-input"><b>Stock:</b></Typography>
                                            </Grid>
                                            <Grid item xs={8}>
                                                <TextField
                                                    id="stock"
                                                    type="text"
                                                    name="stock"
                                                    fullWidth
                                                    value={values.stock || "sin stock"}
                                                    onChange={handleChange}
                                                    placeholder="stock"
                                                    disabled={!editMode}
                                                />
                                            </Grid>
                                        </Grid>
                                    )
                                }

                                <Grid container item xs={12} style={{ display: "flex", alignItems: "center" }}>
                                    <Grid item xs={4}>
                                        <Typography variant="subtitle1"><b>Familia:</b> </Typography>
                                    </Grid>
                                    <Grid item xs={8} style={{ display: 'flex', alignItems: "center", width: "230px" }}>
                                        <Select
                                            id="familia_id"
                                            value={values.familia_id}
                                            onChange={(event) => {
                                                setFieldValue("familia_id", event.target.value);
                                                handleChangeFamilia(event.target.value);
                                            }}
                                            /* error={ errors.rol && touched.rol ? true : false } */
                                            fullWidth
                                            disabled={!editMode}
                                        >
                                            {
                                                (familias || []).map((item, i) => {
                                                    return <MenuItem key={i} value={item.id}>{item.nombre}</MenuItem>;
                                                })
                                            }
                                        </Select>
                                    </Grid>
                                </Grid>

                                {familiasSelected?.subFamilias &&
                                    <Grid container item xs={12} style={{ display: "flex", alignItems: "center" }}>
                                        <Grid item xs={4}>
                                            <Typography variant="subtitle1"><b>Sub Familia:</b> </Typography>
                                        </Grid>
                                        <Grid item xs={8} style={{ display: 'flex', alignItems: "center", width: "230px" }}>
                                            <Select
                                                id="sub_familia_id"
                                                value={values.sub_familia_id}
                                                onChange={(event) => {
                                                    setFieldValue("sub_familia_id", event.target.value);
                                                }}
                                                fullWidth
                                                disabled={!editMode}
                                            >
                                                {
                                                    (familiasSelected?.subFamilias || []).map((item, i) => {
                                                        return <MenuItem key={i} value={item.id}>{item.nombre_subFamilia}</MenuItem>;
                                                    })
                                                }
                                            </Select>
                                        </Grid>
                                    </Grid>
                                }

                                <Grid container item xs={12} style={{ display: "flex", alignItems: "center" }}>
                                    <Grid item xs={4}>
                                        <Typography variant="subtitle1"><b>Linea de producto:</b> </Typography>
                                    </Grid>
                                    <Grid item xs={8} style={{ display: 'flex', alignItems: "center", width: "230px" }}>
                                        <Select
                                            id="linea_id"
                                            value={values.linea_id}
                                            onChange={(event) => {
                                                setFieldValue("linea_id", event.target.value);
                                            }}
                                            /* error={ errors.rol && touched.rol ? true : false } */
                                            fullWidth
                                            disabled={!editMode}
                                        >
                                            {
                                                (lineas || []).map((item, i) => {
                                                    return <MenuItem key={i} value={item.id}>{item.nombre}</MenuItem>;
                                                })
                                            }
                                        </Select>
                                    </Grid>
                                </Grid>

                                <Grid container item xs={12} style={{ display: "flex", alignItems: "center" }}>
                                    <Grid item xs={4}>
                                        <Typography variant="subtitle1"><b>Tipo de impuesto:</b> </Typography>
                                    </Grid>
                                    <Grid item xs={8} style={{ display: 'flex', alignItems: "center", width: "230px" }}>
                                        <Select
                                            id="idtipo_impuesto"
                                            value={values.idtipo_impuesto}
                                            onChange={(event) => {
                                                setFieldValue("idtipo_impuesto", event.target.value);
                                            }}
                                            /* error={ errors.rol && touched.rol ? true : false } */
                                            fullWidth
                                            disabled={!editMode}
                                        >
                                            {
                                                (tiposImpuesto || []).map((item, i) => {
                                                    return <MenuItem key={i} value={item.id}>{item.nombre}</MenuItem>;
                                                })
                                            }
                                        </Select>
                                    </Grid>
                                </Grid>

                            </Grid>

                            <Divider sx={{ my: 2 }} />

                            {ProductsSelect && ProductsSelect.length > 0 && (
                                <>
                                    <Grid item sm={12} container direction='row'>
                                        <Grid xs={12} sx={{ minHeight: '50vh', maxHeight: '45vh', overflowY: 'scroll', padding: '10px' }}>
                                            <div className="H-card__title" style={{ paddingLeft: '10px', paddingTop: '10px' }}>
                                                {`Listado de Productos seleccionados`}
                                            </div>
                                            <div className="H-card__separator"></div>
                                            <div className="H-card__list">
                                                {
                                                    ProductsSelect.map((product, index) => (
                                                        <div className="H-card__list__item">
                                                            <div className="content-info">
                                                                <div className="info">
                                                                    <span className="name">
                                                                        {product.codigo_erp}
                                                                    </span>
                                                                </div>
                                                                <div className="info">
                                                                    <span className="name">
                                                                        {product.nombre.charAt(0).toUpperCase() + product.nombre.slice(1).toLowerCase()}
                                                                    </span>
                                                                </div>
                                                                <div className="info">
                                                                    Cantidad:
                                                                    <span className="name" style={{ fontSize: "17px", marginLeft: "3px" }}>
                                                                        {product.cantidad} <span><b>{UM?.abbreviation}.</b></span>{/* <span><b>{product.id_unidad_medida?.abbreviation || product?.abbreviation}.</b></span> */}
                                                                    </span>
                                                                </div>
                                                                <div className="info">
                                                                    N° produc.:
                                                                    <span className="name" style={{ fontSize: "17px", marginLeft: "3px" }}>
                                                                        {product.productos_x_cantidad}
                                                                    </span>
                                                                </div>

                                                                {/* <div>
                                                            <span style={{marginRight: "2px"}}>
                                                                <b>cantidad</b>

                                                                <input  style={{width: "45px"}} type="number" name="cantidad" placeholder="cantidad"
                                                                value={product.cantidad}
                                                                onChange={(e)=>getProductCantidad(e,index)} required/>
                                                            </span>
                                                            <span style={{marginLeft: "2px"}}>
                                                                <b>N° produc</b>

                                                                <input  style={{width: "45px"}} type="number" name="productos_x_cantidad" placeholder="cantidad"
                                                                value={product.productos_x_cantidad}
                                                                onChange={(e)=>getCantidad_x_producto(e,index)} required/>
                                                            </span>
                                                        </div> */}
                                                            </div>
                                                            {/* <button className="dissmiss-more delete">x</button> */}
                                                        </div>
                                                    ))
                                                }
                                            </div>
                                        </Grid>
                                    </Grid>
                                    <Divider sx={{ my: 2 }} />
                                </>
                            )
                            }

                            {
                                editMode && (
                                    <div className="form-group mb-3 buton_container_modal">
                                        <button type="submit" className="btn btn-primary">
                                            { isSupplyOrProduct ? 'Agregar Producto o Insumo' : (is_supply ? 'Agregar Insumo' : 'Agregar Producto')}
                                        </button>
                                    </div>
                                )
                            }
                        </Form>
                    )
                }}
            </Formik>
            {
                openPopup === true && (
                    <Popup
                        largo="md"
                        title='Listado de Productos'
                        openPopup={openPopup}
                        setOpenPopup={setOpenPopup}
                    >
                        <ProductsNoInsumo
                            closeModal={() => { setOpenPopup(false) }}
                        />
                    </Popup>
                )
            }
        </div>
    );

}
