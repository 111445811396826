import React, { useRef} from "react";
import { Props } from "./TicketBalanza.type";
import { Divider, Grid, TextField, Typography } from "@mui/material";
import { Loading } from "@/components/common/Loading";
import { ComponentBalanzaTicket } from "@/components/common/ComponentBalanzaTicket";
import { useReactToPrint } from 'react-to-print';

export const TicketBalanza: React.FC<Props> = (props: Props): JSX.Element => {

    const{productSelect, closeModal} = props;
// eslint-disable-next-line
    const [loading, setLoading] = React.useState<boolean>(false);
    const [dataImprimirTicket, setDataImprimirTicket] = React.useState<boolean>(false);
    const [data, setData] = React.useState<any>({});
    const componentRef = useRef();

    React.useEffect(() => {
        if(productSelect) setData({
         ...productSelect,
         cantidad: 1,
         precio: Number(productSelect.precio_oferta ? productSelect.precio_oferta : productSelect.precio_standar),
        });// eslint-disable-next-line
    }, [])

    React.useEffect(() => {
        if(dataImprimirTicket){
            handlePrint();
        }// eslint-disable-next-line
    }, [dataImprimirTicket])

    const getChangeCantidad = (e) => {
        let card = data;
        setData({
         ...card,
         cantidad: Number(e.target.value),
         precio: (Number(e.target.value)*Number(data.precio_oferta ? data.precio_oferta : data.precio_standar)),
      });
    }

    const handlePrint = useReactToPrint({
        content: () => componentRef.current,

    });

    return (
        <>
        <div className="container">
            {loading && <Loading/>}
            <Grid container >
                <Grid item style={{width: "100%"}}>
                    <Grid container spacing={3} alignItems="center" justifyContent="center">

                        <Grid item xs={12} container style={{display: "flex", alignItems: "center"}}>
                            <Grid item xs={4}>
                                <Typography variant="subtitle1" className="custom-input"><b>Nombre Producto:</b></Typography>
                            </Grid>
                            <Grid item xs={8} style={{display: 'flex', alignItems: "center"}}>
                                <TextField
                                id="nombre"
                                type="text"
                                name="nombre"
                                fullWidth
                                value={data?.nombre}
                                placeholder="Nombre del Usuario"
                                disabled
                                size="small"
                                />
                            </Grid>
                        </Grid>

                        <Grid item xs={12} container style={{display: "flex", alignItems: "center"}}>
                            <Grid item xs={4}>
                                <Typography variant="subtitle1" className="custom-input"><b>Precio Producto:</b></Typography>
                            </Grid>
                            <Grid item xs={8} style={{display: 'flex', alignItems: "center"}}>
                                <TextField
                                id="nombre"
                                type="text"
                                name="nombre"
                                fullWidth
                                value={data.precio_oferta ? data.precio_oferta : data.precio_standar}
                                disabled
                                size="small"
                                />
                            </Grid>
                        </Grid>

                        <Grid item xs={12} container style={{display: "flex", alignItems: "center"}}>
                            <Grid item xs={4}>
                                <Typography variant="subtitle1" className="custom-input"><b>Cantidad en {data?.unidad_medida?.abbreviation}:</b></Typography>
                            </Grid>
                            <Grid item xs={8} style={{display: 'flex', alignItems: "center"}}>
                              <TextField
                                 type={"number"} name="cantidad" placeholder="cantidad"
                                 onChange={getChangeCantidad} required value={data.cantidad}
                              />
                            </Grid>
                        </Grid>
                        <Grid item xs={12} container style={{display: "flex", alignItems: "center"}}>
                            <Grid item xs={4}>
                                <Typography variant="subtitle1" className="custom-input"><b>Monto a pagar:</b></Typography>
                            </Grid>
                            <Grid item xs={8} style={{display: 'flex', alignItems: "center"}}>
                                <TextField
                                id="nombre"
                                type="text"
                                name="nombre"
                                fullWidth
                                value={data.precio}
                                disabled
                                size="small"
                                />
                            </Grid>
                        </Grid>

                    </Grid>
                    <Divider sx={{ mt: 2, mb: 2 }}/>

                    <div className="form-group mb-3 buton_container_modal">
                        <button disabled={data.precio && data.precio > 0 ? false : true} type="submit" className="btn btn-primary" onClick={()=>{
                            setDataImprimirTicket(true);}}>
                            Generar Ticket
                        </button>
                    </div>
                </Grid>
            </Grid>
        </div>
        { dataImprimirTicket &&
            <div style={{ display: "none" }}>
               <div ref={componentRef}>
                  <ComponentBalanzaTicket
                     data={data}
                     closeModal={closeModal}
                  />
               </div>
            </div>
        }
        </>
    )
}
