import { Props } from "./ReporteVentas.type";
import { Box, Button, createTheme, Grid, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, ThemeProvider } from "@mui/material";
import { esES } from "@mui/material/locale";
import React  from "react";
import { Popup } from "@/views/Editor/components/Popup";
import VisibilityIcon from '@mui/icons-material/Visibility';
import { DetailModal } from "./DetailModal";
import { moneyFormatInt } from "@/toolbox/helpers/money.helper";

const theme = createTheme(
    {
        palette: {
            primary: { main: '#1976D2' },
        },
    },
    esES,
);

export const ReporteVentas: React.FC<any> = (props: Props):JSX.Element => {

    const {ventas} = props;

    const [openPopup, setOpenPopup] = React.useState<boolean>(false);
    const [popUp, setPopUp] = React.useState<String>('');

    const [selectedVenta, setSelectedVenta] = React.useState<any>('');
    // eslint-disable-next-line
    const [showModalDelete, setShowModalDelete]= React.useState(false);

    const closeModalDelete=()=>{
        setShowModalDelete(false);
    }

    return (
        <Grid>
            <Box sx={{Width: '100%', marginTop:'0px'}}>
                <TableContainer component={Paper}>
                <ThemeProvider theme={theme}>
                <Table aria-label="simple table" size='small'>
                <TableHead>
                    <TableRow  sx={{ bgcolor:'#1976D2'}}>
                        <TableCell sx={{ color: 'white' }} >
                            <strong>N°</strong>
                        </TableCell>
                        <TableCell align='center' sx={{ color: 'white' }} >
                            <strong>Folio</strong>
                        </TableCell>
                        <TableCell align='center' sx={{ color: 'white' }} >
                            <strong>Cantidad de Producto</strong>
                        </TableCell>
                        <TableCell align='center' sx={{ color: 'white' }} >
                            <strong>Monto total</strong>
                        </TableCell>
                        <TableCell align='center' sx={{ color: 'white' }} >
                            <strong>Fecha</strong>
                        </TableCell>
                        <TableCell align='center' sx={{ color: 'white' }} >
                            <strong>Estado</strong>
                        </TableCell>
                        <TableCell align='center' sx={{ color: 'white' , minWidth:'8rem', maxWidth:'12rem'}}><strong>Acciones</strong></TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {
                        ventas && ventas.map((item, id) =>(
                            <TableRow key={id}>
                                <TableCell component="th" scope="row">
                                    {id+1}
                                </TableCell>
                                <TableCell align='center' component="th" scope="row">
                                    {item?.folio}
                                </TableCell>
                                <TableCell align='center' component="th" scope="row">
                                    {item?.cantidad_productos}
                                </TableCell>
                                <TableCell align='center' component="th" scope="row">
                                    {moneyFormatInt(item?.monto_total)}
                                </TableCell>
                                <TableCell align='center' component="th" scope="row">
                                    {item?.fecha}
                                </TableCell>
                                <TableCell align='center' component="th" scope="row">
                                    {item?.state != null ? item?.state : '---'}
                                </TableCell>
                                <TableCell align='center'>
                                    <Button
                                        onClick={() =>  {
                                            setOpenPopup(true);
                                            setPopUp('DetailVenta');
                                            setSelectedVenta(item);
                                        }}
                                    >
                                        <VisibilityIcon/>
                                    </Button>
                                </TableCell>
                            </TableRow>
                        ))
                    }
                </TableBody>
                </Table>
                </ThemeProvider>
                </TableContainer>
                {
                popUp === 'DetailVenta' && (
                    <Popup
                        largo="md"
                        title = 'Detalle de la Venta'
                        openPopup = {openPopup}
                        setOpenPopup = {setOpenPopup}
                    >
                        <DetailModal
                            popUp={popUp}
                            closeModal={closeModalDelete}
                            venta={selectedVenta}
                        />

                    </Popup>
                )
            }
            </Box>
        </Grid>
    );
}
