import { API_URL_NUPY } from '@/toolbox/defaults/app';
import axios from 'axios';

export const distribucionRepository = {

    register: async(cantidad: number, productos_x_cantidad: number, id_unidad_medida: number, id_insumo: number, id_producto: number): Promise<any> => {
        const res = await axios.post(`${API_URL_NUPY}/distribucion/register`,{
            cantidad,
            productos_x_cantidad,
            id_unidad_medida,
            id_insumo,
            id_producto
        });
        return res.data;
    },

    update: async(id:number, cantidad: number): Promise<any> => {
        const res = await axios.post(`${API_URL_NUPY}/distribucion/update/${id}`, {
            cantidad,
        });
        return res.data;
    },

    getDistribucionByInsumo: async(idInsumo: number): Promise<any> => {
        const res = await axios.get(`${API_URL_NUPY}/distribucion/list-byImsumo/${idInsumo}`);
        return res.data;
    },

    deleteByIdProduct: async(idProducto:number): Promise<any> => {
        const res= await axios.delete(`${API_URL_NUPY}/distribucion/delete_byIdProducto/${idProducto}`)
        return res;
    },

    deleteByIdInsumo: async(idInsumo:number): Promise<any> => {
        const res= await axios.delete(`${API_URL_NUPY}/distribucion/delete_byIdInsumo/${idInsumo}`)
        return res;
    },

    ListUnidadMedida: async(): Promise<any> => {
        const res = await axios.get(`${API_URL_NUPY}/distribucion/list-unidad-medida`);
        return res.data;
    },
    
}