import { Props } from "./Empresas.type";
import React, { useEffect } from "react";
import { Loading } from "../../components/common/Loading/Loading";
import { Protected } from "@/components/layout/Protected";
import { Box, Button, Container, createTheme, Divider, FormControlLabel, Grid, Paper, Switch, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, ThemeProvider, Typography } from "@mui/material";
import { esES } from "@mui/material/locale";
import { PencilIcon } from "@/toolbox/constants/icons";
import { Popup } from "../Editor/components/Popup";
import ContentPasteOffIcon from '@mui/icons-material/ContentPasteOff';
import { empresasService } from "@/service/services/Empresas.service";
import { AddEmpresa } from "./componentes/AddEmpresa";

const theme = createTheme(
    {
        palette: {
            primary: { main: '#1976D2' },
        },
    },
    esES,
);

export const Empresas: React.FC<Props> = (props: Props): JSX.Element => {

    const [loading, setLoading] = React.useState<boolean>(false);
    const [empresas, setEmpresas] = React.useState([]);

    const [selectedEmpresa, setSelectedEmpresa] = React.useState('');

    const [openPopup, setOpenPopup] = React.useState<boolean>(false);
    const [popUp, setPopUp] = React.useState<String>('');

    useEffect(() => {
        getEmpresas();
    }, [])

    const getEmpresas = async() => {
        setLoading(true);
        await empresasService.getEmpresas().then((data) => {
            setEmpresas(data);
            setLoading(false);
        })
    }

    const funcionSwitch = async(empresa) =>{
        setLoading(true);
        if(empresa.state === 1){
            await empresasService.editState(empresa.id, 0);
        }else{
            await empresasService.editState(empresa.id, 1);
        }
        getEmpresas();
    }

    return (
        <Protected>
            {loading && <Loading/>}
            <Container maxWidth='xl' sx={{ mt: 1, mb: 2 }}>
                <Grid container justifyContent='space-between' spacing={2}>
                        <Grid item>
                            <Typography variant='h4' >Gestión de Empresas</Typography>
                        </Grid>
                        <Grid item>
                            <Button variant='outlined'
                            onClick={()=>{
                                setOpenPopup(true);
                                setPopUp('addEmpresa');
                            }}
                            >Crear Empresa</Button>
                        </Grid>
                </Grid>

                <Divider sx={{ mt: 2 }} />

                <Grid container spacing={1} >
                    <Grid item xs={12}>
                        <Typography variant="body2" sx={{ mt: '20px' }} >
                            {
                                (empresas.length)  ? `${empresas.length} registros encontrados`
                                    : `0` && !loading
                            }
                        </Typography>
                    </Grid>
                </Grid>

                <Grid>
                    {
                        empresas.length > 0 && (
                            <Box sx={{Width: '100%', marginTop:'30px'}}>
                                <TableContainer component={Paper}>
                                    <ThemeProvider theme={theme}>
                                        <Table aria-label="simple table" size='small'>
                                            <TableHead>
                                                <TableRow  sx={{ bgcolor:'#1976D2'}}>
                                                    <TableCell sx={{ color: 'white' }} >
                                                        <strong>N°</strong>
                                                    </TableCell>
                                                    <TableCell align='center' sx={{ color: 'white' }} >
                                                        <strong>Nombre</strong>
                                                    </TableCell>
                                                    <TableCell align='center' sx={{ color: 'white' }} >
                                                        <strong>RUT</strong>
                                                    </TableCell>
                                                    {/* <TableCell align='center' sx={{ color: 'white' }} >
                                                        <strong>Formas de pago</strong>
                                                    </TableCell> */}
                                                    <TableCell align='center' sx={{ color: 'white' }} >
                                                        <strong>Estado</strong>
                                                    </TableCell>
                                                    <TableCell align='center' sx={{ color: 'white' }} >
                                                        <strong>Tipo </strong>
                                                    </TableCell>
                                                    <TableCell align='center' sx={{ color: 'white' }} >
                                                        <strong>Moneda </strong>
                                                    </TableCell>
                                                    <TableCell align='center' sx={{ color: 'white' , minWidth:'8rem', maxWidth:'12rem'}}><strong>Acciones</strong></TableCell>
                                                </TableRow>
                                            </TableHead>

                                            <TableBody>
                                                {
                                                    empresas.map((empresa, id) =>(
                                                        <TableRow key={id}>
                                                            <TableCell component="th" scope="row">
                                                                {id+1}
                                                            </TableCell>
                                                            <TableCell component="th" scope="row">
                                                                {empresa.name.toLowerCase()}
                                                            </TableCell>
                                                            <TableCell align='center' component="th" scope="row">
                                                                {empresa.rut}
                                                            </TableCell>
                                                            {/* <TableCell align='center' component="th" scope="row">
                                                                {empresa.formas_de_pago}
                                                            </TableCell> */}

                                                            <TableCell align='center' component="th" scope="row">
                                                                <Box>
                                                                <FormControlLabel
                                                                    control={<Switch onClick={() => {
                                                                        funcionSwitch(empresa);
                                                                    }}
                                                                />}
                                                                checked={empresa.state === 1 ? true : false}
                                                                label=""
                                                                />
                                                                </Box>
                                                            </TableCell>
                                                            <TableCell align='center' component="th" scope="row">
                                                                {empresa.tipo_empresa.tipo}
                                                            </TableCell>
                                                            <TableCell align='center' component="th" scope="row">
                                                                {empresa?.country?.abbreviation} - {empresa?.country?.nombre}
                                                            </TableCell>
                                                            <TableCell align='center'>
                                                                <Button
                                                                    onClick={() =>  {
                                                                        setOpenPopup(true);
                                                                        setPopUp('editEmpresa');
                                                                        setSelectedEmpresa(empresa);
                                                                    }}
                                                                >
                                                                    <PencilIcon/>
                                                                </Button>
                                                            </TableCell>
                                                        </TableRow>
                                                    ))
                                                }
                                            </TableBody>
                                        </Table>
                                    </ThemeProvider>
                                </TableContainer>
                            </Box>
                        )
                    }
                    {
                        !loading && empresas.length === 0 &&
                            <Grid container justifyContent="center">
                                <Box sx={{marginTop: '50px'}}>
                                <Grid container justifyContent="center"> <ContentPasteOffIcon className="iconocarrito"/></Grid>
                                    <Box sx={{Width: '100%', marginBottom:'30px', textAlign:'center'}}>No hay Empresas registradas</Box>
                                </Box>
                            </Grid>

                    }
                    {loading && empresas.length === 0 &&
                     <Box sx={{backgroundColor: 'rgba(224, 224, 224, 0.5)', padding: '15px', borderRadius: '10px', height:'100px'}}>
                     </Box>
                    }
                </Grid>
            </Container>
            {
                popUp === 'addEmpresa' && (
                    <Popup
                        largo="sm"
                        title = 'Agregar Empresa'
                        openPopup = {openPopup}
                        setOpenPopup = {setOpenPopup}
                    >
                        <AddEmpresa
                            closeModal={() => {setOpenPopup(false)}}
                            onListEmpresas = {getEmpresas}
                        />

                    </Popup>
                )
            }
            {
                popUp === 'editEmpresa' && (
                    <Popup
                        largo="sm"
                        title = 'Editar Empresa'
                        openPopup = {openPopup}
                        setOpenPopup = {setOpenPopup}
                    >
                        <AddEmpresa
                            closeModal={() => {setOpenPopup(false)}}
                            company = {selectedEmpresa}
                            onListEmpresas = {getEmpresas}
                        />

                    </Popup>
                )
            }
        </Protected>
    );
}
