import React from "react";
import { Button, CardActions, CardContent, Container, Divider,  Grid, Typography } from "@mui/material";
import { Loading } from "@/components/common/Loading";
import { Props } from "./MyPurchases.type";
import '../../components/UsersClients.sass';
import { ticketService } from "@/service/services/Ticket.service";
import { PopupComponent } from "@/components/common/Popup";
import { ModalOrders } from "../MyOrders/components/ModalOrders";
import { HeaderEcommerce } from "../Header";
import { clientesService } from "@/service/services/Clientes.service";
import { readLocalStorage } from "@/toolbox/helpers/local-storage-helper";
import { KEY_USER_DATA } from "@/toolbox/constants/local-storage";
import moment from "moment";


export const MyPurchases: React.FC<Props> = (props:Props):JSX.Element => {

    const [loading, setLoading] = React.useState<boolean>(false);
    const [pedidos,setPedidos] = React.useState<Array<any>>([]);
    const [openPopup, setOpenPopup] = React.useState(false);
    const [pedidoSelected, setPedidoSelect] = React.useState<number>(0);

    const dataUsuario = readLocalStorage(KEY_USER_DATA);
    const rol = dataUsuario?.user?.rol;

    React.useEffect(() => {
        if(rol === 'Cliente'){
            getVentasRestaurant();
        }else if(rol === 'UserEcommerce'){
            getShoppingHistory();
        }
    },[]);

    const getVentasRestaurant = async() => {
        setLoading(true);
        await ticketService.getPedidoFinalizadoByClient().then((data)=>{
            if(data && data.length > 0){
                setPedidos(data || []);
            }
        });
        setLoading(false);
    }

    const getShoppingHistory = async() => {
        setLoading(true);
        await clientesService.shoppingHistory().then((data)=>{
            if(data && data.length > 0){
                setPedidos(data || []);
            }
        });
        setLoading(false);
    }

    return (
        <Container>
            {loading && <Loading/>}
            <Grid container sx={{ marginBottom: '25px', alignContent: 'center', marginTop: '20px' }}>
                <HeaderEcommerce/>
            <Container maxWidth='xl' sx={{ mt: 1, mb: 2 }}>

                <Grid container  spacing={2}>
                    <Grid item xs={12} sm={8} md={8}>
                        <Typography variant='h4' >Mis Compras</Typography>
                    </Grid>
                </Grid>
                {
                    pedidos.length > 0 && pedidos.map((pedido, i) => (
                        <Grid className='purchases-content' >
                            <span className='text-date'>
                                {moment(pedido.created_at).format('MMMM Do YYYY')}
                            </span>
                            <Divider sx={{ mt: 2 }} />
                            <Grid sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', marginTop:'5px'}} container>

                                <Grid sx={{ display: 'flex', alignItems: 'center'}}>
                                    <CardContent sx={{maxWidth: "600px", marginLeft:'5px'}}>
                                        <Typography gutterBottom variant="h6">
                                            {pedido.ticket?.n_ticket}
                                        </Typography>
                                        <Typography className='text-estado'>
                                            {pedido.estado}
                                        </Typography>
                                        {
                                            rol && rol === 'Cliente' ? (
                                                <Typography className='text'>
                                                    Recibiste la compra
                                                </Typography>
                                            ) : (
                                                <Typography className='text'>
                                                    comprado en: {pedido.cliente?.empresa?.name}
                                                </Typography>
                                            )
                                        }
                                    </CardContent>
                                </Grid>

                                <CardActions>
                                    <Button variant='contained' color="primary" size="small"
                                    style={{textTransform: "capitalize"}}
                                    onClick={()=>{
                                        setOpenPopup(true);
                                        setPedidoSelect(pedido.id);
                                    }}
                                    >Ver compra</Button>
                                </CardActions>

                            </Grid>
                        </Grid>
                    ))
                }

            </Container>
            </Grid>
            { openPopup &&
               <PopupComponent
               largo="md"
               title = {'Detalle de la compra'}
               openPopup = {openPopup}
               setOpenPopup = {setOpenPopup}
               >
               <ModalOrders idPedido={pedidoSelected} setOpenPopup={setOpenPopup} />
            </PopupComponent>
            }
        </Container>
    );
}
