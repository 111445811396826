import { Protected } from "@/components/layout/Protected";
import { DatePicker, LocalizationProvider } from "@mui/lab";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import { Button, Grid, IconButton, Paper, Tab, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Tooltip, Typography } from "@mui/material";
import { FC, useEffect, useRef, useState } from "react";
import esLocale from 'date-fns/locale/es';
import { Loading } from "@/components/common/Loading";
import VisibilityIcon from '@mui/icons-material/Visibility';
import { styled } from '@mui/material/styles';
import { readLocalStorage } from "@/toolbox/helpers/local-storage-helper";
import { KEY_USER_DATA } from "@/toolbox/constants/local-storage";
import moment from "moment";
import { ventaService } from "@/service/services/Venta.service";

export const VentasDiarias: FC = () => {

    const [fecha, setFecha] = useState(new Date())
    const [ventas, setVentas] = useState<any[]>([])
    const [loading, setLoading] = useState<boolean>(false)

    const dataUsuario = readLocalStorage(KEY_USER_DATA);

    const nombreEmpresa = dataUsuario.user?.nombre_empresa;
    const rutEmpresa = dataUsuario.user?.rut_empresa

    useEffect(() => {
        const fechalocal = new Date(fecha)
        const año = fechalocal.getFullYear();
        const mes = String(fechalocal.getMonth() + 1).padStart(2, '0')
        const dia = String(fechalocal.getDate()).padStart(2, '0')

        const fechaFormateada = `${año}-${mes}-${dia}`
        filtrarByDay(fechaFormateada);
    }, [fecha])


    const filtrarByDay = async(date) =>{
        setLoading(true);
        const fecha = moment(date).format('DD-MM-YYYY');
        await ventaService.getVentasByDay(fecha).then((data) => {
           setVentas(data);
        })
        setLoading(false);
     }

     const viewPdfSoftnet = (item) => {
        const pdfUrl = item?.pdf_softnet;
        
        if (pdfUrl) {
            console.log(pdfUrl);
            window.open(pdfUrl, '_blank');
        } else {
            console.error('No se encontró una URL válida para el PDF.');
        }
    };


    return (<Protected>
        {loading && <Loading />}
        <Grid container spacing={2}>
            <Grid item container xs={12}>
                <Grid item xs={4}>
                    <Typography variant="h4">{"Fecha de venta: "}</Typography>

                </Grid>
                <Grid item xs={6}>
                    <LocalizationProvider dateAdapter={AdapterDateFns} locale={esLocale} >
                        <DatePicker
                            PopperProps={{ placement: "auto-start" }}
                            views={['day']}
                            value={fecha}
                            minDate={new Date('2020-01-01')}
                            maxDate={new Date('2030-01-01')}
                            onChange={(newValue) => {
                                setFecha(newValue);
                            }}
                            renderInput={(params) => <TextField {...params} />}
                        />
                    </LocalizationProvider>
                </Grid>
            </Grid>
            <Grid item container xs={12}>
                <TableContainer component={Paper}>
                    <Table sx={{ minWidth: 650 }} aria-label="simple table">
                        <TableHead>
                            <TableRow>
                                <TableCell>{`N°`}</TableCell>
                                <TableCell align="left">Folio</TableCell>
                                {/* <TableCell align="left">Fecha</TableCell> */}
                                <TableCell align="left">Tipo</TableCell>
                                <TableCell align="left">Monto neto</TableCell>
                                <TableCell align="left">Monto total</TableCell>
                                <TableCell align="left">Monto exento</TableCell>
                                <TableCell align="left">Total pagado</TableCell>
                                <TableCell align="center">Detalle</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {ventas?.map((venta, index) =>
                                <TableRow>
                                    <TableCell align="left">{index + 1}</TableCell>
                                    <TableCell align="left">{venta.folio}</TableCell>
                                    {/* <TableCell align="left">{venta.fecha}</TableCell> */}
                                    <TableCell align="left">{venta.pedido.tipo}</TableCell>
                                    <TableCell align="left">{venta.monto_neto}</TableCell>
                                    <TableCell align="left">{venta.monto_total}</TableCell>
                                    <TableCell align="left">{venta.exento}</TableCell>
                                    <TableCell align="left">{venta.monto_pagado}</TableCell>
                                    <TableCell align="center">
                                        <Tooltip title={"Ver detalle"}>
                                            <IconButton onClick={() => viewPdfSoftnet(venta)}>
                                                <VisibilityIcon fontSize="small"/>
                                            </IconButton>
                                        </Tooltip>
                                    </TableCell>
                                </TableRow>
                            )}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Grid>
        </Grid>
    </Protected>)
}