import { useEffect, useState } from "react";
import { Props } from "./ReserveTable.type"
import { Card, CardContent, Button, TextField, Grid, Typography, Tooltip, Select, MenuItem, CardMedia } from "@mui/material";
import { HeaderEcommerce } from "../Header/HeaderEcommerce";
import { salas } from "@/service/services/Salas.service";
import { Loading } from "@/components/common/Loading";
import { useLocation } from "react-router-dom";
import { Popup } from "@/views/Editor/components/Popup";
import { ModalReservas } from "@/views/Reservas/components/ModalReservas";
import isWeekend from 'date-fns/isWeekend';
import { LocalizationProvider, StaticDatePicker } from "@mui/lab";
import AdapterDateFns from "@mui/lab/AdapterDateFns";

import Slider from "react-slick";
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";
import './ReserveTable.css';


export const ReserveTable: React.FC<Props> = (props:Props):JSX.Element => {

    const {search} = useLocation();
    const query = new URLSearchParams(search)
    const idCompany: any = query.get("company");
    const idSucursal: any = query.get("sucursal");

    const [loading, setLoading] = useState(false);
    const [rooms, setRooms] = useState([]);
    const [tables, setTables] = useState([]);
    const [selectedRoom, setSelectedRoom] = useState<any>('');
    const [idRoom, setIdRoom] = useState<any>('');
    const [selectedTable, setSelectedTable] = useState<any>('');
    const [modalReserve, setModalReserve] = useState<boolean>(false);

    console.log(selectedTable);

    const [date, setDate] = useState<any>(new Date());
    console.log(date);
    
    // console.log(idCompany);
    // console.log(idSucursal);

    useEffect(() => {
        if(idCompany && idSucursal) cargarSalas(idCompany, idSucursal);
    }, []);

    const cargarSalas = async (idCompany, idSucursal)=>{
        setLoading(true);
        await salas.getSalasByIdCompany(idCompany, idSucursal).then((data)=>{
            setRooms(data || []);
            if(data){
                setSelectedRoom(data[0].nombre);
                cargarMesas(data[0].id);
                setIdRoom(data[0].id);
            }
            setLoading(false);
        });
    }

    const cargarMesas = async (id_sala)=>{
        setLoading(true);
        await salas.cargarMesas(id_sala).then((data)=>{
            setTables(data.mesas || [])
            setLoading(false)
        });

    }

    var settings = {
        dots: true,
        infinite: false,
        speed: 500,
        arrows: true,
        slidesToShow: 3.6,
        slidesToScroll: 3.6,
        initialSlide: 0,
        responsive: [
            {
            breakpoint: 1024,
            settings: {
                slidesToShow: 3,
                slidesToScroll: 3,
                infinite: true,
                dots: true
            }
            },
            {
            breakpoint: 600,
            settings: {
                slidesToShow: 2,
                slidesToScroll: 2,
                initialSlide: 2
            }
            },
            {
            breakpoint: 480,
            settings: {
                slidesToShow: 1,
                slidesToScroll: 1
            }
            }
        ]
    };

    return (
        <Grid>
            {loading && <Loading/>}
            <Grid container sx={{ marginBottom: '25px', alignContent: 'center' }}>
                <HeaderEcommerce/>
            </Grid>

            <Grid item xs={12} textAlign='center' mb={2}>
                <Typography variant="h2" gutterBottom mb={3} className="reserve-textTitle">
                    Haz tu reserva en DEMO ERP SOFTNET - RESTAURANT TRADICIONAL
                </Typography>
            </Grid>

            <Grid sx={{ marginBottom:'15px', display: 'flex', alignItems: 'center', paddingLeft: '35px'}}>
                <Grid style={{marginTop:'-30px !important'}}>
                    <span>{"Filtrar mesas por sala: "}</span>
                </Grid>

                <Grid item xs={2} spacing={3} sx={{marginLeft: '5px'}}>
                    <Select
                        id="filtro"
                        size='small'
                        value={selectedRoom}
                        onChange={(event) => {
                            setSelectedRoom(event.target.value);
                        }}
                        sx={{width:'250px'}}
                        >

                        <MenuItem value={''} disabled><em>seleccionar filtro</em></MenuItem>

                        {
                            rooms.length > 0 && rooms.map((room, key) => (
                                <MenuItem key={key} value={room.nombre} onClick={() => {
                                    setIdRoom(room.id);
                                    cargarMesas(room.id);
                                }}
                                    >{room.nombre}</MenuItem>
                            ))
                        }
                    </Select>
                </Grid>
            </Grid>
            
            <Grid container spacing={2} style={{paddingLeft: '35px', paddingRight: '35px', display: 'flex', justifyContent: 'center', marginTop: '20px'}}>

                <Grid container item xs={12} md={12} lg={4} className='reserve-content-rooms' >
                    {
                        selectedRoom && selectedRoom === 'comida oriental' ? (
                            <Grid >
                                <CardMedia
                                    component="img"
                                    // image={company.imagen}
                                    image= {"https://cdn.theforkmanager.com/static/body-images/el-tenedor-software-restaurante-plano-de-sala-digital-8-4.jpg"}
                                    alt="logo"
                                    sx={{ height: '300px', margin: 'auto', background: "#82A8F3", boxSizing: "border-box"}}
                                />
                            </Grid>
                        ) : (
                            <Grid >
                                <CardMedia
                                    component="img"
                                    // image={company.imagen}
                                    image= {"https://m2design.com.pa/wp-content/uploads/2019/04/sistema-de-restaurantes-731x511.jpg"}
                                    alt="logo"
                                    sx={{ height: '300px', margin: 'auto', background: "#82A8F3", boxSizing: "border-box"}}
                                />
                            </Grid>
                        )
                    }
                </Grid>

                <Grid container item xs={12} md={12} lg={7} className='reserve-content-tables'>
                    {
                        tables.length > 0 && tables.map((table, key) => (
                            <Card key={key} className={selectedTable.id === table.id ? 'reserve-table-desahabilitado' : 'reserve-table-habilitado'}>
                                <Button aria-label="print" disabled={table.reservated_at == null ? false : true}
                                    onClick={() =>  {
                                        setModalReserve(true);
                                        setSelectedTable(table);
                                    }}
                                >
                                    <Typography className="reserve-text">
                                        {table.nombre.charAt(0).toUpperCase() + table.nombre.slice(1).toLowerCase()}
                                    </Typography>
                                </Button>
                            </Card>
                        ))
                    }
                </Grid>

            </Grid>

            <Grid container style={{paddingLeft: '35px', paddingRight: '35px', marginTop: '20px'}}>
                <Grid container item xs={12} md={12} lg={5} className='reserve-content-date' >
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <StaticDatePicker
                            orientation="landscape"
                            openTo="day"
                            value={date}
                            shouldDisableDate={isWeekend}
                            onChange={(newValue) => {
                                setDate(newValue);
                            }}
                            renderInput={(params) => <TextField {...params} />}
                        />
                    </LocalizationProvider>
                </Grid>
                <Grid container item xs={12} md={12} lg={7} className='reserve-content-date'>
                    <Grid xs={12} md={12} lg={12} style={{paddingLeft:'10px', marginBottom: '20px'}}>
                        <span className='title-hour'>ELIGE UNA HORA DISPONIBLE</span>
                    </Grid>

                    <Grid className='hour'>
                        <Button>{`12:00 PM`}</Button>
                    </Grid>
                    <Grid className='hour'>
                        <Button>{`12:00 PM`}</Button>
                    </Grid>
                    <Grid className='hour'>
                        <Button>{`12:00 PM`}</Button>
                    </Grid>
                    <Grid className='hour'>
                        <Button>{`12:00 PM`}</Button>
                    </Grid>
                    <Grid className='hour'>
                        <Button>{`12:00 PM`}</Button>
                    </Grid>
                    <Grid className='hour'>
                        <Button>{`12:00 PM`}</Button>
                    </Grid>
                    <Grid className='hour'>
                        <Button>{`12:00 PM`}</Button>
                    </Grid>
                    <Grid className='hour'>
                        <Button>{`12:00 PM`}</Button>
                    </Grid>
                    <Grid className='hour'>
                        <Button>{`12:00 PM`}</Button>
                    </Grid>
                    <Grid className='hour'>
                        <Button>{`12:00 PM`}</Button>
                    </Grid>
                    <Grid className='hour'>
                        <Button>{`12:00 PM`}</Button>
                    </Grid>
                    <Grid className='hour'>
                        <Button>{`12:00 PM`}</Button>
                    </Grid>
                    <Grid className='hour'>
                        <Button>{`12:00 PM`}</Button>
                    </Grid>
                    <Grid className='hour'>
                        <Button>{`12:00 PM`}</Button>
                    </Grid>
                    <Grid className='hour'>
                        <Button>{`12:00 PM`}</Button>
                    </Grid>
                    <Grid className='hour'>
                        <Button>{`12:00 PM`}</Button>
                    </Grid>
                    <Grid className='hour'>
                        <Button>{`12:00 PM`}</Button>
                    </Grid>
                    <Grid className='hour'>
                        <Button>{`12:00 PM`}</Button>
                    </Grid>
                    <Grid className='hour'>
                        <Button>{`12:00 PM`}</Button>
                    </Grid>
                </Grid>
            </Grid>

            <Grid item xs={4} textAlign='center' mb={2} style={{marginTop: '20px'}} >
                <Button variant="contained" color="success" className='btn-reserve'>RESERVAR MESA</Button>
            </Grid>

            <Grid item xs={12} textAlign='center' mb={2} style={{marginTop: '35px'}}>
                <Typography variant="h2" gutterBottom mb={3} className="reserve-textTitle">
                    Otros restaurantes que te podrían gustar
                </Typography>
            </Grid>

            <Grid style={{marginBottom: '30px', width: '100%', paddingLeft: '40px', paddingRight: '40px'}}>
                <Slider {...settings}>
                    <div>
                        <Card className={"card-container"} sx={{ borderRadius: '25px' }}>
                            <CardMedia
                                component="img"
                                // image={company.imagen}
                                image= {"https://images.rappi.cl/restaurants_background/crudosin-1662003183359.jpg?e=webp&q=80&d=400x400"}
                                alt="logo"
                                sx={{ height: '150px', margin: 'auto', background: "#82A8F3", boxSizing: "border-box"}}
                            />
                            <CardContent sx={{ height: '100px', padding: '20px', boxSizing: "border-box" }} >
                                <Typography sx={{ height: '100%' }}  className="card-container-name" textAlign={'center'}>
                                    {'Amoramar'}
                                </Typography>
                            </CardContent>
                        </Card>
                    </div>
                    <div>
                        <Card className={"card-container"} sx={{ borderRadius: '25px' }}>
                            <CardMedia
                                component="img"
                                // image={company.imagen}
                                image= {"https://images.rappi.cl/restaurants_background/crudosin-1662003183359.jpg?e=webp&q=80&d=400x400"}
                                alt="logo"
                                sx={{ height: '150px', margin: 'auto', background: "#82A8F3", boxSizing: "border-box"}}
                            />
                            <CardContent sx={{ height: '100px', padding: '20px', boxSizing: "border-box" }} >
                                <Typography sx={{ height: '100%' }}  className="card-container-name" textAlign={'center'}>
                                    {'La Cabrera - Surco'}
                                </Typography>
                            </CardContent>
                        </Card>
                    </div>
                    <div>
                        <Card className={"card-container"} sx={{ borderRadius: '25px' }}>
                            <CardMedia
                                component="img"
                                // image={company.imagen}
                                image= {"https://images.rappi.cl/restaurants_background/crudosin-1662003183359.jpg?e=webp&q=80&d=400x400"}
                                alt="logo"
                                sx={{ height: '150px', margin: 'auto', background: "#82A8F3", boxSizing: "border-box"}}
                            />
                            <CardContent sx={{ height: '100px', padding: '20px', boxSizing: "border-box" }} >
                                <Typography sx={{ height: '100%' }}  className="card-container-name" textAlign={'center'}>
                                    {'La Huaca Pucllana'}
                                </Typography>
                            </CardContent>
                        </Card>
                    </div>
                    <div>
                        <Card className={"card-container"} sx={{ borderRadius: '25px' }}>
                            <CardMedia
                                component="img"
                                // image={company.imagen}
                                image= {"https://images.rappi.cl/restaurants_background/crudosin-1662003183359.jpg?e=webp&q=80&d=400x400"}
                                alt="logo"
                                sx={{ height: '150px', margin: 'auto', background: "#82A8F3", boxSizing: "border-box"}}
                            />
                            <CardContent sx={{ height: '100px', padding: '20px', boxSizing: "border-box" }} >
                                <Typography sx={{ height: '100%' }}  className="card-container-name" textAlign={'center'}>
                                    {'Don Nico - Miraflores'}
                                </Typography>
                            </CardContent>
                        </Card>
                    </div>
                    <div>
                        <Card className={"card-container"} sx={{ borderRadius: '25px' }}>
                            <CardMedia
                                component="img"
                                // image={company.imagen}
                                image= {"https://images.rappi.cl/restaurants_background/crudosin-1662003183359.jpg?e=webp&q=80&d=400x400"}
                                alt="logo"
                                sx={{ height: '150px', margin: 'auto', background: "#82A8F3", boxSizing: "border-box"}}
                            />
                            <CardContent sx={{ height: '100px', padding: '20px', boxSizing: "border-box" }} >
                                <Typography sx={{ height: '100%' }}  className="card-container-name" textAlign={'center'}>
                                    {'Ortega & Huamán'}
                                </Typography>
                            </CardContent>
                        </Card>
                    </div>
                </Slider>
            </Grid>

            {/* {modalReserve &&
                <Popup
                largo="sm"
                title = 'Registro de Reserva'
                openPopup = {modalReserve}
                setOpenPopup = {setModalReserve}
                >
                <ModalReservas
                    data={selectedTable}
                    onlistTable={() => {cargarMesas(idRoom)}}
                    close={()=>{setModalReserve(false)}}
                />
                </Popup>
            } */}
        </Grid>
    )
}