import { Props } from "./BeneficioProducto.type";
import { Box, Button, createTheme, Grid, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, ThemeProvider } from "@mui/material";
import { esES } from "@mui/material/locale";
import { PencilIcon } from "@/toolbox/constants/icons";
import DeleteIcon from '@mui/icons-material/Delete';
import React from "react";
import { Popup } from "@/views/Editor/components/Popup";
import { DeleteModalProducto } from "../ModalDeleteProducto";
import { AddModal } from "../ModalAdd";

const theme = createTheme(
    {
        palette: {
            primary: { main: '#1976D2' },
        },
    },
    esES,
);

export const BeneficioProducto: React.FC<Props> = (props:Props):JSX.Element => {

    const {beneficiosProducto, onListBeneficioProducto} = props;

    const [openPopup, setOpenPopup] = React.useState<boolean>(false);
    const [popUp, setPopUp] = React.useState<String>('');

    const [selectedBeneficioProducto, setSelectedBeneficioProducto] = React.useState<any>('');
    const [showModalDelete, setShowModalDelete]= React.useState(false);

    const openModalDelete=()=>{
        setShowModalDelete(true);
    }

    const closeModalDelete=()=>{
        setShowModalDelete(false);
    }

    return (
        <Grid>
            <Box sx={{Width: '100%', marginTop:'0px'}}>
                <TableContainer component={Paper}>
                    <ThemeProvider theme={theme}>
                        <Table aria-label="simple table" size='small'>
                            <TableHead>
                                <TableRow  sx={{ bgcolor:'#1976D2'}}>
                                    <TableCell sx={{ color: 'white' }} >
                                        <strong>N°</strong>
                                    </TableCell>
                                    <TableCell align='center' sx={{ color: 'white' }} >
                                        <strong>Código Producto</strong>
                                    </TableCell>
                                    <TableCell align='center' sx={{ color: 'white' }} >
                                        <strong>Nombre Producto</strong>
                                    </TableCell>
                                    <TableCell align='center' sx={{ color: 'white' }} >
                                        <strong>Cantidad</strong>
                                    </TableCell>
                                    <TableCell align='center' sx={{ color: 'white' }} >
                                        <strong>Puntos</strong>
                                    </TableCell>
                                    <TableCell align='center' sx={{ color: 'white' , minWidth:'8rem', maxWidth:'12rem'}}><strong>Acciones</strong></TableCell>
                                </TableRow>
                            </TableHead>

                            <TableBody>
                                {
                                    beneficiosProducto && beneficiosProducto.map((item, id) =>(
                                        <TableRow key={id}>
                                            <TableCell component="th" scope="row">
                                                {id+1}
                                            </TableCell>
                                            <TableCell align='center' component="th" scope="row">
                                                {item.producto.codigo_erp}
                                            </TableCell>
                                            <TableCell align='center' component="th" scope="row">
                                                {item.producto.nombre}
                                            </TableCell>
                                            <TableCell align='center' component="th" scope="row">
                                                {item.cantidad_productos}
                                            </TableCell>
                                            <TableCell align='center' component="th" scope="row">
                                                {item.cantidad_puntos}
                                            </TableCell>
                                            <TableCell align='center'>
                                                <Button
                                                    onClick={() =>  {
                                                        setOpenPopup(true);
                                                        setPopUp('editBeneficioProducto');
                                                        setSelectedBeneficioProducto(item);
                                                    }}
                                                >
                                                    <PencilIcon/>
                                                </Button>

                                                <Button color="error"
                                                    onClick={() =>  {
                                                        openModalDelete()
                                                        setPopUp('delete');
                                                        setSelectedBeneficioProducto(item);
                                                    }}
                                                >
                                                    <DeleteIcon/>
                                                </Button>
                                            </TableCell>
                                        </TableRow>
                                    ))
                                }
                            </TableBody>
                        </Table>
                    </ThemeProvider>
                </TableContainer>
            </Box>
            {
                popUp === 'editBeneficioProducto' && (
                    <Popup
                        largo="sm"
                        title = 'Editar Beneficio por Producto'
                        openPopup = {openPopup}
                        setOpenPopup = {setOpenPopup}
                    >
                        <AddModal
                            popUp={popUp}
                            closeModal={closeModalDelete}
                            beneficioProducto={selectedBeneficioProducto}
                            onListBeneficioProducto={onListBeneficioProducto}
                        />

                    </Popup>
                )
            }
            {
                popUp === 'delete' && (
                    <DeleteModalProducto
                    open={showModalDelete}
                    closeModal={closeModalDelete}
                    beneficioProducto={selectedBeneficioProducto}
                    onListBeneficioProducto={onListBeneficioProducto}
                    />
                )
            }
        </Grid>
    );
}
