import React, { useEffect, useState } from "react";
import { Props } from "./NuevaCompra-type";
import { Protected } from "@/components/layout/Protected";
import { Loading } from "@/components/common/Loading";
import { Box, Button, Divider, Grid, Menu, Table, TableBody, TableCell, TableContainer, TableFooter, TableHead, TablePagination, TableRow, TextField, Typography } from "@mui/material";
import Paper from '@mui/material/Paper';
import { useLocalStorage } from '@toolbox/hooks/local-storage.hook';
import { KEY_EDIT_COMPRA, KEY_EMPRESA, PRODUCTS_CARD, KEY_EDIT_COMPRA_ID, KEY_EDIT_COMPRA_PROVEEDOR_ID } from '@constants/local-storage';
import { productoService } from "@/service/services/Producto.service";
import "../../Home/Home.sass";
import { PagoCompra } from "../components/PagoCompra";
import { saveLocalStorage } from "@/toolbox/helpers/local-storage-helper";
import { useHistory } from 'react-router-dom';
import { ROUTE_COMPRAS } from "@/toolbox/constants/route-map";
import { Popup } from "@/views/Editor/components/Popup";
import { ModalConfirm } from "../components/ModalConfirm";
import { AddProductoS } from "@/views/Productos/AddProductoS";
import AddBoxIcon from '@mui/icons-material/AddBox';
import LocalOfferIcon from '@mui/icons-material/LocalOffer';
import { ModalDescuentoCompra } from "../components/ModalDescuentoCompra";
import { ReposicionInsumosVentaModal } from "./ReposicionInsumosVentaModal";
import { MenuItem } from "react-pro-sidebar";
import { ReposicionSobrantesModal } from "./ReposicionSobrantesModal";

//TabPanel
interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
}
function TabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <Typography variant="h6">{children}</Typography>
                </Box>
            )}
        </div>
    );
}

export const NuevaCompra: React.FC<Props> = (props: Props): JSX.Element => {

    const [loading, setLoading] = React.useState<boolean>(false);
    const [dataEmpresa] = useLocalStorage(KEY_EMPRESA, {
        id: 0, id_tipo_empresa: 0, equivalencia_puntos: 0,
        id_country: 0, country_name: "", country_abbreviation: "",
    });

    // eslint-disable-next-line
    const [value, setValue] = React.useState(0);
    const [textSearch, setTextSearch] = React.useState<string>("");

    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(16);

    const [products, setProducts] = React.useState<Array<any>>([]);
    const [filter, setFilter] = React.useState<Array<any>>([]);

    const [cardState, setCardState] = React.useState([]);

    const [editCompra] = useLocalStorage(KEY_EDIT_COMPRA, []);
    const [card_shop] = useLocalStorage(PRODUCTS_CARD, []);

    const [openPopup, setOpenPopup] = React.useState(false);
    const [popUp, setPopUp] = React.useState<String>('');
    const [showModalConfirm, setShowModalConfirm] = React.useState(false);
    const [codigoBarras, setCodigoBarras] = React.useState<any>('');
    // eslint-disable-next-line
    const [newProduct, setNewProduct] = React.useState<any>('');

    // eslint-disable-next-line
    const [submodulos, setSubModulos] = React.useState([]);
    // eslint-disable-next-line
    const [showBarras, setShowBarras] = React.useState<boolean>(false);

    const [editMode, setEditMode] = React.useState<boolean>(false);
    const [tipoDescuento, setTipoDescuento] = React.useState<any>(1);
    const [valorDescuento, setValorDescuento] = React.useState<any>(0);
    const [indexCart, setIndexCart] = React.useState<any>(0);
    const [showModalReponerVenta, setShowModalReponerVenta] = useState<boolean>(false)
    const [insumosVenta, setInsumosVenta] = useState<any[]>([])
    const [menu, setMenu] = useState(null);
    const history = useHistory();
    const [showModalReponerSobrante, setShowModalReponerSobrante] = useState<boolean>(false)
    const [sobrantes, setSobrantes] = useState<any[]>([])
    const [idsTarjetas, setIdsTarjetas] = useState<number[]>([])

    useEffect(() => {
        getProducts();
        // listAllSubmodulos();
        setCardState(card_shop)
    }, // eslint-disable-next-line
        [])

    useEffect(() => {
        if (showBarras) {
            let inputT = document.getElementById("mi-codigo-de-barras");
            inputT && inputT.focus();
        }
    }, // eslint-disable-next-line
        [showBarras])

    useEffect(() => {
        if (textSearch) {
            let copyFilters = []
            copyFilters = products.filter(producto => (producto?.nombre.toLowerCase().includes(textSearch.toLowerCase()) || producto?.codigo_erp.toLowerCase().includes(textSearch.toLowerCase())))
            setFilter([...copyFilters])
        }
    }, // eslint-disable-next-line
        [textSearch])

    const getProducts = async () => {
        setLoading(true);
        await productoService.getProductosSimplesCompany(dataEmpresa.id).then((data) => {
            if (data.status === 200) {
                setProducts(data.productos);
                setFilter(data.productos)
            }
            setLoading(false);
        })
    }

    const openModalConfirm = () => {
        setShowModalConfirm(true);
    }

    const closeModalConfirm = () => {
        setShowModalConfirm(false);
    }

    useEffect(() => {
        if (insumosVenta.length > 0) {
            insumosVenta.forEach(({ idproducto, cantidad }) => {
                const insumo = products.find(insumo => insumo.id === idproducto);
                if (insumo) handleSelectProduct(insumo, cantidad, parseInt(insumo.precio_compra));
            });
        }
    }, [insumosVenta, products]);

    useEffect(() => {
        if (sobrantes.length > 0) {
            sobrantes.forEach(item => {
                handleSelectProduct(item.producto, item.sobrante, parseInt(item.producto.precio_compra))
            })
            const ids = sobrantes.map(elemento => elemento.id)
            setIdsTarjetas(ids)
        }
    }, [sobrantes]);

    useEffect(() => {
        console.log('idsTarjetas', idsTarjetas)
    }, [idsTarjetas])

    const handleKeyDown = async (event) => {
        let codigo: any = document.getElementById("mi-codigo-de-barras");
        if (event.keyCode === 13) {
            let newValue = codigo.value;

            setLoading(true);
            await productoService.getProductsByCodigo(newValue).then((data) => {
                if (data.status === 200) {
                    codigo.value = ""
                    setTextSearch(newValue);
                    // eslint-disable-next-line
                    (data.productos).map((producto) => {
                        handleSelectProduct(producto);
                    })
                } else {
                    openModalConfirm();
                    setPopUp('modalConfirm');
                    setCodigoBarras(newValue);
                }
                setLoading(false);
            })
        } else {
            let texto = `${event.target.value}`;
            setTextSearch(texto);
            if (!texto) {
                setFilter([...products])
            }
        }
    }

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };
    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleSelectProduct = (product, cantidad?: number, precio?: number) => {
        let card: Array<any> = cardState;
        let exits: boolean = false;
        for (let i = 0; i < card.length; i++) {
            if (card[i].codigo_erp === product?.codigo_erp && card[i].nombre === product?.nombre) {
                exits = true
            }
            if (Object.keys(editCompra).length > 0) {
                if (card[i].codigo === product?.codigo && card[i].nombre === product?.nombre) { exits = true }
            }
        }

        if (!exits) {
            card.push({
                ...product,
                cantidad: cantidad || '',
                precio_unidad: precio || ''
            })
        } else {
            if (cantidad) {
                const item = card.find(element => element.id === product.id)
                if (item) item.cantidad += cantidad
            }
        }
        setCardState([...card])
        saveLocalStorage(PRODUCTS_CARD, card);
    }

    const handleDeleteProduct = (index: number) => {
        let card: Array<any> = cardState;
        card.splice(index, 1);
        setCardState([...card])
    }

    const getProductCantidad = (e, index: number) => {
        let card: Array<any> = cardState;
        card[index].cantidad = e.target.value;
        setCardState([...card])
    }

    const getProductPrecio = (e, index: number) => {

        let card: Array<any> = cardState;
        card[index].precio_unidad = e.target.value;
        setCardState([...card])
    }

    const saveDescuento = (type, value, index) => {
        let card: Array<any> = cardState;
        card[index].tipoDescuento = type;
        card[index].valorDescuento = value;
        setCardState([...card])
    }

    const salirEdicion = () => {
        localStorage.removeItem(KEY_EDIT_COMPRA);
        localStorage.removeItem(PRODUCTS_CARD);
        localStorage.removeItem(KEY_EDIT_COMPRA_ID);
        localStorage.removeItem(KEY_EDIT_COMPRA_PROVEEDOR_ID);
        history.push(ROUTE_COMPRAS);
    }

    function formatName(name) {
        return name.charAt(0).toUpperCase() + name.slice(1).toLowerCase();
    }

    const handleClick = (event) => {
        setMenu(event.currentTarget);
    };

    const handleCloseMenu = () => {
        setMenu(null);
    };

    return (
        <Protected>
            {loading && <Loading />}
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Button variant='outlined' onClick={handleClick} >
                        Opciones
                    </Button>
                    <Menu
                        id="simple-menu-2"
                        anchorEl={menu}
                        open={Boolean(menu)}
                        onClose={handleCloseMenu}
                    >
                        <MenuItem onClick={() => { setShowModalReponerVenta(true); handleCloseMenu(); }} style={{ padding: '5px' }}>
                            <Button variant='outlined'>Reponer insumos de ventas</Button>
                        </MenuItem>
                        <MenuItem onClick={() => { setShowModalReponerSobrante(true); handleCloseMenu(); }} style={{ padding: '5px' }}>
                            <Button variant='outlined' fullWidth>Ingresar sobrantes</Button>
                        </MenuItem>
                    </Menu>
                </Grid>
                <Grid item sm={7} sx={{ minHeight: '60vh', maxHeight: '80vh', overflowY: 'scroll' }}>
                    <Box sx={{ maxWidth: '100%', bgcolor: 'background.paper' }}>
                        <Box sx={{ backgroundColor: '#EDEDED' }}>
                            <TabPanel value={value} index={0} key={0} >
                                <div className="H-card__fcontainer">
                                    <div className="">
                                        <div className="subModulo_container">
                                            <span style={{ marginRight: "15px" }} >Agregar Producto o Insumo:</span>
                                            <AddBoxIcon className="iconAddProduct" fontSize="large"
                                                onClick={() => {
                                                    setOpenPopup(true);
                                                    setPopUp('addNewProduct');
                                                    setEditMode(true);
                                                    setNewProduct('addNewProduct');
                                                }}
                                            />
                                        </div>

                                        <Divider style={{ marginTop: '20px', marginBottom: '20px' }} />

                                        <div className="subModulo_container">
                                            <span>Filtro:</span>
                                            {/* {
                           !showBarras &&
                           <TextField
                              type="text"
                              value={textSearch}
                              placeholder="Buscar por código o nombre del producto"
                              onChange={handleChangeSearch}
                              classes={{
                                 root: "H-card__fcontainer__filter__input"
                              }}
                              style={{backgroundColor: "white", width:"290px", marginLeft: "10px"}}
                           />
                        } */}
                                            <TextField
                                                type="text"
                                                id="mi-codigo-de-barras"
                                                placeholder="Buscar..."
                                                onKeyDown={handleKeyDown}
                                                className="H-card__fcontainer__filter__input"
                                                style={{ backgroundColor: "white", width: "290px", marginLeft: "10px" }}
                                            />
                                            {
                                                Object.keys(editCompra).length > 0 && (
                                                    <Box sx={{ display: 'flex', width: '100%', flexDirection: 'row-reverse', marginLeft: '20px', marginRight: '-10px' }}>
                                                        <Button variant={'outlined'} onClick={() => {
                                                            salirEdicion()
                                                        }}>
                                                            Salir de modo edicion
                                                        </Button>
                                                    </Box>
                                                )
                                            }
                                        </div>
                                    </div>
                                </div>
                                <Grid container spacing={2} justifyContent="center" mt={2}>
                                    {
                                        (rowsPerPage > 0
                                            ? filter?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                            : filter
                                        ).map((product) => (
                                            <Grid item key={product.id}>
                                                <Paper elevation={3}
                                                    style={{
                                                        width: '150px',
                                                        height: '150px',
                                                        padding: '10px',
                                                        display: 'flex',
                                                        flexDirection: 'column',
                                                        justifyContent: 'center',
                                                        alignItems: 'center',
                                                        cursor: 'pointer',
                                                        borderRadius: '8px',
                                                        backgroundColor: '#ffffff',
                                                        boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)',
                                                        transition: 'transform 0.2s ease-in-out',
                                                        textAlign: 'center',
                                                        overflow: 'hidden',
                                                    }}
                                                    onClick={() => handleSelectProduct(product)}
                                                >
                                                    <Typography variant="h6" sx={{
                                                        fontWeight: 'bold',
                                                        fontSize: '1em',
                                                        marginBottom: '5px',
                                                        color: '#333333',
                                                        wordWrap: 'break-word',
                                                        overflow: 'hidden',
                                                        textOverflow: 'ellipsis',
                                                        maxHeight: '80%',
                                                    }}>{formatName(product.nombre)}</Typography>
                                                    <Typography variant="body1" sx={{ fontSize: '1em', color: product.stock > 0 ? '#1b5e20' : '#FF0000' }}>Stock: {product.stock}</Typography>
                                                    <Typography variant="body1" sx={{ fontSize: '1em', color: '#666666' }}>{product.is_supply == 1 ? 'Insumo' : 'Producto'}</Typography>
                                                </Paper>
                                            </Grid>
                                        ))
                                    }
                                </Grid>
                                <Grid item xs={12}>
                                    <TableRow>
                                        <TablePagination
                                            count={filter?.length || 0}
                                            rowsPerPage={rowsPerPage}
                                            page={page}
                                            onPageChange={handleChangePage}
                                            onRowsPerPageChange={handleChangeRowsPerPage}
                                            rowsPerPageOptions={[16, 20, 24]}
                                            labelRowsPerPage={<span className="tabla">Filas:</span>}
                                            labelDisplayedRows={({ page }) => {
                                                return `Página: ${page + 1}`;
                                            }}
                                            SelectProps={{
                                                inputProps: {
                                                    "aria-label": "page number"
                                                }
                                            }}
                                            showFirstButton={true}
                                            showLastButton={true}
                                        />
                                    </TableRow>
                                </Grid>
                            </TabPanel>
                        </Box>
                    </Box>
                </Grid>

                <Grid item sm={5} container direction='row'>
                    <Grid xs={12} sx={{ minHeight: '45vh', maxHeight: '45vh', overflowY: 'scroll', padding: '10px' }}>
                        <div className="H-card__title" style={{ paddingLeft: '10px', paddingTop: '10px' }}>
                            {`Productos Seleccionados | Moneda: ${dataEmpresa.country_abbreviation}`}
                        </div>
                        <div className="H-card__separator"></div>
                        <div className="H-card__list">
                            {
                                cardState.map((product, index) => (
                                    <div className="H-card__list__item">
                                        <div className="content-info">
                                            <div className="info">
                                                <span className="name">
                                                    {product.nombre.charAt(0).toUpperCase() + product.nombre.slice(1).toLowerCase()}
                                                </span>
                                            </div>
                                            {/* <span>Min. {(product?.stock_minimo - product?.stock) <= 0 ? "1" : product?.stock_minimo - product?.stock} u.</span> */}
                                            <span className="price">
                                                cant.
                                                <input
                                                    style={{ width: "50px" }}
                                                    type="number" name="cantidad"
                                                    placeholder="cantidad"
                                                    value={product.cantidad}
                                                    onChange={(e) => getProductCantidad(e, index)}
                                                    readOnly={product.idtarjeta_existencia}
                                                />
                                                <span> a </span>
                                                <input
                                                    style={{ width: "100px", marginRight: "3px" }}
                                                    type="number"
                                                    name="precio_unidad"
                                                    placeholder="precio/u"
                                                    value={product.precio_unidad}
                                                    onChange={(e) => getProductPrecio(e, index)} />
                                                {dataEmpresa.country_abbreviation} c/u
                                            </span>
                                        </div>

                                        <button className="dissmiss-more delete" onClick={() => handleDeleteProduct(index)}>x</button>
                                        {/* <button style={{ color: 'green', border: 'none', background: 'white', cursor: 'pointer' }} onClick={() => {
                                            setOpenPopup(true);
                                            setPopUp('showModalDiscount');
                                            setIndexCart(index)
                                        }
                                        }>
                                            <LocalOfferIcon />
                                        </button> */}
                                    </div>
                                ))
                            }
                        </div>
                    </Grid>
                    <Grid xs={12} sx={{ minHeight: '35vh', maxHeight: '35vh', overflowY: 'scroll' }}>
                        <PagoCompra
                            productsCard={cardState}
                            idsTarjetas={idsTarjetas}
                        />
                    </Grid>
                </Grid>

            </Grid>
            <Box>
                {
                    popUp === 'addNewProduct' && (
                        <Popup
                            largo="sm"
                            title='Agregar Producto o Insumo'
                            openPopup={openPopup}
                            setOpenPopup={setOpenPopup}
                        >
                            <AddProductoS
                                closeModal={() => { setOpenPopup(false) }}
                                getProducts={getProducts}
                                editMode={editMode}
                                isSupplyOrProduct
                            />
                        </Popup>

                    )
                }
            </Box>

            <Box>
                {
                    popUp === 'modalConfirm' && (
                        <ModalConfirm
                            open={showModalConfirm}
                            closeModal={closeModalConfirm}
                            codigoBarras={codigoBarras}
                            getProducts={getProducts}
                            isHome={false}
                        />
                    )
                }
            </Box>
            <Box>
                {
                    popUp === 'showModalDiscount' && (
                        <Popup
                            largo="sm"
                            title='Agregar Descuento'
                            openPopup={openPopup}
                            setOpenPopup={setOpenPopup}
                        >
                            <ModalDescuentoCompra
                                closeModal={() => { setOpenPopup(false) }}
                                setTipoDescuento={setTipoDescuento}
                                tipoDescuento={tipoDescuento}
                                setValorDescuento={setValorDescuento}
                                valorDescuento={valorDescuento}
                                indexCart={indexCart}
                                saveDescuento={saveDescuento}
                                productsCard={cardState}
                            />
                        </Popup>

                    )
                }
            </Box>
            {
                showModalReponerVenta && (
                    <ReposicionInsumosVentaModal
                        open={showModalReponerVenta}
                        setOpenPopup={setShowModalReponerVenta}
                        setInsumosVenta={setInsumosVenta}
                    />
                )
            }
            {
                showModalReponerSobrante && (
                    <ReposicionSobrantesModal
                        open={showModalReponerSobrante}
                        setOpenPopup={setShowModalReponerSobrante}
                        setSobrantes={setSobrantes}
                    />
                )
            }
        </Protected>
    );
}
