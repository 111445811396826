import { http } from '../http/http';
import { UserDTO } from '../http/dto/UserDTO';
import { API_URL_BASE, API_URL_NUPY } from '@toolbox/defaults/app';
import { User } from '../models/User';
import axios from 'axios';

export const userRepository = {

   getUser: async (): Promise<User> => {
      const user = await http.get<UserDTO>(`${API_URL_BASE}/v1/account`)
      const { data, error, message } = user
      return {
         data: (data || []).map((dt) => ({
            email: dt.email,
            password: dt.password
         })),
         error,
         message
      };
   },
   getUsers: async (): Promise<any> => {
      const res = await axios.get(`${API_URL_NUPY}/users/list`);
      return res.data;
   },
   getUserById: async (idUser: number): Promise<any> => {
      const res = await axios.get(`${API_URL_NUPY}/users/getUserById/${idUser}`);
      return res.data;
   },
   getUsersByEmpresa: async (empresa_id: number): Promise<any> => {
      const res = await axios.get(`${API_URL_NUPY}/users/users-empresa/${empresa_id}`);
      return res.data;
   },
   getUsersBySucursal: async (id_sucursal: number): Promise<any> => {
      const res = await axios.get(`${API_URL_NUPY}/users/users-sucursal/${id_sucursal}`);
      return res.data;
   },
   editState: async (idUser: number, state: string): Promise<any> => {
      const res = await axios.patch(`${API_URL_NUPY}/users/editState/${idUser}/${state}`);
      return res.data;
   },
   register: async (data: {}): Promise<any> => {
      const res = await axios.post(`${API_URL_NUPY}/users/register`, data);
      return res.data;
   },
   update: async (data: {}, idUser: number): Promise<any> => {
      const res = await axios.post(`${API_URL_NUPY}/users/update/${idUser}`, data);
      return res.data;
   },
   listRol: async (): Promise<any> => {
      const res = await axios.get(`${API_URL_NUPY}/users/listRol`);
      return res.data;
   },
   changePassword: async (data: {}, idUser: number): Promise<any> => {
      const res = await axios.post(`${API_URL_NUPY}/users/changePassword/${idUser}`, data);
      return res.data;
   },

   //validar table_detalle_cliente_user ->logueo
   validateDetalleClienteUser: async (data: {}): Promise<any> => {
      const res = await axios.post(`${API_URL_NUPY}/users/validateDetalleClienteUser`, data);
      return res.data;
   },
}
