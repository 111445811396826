import { API_URL_NUPY } from '@/toolbox/defaults/app';
import axios from 'axios';

export const CuponesRepository = {
    getCupones: async(): Promise<any> => {
        const res = await axios.get(`${API_URL_NUPY}/cupones/list`);
        return res.data;
    },

    showCupon: async(id: number): Promise<any> => {
        const res = await axios.get(`${API_URL_NUPY}/cupones/show/${id}`);
        return res.data;
    },

    couponsAvailable: async(): Promise<any> => {
        const res = await axios.get(`${API_URL_NUPY}/cupones/couponsAvailable`);
        return res.data;
    },

    register: async(data:{}): Promise<any> => {
        const res = await axios.post(`${API_URL_NUPY}/cupones/register`, data);
        return res;
    },

    update: async(data:{}, idCupon:number): Promise<any> => {
        const res = await axios.post(`${API_URL_NUPY}/cupones/update/${idCupon}`, data);
        return res;
    },

    updateStatus: async(idCupon:number, state:string): Promise<any> => {
        const res = await axios.patch(`${API_URL_NUPY}/cupones/updateStatus/${idCupon}/${state}`);
        return res.data;
    },

    deleteCupon: async(idCupon:number): Promise<any> => {
        const res= await axios.delete(`${API_URL_NUPY}/cupones/delete/${idCupon}`)
        return res;
    }
}
