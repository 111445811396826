import React, { useEffect, useState } from 'react';
import { Grid, Typography } from '@mui/material';
import { readLocalStorage } from '@/toolbox/helpers/local-storage-helper';
import { KEY_EMPRESA } from '@/toolbox/constants/local-storage';
import { Props } from './ComponentPreCuenta.type';
import { ticketService } from '@/service/services/Ticket.service';
import { moneyFormatInt } from '@/toolbox/helpers/money.helper';

export const ComponentPreCuenta: React.FC<Props> = (props: Props) => {
    const {pedido, detalle, numberTickets} = props;
    const SessionEmpresa: any = readLocalStorage(KEY_EMPRESA);

    return(
        <div style={{  marginBottom: 30, width: '300px', margin: 'auto', marginTop: 30 ,padding:"10px"}}>
            <div style={{width:"100%"}} >

                <div style={{textAlign:"center"}}>
                    <Typography sx={{fontSize:'1.3em !important', fontFamily:'sans-serif',fontWeight:'700'}}>
                        {SessionEmpresa?.nombre}
                    </Typography>
                    <Typography sx={{fontSize:'1.3em !important', fontFamily:'sans-serif',fontWeight:'700'}}
                     >PRE CUENTA</Typography>
                </div>

                <hr/>

                <div>
                    <Typography sx={{fontSize:'1.2em !important'}}> 
                        <strong>Mesa:</strong> {pedido?.pedido?.mesa?.nombre || '---'}
                    </Typography>
                    <Typography sx={{fontSize:'1.2em !important'}}> 
                        <strong>Cliente:</strong> {pedido?.pedido?.cliente?.nombre || '---'}
                    </Typography>
                    {/* <Typography sx={{fontSize:'1.2em !important'}}> 
                        <strong>Cantidad Tickets:</strong> {numberTickets || '1'}
                    </Typography> */}
                </div>

                <hr style={{border: '0', height: '2px', borderTop: '1px dashed black', borderBottom: '1px dashed black'}}/>

                <div>
                    <Grid container>
                        <Grid item xs={6}>
                            <Typography sx={{textAlign:"left",fontSize:'1.2em !important'}}>Artículo</Typography>
                        </Grid>
                        <Grid item xs={6}>
                            <Typography sx={{textAlign:"right",fontSize:'1.2em !important'}}>Cantidad</Typography>
                        </Grid>
                    </Grid>
                </div>

                <hr style={{border: '0', height: '2px', borderTop: '1px dashed black', borderBottom: '1px dashed black'}}/>
                {
                    detalle?.length > 0 && detalle.map((product, key) => (
                        <div key={key}>
                            <Grid container>

                                <Grid item xs={12}>
                                    <Typography sx={{textAlign:"left",fontSize:'1em !important'}}>{product?.productos?.nombre}</Typography>
                                </Grid>
                                <Grid item xs={8}>
                                    <Typography sx={{textAlign:"left",fontSize:'1em !important'}}>
                                        {product.cantidad_inicial} {product?.unidad_medida ? product?.unidad_medida?.abbreviation : ''} x {moneyFormatInt(Math.ceil(product?.precio_unitario))}
                                    </Typography>
                                </Grid>
                                <Grid item xs={4}>
                                    <Typography sx={{textAlign:"right",fontSize:'1em !important'}}>
                                        {moneyFormatInt(Math.ceil(Number(product?.precio_unitario)*Number(product.cantidad_inicial)/Number(product.cantidad_peso || 1)))}
                                    </Typography>
                                </Grid>

                            </Grid>
                        </div>
                    ))
                }
                {
                    pedido?.registosCambios?.length > 0 &&
                    <>
                    <hr style={{border: '0', height: '2px', borderTop: '1px black', borderBottom: '1px dashed black'}}/>
                    <div>
                        <Grid container>
                            <Grid item xs={12}>
                                <Typography sx={{textAlign:"left",fontSize:'1.2em !important'}}>Cambios</Typography>
                            </Grid>
                        </Grid>
                    </div>
                    </>
                }
                
                <hr style={{border: '0', height: '2px', borderTop: '1px black', borderBottom: '1px dashed black'}}/>
                {
                    pedido?.registosCambios?.length > 0 && pedido?.registosCambios?.map((product, key) => (
                        <div key={key}>
                            <Grid container>

                                <Grid item xs={12}>
                                    <Typography sx={{textAlign:"left",fontSize:'1em !important'}}>{product?.productos?.nombre}</Typography>
                                </Grid>
                                <Grid item xs={8}>
                                    <Typography sx={{textAlign:"left",fontSize:'1em !important'}}>
                                        {product.diferencia} {product?.unidad_medida ? product?.unidad_medida?.abbreviation : ''} x {moneyFormatInt(Math.ceil(product?.productos?.precio_standar))}
                                    </Typography>
                                </Grid>
                                <Grid item xs={4}>
                                    <Typography sx={{textAlign:"right",fontSize:'1em !important'}}>
                                       {product?.operacion == '1' ? ' + ' : ' - ' } {moneyFormatInt(Math.ceil(Number(product?.productos?.precio_standar)*Number(product.diferencia)/Number(product.cantidad_peso || 1)))}
                                    </Typography>
                                </Grid>

                            </Grid>
                        </div>
                    ))
                }
                <hr style={{border: '0', height: '2px', borderTop: '1px black', borderBottom: '1px dashed black'}}/>
                <div >
                    <Grid container>
                        <Grid item xs={6}>
                            <Typography sx={{textAlign:"left",fontSize:'1em !important'}}>Total Pre-Cuenta:</Typography>
                        </Grid>
                        <Grid item xs={6}>
                            <Typography sx={{textAlign:"right",fontSize:'1em !important'}}>
                            <strong>{moneyFormatInt(Math.ceil(pedido?.pedido.monto_total))}</strong>
                            </Typography>
                        </Grid>
                    </Grid>
                </div>

            </div>
        </div>
    )
}