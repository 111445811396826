import React, { useEffect, useState } from 'react';
import { Grid, Typography } from "@mui/material";
import { Props } from "./CategoryProducts.type";
import './CategoryProducts_style.css';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import { useHistory } from 'react-router-dom';
import { editor } from '@/service/services/Editor.service';
import { ROUTE_PRODUCTOS_ECOMMERCE } from '@/toolbox/constants/route-map';
import { Loading } from '@/components/common/Loading';

export const CategoryProducts: React.FC<Props> = (props: Props): JSX.Element => {

   const idsProducts = useHistory();

   const [loading, setLoading] = useState<boolean>(false);
   const [submodulos, setSubModulos] = useState([]);

   useEffect(()=> {
      getModulos();
      // eslint-disable-next-line
   }, []);

   const getModulos = async() => {
      setLoading(true);
      await editor.cargarModulos().then((data) => {
         const modulos: any = Object.values(data.modulos);
         for(let i = 0; i<modulos.length;i++){
            if(modulos[i].submodulos){
               getSubmodulos(modulos[i].submodulos);
            }
         }
      });
   }

   const getSubmodulos = async(ids) => {
      let arraySubmodulos = [];
      await editor.cargarSubModulos(ids).then((data) => {
         const subModulos: any = Object.values(data.submodulos);
         for(let i = 0; i<subModulos.length;i++){
            const valor = Object.values(subModulos[i]);
            arraySubmodulos.push(valor[0]);
         }
         setSubModulos(arraySubmodulos);
      })
      setLoading(false);
   }

   return (
      <>
         {loading && <Loading/>}
         <Grid container item xs={12} sx={{ display: 'flex'}}>
            {
               submodulos && submodulos.map((submodulo, i) => {
                  return (
                     <Grid item xs={12} sm={6} md={4} p={3} key={i} onClick = {() => {idsProducts.push(ROUTE_PRODUCTOS_ECOMMERCE, {id_products: submodulo.id_productos, categoryName: submodulo.nombre} )}}>
                        <Card key={i} className={"card-container"} sx={{ borderRadius: '25px', boxSizing: "border-box" }}>
                           <CardMedia

                              component="img"
                              image={submodulo.imagen}
                              alt="categorias"
                              sx={{ height: '150px', padding: '20px', margin: 'auto', background: "#82A8F3", boxSizing: "border-box" }}
                           />
                           <CardContent sx={{ height: '100px',  boxSizing: "border-box" }}>
                              <Typography
                                 sx={{ padding: '20px', boxSizing: "border-box" }}
                                 className="card-container-name" textAlign={'center'} >
                                 {submodulo.nombre}
                              </Typography>
                           </CardContent>

                        </Card>

                     </Grid>
                  )
               })
            }
         </Grid>
      </>
   );
}
