import React from "react";
import { Chart } from "react-google-charts";

export const DonutChart = (props) =>{

    const {data} = props;

    const options = {
        title: "",
        pieHole: 0.4,
        is3D: false,
    };

    return (
        <Chart
            chartType="PieChart"
            width="100%"
            height="400px"
            data={data ? data : []}
            options={options}
        />
    );
}
