import React, { useEffect } from "react";
import { Autocomplete, Divider, Grid, TextField, Typography, Tooltip } from "@mui/material";
import { Form, Formik } from "formik";
import { useLocalStorage } from '@/toolbox/hooks/local-storage.hook';
import { KEY_USER_DATA } from '@/toolbox/constants/local-storage';
import { empresasService } from "@/service/services/Empresas.service";
import { Loading } from "@/components/common/Loading";
import { sucursalesService } from "@/service/services/Sucursales.service";
import PriorityHighIcon from '@mui/icons-material/PriorityHigh';
import { Snackbar, Alert } from '@mui/material';

export const AddSucursal: React.FC<any> = (props:any): JSX.Element => {
    const {closeModal, onListSucursales, sucursal, editMode} = props;

    const [loading, setLoading] = React.useState<boolean>(false);
    const [companies, setCompanies] = React.useState([]);
    const [empresaSelect, setEmpresaSelect] = React.useState<any>("");

    const [dataUser] = useLocalStorage(KEY_USER_DATA, undefined);
    const userSession = dataUser && dataUser.user;
    const rol = dataUser && dataUser.user.rol;
    const isSuperAdmin = rol === 'Superadmin';

    const [snackBarConfig, setSnackBarConfig] = React.useState<any>({
        open: false,
        severity: 'error',
        message: 'Error',
        autoHideDuration: 3000,
    });
    // eslint-disable-next-line
    const [data, setData] = React.useState({
        id: sucursal && sucursal.id ? sucursal.id : "",
        nombre: sucursal && sucursal.nombre? sucursal.nombre : "",
        state: sucursal && sucursal.state? sucursal.state : 1,
        direccion: sucursal && sucursal.direccion? sucursal.direccion : "",
        id_empresa: sucursal && sucursal.id_empresa? sucursal.id_empresa : "",
    });

    useEffect(() => {
        getEmpresas();
    },[])

    const getEmpresas = async() => {
        setLoading(true);
        await empresasService.listEmpresaByName().then((data)=>{
            setCompanies(data);
            setLoading(false);
            if(data){
                setEmpresaSelect(data[0])
            }
        })
    }

    const validateForm = (values) => {
        let errors: any = {};

        if(!values.nombre) errors.nombre = "nombre requerido";

        if(!values.direccion) errors.direccion = "dirección requerido";
        return errors;
    }

    const onSubmit = async(values, {resetForm}) => {
        const formData = new FormData();
        formData.append('nombre', values.nombre);
        formData.append('direccion', values.direccion);
        setLoading(true);
        if(sucursal){
            await sucursalesService.update(formData, values.id).then((data)=>{
                onListSucursales && onListSucursales();
                closeModal();
            })
        }else{
            formData.append('state', values.state);
            if(!isSuperAdmin){
                formData.append('id_empresa', userSession.id_empresa);
            }else{
                formData.append('id_empresa', empresaSelect.id);
            }

            await sucursalesService.register(formData).then((data)=>{
                if(data.status === 400){
                    setSnackBarConfig(prev => ({
                        ...prev,
                        open: true,
                        message: `${data.error}`,
                    }));
                }else{
                    onListSucursales && onListSucursales();
                    closeModal();
                }
            })
        }
        setLoading(false);
    }

    return (
        <div className="container">
            {loading && <Loading/>}

            <Snackbar
                open={snackBarConfig.open}
                autoHideDuration={snackBarConfig.autoHideDuration}
                onClose={() => setSnackBarConfig(prev => ({ ...prev, open: false }))}
                anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                >
                <Alert
                onClose={() => setSnackBarConfig(prev => ({ ...prev, open: false }))}
                severity={snackBarConfig.severity}
                variant="filled"
                >
                {snackBarConfig.message}
                </Alert>
            </Snackbar>

            <Formik enableReinitialize initialValues={data} validate={ (values) => validateForm(values) } onSubmit={onSubmit}>
                {({values, errors, touched, handleSubmit, handleChange, setFieldValue}) => {
                    return(
                        <Form onSubmit={handleSubmit}>
                            <Grid container spacing={3} alignItems="center" justifyContent="center">

                            {!sucursal && isSuperAdmin && (
                                <Grid container item xs={12} style={{display: "flex", alignItems: "center"}}>
                                    <Grid item xs={4}>
                                        <Typography variant="subtitle1"><b>Empresa:</b> </Typography>
                                    </Grid>
                                    <Grid item xs={8}>
                                    <Autocomplete
                                        disablePortal
                                        id="combo-box-demo"
                                        value={empresaSelect}
                                        options={companies || []}
                                        getOptionLabel={(option) => option.name || ""}
                                        fullWidth
                                        renderInput={(params) => <TextField {...params} label="Escriba para buscar" />}
                                        onChange={(e, newValue) => {
                                        setEmpresaSelect(newValue);
                                        }}
                                    />
                                    </Grid>
                                </Grid>
                            )}

                            <Grid item xs={12} container style={{display: "flex", alignItems: "center"}}>
                                <Grid item xs={4}>
                                    <Typography variant="subtitle1" className="custom-input"><b>Nombre:</b></Typography>
                                </Grid>
                                <Grid item xs={8} style={{display: 'flex', alignItems: "center"}}>
                                    {
                                        errors.nombre && (touched.nombre ? true : false) && (
                                            <Tooltip title={errors.nombre}>
                                                <PriorityHighIcon className={'iconWarning'}/>
                                            </Tooltip>
                                        )
                                    }
                                    <TextField
                                    id="nombre"
                                    type="text"
                                    name="nombre"
                                    fullWidth
                                    value={values.nombre}
                                    onChange={handleChange}
                                    placeholder="Nombre de la sucursal"
                                    error={errors.nombre && touched.nombre ? true : false}
                                    disabled = {!editMode}
                                    />
                                </Grid>
                            </Grid>

                            <Grid item xs={12} container style={{display: "flex", alignItems: "center"}}>
                                <Grid item xs={4}>
                                    <Typography variant="subtitle1" className="custom-input"><b>Dirección:</b></Typography>
                                </Grid>
                                <Grid item xs={8} style={{display: 'flex', alignItems: "center"}}>
                                    {
                                        errors.direccion && (touched.direccion ? true : false) && (
                                            <Tooltip title={errors.direccion}>
                                                <PriorityHighIcon className={'iconWarning'}/>
                                            </Tooltip>
                                        )
                                    }
                                    <TextField
                                    id="direccion"
                                    type="text"
                                    name="direccion"
                                    fullWidth
                                    value={values.direccion}
                                    onChange={handleChange}
                                    placeholder="direccion de la sucursal"
                                    error={errors.direccion && touched.direccion ? true : false}
                                    disabled = {!editMode}
                                    />
                                </Grid>
                            </Grid>

                            {
                                (sucursal && !editMode) && (
                                <Grid item xs={12} container style={{display: "flex", alignItems: "center"}}>
                                    <Grid item xs={4}>
                                        <Typography variant="subtitle1" className="custom-input"><b>Estado:</b></Typography>
                                    </Grid>
                                    <Grid item xs={8}>
                                        <TextField
                                        id="state"
                                        type="text"
                                        name="state"
                                        fullWidth
                                        value={sucursal.state === 1 ? 'Habilitado' : 'Desabilitado'}
                                        onChange={handleChange}
                                        placeholder="Estado"
                                        disabled
                                        />
                                    </Grid>
                                </Grid>
                                )
                            }

                            </Grid>
                            <Divider sx={{ my: 2 }} />
                            {
                                editMode && (
                                    <div className="form-group mb-3 buton_container_modal">
                                        <button type="submit" className="btn btn-primary">
                                        {
                                            sucursal ? `Editar Sucursal` : `Agregar Sucursal`
                                        }
                                        </button>
                                    </div>
                                )
                            }

                        </Form>
                    )
                }}
            </Formik>
        </div>
    );
}
