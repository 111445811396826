import axios from 'axios';
import { API_URL_NUPY } from '@/toolbox/defaults/app';

export const compraDetalle = {
    saveCompraDetalle: async(codigo:string, cantidad:number, precio_unidad:number, tipo_descuento:number, valor_descuento:number,  id_producto:number, id_compra:number): Promise<any> =>{
        const res = await axios.post(`${API_URL_NUPY}/compraDetalle/register`, {
            codigo,
            cantidad,
            precio_unidad,
            tipo_descuento,
            valor_descuento,
            id_producto,
            id_compra
        });

        return res.data;
    },

    listByCompra: async(idCompra: number): Promise<any> =>{
        const res = await axios.get(`${API_URL_NUPY}/compraDetalle/listByCompra/${idCompra}`);
        return res.data;
    }
}