import { Props } from "./DetailModal.type";
import { styled } from '@mui/material/styles';
import { Grid, Table, TableCell, TableContainer, TableHead, TableRow, TextField, Typography, TableBody } from "@mui/material";
import Paper from '@mui/material/Paper';
import { ventaService } from "@/service/services/Venta.service";
import React, { useEffect } from "react";
import { Loading } from "@/components/common/Loading";

const Titulo = styled('div')(({ theme }) => ({
    ...theme.typography.button,
    padding: theme.spacing(1),
    fontWeight: 'bold',
    fontSize: '18px',
    color: 'black',
  }));

export const DetailModal: React.FC<any> = (props:Props):JSX.Element => {

    const {venta} = props;

    const [products, setProducts] = React.useState<Array<any>>([]);
    const [loading,setLoading] = React.useState<boolean>(false);

    useEffect(() => {
        getProductosVenta();
    },// eslint-disable-next-line
    [])

    const getProductosVenta = async() => {
        setLoading(true);
        await ventaService.listProductByVenta(venta.id).then((data) =>{
            setProducts(data);
            setLoading(false);
        })
    }

    return (
        <Grid container spacing={0} direction="column" alignItems="center" justifyContent="center" style={{ marginBottom: '15px', marginTop:'5px'}} >
            {loading && <Loading/>}
            <Grid item xs={3} style={{ marginBottom:'10px'}}>
               <Titulo>{"Informacion del pedido"}</Titulo>
            </Grid>

            <Grid container alignItems="center" justifyContent="center">

                <Grid item xs={6} style={{display: "flex", alignItems: "center", marginBottom: "20px"}}>
                <Typography variant="subtitle1" style={{width: "185px"}}>Nombre Cliente:</Typography>
                <TextField
                    disabled
                    size="small"
                    value={venta && venta.pedido?.cliente?.nombre ? venta.pedido?.cliente?.nombre: "Cliente General"}/>
                </Grid>

                <Grid item xs={6} style={{display: "flex", alignItems: "center", marginBottom: "20px"}}>
                <Typography variant="subtitle1" style={{width: "185px"}}>Nombre Empleado:</Typography>
                <TextField
                    disabled
                    size="small"
                    value={venta && venta.pedido?.usuario?.nombre ? venta.pedido?.usuario?.nombre: "Usuario General"}/>
                </Grid>

                <Grid item xs={6} style={{display: "flex", alignItems: "center", marginBottom: "20px"}}>
                <Typography variant="subtitle1" style={{width: "185px"}}>Cantidad de Productos:</Typography>
                <TextField
                    disabled
                    size="small"
                    value={venta && venta.cantidad_productos}/>
                </Grid>

                <Grid item xs={6} style={{display: "flex", alignItems: "center", marginBottom: "20px"}}>
                <Typography variant="subtitle1" style={{width: "185px"}}>Descuento por Puntos:</Typography>
                <TextField
                    disabled
                    size="small"
                    value={venta && venta.descuento_x_puntos}/>
                </Grid>

                <Grid item xs={6} style={{display: "flex", alignItems: "center"}}>
                <Typography variant="subtitle1" style={{width: "185px"}}>Monto Total:</Typography>
                <TextField
                    disabled
                    size="small"
                    value={venta && venta.monto_total}/>
                </Grid>

                <Grid item xs={6} style={{display: "flex", alignItems: "center"}}>
                <Typography variant="subtitle1" style={{width: "185px"}}>Fecha :</Typography>
                <TextField
                    disabled
                    size="small"
                    value={venta && venta.fecha}/>
                </Grid>

            </Grid>

            <Grid item xs={3} style={{marginTop: "20px"}}>
               <Titulo>{"Productos"}</Titulo>
            </Grid>

            <TableContainer component={Paper}>
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                <TableHead>
                     <TableRow>
                        <TableCell>{`N°`}</TableCell>
                        <TableCell align="right">Codigo</TableCell>
                        <TableCell align="right">Nombre</TableCell>
                        <TableCell align="right">Precio</TableCell>
                        <TableCell align="right">Cantidad</TableCell>
                     </TableRow>
                </TableHead>
                <TableBody>
                    {
                        products && products.map((item, index) => (
                            <TableRow key={item.id}
                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                            classes={{
                               hover: 'hover-row-t'
                            }}>
                                <TableCell>{index+1}</TableCell>
                                <TableCell align="right">{item.productos.codigo_erp}</TableCell>
                                <TableCell align="right">{item.productos.nombre}</TableCell>
                                <TableCell align="right">{item.productos.precio_standar}</TableCell>
                                <TableCell align="right">{item.cantidad}</TableCell>
                            </TableRow>
                        ))
                    }
                </TableBody>
                </Table>
            </TableContainer>

        </Grid>
    );
}
