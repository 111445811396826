// import axios from "axios";
import { KEY_EMPRESA} from '@constants/local-storage';
import {readLocalStorage} from '@helpers/local-storage-helper';
import { cajasRepository } from "../repositories/Cajas.repository";

export const cajas = {
    cargarCajas,
    addCaja,
    deleteCaja,
    editCaja,
    getCajasByIdCompany,
    getCierreByCaja
}

async function cargarCajas () {
    const res = await cajasRepository.getCajas();
    return res;
}

async function getCajasByIdCompany (idCompany:number, idSucursal:number) {
    const res = await cajasRepository.getCajasByIdCompany(idCompany, idSucursal);
    return res;
}

async function getCierreByCaja (idCaja:number) {
    const res = await cajasRepository.getCierreByCaja(idCaja);
    return res;
}

async function addCaja (nombre:string ,  id_sucursal: number) {
    const company: any = readLocalStorage(KEY_EMPRESA);
    const res = await cajasRepository.addCaja(nombre, company.id, id_sucursal).then((data)=>{
        return data;
    });
    return res;
}

async function deleteCaja (id:Number) {
    const res = await cajasRepository.deleteCaja(id);
    return res;
}

async function editCaja (id: number, nombre: any, id_sucursal: any) {
    const res = await cajasRepository.editCaja(id, nombre,id_sucursal);
    return res;
}

