import { CocinaRepository } from "../repositories/Cocina.repository"

export const cocinaService = {
    register,
    getCocinaById,
    getCocinas,
    getCocinasAvailable,
    update,
    editStatus,
}

async function register(data:{}){
    const res = await CocinaRepository.register(data);
    return res;
}

async function getCocinaById(idCocina: number){
    const res = await CocinaRepository.getCocinaById(idCocina);
    return res;
}

async function getCocinas(){
    const res = await CocinaRepository.getCocinas();
    return res;
}

async function getCocinasAvailable(){
    const res = await CocinaRepository.getCocinasAvailable();
    return res;
}

async function update(data:{}, idCocina:number){
    const res = await CocinaRepository.update(data, idCocina);
    return res;
}

async function editStatus(idCocina:number, state:number){
    const res = await CocinaRepository.editStatus(idCocina, state);
    return res;
}