import { Button, Grid, useTheme , CircularProgress } from '@mui/material';
import {  ThemeProvider } from '@mui/material/styles';
import Paper from '@mui/material/Paper';
import { Input } from '@components/common/Input';
import { useMemo, useRef, useState } from 'react';
import { VALIDATORS } from '@toolbox/helpers/validation-rules';
import { InputRef } from '@components/common/Input/InputInterfaces';
import { useLocation } from 'react-router-dom';
import { authenticationService } from '@/service/services/Authentication.service';
import { saveLocalStorage } from '@/toolbox/helpers/local-storage-helper';
import { KEY_EDIT_PEDIDO } from '@/toolbox/constants/local-storage';
import { ticketService } from '@/service/services/Ticket.service';
import * as Routes from '@constants/route-map';

export const LoginCliente: React.FC<any> = (props): JSX.Element => {

    const {search} = useLocation();
    const inputRefs = useRef<Array<InputRef | null>>([]);
    const theme = useTheme();
    const [loading, setLoading] = useState<boolean>(false);

    const [data, setData] = useState({
        username: '',
        password: '',
        textError: '',
    });

    let query = new URLSearchParams(search)
    let keyMesaClient: any = query.get("key");
    // eslint-disable-next-line
    let keyClient: any = query.get("key2");

    const rules = useMemo(() => ({
        username: [
           VALIDATORS.REQUIRED
        ],
        password: [
           VALIDATORS.REQUIRED
        ],
    }), []);

    const validate = () => {
    const refs = inputRefs.current;
    const valid = refs.reduce((prev, ref) => {
        const isValid = ref ? ref.validate(true) : false
        return prev && isValid;
    }, true);

    return valid;
    }

    const handleInput = (event: React.ChangeEvent<HTMLInputElement>) => {
        const name = event.target.name;
        const value = event.target.value;

        switch (name) {
            case 'username':
                setData(prev => ({ ...prev, username: value, textError: '' }));
                break;
            case 'password':
                setData(prev => ({ ...prev, password: value, textError: '' }));
                break;
            default:
                break;
        }
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        const { username, password } = data;
        try {
           if (validate()) {
              authSubmit( username, password);
           }
        } catch (error) {
           setData(prev => ({ ...prev, textError: 'Lo sentimos, ocurrió un error inesperado.' }));
        } finally {
          // setLoading(false);
        }
    }

    const authSubmit = async (username, password) => {

        const response = await authenticationService.loginClient(keyMesaClient, username, password);
        if (response.token === '') {
            window.location.replace("/login");
            setLoading(false);
        } else {
            const searchPedido = await ticketService.getPedidoDetalleByMesa(keyMesaClient);
            if(searchPedido && searchPedido.status === 200){
                saveLocalStorage(KEY_EDIT_PEDIDO, searchPedido);
                setLoading(false);
            }

            if (response?.user?.main_redirect === Routes.ROUTE_HOME) {
                window.location.reload();
            } else {
                window.location.replace('/home-cliente');
            }
        }

        localStorage.setItem('keysClient', JSON.stringify({
            keyCliente: response?.cliente_id,
            keyMesa: keyMesaClient
        }));
    }

    return (
        <ThemeProvider theme={theme}>
            <Grid container component="main" className="App-login" sx={{ height: '100vh' }}>
            <Grid container className="all-heigth" style={{ height: "100%",textAlign: "left", justifyContent: "center", alignItems: "center" }}>
                <Grid container item xs={12} md={3} style={{ textAlign: "left", justifyContent: "center", alignItems: "center" }}>
                <Paper elevation={3} >
                    <form >
                        <div style={{padding: "20px"}}>
                        <Grid container spacing={2} >
                            <Grid item xs={12}>
                                <h1 className ="textTittle">Iniciar Sesión</h1>
                                <Input
                                    ref={ref => inputRefs.current[0] = ref}
                                    name="username" placeholder="Ingrese su nombre de usuario"
                                    value={data.username} backgroundColor="#ffffff"
                                    rules={rules.username} disableElevation validateOnBlur
                                    dense onChange={handleInput}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <Input
                                    ref={ref => inputRefs.current[1] = ref}
                                    name="password" type="password"
                                    placeholder="Ingrese su contraseña" value={data.password}
                                    backgroundColor="#ffffff" rules={rules.password}
                                    disableElevation validateOnBlur
                                    dense onChange={handleInput}
                                />
                            </Grid>
                            <Grid item xs={12} container>
                                <Button
                                    className="buttonLogin"
                                    style={{ backgroundColor: "#0c879c", fontFamily: "Lato" }}
                                    fullWidth={true}
                                    variant='contained' onClick={handleSubmit}
                                    disabled={loading} disableElevation
                                >
                                    {
                                        loading ?
                                        <CircularProgress size={24}  style={{color: "#FFFF"}}/> :
                                        <span style={{textTransform: "capitalize", fontSize: "14px"}}>Iniciar Sesión</span>
                                    }
                                </Button>
                            </Grid>
                        </Grid>
                        </div>
                    </form>
                </Paper>
                </Grid>
            </Grid>
            </Grid>
        </ThemeProvider>
    );
}
