import React, {  useState, useEffect, FunctionComponent } from 'react';
import Box from '@mui/material/Box';
import { Loading } from '@/components/common/Loading';
import { cuadraturaService } from '@service/services/Cuadratura.service';
import Grid from '@mui/material/Grid';
import moment from 'moment';
import { ventaService } from "@/service/services/Venta.service";
import { productoService } from "@/service/services/Producto.service";
import { ReporteVentas } from "../Reporte/componentes/ReporteVentas";
import { ProductosSimples } from "../Reporte/componentes/productosSimples/ProductosSimples";
import { ProductosMenu } from "../Reporte/componentes/productosMenu/ProductosMenu";
import { ProductosAgrupados } from "../Reporte/componentes/productosAgrupado/ProductosAgrupado";
import { OpenCuadratura } from "./popups/open/Open";
import { CloseCuadratura } from "./popups/close/Close";
import  "./Cuadratura.sass"
import { Alert, Button, Divider, Snackbar } from '@mui/material';
import { styled } from '@mui/material/styles';
import { TiposPagos } from '../Reporte/componentes/TiposPagos/TiposPagos';
import { CierreCuadratura } from './CierreCuadratura';
import { AjustesMovimientos } from '../Reporte/componentes/AjustesMovimientos/AjustesMovimientos';
import { moneyFormatInt } from '@/toolbox/helpers/money.helper';
import { Billetes } from '../Reporte/componentes/Billetes/Billetes';
import { DetalleDescuadre } from '../Reporte/componentes/DetalleDescuadre/DetalleDescuadre';
import { TiposPagosFamilia } from '../Reporte/componentes/TiposPagosFamilia/TiposPagosFamilia';
import { useLocalStorage } from '@/toolbox/hooks/local-storage.hook';
import { TOKEN_API } from '@/toolbox/constants/local-storage';
import { readLocalStorage } from '@/toolbox/helpers/local-storage-helper';
type Props = {
   type: "actual" | "anterior",
   idUsuario?: number,
   idMesa?: number,
   idSala?: number,
   itemSelected?: any,
   filtro?: any,
}

const Div = styled('div')(({ theme }) => ({
   ...theme.typography.button,
   padding: theme.spacing(1),
   fontWeight: 'bold',
   fontSize: '20px',
   color: 'rgba(102, 102, 102, 0.4)',
 }));

const Titulo = styled('div')(({ theme }) => ({
   ...theme.typography.button,
   padding: theme.spacing(1),
   fontWeight: 'bold',
   fontSize: '20px',
   color: 'black',
 }));

export const Cuadratura: FunctionComponent<Props> = (props: Props) => {
   const [loadData,setLoadData] = useState<boolean>(false);
   const [cuadratura, setCuadratura] = useState<any>({
      id: 0,
      fecha_apertura: '',
      monto_inicial: 0,
      fecha_cierre: '',
      monto_final: 0,
      id_responsable: 0,
      id_empresa: 0,
   });

   const [snackBarConfig, setSnackBarConfig] = React.useState<any>({
      open: false,
      severity: 'error',
      message: 'Error',
      autoHideDuration: 3000,
  });

   const [ventas, setVentas] = React.useState([]);

   const [productSimple, setProductSimple] = React.useState([]);
   const [productMenu, setProductMenu] = React.useState([]);
   const [productAgrupado, setProductAgrupado] = React.useState([]);
   const [formasPago, setFormasPago] = React.useState([]);
   const [formasPagoFamilia, setFormasPagoFamilia] = React.useState([]);
   const [checkboxes, setCheckboxes] = useState(Array(formasPago.length).fill(false)); 
   const [dataAjuste, setDataAjuste] = useState<any>([])
   const [dataDescuadre, setDataDescuadre] = useState<any>([])
   const [openDrawer, setOpenDrawer] = useState<boolean>(false)
   const [gananciaTotal, setGananciaTotal] = React.useState(0);
   const [openPopup, setOpenPopup] = React.useState({
      open: false,
      type: ""
   })

   useEffect(() => {
      setLoadData(true);
      if(props.idUsuario){
         getCuadraturaByUsuario();
      }else if(props.idSala){
         getCuadraturaByRooms();
      }
   },// eslint-disable-next-line
   [])

   const getCuadraturaByUsuario = () => {
      (props.type === 'actual' ?
         cuadraturaService.getActualCuadratura(props.idUsuario):
         cuadraturaService.getUltimaCuadratura(props.idUsuario)
      ).then((res)=> {
         console.log(res)
         if (res.status === 200) {
            console.log('entre')
            setCuadratura(res.cuadratura)
         } else {
            setCuadratura({
               id: 0,
               fecha_apertura: '',
               monto_inicial: 0,
               fecha_cierre: '',
               monto_final: 0,
               id_responsable: 0,
               id_empresa: 0,
            })
         }
         setLoadData(false);
      })
   }
   const getCuadraturaByRooms = () => {
      (props.type === 'actual' ?
         cuadraturaService.getActualCuadraturaRooms(props.idSala):
         cuadraturaService.getUltimaCuadraturaRooms(props.idSala)
      ).then((res)=> {
         if (res.status === 200) {
            setCuadratura(res.cuadratura)
         } else {
            setCuadratura({
               id: 0,
               fecha_apertura: '',
               monto_inicial: 0,
               fecha_cierre: '',
               monto_final: 0,
               id_responsable: 0,
               id_empresa: 0,
            })
         }
         setLoadData(false);
      })
   }

   useEffect(() => {
      console.log(cuadratura)
      setLoadData(true)
      if (cuadratura.id) {
         console.log(cuadratura)
         let inicio = new Date(cuadratura.fecha_apertura)
         const fecha1= moment(inicio).format('DD-MM-YYYY');
         let fin = cuadratura.fecha_cierre ? new Date(cuadratura.fecha_cierre) : new Date()
         const fecha2 = moment(fin).format('DD-MM-YYYY');

         if(props.filtro=='users'){
            filtrarByFechas( props.idUsuario, cuadratura.id );
            filtrarSimplesByFechas(fecha1, fecha2, props.filtro, props.idUsuario);
            filtrarMenuByFechas(fecha1, fecha2, props.filtro, props.idUsuario);
            filtrarAgrupadoByFechas(fecha1, fecha2, props.filtro, props.idUsuario);
            montoCuadratura( props.idUsuario, cuadratura.id);
            tiposPagosTableFechas( props.idUsuario, cuadratura.id);
            ventasCuadraturaByResponsableFamiliaFormaPago( props.idUsuario, cuadratura.id);
         }

         if(props.type != 'actual'){
            getCuadraturaAjuste(cuadratura.id)
            getCuadraturaDescuadre(cuadratura.id)
         }
         
      }// eslint-disable-next-line
      setLoadData(false)
   },[cuadratura])

   const filtrarByFechas = async( id_responsable, id_cuadratura) =>{
      await ventaService.getVentasByResponsableCuadratura(id_responsable, id_cuadratura).then((data) => {
         setVentas(data);
      })
   }

   const filtrarSimplesByFechas = async(fecha1, fecha2, filtro, id_responsable) => {
      await productoService.getProductsSimplesByDFechasByResponsable(fecha1, fecha2, filtro, id_responsable).then((data)=>{
         setProductSimple(data)
      })
   }

   const filtrarMenuByFechas = async(fecha1, fecha2, filtro, id_responsable) => {
      await productoService.getProductsMenuByFechasByResponsable(fecha1, fecha2, filtro, id_responsable).then((data)=>{
         setProductMenu(data)
      })
   }

   const filtrarAgrupadoByFechas = async(fecha1, fecha2, filtro, id_responsable) => {
      await productoService.getProductsAgrupadoByFechasByResponsable(fecha1, fecha2, filtro, id_responsable).then((data)=>{
         setProductAgrupado(data)
      })
   }

   const getCuadraturaAjuste = async(id_cuadratura) => {
      await cuadraturaService.getCuadraturaAjuste(id_cuadratura).then((data)=> {
         setDataAjuste(data.detalle)
      })
   }

   const getCuadraturaDescuadre = async(id_cuadratura) => {
      await cuadraturaService.getCuadraturaDescuadre(id_cuadratura).then((data)=> {
         setDataDescuadre(data.detalle)
      })
   }

   const montoCuadratura = async( id_responsable, id_cuadratura) => {
      await cuadraturaService.montoCuadratura( id_responsable, id_cuadratura).then((data)=>{
         console.log(data)
         setGananciaTotal(data)
      })
   }

   const tiposPagosTableFechas = async( id_responsable, id_cuadratura) => {
      const token = readLocalStorage(TOKEN_API);
      await ventaService.tiposPagosTableFechasByResponsable( id_responsable, id_cuadratura, token).then((data)=>{
         setFormasPago(data)
      })
   }

   const ventasCuadraturaByResponsableFamiliaFormaPago = async( id_responsable, id_cuadratura) => {
      const token = readLocalStorage(TOKEN_API);
      await ventaService.ventasCuadraturaByResponsableFamiliaFormaPago( id_responsable, id_cuadratura, token).then((data)=>{
         setFormasPagoFamilia(data)
      })
   }

   const handleCloseCuadratura = (total_ajuste, total_cierre_efectivo, detalleMov, detalleDescuadre,
      monto_final_real, total_descuadre, total_descuadre_efectivo, total_ventas, total_ventas_efectivo ) => {
      setLoadData(true);
     
      console.log(ventas);
      let idsVentas = ventas.map(venta => venta.id);
      let idsVentasJSON = JSON.stringify(idsVentas);

      const detalleMovimientos = detalleMov.map(item => ({
         banco: item.banco.name,
         monto: item.monto,
         tipo_movimiento: item.tipoMovimiento
       }));
           
      cuadraturaService.cerrarCuadratura(cuadratura.id, (cuadratura?.monto_inicial || 0)+gananciaTotal, 
      idsVentasJSON, total_ajuste, total_cierre_efectivo, JSON.stringify(detalleMovimientos), JSON.stringify(detalleDescuadre),
      monto_final_real, total_descuadre, total_descuadre_efectivo, total_ventas, total_ventas_efectivo).then(res => {
         if(res.status == 401) {
            setSnackBarConfig({...snackBarConfig, open:true, message: res.message})
         }
         setOpenPopup({
            open: false,
            type: '',
         })
         setOpenDrawer(false)
         setLoadData(false);
         setVentas([]);
         setProductSimple([]);
         setProductMenu([]);
         setProductAgrupado([]);
         setGananciaTotal(0);
         setFormasPago([]);
         setFormasPagoFamilia([]);
         if(props.filtro=='users') getCuadraturaByUsuario();
         if(props.filtro=='rooms') getCuadraturaByRooms();
      })
   }

   const handleOpenCuadratura = (responsable_id: number, monto:number, id_caja) => {
      setLoadData(true);
      cuadraturaService.abrirCuadratura(responsable_id, props.filtro, monto, id_caja).then(res => {
         if(props.filtro=='users') getCuadraturaByUsuario();
         if(props.filtro=='rooms') getCuadraturaByRooms();
         
         setOpenPopup({
            open: false,
            type: '',
         })
         setLoadData(false);
      })
   }

   const handleClosePopup = (open: boolean) => {
      setOpenPopup({
         open: open,
         type: '',
      })
   }

   const handleOpenDialog = () => {
      if (cuadratura?.fecha_apertura) {
         openCerrarCuadratura()
      } else {
         setOpenPopup({open: true, type: "open"})
      }
   }

   const openCerrarCuadratura = () => {
      const validate = checkboxes.every(checkbox => checkbox);
       
      if(!!!validate){
         setSnackBarConfig(prev => ({
            ...prev,
            open: true,
            severity: 'warning',
            message: `Debe confirmar los totales.`,
          }));
          return
      }
      
       
      setOpenDrawer(true)
   }

   return(
      <>
      {loadData && <Loading/>}
      <Snackbar
         open={snackBarConfig.open}
         autoHideDuration={snackBarConfig.autoHideDuration}
         onClose={() => setSnackBarConfig(prev => ({ ...prev, open: false }))}
         anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      >
         <Alert
            onClose={() => setSnackBarConfig(prev => ({ ...prev, open: false }))}
            severity={snackBarConfig.severity}
            variant="filled"
         >
            {snackBarConfig.message}
         </Alert>
      </Snackbar>
      <Box sx={{ marginLeft:'10px',marginRight:'10px', marginBottom:'20px'}}>
         <Grid container>
         {
               props.type === "actual" &&
               <Grid item xs={6} style={{display: "flex", alignItems: "center", marginTop: "20px"}}>
                  <Button variant='contained' color={cuadratura?.fecha_apertura? "error" : "success"} size="small"  style={{textTransform: "capitalize"}}
                     onClick={handleOpenDialog}
                  >{cuadratura?.fecha_apertura? 'Cerrar cuadratura':'Abrir cuadratura'}</Button>
               </Grid>
            }
        
         </Grid>
         <Grid container>
            <Grid item xs={6} style={{display: "flex", alignItems: "center", marginTop: "20px"}}>
               <span style={{width: "200px", fontSize: "18px"}}><b>Responsable:</b> </span>
               <span style={{width: "200px", fontSize: "18px"}}>{cuadratura?.responsable?.nombre || '---'}</span>
            </Grid>
            <Grid item xs={6} style={{display: "flex", alignItems: "center", marginTop: "20px"}}>
               <span style={{width: "200px", fontSize: "18px"}}><b>Caja:</b> </span>
               <span style={{width: "200px", fontSize: "18px"}}>{cuadratura?.caja?.nombre || '---'}</span>
            </Grid>
         </Grid>
         <Grid container>
            <Grid item xs={6} style={{display: "flex", alignItems: "center", marginTop: "20px"}}>
               <span style={{width: "200px", fontSize: "18px"}}><b>Fecha de inicio:</b> </span>
               <span style={{width: "200px", fontSize: "18px"}}>{cuadratura?.fecha_apertura}</span>
            </Grid>
            <Grid item xs={6} style={{display: "flex", alignItems: "center", marginTop: "20px"}}>
               <span style={{width: "200px", fontSize: "18px"}}><b>Monto Inicial:</b> </span>
               <span style={{width: "200px", fontSize: "18px"}}>{moneyFormatInt(cuadratura?.monto_inicial)}</span>
            </Grid>
         </Grid>
         <Grid container>
            <Grid item xs={6} style={{display: "flex", alignItems: "center", marginTop: "20px"}}>
               <span style={{width: "200px", fontSize: "18px"}}><b>Fecha de cierre:</b> </span>
               <span style={{width: "200px", fontSize: "18px"}}>{cuadratura?.fecha_cierre || '---'}</span>
            </Grid>
            <Grid item xs={6} style={{display: "flex", alignItems: "center", marginTop: "20px"}}>
               <span style={{width: "200px", fontSize: "18px"}}><b>Monto final sistema:</b> </span>
               <span style={{width: "200px", fontSize: "18px"}}> {props.type === "actual" ? (moneyFormatInt((cuadratura?.monto_inicial || 0)+gananciaTotal)) : moneyFormatInt(cuadratura?.monto_final)}</span>
            </Grid>
         </Grid>
         {
            props.type != 'actual' &&
            <>
               <Grid container>
                  <Grid item xs={6} style={{ display: "flex", alignItems: "center", marginTop: "20px" }}>
                     <span style={{ width: "200px", fontSize: "18px" }}><b>Total ventas:</b> </span>
                     <span style={{ width: "200px", fontSize: "18px" }}> {moneyFormatInt(cuadratura?.total_ventas || 0) || '---'}</span>
                  </Grid>
                  <Grid item xs={6} style={{ display: "flex", alignItems: "center", marginTop: "20px" }}>
                     <span style={{ width: "200px", fontSize: "18px" }}><b>Monto final cierre:</b> </span>
                     <span style={{ width: "200px", fontSize: "18px" }}>{moneyFormatInt(cuadratura?.monto_final_real || 0) || '---'}</span>
                  </Grid>
               </Grid>
               <Grid container>
                  <Grid item xs={6} style={{ display: "flex", alignItems: "center", marginTop: "20px" }}>
                     <span style={{ width: "200px", fontSize: "18px" }}><b>Total ventas (EFECTIVO):</b> </span>
                     <span style={{ width: "200px", fontSize: "18px" }}> {moneyFormatInt(cuadratura?.total_ventas_efectivo || 0) || '---'}</span>
                  </Grid>
                  <Grid item xs={6} style={{ display: "flex", alignItems: "center", marginTop: "20px" }}>
                     <span style={{ width: "200px", fontSize: "18px" }}><b>Total movimientos (EFECTIVO):</b> </span>
                     <span style={{ width: "200px", fontSize: "18px" }}>{moneyFormatInt(cuadratura?.total_ajuste || 0) || '---'}</span>
                  </Grid>
               </Grid>
               <Grid container>
                  <Grid item xs={6} style={{ display: "flex", alignItems: "center", marginTop: "20px" }}>
                     <span style={{ width: "200px", fontSize: "18px" }}><b>Total descuadre (EFECTIVO):</b> </span>
                     <span style={{ width: "200px", fontSize: "18px" }}> {moneyFormatInt(cuadratura?.total_descuadre_efectivo || 0) || '---'}</span>
                  </Grid>
                  <Grid item xs={6} style={{ display: "flex", alignItems: "center", marginTop: "20px" }}>
                     <span style={{ width: "200px", fontSize: "18px" }}><b>Efectivo final:</b> </span>
                     <span style={{ width: "200px", fontSize: "18px" }}>{moneyFormatInt(cuadratura?.total_cierre_efectivo || 0) || '---'}</span>
                  </Grid>
               </Grid>
            </>
         }

         <Divider sx={{ mt: 4, mb:2 }} style={{width:"100%"}}/>

            <Grid container spacing={0} alignItems="center" justifyContent="center" style={{ marginBottom: '20px', marginTop:'30px', textAlign:'center' }}>
               {/* <Grid item xs={9}>
                  <Titulo>{"Efectivo"}</Titulo>
                  {
                        !loadData && (
                           <Box sx={{backgroundColor: 'rgba(224, 224, 224, 0.5)', padding: '15px', borderRadius: '10px', marginRight: '3px'}}>
                              <Billetes/>
                           </Box>
                        )
                     }

               </Grid> */}

               <Grid item xs={9}>
                     <Titulo>{"Formas de pago agrupados por familia"}</Titulo>
                     <Grid item xs={6} style={{ display: "flex", alignItems: "center", marginTop: "20px", marginBottom: "20px" }}>
                        <span style={{ width: "200px", fontSize: "18px" }}><b>Total Ventas:</b> </span>
                        <span style={{ width: "200px", fontSize: "18px" }}> {(moneyFormatInt(gananciaTotal))}</span>
                     </Grid>
                     {
                        !loadData && formasPagoFamilia.length > 0 && (
                           <Box sx={{backgroundColor: 'rgba(224, 224, 224, 0.5)', padding: '15px', borderRadius: '10px', marginRight: '3px'}}>
                              <TiposPagosFamilia formasPago={formasPagoFamilia} checkboxes={checkboxes} setCheckboxes={setCheckboxes}/>
                           </Box>
                        )
                     }
                     {
                        !loadData && formasPagoFamilia.length === 0 && (
                           <Box sx={{backgroundColor: 'rgba(224, 224, 224, 0.5)', padding: '15px', borderRadius: '10px', height:'100px', justifyContent: 'center',alignItems: 'center', display: 'flex',  marginRight: '3px'}} >
                              <Div>{"No se encontraron ventas"}</Div>
                        </Box>
                        )
                     }
               </Grid>
               <Grid item xs={9}>
                     <Titulo>{"Formas de Pago por Submodulos"}</Titulo>
                     <Grid item xs={6} style={{ display: "flex", alignItems: "center", marginTop: "20px", marginBottom: "20px" }}>
                        <span style={{ width: "200px", fontSize: "18px" }}><b>Total Ventas:</b> </span>
                        <span style={{ width: "200px", fontSize: "18px" }}> {(moneyFormatInt(gananciaTotal))}</span>
                     </Grid>
                     {
                        !loadData && formasPago.length > 0 && (
                           <Box sx={{backgroundColor: 'rgba(224, 224, 224, 0.5)', padding: '15px', borderRadius: '10px', marginRight: '3px'}}>
                              <TiposPagos formasPago={formasPago} checkboxes={checkboxes} setCheckboxes={setCheckboxes}/>
                           </Box>
                        )
                     }
                     {
                        !loadData && formasPago.length === 0 && (
                           <Box sx={{backgroundColor: 'rgba(224, 224, 224, 0.5)', padding: '15px', borderRadius: '10px', height:'100px', justifyContent: 'center',alignItems: 'center', display: 'flex',  marginRight: '3px'}} >
                              <Div>{"No se encontraron ventas"}</Div>
                        </Box>
                        )
                     }
               </Grid>


               {
                  props.type == 'anterior' &&
                  <>
                   <Grid item xs={9}>
                     <Titulo>{"Validación de totales"}</Titulo>
                     {
                        !loadData && dataDescuadre.length > 0 && (
                           <Box sx={{backgroundColor: 'rgba(224, 224, 224, 0.5)', padding: '15px', borderRadius: '10px', marginRight: '3px'}}>
                              <DetalleDescuadre detalle={dataDescuadre}/>
                           </Box>
                        )
                     }
                     {
                        !loadData && dataDescuadre.length === 0 && (
                           <Box sx={{backgroundColor: 'rgba(224, 224, 224, 0.5)', padding: '15px', borderRadius: '10px', height:'100px', justifyContent: 'center',alignItems: 'center', display: 'flex',  marginRight: '3px'}} >
                              <Div>{"No se encontraron registros"}</Div>
                        </Box>
                        )
                     }
                  </Grid>
                  <Grid item xs={9}>
                     <Titulo>{"Ajustes de Ingresos/Egresos"}</Titulo>
                     {
                        !loadData && dataAjuste.length > 0 && (
                           <Box sx={{backgroundColor: 'rgba(224, 224, 224, 0.5)', padding: '15px', borderRadius: '10px', marginRight: '3px'}}>
                              <AjustesMovimientos ajustes={dataAjuste}/>
                           </Box>
                        )
                     }
                     {
                        !loadData && dataAjuste.length === 0 && (
                           <Box sx={{backgroundColor: 'rgba(224, 224, 224, 0.5)', padding: '15px', borderRadius: '10px', height:'100px', justifyContent: 'center',alignItems: 'center', display: 'flex',  marginRight: '3px'}} >
                              <Div>{"No se encontraron registros"}</Div>
                        </Box>
                        )
                     }
                  </Grid>
                  </>
                  
               }

              
               <Grid item xs={9}>
                  <Titulo>{"Ventas Realizadas"}</Titulo>

                  {
                     !loadData && ventas.length > 0 && (
                        <Box sx={{ backgroundColor: 'rgba(224, 224, 224, 0.5)', padding: '15px', borderRadius: '10px' }}>
                           <ReporteVentas ventas={ventas} />
                        </Box>
                     )
                  }
                  {
                     !loadData && ventas.length === 0 && (
                        <Box sx={{ backgroundColor: 'rgba(224, 224, 224, 0.5)', padding: '15px', borderRadius: '10px', height: '100px', justifyContent: 'center', alignItems: 'center', display: 'flex' }} >
                           <Div>{"No se encontraron ventas"}</Div>
                        </Box>
                     )
                  }
               </Grid>
               


               {/* <Grid item xs={6}>
                     <Titulo>{"Productos Simples Vendido"}</Titulo>
                     {
                        !loadData && productSimple.length > 0 && (
                           <Box sx={{backgroundColor: 'rgba(224, 224, 224, 0.5)', padding: '15px', borderRadius: '10px', marginRight: '3px'}}>
                              <ProductosSimples productSimple={productSimple}/>
                           </Box>
                        )
                     }
                     {
                        !loadData && productSimple.length === 0 && (
                           <Box sx={{backgroundColor: 'rgba(224, 224, 224, 0.5)', padding: '15px', borderRadius: '10px', height:'100px', justifyContent: 'center',alignItems: 'center', display: 'flex',  marginRight: '3px'}} >
                              <Div>{"No se encontraron ventas"}</Div>
                        </Box>
                        )
                     }
               </Grid> */}
               {/* <Grid item xs={6}>
                     <Titulo>{"Productos Menu Vendido"}</Titulo>
                     {
                        !loadData && productMenu.length > 0 && (
                           <Box sx={{backgroundColor: 'rgba(224, 224, 224, 0.5)', padding: '15px', borderRadius: '10px', marginRight: '3px'}}>
                              <ProductosMenu productMenu={productMenu}/>
                           </Box>
                        )
                     }
                     {
                        !loadData && productMenu.length === 0 && (
                           <Box sx={{backgroundColor: 'rgba(224, 224, 224, 0.5)', padding: '15px', borderRadius: '10px', height:'100px', justifyContent: 'center',alignItems: 'center', display: 'flex', marginRight: '3px'}} >
                              <Div>{"No se encontraron ventas"}</Div>
                        </Box>
                        )
                     }
               </Grid> */}
               {/* <Grid item xs={6}>
                     <Titulo>{"Productos Agrupado Vendido"}</Titulo>
                     {
                        !loadData && productAgrupado.length > 0 && (
                           <Box sx={{backgroundColor: 'rgba(224, 224, 224, 0.5)', padding: '15px', borderRadius: '10px'}}>
                              <ProductosAgrupados productAgrupado={productAgrupado}/>
                           </Box>
                        )
                     }
                     {
                        !loadData && productAgrupado.length === 0 && (
                           <Box sx={{backgroundColor: 'rgba(224, 224, 224, 0.5)', padding: '15px', borderRadius: '10px', height:'100px', justifyContent: 'center',alignItems: 'center', display: 'flex'}} >
                              <Div>{"No se encontraron ventas"}</Div>
                           </Box>
                        )
                     }
               </Grid> */}
            </Grid>

            <CierreCuadratura  openDrawer={openDrawer} setOpenDrawer={setOpenDrawer} cuadratura={cuadratura} formasPago={formasPago} gananciaTotal={gananciaTotal} handleCloseCuadratura={handleCloseCuadratura} type={props.type}/>

            { props.idUsuario && openPopup.type === 'open' &&
               <OpenCuadratura
                  openPopup={openPopup.open}
                  title="Abrir cuadratura"
                  setOpenPopup={handleClosePopup}
                  onConfirm={handleOpenCuadratura}
                  idUsuario={props.idUsuario}
                  itemSelected={props.itemSelected}
                  filtro={props.filtro}
               />
            }
            { props.idSala && openPopup.type === 'open' &&
               <OpenCuadratura
                  openPopup={openPopup.open}
                  title="Abrir cuadratura"
                  setOpenPopup={handleClosePopup}
                  onConfirm={handleOpenCuadratura}
                  idSala={props.idSala}
                  itemSelected={props.itemSelected}
                  filtro={props.filtro}
               />
            }

            {/* { openPopup.type === 'close' &&
               <CloseCuadratura
                  openPopup={openPopup.open}
                  title="Cerrar cuadratura"
                  setOpenPopup={handleClosePopup}
                  onConfirm={handleCloseCuadratura}
                  closeModal={handleClosePopup}
                  idUsuario={props.idUsuario}
               />
            } */}
      </Box>
      </>
   )
}
