import { BeneficioProductoRepository } from "../repositories/BeneficioProducto.repository";

export const beneficioProductoService = {
    getBeneficioProducto,
    register,
    update,
    deleteBeneficio
}

async function getBeneficioProducto(){
    const res = await BeneficioProductoRepository.getBeneficioProducto();
    return res;
}

async function register(data:{}){
    const res = await BeneficioProductoRepository.register(data);
    return res;
}

async function update(data:{}, idbeneficioProduct:number){
    const res = await BeneficioProductoRepository.update(data, idbeneficioProduct);
    return res;
}

async function deleteBeneficio(idbeneficioProduct:number){
    const res = await BeneficioProductoRepository.deleteBeneficio(idbeneficioProduct);
    return res;
}