import { editor } from "@/service/services/Editor.service";
import React, { useState, useEffect } from "react";
import "./../AddCategoria/AddCategoria.sass";
import { Loading } from '@/components/common/Loading/Loading';
import { Form, Formik } from 'formik';
import { Button, Divider, Grid, MenuItem, Select, TextField, Tooltip, Typography } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import { Popup } from "../Popup";
import { ProductsNoInsumo } from "../ProductsNoInsumo";
import { distribucionService } from "@/service/services/Distribucion.service";
import { removeLocalStorage } from "@/toolbox/helpers/local-storage-helper";
import PriorityHighIcon from '@mui/icons-material/PriorityHigh';
import { DatePicker, LocalizationProvider } from "@mui/lab";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import esLocale from 'date-fns/locale/es';
import moment from "moment";
import { productoService } from "@/service/services/Producto.service";
import { TipoImpuestoService } from "@/service/services/TipoImpuesto.service";

export const EditProducto: React.FC<any> = ({ idProducto, handleLoad, closeModal }): JSX.Element => {

    const [loadData, setLoadData] = React.useState<boolean>(false);
    const [data, setData] = useState({
        codigo_erp: '',
        nombre: '',
        precio_standar: '',
        precio_oferta: '',
        is_supply: '',
        stock_minimo: 0,
        familia_id: 0,
        linea_id: 0,
        idtipo_impuesto: 0
    });
    const [isSupply, setIsSupply] = React.useState(false);
    const [ProductsSelect, setProductsSelect] = React.useState([]);
    const [openPopup, setOpenPopup] = React.useState<boolean>(false);
    const [UM, setUM] = React.useState<any>('');
    const [dateCaducidad, setDateCaducidad] = React.useState<any>(new Date());
    const [familias, setFamilia] = React.useState<Array<any>>([]);
    const [familiasSelected, setFamiliasSelected] = React.useState<any>({
        id: 0,
        nombre: "Familia",
        estado: 0,
        subFamilia: [],
    });
    const [lineas, setLineas] = React.useState<Array<any>>([]);
    const [tiposImpuesto, setTiposImpuesto] = useState<any>([])

    useEffect(() => {
        getFamiliesAndSubFamilies()
        getLinesProducts()
        getTiposImpuestoApi()
        if (idProducto && idProducto >= 0) {
            setLoadData(true);
            editor.getProductoById(idProducto).then((data) => {
                setLoadData(false);
                if (data && data.data && data.data.productos) {
                    setIsSupply(data.data.productos[0]?.is_supply && data.data.productos[0]?.is_supply === 1 ? true : false);
                    setData({
                        codigo_erp: data.data.productos[0]?.codigo_erp || '',
                        nombre: data.data.productos[0]?.nombre || '',
                        precio_standar: data.data.productos[0]?.precio_standar || '',
                        precio_oferta: data.data.productos[0]?.precio_oferta || '',
                        is_supply: data.data.productos[0]?.is_supply || '',
                        stock_minimo: data.data.productos[0]?.stock_minimo || 0,
                        familia_id: data.data.productos[0]?.familia_id || 0,
                        linea_id: data.data.productos[0]?.linea_id || 0,
                        idtipo_impuesto: data.data.productos[0]?.idtipo_impuesto || 0
                    })
                    let date = data.data.productos[0]?.caducidad_precio_oferta;
                    if (date) {
                        let format = date.split("-").reverse().join("/");
                        let fecha = new Date(format);
                        setDateCaducidad(fecha || '');
                    }
                }
            });

            distribucionService.getDistribucionByInsumo(idProducto).then((data) => {
                let productsData = [];
                if (data) {
                    for (let i = 0; i < data.length; i++) {
                        productsData.push({
                            cantidad: data[i].cantidad,
                            productos_x_cantidad: data[i].productos_x_cantidad,
                            id_distribucion: data[i].id,
                            id_insumo: data[i].id_insumo,
                            id_producto: data[i].id_producto,
                            productos: data[i].productos,
                            id_unidad_medida: data[i].unidades_medida,
                        });
                    }
                }
                setProductsSelect(productsData);
            })
        }
    }, // eslint-disable-next-line
        []);

    useEffect(() => {
        const array = localStorage.getItem('productsArray');
        setProductsSelect(JSON.parse(array));

        const uMedida = localStorage.getItem('UM');
        setUM(JSON.parse(uMedida));
        // eslint-disable-next-line
    }, [openPopup]);

    const getTiposImpuestoApi = async () => {
        const response = await TipoImpuestoService.getTiposImpuesto()
        if (response.data?.length) {
            setTiposImpuesto(response.data)
        }
    }

    const handleEditarDistribucion = () => {
        let products = [];
        if (ProductsSelect) {
            for (let i = 0; i < ProductsSelect.length; i++) {
                products.push({
                    id_distribucion: ProductsSelect[i].id_distribucion || null,
                    cantidad: ProductsSelect[i].cantidad,
                    productos_x_cantidad: ProductsSelect[i].productos_x_cantidad,
                    id_unidad_medida: ProductsSelect[i].id_unidad_medida?.id || ProductsSelect[i].id_unidad_medida,
                    id_insumo: ProductsSelect[i].id_insumo || null,
                    id_producto: ProductsSelect[i].id_producto || ProductsSelect[i].id,
                    codigo_erp: ProductsSelect[i].productos?.codigo_erp || ProductsSelect[i].codigo_erp,
                    nombre: ProductsSelect[i].productos?.nombre || ProductsSelect[i].nombre,
                    abbreviation: ProductsSelect[i].id_unidad_medida?.abbreviation || ProductsSelect[i].abbreviation,
                });
            }
        }
        const UM = {
            id: products[0]?.id_unidad_medida || '',
            abbreviation: products[0]?.abbreviation || '',
        }
        localStorage.setItem('productsArray', JSON.stringify(products));
        localStorage.setItem('UM', JSON.stringify(UM));
        setOpenPopup(true);
    }

    const validateForm = (values) => {
        let errors: any = {};

        if (!values.codigo_erp) errors.codigo_erp = "codigo requerido";
        if (!values.nombre) errors.nombre = "nombre requerido";
        if (!values.precio_standar) errors.precio_standar = "precio requerido";
        if (values.precio_standar && !(Number.isInteger(Number(values.precio_standar)))) errors.precio_standar = "no se permiten decimal";
        if (values.precio_oferta && !(Number.isInteger(Number(values.precio_oferta)))) errors.precio_oferta = "no se permiten decimal";
        if (values.stock_minimo < 0) errors.stock_minimo = "Stock minimo debe ser mayor o igual a 0";

        return errors;
    }

    const onSubmit = async (values, { resetForm }) => {
        setLoadData(true);

        let precio_caducidad;
        if (values.precio_oferta !== "") {
            precio_caducidad = moment(dateCaducidad).format('DD-MM-YYYY');
        } else {
            precio_caducidad = null;
        }

        await editor.editarProducto(idProducto, {
            nombre: values.nombre,
            precio_standar: values.precio_standar,
            precio_oferta: values.precio_oferta || null,
            caducidad_precio_oferta: precio_caducidad || null,
            is_supply: isSupply,
            stock_minimo: values.stock_minimo,
            familia_id: values.familia_id,
            linea_id: values.linea_id,
            idtipo_impuesto: values.idtipo_impuesto
        }).then((data) => {
            setLoadData(false);
            if (data && data.data && data.data.update) {
                //window.location.reload();
                handleLoad && handleLoad();
                closeModal && closeModal();
                removeLocalStorage('productsArray');
                removeLocalStorage('UM');
            }
        })
    }

    const handleChangeFamilia = (id) => {
        setFamiliasSelected({
            id: 0,
            nombre: "",
            subFamilia: [],
            estado: 0,
        })
        let familia = familias.find((f: any) => f.id === id);
        if (familia) {
            setFamiliasSelected(familia)
        }
    }

    const getFamiliesAndSubFamilies = async () => {
        await productoService.getFamiliesAndSubFamilies().then((data) => {
            if (data.status === 200) {
                setFamilia(data.families || []);
            } else {
                return
            }
        })
    }

    const getLinesProducts = async () => {
        await productoService.getLinesProducts().then((data) => {
            if (data.status === 200) {
                setLineas(data.lines || []);
            } else {
                return
            }
        });
    }

    return (
        <div className="container">
            {loadData && <Loading />}

            <Formik enableReinitialize initialValues={data} validate={(values) => validateForm(values)} onSubmit={onSubmit}>
                {({ values, errors, touched, handleSubmit, handleChange, setFieldValue }) => {
                    return (
                        <Form onSubmit={handleSubmit} style={{ width: '100%' }}>
                            <Grid container spacing={3} alignItems="center" justifyContent="center">

                                <Grid item xs={12} container style={{ display: "flex", alignItems: "center" }}>
                                    <Grid item xs={4}>
                                        <Typography variant="subtitle1" className="custom-input"><b>Codigo:</b></Typography>
                                    </Grid>
                                    <Grid item xs={8} style={{ display: 'flex', alignItems: "center" }}>
                                        {
                                            errors.codigo_erp && (touched.codigo_erp ? true : false) && (
                                                <Tooltip title={errors.codigo_erp}>
                                                    <PriorityHighIcon className={'iconWarning'} />
                                                </Tooltip>
                                            )
                                        }
                                        <TextField
                                            disabled={true}
                                            id="codigo_erp"
                                            type="text"
                                            name="codigo_erp"
                                            fullWidth
                                            value={values.codigo_erp}
                                            onChange={handleChange}
                                            placeholder="codigo de producto"
                                            error={errors.codigo_erp && touched.codigo_erp ? true : false}
                                        />
                                    </Grid>
                                </Grid>

                                <Grid item xs={12} container style={{ display: "flex", alignItems: "center" }}>
                                    <Grid item xs={4}>
                                        <Typography variant="subtitle1" className="custom-input"><b>Nombre:</b></Typography>
                                    </Grid>
                                    <Grid item xs={8} style={{ display: 'flex', alignItems: "center" }}>
                                        {
                                            errors.nombre && (touched.nombre ? true : false) && (
                                                <Tooltip title={errors.nombre}>
                                                    <PriorityHighIcon className={'iconWarning'} />
                                                </Tooltip>
                                            )
                                        }
                                        <TextField
                                            id="nombre"
                                            type="text"
                                            name="nombre"
                                            fullWidth
                                            value={values.nombre}
                                            onChange={handleChange}
                                            placeholder="Nombre de la sala"
                                            error={errors.nombre && touched.nombre ? true : false}
                                        />
                                    </Grid>
                                </Grid>

                                <Grid item xs={12} container style={{ display: "flex", alignItems: "center" }}>
                                    <Grid item xs={4}>
                                        <Typography variant="subtitle1" className="custom-input"><b>Precio:</b></Typography>
                                    </Grid>
                                    <Grid item xs={8} style={{ display: 'flex', alignItems: "center" }}>
                                        {
                                            errors.precio_standar && (touched.precio_standar ? true : false) && (
                                                <Tooltip title={errors.precio_standar}>
                                                    <PriorityHighIcon className={'iconWarning'} />
                                                </Tooltip>
                                            )
                                        }
                                        <TextField
                                            id="precio_standar"
                                            type="text"
                                            name="precio_standar"
                                            fullWidth
                                            value={values.precio_standar}
                                            onChange={handleChange}
                                            placeholder="Precio"
                                            error={errors.precio_standar && touched.precio_standar ? true : false}
                                        />
                                    </Grid>
                                </Grid>

                                <Grid item xs={12} container style={{ display: "flex", alignItems: "center" }}>
                                    <Grid item xs={4}>
                                        <Typography variant="subtitle1" className="custom-input"><b>Precio oferta:</b></Typography>
                                    </Grid>
                                    <Grid item xs={8} style={{ display: 'flex', alignItems: "center" }}>
                                        {
                                            errors.precio_oferta && (touched.precio_oferta ? true : false) && (
                                                <Tooltip title={errors.precio_oferta}>
                                                    <PriorityHighIcon className={'iconWarning'} />
                                                </Tooltip>
                                            )
                                        }
                                        <TextField
                                            id="precio_oferta"
                                            type="text"
                                            name="precio_oferta"
                                            fullWidth
                                            value={values.precio_oferta}
                                            onChange={handleChange}
                                            placeholder="precio_oferta"
                                            error={errors.precio_oferta && touched.precio_oferta ? true : false}
                                        />
                                    </Grid>
                                </Grid>

                                {
                                    values.precio_oferta !== "" && (
                                        <Grid item xs={12} container style={{ display: "flex", alignItems: "center" }}>
                                            <Grid item xs={4}>
                                                <Typography variant="subtitle1" className="custom-input"><b>Caducidad precio oferta:</b></Typography>
                                            </Grid>
                                            <Grid item xs={8} style={{ display: 'flex', alignItems: "center" }}>
                                                <LocalizationProvider dateAdapter={AdapterDateFns} locale={esLocale} style={{ width: '300px' }}>
                                                    <DatePicker
                                                        views={['year', 'month', 'day']}
                                                        minDate={new Date('2022-01-01')}
                                                        maxDate={new Date('2025-01-01')}
                                                        value={dateCaducidad}
                                                        onChange={(newValue) => {
                                                            setDateCaducidad(newValue);
                                                        }}
                                                        renderInput={(params) => <TextField {...params} helperText={null} />}
                                                    />
                                                </LocalizationProvider>
                                            </Grid>
                                        </Grid>
                                    )
                                }

                                <Grid item xs={12} container style={{ display: "flex", alignItems: "center" }}>
                                    <Grid item xs={4}>
                                        <Typography variant="subtitle1" className="custom-input"><b>Stock Minimo:</b></Typography>
                                    </Grid>
                                    <Grid item xs={8}>
                                        <TextField
                                            id="stock_minimo"
                                            type="number"
                                            name="stock_minimo"
                                            fullWidth
                                            value={values.stock_minimo}
                                            onChange={handleChange}
                                            placeholder="Ingrese el stock minimo del producto"
                                            error={errors.stock_minimo && touched.stock_minimo ? true : false}
                                        />
                                    </Grid>
                                </Grid>

                                <Grid container item xs={12} style={{ display: "flex", alignItems: "center" }}>
                                    <Grid item xs={4}>
                                        <Typography variant="subtitle1"><b>Familia:</b> </Typography>
                                    </Grid>
                                    <Grid item xs={8} style={{ display: 'flex', alignItems: "center", width: "230px" }}>
                                        <Select
                                            id="familia_id"
                                            value={values.familia_id}
                                            onChange={(event) => {
                                                setFieldValue("familia_id", event.target.value);
                                                handleChangeFamilia(event.target.value);
                                            }}
                                            /* error={ errors.rol && touched.rol ? true : false } */
                                            fullWidth
                                        >
                                            {
                                                (familias || []).map((item, i) => {
                                                    return <MenuItem key={i} value={item.id}>{item.nombre}</MenuItem>;
                                                })
                                            }
                                        </Select>
                                    </Grid>
                                </Grid>

                                <Grid container item xs={12} style={{ display: "flex", alignItems: "center" }}>
                                    <Grid item xs={4}>
                                        <Typography variant="subtitle1"><b>Linea de producto:</b> </Typography>
                                    </Grid>
                                    <Grid item xs={8} style={{ display: 'flex', alignItems: "center", width: "230px" }}>
                                        <Select
                                            id="linea_id"
                                            value={values.linea_id}
                                            onChange={(event) => {
                                                setFieldValue("linea_id", event.target.value);
                                            }}
                                            /* error={ errors.rol && touched.rol ? true : false } */
                                            fullWidth
                                        >
                                            {
                                                (lineas || []).map((item, i) => {
                                                    return <MenuItem key={i} value={item.id}>{item.nombre}</MenuItem>;
                                                })
                                            }
                                        </Select>
                                    </Grid>
                                </Grid>

                                <Grid container item xs={12} style={{ display: "flex", alignItems: "center" }}>
                                    <Grid item xs={4}>
                                        <Typography variant="subtitle1"><b>Tipo de impuesto:</b> </Typography>
                                    </Grid>
                                    <Grid item xs={8} style={{ display: 'flex', alignItems: "center", width: "230px" }}>
                                        <Select
                                            id="idtipo_impuesto"
                                            value={values.idtipo_impuesto}
                                            onChange={(event) => {
                                                setFieldValue("idtipo_impuesto", event.target.value);
                                            }}
                                            /* error={ errors.rol && touched.rol ? true : false } */
                                            fullWidth
                                            // disabled={!editMode}
                                        >
                                            {
                                                (tiposImpuesto || []).map((item, i) => {
                                                    return <MenuItem key={i} value={item.id}>{item.nombre}</MenuItem>;
                                                })
                                            }
                                        </Select>
                                    </Grid>
                                </Grid>

                                {/* {
                                window.location.pathname === '/productos-insumo' && (
                                    <Grid item xs={12} container style={{display: "flex", alignItems: "center"}}>
                                        <Grid item xs={4} className="custom-input">
                                            <Typography variant="subtitle1" className="custom-input"><b>Productos que lo contienen: </b></Typography>
                                        </Grid>
                                        <Grid item xs={8}>
                                            <Checkbox
                                                id="is_supply"
                                                name="is_supply"
                                                checked={checked}
                                                onChange={(event) => {
                                                    setChecked(event.target.checked)
                                                }}
                                            />
                                        </Grid>
                                    </Grid>
                                )
                            } */}

                            </Grid>
                            <Divider sx={{ my: 2 }} />

                            {
                                // isSupply === true && (
                                //     <>
                                //         <Grid item sm={12} container direction='row'>
                                //             <Grid xs={12} sx={{ minHeight: '35vh', maxHeight: '35vh', overflowY: 'scroll', padding: '10px' }}>

                                //                 <Grid sx={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                                //                     <div className="H-card__title" style={{ paddingLeft: '10px', paddingTop: '10px' }}>
                                //                         {`Productos que contienen el Insumo`}
                                //                     </div>
                                //                     <Button aria-label="add to shopping cart" sx={{ marginRight: '5px', color: "green", cursor: "pointer" }} onClick={() => {
                                //                         handleEditarDistribucion();
                                //                     }}>
                                //                         <EditIcon />
                                //                     </Button>
                                //                 </Grid>

                                //                 <div className="H-card__separator"></div>
                                //                 <div className="H-card__list">
                                //                     {
                                //                         ProductsSelect && ProductsSelect.map((product, index) => (
                                //                             <div className="H-card__list__item" key={index}>
                                //                                 <div className="content-info">
                                //                                     <div className="info">
                                //                                         <span className="name">
                                //                                             {
                                //                                                 (product.productos?.codigo_erp) || (product.codigo_erp)
                                //                                             }
                                //                                         </span>
                                //                                     </div>
                                //                                     <div className="info">
                                //                                         <span className="name">
                                //                                             {
                                //                                                 (product.productos?.nombre.charAt(0).toUpperCase() + product.productos?.nombre.slice(1).toLowerCase()) || (product.nombre.charAt(0).toUpperCase() + product.nombre.slice(1).toLowerCase())

                                //                                             }
                                //                                         </span>
                                //                                     </div>
                                //                                     <div className="info">
                                //                                         Cantidad:
                                //                                         <span className="name" style={{ fontSize: "17px", marginLeft: "3px" }}>
                                //                                             {product.cantidad} <span><b>{product.id_unidad_medida?.abbreviation || (UM?.abbreviation ? UM?.abbreviation : product?.abbreviation)}.</b></span>{/* <span><b>{product.id_unidad_medida?.abbreviation || product?.abbreviation}.</b></span> */}
                                //                                         </span>
                                //                                     </div>

                                //                                     <div className="info">
                                //                                         N° produc.:
                                //                                         <span className="name" style={{ fontSize: "17px", marginLeft: "3px" }}>
                                //                                             {product.productos_x_cantidad}
                                //                                         </span>
                                //                                     </div>

                                //                                 </div>
                                //                             </div>
                                //                         ))
                                //                     }
                                //                 </div>
                                //             </Grid>
                                //         </Grid>
                                //         <Divider sx={{ my: 2 }} />
                                //     </>
                                // )
                            }
                            <div className="form-group mb-3 buton_container_modal">
                                <button type="submit" className="btn btn-primary">
                                    Editar Producto
                                </button>
                            </div>
                        </Form>
                    )
                }}
            </Formik>

            {
                isSupply && openPopup && (
                    <Popup
                        largo="md"
                        title='Listado de Productos'
                        openPopup={openPopup}
                        setOpenPopup={setOpenPopup}
                    >
                        <ProductsNoInsumo
                            closeModal={() => { setOpenPopup(false) }}
                        />
                    </Popup>
                )
            }

        </div>
    );
}
