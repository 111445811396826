import moment from "moment";

export const validateCupon = (cupones, productsCard, secTipoCupon, allFamilias) => {
    let tipoCupones = [];
    const dateToday = moment();

    for(let i = 0; i < cupones.length; i++) {
        const cupon = cupones[i];
        const dateCupon = cupon.fecha_fin;
        const expirationCupon = moment(dateCupon, 'YYYY-MM-DD');
        
        if(expirationCupon.isSameOrAfter(dateToday)){
        const cantidad = cupon.cantidad;
        const monto = cupon.monto;

        const familia = Number(cupon.familia_id);
        const subFamilia = Number(cupon.sub_familia_id);
        const linea = cupon.linea_id;
        const productos = JSON.parse(cupon.productos);
        let nameFamily ='';
        let nameSubFamily ='';

        if(familia !== null){
            let cantidadP = 0;
            let montoP = 0;
            let productsFamilia = productsCard.filter(e => e.familia_id === familia);
            if(allFamilias){
                let currentFamily = allFamilias.find(e => e.id === familia);
                if(currentFamily) nameFamily = currentFamily.nombre;
            }
            if(productsFamilia){
                productsFamilia.forEach(e => {
                    cantidadP = cantidadP + e.cantidad;
                    montoP = montoP + (e.cantidad * e.precio_unitario);
                });
            }
            if(cantidad !== null && cantidadP >= cantidad){
                cupon['name'] = `Cupon por cantidad de productos de la familia "${nameFamily}"`;
                tipoCupones.push(cupon);
            }else{
                secTipoCupon([]);
            }
            if(monto !== null && montoP >= monto){
                cupon['name'] = `Cupon por monto superado de la familia "${nameFamily}"`;
                tipoCupones.push(cupon);
            }else{
                secTipoCupon([]);
            }
        }
        if(subFamilia !== null){
            let cantidadP = 0;
            let montoP = 0;
            let productsSubFamilia = productsCard.filter(e => e.sub_familia_id === subFamilia);
            if(allFamilias){
               // eslint-disable-next-line
                allFamilias.map((familia) => {
                    if(familia.subFamilias){
                        let currentSubFamily = familia.subFamilias.find(e => e.id === subFamilia);
                        if(currentSubFamily) nameSubFamily = currentSubFamily.nombre_subFamilia;
                    }
                })
            }
            if(productsSubFamilia){
                productsSubFamilia.forEach(e => {
                    cantidadP = cantidadP + e.cantidad;
                    montoP = montoP + (e.cantidad * e.precio_unitario);
                });
            }
            if(cantidad !== null && cantidadP >= cantidad){
                cupon['name'] = `Cupon por cantidad de productos de la Sub familia "${nameSubFamily}"`;
                tipoCupones.push(cupon);
            }else{
                secTipoCupon([]);
            }
            if(monto !== null && montoP >= monto){
                cupon['name'] = `Cupon por el monto superado de la Sub familia "${nameSubFamily}"`;
                tipoCupones.push(cupon);
            }else{
                secTipoCupon([]);
            }
        }
        if(linea !== null){
            let cantidadP = 0;
            let montoP = 0;
            let productsLinea = productsCard.filter(e => e.linea_id === linea);
            if(productsLinea){
                productsLinea.forEach(e => {
                    cantidadP = cantidadP + e.cantidad;
                    montoP = montoP + (e.cantidad * e.precio_unitario);
                });
            }
            if(cantidad !== null && cantidadP >= cantidad){
                cupon['name'] = `Cupon por cantidad de productos de la Linea`;
                tipoCupones.push(cupon);
            }
            if(monto !== null && montoP >= monto){
                cupon['name'] = `Cupon por el monto de productos de la Linea`;
                tipoCupones.push(cupon);
            }
        }
        if(productos !== null){
            let cantidadP = 0;
            let montoP = 0;
            productsCard.forEach(e => {
                let existProduct = productos.find(id => id === (e.id || Number(e.id_producto)));
                if(existProduct){
                    cantidadP = cantidadP + Number(e.cantidad);
                    montoP = montoP + (Number(e.cantidad) * e.precio_unitario);
                }
            });
            if(cantidad !== null && cantidadP >= cantidad){
                cupon['name'] = `Cupon por cantidad de productos`;
                tipoCupones.push(cupon)
            }else{
                secTipoCupon([]);
            }
            if(monto !== null && montoP >= monto){
                cupon['name'] = `Cupon por el monto superado de productos`;
                tipoCupones.push(cupon)
            }else{
                secTipoCupon([]);
            }
        }

        }else{
        alert('cupon no valido')
        }
    }
    secTipoCupon(tipoCupones);
}
