import React, { useState } from "react";
import { Divider, Grid, TextField, Typography, Tooltip, MenuItem, Select } from '@mui/material';

export const ConfigMesa: React.FC<any> = (props:any): JSX.Element => {

    const [typeService, setTypeService] = useState<String>('reserva');

    return (
        <div className="container">
            <Grid container display='flex' alignItems="center">
                <Grid item xs={4}>
                    <Typography variant="subtitle1" className="custom-input">Tipo se servicio:</Typography>
                </Grid>
                <Grid item xs={8}>
                    <Select
                        id="filtro"
                        size='small'
                        value={typeService}
                        onChange={(event) => {
                            setTypeService(event.target.value);
                        }}
                        sx={{width:'250px'}}
                        >

                        <MenuItem value={''} disabled><em>seleccionar filtro</em></MenuItem>
                        <MenuItem value={'reserva'} >reserva</MenuItem>
                        <MenuItem value={'delivery'} >delivery</MenuItem>
                    </Select>
                </Grid>
            </Grid>
            <Divider sx={{ my: 2 }} />
            <div className="form-group mb-3 buton_container_modal">
                <button type="submit" className="btn btn-primary">
                    { 'Guardar' }
                </button>
            </div>
        </div>
    )
}