import { salasRepository } from "../repositories/Salas.repository";
// import axios from "axios";
import { KEY_EMPRESA} from '@constants/local-storage';
import {readLocalStorage} from '@helpers/local-storage-helper';

export const salas = {
    cargarSalas,
    addSala,
    deleteSala,
    editSala,
    cargarMesas,
    cargarAllMesas,
    addMesa,
    deleteMesa,
    editMesa,
    editMesaStatus,
    editMesaReserva,
    getSalasByIdCompany,
    disponibilizarMesa,
    createV2,
    updateV2
}

async function cargarSalas () {
    const res = await salasRepository.getSalas();
    return res;
}

async function getSalasByIdCompany (idCompany:number, idSucursal:number) {
    const res = await salasRepository.getSalasByIdCompany(idCompany, idSucursal);
    return res;
}

async function cargarAllMesas () {
    const res = await salasRepository.getMesas();
    return res;
}

async function addSala (nombre:string , state: any, mesas:any, mesaUrl: string, id_sucursal: number) {
    const company: any = readLocalStorage(KEY_EMPRESA);
    const res = await salasRepository.addSala(nombre, mesas, state, company.id, id_sucursal).then((data)=>{
        if(data.status !== 400){
            const mesa = salasRepository.addMesas(nombre, state, mesas, data.sala.id, mesaUrl).then((datos)=>{
                return datos.data;
            });
            return mesa;
        }
        return data;
    });
    return res;
}

async function deleteSala (id:Number) {
    const res = await salasRepository.deleteSala(id);
    return res;
}

async function editSala (id: number, nombre: any, mesas:any, state: any) {
    const res = await salasRepository.editSala(id, nombre, mesas, state);
    return res;
}

async function cargarMesas (id_sala: any) {
    const res = await salasRepository.getMesa(id_sala);
    return res;
}

async function addMesa (nombre:string , state: any, id_sala:any, mesaUrl: string) {
    const res = await salasRepository.addMesa(nombre, state, id_sala, mesaUrl);
    return res;
}

async function deleteMesa (id:Number) {
    const res = await salasRepository.deleteMesa(id);
    return res;
}

async function editMesa (id: number, nombre: any, id_sala: any, state: any, mesaUrl: string) {
    const res = await salasRepository.editMesa(id, nombre, id_sala, state, mesaUrl);
    return res;
}

async function editMesaStatus (id: number, state: any) {
    const res = await salasRepository.editMesaStatus(id, state);
    return res;
}

async function editMesaReserva (id: number, fecha: any) {
   const res = await salasRepository.editMesaReserva(id, fecha);
   return res;
}

async function disponibilizarMesa (id:Number) {
    const res = await salasRepository.disponibilizar(id);
    return res;
}

async function createV2 (payload: {}) {
    const res = await salasRepository.createV2(payload);
    return res;
}

async function updateV2 (id: number, payload: {}) {
    const res = await salasRepository.updateV2(id, payload);
    return res;
}