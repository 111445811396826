import React, { useEffect } from 'react';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import { Autocomplete, Divider, Select, Typography } from '@mui/material';
import { PopupComponent } from '@/components/common/Popup/Popup';
import { MenuItem } from "@mui/material";
import { userService } from '@/service/services/User.service';
import { salas } from '@/service/services/Salas.service';
import { cuadraturaService } from '@/service/services/Cuadratura.service';
import { Cuadratura } from '../../Cuadratura';
import { cajas } from '@/service/services/Cajas.service';

export const OpenCuadratura: React.FC<any> = (props:any): JSX.Element => {

   const {idUsuario} = props
   const [users, setUsers] = React.useState<Array<any>>([]);
   const [rooms, setRooms] = React.useState<Array<any>>([]);
   const [usersSelected, setUsersSelected] = React.useState<any>(0);
   const [monto, setMonto] = React.useState<number>(0);
   const [cajasDisponibles, setCajasDisponible] =  React.useState<any>([]);
   const [cajaSelected, setCajaSelected] =  React.useState<any>(null);

   useEffect(() => {
   // userService.getUsersByEmpresa().then(res => {
   //    if (props.idUsuario){
   //       getUsersByEmpresa();
   //    } else if(props.idMesa){
   //       getTablesByEmpresa();
   //    } else if(props.idSala){
   //       getRoomsByEmpresa();
   //    }
   // })
   cuadraturaService.getUltimaCuadratura(idUsuario).then((data)=> {
      setMonto(data.cuadratura?.total_cierre_efectivo || 0)
   })
   cajas.cargarCajas().then((data)=>{
      console.log(data)
      const cajasFilter = data.cajas.filter((item)=> item.en_uso == 0);
      setCajasDisponible(cajasFilter)
   })
   }, [])

   // const getUsersByEmpresa = async() => {
   //    setLoading(true);
   //    await userService.getUsersByEmpresa().then(res => {
   //       if (res.status === 200) {
   //          setUsers([...res.users] || []);
   //          setUsersSelected(props.idUsuario);
   //       }
   //    })
   //    setLoading(false);
   // }

    const confirmPopup = () => {
      props.onConfirm(props.itemSelected?.id, monto, cajaSelected.id);
    }

    const handleChangeUser = (e) =>{
      setUsersSelected(e.target.value || 0);
   }

   const handleChangeMonto = (e) => {
      if (e.target.value>=0) {
         setMonto(e.target.value);
      }
   }

    return(
        <>
        <PopupComponent
         largo="sm"
         title = {props.title}
         openPopup = {props.openPopup}
         setOpenPopup = {props.setOpenPopup}
        >
            <Grid container spacing={3} alignItems="center" justifyContent="center">
            <Grid item xs={12} container style={{display: "flex", alignItems: "center"}}>
                  <Grid item xs={4}>
                     <Typography variant="subtitle1" className="custom-input"><b>Caja:</b></Typography>
                  </Grid>
                  <Grid item xs={8}>
                      <Autocomplete
                         disablePortal
                         id="combo-box-demo"
                         value={cajaSelected}
                         options={cajasDisponibles || []}
                         getOptionLabel={(option: any) => option?.nombre || ""}
                         isOptionEqualToValue={(option, value) => option?.id === value?.id}
                         fullWidth
                         renderInput={(params) => <TextField {...params} label="" />}
                         onChange={(e, newValue) => {
                            setCajaSelected(newValue);
                         }}
                         size="small"
                      />

                  </Grid>
               </Grid>
               <Grid item xs={12} container style={{display: "flex", alignItems: "center"}}>
                     <Grid item xs={4}>
                        <Typography variant="subtitle1" className="custom-input"><b>Responsable:</b></Typography>
                     </Grid>
                     <Grid item xs={8}>
                        <TextField
                           size="small"
                           fullWidth
                           type={"text"}
                           value={props.itemSelected?.nombre}
                           onChange={handleChangeMonto}/>
                        {/* <Select id="tipoPago" size='small'
                           fullWidth name="responable" value={usersSelected}
                           onChange={handleChangeUser}
                           disabled
                        >
                           {users.map((option, i) => {
                              return <MenuItem key={i} value={option.id}>{option.nombre}</MenuItem>
                           })
                           }
                        </Select> */}
                     </Grid>
               </Grid>
               <Grid item xs={12} container style={{display: "flex", alignItems: "center"}}>
                  <Grid item xs={4}>
                     <Typography variant="subtitle1" className="custom-input"><b>Monto Inicial:</b></Typography>
                  </Grid>
                  <Grid item xs={8}>
                     <TextField
                        size="small"
                        fullWidth
                        type={"number"}
                        value={monto}
                        onChange={handleChangeMonto}/>
                  </Grid>
               </Grid>
            </Grid>
            <Divider sx={{ my: 2 }} />
            <div className="form-group mb-3 buton_container_modal">
               <button onClick={confirmPopup} className="btn btn-primary">
               Abrir cuadratura
               </button>
            </div>

        </PopupComponent>
      </>
    )
}
