import { Props } from "./HeaderEcommerce.type";
import React, { useEffect } from 'react';
import { Button, Grid, IconButton, InputBase, MenuItem, Paper, Typography } from "@mui/material";
import '../../components/Categories/CategoryProducts_style.css';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import SearchIcon from '@mui/icons-material/Search';
import { ROUTE_MIS_COMPRAS_CLIENTE, ROUTE_MIS_PEDIDOS_CLIENTE } from "@/toolbox/constants/route-map";
import { Link } from "react-router-dom";
import { readLocalStorage } from "@/toolbox/helpers/local-storage-helper";
import { authenticationService } from "@/service/services/Authentication.service";
import { styled } from '@mui/material/styles';
import LogoutIcon from '@mui/icons-material/Logout';
import { red } from '@mui/material/colors';
import Menu from '@mui/material/Menu';
import Fade from '@mui/material/Fade';
import { KEY_USER_DATA } from "@/toolbox/constants/local-storage";
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import { Popup } from "@/views/Editor/components/Popup";
import { LoginModal } from "../LoginModal";
import { Loading } from "@/components/common/Loading";
import { useHistory } from 'react-router-dom';
import * as Routes from '@constants/route-map';
import { clientesService } from "@/service/services/Clientes.service";
import { RegisterModal } from "../RegisterModal";

const ColorButton = styled(Button)(({ theme }) => ({
    color: theme.palette.getContrastText(red[500]),
    textTransform: "capitalize",
    fontSize: "18px !important",
    fontWeight: "normal",
    padding: "5px 10px",
    backgroundColor: red[500],
    '&:hover': {
      backgroundColor: red[700],
    },
}));

export const HeaderEcommerce: React.FC<Props> = (props: any): JSX.Element => {
   const history = useHistory();
    const [anchorEl, setAnchorEl] = React.useState(null);
    // eslint-disable-next-line
    const [showModalLogin, setShowModalLogin] = React.useState<boolean>(false);
    const [showModalRegister, setShowModalRegister] = React.useState<boolean>(false);
    const [loading, setLoading] = React.useState<boolean>(false);
    const isMenuOpen = Boolean(anchorEl);

    const dataUsuario = readLocalStorage(KEY_USER_DATA);
    const rol = dataUsuario?.user?.rol;

    const pathname = window.location.pathname;

    const handleProfileMenuOpen = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleMenuClose = () => {
        setAnchorEl(null);
    };

    const handleOpen = () => {
        props?.$action?.actionSetEcommerce({
            ...props.$store.ecommerceReducer,
            showModal: true,
        })
    };

    const handleLogout = () => {
        setLoading(true);
        authenticationService.logout().then(res => {
            if (!res.error) {
                window.location.replace('/shop-ecommerce');
            }
        })
        setLoading(false);
    }

    return (
        <>
        {loading && <Loading/>}
            <Grid xs={12} container item justifyContent={'space-between'} mb={3} display="flex" gap={"10px"} sx={{alignItems: 'center', backgroundColor: '#176ECA ', paddingTop: '10px', paddingBottom: '10px'}}>
                <Grid item xs={12} md={2} justifyContent="start">
                    {/* {
                        (pathname !== '/shop-ecommerce') && (pathname !== '/shop-ecommerce/reserve-table') && (
                            <Button variant='contained' className='btn_back'
                                onClick={()=> {
                                history.push(Routes.ROUTE_SHOP_ECOMMERCE)
                                }}
                            >
                                <ArrowBackIosIcon />
                                <Typography variant='subtitle2'>Regresar</Typography>
                            </Button>
                        )
                    } */}
                    <Button color="inherit" component={Link} to={ Routes.ROUTE_SHOP_ECOMMERCE }>
                        <Typography
                            variant="h6"
                            component="div"
                            sx={{ display: { xs: 'block', sm: 'block' }, color: 'white !important' }}
                        >
                            NUPY MARKETPLACE
                        </Typography>
                    </Button>
                </Grid>

                {
                    (pathname !== '/shop-ecommerce') && (pathname !== '/shop-ecommerce/reserve-table') && (
                        <Grid item xs={12} md={5} display="flex" justifyContent="center">
                            <Paper
                                component="form"
                                sx={{ p: '0px 4px', display: 'flex', alignItems: 'center', width: 600, height: 45, border: '1.7px solid #dfdfdf', boxShadow: 'none' }}
                            >
                            <InputBase

                                sx={{ ml: 1, flex: 1 }}
                                placeholder="Buscar Proveedores"
                                inputProps={{ 'aria-label': 'search google maps' }}
                            />
                            <IconButton type="submit" sx={{ p: '10px' }} aria-label="search" style={{ backgroundColor: '#fff', color: '#3f3f3f' }}>
                                <SearchIcon />
                            </IconButton>
                            </Paper>
                        </Grid>
                    )
                }
                
                <Grid item container xs={12} md={4} justifyContent="end" alignItems='center'>
                    {/* {
                        (pathname !== '/shop-ecommerce') && (
                            <>
                            <Grid item ml={1}>
                                <Button variant='contained' color='success' onClick={() => handleOpen()} sx={{ marginRight: '15px' }}>
                                    <ShoppingCartIcon /> <Typography variant='subtitle2'>{props?.$store?.ecommerceReducer?.productsCount || '0'}</Typography>
                                </Button>
                            </Grid>
                            <span style={{color:'white'}}>|</span>
                            </>
                        )
                    } */}
                    
                    {
                        dataUsuario ? (
                            <>
                            <Grid item ml={1}>
                                <Button variant='contained' color='success' onClick={() => handleOpen()} sx={{ marginRight: '15px' }}>
                                    <ShoppingCartIcon /> <Typography variant='subtitle2'>{props?.$store?.ecommerceReducer?.productsCount || '0'}</Typography>
                                </Button>
                            </Grid>
                            <span style={{color:'white'}}>|</span>
                            <Grid sx={{ marginLeft:'20px', display: 'flex', alignItems: 'center' }}>
                                <Grid>
                                    <div style={{textAlign:'end', color:'white'}}>{dataUsuario?.user?.usuario}</div>
                                </Grid>
                                <Button color='inherit' onClick={handleProfileMenuOpen}>
                                    <AccountCircleIcon style={{color: 'white'}} fontSize='large'/>
                                </Button>
                            </Grid>
                            </>
                        ) : (
                            <Grid display='flex' style={{alignItems: 'center', cursor:'pointer'}}>
                                <span style={{color:'white'}} onClick={()=>{setShowModalLogin(true)}}>Iniciar sesion</span>
                                <Button>
                                    <AccountCircleIcon style={{color: 'white'}}  fontSize='large'/>
                                </Button>
                            </Grid>
                        )
                    }

                </Grid>
            </Grid>

            <Menu
                id="fade-menu"
                MenuListProps={{
                    'aria-labelledby': 'fade-button',
                }}
                anchorEl={anchorEl}
                open={isMenuOpen}
                onClose={handleMenuClose}
                TransitionComponent={Fade}
            >
                {
                    rol && rol === 'Cliente' && (
                        <MenuItem onClick={handleMenuClose} component={Link} to={ROUTE_MIS_PEDIDOS_CLIENTE}>Mis Pedidos</MenuItem>
                    )
                }
                <MenuItem onClick={handleMenuClose} component={Link} to={ROUTE_MIS_COMPRAS_CLIENTE}>Mis Compras</MenuItem>

                <MenuItem onClick={()=>handleLogout()} component={Link} to={'/'}>
                  <ColorButton variant="contained"> salir&nbsp;&nbsp;<LogoutIcon style={{width: "18px", height: "19px"}}/> </ColorButton>
                </MenuItem>

            </Menu>

            {
                showModalLogin && (
                    <Popup
                        largo="sm"
                        title = 'LOGIN SHOP'
                        openPopup = {showModalLogin}
                        setOpenPopup = {setShowModalLogin}
                    >
                        <LoginModal
                            closeModal = {() => {setShowModalLogin(false)}}
                        />

                    </Popup>
                )
            }
            {
                showModalRegister && (
                    <Popup
                        largo="sm"
                        title = 'REGISTER SHOP'
                        openPopup = {showModalRegister}
                        setOpenPopup = {setShowModalRegister}
                    >
                        <RegisterModal/>
                    </Popup>
                )
            }

        </>
    )
}
