import React, { useEffect, useState } from "react";
import { Autocomplete, Divider, Grid, MenuItem, Select, TextField, Tooltip, Typography } from "@mui/material";
import { Form, Formik } from "formik";
import { Loading } from "@/components/common/Loading";
import { userService } from "@/service/services/User.service";
import { empresasService } from "@/service/services/Empresas.service";
import { sucursalesService } from "@/service/services/Sucursales.service";
import { useLocalStorage } from '@/toolbox/hooks/local-storage.hook';
import { KEY_USER_DATA } from '@/toolbox/constants/local-storage';
import {KEY_EMPRESA} from '@constants/local-storage';
import {readLocalStorage} from '@helpers/local-storage-helper';
import PriorityHighIcon from '@mui/icons-material/PriorityHigh';
import  "../../Users.sass";
import { Snackbar, Alert } from '@mui/material';

export const AddUsuario: React.FC<any> = (props:any): JSX.Element => {

    const {closeModal, onListUsuarios, usuario, editMode} = props;

    const [loading, setLoading] = React.useState<boolean>(false);
    const [companies, setCompanies] = React.useState([]);
    const [sucursales, setSucursales] = React.useState([]);
    console.log(sucursales);
    const [roles, setRoles] = React.useState([]);
    const [empresaSelect, setEmpresaSelect] = React.useState<any>("");
    const [sucursalSelect, setSucursalSelect] = React.useState<any>("");
    const [rolSelect, setRolSelect] = React.useState<any>("");
    // eslint-disable-next-line
    const [data, setData] = React.useState({
        id: usuario && usuario?.id ? usuario.id : "",
        nombre: usuario && usuario?.nombre ? usuario.nombre : "",
        rut: usuario && usuario?.rut ? usuario.rut : "",
        rut_empresa: usuario && usuario?.rut_empresa ? usuario.rut_empresa : "",
        usuario: usuario && usuario?.usuario ? usuario.usuario : "",
        email: usuario && usuario?.email ? usuario.email : "",
        password: usuario && usuario?.password ? usuario.password : "",
        state: usuario && usuario?.state ? usuario.state : "",
        id_sucursal: usuario && usuario?.id_sucursal ? usuario.id_sucursal : "",
        rol: usuario && usuario?.rol ? usuario.rol : 3,
    });

    const [dataUser] = useLocalStorage(KEY_USER_DATA, undefined);
    const userSession = dataUser && dataUser.user;
    const rol = dataUser && dataUser.user.rol;
    const isSuperAdmin = rol === 'Superadmin';

    const [snackBarConfig, setSnackBarConfig] = useState<any>({
        open: false,
        severity: 'error',
        message: 'Error',
        autoHideDuration: 3000,
    });

    const SessionEmpresa: any = readLocalStorage(KEY_EMPRESA);
    const pais = SessionEmpresa.country_abbreviation;

      useEffect(() => {
        if(isSuperAdmin){getEmpresas()}
        if(!isSuperAdmin){getSucursales(userSession.id_empresa)}
        getRoles();
      },// eslint-disable-next-line
      [])

      const getEmpresas = async() => {
        setLoading(true);
        await empresasService.listEmpresaByName().then((data)=>{
            setCompanies(data);
            setLoading(false);
            if(data){
                setEmpresaSelect(data[0]);
                getSucursales(data[0].id)
            }
        })
    }

    const getSucursales = async(idCompany) => {
        setLoading(true);
        await sucursalesService.getSucursalesByEmpresa(idCompany).then((data) => {
            let sucursales = [];
            if(data && data.length > 0){
                data.map((sucursal) => {
                    sucursales.push({
                        created_at: sucursal.created_at,
                        direccion: sucursal.direccion,
                        id: sucursal.id,
                        nombre: sucursal.nombre + '-' + sucursal.direccion,
                        state: sucursal.state,
                        updated_at: sucursal.updated_at
                    });
                })
            } 
            setSucursales(sucursales);
            setLoading(false);
            if(data){
                setSucursalSelect(sucursales[0]);
            }
        })
    }

      const getRoles = async() => {
        setLoading(true);
        await userService.listRol().then((data)=>{
            setRoles(data.map((el) => {
            return {label: el.rol, key: el.id}
            }));

          setLoading(false);
        })
      }

      const validateForm = (values) => {
        let errors: any = {};

        const formats = {
            name: /^[A-Za-zÑñÁáÉéÍíÓóÚúÜü\s]+$/,
            email: /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/,
            rutChile: /^[0-9]{8,9}[-|‐]{1}[0-9kK]{1}$/,
            rutPeru: /^\d{11}$/,
            rutColombia: /^[0-9]{9}[-|‐]{1}[0-9]{1}$/,
        }

        if(!values.nombre) errors.nombre = "nombre requerido";
        if(values.nombre && !formats.name.test(values.nombre)) errors.nombre = "nombre inválido";
        if(!values.rut) errors.rut = "RUT requerido";

        if(pais==='CLP' && values.rut && !formats.rutChile.test(values.rut)) errors.rut = "Rut inválido";
        if(pais==='PEN' && values.rut && !formats.rutPeru.test(values.rut)) errors.rut = "Rut inválido";
        if(pais==='COP' && values.rut && !formats.rutColombia.test(values.rut)) errors.rut = "Rut inválido";

        if(!values.usuario) errors.usuario = "nombre de usuario requerido";
        if(!values.email) errors.email = "email requerido";
        if(values.email && !formats.email.test(values.email)) errors.email = "email inválido";
        if(!usuario && rolSelect !== 5 && !values.password) errors.password = "contraseña requerido";
        if(!values.rol) errors.rol = "Rol requerido";

        return errors;
      }

      const onSubmit = async(values, {resetForm}) => {
          const formData = new FormData();
          formData.append('nombre', values.nombre);
          formData.append('rut', values.rut);

          formData.append('usuario', values.usuario);
          formData.append('email', values.email);

          formData.append('rol', values.rol);

          setLoading(true);
          if(usuario && editMode){
            //Editar
            await userService.update(formData, values.id).then((data) => {
                onListUsuarios && onListUsuarios();
                closeModal();
            })
          }else{
            //Agregar
            formData.append('password', values.password);

            if(!isSuperAdmin){
                formData.append('rut_empresa', userSession.rut_empresa);
                sendData(formData);
            }else{
                if(!empresaSelect){
                    setSnackBarConfig(prev => ({
                        ...prev,
                        open: true,
                        message: 'seleccione una empresa',
                    }));
                }

                if(!sucursalSelect){
                    setSnackBarConfig(prev => ({
                        ...prev,
                        open: true,
                        message: 'seleccione una empresa',
                    }));
                }else{
                    formData.append('id_sucursal', sucursalSelect.id);
                }

                formData.append('rut_empresa', empresaSelect.rut);

                if(empresaSelect && sucursalSelect){
                    sendData(formData);
                }

            }
          }

          setLoading(false);
      }

      const sendData = async(formData) => {
        await userService.register(formData).then((data) => {
            if(data.status === 400){
                setSnackBarConfig(prev => ({
                    ...prev,
                    open: true,
                    message: `${data.error}`,
                }));
            }else{
                onListUsuarios && onListUsuarios();
                closeModal();
            }
        })
      }

    return (
        <div className="container">
            {loading && <Loading/>}

            <Snackbar
                open={snackBarConfig.open}
                autoHideDuration={snackBarConfig.autoHideDuration}
                onClose={() => setSnackBarConfig(prev => ({ ...prev, open: false }))}
                anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                >
                <Alert
                onClose={() => setSnackBarConfig(prev => ({ ...prev, open: false }))}
                severity={snackBarConfig.severity}
                variant="filled"
                >
                {snackBarConfig.message}
                </Alert>
            </Snackbar>

            <Formik enableReinitialize initialValues={data} validate={ (values) => validateForm(values) } onSubmit={onSubmit}>
                {({values, errors, touched, handleSubmit, handleChange, setFieldValue}) => {
                    return (
                        <Form onSubmit={handleSubmit}>
                            <Grid container spacing={3} alignItems="center" justifyContent="center">

                                <Grid item xs={12} container style={{display: "flex", alignItems: "center"}}>
                                    <Grid item xs={4}>
                                        <Typography variant="subtitle1" className="custom-input"><b>Nombre:</b></Typography>
                                    </Grid>
                                    <Grid item xs={8} style={{display: 'flex', alignItems: "center"}}>
                                        {
                                            errors.nombre && (touched.nombre ? true : false) && (
                                                <Tooltip title={errors.nombre}>
                                                    <PriorityHighIcon className={'iconWarning'}/>
                                                </Tooltip>
                                            )
                                        }
                                        <TextField
                                        id="nombre"
                                        type="text"
                                        name="nombre"
                                        fullWidth
                                        value={values.nombre}
                                        onChange={handleChange}
                                        placeholder="Nombre del Usuario"
                                        error={errors.nombre && touched.nombre ? true : false}
                                        disabled = {!editMode}
                                        />
                                    </Grid>
                                </Grid>

                                <Grid item xs={12} container style={{display: "flex", alignItems: "center"}}>
                                    <Grid item xs={4}>
                                        <Typography variant="subtitle1" className="custom-input"><b>RUT:</b></Typography>
                                    </Grid>
                                    <Grid item xs={8} style={{display: 'flex', alignItems: "center"}}>
                                        {
                                            errors.rut && (touched.rut ? true : false) && (
                                                <Tooltip title={errors.rut}>
                                                    <PriorityHighIcon className={'iconWarning'}/>
                                                </Tooltip>
                                            )
                                        }
                                        <TextField
                                        id="rut"
                                        type="text"
                                        name="rut"
                                        fullWidth
                                        value={values.rut}
                                        onChange={handleChange}
                                        placeholder={pais && (pais==='PEN' ? 'XXXXXXXXXXX' : (pais=== 'CLP' ? 'xxxxxxxx-x' : 'xxxxxxxxx-x') )}
                                        error={errors.rut && touched.rut ? true : false}
                                        disabled = {!editMode}
                                        />
                                    </Grid>
                                </Grid>

                                <Grid item xs={12} container style={{display: "flex", alignItems: "center"}}>
                                    <Grid item xs={4}>
                                        <Typography variant="subtitle1" className="custom-input"><b>Nombre de Usuario:</b></Typography>
                                    </Grid>
                                    <Grid item xs={8} style={{display: 'flex', alignItems: "center"}}>
                                        {
                                            errors.usuario && (touched.usuario ? true : false) && (
                                                <Tooltip title={errors.usuario}>
                                                    <PriorityHighIcon className={'iconWarning'}/>
                                                </Tooltip>
                                            )
                                        }
                                        <TextField
                                        id="usuario"
                                        type="text"
                                        name="usuario"
                                        fullWidth
                                        value={values.usuario}
                                        onChange={handleChange}
                                        placeholder="Nombre de Usuario"
                                        error={errors.usuario && touched.usuario ? true : false}
                                        disabled = {!editMode}
                                        />
                                    </Grid>
                                </Grid>

                                <Grid container item xs={12} style={{display: "flex", alignItems: "center"}}>
                                    <Grid item xs={4}>
                                        <Typography variant="subtitle1"><b>Rol:</b> </Typography>
                                    </Grid>
                                    <Grid item xs={8} style={{display: 'flex', alignItems: "center"}}>
                                    {
                                        errors.rol && (touched.rol ? true : false) && (
                                            <Tooltip title={errors.rol}>
                                                <PriorityHighIcon className={'iconWarning'}/>
                                            </Tooltip>
                                        )
                                    }
                                    <Select
                                    id="rol"
                                    value={values.rol}
                                    onChange={(event) => {
                                        setFieldValue("rol", event.target.value);
                                        setRolSelect(event.target.value);
                                    }}
                                    error={ errors.rol && touched.rol ? true : false }
                                    fullWidth
                                    disabled = {!editMode}
                                    >
                                        {
                                        roles.map((role, i) => {
                                            return <MenuItem key={i} value={role.key}>{role.label}</MenuItem>;
                                        })
                                        }

                                    </Select>
                                    </Grid>
                                </Grid>

                                <Grid item xs={12} container style={{display: "flex", alignItems: "center"}}>
                                    <Grid item xs={4}>
                                        <Typography variant="subtitle1" className="custom-input"><b>Email:</b></Typography>
                                    </Grid>
                                    <Grid item xs={8} style={{display: 'flex', alignItems: "center"}}>
                                        {
                                            errors.email && (touched.email ? true : false) && (
                                                <Tooltip title={errors.email}>
                                                    <PriorityHighIcon className={'iconWarning'}/>
                                                </Tooltip>
                                            )
                                        }
                                        <TextField
                                        id="email"
                                        type="email"
                                        name="email"
                                        fullWidth
                                        value={values.email}
                                        onChange={handleChange}
                                        placeholder="Email del Usuario"
                                        error={errors.email && touched.email ? true : false}
                                        disabled = {!editMode}
                                        />
                                    </Grid>
                                </Grid>

                                {
                                    !usuario && rolSelect !== 5 && (
                                        <Grid item xs={12} container style={{display: "flex", alignItems: "center"}}>
                                            <Grid item xs={4}>
                                                <Typography variant="subtitle1" className="custom-input"><b>Contraseña:</b></Typography>
                                            </Grid>
                                            <Grid item xs={8} style={{display: 'flex', alignItems: "center"}}>
                                                {
                                                    errors.password && (touched.password ? true : false) && (
                                                        <Tooltip title={errors.password}>
                                                            <PriorityHighIcon className={'iconWarning'}/>
                                                        </Tooltip>
                                                    )
                                                }
                                                <TextField
                                                id="password"
                                                type="password"
                                                name="password"
                                                fullWidth
                                                value={values.password}
                                                onChange={handleChange}
                                                placeholder="contraseña del Usuario"
                                                error={errors.password && touched.password ? true : false}
                                                />
                                            </Grid>
                                        </Grid>
                                    )
                                }

                                {
                                    (!usuario && isSuperAdmin) && (
                                        <>
                                        <Grid container item xs={12} style={{display: "flex", alignItems: "center"}}>
                                            <Grid item xs={4}>
                                                <Typography variant="subtitle1"><b>Empresa:</b> </Typography>
                                            </Grid>
                                            <Grid item xs={8} style={{display: 'flex', alignItems: "center"}}>
                                            {
                                                empresaSelect === "" && (touched.password ? true : false) && (
                                                    <Tooltip title={'seleccione una empresa'}>
                                                        <PriorityHighIcon className={'iconWarning'}/>
                                                    </Tooltip>
                                                )
                                            }
                                            <Autocomplete
                                                disablePortal
                                                id="combo-box-demo"
                                                value={empresaSelect}
                                                options={companies || []}
                                                getOptionLabel={(option) => option.name || ""}
                                                fullWidth
                                                renderInput={(params) => <TextField {...params} label="Escriba para buscar" />}
                                                onChange={(e, newValue) => {
                                                setEmpresaSelect(newValue);
                                                getSucursales(newValue.id)
                                                }}
                                            />
                                            </Grid>
                                        </Grid>

                                        {
                                            (empresaSelect) && (
                                                <Grid item xs={12} container style={{display: "flex", alignItems: "center"}}>
                                                    <Grid item xs={4}>
                                                        <Typography variant="subtitle1" className="custom-input"><b>Rut Empresa:</b></Typography>
                                                    </Grid>
                                                    <Grid item xs={8} style={{display: 'flex', alignItems: "center"}}>
                                                        <TextField
                                                        id="rut_empresa"
                                                        type="text"
                                                        name="rut_empresa"
                                                        fullWidth
                                                        value={ empresaSelect && empresaSelect?.rut ? empresaSelect.rut : ""}
                                                        onChange={handleChange}
                                                        placeholder="Rut de la empresa"
                                                        disabled
                                                        />
                                                    </Grid>
                                                </Grid>
                                            )
                                        }
                                        </>
                                    )
                                }
                                {
                                    (!usuario) && (
                                        <Grid container item xs={12} style={{display: "flex", alignItems: "center"}}>
                                            <Grid item xs={4}>
                                                <Typography variant="subtitle1"><b>Sucursal:</b> </Typography>
                                            </Grid>
                                            <Grid item xs={8}>
                                            <Autocomplete
                                                disablePortal
                                                id="combo-box-demo"
                                                value={sucursalSelect}
                                                options={sucursales || []}
                                                getOptionLabel={(option) => option.nombre || ""}
                                                fullWidth
                                                renderInput={(params) => <TextField {...params} label="Escriba para buscar" />}
                                                onChange={(e, newValue) => {
                                                setSucursalSelect(newValue);
                                                }}
                                            />
                                            </Grid>
                                        </Grid>
                                    )
                                }

                                {
                                    usuario && (
                                    <Grid item xs={12} container style={{display: "flex", alignItems: "center"}}>
                                        <Grid item xs={4}>
                                            <Typography variant="subtitle1" className="custom-input"><b>Estado:</b></Typography>
                                        </Grid>
                                        <Grid item xs={8}>
                                            <TextField
                                            id="state"
                                            type="text"
                                            name="state"
                                            fullWidth
                                            value={usuario.state === 1 ? 'Habilitado' : 'Desabilitado'}
                                            onChange={handleChange}
                                            placeholder="RUT"
                                            disabled
                                            />
                                        </Grid>
                                    </Grid>
                                    )
                                }

                            </Grid>
                            <Divider sx={{ my: 2 }} />

                            <div className="form-group mb-3 buton_container_modal">
                                <button type="submit" className="btn btn-primary">
                                {
                                    usuario ? `Editar Usuario` : `Agregar Usuario`
                                }
                                </button>
                            </div>
                        </Form>
                    )
                }}
            </Formik>
        </div>
    );
}
