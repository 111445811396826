import React, { useEffect, useState } from 'react';
import { Grid, Typography, Divider, FormControlLabel, FormControl, RadioGroup, Radio } from '@mui/material';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import '../Products/AllProducts_style.css';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import { useLocalStorage } from '@/toolbox/hooks/local-storage.hook';
import { KEY_EMPRESA } from '@/toolbox/constants/local-storage';
import { editor } from '@/service/services/Editor.service';
import { Loading } from '@/components/common/Loading';

interface Props {
    valueSubModulo?: string;
    valuePrice?: string;
    handleChangeSubModulo?: (newValue: any) => void;
    handleChangePrice?: (newValue: string) => void;
    handleCleanFilters?: () => void;
}

export const SidebarEcommerce: React.FC<Props> = (props: Props): JSX.Element => {
    const style = {
        width: '100%',
        bgcolor: 'background.paper',
        boxSizing: "border-box"
    };

    const [loading, setLoading] = useState<boolean>(false);
    const [dataEmpresa] = useLocalStorage(KEY_EMPRESA, {
         country_abbreviation: "",
         nombre: ""
   });
   const [submodulos, setSubModulos] = useState([]);

    useEffect(()=> {
        getModulos();
        // eslint-disable-next-line
    }, []);

    useEffect(()=> {
        if(submodulos.length > 0 ){
            const ids = submodulos[0].id_productos;
            props.handleChangeSubModulo && props.handleChangeSubModulo(ids)
        }
        // eslint-disable-next-line
    }, [submodulos]);

    const getModulos = async() => {
        setLoading(true);
        await editor.cargarModulos().then((data) => {
        const modulos: any = Object.values(data.modulos);
        for(let i = 0; i<modulos.length;i++){
            if(modulos[i].submodulos){
                getSubmodulos(modulos[i].submodulos);
            }
        }
        });
    }

    const getSubmodulos = async(ids) => {
        let arraySubmodulos = [];
        await editor.cargarSubModulos(ids).then((data) => {
        const subModulos: any = Object.values(data.submodulos);
        for(let i = 0; i<subModulos.length;i++){
            const valor = Object.values(subModulos[i]);
            arraySubmodulos.push(valor[0]);
        }
        setSubModulos(arraySubmodulos);
        })
        setLoading(false);
    }

    const handleChangePrice = (event: React.ChangeEvent<HTMLInputElement>, newValue: string) => {
      props.handleChangePrice && props.handleChangePrice(newValue)
    };
    
    const handleChangeCategory = (event: React.ChangeEvent<HTMLInputElement>, newValue: string) => {
        props.handleChangeSubModulo && props.handleChangeSubModulo(newValue)
    }

    return (
        <>
        {loading && <Loading/>}
        <Grid item container className={'grid-slider'} sx={{boxSizing: "border-box"}} >
            <Grid item xs={12} textAlign='center'
               sx={{height: "100px", background:"red", boxSizing: "border-box"}}
            >
                <img
                  style={{height: "100px", width: "100%"}}
                  alt='Proveedor'
                  src={'https://images.rappi.pe/restaurants_background/burger_03-1617220183.jpg?e=webp&q=80&d=400x400'} />
            </Grid>
            <List sx={style} className="list-sidebar">
                <Divider light />
                <ListItem button>
                    <Typography variant='h6'>{dataEmpresa?.nombre}</Typography>
                </ListItem>
                <Divider light />
                <Accordion expanded={true}>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel3a-content"
                        id="panel3a-header"
                    >
                        <Typography variant='subtitle1'>Categorias</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <FormControl>
                           <RadioGroup
                              value={props.valueSubModulo}
                              onChange={handleChangeCategory}
                           >
                            {
                                submodulos && submodulos.map((submodulo, i) => (
                                    <FormControlLabel
                                        control={<Radio />}
                                        value={submodulo.id_productos ? submodulo.id_productos : 0}
                                        label= {submodulo.nombre}
                                    />
                                ))
                            }
                              
                           </RadioGroup>
                        </FormControl>
                    </AccordionDetails>
                </Accordion>
                <Accordion>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel3a-content"
                        id="panel3a-header"
                    >
                        <Typography variant='subtitle1'>Precio</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <FormControl>
                           <RadioGroup
                              value={props.valuePrice}
                              onChange={handleChangePrice}
                           >
                              <FormControlLabel
                                 control={<Radio />}
                                 value="100"
                                 label="De $ 0 a $ 100 "
                              />
                              <FormControlLabel
                                 control={<Radio />}
                                 value="500"
                                 label="De $ 101 a $ 500"
                              />
                              <FormControlLabel
                                 control={<Radio />}
                                 value="1000"
                                 label="De $501 a $1000"
                              />
                              <FormControlLabel
                                 control={<Radio />}
                                 value="3000"
                                 label="De $1001 a $3000"
                              />
                              <FormControlLabel
                                 control={<Radio />}
                                 value="8000"
                                 label="De $3001 a $8000"
                              />
                              <FormControlLabel
                                 control={<Radio />}
                                 value="8001"
                                 label="De $8001 a más"
                              />
                           </RadioGroup>
                        </FormControl>
                    </AccordionDetails>
                </Accordion>
                
                <Grid mt={3}>
                    <button className='list-siderbar__button' onClick={props.handleCleanFilters}>Limpiar</button>
                </Grid>

            </List>
        </Grid>
        </>
    );
}
