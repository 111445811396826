import { NotaVentaRepository } from "../repositories/NotaVenta.repository";

export const NotaVentaService = {
    getNotasVenta,
    register
}

async function getNotasVenta(){
    const res = await NotaVentaRepository.getNotasVenta();
    return res;
}

async function register(data: any){
    const res = await NotaVentaRepository.register(data);
    return res;
}