import React, { useEffect } from "react";
import { Props } from "./ProductoBalanza.type";
import { productoService } from "@/service/services/Producto.service";
import { Grid, Paper, Table, TableBody, TableCell, TableContainer, TableFooter, TableHead, TablePagination, TableRow, TextField } from "@mui/material";
import { Loading } from "@/components/common/Loading";
import { Popup } from "@/views/Editor/components/Popup";
import { TicketBalanza } from "../TicketBalanza";

export const ProductoBalanza: React.FC<Props> = (props: Props): JSX.Element => {

    const {closeModal} = props;

    const [loading, setLoading] = React.useState<boolean>(false);
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    const [products, setProducts] = React.useState<Array<any>>([]);
    const [filter, setFilter] = React.useState<Array<any>>([]);
    // eslint-disable-next-line
    const [textSearch, setTextSearch] = React.useState<string>("");
    const [ProductsSelect, setProductsSelect] = React.useState({});
    const [openPopup, setOpenPopup] = React.useState(false);

    useEffect(() => {
        getProductosBalanza();
    }, []);

    const getProductosBalanza = async() => {
        setLoading(true);
        await productoService.getProductosBalanza().then((data) => {
            if(data.status === 200){
                setProducts(data.productos);
                setFilter(data.productos);
            }
            setLoading(false);
        })
    }

    const handleChangeRowsPerPage = (event)   =>{
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };
    const handleChangePage = (event, newPage)  => {
        setPage(newPage);
    };

    const handleKeyDown = (event) => {
        let texto = `${event.target.value}`;
        setTextSearch(texto);
        if (!texto) {
            setFilter([...products])
        }
    }
    const handleSelectProduct = (product) => {
        setProductsSelect(product);
        //closeModal && closeModal();
        setOpenPopup(true);
    }

    return (
        <>
            <div className="container">
            {loading && <Loading/>}
            <Grid container >
            <Grid item sx={{overflowY:'scroll'}} style={{width: "100%"}}>
                <Grid item >
                    <div className="H-card__fcontainer">
                        <div className="H-card__fcontainer__filter">
                            <span>Filtro:</span>
                            <TextField
                                type="text"
                                id="mi-codigo-de-barras"
                                placeholder="Buscar..."
                                onChange={handleKeyDown}
                                className="H-card__fcontainer__filter__input"
                                style={{ backgroundColor: "white", width: "290px", marginLeft: "10px" }}
                            />
                        </div>
                    </div>
                </Grid>

                <Grid item>
                    <TableContainer component={Paper}>
                        <Table aria-label="simple table">
                            <TableHead>
                                <TableRow>
                                    <TableCell>Código</TableCell>
                                    <TableCell>Nombre</TableCell>
                                    <TableCell>Precio</TableCell>
                                    <TableCell>Unidad Medida</TableCell>
                                </TableRow>
                            </TableHead>

                            <TableBody>
                            {
                                filter && filter.map((product, index) => (
                                    <TableRow
                                        key={index}
                                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                        classes={{
                                            hover: 'hover-row-t'
                                        }}
                                    >
                                        <TableCell component="th" scope="row">
                                            <span className="tabla">{product.codigo_erp}</span>
                                        </TableCell>
                                        <TableCell component="th" scope="row">
                                            <button className="H-card__tableButton" onClick={()=>{handleSelectProduct(product)}}>
                                                <span className="tabla">
                                                    {product.nombre.charAt(0).toUpperCase() + product.nombre.slice(1).toLowerCase()}
                                                </span>
                                            </button>
                                        </TableCell>
                                        <TableCell component="th" scope="row">
                                            <span className="tabla">{product.precio_oferta ? product.precio_oferta : product.precio_standar}</span>
                                        </TableCell>
                                        <TableCell component="th" scope="row">
                                            <span className="tabla">{product?.unidad_medida?.abbreviation}</span>
                                        </TableCell>
                                    </TableRow>
                                ))
                            }
                            </TableBody>

                            <TableFooter>
                                <TableRow>
                                    <TablePagination
                                        count= {filter.length}
                                        rowsPerPage={rowsPerPage}
                                        page={page}
                                        onPageChange={handleChangePage}
                                        onRowsPerPageChange={handleChangeRowsPerPage}
                                        rowsPerPageOptions={[10, 15, 20]}
                                        labelRowsPerPage={<span className="tabla">Filas:</span>}
                                        labelDisplayedRows={({ page }) => {
                                        return `Página: ${page+1}`;
                                        }}
                                        SelectProps={{
                                            inputProps: {
                                            "aria-label": "page number"
                                            }
                                        }}
                                        showFirstButton={true}
                                        showLastButton={true}
                                    />
                                </TableRow>
                            </TableFooter>
                        </Table>
                    </TableContainer>
                </Grid>
            </Grid>
            </Grid>
            </div>
            {
               openPopup === true &&(
                  <Popup
                  largo="sm"
                  title = 'Producto seleccionado'
                  openPopup = {openPopup}
                  setOpenPopup = {setOpenPopup}
                  >
                    <TicketBalanza
                        closeModal={closeModal}
                        productSelect={ProductsSelect}
                    />
                  </Popup>
               )
            }
        </>
    );
}
