import axios from 'axios';
import { API_URL_NUPY } from '@/toolbox/defaults/app';
import { addClienteSofnet, updateClienteSofnet } from './Clientes.repository';

export const ProveedorRepository = {

    getProveedores: async(): Promise<any> => {
        const res = await axios.get(`${API_URL_NUPY}/proveedor/list`);
        return res.data;
    },

    validateProveedoresSofnet: async(): Promise<any> => {
      const res = await axios.post(`${API_URL_NUPY}/proveedor/sync`);
      return res.data;
    },

    register: async(data:any): Promise<any> => {

       const dataSoft= {
          rut: data?.get('rut'),
          nombre_rz: data?.get('razon_social'),
          giro: data?.giro || "giro 1",
          direccion: data?.direccion || "direccion",
          comuna: data?.comuna || "comuna",
          ciudad: data?.ciudad || "ciudad",
          observacion: data?.get("observacion") || ""
         }

        await addClienteSofnet(dataSoft, 0, 1);
        const res = await axios.post(`${API_URL_NUPY}/proveedor/register`, data);
        return res.data;
    },

    update: async(data: any, idProveedor:number): Promise<any> => {
      const dataSoft= {
         rut: data?.get('rut'),
         nombre_rz: data?.get('razon_social'),
         giro: data?.giro || "giro 1",
         direccion: data?.direccion || "direccion",
         comuna: data?.comuna || "comuna",
         ciudad: data?.ciudad || "ciudad",
         observacion: data?.get("observacion") || ""
      }
         await updateClienteSofnet(dataSoft, 0, 1, dataSoft.rut)
        const res = await axios.post(`${API_URL_NUPY}/proveedor/update/${idProveedor}`, data);
        return res.data;
    },

    editState: async(idProveedor:number, state:string): Promise<any> => {
        const res = await axios.patch(`${API_URL_NUPY}/proveedor/editState/${idProveedor}/${state}`);
        return res.data;
    },

    getProveedoresByCompany: async(idCompany: number): Promise<any> => {
        const res = await axios.get(`${API_URL_NUPY}/proveedor/list-ByCompany/${idCompany}`);
        return res.data;
    },
}
