import { ProvinciaRepository } from "../repositories/Provincia.repository";

export const ProvinciaService = {
    getProvincias
}

async function getProvincias(){
    const res = await ProvinciaRepository.get()
    return res
}
