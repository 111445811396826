import React, {  useEffect, useRef } from 'react';

import { Loading } from '@/components/common/Loading/Loading';

import TextField from '@mui/material/TextField';
import TableHead from '@mui/material/TableHead';

import Grid from '@mui/material/Grid';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { styled } from '@mui/material/styles';
import { ticketService } from '@/service/services/Ticket.service';
import moment from 'moment';

import Box from '@mui/material/Box';
import Collapse from '@mui/material/Collapse';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { Button, Divider } from '@mui/material';
import { ComponentPreCuenta } from '@/components/common/ComponentPreCuenta';
import { useReactToPrint } from 'react-to-print';

type Props = {
   idTicket: number
}

const StyledButton = styled(Button)(({ theme }) => ({
   textTransform: 'none',
}));

const Titulo = styled('div')(({ theme }) => ({
   ...theme.typography.button,
   padding: theme.spacing(1),
   fontWeight: 'bold',
   fontSize: '18px',
   color: 'black',
 }));

export const PedidoDetail: React.FC<any> = (props:Props): JSX.Element => {
   const {idTicket} = props;
   const [pedido, setPedido] = React.useState<any>(null);
   const [ticket, setTicket] = React.useState<any>(null);
   const [detalle, setDetalle] = React.useState<Array<any>>([]);
   const [loadData,setLoadData] = React.useState<boolean>(false);

   const [imprimirPreCuenta, setImprimirPrecuenta] = React.useState(null);
   const [pedidoComplete, setPedidoComplete] = React.useState<any>(null);
   const [detalleComplete, setDetalleComplete] = React.useState<Array<any>>([]);
   const [numberTickets, setNumberTickets] = React.useState<any>(null);
   const componentRef = useRef();

   const handlePrint = useReactToPrint({
      content: () => componentRef.current,

   });

   useEffect(() => {
      if (imprimirPreCuenta !== null) {
         handlePrint();
      }
      // eslint-disable-next-line
   }, [imprimirPreCuenta])

   const validatePrice = (product) => {
   const dateToday = moment();
   const value = product.caducidad_precio_oferta;
   const expirationPrice = moment(value, 'DD-MM-YYYY');

   const standarPrice = product.precio_standar || product.precio;
   const offerPrice = product.precio_oferta;

   if(expirationPrice !== null && offerPrice !== null){
      if(expirationPrice.isSameOrAfter(dateToday)){
         return offerPrice;
      }else{
         return standarPrice;
      }
   }else{
      return standarPrice;
   }
   }

   useEffect(()=> {
   if (idTicket) {
      setLoadData(true);
      ticketService.getTicketDetalle(idTicket).then(async (data) => {
         if (data?.status === 200) {
            setPedido(data.ticket.pedidoDetail);
            setTicket(data.ticket);
            let arrayProducts = [];
            for(let i = 0; i < data.ticket.detalle_ticket.length; i++){
               const productos = data.ticket.detalle_ticket[i].productos;
               arrayProducts.push({
                  "cantidad": data.ticket.detalle_ticket[i].cantidad,
                  "id_producto": data.ticket.detalle_ticket[i].id_producto,
                  "codigo_erp": productos?.codigo_erp,
                  "nombre": productos?.nombre,
                  "id_tipo_producto": productos?.id_tipo_producto,
                  "precio_standar": productos?.precio_standar,
                  // "precio_oferta": productos?.precio_oferta
                  "precio_oferta": productos && validatePrice(productos),
                  "precio_unitario": data.ticket.detalle_ticket[i].precio_unitario,
                  "subProductos": data.ticket.detalle_ticket[i].subProductos,
                  "unidad_medida": productos?.unidad_medida?.abbreviation,
               });
            }
            setDetalle(arrayProducts);

            if (data.ticket.pedidoDetail.id) {
               const idPedido = data.ticket.pedidoDetail.id;
               ticketService.getPedidoDetalle(idPedido).then(async (data) => {
                  if (data?.status === 200) {
                     setPedidoComplete(data);
                     setNumberTickets((data.tickets).length);
                     let arrayProducts = [];
                     // eslint-disable-next-line
                     (data.tickets).map((item) => {
                        for(let i=0; i < item.detalle_ticket.length; i++){
                           const productos = item.detalle_ticket[i].productos;
                           arrayProducts.push({
                           "cantidad": item.detalle_ticket[i].cantidad,
                           "id_producto": item.detalle_ticket[i].id_producto,
                           "codigo_erp": productos?.codigo_erp,
                           "nombre": productos?.nombre,
                           "id_tipo_producto": productos?.id_tipo_producto,
                           "cantidad_peso": productos?.cantidad_peso,
                           "precio_unitario": item.detalle_ticket[i].precio_unitario,
                           "subProductos": item.detalle_ticket[i].subProductos,
                           "id_unidad_medida": productos?.id_unidad_medida,
                           "unidad_medida": productos?.unidad_medida?.abbreviation,
                           "familia_id": productos?.familia_id,
                           "sub_familia_id": productos?.sub_familia_id,
                           "linea_id": productos?.linea_id,
                           });
                        }
                     });
                     setDetalleComplete(arrayProducts);
                  }
               })
            }
            setLoadData(false)
         } else {
            setLoadData(false)
         }
      })
   }
   }, // eslint-disable-next-line
   [])


    const Row = (props: { item: any, index: number }) =>  {
      const { item, index } = props;
      const [open, setOpen] = React.useState(false);

      return (
        <React.Fragment>
          <TableRow sx={{ '& > *': { borderBottom: 'unset' } }}>
            <TableCell>
              <IconButton
                aria-label="expand row"
                size="small"
                onClick={() => setOpen(!open)}
                disabled={item.subProductos ? false : true}
              >
                {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
              </IconButton>
            </TableCell>
            <TableCell component="th" scope="row">
               {index+1}
            </TableCell>

            <TableCell align="right">{item.codigo_erp}</TableCell>
            <TableCell align="right">{item.nombre}</TableCell>
            <TableCell align="right">
               {/* <span className={ item.precio_oferta ? 'X' : 'o'}>{ item.precio_oferta ? validatePrice(item) : item.precio_standar}</span> */}
               <span >
                  {
                     item.precio_unitario ? item.precio_unitario :
                     (item.precio_oferta ? item.precio_oferta : item.precio_standar)
                  }
               </span>
            </TableCell>
            <TableCell align="right">{`${item.cantidad} (${item.unidad_medida})`}</TableCell>

          </TableRow>
         {  item.subProductos &&
            <TableRow>
               <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
               <Collapse in={open} timeout="auto" unmountOnExit>
                  <Box sx={{ margin: 1 }}>
                     <Typography variant="h6" gutterBottom component="div">
                     Items del menu
                     </Typography>
                     <Table size="small" aria-label="purchases">
                     <TableHead>
                        <TableRow>
                           <TableCell>Codigo</TableCell>
                           <TableCell>Nombre</TableCell>
                           <TableCell>Cantidad</TableCell>
                        </TableRow>
                     </TableHead>
                     <TableBody>
                        {item.subProductos.map((subPro) => (
                           <TableRow key={subPro.id}>
                              <TableCell component="th" scope="row">
                                 {subPro.codigo_erp}
                              </TableCell>
                              <TableCell>{subPro.nombre}</TableCell>
                              <TableCell>{subPro.cantidad}</TableCell>
                           </TableRow>
                        ))}
                     </TableBody>
                     </Table>
                  </Box>
               </Collapse>
               </TableCell>
            </TableRow>
         }
        </React.Fragment>
      );
    }


    return(
        <Grid
            container spacing={0} direction="column"
            alignItems="center" justifyContent="center"
            style={{ marginBottom: '15px', marginTop:'5px'}}
            >
            <Grid item xs={3}>
               <Titulo>{"Informacion del pedido"}</Titulo>
            </Grid>
            <Grid container>
               <Grid item xs={6} style={{display: "flex", alignItems: "center", marginTop: "20px"}}>
                  <span style={{width: "100px"}}>Cliente: </span>
                  <TextField
                  disabled
                     size="small"
                     value={pedido?.cliente?.nombre || 'Cliente General'}/>
               </Grid>
               <Grid item xs={6} style={{display: "flex", alignItems: "center", marginTop: "20px"}}>
                  <span style={{width: "100px"}}>Estado: </span>
                  <TextField
                  disabled
                     size="small"
                     value={ticket?.state}/>
               </Grid>
               <Grid item xs={6} style={{display: "flex", alignItems: "center", marginTop: "20px"}}>
                  <span style={{width: "100px"}}>Mesa: </span>
                  <TextField
                  disabled
                     size="small"
                     value={pedido?.mesa?.nombre || '---'}/>
               </Grid>
               <Grid item xs={6} style={{display: "flex", alignItems: "center", marginTop: "20px"}}>
                  <span style={{width: "100px"}}>Usuario: </span>
                  <TextField
                     disabled
                     size="small"
                     value={pedido?.usuario?.usuario}/>
               </Grid>
               <Grid item xs={6} style={{display: "flex", alignItems: "center", marginTop: "20px"}}>
                  <span style={{width: "100px"}}>N° Ticket: </span>
                  <TextField
                  disabled
                     size="small"
                     value={ticket?.n_ticket}/>
               </Grid>
               <Grid item xs={6} style={{display: "flex", alignItems: "center", marginTop: "20px"}}>
                  <span style={{width: "100px"}}>Monto Pagado: </span>
                  <TextField
                  disabled
                     size="small"
                     type={"number"}
                     value={ticket?.monto_pedido}/>
               </Grid>
               <Grid item xs={6} style={{display: "flex", alignItems: "center", marginTop: "20px"}}>
                  <span style={{width: "100px"}}>Descuento de beneficio de monto: </span>
                  <TextField
                  disabled
                     size="small"
                     type={"number"}
                     value={pedido?.descuento_monto_total}/>
               </Grid>
               <Grid item xs={6} style={{display: "flex", alignItems: "center", marginTop: "20px"}}>
                  <span style={{width: "100px"}}>Descuento de beneficiopor productoso: </span>
                  <TextField
                  disabled
                     size="small"
                     type={"number"}
                     value={pedido?.descuento_productos_total}/>
               </Grid>
            </Grid>
            <Divider sx={{my:2, width:'100%', borderColor:'#2a29291f !important'}}/>
            <Grid item xs={3}>
               <Titulo>{"Productos"}</Titulo>
            </Grid>
            <TableContainer component={Paper}>
               <Table sx={{ minWidth: 650 }} aria-label="simple table">
                  <TableHead>
                     <TableRow>
                        <TableCell width={1}></TableCell>
                        <TableCell>{`N°`}</TableCell>
                        <TableCell align="right">Codigo</TableCell>
                        <TableCell align="right">Nombre</TableCell>
                        <TableCell align="right">Precio</TableCell>
                        <TableCell align="right">Cantidad</TableCell>
                     </TableRow>
                  </TableHead>
                  <TableBody>
                     {(detalle||[]).map((item,index) => (
                        <Row  item={item} index={index} key={`item-table-${index}`}/>
                     ))}
                  </TableBody>
               </Table>
            </TableContainer>
            {
               detalleComplete.length > 0 && (
                  <>
                     <Divider sx={{my:2, width:'100%', borderColor:'#2a29291f !important'}}/>
                     <Grid sx={{width:'100%', textAlign:'center'}}>
                        <StyledButton
                              variant="contained" color='success' fullWidth
                              onClick={() => {
                                 setImprimirPrecuenta(pedido?.id);
                              }}
                              sx={{ bgcolor: '#229954', width: '40%'}}
                           >
                              Imprimir Precuenta
                        </StyledButton>
                     </Grid>
                  </>
               )
            }
            
            {loadData && <Loading/>}

            { imprimirPreCuenta &&
               <div style={{ display: "none" }}>
                  <div ref={componentRef}>
                     <ComponentPreCuenta
                        pedido={pedidoComplete}
                        detalle={pedidoComplete.detalleInicialTicket}
                        numberTickets={numberTickets}
                     />
                  </div>
               </div>
            }

         </Grid>
    );

}
