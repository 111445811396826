import React, { useEffect } from 'react';
import { Grid, Typography } from '@mui/material';
import { useBarcode } from '@createnextapp/react-barcode';
import { readLocalStorage } from '@/toolbox/helpers/local-storage-helper';
import { KEY_EMPRESA } from '@/toolbox/constants/local-storage';
import { Props } from './ComponentCuponTicket.type';

export const ComponentCuponTicket: React.FC<Props> = (props: Props) => {
    const {data, closeModal} = props;

    const SessionEmpresa: any = readLocalStorage(KEY_EMPRESA);

    function BarCode({ textobarcode }) {
        const { inputRef } = useBarcode({
          value: textobarcode === "" ? "vacio" : textobarcode,
          options: {
            width: 0.9,
            height: 40,
            textAlign: 'center',
            textPosition: 'center',
          }
        });
        return (<img ref={inputRef} alt="barcodeIMG"/>);
    }

    useEffect(() => {
        closeModal && closeModal();
        // eslint-disable-next-line
    }, []);

    return (
        <>
        {
            data && data.map((cupon) => (
            <div style={{  marginBottom: 30, width: '300px', margin: 'auto', marginTop: 30, padding:"10px"}}>
                <div style={{width:"100%"}} >

                    <div style={{textAlign:"center"}}>
                        <Typography sx={{fontSize:'1.3em !important', fontFamily:'sans-serif',fontWeight:'700'}}>
                            {SessionEmpresa?.nombre}
                        </Typography>
                    </div>

                    <hr style={{border: '0', height: '2px', borderTop: '1px dashed black', borderBottom: '1px dashed black'}}/>

                    <div>
                        <Grid container>
                            <Grid item xs={12} style={{textAlign:"center"}}>
                                <Typography sx={{fontSize:'1.2em !important'}}>
                                    ¡ FELICITACIONES !
                                </Typography>
                            </Grid>
                            <Grid item xs={12} style={{textAlign:"center"}}>
                                <Typography sx={{fontSize:'.9em !important'}}>
                                    {`Ud. ha ganado un cupon de ${cupon?.margen_descuento}% de descuento`}
                                </Typography>
                            </Grid>
                        </Grid>
                    </div>

                    <div style={{textAlign:"center"}}>
                        <Grid item xs={8} style={{textAlign:"center"}}>
                            <BarCode textobarcode={`${cupon?.id}:${cupon?.fecha_inicio}:${cupon?.fecha_fin}`} />
                        </Grid>
                    </div>

                    <hr style={{border: '0', height: '2px', borderTop: '1px dashed black', borderBottom: '1px dashed black'}}/>

                    <div>
                        <Grid container>
                            <Grid item xs={12}>
                                <Typography sx={{fontSize:'.9em !important'}}>
                                    {`${cupon?.name}`}
                                </Typography>
                            </Grid>
                        </Grid>
                    </div>

                    <hr/>

                    <div>
                        <Grid container>
                            <Grid item xs={8}>
                                <Typography sx={{textAlign:"left",fontSize:'1em !important'}}>
                                    Válido hasta:
                                </Typography>
                            </Grid>
                            <Grid item xs={4}>
                                <Typography sx={{textAlign:"right",fontSize:'1em !important'}}>
                                    {cupon?.fecha_fin}
                                </Typography>
                            </Grid>
                        </Grid>
                    </div>
                    <hr/>
                    <div>
                        <Grid container>
                            <Grid item xs={12}>
                                <Typography sx={{fontSize:'0.8em !important', textAlign: 'center'}}>
                                    {`*** GRACIAS POR COMPRAR EN ${SessionEmpresa?.nombre} ***`}
                                </Typography>
                            </Grid>
                        </Grid>
                    </div>

                </div>
            </div>
            ))
        }
        </>
    )
}