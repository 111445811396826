import { Loading } from "@/components/common/Loading";
import { Protected } from "@/components/layout/Protected";
import { styled } from '@mui/material/styles';
import React, {  useEffect } from "react";
import { Props } from "./ReporteTable.type";
import { Box,  Grid, MenuItem, Select, TextField } from '@mui/material';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DatePicker from '@mui/lab/DatePicker';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import esLocale from 'date-fns/locale/es';
import moment from 'moment';
import { ventaService } from "@/service/services/Venta.service";
import { productoService } from "@/service/services/Producto.service";
import { ReporteVentas } from "../Reporte/componentes/ReporteVentas";
import { ProductosSimples } from "../Reporte/componentes/productosSimples/ProductosSimples";
import { ProductosMenu } from "../Reporte/componentes/productosMenu/ProductosMenu";
import { ProductosAgrupados } from "../Reporte/componentes/productosAgrupado/ProductosAgrupado";
import { TiposPagos } from "../Reporte/componentes/TiposPagos/TiposPagos";

const Titulo = styled('div')(({ theme }) => ({
    ...theme.typography.button,
    padding: theme.spacing(1),
    fontWeight: 'bold',
    fontSize: '20px',
    color: 'black',
  }));
  const Div = styled('div')(({ theme }) => ({
    ...theme.typography.button,
    padding: theme.spacing(1),
    fontWeight: 'bold',
    fontSize: '20px',
    color: 'rgba(102, 102, 102, 0.4)',
  }));

export const ReporteTable: React.FC<Props> = (props:Props): JSX.Element => {

const [loading,setLoading] = React.useState<boolean>(false);
const [filtro, setFiltro] = React.useState('month');
const [ventasMonth, setVentasMonth] = React.useState(new Date());
const [ventasDay, setVentasDay] = React.useState(new Date());

const [ventas, setVentas] = React.useState([]);

const [productSimple, setProductSimple] = React.useState([]);
const [productMenu, setProductMenu] = React.useState([]);
const [productAgrupado, setProductAgrupado] = React.useState([]);
const [formasPago, setFormasPago] = React.useState([]);

const [fechaDe, setFechaDe] = React.useState(new Date());
const [fechaA, setFechaA] = React.useState(new Date());

useEffect(() => {
    filtrarByMes(ventasMonth)
    filtrarSimplesMes(ventasMonth)
    filtrarAgrupadoMes(ventasMonth)
    filtrarMenuMes(ventasMonth)
    tiposPagosTableMes(ventasMonth)
 },// eslint-disable-next-line
 [])

 //VENTAS
 const filtrarByMes = async(date) =>{
    setLoading(true);
    const fecha = moment(date).format('MM-YYYY');
    await ventaService.getVentasByMonth(fecha).then((data) => {
       setVentas(data);
    })
    setLoading(false);
 }

 const filtrarByDay = async(date) =>{
    setLoading(true);
    const fecha = moment(date).format('DD-MM-YYYY');
    await ventaService.getVentasByDay(fecha).then((data) => {
       setVentas(data);
    })
    setLoading(false);
 }

 const filtrarByFechas = async(fecha_de, fecha_a) =>{
    setLoading(true);
    const fecha1= moment(fecha_de).format('DD-MM-YYYY');
    const fecha2 = moment(fecha_a).format('DD-MM-YYYY');
    await ventaService.getVentasByDFechas(fecha1, fecha2).then((data) => {
       setVentas(data);
    })
    setLoading(false);
 }

 //PRODUCTOS SIMPLES
 const filtrarSimplesMes = async(date) => {
    setLoading(true);
    const fecha = moment(date).format('MM-YYYY');
    await productoService.getProductsSimplesByMonth(fecha).then((data)=>{
       setProductSimple(data)
    })
    setLoading(false);

 }

 const filtrarSimplesDay = async(date) => {
    setLoading(true);
    const fecha = moment(date).format('DD-MM-YYYY');
    await productoService.getProductsSimplesByDay(fecha).then((data)=>{
       setProductSimple(data)
    })
    setLoading(false);

 }

 const filtrarSimplesByFechas = async(fecha_de, fecha_a) => {
    setLoading(true);
    const fecha1= moment(fecha_de).format('DD-MM-YYYY');
    const fecha2 = moment(fecha_a).format('DD-MM-YYYY');
    await productoService.getProductsSimplesByFechas(fecha1, fecha2).then((data)=>{
       setProductSimple(data)
    })
    setLoading(false);

 }
 //PRODUCTOS MENU
 const filtrarMenuMes = async(date) => {
    setLoading(true);
    const fecha = moment(date).format('MM-YYYY');
    await productoService.getProductsMenuByMonth(fecha).then((data)=>{
       setProductMenu(data)
    })
    setLoading(false);
 }

 const filtrarMenuDay = async(date) => {
    setLoading(true);
    const fecha = moment(date).format('DD-MM-YYYY');
    await productoService.getProductsMenuByDay(fecha).then((data)=>{
       setProductMenu(data)
    })
    setLoading(false);

 }

 const filtrarMenuByFechas = async(fecha_de, fecha_a) => {
    setLoading(true);
    const fecha1= moment(fecha_de).format('DD-MM-YYYY');
    const fecha2 = moment(fecha_a).format('DD-MM-YYYY');
    await productoService.getProductsMenuByFechas(fecha1, fecha2).then((data)=>{
       setProductMenu(data)
    })
    setLoading(false);

 }

 //PRODUCTOS AGRUPADO
 const filtrarAgrupadoMes = async(date) => {
    setLoading(true);
    const fecha = moment(date).format('MM-YYYY');
    await productoService.getProductsAgrupadoByMonth(fecha).then((data)=>{
       setProductAgrupado(data)
    })
    setLoading(false);

 }

 const filtrarAgrupadoDay = async(date) => {
    setLoading(true);
    const fecha = moment(date).format('DD-MM-YYYY');
    await productoService.getProductsAgrupadoByDay(fecha).then((data)=>{
       setProductAgrupado(data)
    })
    setLoading(false);

 }

 const filtrarAgrupadoByFechas = async(fecha_de, fecha_a) => {
    setLoading(true);
    const fecha1= moment(fecha_de).format('DD-MM-YYYY');
    const fecha2 = moment(fecha_a).format('DD-MM-YYYY');
    await productoService.getProductsAgrupadoByFechas(fecha1, fecha2).then((data)=>{
       setProductAgrupado(data)
    })
    setLoading(false);

 }

 //FORMAS DE PAGOS MAS USADOS (DASBOARD-DONAS)
   const tiposPagosTableMes = async(date) => {
      setLoading(true);
      const fecha = moment(date).format('MM-YYYY');
      await ventaService.tiposPagosTableMes(fecha).then((data)=>{
         setFormasPago(data)
      })
      setLoading(false);
   }
   const tiposPagosTableDay = async(date) => {
      setLoading(true);
      const fecha = moment(date).format('DD-MM-YYYY');
      await ventaService.tiposPagosTableDay(fecha).then((data)=>{
         setFormasPago(data)
      })
      setLoading(false);
   }
   const tiposPagosTableFechas = async(fecha_de, fecha_a) => {
      setLoading(true);
      const fecha1= moment(fecha_de).format('DD-MM-YYYY');
      const fecha2 = moment(fecha_a).format('DD-MM-YYYY');
      await ventaService.tiposPagosTableFechas(fecha1, fecha2).then((data)=>{
         setFormasPago(data)
      })
      setLoading(false);
   }

    return(
        <Protected>
            {loading && <Loading/>}
            <Box sx={{ marginBottom:'15px'}}>
                <Box sx={{ marginBottom:'10px'}}>
                <Titulo>{"Filtros de busqueda"}</Titulo>
                </Box>
            </Box>

            <Grid container item xs={12} className='container'>
            <Grid item xs={2} spacing={3} style={{marginRight:50}}>
                <Select className='select-container'
                id="filtro"
                value={filtro}
                onChange={(event) => {
                    setFiltro(event.target.value);
                }}
                fullWidth
                >
                <MenuItem value={''}><em>seleccionar filtro</em></MenuItem>
                <MenuItem value={'month'} onClick={() => {
                    filtrarByMes(ventasMonth)
                    filtrarSimplesMes(ventasMonth)
                    filtrarAgrupadoMes(ventasMonth)
                    filtrarMenuMes(ventasMonth)
                    tiposPagosTableMes(ventasMonth)
                }}
                    >
                        ventas por mes
                </MenuItem>
                <MenuItem value={'day'} onClick={() => {
                    filtrarByDay(ventasDay)
                    filtrarSimplesDay(ventasDay)
                    filtrarAgrupadoDay(ventasDay)
                    filtrarMenuDay(ventasDay)
                    tiposPagosTableDay(ventasDay)

                }}
                    >
                        ventas por día
                    </MenuItem>
                <MenuItem value={'dates'}>ventas entre fechas</MenuItem>
                </Select>
            </Grid>

            {
                filtro === 'month' && (
                <Grid item xs={2}>
                <LocalizationProvider dateAdapter={AdapterDateFns} locale={esLocale}>
                <DatePicker
                views={['year', 'month']}
                minDate={new Date('2020-01-01')}
                maxDate={new Date('2023-01-01')}
                value={ventasMonth}
                onChange={(newValue) => {
                    setVentasMonth(newValue);
                    filtrarByMes(newValue);
                    filtrarSimplesMes(newValue);
                    filtrarAgrupadoMes(newValue);
                    filtrarMenuMes(newValue);
                    tiposPagosTableMes(newValue);
                }}
                renderInput={(params) => <TextField {...params} helperText={null} />}
                />
                </LocalizationProvider>
                </Grid>
                )
            }
            {
                filtro === 'day' && (
                <Grid item xs={2}>
                <LocalizationProvider dateAdapter={AdapterDateFns} locale={esLocale}>
                <DatePicker
                views={['year', 'month', 'day']}
                minDate={new Date('2020-01-01')}
                maxDate={new Date('2023-01-01')}
                value={ventasDay}
                onChange={(newValue) => {
                    setVentasDay(newValue);
                    filtrarByDay(newValue);
                    filtrarSimplesDay(newValue);
                    filtrarAgrupadoDay(newValue);
                    filtrarMenuDay(newValue);
                    tiposPagosTableDay(newValue);
                }}
                renderInput={(params) => <TextField {...params} helperText={null} />}
                />
                </LocalizationProvider>
                </Grid>
                )
            }
            {
                filtro === 'dates' && (
                <Grid className='container-fechas' xs={8}>
                <span className='spacing'>Del: </span>
                <Grid item xs={3} style={{marginRight:10}}>
                <LocalizationProvider dateAdapter={AdapterDateFns} locale={esLocale}>
                <DatePicker
                views={['year', 'month', 'day']}
                minDate={new Date('2020-01-01')}
                maxDate={new Date('2023-01-01')}
                value={fechaDe}
                onChange={(newValue) => {
                    setFechaDe(newValue);
                }}
                renderInput={(params) => <TextField {...params} helperText={null} />}
                />
                </LocalizationProvider>
                </Grid>
                <span className='spacing'>al: </span>
                <Grid item xs={3}>
                <LocalizationProvider dateAdapter={AdapterDateFns} locale={esLocale}>
                <DatePicker
                views={['year', 'month', 'day']}
                minDate={new Date('2020-01-01')}
                maxDate={new Date('2023-01-01')}
                value={fechaA}
                onChange={(newValue) => {
                    setFechaA(newValue);
                    filtrarByFechas(fechaDe, newValue);
                    filtrarSimplesByFechas(fechaDe, newValue);
                    filtrarAgrupadoByFechas(fechaDe, newValue);
                    filtrarMenuByFechas(fechaDe, newValue);
                    tiposPagosTableFechas(fechaDe, newValue);
                }}
                renderInput={(params) => <TextField {...params} helperText={null} />}
                />
                </LocalizationProvider>
                </Grid>
                </Grid>
                )
            }

            </Grid>

        {/* TABLAS */}

         <Grid
         container spacing={0} direction="column"
         alignItems="center" justifyContent="center"
         style={{ marginBottom: '20px', marginTop:'30px'}}
         >
            <Grid item xs={3}>
               <Titulo>{"Ventas Realizadas"}</Titulo>
            </Grid>

         </Grid>

         {
            !loading && ventas.length > 0 && (
               <Box sx={{backgroundColor: 'rgba(224, 224, 224, 0.5)', padding: '15px', borderRadius: '10px'}}>
                  <ReporteVentas ventas={ventas} />
               </Box>
            )
         }
         {
            !loading && ventas.length === 0 && (
               <Box sx={{backgroundColor: 'rgba(224, 224, 224, 0.5)', padding: '15px', borderRadius: '10px', height:'100px', justifyContent: 'center',alignItems: 'center', display: 'flex' }} >
                  <Div>{"No se encontraron ventas"}</Div>
            </Box>
            )
         }
         {loading &&
            <Box sx={{backgroundColor: 'rgba(224, 224, 224, 0.5)', padding: '15px', borderRadius: '10px', height:'100px'}}>
            </Box>
         }

         <Grid container spacing={0} alignItems="center" justifyContent="center" style={{ marginBottom: '20px', marginTop:'30px', textAlign:'center' }}>
            <Grid item xs={6}>
                  <Titulo>{"Tipos de Pagos"}</Titulo>
                  {
                     !loading && formasPago.length > 0 && (
                        <Box sx={{backgroundColor: 'rgba(224, 224, 224, 0.5)', padding: '15px', borderRadius: '10px', marginRight: '3px'}}>
                           <TiposPagos formasPago={formasPago}/>
                        </Box>
                     )
                  }
                  {
                     !loading && formasPago.length === 0 && (
                        <Box sx={{backgroundColor: 'rgba(224, 224, 224, 0.5)', padding: '15px', borderRadius: '10px', height:'100px', justifyContent: 'center',alignItems: 'center', display: 'flex' , marginRight: '3px'}} >
                           <Div>{"No se encontraron ventas"}</Div>
                     </Box>
                     )
                  }
                  {loading &&
                     <Box sx={{backgroundColor: 'rgba(224, 224, 224, 0.5)', padding: '15px', borderRadius: '10px', height:'100px', marginRight: '3px'}}>
                     </Box>
                  }
            </Grid>
            <Grid item xs={6}>
                  <Titulo>{"Productos Simples Vendido"}</Titulo>
                  {
                     !loading && productSimple.length > 0 && (
                        <Box sx={{backgroundColor: 'rgba(224, 224, 224, 0.5)', padding: '15px', borderRadius: '10px', marginRight: '3px'}}>
                           <ProductosSimples productSimple={productSimple}/>
                        </Box>
                     )
                  }
                  {
                     !loading && productSimple.length === 0 && (
                        <Box sx={{backgroundColor: 'rgba(224, 224, 224, 0.5)', padding: '15px', borderRadius: '10px', height:'100px', justifyContent: 'center',alignItems: 'center', display: 'flex' , marginRight: '3px'}} >
                           <Div>{"No se encontraron ventas"}</Div>
                     </Box>
                     )
                  }
                  {loading &&
                     <Box sx={{backgroundColor: 'rgba(224, 224, 224, 0.5)', padding: '15px', borderRadius: '10px', height:'100px', marginRight: '3px'}}>
                     </Box>
                  }
            </Grid>
            <Grid item xs={6}>
                  <Titulo>{"Productos Menu Vendido"}</Titulo>
                  {
                     !loading && productMenu.length > 0 && (
                        <Box sx={{backgroundColor: 'rgba(224, 224, 224, 0.5)', padding: '15px', borderRadius: '10px', marginLeft: '3px'}}>
                           <ProductosMenu productMenu={productMenu}/>
                        </Box>
                     )
                  }
                  {
                     !loading && productMenu.length === 0 && (
                        <Box sx={{backgroundColor: 'rgba(224, 224, 224, 0.5)', padding: '15px', borderRadius: '10px', height:'100px', justifyContent: 'center',alignItems: 'center', display: 'flex' , marginLeft: '3px'}} >
                           <Div>{"No se encontraron ventas"}</Div>
                     </Box>
                     )
                  }
                  {loading &&
                     <Box sx={{backgroundColor: 'rgba(224, 224, 224, 0.5)', padding: '15px', borderRadius: '10px', height:'100px', marginLeft: '3px'}}>
                     </Box>
                  }
            </Grid>
            <Grid item xs={8}>
                  <Titulo>{"Productos Agrupado Vendido"}</Titulo>
                  {
                     !loading && productAgrupado.length > 0 && (
                        <Box sx={{backgroundColor: 'rgba(224, 224, 224, 0.5)', padding: '15px', borderRadius: '10px'}}>
                           <ProductosAgrupados productAgrupado={productAgrupado}/>
                        </Box>
                     )
                  }
                  {
                     !loading && productAgrupado.length === 0 && (
                        <Box sx={{backgroundColor: 'rgba(224, 224, 224, 0.5)', padding: '15px', borderRadius: '10px', height:'100px', justifyContent: 'center',alignItems: 'center', display: 'flex' }} >
                           <Div>{"No se encontraron ventas"}</Div>
                     </Box>
                     )
                  }
                  {loading &&
                     <Box sx={{backgroundColor: 'rgba(224, 224, 224, 0.5)', padding: '15px', borderRadius: '10px', height:'100px'}}>
                     </Box>
                  }
            </Grid>
         </Grid>
        </Protected>
    );
}
