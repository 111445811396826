import { TOKEN_API } from "@/toolbox/constants/local-storage";
import { useLocalStorage } from "@/toolbox/hooks/local-storage.hook";
import { Autocomplete, Grid, Select, TextField, MenuItem, FormControl, Button, IconButton, Stack } from "@mui/material";
import React, { useEffect } from "react";
import { Props } from "./HomePersonalized.type";
import { Snackbar, Alert } from '@mui/material';
import  "./HomePersonalized.sass";
import { Loading } from "@/components/common/Loading";
import { productoService } from "@/service/services/Producto.service";
import ClearIcon from '@mui/icons-material/Clear';
const typeFilters = [
    {id:1, name: 'Familia'},
    {id:2, name: 'Linea'},
]

export const HomePersonalized2: React.FC<any> = (props:any):JSX.Element => {
    const { setFilteredProducts, setUseFilters, dataFamilies, dataLines } = props;

    const [loading, setLoading] = React.useState<boolean>(false);
    
    const [term1, setTerm1] = React.useState<any>('');
    const [term2, setTerm2] = React.useState<any>('');
    const [term3, setTerm3] = React.useState<any>('');
    const [searchTimeout, setSearchTimeout] = React.useState<any>(null);
    const [typeFilterSelected, setTypeFilterSelected] = React.useState<any>(null);
    const [lineSelected, setLineSelected] = React.useState<any>("");
    const [familySelected, setFamilySelected] = React.useState<any>("");

    const [snackBarConfig, setSnackBarConfig] = React.useState<any>({
        open: false,
        severity: 'error',
        message: 'Error',
        autoHideDuration: 3000,
    });

    const onListProducts = async(term1, term2, term3) => {
        setLoading(true);
        await productoService.searchProductsByFilters(term1, term2, term3).then((data) => {
            setFilteredProducts(data.data || []);
            setUseFilters(true);
        })
        setLoading(false);
    }

    const searchProducts = (term1, term2, term3) => {
        clearTimeout(searchTimeout);
        setSearchTimeout(
            setTimeout(() => {onListProducts(term1, term2, term3)}, 1000)
        )
    }

    useEffect(()=>{
 const familyInitial = dataFamilies.filter((item) => item.nombre == "ARRIENDOS")
 console.log(familyInitial)
 if(familyInitial){
 setFamilySelected(familyInitial[0])
    setTerm1(familyInitial[0]?.nombre ? familyInitial[0]?.nombre : '');
    searchProducts(familyInitial[0]?.nombre || "", "", "");   
 }else{
    searchProducts("", "", "");   
 }

    },[dataFamilies])
   
    return (
        <Grid item sx={{width: '100%'}}>
            {loading && <Loading/>}
            <Snackbar
                open={snackBarConfig.open}
                autoHideDuration={snackBarConfig.autoHideDuration}
                onClose={() => setSnackBarConfig(prev => ({ ...prev, open: false }))}
                anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                >
                <Alert
                onClose={() => setSnackBarConfig(prev => ({ ...prev, open: false }))}
                severity={snackBarConfig.severity}
                variant="filled"
                > 
                {snackBarConfig.message}
                </Alert>
            </Snackbar>

            <Grid sx={{ Width: '100%', bgcolor: 'background.paper', padding: '10px', display:'flex', alignItems: "center", borderRadius: '10px'}} container>

                
                {/* <Grid item xs={6} style={{ display: "flex", alignItems: "center", marginBottom: "10px" }}>
                    <span style={{ marginRight: "10px", width: "150px" }}>Filtrar por:</span>
                    <FormControl fullWidth>
                        <Stack direction="row" spacing={1} alignItems="center">
                            <Select
                                labelId="tipoPago"
                                id="tipoPago"
                                size='small'
                                name="tipoPago"
                                value={typeFilterSelected}
                                onChange={e => setTypeFilterSelected(e.target.value)}
                                fullWidth
                            >
                                {typeFilters.map((option, i) => {
                                    return <MenuItem key={i} value={option.id} onClick={() => { setTypeFilterSelected(option.name) }}>{option.name}</MenuItem>
                                })
                                }
                            </Select>
                            <IconButton aria-label="delete" size="small" onClick={() => { setTypeFilterSelected(null);
                                setTerm1(''); setTerm3('');  searchProducts("", "", "");
                            }}>
                                <ClearIcon sx={{ fontSize: 20 }} />
                            </IconButton>
                        </Stack>
                    </FormControl>
                </Grid> */}
                {/* { typeFilterSelected == 1 && */}
                <Grid item xs={6} style={{ display: "flex", alignItems: "center", marginBottom: "10px", marginTop: "8px", paddingRight:'5px' }}>
                    {/* <span style={{ marginRight: "10px", width: '170px' }}><b>Familias:</b></span> */}
                    <Autocomplete
                        disablePortal
                        id="combo-box-demo"
                        value={familySelected}
                        options={dataFamilies || []}
                        getOptionLabel={(option) => option?.nombre || ""}
                        isOptionEqualToValue={(option, value) => option?.id === value?.id}
                        fullWidth
                        renderInput={(params) => <TextField {...params} label="Familias" />}
                        onChange={(e, newValue) => {
                            setFamilySelected(newValue);
                            setTerm1(newValue?.nombre ? newValue?.nombre : '');
                            searchProducts(newValue?.nombre || "", "", "");     
                        }}
                        size="small"
                    />
                </Grid>
                {/* }
                { typeFilterSelected == 2 && */}
                <Grid item xs={6} style={{ display: "flex", alignItems: "center", marginBottom: "10px", marginTop: "8px" }}>
                    {/* <span style={{ marginRight: "10px", width: '170px' }}><b>Lineas:</b></span> */}
                    <Autocomplete
                        disablePortal
                        id="combo-box-demo"
                        value={lineSelected}
                        options={dataLines || []}
                        getOptionLabel={(option) => option?.nombre || ""}
                        isOptionEqualToValue={(option, value) => option?.id === value?.id}
                        fullWidth
                        renderInput={(params) => <TextField {...params} label="Lineas" />}
                        onChange={(e, newValue) => {
                            setFamilySelected(newValue);
                            setTerm3(newValue?.nombre ? newValue?.nombre : '');
                            searchProducts( "", "", newValue?.nombre || "");     
                        }}
                        size="small"
                    />
                </Grid>
                {/* } */}
            </Grid>
        </Grid>
    );
}