import {
    ACTION_ECOMMERCE_CLIENT,
} from '@constants/action-type';
 
 export function actionSetEcommerce(data: any) {
    return {
       type: ACTION_ECOMMERCE_CLIENT,
       payload: data
    }
}
 