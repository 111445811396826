import axios from 'axios';
import { API_URL_NUPY } from '@/toolbox/defaults/app';

export const BeneficioProductoRepository = {
    getBeneficioProducto: async(): Promise<any> => {
        const res = await axios.get(`${API_URL_NUPY}/puntos-producto/list`);
        return res.data;
    },

    register: async(data:{}): Promise<any> => {
        const res = await axios.post(`${API_URL_NUPY}/puntos-producto/register`, data);
        return res;
    },

    update: async(data:{}, idbeneficioProducto:number): Promise<any> => {
        const res = await axios.post(`${API_URL_NUPY}/puntos-producto/update/${idbeneficioProducto}`, data);
        return res;
    },

    deleteBeneficio: async(idbeneficioProducto:number): Promise<any> => {
        const res= await axios.delete(`${API_URL_NUPY}/puntos-producto/delete/${idbeneficioProducto}`)
        return res;
    }
}