import moment from "moment";

export const getNamePorductByListGeneral = (
   product, productsShop
) => {
   let codigo_erp = product?.codigo || "";
   let exist = (productsShop|| []).find(p => p.codigo_erp === codigo_erp);

   if ( exist ) {
      return exist.nombre;
   } else {
      let nameShop = (product.nombre||'').charAt(0).toUpperCase() + (product.nombre||'').slice(1).toLowerCase();
      return nameShop;
   }
};

export const getStockPorductByListGeneral = (
   product, productsShop
) => {
   let codigo_erp = product?.codigo || "";
   let exist = (productsShop|| []).find(p => p.codigo_erp === codigo_erp);

   if ( exist ) {
      if(exist.stock < 1) return 'sin stock';
      return exist.stock;
   } else {
      let stock = (product.stock || 0);
      if(stock < 1) return 'sin stock';
      return stock;
   }
};

export const getPricePorductByListGeneral = (
   product, productsShop, listaPrecio
) => {
   let codigo_erp = product?.codigo || "";
   let exist = (productsShop|| []).find(p => p.codigo_erp === codigo_erp);
   if ( exist ) {
      let price = 0;
      price = validatePrice(exist, listaPrecio);
      return price;

   } else {
      let price = 0;
      if (product.precio_oferta !== "" && product.precio_oferta !== "0") {
         price = product.precio_oferta;
      } else {
         price = product.precio;
      }
      return price;
   }
};

export const getPricePorductByCarrito = (
   product, listaPrecio
) => {
   if (product?.precio ) {
      let price = 0;
      if (product.precio_oferta !== "" && product.precio_oferta !== "0") {
         price = product.precio_oferta;
      } else {
         price = product.precio;
      }
      return price;
   } else {
      let price = 0;
      price = validatePrice(product, listaPrecio)
      return price
   }
};

export const validatePrice = (product, listaPrecio) => {
   //validar si existe en la lista de precio
   let existList = (listaPrecio|| []).find(p => p.codigo_erp === product.codigo_erp);
   if (existList) {
      return existList?.precio_ajustado || 0;
   }

   const dateToday = moment();
   const value = product.caducidad_precio_oferta;
   const expirationPrice = moment(value, 'DD-MM-YYYY');

   const standarPrice = product.precio_standar ? product.precio_standar : Number(product.precio);
   const offerPrice = Number(product.precio_oferta);

   if(expirationPrice !== null && offerPrice !== null && offerPrice !== 0){
      if(expirationPrice.isSameOrAfter(dateToday)){
         return offerPrice;
      }else{
         return standarPrice;
      }
   }else{
      return standarPrice;
   }
}
