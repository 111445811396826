import { productoService } from "@/service/services/Producto.service";
import { distribucionService } from "@/service/services/Distribucion.service";
import { Divider, Grid, MenuItem, Paper, Select, Table, TableBody, TableCell, TableContainer, TableFooter, TableHead, TablePagination, TableRow, TextField } from "@mui/material";
import React, { useEffect } from "react";
import { Props } from "./ProductsNoInsumo.type";
import {KEY_EMPRESA} from '@constants/local-storage';
import { useLocalStorage } from "@/toolbox/hooks/local-storage.hook";
import { Loading } from "@/components/common/Loading";
import { Snackbar, Alert } from '@mui/material';
import { SelectChangeEvent } from '@mui/material/Select';

export const ProductsNoInsumo: React.FC<Props> = (props: Props): JSX.Element => {

    const {closeModal} = props;

    const [dataEmpresa] = useLocalStorage(KEY_EMPRESA, {
        id: 0, id_tipo_empresa:0, equivalencia_puntos: 0,
        id_country: 0, country_name: "", country_abbreviation: "",
    });
    const [loading, setLoading] = React.useState<boolean>(false);
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    const [products, setProducts] = React.useState<Array<any>>([]);
    const [filter, setFilter] = React.useState<Array<any>>([]);
    const [textSearch, setTextSearch] = React.useState<string>("");
    const [ProductsSelect, setProductsSelect] = React.useState([]);
    const [unidadMedida, setUnidadMedida] = React.useState([]);
    const [selectMedida, setSelectMedida] = React.useState<any>(null);
    const [snackBarConfig, setSnackBarConfig] = React.useState<any>({
        open: false,
        severity: 'error',
        message: 'Error',
        autoHideDuration: 3000,
    });

    useEffect(() => {
        if (textSearch) {
           let copyFilters = []
           copyFilters = products.filter(producto => (producto?.nombre.toLowerCase().includes(textSearch.toLowerCase()) || producto?.codigo_erp.toLowerCase().includes(textSearch.toLowerCase())) )
           setFilter([...copyFilters])
        }
        // eslint-disable-next-line
     }, [textSearch])

    useEffect(() => {
        getProductos();
        getUnidadMedida();
        const array = localStorage.getItem('productsArray');
        if(array){
            setProductsSelect(JSON.parse(array));
        }
      // eslint-disable-next-line
    }, []);

    const getProductos = async() => {
        setLoading(true);
        await productoService.getProductosNoInsumo(dataEmpresa.id).then((data) => {
            if(data.status === 200){
                setProducts(data.productos);
                setFilter(data.productos);
            }
            setLoading(false);
        })
    }

    const getUnidadMedida =  async() => {
        setLoading(true);
        await distribucionService.ListUnidadMedida().then((data) => {
            setUnidadMedida(data || []);
            setLoading(false);
        })
    }

    const handleChangeRowsPerPage = (event)   =>{
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };
     const handleChangePage = (event, newPage)  => {
        setPage(newPage);
    };

    const handleMedidaChange = (e: SelectChangeEvent) =>{
        setSelectMedida(e.target.value);
    }

    const handleDeleteProduct = (index: number) => {
        let card: Array<any> = ProductsSelect;
        card.splice(index, 1);
        setProductsSelect([...card]);
    }

    const getProductCantidad = (e, index: number) => {

        let card: Array<any> = ProductsSelect;
        card[index].cantidad = e.target.value;
        setProductsSelect([...card]);
    }

    const getCantidad_x_producto = (e, index: number) => {
        let card: Array<any> = ProductsSelect;
        card[index].productos_x_cantidad = e.target.value;
        setProductsSelect([...card]);
    }

    const handleKeyDown = (event) => {
        let texto = `${event.target.value}`;
        setTextSearch(texto);
        if (!texto) {
            setFilter([...products])
        }
    }
    const handleSelectProduct = (product) => {
        let card: Array<any> =  ProductsSelect;
        let exits: boolean = false;
        for (let i = 0; i < card.length; i++) {
            if(card[i].codigo_erp === product?.codigo_erp && card[i].nombre === product?.nombre){
                exits = true
            }
        }
        if (!exits) {
            card.push({
               ...product,
            })
        }
        setProductsSelect([...card]);
    }

    const SaveProducts = () => {
        let sinCantidad: number = 0;
        if(selectMedida != null){
            if(ProductsSelect.length > 0) {
                for (let i = 0; i < ProductsSelect.length; i++) {
                   if(!ProductsSelect[i].cantidad || ProductsSelect[i].cantidad<1 || ProductsSelect[i].cantidad === "0" ||
                        !ProductsSelect[i].productos_x_cantidad || ProductsSelect[i].productos_x_cantidad<1 || ProductsSelect[i].productos_x_cantidad === "0"){
                      sinCantidad++;
                    }
                 }
                 if(sinCantidad===0){
                     localStorage.setItem('productsArray', JSON.stringify(ProductsSelect));
                     closeModal && closeModal();
                 }else{
                     setSnackBarConfig(prev => ({
                         ...prev,
                         open: true,
                         message: `llenar todos los campos cantidad`,
                     }));
                 }
     
             }else{
                 setSnackBarConfig(prev => ({
                     ...prev,
                     open: true,
                     message: `Seleccionar almenos un producto para la distribucion`,
                 }));
             }
        }else{
            setSnackBarConfig(prev => ({
                ...prev,
                open: true,
                message: `Seleccionar una unidad de medida`,
            }));
        }
    }

    return (
        <>
        <div className="container">
            {loading && <Loading/>}
            <Snackbar
                open={snackBarConfig.open}
                autoHideDuration={snackBarConfig.autoHideDuration}
                onClose={() => setSnackBarConfig(prev => ({ ...prev, open: false }))}
                anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                >
                <Alert
                onClose={() => setSnackBarConfig(prev => ({ ...prev, open: false }))}
                severity={snackBarConfig.severity}
                variant="filled"
                >
                {snackBarConfig.message}
                </Alert>
            </Snackbar>

            <Grid container>
                
            <Grid item sm={5}  sx={{minHeight:'60vh', maxHeight:'60vh', overflowY:'scroll'}}>
                <Grid item >
                    <div className="H-card__fcontainer">
                        <div className="H-card__fcontainer__filter">
                            <span>Filtro:</span>
                            <TextField
                                type="text"
                                id="mi-codigo-de-barras"
                                placeholder="Buscar..."
                                onChange={handleKeyDown}
                                className="H-card__fcontainer__filter__input"
                                style={{ backgroundColor: "white", width: "290px", marginLeft: "10px" }}
                            />
                        </div>
                    </div>
                </Grid>

                <Grid item>
                    <TableContainer component={Paper}>
                        <Table sx={{ minWidth: 300}} aria-label="simple table">
                            <TableHead>
                                <TableRow>
                                    <TableCell>Código</TableCell>
                                    <TableCell>Nombre</TableCell>
                                </TableRow>
                            </TableHead>

                            <TableBody>
                            {
                                filter && filter.map((product, index) => (
                                    <TableRow
                                        key={product.id}
                                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                        classes={{
                                            hover: 'hover-row-t'
                                        }}
                                    >
                                        <TableCell component="th" scope="row">
                                            <span className="tabla">{product.codigo_erp}</span>
                                        </TableCell>
                                        <TableCell component="th" scope="row">
                                            <button className="H-card__tableButton" onClick={()=>handleSelectProduct(product)}>
                                                <span className="tabla">
                                                    {product.nombre.charAt(0).toUpperCase() + product.nombre.slice(1).toLowerCase()}
                                                </span>
                                            </button>
                                        </TableCell>
                                    </TableRow>
                                ))
                            }
                            </TableBody>

                            <TableFooter>
                                <TableRow>
                                    <TablePagination
                                        count= {filter.length}
                                        rowsPerPage={rowsPerPage}
                                        page={page}
                                        onPageChange={handleChangePage}
                                        onRowsPerPageChange={handleChangeRowsPerPage}
                                        rowsPerPageOptions={[10, 15, 20]}
                                        labelRowsPerPage={<span className="tabla">Filas:</span>}
                                        labelDisplayedRows={({ page }) => {
                                        return `Página: ${page+1}`;
                                        }}
                                        SelectProps={{
                                            inputProps: {
                                            "aria-label": "page number"
                                            }
                                        }}
                                        showFirstButton={true}
                                        showLastButton={true}
                                    />
                                </TableRow>
                            </TableFooter>
                        </Table>
                    </TableContainer>
                </Grid>
            </Grid>

            <Grid item sm={7} container direction='row'>
                <Grid xs={12} sx={{minHeight:'60vh', maxHeight:'60vh', overflowY:'scroll', padding: '10px'}}>
                <div className="H-card__title" style={{paddingLeft: '10px', paddingTop:'10px'}}>
                    {`Listado de Productos seleccionados`}
                </div>
                <div className="H-card__separator"></div>
                <div style={{paddingLeft: '10px', paddingTop:'10px', paddingBottom: '20px'}}>
                    {`Unidad de medida: `}
                    <Select sx={{height: "30px", width: "200px", marginLeft: "10px"}}
                        id="select"
                        value={selectMedida}
                        onChange={handleMedidaChange}
                    >
                        <MenuItem value={''} disabled><em>seleccionar</em></MenuItem>
                        {
                            (unidadMedida && unidadMedida.length > 0) && unidadMedida.map((item, index) => (
                                <MenuItem 
                                    value={item.id} 
                                    key={index} 
                                    onClick={() =>{
                                        localStorage.setItem('UM', JSON.stringify(item));
                                    }}
                                >
                                    {item.name}
                                </MenuItem>
                            ))
                        }
                    </Select>
                </div>
                <div className="H-card__separator"></div>
                <div className="H-card__list">
                    {
                        ProductsSelect && ProductsSelect.map((product, index) => (
                            <div className="H-card__list__item" key={index}>
                                <div className="content-info">
                                    <div className="info">
                                        <span className="name">
                                            {product.nombre.charAt(0).toUpperCase() + product.nombre.slice(1).toLowerCase()}
                                        </span>
                                    </div>

                                    <div>
                                        <span style={{marginRight: "2px"}}>
                                            <b>cantidad</b>

                                            <input  style={{width: "45px"}} type="number" name="cantidad" placeholder="cantidad"
                                            value={product.cantidad}
                                            onChange={(e)=>getProductCantidad(e,index)} required/>
                                        </span>
                                        <span style={{marginLeft: "2px"}}>
                                            <b>N° produc</b>

                                            <input  style={{width: "45px"}} type="number" name="productos_x_cantidad" placeholder="cantidad"
                                            value={product.productos_x_cantidad}
                                            onChange={(e)=>getCantidad_x_producto(e,index)} required/>
                                        </span>
                                    </div>
                                </div>
                                <button className="dissmiss-more delete" onClick={()=>handleDeleteProduct(index)}>x</button>
                            </div>
                        ))
                    }
                </div>
                </Grid>
            </Grid>
            </Grid>
        </div>
        <Divider sx={{ my: 2 }} />
            <div className="form-group mb-3 buton_container_modal">
                <button className="btn btn-primary" onClick={SaveProducts}>
                    Guardar Productos
                </button>
            </div>
        </>
    );
}
