import React, { useEffect, useState } from 'react';
import { Autocomplete, TextField } from '@mui/material';
import { Loading } from '@/components/common/Loading/Loading';
import { beneficioProductoService } from '@/service/services/BeneficioProducto.service';
import { productoService } from "@/service/services/Producto.service";
import { useLocalStorage } from '@/toolbox/hooks/local-storage.hook';
import { KEY_USER_DATA } from '@/toolbox/constants/local-storage';

import { Divider, Grid, Typography, Tooltip } from '@mui/material';
import { Form, Formik } from 'formik';
import PriorityHighIcon from '@mui/icons-material/PriorityHigh';
import { Snackbar, Alert } from '@mui/material';

export const AddModal: React.FC<any> = (props:any): JSX.Element => {
    const {popUp, closeModal, onListBeneficioProducto, beneficioProducto} = props;

    const [loading, setLoading] = React.useState<boolean>(false);

    const [beneficioProductoSelect,setBeneficioProductoSelect] = useState<any>("");

    const [productsByCompany, setProductsByCompany] = useState<Array<any>>([]);

    // eslint-disable-next-line
    const [dataProducto, setDataProducto] = React.useState({
        id: beneficioProducto && beneficioProducto.id,
        cantidad_productos: beneficioProducto && beneficioProducto.cantidad_productos,
        cantidad_puntos: beneficioProducto && beneficioProducto.cantidad_puntos,
        id_producto: beneficioProducto && beneficioProducto.id_producto,
    });

    const [snackBarConfig, setSnackBarConfig] = useState<any>({
        open: false,
        severity: 'error',
        message: 'Error',
        autoHideDuration: 3000,
    });

    useEffect(() => {
        getProductsCompany();
    },  // eslint-disable-next-line
    [popUp])

    const [dataUser] = useLocalStorage(KEY_USER_DATA, undefined);
    const idCompany = dataUser && dataUser.user.id_empresa;

    const getProductsCompany = async() =>{
        setLoading(true);
        await productoService.getProductsByCompany(idCompany).then((data) =>{
            if(data.status === 200){
                setProductsByCompany(data.productos.map((el) =>{
                    return {label: el.nombre, key: el.id};
                }));
            }
            setLoading(false);
            if(data.productos){
                setBeneficioProductoSelect({
                    label: data.productos[0].nombre,
                    key: data.productos[0].id
                })
            }
        });
    }

    const validateForm = (values) => {
        let errors: any ={};
        if(!values.cantidad_productos) errors.cantidad_productos = "cantidad requerido";
        if(!values.cantidad_puntos) errors.cantidad_puntos = "cantidad requerido";

        return errors;
    }
    const onSubmit = async(values, {resetForm}) => {
        const formData = new FormData();
        formData.append('cantidad_productos', values.cantidad_productos);
        formData.append('cantidad_puntos', values.cantidad_puntos);

        setLoading(true);
        if(beneficioProducto){
            await beneficioProductoService.update(formData, beneficioProducto.id).then((data) => {
                if(data.status === 200){
                    onListBeneficioProducto && onListBeneficioProducto();
                    closeModal();
                }
            })
        }else{
            formData.append('id_producto', beneficioProductoSelect.key)

            await beneficioProductoService.register(formData).then((data) => {
                if(data.data.status === 400){
                    setSnackBarConfig(prev => ({
                        ...prev,
                        open: true,
                        message: `${data.data.error}`,
                    }));
                }else{
                    onListBeneficioProducto && onListBeneficioProducto();
                    closeModal();
                }
            })
        }
        setLoading(false);
    }

    return(
        <div className="container">
            {loading && <Loading/>}

            <Snackbar
                open={snackBarConfig.open}
                autoHideDuration={snackBarConfig.autoHideDuration}
                onClose={() => setSnackBarConfig(prev => ({ ...prev, open: false }))}
                anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                >
                <Alert
                onClose={() => setSnackBarConfig(prev => ({ ...prev, open: false }))}
                severity={snackBarConfig.severity}
                variant="filled"
                >
                {snackBarConfig.message}
                </Alert>
            </Snackbar>

            <Formik enableReinitialize initialValues={dataProducto} validate={ (values) => validateForm(values) } onSubmit={onSubmit}>
                {({values, errors, touched, handleSubmit, handleChange, setFieldValue}) => {
                    return(
                        <Form onSubmit={handleSubmit}>
                            <Grid container spacing={3} alignItems="center" justifyContent="center">

                                {
                                    !beneficioProducto && (
                                        <Grid item xs={12} container style={{display: "flex", alignItems: "center"}}>
                                        <Grid item xs={4}>
                                            <Typography variant="subtitle1" className="custom-input"><b>Productos:</b></Typography>
                                        </Grid>
                                        <Grid item xs={8}>
                                            <Autocomplete
                                                disablePortal
                                                id="combo-box-demo"
                                                options={productsByCompany || []}
                                                getOptionLabel={(option) => option.label || ""}
                                                sx={{ width: 367 }}
                                                renderInput={(params) => <TextField {...params} label="Escriba para buscar" />}
                                                value={beneficioProductoSelect}
                                                onChange={(e, newValue) => {
                                                    setBeneficioProductoSelect(newValue);
                                                }}
                                            />
                                        </Grid>
                                        </Grid>
                                    )
                                }

                                <Grid item xs={12} container style={{display: "flex", alignItems: "center"}}>
                                <Grid item xs={4}>
                                    <Typography variant="subtitle1" className="custom-input"><b>Cantidad de Productos:</b></Typography>
                                </Grid>
                                <Grid item xs={8} style={{display: 'flex', alignItems: "center"}}>
                                    {
                                        errors.cantidad_productos && touched.cantidad_productos ? true : false && (
                                            <Tooltip title={errors.cantidad_productos}>
                                                <PriorityHighIcon className={'iconWarning'}/>
                                            </Tooltip>
                                        )
                                    }
                                    <TextField
                                    id="cantidad_productos"
                                    type="number"
                                    name="cantidad_productos"
                                    fullWidth
                                    value={values.cantidad_productos}
                                    onChange={handleChange}
                                    placeholder="Cantidad de Productos"
                                    error={errors.cantidad_productos && touched.cantidad_productos ? true : false}
                                    />
                                </Grid>
                                </Grid>

                                <Grid item xs={12} container style={{display: "flex", alignItems: "center"}}>
                                <Grid item xs={4}>
                                    <Typography variant="subtitle1" className="custom-input"><b>Puntos por Producto:</b></Typography>
                                </Grid>
                                <Grid item xs={8} style={{display: 'flex', alignItems: "center"}}>
                                    {
                                        errors.cantidad_puntos && touched.cantidad_puntos ? true : false && (
                                            <Tooltip title={errors.cantidad_puntos}>
                                                <PriorityHighIcon className={'iconWarning'}/>
                                            </Tooltip>
                                        )
                                    }
                                    <TextField
                                    id="cantidad_puntos"
                                    type="number"
                                    name="cantidad_puntos"
                                    fullWidth
                                    value={values.cantidad_puntos}
                                    onChange={handleChange}
                                    placeholder="Cantidad de Puntos"
                                    error={errors.cantidad_puntos && touched.cantidad_puntos ? true : false}
                                    />
                                </Grid>
                                </Grid>
                            </Grid>
                            <Divider sx={{ my: 2 }} />
                            <div className="form-group mb-3 buton_container_modal">
                            <button type="submit" className="btn btn-primary">
                                {beneficioProducto ? "Editar Benefico" : "Agregar Beneficio" }
                            </button>
                        </div>
                        </Form>
                    )
                }}

            </Formik>
        </div>
    )
}
