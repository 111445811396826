import { Props } from "./Cocinas.type";
import { Loading } from "@/components/common/Loading";
import { Protected } from "@/components/layout/Protected";
import { Box, Button, Container, createTheme, Divider, Grid, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, ThemeProvider, Typography, FormControlLabel, Switch, TextField, InputAdornment } from "@mui/material";
import { esES } from "@mui/material/locale";
import { Popup } from "../Editor/components/Popup";
import ContentPasteOffIcon from '@mui/icons-material/ContentPasteOff';
import { PencilIcon } from "@/toolbox/constants/icons";
import DeleteIcon from '@mui/icons-material/Delete';
import React from "react";
import { ModalCocina } from "./components/ModalCocina";
import { cocinaService } from "@/service/services/Cocina.service";
import SearchIcon from "@mui/icons-material/Search";

const theme = createTheme(
    {
        palette: {
            primary: { main: '#1976D2' },
        },
    },
    esES,
);

export const Cocinas: React.FC<Props> = (props: Props): JSX.Element => {

    const [loading, setLoading] = React.useState<boolean>(false);
    const [openPopup, setOpenPopup] = React.useState<boolean>(false);
    const [editMode, setEditMode] = React.useState<boolean>(false);
    const [cocinaSelected, setCocinaSelected] = React.useState<any>('');

    const [cocinas, setCocinas] = React.useState([]);
    const [cocinasBackUp, setCocinasBackUp] = React.useState([]);

    React.useEffect(() => {
        getCocinas();
        // eslint-disable-next-line
    }, []);

    const getCocinas = async () => {
        setLoading(true);
        await cocinaService.getCocinas().then((data) => {
            if (data.status === 200) {
                setCocinas(data.cocinas);
                setCocinasBackUp(data.cocinas)
            } else {
                setCocinas([]);
            }
            setLoading(false);
        })
    }

    const funcionSwitch = async (cocina) => {
        setLoading(true);
        if (cocina.status === 1) {
            await cocinaService.editStatus(cocina.id, 0);
        } else {
            await cocinaService.editStatus(cocina.id, 1);
        }
        getCocinas();
    }

    const onSearch = (search: string) => {
        const searchLowerCase = search.trim().toLowerCase();
        if (search !== '') {
            const filteredProducts = cocinasBackUp.filter(cocina => {
                const name = cocina.name && cocina.name.toLowerCase();
                return (
                    (name && name.includes(searchLowerCase))
                );
            });
            setCocinas(filteredProducts);
        } else {
            setCocinas(cocinasBackUp);
        }
    };

    return (
        <Protected>
            {loading && <Loading />}
            <Grid container xs={12}>
                <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'center' }}>
                    <Typography variant="h4">Gestión de cocinas</Typography>
                </Grid>
                <Grid item container xs={12}
                    sx={{
                        alignItems: "center",
                        display: "flex",
                        justifyContent: "space-between",
                        flexWrap: "wrap",
                        marginBottom: "10px",
                    }}
                >
                    <Grid item>
                        <TextField
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <SearchIcon fontSize="small" />
                                    </InputAdornment>
                                ),
                            }}
                            placeholder="Buscar..."
                            size="small"
                            onChange={(e) => onSearch(e.target.value)}
                        />
                    </Grid>
                    <Grid item sx={{ textAlign: "right" }}>
                        <Button variant='outlined' style={{ textTransform: "capitalize" }}
                            onClick={() => {
                                setCocinaSelected(null)
                                setOpenPopup(true);
                            }}
                        >Crear nueva cocina</Button>
                    </Grid>
                </Grid>

                <Grid item xs={12}>
                    <Box sx={{ Width: '100%', }}>
                        <TableContainer component={Paper}>
                            <ThemeProvider theme={theme}>
                                <Table aria-label="simple table" size='small'>
                                    <TableHead>
                                        <TableRow sx={{ bgcolor: '#1976D2' }}>
                                            <TableCell sx={{ color: 'white' }} >
                                                <strong>N°</strong>
                                            </TableCell>
                                            <TableCell align='left' sx={{ color: 'white' }} >
                                                <strong>Nombre</strong>
                                            </TableCell>
                                            <TableCell align='center' sx={{ color: 'white' }} >
                                                <strong>Habilitar / Deshabilitar</strong>
                                            </TableCell>
                                            <TableCell align='center' sx={{ color: 'white' }} >
                                                <strong>Estado</strong>
                                            </TableCell>
                                            <TableCell align='center' sx={{ color: 'white', minWidth: '8rem', maxWidth: '12rem' }}><strong>Acciones</strong></TableCell>
                                        </TableRow>
                                    </TableHead>

                                    <TableBody>
                                        {!!cocinas.length
                                            ?
                                            cocinas.map((cocina, id) => (
                                                <TableRow key={id}>
                                                    <TableCell component="th" scope="row">
                                                        {id + 1}
                                                    </TableCell>
                                                    <TableCell component="th" scope="row">
                                                        {cocina.name.charAt(0).toUpperCase() + cocina.name.slice(1).toLowerCase()}
                                                    </TableCell>
                                                    <TableCell align='center' component="th" scope="row">
                                                        <Box>
                                                            <FormControlLabel
                                                                control={<Switch onClick={() => {
                                                                    funcionSwitch(cocina);
                                                                }}
                                                                />}
                                                                checked={cocina.status === 1 ? true : false}
                                                                label=""
                                                            />
                                                        </Box>
                                                    </TableCell>
                                                    <TableCell align='center' component="th" scope="row">
                                                        {cocina.status === 1 ? 'Habilitada' : 'Deshabilitada'}
                                                    </TableCell>
                                                    <TableCell align='center'>
                                                        <Button
                                                            onClick={() => {
                                                                setOpenPopup(true);
                                                                setEditMode(true);
                                                                setCocinaSelected(cocina);
                                                            }}
                                                        >
                                                            <PencilIcon />
                                                        </Button>
                                                        {/* <Button
                                                                    onClick={() =>  {
                                                                    }}
                                                                >
                                                                    <DeleteIcon style={{color: 'red'}}/>
                                                                </Button> */}
                                                    </TableCell>
                                                </TableRow>
                                            ))
                                            :
                                            <TableRow>
                                                <TableCell align="center" colSpan={6}>Sin resultados</TableCell>
                                            </TableRow>
                                        }
                                    </TableBody>
                                </Table>
                            </ThemeProvider>
                        </TableContainer>
                    </Box>
                </Grid>
            </Grid>
            {
                openPopup === true && (
                    <Popup
                        largo="sm"
                        title='Registrar nueva Cocina'
                        openPopup={openPopup}
                        setOpenPopup={setOpenPopup}
                    >
                        <ModalCocina
                            closeModal={() => { setOpenPopup(false) }}
                            handleLoad={getCocinas}
                            cocinaSelected={cocinaSelected}
                        />
                    </Popup>
                )
            }
        </Protected>
    )
}