import { Props } from "./ListaPrecios.type";
import { Box, Button, Container, createTheme, Divider, Grid, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, ThemeProvider, Typography } from "@mui/material";
import { Protected } from "@/components/layout/Protected";
import { esES } from "@mui/material/locale";
import React, { useEffect } from "react";
import { Loading } from "@/components/common/Loading";
import { useLocalStorage } from '@/toolbox/hooks/local-storage.hook';
import { KEY_USER_DATA } from '@/toolbox/constants/local-storage';
import { Popup } from "../Editor/components/Popup";
import SettingsIcon from '@mui/icons-material/Settings';
import { listaPrecioService } from "@/service/services/ListaPrecio.service";
import { ROUTE_CREAR_LISTA_PRECIO } from "@/toolbox/constants/route-map";
import { Link } from "react-router-dom";
import { ListaPrecioProducto } from "./componentes/ListaPrecioProducto";
//import { ListPrice } from "../Sucursales/componentes/ListPrice";
import ListAltIcon from '@mui/icons-material/ListAlt';
import { DetalleListaPrecio } from "./componentes/DetalleListaPrecio";

const theme = createTheme(
    {
        palette: {
            primary: { main: '#1976D2' },
        },
    },
    esES,
);

export const ListaPrecios: React.FC<Props> = (prop:Props):JSX.Element => {

    const [loading, setLoading] = React.useState<boolean>(false);

    const [openPopup, setOpenPopup] = React.useState<boolean>(false);
    const [popUp, setPopUp] = React.useState<String>('');
    const [editMode, setEditMode] = React.useState<boolean>(false);

    const [listaPrecios, setListaPrecios] = React.useState([]);
    const [selectedListaPrecio, setSelectedListaPrecio] = React.useState<any>('');

    const [dataUser] = useLocalStorage(KEY_USER_DATA, undefined);
    const rol = dataUser && dataUser.user.rol;
    // eslint-disable-next-line
    const isSuperAdmin = rol === 'Superadmin';

    useEffect(() => {
        getListaPrecios();
        // eslint-disable-next-line
    }, []);

    const getListaPrecios = async() => {
        setLoading(true);
        const resp = await listaPrecioService.getListaPrecioEmpresa(dataUser?.user.id_empresa);
        if(resp.status === 200){
            setListaPrecios(resp.data);
        }
        setLoading(false);
    }

    return(
        <Protected>
            {loading && <Loading/>}
            <Container maxWidth='xl' sx={{ mt: 1, mb: 2 }}>

                <Grid container justifyContent='space-between' spacing={2}>
                    <Grid item>
                        <Typography variant='h4' >Lista de Precios</Typography>
                    </Grid>
                    <Grid item>
                        <Button variant='outlined' style={{textTransform: "capitalize"}}
                        component={Link} to={ROUTE_CREAR_LISTA_PRECIO}
                        >Crear Lista de Precios</Button>
                    </Grid>
                </Grid>

                <Divider sx={{ mt: 2 }} />

                <Grid container spacing={1} >
                    <Grid item xs={12}>
                        <Typography variant="body2" sx={{ mt: '20px' }} >
                            {
                                (listaPrecios.length)  ? `${listaPrecios.length} registros encontrados`
                                    : `0` && !loading
                            }
                        </Typography>
                    </Grid>
                </Grid>
                <Grid>
                {
                    listaPrecios.length > 0 && (
                        <Box sx={{Width: '100%', marginTop:'30px'}}>
                            <TableContainer component={Paper}>
                                <ThemeProvider theme={theme}>
                                    <Table aria-label="simple table" size='small'>

                                        <TableHead>
                                            <TableRow  sx={{ bgcolor:'#1976D2'}}>

                                            <TableCell sx={{ color: 'white' }} >
                                                <strong>N°</strong>
                                            </TableCell>
                                            <TableCell align='center' sx={{ color: 'white' }} >
                                                <strong>Nombre</strong>
                                            </TableCell>
                                            <TableCell align='center' sx={{ color: 'white' }} >
                                                <strong>Máximo Descuento</strong>
                                            </TableCell>
                                            <TableCell align='center' sx={{ color: 'white' }} >
                                                <strong>Margen de Utilidad</strong>
                                            </TableCell>
                                            <TableCell align='center' sx={{ color: 'white' }} >
                                                <strong>Sucursal</strong>
                                            </TableCell>
                                            <TableCell align='center' sx={{ color: 'white' }} >
                                                <strong>Dirección</strong>
                                            </TableCell>
                                            <TableCell align='center' sx={{ color: 'white' , minWidth:'8rem', maxWidth:'12rem'}}><strong>Acciones</strong></TableCell>

                                            </TableRow>
                                        </TableHead>

                                        <TableBody>
                                            {
                                                listaPrecios.map((listaprecio, id) =>(
                                                    <TableRow key={id}>
                                                        <TableCell component="th" scope="row">
                                                            {id+1}
                                                        </TableCell>
                                                        <TableCell align='center' component="th" scope="row">
                                                            {listaprecio.nombre.toLowerCase()}
                                                        </TableCell>
                                                        <TableCell align='center' component="th" scope="row">
                                                            {listaprecio.max_descuento}
                                                        </TableCell>
                                                        <TableCell align='center' component="th" scope="row">
                                                            {listaprecio.margen_utilidad}
                                                        </TableCell>
                                                        <TableCell align='center' component="th" scope="row">
                                                            {listaprecio.sucursal}
                                                        </TableCell>
                                                        <TableCell align='center' component="th" scope="row">
                                                            {listaprecio.direccion}
                                                        </TableCell>

                                                        <TableCell align='center'>
                                                            <Button
                                                                onClick={() =>  {
                                                                    setOpenPopup(true);
                                                                    setEditMode(true);
                                                                    setPopUp('listaPrecioProducto');
                                                                    setSelectedListaPrecio({'id':listaprecio.id,
                                                                    'nombre':listaprecio.nombre});
                                                                }}
                                                            >
                                                                <ListAltIcon color='secondary'/>
                                                            </Button>
                                                            <Button
                                                                onClick={() =>  {
                                                                    setOpenPopup(true);
                                                                    setEditMode(true);
                                                                    setPopUp('detailListPrice');
                                                                    setSelectedListaPrecio({'id':listaprecio.id,
                                                                                        'nombre':listaprecio.nombre,
                                                                                        'id_sucursal':listaprecio.id_sucursal});
                                                                }}
                                                            >
                                                                <SettingsIcon color='secondary'/>
                                                            </Button>
                                                        </TableCell>
                                                    </TableRow>
                                                ))
                                            }
                                        </TableBody>
                                    </Table>
                                </ThemeProvider>
                            </TableContainer>
                        </Box>
                    )
                    }
                </Grid>
            </Container>
            {
                popUp === 'listaPrecioProducto' && (
                    <Popup
                        largo="md"
                        title = {'Lista de Precios - '+ selectedListaPrecio.nombre.toLowerCase()}
                        openPopup = {openPopup}
                        setOpenPopup = {setOpenPopup}
                    >
                        <ListaPrecioProducto
                            closeModal={() => {setOpenPopup(false)}}
                            listaPrecio = {selectedListaPrecio}
                            editMode = {editMode}
                        />

                    </Popup>
                )
            }
            {
                popUp === 'detailListPrice' && (
                    <Popup
                        largo="md"
                        title = {'Ajustes Lista de Precios'}
                        openPopup = {openPopup}
                        setOpenPopup = {setOpenPopup}
                    >
                        <DetalleListaPrecio
                            closeModal={() => {setOpenPopup(false)}}
                            listaPrecio = {selectedListaPrecio}
                            editMode = {editMode}
                            setEditMode = {setEditMode}
                            getListaPrecios = {getListaPrecios}
                        />

                    </Popup>
                )
            }
    </Protected>
    );
}
