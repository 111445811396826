import React, { useEffect, useRef, useState } from 'react';
import { Button, Grid, IconButton, Typography } from "@mui/material";
import './ModalCartShop.css';
import CloseIcon from '@mui/icons-material/Close';
import AddShoppingCartIcon from '@mui/icons-material/AddShoppingCart';
import { Props } from './ModalCartShop.type';
import { readLocalStorage, saveLocalStorage } from '@helpers/local-storage-helper';
import { CLIENTE_SHOP, PRODUCTS_CARD } from "@/toolbox/constants/local-storage";
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import { KEY_EMPRESA } from "@/toolbox/constants/local-storage";
import { useLocation } from 'react-router-dom';
import { ticketService } from '@/service/services/Ticket.service';
import { STATUS_PENDIENTE } from '@/toolbox/constants/status-ticket';
import { useLocalStorage } from '@/toolbox/hooks/local-storage.hook';
import { ComponentPrintTicket } from '@/components/common/ComponentPrintTicket';
import { Snackbar, Alert } from '@mui/material';
import moment from 'moment';
import { Loading } from '@/components/common/Loading';

export const ModalCartShop: React.FC<Props> = (props: any): JSX.Element => {
    const {listaPrecio} = props;
    const {search} = useLocation();
    const componentRef = useRef();
    const [products, setProducts] = useState<any>([]);
    const [keysClient, setKeysClient] = useState<any>(null);

    const id_cliente_shop = readLocalStorage(CLIENTE_SHOP);

    const handleClose = () => {
        props.$action.actionSetEcommerce({
            ...props.$store.ecommerceReducer,
            showModal: false,
        })
    };

    const [dataEmpresa] = useLocalStorage(KEY_EMPRESA, {
        id: 0, id_tipo_empresa:0, equivalencia_puntos: 0,
        id_country: 0, country_name: "", country_abbreviation: "", mesas: 0,
    });
   //  const [beneficioProducto] = useLocalStorage(KEY_BENEFICIO_PRO, []);
   //  const [beneficioMonto] = useLocalStorage(KEY_BENEFICIO_MON, []);

    const [descuentoMonto,setDescuentoMonto] = useState<number>(0);
    const [descuentoProductos,setDescuentoProductos] = useState<number>(0);
    const [totalCard,setTotalCard] = useState<number>(0);
    console.log(totalCard);
    console.log(products);
    // eslint-disable-next-line
    const [pagosSelect,setPagoSelect] = useState<number>(0);
    const [arrayIdsProducto, setArrayIdsProducto] = useState<any>([]);
    const [dataImprimirTicket, setDataImprimirTicket] = useState(null);
    const [loading, setLoading] = React.useState<boolean>(false);
    const [snackBarConfig, setSnackBarConfig] = useState<any>({
        open: false,
        severity: 'error',
        message: 'Error',
        autoHideDuration: 3000,
    });

    const validatePrice = (product) => {
        let existList = (listaPrecio|| []).find(p => p.codigo_erp === product.codigo_erp);
        if (existList) {
           return existList?.precio_ajustado || 0;
        }
        const dateToday = moment();
        const value = product.caducidad_precio_oferta;
        const expirationPrice = moment(value, 'DD-MM-YYYY');

        const standarPrice = product.precio_standar || product.precio;
        const offerPrice = product.precio_oferta;

        if(expirationPrice !== null && offerPrice !== null){
           if(expirationPrice.isSameOrAfter(dateToday)){
              return offerPrice || 0;
           }else{
              return standarPrice || 0;
           }
        }else{
           return standarPrice || 0;
        }
    }

    useEffect(() => {
        const card_shop = readLocalStorage(PRODUCTS_CARD);
        if(card_shop){ setProducts(card_shop); }

        const objetKeys = localStorage.getItem('keysClient');
        const keys = JSON.parse(objetKeys);
        if(keys){ setKeysClient(keys); }

        let total : number = 0;
        let descuento : number = 0;
        if(card_shop){
            for (let i = 0; i < card_shop.length; i++) {
                if(!card_shop[i]?.cantidad){
                    total = (total + Number(validatePrice(card_shop[i])));
                }else{
                    if (card_shop[i].id_unidad_medida) {
                        total = (total + ((Number(validatePrice(card_shop[i]))*card_shop[i].cantidad) / Number(card_shop[i].cantidad_peso)))
                     } else {
                        total = (total + (Number(validatePrice(card_shop[i]))*card_shop[i].cantidad))
                     }
                }
            }
        }
        let puntos = 0;
        setDescuentoMonto(puntos*dataEmpresa.equivalencia_puntos);
        setDescuentoProductos(descuento);
        setTotalCard(total-(descuento + (puntos*dataEmpresa.equivalencia_puntos)));
      // eslint-disable-next-line
    }, [])

    useEffect(() => {
        if(products){
            const objetKeys = localStorage.getItem('keysClient');
            const keys = JSON.parse(objetKeys);
            if(keys){ setKeysClient(keys); }

            let total : number = 0;
            let descuento : number = 0;
            if(products){
                for (let i = 0; i < products.length; i++) {
                    if(!products[i]?.cantidad){
                        total = (total + Number(validatePrice(products[i])));
                    }else{
                        if (products[i].id_unidad_medida) {
                            total = (total + ((Number(validatePrice(products[i]))*products[i].cantidad) / Number(products[i].cantidad_peso)))
                        } else {
                            total = (total + (Number(validatePrice(products[i]))*products[i].cantidad))
                        }
                    }
                }
            }
            let puntos = 0;
            setDescuentoMonto(puntos*dataEmpresa.equivalencia_puntos);
            setDescuentoProductos(descuento);
            setTotalCard(total-(descuento + (puntos*dataEmpresa.equivalencia_puntos)));
        }
        
      // eslint-disable-next-line
    }, [products])


    const handleDismissProduct = (index: number) => {
        let card: Array<any> = products;
        if (!card[index]?.cantidad || card[index].cantidad === 1) {
            card.splice(index, 1);
        } else {
            card[index].cantidad = card[index].cantidad-1
        }
        setProducts([...card])
        saveLocalStorage(PRODUCTS_CARD, card);
    }
    const handleMoreProduct = (index: number) => {
        let card: Array<any> = products;
        if (!card[index].cantidad) {
            card[index].cantidad = 2;
        } else {
            card[index].cantidad = parseInt(card[index].cantidad);
            if(card[index].cantidad === card[index].stock){
                setSnackBarConfig(prev => ({
                ...prev,
                open: true,
                message: "no hay suficiente stock",
                }));
            }else{
                card[index].cantidad = card[index].cantidad+1;
            }
        }
        setProducts([...card])
        saveLocalStorage(PRODUCTS_CARD, card);
    }
    const handleDeleteProduct = (index: number) => {
        let card: Array<any> = products;
        card.splice(index, 1);
        setProducts([...card])
        saveLocalStorage(PRODUCTS_CARD, card);

        props.$action.actionSetEcommerce({
            ...props.$store.ecommerceReducer,
            productsCount: card.length,
        });
    }

    const realizarPedido = async() => {
        arrayIdsProducto.length = 0;
        setArrayIdsProducto(arrayIdsProducto);
         // eslint-disable-next-line
        const data: any = readLocalStorage(KEY_EMPRESA);

        let query = new URLSearchParams(search)
        let respTicket:any;
        let keyMesaClient: any = query.get("key");
        let keyClienteClient: any = query.get("key2");

        //loading
        if(products.length === 0){
            return setSnackBarConfig(prev => ({
                ...prev,
                open: true,
                severity: 'warning',
                message: "Seleccione al menos un producto",
            }));
        }

        setLoading(true);

        for (let i = 0; i < products.length; i++) {
            let precio;
            let codigo;

            if(products[i].precio_oferta){
                precio = validatePrice(products[i]);
                // precio = products[i].precio_oferta;
            }else{
               // eslint-disable-next-line
               precio = validatePrice(products[i]);
                // precio = products[i].precio_standar;
            }
            // eslint-disable-next-line
            codigo = products[i].codigo_erp;

            arrayIdsProducto.push(products[i].id);
            setArrayIdsProducto(arrayIdsProducto);
        }

        try{
            if(keyMesaClient || keysClient !== null){
                respTicket = await ticketService.generarTicket(keyMesaClient || keysClient?.keyMesa, keyClienteClient || keysClient?.keyCliente || null, totalCard, descuentoMonto, descuentoProductos, STATUS_PENDIENTE, products/* , pagosSelect */, arrayIdsProducto)
            }else{
                respTicket = await ticketService.generarTicket(null, (id_cliente_shop || null), totalCard, descuentoMonto, descuentoProductos, STATUS_PENDIENTE, products/* , pagosSelect */, arrayIdsProducto)
            }
            if(respTicket.status === 200){
                const ticketGenerado = await ticketService.getTicketDetalle(respTicket?.ticketID);
                const data = {
                   n_ticket : ticketGenerado?.ticket?.n_ticket,
                   monto_pedido: ticketGenerado?.ticket?.monto_pedido,
                   monto_descuento: ticketGenerado?.ticket?.pedidoDetail?.descuento_monto_total,
                   usuario: ticketGenerado.ticket?.pedidoDetail?.usuario,
                   cliente: ticketGenerado.ticket?.pedidoDetail?.cliente,
                   mesa: ticketGenerado.ticket?.pedidoDetail?.mesa,
                   fecha: ticketGenerado?.ticket?.created_at,
                   productos: products
                }
                setDataImprimirTicket(data);
            }

            setProducts([]);
            saveLocalStorage(PRODUCTS_CARD, []);
            setLoading(false);
            props.$action.actionSetEcommerce({
                ...props.$store.ecommerceReducer,
                productsCount: 0,
            });
            return setSnackBarConfig(prev => ({
                ...prev,
                open: true,
                severity: 'success',
                message: "Tú pedido se realizo con exito",
            }));

        }catch(error){
            console.log(error);
            setLoading(false);
            return setSnackBarConfig(prev => ({
                ...prev,
                open: true,
                message: "Ocurrió un error vuelva a intentarlo",
            }));
        }
    }

   return (
       <Grid>

        {loading && <Loading/>}

        <Snackbar
            open={snackBarConfig.open}
            autoHideDuration={snackBarConfig.autoHideDuration}
            onClose={() => setSnackBarConfig(prev => ({ ...prev, open: false }))}
            anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
            >
            <Alert
            onClose={() => setSnackBarConfig(prev => ({ ...prev, open: false }))}
            severity={snackBarConfig.severity}
            variant="filled"
            >
            {snackBarConfig.message}
            </Alert>
        </Snackbar>

        <Grid container p={2} className="cardSell">
                <Grid xs={12} container className="text" item justifyContent={'space-between'} alignItems='left' height={40}>
                    <Typography className="textMain" text-align="start" >Tu canasta</Typography>
                    <CloseIcon onClick={handleClose} style={{ cursor: 'pointer' }} />
                </Grid>

                {/* <Grid xs={12} item justifyContent={'space-between'} alignItems='left'>
                    <Button startIcon={<LocationOnIcon sx={{ color: '#ff4d00' }} className="ico" />}>
                        <Typography sx={{ color: '#ff4d00 ' }} className="ubication">Ingresar mi ubicacion</Typography>
                    </Button>
                </Grid> */}

                {/* <Divider sx={{mt: 2}} /> */}
                <Grid xs={12} container className="text" item justifyContent={'center'}  alignItems='center' height={"80%"} overflow="auto">
                {
                    products && products.length > 0 && products.map((product, i) => (
                     <Grid item xs={12} display={"flex"} flexDirection={"column"} justifyContent={"flex-start"} alignItems={"flex-start"}>
                        <Card className='card-content-shop' key={i}>
                            <CardMedia
                                component="img"
                                sx={{ width: 110 }}
                                image="/static/images/cards/live-from-space.jpg"
                                alt="img"
                                style={{border: "1px solid red"}}
                            />
                            <Box sx={{ display: 'flex', flexDirection: 'column' }} style={{width: '100%'}}>
                                <CardContent>
                                    <Typography component="div" variant="body1">
                                        {product.nombre}
                                    </Typography>
                                    <Grid display="flex">
                                        <Typography variant="body1" color="text.secondary" component="div">
                                            {/* {product.precio_oferta ? product.precio_oferta : product.precio_standar} */}
                                            $ {validatePrice(product)}
                                        </Typography>
                                        {/* <Typography variant="body1" color="text.secondary" component="div" className="price_oferta">
                                            {product.precio_oferta ? product.precio_standar : 0}
                                        </Typography> */}
                                    </Grid>
                                    <Grid display="flex" justifyContent="end">
                                        <Grid>
                                        <button className="dissmiss-more delete" onClick={()=>handleDeleteProduct(i)}>x</button>
                                        </Grid>
                                        <Grid display="flex" alignItems="center" justifyContent="center">
                                        <button className="dissmiss-more" onClick={()=>handleDismissProduct(i)}>-</button>
                                        <span className="quantity">{`(${product.cantidad ? product.cantidad: 1})`}</span>
                                        <button className="dissmiss-more" onClick={()=>handleMoreProduct(i)}>+</button>
                                        </Grid>
                                    </Grid>
                                </CardContent>
                            </Box>
                           </Card>
                        </Grid>
                    ))
                }

                {
                    products && products.length === 0 && (
                        <>
                            <Grid item xs={12} className="icoCard" alignContent='center' >
                                <IconButton  >
                                <AddShoppingCartIcon sx={{ fontSize: '50px' }} />
                                </IconButton>
                            </Grid >

                            <Grid xs={12} item justifyContent={'space-between'} alignItems='center' >
                                <Typography className='textbody' sx={{ color: '#3f3f3f ' }} >Aun no tienes productos en tu canasta</Typography>
                            </Grid>
                        </>
                    )
                }
                </Grid>

                <Grid xs={12} item height={50} justifyContent={'space-between'} alignItems='center'  >
                    {
                        products.length > 0 && (
                            <Grid display="flex"  alignItems='center' style={{marginBottom: '5px'}}>
                                <Typography className='totalText' >Total a pagar: </Typography>
                                <Typography className='montoText' >{`$ ${totalCard}`}</Typography>
                            </Grid>
                        ) 
                    }
                    
                    <Button className='btnCard' onClick={() => {realizarPedido()}}>
                        {products.length === 0 ? 'Comenzar a comprar' : 'Realizar pedido'}
                    </Button>
                </Grid>
        </Grid>
        { dataImprimirTicket &&
            <div style={{ display: "none" }}>
               <div ref={componentRef}>
                  <ComponentPrintTicket
                     data={dataImprimirTicket}
                  />
               </div>
            </div>
        }

    </Grid>
   );
}
