import React from 'react';
import { Grid, Typography } from '@mui/material';
import { moneyFormatInt } from '@/toolbox/helpers/money.helper';
import {Props} from './ComponentPrintFactura.type'

export const ComponentPrintFactura: React.FC<Props> = (props: Props) => {

    return (
      <>
         <div style={{  marginBottom: 30, width: '400px', margin: 'auto', marginTop: 30 ,padding:"10px"}}>

            <Grid container justifyContent={'center'} >
               <div style={{textAlign:"center", border:'2px solid black', padding:'10px', minWidth:'90%'}}>

                  <Typography
                  sx={{fontSize:'1.3em !important', fontFamily:'sans-serif',fontWeight:'700'}}
                  >{props.data.ventaDetalle? "RUT "+ props.data.ventaDetalle?.pedido?.usuario?.sucursales?.empresa?.rut :''}</Typography>
                  <Typography
                  sx={{fontSize:'1.3em !important', fontFamily:'sans-serif',fontWeight:'700'}}
                  >{props.data.folio? "FACTURA ELECTRONICA ":''}</Typography>
                     <Typography
                  sx={{fontSize:'1.3em !important', fontFamily:'sans-serif',fontWeight:'700'}}
                  >{props.data.folio? "N° "+ props.data.folio:' '}</Typography>

               </div>
            </Grid>
            <br/>

            <div style={{textAlign:"center"}}>
                  <Typography sx={{fontSize:'15px !important', fontWeight:700}}>
                     S.I.I. {props.data.ventaDetalle?.pedido?.usuario?.sucursales?.empresa?.unidad_sii || '-----'}
                  </Typography>
            </div>

            <div className='encabezadoboleta' style={{width:"100%"}}>
               <div style={{textAlign:"center"}}>
                  <Typography sx={{fontSize:'15px !important', fontWeight:700}}>
                     {"SOFNET SPA"} 
                  </Typography>
                  <br/>
                  <Typography sx={{fontSize:'14px !important', fontWeight:700, marginBottom:'3px'}}>
                     {props.data.ventaDetalle?.pedido?.usuario?.sucursales?.empresa?.giro1 || 'sin registro'}
                  </Typography>
                  <Typography sx={{fontSize:'14px !important', fontWeight:700}}>
                     {props.data.ventaDetalle?.pedido?.usuario?.sucursales?.empresa?.giro2 || 'sin registro'}
                  </Typography>
                  <br/>
                  <Typography sx={{fontSize:'14px !important', fontWeight:700, marginBottom:'3px'}}>
                     {props.data.ventaDetalle?.pedido?.usuario?.sucursales?.empresa?.direccion || ''}
                  </Typography>
                  <Typography sx={{fontSize:'14px !important', fontWeight:700, marginBottom:'5px'}}>
                     {props.data.ventaDetalle?.pedido?.usuario?.sucursales?.empresa?.comuna+' '+props.data.ventaDetalle?.pedido?.usuario?.sucursales?.empresa?.comuna || ''}
                  </Typography>
               </div>
               <div style={{textAlign:"right"}}>
                  <Typography sx={{fontSize:'1.2em !important'}}>
                     <strong>Fecha:</strong>  {props.data.ventaDetalle?.fecha || "--------"}{' '}
                  </Typography>
               </div>
            </div>

            <hr style={{color: 'black'}}/>

            <Grid container spacing={0} sx={{width: '100%'}}>
               
               <Grid item xs={5} style={{ textAlign: 'left', padding: 0 }} paddingBottom={0}>
                  <Typography sx={{fontSize:'1.2em !important'}}>
                     <strong>Rut</strong>
                  </Typography>
               </Grid>
               <Grid item xs={7} paddingBottom={0}>
                  <Typography sx={{fontSize:'1.2em !important'}}>
                     :{props.data.ventaDetalle?.pedido?.cliente?.rut || "66666666-6"}
                  </Typography>
               </Grid>

               <Grid item xs={5} style={{ textAlign: 'left' }} paddingTop={0}>
                  <Typography sx={{fontSize:'1.2em !important'}}>
                     <strong>Razon social</strong>
                  </Typography>
               </Grid>
               <Grid item xs={7}  paddingTop={0}>
                  <Typography sx={{fontSize:'1.2em !important'}}>
                     :{props.data.ventaDetalle?.pedido?.cliente?.nombre_rz || "sin registro"}
                  </Typography>
               </Grid>

               <Grid item xs={5} style={{ textAlign: 'left' }}>
                  <Typography sx={{fontSize:'1.2em !important'}}>
                     <strong>Giro</strong>
                  </Typography>
               </Grid>
               <Grid item xs={7}>
                  <Typography sx={{fontSize:'1.2em !important'}}>
                     :{props.data.ventaDetalle?.pedido?.cliente?.giro || "sin registro"}
                  </Typography>
               </Grid>

               <Grid item xs={5} style={{ textAlign: 'left' }}>
                  <Typography sx={{fontSize:'1.2em !important'}}>
                     <strong>Direccion</strong>
                  </Typography>
               </Grid>
               <Grid item xs={7}>
                  <Typography sx={{fontSize:'1.2em !important'}}>
                     :{props.data.ventaDetalle?.pedido?.cliente?.direccion || "sin registro"}
                  </Typography>
               </Grid>

               <Grid item xs={5} style={{ textAlign: 'left' }}>
                  <Typography sx={{fontSize:'1.2em !important'}}>
                     <strong>Comuna/Ciudad</strong>
                  </Typography>
               </Grid>
               <Grid item xs={7}>
                  <Typography sx={{fontSize:'1.2em !important'}}>
                     :{props.data.ventaDetalle?.pedido?.cliente?.comuna+ ' - ' + props.data.ventaDetalle?.pedido?.cliente?.ciudad || "sin registro"}
                  </Typography>
               </Grid>
            </Grid>

               <hr style={{border: '0', height: '2px', borderTop: '1px dashed black', borderBottom: '1px dashed black'}}/>

               <div>
                  <Grid container>
                     <Grid item xs={6}>
                        <Typography sx={{textAlign:"left",fontSize:'1.2em !important'}}>Artículo</Typography>
                     </Grid>
                     <Grid item xs={6}>
                        <Typography sx={{textAlign:"right",fontSize:'1.2em !important'}}>Valor</Typography>
                     </Grid>
                  </Grid>
               </div>

               <div>
               <hr style={{border: '0', height: '2px', borderTop: '1px dashed black', borderBottom: '1px dashed black'}}/>
               </div>
               <div>
                  <Grid container>
                  {(props.data.detalle||[]).map((item, index) => (
                        <>
                        <Grid item xs={12}>
                           <Typography sx={{textAlign:"left",fontSize:'1em !important'}}>{item?.nombre}</Typography>
                        </Grid>
                        <Grid item xs={8}>
                           <Typography sx={{textAlign:"left",fontSize:'1em !important'}}>
                              {item.cantidad} {item?.unidad_medida?.abbreviation ? item?.unidad_medida?.abbreviation : ''} x {moneyFormatInt(Math.ceil(item?.precio_unitario))}</Typography>
                        </Grid>
                        <Grid item xs={4}>
                           <Typography sx={{textAlign:"right",fontSize:'1em !important'}}>
                              {moneyFormatInt(Math.ceil(Number(item?.precio_unitario)*Number(item.cantidad)/Number(item.cantidad_peso || 1)))}</Typography>
                        </Grid>
                        </>
                     ))}
                  </Grid>
               </div>
               <hr/>
               <div>
                  <Grid container xs={12} style={{padding:"5px",display:"flex", alignItems:"center",color:"#000"}}>
                     {/* MONTO TOTAL */}
                     <Grid item xs={6} >
                        <Typography sx={{ textAlign:"left",fontSize:'1em !important'}}><strong>DESCUENTO</strong></Typography>
                     </Grid>
                     <Grid item xs={6} style={{textAlign:"right",fontWeight:"bold"}}>
                        <Typography sx={{textAlign:"right", fontWeight:"bold", fontSize:'1em !important'}}><strong>{moneyFormatInt(Math.ceil(props.data.ventaDetalle?.descuento_x_puntos))}</strong></Typography>
                     </Grid>

                     <Grid xs={12}>
                        <hr/>
                     </Grid>

                     <Grid item xs={6}>
                        <Typography sx={{ textAlign:"left",fontSize:'1em !important'}}><strong>MONTO NETO</strong></Typography>
                     </Grid>
                     <Grid item xs={6} style={{textAlign:"right",fontWeight:"bold"}}>
                        <Typography sx={{textAlign:"right", fontWeight:"bold", fontSize:'1em !important'}}><strong>{moneyFormatInt(Math.ceil(props.data.montoNeto))}</strong></Typography>
                     </Grid>

                     <Grid xs={12}>
                        <hr/>
                     </Grid>

                     <Grid item xs={6}>
                        <Typography sx={{ textAlign:"left",fontSize:'1em !important'}}><strong>IVA (19%)</strong></Typography>
                     </Grid>
                     <Grid item xs={6} style={{textAlign:"right",fontWeight:"bold"}}>
                        <Typography sx={{textAlign:"right", fontWeight:"bold", fontSize:'1em !important'}}><strong>{moneyFormatInt(Math.ceil(props.data.ventaDetalle?.monto_total - props.data.montoNeto))}</strong></Typography>
                     </Grid>

                     <Grid xs={12}>
                        <hr/>
                     </Grid>

                     <Grid item xs={6}>
                        <Typography sx={{ textAlign:"left",fontSize:'1em !important'}}><strong>TOTAL A PAGAR</strong></Typography>
                     </Grid>
                     <Grid item xs={6} style={{textAlign:"right",fontWeight:"bold"}}>
                        <Typography sx={{textAlign:"right", fontWeight:"bold", fontSize:'1em !important'}}><strong>{moneyFormatInt(Math.ceil(props.data.ventaDetalle?.monto_total))}</strong></Typography>
                     </Grid>

                  </Grid>
               </div>
               <hr/>
               <div>
                  <Grid container alignItems="center">
                     {<img
                        width='100%'
                        alt='Timbre Fiscal'
                        className='timbrefiscal'
                        src={props?.data.timbre}
                        //src={props.data.timbre!=''?props.data.timbre:`data:image/png;base64, ${timbre}`}
                     />}
                     <p style={{ textAlign: 'center', fontSize: 12, margin: 'auto' }}>
                        Timbre Electrónico S.I.I. · Verifique Documento: http://www.sii.cl
                     </p>
                  </Grid>
               </div>
         </div>
      </>
    )
}
