import { Loading } from "@/components/common/Loading";
import { Protected } from "@/components/layout/Protected";
import { Box, Button, Container, createTheme, Divider, Grid, InputAdornment, Menu, MenuItem, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, TextField, ThemeProvider, Tooltip, Typography } from "@mui/material";
import { esES } from "@mui/material/locale";
import { Popup } from "../Editor/components/Popup";
import ContentPasteOffIcon from '@mui/icons-material/ContentPasteOff';
import { PencilIcon } from "@/toolbox/constants/icons";
import DeleteIcon from '@mui/icons-material/Delete';
import React, { useState } from "react";
import { Props } from "./Insumos.type";
import { productoService } from "@/service/services/Producto.service";
import { KEY_EMPRESA } from '@constants/local-storage';
import { useLocalStorage } from "@/toolbox/hooks/local-storage.hook";
import { AddProductoS } from "../Productos/AddProductoS";
import { EditProducto } from "../Editor/components/EditProducto";
import { DeletedProduct } from "../Editor/components/DeletedProduct";
import { editor } from "@/service/services/Editor.service";
import { removeLocalStorage } from "@/toolbox/helpers/local-storage-helper";
import SimCardDownloadIcon from '@mui/icons-material/SimCardDownload';
import excelImage from '../../assets/fileIcons/xlsx.png';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import { styled } from '@mui/material/styles';
import SearchIcon from "@mui/icons-material/Search";
import { moneyFormatInt } from "@/toolbox/helpers/money.helper";

const theme = createTheme(
    {
        palette: {
            primary: { main: '#1976D2' },
        },
    },
    esES,
);

const VisuallyHiddenInput = styled('input')({
    clip: 'rect(0 0 0 0)',
    clipPath: 'inset(50%)',
    height: 2,
    overflow: 'hidden',
    position: 'absolute',
    bottom: 0,
    left: 0,
    whiteSpace: 'nowrap',
    width: 1,
});

export const Insumos: React.FC<Props> = (props: Props): JSX.Element => {

    const [dataEmpresa] = useLocalStorage(KEY_EMPRESA, {
        id: 0, id_tipo_empresa: 0, equivalencia_puntos: 0,
        id_country: 0, country_name: "", country_abbreviation: "",
    });

    const [loading, setLoading] = React.useState<boolean>(false);
    const [openPopup, setOpenPopup] = React.useState<boolean>(false);
    const [editMode, setEditMode] = React.useState<boolean>(false);
    const [popUp, setPopUp] = React.useState<String>('');

    const [productos, setProductos] = React.useState([]);
    const [selectedProducto, setSelectedProducto] = React.useState<any>('');
    const [showModalDelete, setShowModalDelete] = React.useState(false);
    const [productosBackUp, setProductosBackUp] = useState<any[]>([])
    const [submodulo, setSubModulo] = React.useState([]);
    const [rowsPerPage, setRowsPerPage] = useState(20);
    const [page, setPage] = useState(0);
    const [menu, setMenu] = useState(null);

    React.useEffect(() => {
        getProductos();
        cargarModulos();
        // eslint-disable-next-line
    }, []);

    React.useEffect(() => {
        if (!openPopup) {
            removeLocalStorage('UM');
            removeLocalStorage('productsArray');
        }
        // eslint-disable-next-line
    }, [openPopup]);

    const cargarSubModulos = async (ids: any) => {
        setLoading(true);
        const res = await editor.cargarSubModulos(ids);
        if (res.status === 200) {
            const submodulos: any = res.submodulos;
            // eslint-disable-next-line
            submodulos.map((item) => {
                const valor = Object.values(item);
                if (valor[0]['nombre'] === 'Simple') {
                    setSubModulo(valor)
                }
            })
        }
        setLoading(false);
    }
    const cargarModulos = async () => {
        setLoading(true);
        const res = await editor.cargarModulos();
        if (res.status === 200) {
            const modulos: any = Object.values(res.modulos);
            for (let i = 0; i < modulos.length; i++) {
                if (modulos[i].submodulos) {
                    cargarSubModulos(modulos[i].submodulos);
                }
            }
        }
        setLoading(false);
    }

    const closeModalDelete = () => {
        setShowModalDelete(false);
    }

    const getProductos = async () => {
        setLoading(true);
        await productoService.getProductosInsumo(dataEmpresa.id).then((data) => {
            if (data.status === 200) {
                setProductos(data.productos);
                setProductosBackUp(data.productos)
            } else {
                setProductos([]);
            }
            setLoading(false);
        })
    }

    const downloadTemplateProductImport = async () => {
        try {
            const response = await productoService.downloadInsumoTemplateImport()
            const url: string = response.data.data
            if (!url) return
            window.open(url)
        } catch (error) {
            console.error('Error al descargar el archivo:', error);
        }
    }

    const handleImport = async (e) => {
        try {
            const file = e.target.files[0];
            if (!file) return

            const formData = new FormData()
            formData.append('document', file)

            await productoService.importInsumos(formData)
            e.target.value = null
            getProductos()
        } catch (error) {
            console.error('Error al importar el archivo', error)
        }
    }

    const onSearch = (search: string) => {
        const searchLowerCase = search.trim().toLowerCase();
        if (search !== '') {
            const filteredProducts = productosBackUp.filter(product => {
                const nombre = product.nombre && product.nombre.toLowerCase();
                const codigo_erp = product.codigo_erp && product.codigo_erp.toLowerCase();
                return (
                    (nombre && nombre.includes(searchLowerCase)) ||
                    (codigo_erp && codigo_erp.includes(searchLowerCase))
                );
            });
            setProductos(filteredProducts);
        } else {
            setProductos(productosBackUp);
        }
    };


    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const handleClick = (event) => {
        setMenu(event.currentTarget);
    };

    const handleCloseMenu = () => {
        setMenu(null);
    };

    return (
        <Protected>
            {loading && <Loading />}
            <Container maxWidth='xl' sx={{ mt: 1, mb: 2 }}>
                <Grid container spacing={2}>
                    <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'center' }}>
                        <Typography variant="h4">MIS INSUMOS</Typography>
                    </Grid>
                    <Grid item container xs={12}
                        sx={{
                            alignItems: "center",
                            display: "flex",
                            justifyContent: "space-between",
                            flexWrap: "wrap",
                            marginBottom: "10px",
                        }}
                    >
                        <Grid item>
                            <TextField
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <SearchIcon fontSize="small" />
                                        </InputAdornment>
                                    ),
                                }}
                                placeholder="Buscar..."
                                size="small"
                                onChange={(e) => onSearch(e.target.value)}
                            />
                        </Grid>
                        <Grid
                            item
                            sx={{ textAlign: "right" }}
                        >
                            <Button variant='outlined' onClick={handleClick} >
                                Opciones
                            </Button>
                            <Menu
                                id="simple-menu-2"
                                anchorEl={menu}
                                open={Boolean(menu)}
                                onClose={handleCloseMenu}
                            >
                                <MenuItem onClick={() => { downloadTemplateProductImport(); handleCloseMenu(); }}>
                                    <Button
                                        component="label"
                                        variant="outlined"
                                        className="btn-dwtemplate"
                                        fullWidth
                                        onClick={downloadTemplateProductImport}
                                        startIcon={<SimCardDownloadIcon fontSize="small" />}
                                        endIcon={<img src={excelImage} alt="Icon" width={20} height={20} />}
                                    >
                                        Descargar plantilla
                                    </Button>
                                </MenuItem>
                                <MenuItem>
                                    <Button
                                        component="label"
                                        variant='outlined'
                                        className="btn-import"
                                        fullWidth
                                        startIcon={<CloudUploadIcon fontSize='small' />}
                                        endIcon={<img src={excelImage} alt="Icon" width={20} height={20} />}
                                    >
                                        Importar insumos
                                        <VisuallyHiddenInput type="file" accept=".xlsx" onChange={handleImport} />
                                    </Button>
                                </MenuItem>
                            </Menu>

                            <Button variant='outlined' sx={{ marginLeft: '10px' }}
                                onClick={() => {
                                    setOpenPopup(true);
                                    setEditMode(true);
                                    setPopUp('addNewProduct');
                                }}
                            >Nuevo insumo</Button>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid>
                    <Box sx={{ Width: '100%', marginTop: '30px' }}>
                        <TableContainer component={Paper}>
                            <ThemeProvider theme={theme}>
                                <Table aria-label="simple table" size='small'>
                                    <TableHead>
                                        <TableRow sx={{ bgcolor: '#1976D2' }}>
                                            <TableCell sx={{ color: 'white' }} >
                                                <strong>N°</strong>
                                            </TableCell>
                                            <TableCell align='center' sx={{ color: 'white' }} >
                                                <strong>Código - Nombre</strong>
                                            </TableCell>
                                            <TableCell align='center' sx={{ color: 'white' }} >
                                                <strong>Precio</strong>
                                            </TableCell>
                                            <TableCell align='center' sx={{ color: 'white' }} >
                                                <strong>Stock</strong>
                                            </TableCell>
                                            <TableCell align='center' sx={{ color: 'white' }} >
                                                <strong>Unidad de medida</strong>
                                            </TableCell>
                                            <TableCell align='center' sx={{ color: 'white', minWidth: '8rem', maxWidth: '12rem' }}><strong>Acciones</strong></TableCell>
                                        </TableRow>
                                    </TableHead>

                                    <TableBody>
                                        {!!productos.length
                                            ?
                                            productos?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((producto, index) => (
                                                <TableRow key={index}>
                                                    <TableCell component="th" scope="row">
                                                        {index + 1}
                                                    </TableCell>
                                                    <TableCell component="th" scope="row">
                                                        {producto.codigo_erp} - {producto.nombre.charAt(0).toUpperCase() + producto.nombre.slice(1).toLowerCase()}
                                                    </TableCell>
                                                    <TableCell align='center' component="th" scope="row">
                                                        {moneyFormatInt(producto.precio_standar)}
                                                    </TableCell>
                                                    <TableCell align='center' component="th" scope="row">
                                                        {producto.stock}
                                                    </TableCell>
                                                    <TableCell align='center' component="th" scope="row">
                                                        {producto.unidad_medida}
                                                    </TableCell>
                                                    <TableCell align='center'>
                                                        <Button
                                                            onClick={() => {
                                                                setOpenPopup(true);
                                                                setEditMode(true);
                                                                setPopUp('editProduct');
                                                                setSelectedProducto(producto);
                                                            }}
                                                        >
                                                            <PencilIcon />
                                                        </Button>
                                                        <Button
                                                            onClick={() => {
                                                                setShowModalDelete(true);
                                                                setPopUp('deleteProduct');
                                                                setSelectedProducto(producto);
                                                            }}
                                                        >
                                                            <DeleteIcon style={{ color: 'red' }} />
                                                        </Button>
                                                    </TableCell>
                                                </TableRow>
                                            ))
                                            :
                                            <TableRow>
                                                <TableCell align="center" colSpan={6}>Sin resultados</TableCell>
                                            </TableRow>
                                        }
                                    </TableBody>
                                </Table>
                            </ThemeProvider>
                        </TableContainer>
                        <TablePagination
                            rowsPerPageOptions={[20, 30, 40]}
                            component="div"
                            count={productos?.length || 0}
                            rowsPerPage={rowsPerPage}
                            page={page}
                            onPageChange={handleChangePage}
                            onRowsPerPageChange={handleChangeRowsPerPage}
                        />
                    </Box>
                </Grid>
            </Container>
            {
                popUp === 'addNewProduct' && (
                    <Popup
                        largo="sm"
                        title='Agregar Insumo'
                        openPopup={openPopup}
                        setOpenPopup={setOpenPopup}
                    >
                        <AddProductoS
                            closeModal={() => { setOpenPopup(false) }}
                            getProducts={getProductos}
                            editMode={editMode}
                            is_supply={true}
                        />
                    </Popup>

                )
            }
            {
                popUp === 'editProduct' && (
                    <Popup
                        largo="sm"
                        title='Editar Insumo'
                        openPopup={openPopup}
                        setOpenPopup={setOpenPopup}
                    >
                        <EditProducto
                            idProducto={selectedProducto.id}
                            handleLoad={getProductos}
                            closeModal={() => { setOpenPopup(false) }}
                        />
                    </Popup>
                )
            }
            {
                popUp === "deleteProduct" && (
                    <DeletedProduct
                        open={showModalDelete}
                        closeModal={closeModalDelete}
                        ProductId={selectedProducto.id}
                        TipoProductId={selectedProducto.id_tipo_producto}
                        id_sub={submodulo[0]?.id}
                        isSupply={selectedProducto.is_supply}
                        getProducts={getProductos}
                        handleLoad={getProductos}
                    />
                )
            }
        </Protected>
    );
}
