import { API_URL_NUPY } from "@/toolbox/defaults/app";
import axios from "axios";

export const ComandaRepository = {

    get: async (data: {}): Promise<any> => {
        const res = await axios.get(`${API_URL_NUPY}/comanda/list`, { params: data });
        return res.data;
    },

    register: async (data: {}): Promise<any> => {
        const res = await axios.post(`${API_URL_NUPY}/comanda/register`, data);
        return res.data;
    },

    getPDF: async (id: number) => {
        const res = await axios.get(`${API_URL_NUPY}/comanda/pdf/${id}`, { responseType: 'blob' })
        return res.data
    },

    imprimir: async (idticket: number): Promise<any> => {
        const res = await axios.post(`${API_URL_NUPY}/comanda/imprimir/${idticket}`);
        return res.data;
    },
}