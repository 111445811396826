
import React, { useState, useEffect } from "react";
import { Protected } from "@/components/layout/Protected";
import { Box, Button, ButtonGroup, Card, CardActions, CardContent, Container, Divider, Grid, IconButton, InputAdornment, Menu, TextField, Tooltip, Typography } from "@mui/material";
import { Props } from './Salas.type';
import { AddSala } from './components/AddSala/AddSala';
import { AddMesa } from './components/AddMesa/AddMesa';
import { CodigoQR } from './components/CodigoQR/CodigoQR';
import { Popup } from '../Editor/components/Popup/Popup';
import { Paper, TableBody, TableCell, TableContainer, TableHead, TableRow, Table, createTheme, ThemeProvider } from '@mui/material';
import { esES } from '@mui/material/locale';
import DeleteIcon from '@mui/icons-material/Delete';
import { PencilIcon } from "@toolbox/constants/icons";
import EditIcon from '@mui/icons-material/Edit';
import { salas } from '../../service/services/Salas.service';
import { Loading } from '../../components/common/Loading/Loading';
import TableRestaurantIcon from '@mui/icons-material/TableRestaurant';
import ContentPasteOffIcon from '@mui/icons-material/ContentPasteOff';
import './components/Modal.sass'
import AssignmentIcon from '@mui/icons-material/Assignment';
import { ModalReservas } from "./../Reservas/components/ModalReservas";
// import LocalPrintshopIcon from '@mui/icons-material/LocalPrintshop';
import { Snackbar, Alert } from '@mui/material';
import QrCodeIcon from '@mui/icons-material/QrCode';
import SettingsIcon from '@mui/icons-material/Settings';
import { ConfigMesa } from "./components/ConfigMesa";
import { CheckCircle } from '@mui/icons-material';
import SearchIcon from "@mui/icons-material/Search";
import { MenuItem } from "react-pro-sidebar";
import MoreVertIcon from '@mui/icons-material/MoreVert';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';

export const Salas: React.FC<Props> = (props: Props): JSX.Element => {

    const [popUp, setPopUp] = React.useState<String>('');
    const [openPopup, setOpenPopup] = React.useState(false);
    const [allSalas, setSalas] = React.useState([]);
    const [salasBackUp, setSalasBackUp] = React.useState([]);
    const [loadData, setLoadData] = React.useState<boolean>(false);
    const [selectSala, setSelectSala] = React.useState<any>('');
    const [selectMesa, setSelectMesa] = React.useState<any>('');
    const [mesas, setMesas] = React.useState([]);
    const [textSearch, setTextSearch] = useState<string>("");
    const [filter, setFilter] = useState<Array<any>>([]);
    // eslint-disable-next-line
    const [dataModalSala, setDataModalSala] = useState<any>(false);

    const [showModalConfig, setShowModalConfig] = useState<any>(false);
    const [showModalQr, setShowModalQr] = useState<any>(false);
    const [showModalAddMesa, setShowModalAddMesa] = useState<any>(false);
    const [showModalEditMesa, setShowModalEditMesa] = useState<any>(false);
    const [showModalReserva, setShowModalReserva] = useState<any>(false);

    const [snackBarConfig, setSnackBarConfig] = React.useState<any>({
        open: false,
        severity: 'error',
        message: 'Error',
        autoHideDuration: 3000,
    });

    useEffect(() => {
        if (textSearch) {
            let copyFilters = [];
            copyFilters = mesas.filter(mesa => (mesa?.nombre.toLowerCase().includes(textSearch.toLowerCase())))
            setFilter([...copyFilters])
        }
    }, // eslint-disable-next-line
        [textSearch])

    React.useEffect(() => {
        async function fetch() {
            setLoadData(true);
            await salas.cargarSalas().then((data) => {
                setSalas(data.salas);
                setSalasBackUp(data.salas)
                setLoadData(false);
            });
        }
        fetch();
    },// eslint-disable-next-line
        []);


    const theme = createTheme(
        {
            palette: {
                primary: { main: '#1976D2' },
            },
        },
        esES,
    );

    const deleteSala = async (id) => {
        setLoadData(true);
        await salas.deleteSala(id);
        cargarSalas();
        setLoadData(false);
    }

    const cargarSalas = async () => {
        setLoadData(true);
        await salas.cargarSalas().then((data) => {
            setSalas(data.salas || []);
            setLoadData(false);
        });
    }

    const cargarMesas = async (id_sala) => {
        setLoadData(true);
        await salas.cargarMesas(id_sala).then((data) => {
            setMesas(data.mesas || [])
            setFilter(data.mesas || [])
            setLoadData(false)
            // setFilter(data.mesas)
        });

    }

    const deleteMesa = async (item) => {
        setLoadData(true);
        if (item.state === 0) {
            setSnackBarConfig(prev => ({
                ...prev,
                open: true,
                message: `No se puede eliminar una mesa ocupada`,
            }));
            setLoadData(false);
        } else {
            await salas.deleteMesa(item.id).then(() => {
                const deleteCant = selectSala.mesas - 1
                selectSala.mesas = selectSala.mesas - 1;
                setSelectSala(selectSala);
                salas.editSala(selectSala.id, selectSala.nombre, deleteCant, selectSala.state).then(() => {
                    cargarMesas(selectSala.id);
                    cargarSalas();
                    setLoadData(false);
                });
            });
        }
    }

    //  const funcionSwitch = async (item)=>{
    //      setLoadData(true);
    //      if(item.state === 1){
    //          await salas.editMesaStatus(item.id,0);
    //      }else{
    //          await salas.editMesaStatus(item.id,1);
    //      }
    //      cargarMesas(selectSala.id).then();

    //  }

    const handleLoad = (e) => {
        if (e === 'editMesa') {
            setOpenPopup(true);
            setPopUp('mesas');
            cargarMesas(selectSala.id);
        } else {
            selectSala.mesas = selectSala.mesas + 1;
            setSelectSala(selectSala);
            cargarSalas();
            setOpenPopup(true);
            setPopUp('mesas');
            cargarMesas(selectSala.id);
        }
    }

    const handleLiberarMesa = async (mesa) => {
        try {
            const response = await salas.disponibilizarMesa(mesa.id)
            if (response.status == 200) {
                cargarMesas(selectSala.id)
                setSelectSala(response.data)
            }
        } catch (error) {
            console.log('error:', error)
        }
    }

    const onSearch = (search: string) => {
        const searchLowerCase = search.trim().toLowerCase();
        if (search !== '') {
            const filteredProducts = salasBackUp.filter(sala => {
                const nombre = sala.nombre && sala.nombre.toLowerCase()
                return (
                    (nombre && nombre.includes(searchLowerCase))
                );
            });
            setSalas(filteredProducts);
        } else {
            setSalas(salasBackUp);
        }
    };

    const [diseñoSala, setDiseñoSala] = useState<any[]>([])
    const [menuStates, setMenuStates] = useState({})

    const handleClick = (event, mesa) => {
        setMenuStates({ ...menuStates, [mesa.id]: event.currentTarget })
    };

    const handleClose = () => {
        setMenuStates({})
    };

    useEffect(() => {
        if (selectSala) actualizarDiseñoSala(selectSala.filas, selectSala.columnas)
    }, [selectSala])

    const actualizarDiseñoSala = (numFilas: number, numColumnas: number) => {
        const nuevoDiseñoSala = [];
        for (let i = 0; i < numFilas; i++) {
            for (let j = 0; j < numColumnas; j++) {
                const mesaExistente = selectSala.all_mesas.find(mesa => mesa.fila === i && mesa.columna === j);
                if (mesaExistente) {
                    nuevoDiseñoSala.push(mesaExistente);
                } else {
                    nuevoDiseñoSala.push({
                        nombre: '',
                        fila: i,
                        columna: j,
                    });
                }
            }
        }
        setDiseñoSala(nuevoDiseñoSala);
    };

    const renderMesas = () => {
        const rows = [];
        const numFilas = Math.max(...diseñoSala.map(mesa => mesa.fila)) + 1;
        const numColumnas = Math.max(...diseñoSala.map(mesa => mesa.columna)) + 1;

        for (let i = 0; i < numFilas; i++) {
            const cells = [];
            for (let j = 0; j < numColumnas; j++) {
                const mesa = diseñoSala.find(m => m.fila === i && m.columna === j);

                if (mesa.id) {
                    cells.push(
                        <TableCell key={`cell-${i}-${j}`} sx={{ border: 'none', width: 'fit-content' }}>
                            <Paper elevation={3}
                                style={{
                                    width: '75px',
                                    height: '75px',
                                    display: 'flex',
                                    flexDirection: 'column',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    cursor: 'pointer',
                                    background: mesa?.state === 0 ? '#990000' : '#229954',
                                    color: '#fff',
                                    margin: '0px auto'
                                }}
                            >
                                <Typography variant="h6" sx={{ textAlign: 'center', fontSize: 'calc(10px + 0.5vw)', color: 'white' }}>{mesa?.nombre.toUpperCase().split(" DE ")[0]}</Typography>
                                <ButtonGroup
                                    variant="contained"
                                    aria-label="button-group-custom-table"
                                    style={{
                                        color: "#808080",
                                        display: 'flex',
                                        justifyContent: 'center',
                                        backgroundColor: '#fff'
                                    }}
                                >
                                    <Tooltip title={"Acciones"}>
                                        <IconButton
                                            onClick={(event) => handleClick(event, mesa)}
                                        >
                                            <MoreVertIcon fontSize="small" />
                                        </IconButton>
                                    </Tooltip>
                                </ButtonGroup>

                                <Menu
                                    id={`simple-menu-${mesa.id}`} // Identificador único para cada menú desplegable
                                    anchorEl={menuStates[mesa.id]} // Usa el estado correspondiente al menú desplegable de la mesa actual
                                    open={Boolean(menuStates[mesa.id])} // Verifica si el menú desplegable está abierto
                                    onClose={handleClose}
                                    sx={{
                                        boxShadow: 'none',
                                        '& .MuiBackdrop-root': {
                                            opacity: 0
                                        },
                                        '& .MuiPaper-root': {
                                            boxShadow: 'none'
                                        }
                                    }}
                                >
                                    <MenuItem onClick={() => deleteMesa(mesa)} style={{ padding: '5px' }}>
                                        <DeleteIcon fontSize="small" />
                                        Eliminar
                                    </MenuItem>
                                    <MenuItem onClick={() => {
                                        setShowModalEditMesa(true);
                                        setSelectMesa(mesa);
                                    }} style={{ padding: '5px' }}>
                                        <EditIcon fontSize="small" />
                                        Editar
                                    </MenuItem>
                                    <MenuItem onClick={() => {
                                        setShowModalQr(true);
                                        setSelectMesa(mesa);
                                    }} style={{ padding: '5px' }}>
                                        <QrCodeIcon fontSize="small" />
                                        Ver QR
                                    </MenuItem>
                                    <MenuItem onClick={() => {
                                        setShowModalReserva(true);
                                        setSelectMesa(mesa);
                                    }} style={{ padding: '5px' }}>
                                        <AssignmentIcon fontSize="small" />
                                        Ver Reserva
                                    </MenuItem>
                                    <MenuItem onClick={() => {
                                        setShowModalConfig(true);
                                        setSelectMesa(mesa);
                                    }} style={{ padding: '5px' }}>
                                        <SettingsIcon fontSize="small" />
                                        Configuración
                                    </MenuItem>

                                    {mesa.state === 0 && <MenuItem onClick={() => handleLiberarMesa(mesa)} style={{ padding: '5px' }}>
                                        <CheckCircleIcon fontSize="small" />
                                        Liberar mesa
                                    </MenuItem>}

                                </Menu>
                            </Paper>
                        </TableCell>
                    );
                } else {
                    cells.push(
                        <TableCell key={`cell-${i}-${j}`} sx={{ border: 'none' }}>
                            <div
                                style={{
                                    width: '75px',
                                    height: '75px',
                                    display: 'flex',
                                    flexDirection: 'column',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    background: '#efefef',
                                    color: '#fff'
                                }}
                            >
                            </div>
                        </TableCell>
                    );
                }
            }
            rows.push(<TableRow key={`row-${i}`} sx={{ border: 'none' }}>{cells}</TableRow>);
        }
        return rows;
    };

    return (
        <Protected>
            {loadData && <Loading />}
            <Snackbar
                open={snackBarConfig.open}
                autoHideDuration={snackBarConfig.autoHideDuration}
                onClose={() => setSnackBarConfig(prev => ({ ...prev, open: false }))}
                anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
            >
                <Alert
                    onClose={() => setSnackBarConfig(prev => ({ ...prev, open: false }))}
                    severity={snackBarConfig.severity}
                    variant="filled"
                >
                    {snackBarConfig.message}
                </Alert>
            </Snackbar>
            <Grid container spacing={2}>
                {/* <Grid container justifyContent='space-between' spacing={2}>
                    <Grid item>
                        <Typography variant='h4' >Gestión de Sala</Typography>
                    </Grid>
                    <Grid item>
                        
                    </Grid>
                </Grid> */}
                <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'center' }}>
                    <Typography variant="h4">Gestión de salas</Typography>
                </Grid>
                <Grid item container xs={12}
                    sx={{
                        alignItems: "center",
                        display: "flex",
                        justifyContent: "space-between",
                        flexWrap: "wrap",
                        marginBottom: "10px",
                    }}
                >
                    <Grid item>
                        <TextField
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <SearchIcon fontSize="small" />
                                    </InputAdornment>
                                ),
                            }}
                            placeholder="Buscar..."
                            size="small"
                            onChange={(e) => onSearch(e.target.value)}
                        />
                    </Grid>
                    <Grid item sx={{ textAlign: "right" }}>
                        <Button variant='outlined' style={{ textTransform: "capitalize" }}
                            onClick={() => {
                                setOpenPopup(true);
                                setDataModalSala(false);
                                setPopUp('add');
                            }}
                        >Crear Sala</Button>
                    </Grid>
                </Grid>
                <Grid item xs={12}>
                    <Box sx={{ Width: '100%' }}>
                        <TableContainer component={Paper}>
                            <ThemeProvider theme={theme}>
                                <Table aria-label="simple table" size='small'>
                                    <TableHead >
                                        <TableRow sx={{ bgcolor: '#1976D2' }}>
                                            <TableCell sx={{ color: 'white' }} ><strong>N°</strong></TableCell>
                                            <TableCell sx={{ color: 'white' }} ><strong>Nombre Sala</strong></TableCell>
                                            <TableCell align='center' sx={{ color: 'white' }} ><strong>Cantidad de Mesas</strong></TableCell>
                                            <TableCell align='center' sx={{ color: 'white', minWidth: '8rem', maxWidth: '12rem' }}><strong>Acciones</strong></TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {!!allSalas.length
                                            ?
                                            allSalas.map((item, index) => (
                                                <TableRow key={item.id}>
                                                    <TableCell component="th" scope="row">
                                                        {index + 1}
                                                    </TableCell>
                                                    <TableCell component="th" scope="row">
                                                        {item.nombre.charAt(0).toUpperCase() + item.nombre.slice(1).toLowerCase()}
                                                    </TableCell>
                                                    <TableCell align='center' component="th" scope="row">
                                                        {item.mesas}
                                                    </TableCell>
                                                    <TableCell align='center'>
                                                        <Button color="error"
                                                            onClick={() => { deleteSala(item.id) }}
                                                        >
                                                            <DeleteIcon />
                                                        </Button>
                                                        <Button
                                                            onClick={() => {
                                                                setOpenPopup(true);
                                                                setPopUp('edit');
                                                                setSelectSala(item);
                                                            }}
                                                        >
                                                            <PencilIcon />
                                                        </Button>
                                                        <Button
                                                            onClick={() => {
                                                                setOpenPopup(true);
                                                                setPopUp('mesas');
                                                                setTextSearch("")
                                                                setSelectSala(item);
                                                                cargarMesas(item.id);
                                                            }}
                                                        >
                                                            <TableRestaurantIcon />
                                                        </Button>
                                                    </TableCell>
                                                </TableRow>
                                            ))
                                            :
                                            <TableRow>
                                                <TableCell align="center" colSpan={6}>Sin resultados</TableCell>
                                            </TableRow>
                                        }
                                    </TableBody>
                                </Table>
                            </ThemeProvider>
                        </TableContainer>
                    </Box>
                </Grid>
            </Grid>
            {popUp === 'add' &&
                <Popup
                    largo="md"
                    title='Crear Sala'
                    openPopup={openPopup}
                    setOpenPopup={setOpenPopup}
                >
                    <AddSala
                        closeModal={() => setOpenPopup(false)}
                        onListSala={cargarSalas}
                    />
                </Popup>
            }

            {popUp === 'edit' &&
                <Popup
                    largo="md"
                    title='Editar Sala'
                    openPopup={openPopup}
                    setOpenPopup={setOpenPopup}
                >
                    <AddSala
                        sala={selectSala}
                        closeModal={() => setOpenPopup(false)}
                        onListSala={cargarSalas}
                    />
                </Popup>
            }

            {popUp === 'mesas' &&
                <Popup
                    largo="lg"
                    title={`Mesas de la sala: ${selectSala.nombre.charAt(0).toUpperCase() + selectSala.nombre.slice(1).toLowerCase()}`}
                    openPopup={openPopup}
                    setOpenPopup={setOpenPopup}
                >

                    <Box sx={{ Width: '100%' }}>
                        <Grid item container xs={12} spacing={2} sx={{ display: 'flex', justifyContent: 'center', alignContent: 'center' }}>
                            <Grid item xs={12}>
                                <TableContainer component={Paper}>
                                    <Table sx={{ borderCollapse: 'separate', borderSpacing: '0', borderRadius: '5px', background: '#efefef' }}>
                                        <TableBody>
                                            {renderMesas()}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </Grid>
                        </Grid>
                    </Box >


                    {
                        !loadData && mesas.length === 0 &&
                        <Box>
                            <Box sx={{ display: 'flex', flexDirection: 'row-reverse', marginBottom: '15px' }}>
                                <Button
                                    variant={'outlined'}
                                    onClick={() => {
                                        setOpenPopup(true);
                                        setPopUp('addMesa');
                                    }}
                                >
                                    Agregar
                                </Button>
                            </Box>
                            <Grid container justifyContent="center">
                                <Box>
                                    <Grid container justifyContent="center"> <ContentPasteOffIcon className="iconocarrito" /></Grid>
                                    <Box sx={{ Width: '100%', marginBottom: '30px', textAlign: 'center' }}>No hay Mesas</Box>
                                </Box>
                            </Grid>
                        </Box>
                    }
                </Popup >
            }

            {
                showModalAddMesa &&
                <Popup
                    largo="sm"
                    title='Agregar Mesa'
                    openPopup={showModalAddMesa}
                    setOpenPopup={setShowModalAddMesa}
                >
                    <AddMesa
                        sala={selectSala}
                        handleLoad={handleLoad}
                    />
                </Popup>
            }
            {
                showModalEditMesa &&
                <Popup
                    largo="sm"
                    title='Editar Mesa'
                    openPopup={openPopup}
                    setOpenPopup={setShowModalEditMesa}
                >
                    <AddMesa
                        mesa={selectMesa}
                        id_sala={selectSala.id}
                        handleLoad={handleLoad}
                    />
                </Popup>
            }
            {
                showModalConfig &&
                <Popup
                    largo="sm"
                    title='Seleccionar el tipo de servicio'
                    openPopup={showModalConfig}
                    setOpenPopup={setShowModalConfig}
                >
                    <ConfigMesa
                        mesa={selectMesa}
                        id_sala={selectSala.id}
                        handleLoad={handleLoad}
                        closeModal={() => { setShowModalConfig(false) }}
                    />
                </Popup>
            }
            {
                showModalQr &&
                <Popup
                    largo="sm"
                    title='Generar Código QR'
                    openPopup={showModalQr}
                    setOpenPopup={setShowModalQr}
                >
                    <CodigoQR
                        mesa={selectMesa}
                        closeModal={() => { setShowModalQr(false) }}
                    />
                </Popup>
            }
            {
                showModalReserva &&
                <Popup
                    largo="sm"
                    title='Registro de Reserva'
                    openPopup={showModalReserva}
                    setOpenPopup={setShowModalReserva}
                >
                    <ModalReservas
                        data={selectMesa}
                        onlistTable={() => { cargarMesas(selectSala.id) }}
                        close={() => { setShowModalReserva(false) }}
                    />
                </Popup>
            }

        </Protected >
    )

}
