import React, { useEffect, useState } from 'react';
import ReactToPrint from "react-to-print";
import { ventaService } from '@/service/services/Venta.service';
import './SalePDF.sass';
import { Grid, Typography } from '@mui/material';
import { moneyFormatInt } from '@/toolbox/helpers/money.helper';
import { PRODUCTS_CARD} from '@constants/local-storage';
import {
   saveLocalStorage
} from '@helpers/local-storage-helper';
import { Snackbar, Alert } from '@mui/material';

export const SalePDF: React.FC<any> = (
   props: any
): JSX.Element => {
   const [detalle, setDetalle] = useState<Array<any>>([])
   const [ventaDetalle, setVentaDetalle] = useState<any>()

   const [snackBarConfig, setSnackBarConfig] = useState<any>({
      open: false,
      severity: 'error',
      message: 'Error',
      autoHideDuration: 3000,
   });

   useEffect(() => {
      if (props.venta){
         ventaService.getVentaDetalle(props.venta).then((res) => {
            if (res.status === 200) {
               setDetalle([...res.detalleTicket])
               setVentaDetalle(res.venta)
               setTimeout(() => {
                  let button = document.getElementById("reat-print-id-button");
                  button.click()
               }, 500);
            } else {
               props.setShow({
                  open: false,
                  idVenta: 0,
               })
               props.setOpenPopup(false);
               setSnackBarConfig(prev => ({
                  ...prev,
                  open: true,
                  message: "Ocurrio un error al imprimir la boleta",
               }));
            }
         })
      }
   }, // eslint-disable-next-line
   [props.venta])


   // EST OES PARA IMPRIMIR
   const componentRef = React.useRef(null);

   const onBeforeGetContentResolve = React.useRef(null);

   const [text, setText] = React.useState("old boring text");

   const handleAfterPrint = React.useCallback(() => {
    console.log("`onAfterPrint` called");
    props.setShow({
       open: false,
       idVenta: 0,
    })
    props.setOpenPopup(false);
    saveLocalStorage(PRODUCTS_CARD, []);
  }, // eslint-disable-next-line
  []);

  const handleBeforePrint = React.useCallback(() => {
    console.log("`onBeforePrint` called");
  }, // eslint-disable-next-line
  []);

  const handleOnBeforeGetContent = React.useCallback(() => {
    console.log("`onBeforeGetContent` called");
    setText("Loading new text...");

    return new Promise((resolve) => {
      onBeforeGetContentResolve.current = resolve;

      setTimeout(() => {
        setText("New, Updated Text!");
      }, 2000);
    });
  }, // eslint-disable-next-line
  [setText]);

  React.useEffect(() => {
    if (
      text === "New, Updated Text!" &&
      typeof onBeforeGetContentResolve.current === "function"
    ) {
      onBeforeGetContentResolve.current();
    }
  }, // eslint-disable-next-line
  [onBeforeGetContentResolve.current, text]);

  const reactToPrintContent = React.useCallback(() => {
    return componentRef.current;
  }, // eslint-disable-next-line
  [componentRef.current]);

  const reactToPrintTrigger = React.useCallback(() => {
    // NOTE: could just as easily return <SomeComponent />. Do NOT pass an `onClick` prop
    // to the root node of the returned component as it will be overwritten.

    // Bad: the `onClick` here will be overwritten by `react-to-print`
    // return <button onClick={() => alert('This will not work')}>Print this out!</button>;

    // Good
    return <button id="reat-print-id-button">Print</button>;
  }, []);

   return (
      <div>
         <Snackbar
            open={snackBarConfig.open}
            autoHideDuration={snackBarConfig.autoHideDuration}
            onClose={() => setSnackBarConfig(prev => ({ ...prev, open: false }))}
            anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
            >
            <Alert
            onClose={() => setSnackBarConfig(prev => ({ ...prev, open: false }))}
            severity={snackBarConfig.severity}
            variant="filled"
            >
            {snackBarConfig.message}
            </Alert>
         </Snackbar>

         <div ref={componentRef}>

            <div style={{  marginBottom: 30, width: '300px', margin: 'auto', marginTop: 30 ,padding:"10px"}}>
               <div style={{width:"100%"}} >
                  <div style={{textAlign:"center"}}>
                     <Typography
                     sx={{fontSize:'1.6em !important', fontFamily:'sans-serif',fontWeight:'700'}}
                     >{ventaDetalle?.pedido?.usuario?.sucursales?.empresa?.name || "Empresa"}</Typography>
                     <Typography
                     sx={{fontSize:'1.3em !important', fontFamily:'sans-serif',fontWeight:'700'}}
                     >{props.ventaDetalle? "RUT "+ ventaDetalle?.pedido?.usuario?.sucursales?.empresa?.rut :''}</Typography>
                     <Typography
                     sx={{fontSize:'1.3em !important', fontFamily:'sans-serif',fontWeight:'700'}}
                     >{props.folio? "BOLETA ELECTRONICA ":''}</Typography>
                       <Typography
                     sx={{fontSize:'1.3em !important', fontFamily:'sans-serif',fontWeight:'700'}}
                     >{props.folio? "N° "+ props.folio:' '}</Typography>
                     {/* <Typography
                     sx={{fontSize:'1.4em !important', fontFamily:'sans-serif',fontWeight:'700'}}
                     >{ventaDetalle?.pedido?.ticket?.n_ticket || "--------"}</Typography> */}
                  </div>
                  <hr/>
                  <div>
                  { props.data?.direccion &&
                        <Typography sx={{fontSize:'1.2em !important'}}>
                          <strong>SUCURSAL:</strong>  {props.data?.direccion} </Typography>
                  }
                        <Typography sx={{fontSize:'1.2em !important'}}
                        > <strong>Cliente:</strong> {ventaDetalle?.pedido?.cliente?.rut || "66666666-6"}{''}</Typography>
                        <Typography sx={{fontSize:'1.2em !important'}}
                        ><strong>Empleado:</strong> {ventaDetalle?.pedido?.usuario?.nombre || "Empleado General"}</Typography>
                        <Typography sx={{fontSize:'1.2em !important'}}
                        ><strong>Fecha:</strong>  {ventaDetalle?.fecha || "--------"}{' '}</Typography>
                        {/* <b>HORA:</b> {props.data?.horareporte}{' '} */}
                  </div>
                  <hr style={{border: '0', height: '2px', borderTop: '1px dashed black', borderBottom: '1px dashed black'}}/>
                  <div>
                     <Grid container>
                        <Grid item xs={6}>
                           <Typography sx={{textAlign:"left",fontSize:'1.2em !important'}}>Artículo</Typography>
                        </Grid>
                        <Grid item xs={6}>
                           <Typography sx={{textAlign:"right",fontSize:'1.2em !important'}}>Valor</Typography>
                        </Grid>
                     </Grid>
                  </div>
                  <div>
                  <hr style={{border: '0', height: '2px', borderTop: '1px dashed black', borderBottom: '1px dashed black'}}/>
                  </div>
                  <div>
                     <Grid container>
                     {(detalle||[]).map((item, index) => (
                           <>
                           <Grid item xs={12}>
                              <Typography sx={{textAlign:"left",fontSize:'1em !important'}}>{item?.productos?.nombre}</Typography>
                           </Grid>
                           <Grid item xs={8}>
                              <Typography sx={{textAlign:"left",fontSize:'1em !important'}}>{item.cantidad} x {moneyFormatInt(Math.ceil(item?.productos?.precio_oferta ? item?.productos?.precio_oferta : item?.productos?.precio_standar))}</Typography>
                           </Grid>
                           <Grid item xs={4}>
                              <Typography sx={{textAlign:"right",fontSize:'1em !important'}}>{moneyFormatInt(Math.ceil(Number(item?.productos?.precio_oferta ? item?.productos?.precio_oferta : item?.productos?.precio_standar)*Number(item.cantidad)))}</Typography>
                           </Grid>
                           </>
                        ))}
                     </Grid>
                  </div>
                  <hr/>
                  <div>
                     <Grid container xs={12} style={{padding:"5px",display:"flex", alignItems:"center",color:"#000"}}>
                        {/* MONTO TOTAL */}
                        <Grid item xs={8}>
                           <Typography sx={{ textAlign:"right",fontSize:'1em !important'}}><strong>Descuento</strong></Typography>
                        </Grid>
                        <Grid item xs={4} >
                           <Typography sx={{textAlign:"right", fontWeight:"bold", fontSize:'1em !important'}}><strong>{moneyFormatInt(Math.ceil(ventaDetalle?.descuento_x_puntos))}</strong></Typography>
                        </Grid>
                        <Grid item xs={8}>
                           <Typography sx={{ textAlign:"right",fontSize:'1em !important'}}><strong>Monto Neto</strong></Typography>
                        </Grid>
                        <Grid item xs={4} >
                           <Typography sx={{textAlign:"right", fontWeight:"bold", fontSize:'1em !important'}}><strong>{moneyFormatInt(Math.ceil(props.montoNeto))}</strong></Typography>
                        </Grid>
                        <Grid item xs={8}>
                           <Typography sx={{ textAlign:"right",fontSize:'1em !important'}}><strong>IVA (19%)</strong></Typography>
                        </Grid>
                        <Grid item xs={4} >
                           <Typography sx={{textAlign:"right", fontWeight:"bold", fontSize:'1em !important'}}><strong>{moneyFormatInt(Math.ceil(ventaDetalle?.monto_total - props.montoNeto))}</strong></Typography>
                        </Grid>
                        <Grid item xs={8}>
                           <Typography sx={{ textAlign:"right",fontSize:'1em !important'}}><strong>Total a Pagar</strong></Typography>
                        </Grid>
                        <Grid item xs={4} >
                           <Typography sx={{textAlign:"right", fontWeight:"bold", fontSize:'1em !important'}}><strong>{moneyFormatInt(Math.ceil(ventaDetalle?.monto_total))}</strong></Typography>
                        </Grid>
                     </Grid>
                  </div>
                  <hr/>
                  <div>
                     <Grid container alignItems="center">
                        {<img
                           width='100%'
                           alt='Timbre Fiscal'
                           className='timbrefiscal'
                           src={props?.timbre}
                           //src={props.data.timbre!=''?props.data.timbre:`data:image/png;base64, ${timbre}`}
                        />}
                        <p style={{ textAlign: 'center', fontSize: 12, margin: 'auto' }}>
                           Timbre Electrónico S.I.I. · Verifique Documento: http://www.sii.cl
                        </p>
                     </Grid>
                  </div>
               </div>
            </div>
         </div>
         <ReactToPrint
            content={reactToPrintContent}
            documentTitle="comprobante-pago"
            onAfterPrint={handleAfterPrint}
            onBeforeGetContent={handleOnBeforeGetContent}
            onBeforePrint={handleBeforePrint}
            removeAfterPrint
            trigger={reactToPrintTrigger}
         />
      </div>
   );
}
