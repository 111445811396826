import React, {  useEffect, FunctionComponent, useState, useRef } from 'react';
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import Tooltip from '@mui/material/Tooltip';
import Box from '@mui/material/Box';
import TableBody from '@mui/material/TableBody';
import moment from 'moment';
import { STATUS_FINALIZADO } from '@constants/status-ticket';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import {  Grid, IconButton, TableContainer, TextField, Button, Divider, Menu, MenuItem} from '@mui/material';
import { ticketService } from '@service/services/Ticket.service';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { SendAndArchive } from '@mui/icons-material';
import { PopupComponent } from '@/components/common/Popup';
import { PagarPedido } from '../Pagar';
import { Loading } from '@/components/common/Loading';
import { useLocalStorage } from '@toolbox/hooks/local-storage.hook';
import { KEY_EMPRESA, KEY_USER_DATA } from '@toolbox/constants/local-storage';
import { Link } from 'react-router-dom';
import { ROUTE_CUADRATURAS } from '@/toolbox/constants/route-map';
import { SalePDF } from '@/components/common/SalePDF';
import { ID_TYPE_RESTAURANTE, ID_TYPE_RESTAURANTE_DIRECTO } from '@/toolbox/constants/type-entity';
import { Snackbar, Alert } from '@mui/material';
import { notaCreditoService } from '@/service/services/NotaCredito.service';
import { CuponesService } from '@/service/services/Cupones.service';
import PaymentsIcon from '@mui/icons-material/Payments';
import DeleteIcon from '@mui/icons-material/Delete';
import PrintIcon from '@mui/icons-material/Print';
import DescriptionIcon from '@mui/icons-material/Description';
import { ventaService } from '@/service/services/Venta.service';
import { readLocalStorage } from '@/toolbox/helpers/local-storage-helper';
import { useReactToPrint } from 'react-to-print';
import { ComponentPrint } from '@/components/common/ComponentPrint';
import { moneyFormatInt } from '@/toolbox/helpers/money.helper';

const Titulo = styled('div')(({ theme }) => ({
   ...theme.typography.button,
   padding: theme.spacing(1),
   fontWeight: 'bold',
   fontSize: '20px',
   color: 'black',
 }));
 const Div = styled('div')(({ theme }) => ({
   ...theme.typography.button,
   padding: theme.spacing(1),
   fontWeight: 'bold',
   fontSize: '20px',
   color: 'rgba(102, 102, 102, 0.4)',
 }));

export const GenerarVenta: FunctionComponent = (props: any) => {
   const [loadData,setLoadData] = React.useState<boolean>(false);
   const [pedidos,setPedidos] = React.useState<Array<any>>([]);
   const [finalizados,setFinalizados] = React.useState<Array<any>>([]);
   const [openPopup, setOpenPopup] = React.useState(false);
   const [pagarProducts, setPagarProducts] = React.useState(false);
   const [pedidoSelected, setPedidoSelect] = React.useState<number>(0);
   const [clientePedido, setClientePedido] = React.useState<number>(null)
   const [selected, setSelected] = React.useState<"faltantes"|"finalizado">("faltantes");
   const [dataEmpresa] = useLocalStorage(KEY_EMPRESA, {
      id: 0, id_tipo_empresa:0, mesas: 0
   });

   const componentRef = useRef();

   const [dataImprimir, setDataImprimir] = useState(null);

   const dataUsuario = readLocalStorage(KEY_USER_DATA);
   const role = dataUsuario.user.rol;
   const nombreEmpresa = dataUsuario.user?.nombre_empresa;
   const rutEmpresa = dataUsuario.user?.rut_empresa

   const [textSearch, setTextSearch] = useState<string>("");
   const [filterPedidos, setFilterPedidos] = useState<Array<any>>([]);
   const [filterFinalizados, setFilterFinalizados] = useState<Array<any>>([]);
   const [detailMode,setDetailMode] = React.useState<boolean>(false);
   const [anularMode,setAnularMode] = React.useState<boolean>(false);

   const [verVentas, setVerVentas] = React.useState<boolean>(false);
   const [cupones, setCupones] = React.useState([]);

   const [showPDF,setShowPDF] = React.useState({
      open: false,
      timbre: '',
      montoNeto: 0,
      folio:'',
      idVenta: 0
   });

   const [snackBarConfig, setSnackBarConfig] = useState<any>({
      open: false,
      severity: 'error',
      message: 'error',
      autoHideDuration: 3000,
   });

   //menu anular
   const [anchorEl, setAnchorEl] = React.useState(null);
   const open = Boolean(anchorEl);

   const handleClick = (event) => {
      setAnchorEl(event.currentTarget);
   };
   const handleClose = () => {
   setAnchorEl(null);
   };

   useEffect(() => {
      cargarPedidos();
      getCupones();
      if(dataEmpresa.id_tipo_empresa !== (ID_TYPE_RESTAURANTE || ID_TYPE_RESTAURANTE_DIRECTO)){
         setVerVentas(true);
      }// eslint-disable-next-line
   },[])

   useEffect(() => {
      if(textSearch){
         let copyFiltersPedido = []
         let copyFiltersFinalizado = []

         copyFiltersPedido = pedidos.filter(pedido => (
            pedido.cliente?.nombre.toLowerCase().includes(textSearch.toLowerCase()) ||
            pedido.ticket?.n_ticket.toLowerCase().includes(textSearch.toLowerCase()) ||
            pedido.mesa?.nombre.toLowerCase().includes(textSearch.toLowerCase())
            ));
            copyFiltersFinalizado = finalizados.filter(pedido => (
            pedido.cliente?.nombre.toLowerCase().includes(textSearch.toLowerCase()) ||
            pedido.ticket?.n_ticket.toLowerCase().includes(textSearch.toLowerCase()) ||
            pedido.mesa?.nombre.toLowerCase().includes(textSearch.toLowerCase())
            ));
         setFilterPedidos([...copyFiltersPedido])
         setFilterFinalizados([...copyFiltersFinalizado])
      }
      // eslint-disable-next-line
   },[textSearch])

   const getCupones =  async() => {
      await CuponesService.couponsAvailable().then((data)=>{
         if(data.status === 200){
            setCupones(data.cupones);
         }
      });
   }

   const handleKeyDown = (e) =>{
      let codigo: any = document.getElementById("mi-codigo-de-barras");
      if(e.KeyCode === 13){
         let newValue = codigo.value;
         setTextSearch(newValue);
         if(!newValue){
            setFilterPedidos([...pedidos])
            setFilterFinalizados([...finalizados])
         }
      }else{
         let text = `${e.target.value}`;
         setTextSearch(text);
         if(!text){
            setFilterPedidos([...pedidos])
            setFilterFinalizados([...finalizados])
         }
      }
   }

   const cargarPedidos = async () => {
      let newValue = new Date();
      const formatFecha = moment(newValue).format('DD-MM-YYYY');
      setLoadData(true);
      await ticketService.getPedidoXFinalizar().then((data)=>{
         if (data.status === 200) {
            setPedidos(data.pedidos || []);
            setFilterPedidos(data.pedidos || []);
            setLoadData(false);
         }
      });
      setLoadData(true)
      await ticketService.getPedidos(STATUS_FINALIZADO,formatFecha).then((data)=>{
         if (data.status === 200) {
            setFinalizados(data.ticket || []);
            setFilterFinalizados(data.ticket || []);
            setLoadData(false);
         }
      });
   }

   const anularVentaParcial = async() => {
      setLoadData(true);
      await notaCreditoService.anularVentaTotal(pedidoSelected).then((data) => {
         cargarPedidos();
         if (data.status === 404) {
            setSnackBarConfig(prev => ({
               ...prev,
               open: true,
               error: `${data.error}`,
            }));
         }else{
            setSnackBarConfig(prev => ({
               ...prev,
               open: true,
               message: `${data.message}`,
            }));
         }
      });
   }

   const tituloDoc = (tipoDoc) => {
      switch(tipoDoc){
         case '41':
            return 'BOLETA ELECTRONICA EXENTA';
         case '39': 
            return 'BOLETA ELECTRONICA';
         case '34':
            return 'FACTURA ELECTRONICA EXENTA';
         case '33':
            return 'FACTURA ELECTRONICA';
      }        
   }

   const viewPdfSoftnet = (item) => {
      const pdfUrl = item?.venta?.pdf_softnet;
      
      if (pdfUrl) {
          console.log(pdfUrl);
          window.open(pdfUrl, '_blank');
      } else {
          console.error('No se encontró una URL válida para el PDF.');
      }
  };
  

   const print = async(idTicket) => {
      const resp = await ventaService.getVentaByTicket(idTicket);
      
      const venta = {
         detalle:  resp.pedido.ticket.detalle_ticket,
         ventaDetalle: resp,
         nombreEmpresa: nombreEmpresa,
         rutEmpresa: rutEmpresa,
         folio: resp.folio,
         timbre: resp.timbre_softnet,
         montoNeto: resp.tipo_doc === '41' || resp.tipo_doc === '34'? 0 : resp.monto_neto,
         IVA: resp.monto_total - resp.monto_neto,
         clienteSelect: resp.pedido.cliente,
         nombreCajero:  null,
         exento: resp.exento,
         titulo: tituloDoc(resp.tipo_doc),
         caja:  ''
      }

      setDataImprimir(venta)
   }

   const handlePrint = useReactToPrint({
      content: () => componentRef.current,

   });

   useEffect(() => {
      if (dataImprimir !== null) {
         handlePrint();
      }
      // eslint-disable-next-line
   }, [dataImprimir])

   const mostrarPedidos = (type : 'faltantes' | 'finalizado') =>{
      return(
      <>
      {loadData && <Loading/>}
      <TableContainer component={Paper}>
         <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
               <TableRow>
                  <TableCell>{`N°`}</TableCell>
                  {type === 'finalizado' &&
                     (
                        <>
                        <TableCell align="left">Folio</TableCell>
                        <TableCell align="left">Hora</TableCell>
                        </>
                  )}
                  <TableCell>Cliente</TableCell>
                  <TableCell align="left">Usuario</TableCell>
                  {/* <TableCell align="left">N° ticket</TableCell> */}
                  {
                     dataEmpresa && dataEmpresa.mesas === 1 && (
                        <TableCell align="left">Mesa</TableCell>
                     )
                  }

                  <TableCell align="left">Estado</TableCell>
                  <TableCell align="left">Monto total</TableCell>
                  <TableCell align="center">Detalle</TableCell>
                  <TableCell align="center">Acciones&nbsp;</TableCell>

                  {/* {
                     type==='finalizado' && (
                        <TableCell align="center">Ver Boleta&nbsp;</TableCell>
                     )
                  } */}
               </TableRow>
            </TableHead>
            <TableBody>
               {(type === 'faltantes' ? filterPedidos : filterFinalizados).map((item,index) => (
                  <TableRow
                     key={item.id}
                     sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                     classes={{ hover: 'hover-row-t' }}
                  >
                     <TableCell component="th" scope="row">
                        {index+1}
                     </TableCell>
                     {type === 'finalizado' &&
                     (
                        <>
                        <TableCell align="left">{item?.venta?.folio}</TableCell>
                        <TableCell align="left">{moment(item?.venta?.created_at).format('HH:mm')}</TableCell>
                        </>
                  )}
                     <TableCell >{item.cliente?.nombre || item.pedidoDetail?.cliente?.nombre || 'Cliente General'}</TableCell>
                     <TableCell align="left">{item.usuario?.usuario}</TableCell>
                     {/* <TableCell align="left">{item.ticket?.n_ticket}</TableCell> */}
                     {
                        dataEmpresa && dataEmpresa.mesas === 1 && (
                           <TableCell align="left">{item?.mesa?.nombre || '---'}</TableCell>
                        )
                     }
                     <TableCell align="left">{item.estado}</TableCell>
                     <TableCell align="left">{moneyFormatInt(item.monto_total || 0)}</TableCell>

                     <TableCell align="center" sx={{width: '100px'}}>
                        <Tooltip title={"Ver detalle de venta"}>
                           <IconButton aria-label="add to shopping cart" color="secondary" sx={{marginRight: '5px'}}
                              onClick={()=>{
                                 setOpenPopup(true);
                                 setPedidoSelect(item.id);
                                 setClientePedido(item.cliente)
                                 setSelected(type)
                                 setDetailMode(true);
                                 setAnularMode(false);
                                 setPagarProducts(false);
                              }}
                              >
                                 <VisibilityIcon/>
                           </IconButton>
                        </Tooltip>
                     </TableCell>
                     {
                        type==='faltantes' && (
                           <>
                           <TableCell align="center" sx={{width: '100px'}}>
                              <Tooltip title={"Realizar pago"}>
                                 <IconButton aria-label="add to shopping cart" color="secondary" sx={{marginRight: '5px'}}
                                    onClick={()=>{
                                       setOpenPopup(true);
                                       setPedidoSelect(item.id);
                                       setClientePedido(item.cliente)
                                       setSelected(type)
                                       setDetailMode(false);
                                       setPagarProducts(false);
                                    }}
                                    >
                                       <SendAndArchive/>
                                 </IconButton>
                              </Tooltip>
                              <Tooltip title={"Pagos por partes"}>
                                 <IconButton aria-label="add to shopping cart" color="secondary" sx={{marginRight: '5px'}}
                                    onClick={()=>{
                                       setOpenPopup(true);
                                       setDetailMode(true);
                                       setPagarProducts(true);
                                       setPedidoSelect(item.id);
                                    }}
                                    >
                                       <PaymentsIcon/>
                                 </IconButton>
                              </Tooltip>
                           </TableCell>
                           </>
                        )
                     }
                     {
                        type==='finalizado' && (
                           <TableCell align="center" sx={{width: '100px'}}>
                              <div>
                              {/* <Button
                                 id="basic-button"
                                 variant='contained'
                                 size="small"
                                 color="error"
                                 aria-controls={open ? 'basic-menu' : undefined}
                                 aria-haspopup="true"
                                 aria-expanded={open ? 'true' : undefined}
                                 style={{textTransform: "capitalize"}}
                                 sx={{fontSize: "14px !important"}}
                                 onClick={(e) => {
                                    handleClick(e);
                                    setPedidoSelect(item.id);
                                 }}
                              >  Anular
                              </Button> */}

                              <Tooltip title={"Ver documento softnet"}>
                                    <Button color="secondary"
                                       onClick={() => {
                                          viewPdfSoftnet(item)
                                       }}
                                    >
                                       <DescriptionIcon />
                                    </Button>
                              </Tooltip>

                              <Tooltip title={"Imprimir"}>
                                    <Button color="primary"
                                       onClick={() => {
                                          print(item.id_ticket)
                                       }}
                                    >
                                       <PrintIcon />
                                    </Button>
                              </Tooltip>


                              <Button color="error"
                                 onClick={(e) =>  {
                                    handleClick(e);
                                    setPedidoSelect(item.id);
                                 }}
                              >
                                    <DeleteIcon/>
                              </Button>

                              <Menu
                                 id="basic-menu"
                                 anchorEl={anchorEl}
                                 open={open}
                                 onClose={handleClose}
                                 MenuListProps={{
                                   'aria-labelledby': 'basic-button',
                                 }}
                                 >
                                 <MenuItem onClick={() => {
                                    handleClose();
                                    anularVentaParcial();
                                    }}>Totalmente</MenuItem>
                                 <MenuItem onClick={() => {
                                    handleClose();
                                    setOpenPopup(true);
                                    setDetailMode(true);
                                    setAnularMode(true);
                                 }}>Parcialmente</MenuItem>
                              </Menu>
                              </div>
                           </TableCell>
                        )
                     }

                  </TableRow>
               ))}
            </TableBody>
         </Table>
      </TableContainer>
      </>
      )
   }

   return(
      <>{loadData && <Loading/>}

      <Snackbar
         open={snackBarConfig.open}
         autoHideDuration={snackBarConfig.autoHideDuration}
         onClose={() => setSnackBarConfig(prev => ({ ...prev, open: false }))}
         anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
         >
         <Alert
         onClose={() => setSnackBarConfig(prev => ({ ...prev, open: false }))}
         severity={snackBarConfig.severity}
         variant="filled"
         >
         {snackBarConfig.message}
         </Alert>
   </Snackbar>

      <Grid item sx={{display:"flex", alignItems: "center", justifyContent:"space-between"}}>
         <Grid sx={{display:"flex", alignItems: "center"}}>
            <Button variant='outlined'
            component={Link} to={ROUTE_CUADRATURAS}
            >Ver Cuadraturas</Button>
            {
               dataEmpresa.id_tipo_empresa === (ID_TYPE_RESTAURANTE || ID_TYPE_RESTAURANTE_DIRECTO) && (
                  <Button variant='outlined' sx={{marginLeft: '10px', height: '36.5px', width: '165.14', fontSize: '13px !important'}} onClick={() => {setVerVentas(!verVentas)}}>
                     {verVentas ? 'Ocultar Ventas del Día' : 'Ver Ventas del Día'}
                  </Button>
               )
            }
         </Grid>

         <Grid sx={{display:"flex", alignItems: "center"}}>
            <Box sx={{ marginBottom:'10px', marginRight: '5px'}}>
               <span>{"Buscar pedido:"}</span>
            </Box>
            <TextField
            type="text"
            id="mi-codigo-de-barras"
            placeholder="Escriba para buscar..."
            onChange={handleKeyDown}
            className="H-card__fcontainer__filter__input"
            style={{backgroundColor: "white", width:"290px", marginLeft: "10px"}}
            />
         </Grid>

      </Grid>

      <Divider sx={{ mt: 2, mb:2 }} />

      <Box sx={{ marginLeft:'10px',marginRight:'10px', marginBottom:'20px'}}>
         {
            verVentas &&  (
               <>
               <Titulo>{"Pedidos finalizados:"}</Titulo>
               {(!loadData && finalizados.length !== 0) && (
                  <Box sx={{backgroundColor: 'rgba(224, 224, 224, 0.5)', padding: '10px', borderRadius: '10px'}}>
                     {mostrarPedidos('finalizado')}
                  </Box>)
               }
               {
                  !loadData && finalizados.length === 0 && (
                     <Box sx={{backgroundColor: 'rgba(224, 224, 224, 0.5)', padding: '15px', borderRadius: '10px', height:'100px', justifyContent: 'center',alignItems: 'center', display: 'flex' }} >
                        <Div>{"Pedidos Finalizados no encontradas"}</Div>
                     </Box>
                  )
               }
               {loadData && (
                  <Box sx={{backgroundColor: 'rgba(224, 224, 224, 0.5)', padding: '15px', borderRadius: '10px', height:'100px'}}>
                  </Box>)
               }
               </>
            )
         }
         <Titulo>{"Pedidos por cancelar:"}</Titulo>
         {
            !loadData && pedidos.length !== 0 && (
               <Box sx={{backgroundColor: 'rgba(224, 224, 224, 0.5)', padding: '10px', borderRadius: '10px'}}>
                  { mostrarPedidos('faltantes') }
               </Box>
            )
         }
      
         {
            !loadData && pedidos.length === 0 && (
               <Box sx={{backgroundColor: 'rgba(224, 224, 224, 0.5)', padding: '15px', borderRadius: '10px', height:'100px', justifyContent: 'center',alignItems: 'center', display: 'flex' }} >
                  <Div>{"Pedidos por cancelar no encontradas"}</Div>
               </Box>
            )
         }
         
         {
            loadData && (
               <Box sx={{backgroundColor: 'rgba(224, 224, 224, 0.5)', padding: '15px', borderRadius: '10px', height:'100px'}}>
               </Box>
            )
         }
         
         {/* {
            dataEmpresa?.id_tipo_empresa && dataEmpresa?.id_tipo_empresa === 2 &&
            <Titulo>{"Pedidos por cancelar:"}</Titulo>
         }
         {!loadData && pedidos.length !== 0 && dataEmpresa && dataEmpresa?.id_tipo_empresa && dataEmpresa?.id_tipo_empresa === 2 &&
            <Box sx={{backgroundColor: 'rgba(224, 224, 224, 0.5)', padding: '10px', borderRadius: '10px'}}>
               { mostrarPedidos('faltantes') }
            </Box>
         }
         {
            !loadData && pedidos.length === 0 && dataEmpresa?.id_tipo_empresa && dataEmpresa?.id_tipo_empresa === 2 && (
               <Box sx={{backgroundColor: 'rgba(224, 224, 224, 0.5)', padding: '15px', borderRadius: '10px', height:'100px', justifyContent: 'center',alignItems: 'center', display: 'flex' }} >
                  <Div>{"Pedidos por cancelar no encontradas"}</Div>
               </Box>
            )
         }
         {loadData && dataEmpresa?.id_tipo_empresa && dataEmpresa?.id_tipo_empresa === 2 &&
            <Box sx={{backgroundColor: 'rgba(224, 224, 224, 0.5)', padding: '15px', borderRadius: '10px', height:'100px'}}>
            </Box>
         } */}
            
         { openPopup &&
               <PopupComponent
               largo="md"
               title = {detailMode ? (pagarProducts ? 'Pagar productos seleccionados' :'Detalle del Pedido') : 'Pagar el pedido'}
               openPopup = {openPopup}
               setOpenPopup = {setOpenPopup}
               >
               <PagarPedido idPedido={pedidoSelected} setOpenPopup={setOpenPopup} loadDataList={cargarPedidos} selected={selected} detailMode={detailMode} anularMode={anularMode} cupones={cupones} pagarProducts={pagarProducts} onList={cargarPedidos} clientePedido={clientePedido}/>
            </PopupComponent>
         }
         {
            showPDF.open &&
            <SalePDF venta={2} folio={'showPDF.folio'} timbre={'showPDF.timbre'} montoNeto={'showPDF.montoNeto'} setShow={setShowPDF}  setOpenPopup={setOpenPopup}/>
         }

            {dataImprimir &&
               <div style={{ display: "none" }}>
                  <div ref={componentRef}>
                     <ComponentPrint
                        data={dataImprimir}
                     />
                  </div>
               </div >
            }
      </Box>
      </>
   )
}
