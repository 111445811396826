import { Props } from "./ModalCocina.type";
import { productoService } from "@/service/services/Producto.service";
import { Autocomplete, Checkbox, Divider, FormControlLabel, FormLabel, Grid, MenuItem, Paper, Radio, RadioGroup, Select, Table, TableBody, TableCell, TableContainer, TableFooter, TableHead, TablePagination, TableRow, TextField, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { KEY_EMPRESA } from '@constants/local-storage';
import { useLocalStorage } from "@/toolbox/hooks/local-storage.hook";
import { Loading } from "@/components/common/Loading";
import { Snackbar, Alert } from '@mui/material';
import { SelectChangeEvent } from '@mui/material/Select';
import { cocinaService } from "@/service/services/Cocina.service";
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank'
import CheckBoxIcon from '@mui/icons-material/CheckBox'

export const ModalCocina: React.FC<Props> = (props: Props): JSX.Element => {

    const { handleLoad, closeModal, cocinaSelected } = props;

    const [loading, setLoading] = React.useState<boolean>(false);
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    const [products, setProducts] = React.useState<Array<any>>([]);
    const [filter, setFilter] = React.useState<Array<any>>([]);
    const [textSearch, setTextSearch] = React.useState<string>("");
    const [nameCocina, setNameCocina] = React.useState<string>("");
    const [ProductsSelect, setProductsSelect] = React.useState([]);
    const [selectedFamilies, setSelectedFamilies] = React.useState<any[]>([]);
    const [familias, setFamilias] = React.useState<any[]>([]);
    const [tipo, setTipo] = useState<number>(1)
    const [dataEmpresa] = useLocalStorage(KEY_EMPRESA, {
        id: 0, id_tipo_empresa: 0, equivalencia_puntos: 0,
        id_country: 0, country_name: "", country_abbreviation: ""
    });

    const [snackBarConfig, setSnackBarConfig] = React.useState<any>({
        open: false,
        severity: 'error',
        message: 'Error',
        autoHideDuration: 3000,
    });

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };
    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    useEffect(() => {
        if (textSearch) {
            let copyFilters = []
            copyFilters = products.filter(producto => (producto?.nombre.toLowerCase().includes(textSearch.toLowerCase()) || producto?.codigo_erp.toLowerCase().includes(textSearch.toLowerCase())))
            setFilter([...copyFilters])
        }
        // eslint-disable-next-line
    }, [textSearch])

    useEffect(() => {
        if (cocinaSelected) {
            getCocinaById(cocinaSelected.id)
            setTipo(parseInt(cocinaSelected.tipo))
        }
        getFamiliasApi()
    }, [cocinaSelected]);

    useEffect(() => {
        if (cocinaSelected && !!familias.length) {
            const idsFamilias = JSON.parse(cocinaSelected?.familias) || []
            const selecteds = familias.filter(familia => idsFamilias.includes(familia.id))
            setSelectedFamilies(selecteds || []);
        }
    }, [cocinaSelected, familias])


    const getFamiliasApi = async () => {
        const response = await productoService.getFamiliesProducts()
        setFamilias(response?.families || [])
    }

    const getCocinaById = async (idCocina) => {
        setLoading(true);
        await cocinaService.getCocinaById(idCocina).then((data) => {
            if (data.status === 200) {
                setProductsSelect(data.productos);
                setNameCocina(data.cocina.name);
            }
            setLoading(false);
        })
    }

    const getProductos = async () => {
        setLoading(true);
        await productoService.getProductosNokitchen().then((data) => {
            if (data.status === 200) {
                setProducts(data.productos);
                setFilter(data.productos);
            }
            setLoading(false);
        })
    }

    const handleKeyDown = (event) => {
        let texto = `${event.target.value}`;
        setTextSearch(texto);
        if (!texto) {
            setFilter([...products])
        }
    }

    const handleSelectProduct = (product) => {
        let card: Array<any> = ProductsSelect;
        let exits: boolean = false;
        for (let i = 0; i < card.length; i++) {
            if (card[i].codigo_erp === product?.codigo_erp && card[i].nombre === product?.nombre) {
                exits = true
            }
        }
        if (!exits) {
            card.push({
                ...product,
            })
        }
        setProductsSelect([...card]);
    }

    const handleDeleteProduct = (index: number) => {
        let card: Array<any> = ProductsSelect;
        card.splice(index, 1);
        setProductsSelect([...card]);
    }

    const onSubmit = async () => {
        let arrayIds = [];
        setLoading(true);

        if (nameCocina == '') {
            setLoading(false);
            return setSnackBarConfig(prev => ({
                ...prev,
                open: true,
                severity: 'warning',
                message: `El nombre de la cocina es requerido.`,
            }));
        }
        // if (ProductsSelect.length > 0) {

        // ProductsSelect.forEach(e => {
        //     arrayIds.push(e.id);
        // });

        // if (arrayIds.length > 0) {
        let data = {
            name: nameCocina,
            status: 1,
            id_empresa: dataEmpresa.id,
            arrayIds: arrayIds,
            familias: selectedFamilies.map(familia => familia.id),
            tipo: tipo
        }

        if (cocinaSelected) {
            await cocinaService.update(data, cocinaSelected.id).then((data) => {
                if (data.status === 400) {
                    return setSnackBarConfig(prev => ({
                        ...prev,
                        open: true,
                        message: `${data.error}`,
                    }));
                } else {
                    closeModal && closeModal();
                    handleLoad && handleLoad();
                }
            });
        } else {
            await cocinaService.register(data).then((data) => {
                if (data.status === 400) {
                    return setSnackBarConfig(prev => ({
                        ...prev,
                        open: true,
                        message: `${data.error}`,
                    }));
                } else {
                    closeModal && closeModal();
                    handleLoad && handleLoad();
                }
            });
        }
        // }
        setLoading(false);

        // } else {
        //     setLoading(false);
        //     return setSnackBarConfig(prev => ({
        //         ...prev,
        //         open: true,
        //         severity: 'warning',
        //         message: `Debe seleccionar al menos un producto.`,
        //     }));
        // }
    }

    return (
        <>
            <div className="container">
                {loading && <Loading />}
                <Snackbar
                    open={snackBarConfig.open}
                    autoHideDuration={snackBarConfig.autoHideDuration}
                    onClose={() => setSnackBarConfig(prev => ({ ...prev, open: false }))}
                    anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                >
                    <Alert
                        onClose={() => setSnackBarConfig(prev => ({ ...prev, open: false }))}
                        severity={snackBarConfig.severity}
                        variant="filled"
                    >
                        {snackBarConfig.message}
                    </Alert>
                </Snackbar>

                <Grid item container xs={12} spacing={2}>
                    <Grid item xs={12}>
                        <FormLabel>Nombre:</FormLabel>
                        <TextField
                            id="codigo_erp"
                            type="text"
                            name="codigo_erp"
                            size='small'
                            fullWidth
                            value={nameCocina}
                            onChange={(e) => { setNameCocina(e.target.value) }}
                            placeholder="escribe el nombre"
                        />
                    </Grid>

                    <Divider sx={{ width: '100%', mb: 2, mt: 2 }} />

                    {/* <Grid item sm={6}  sx={{minHeight:'53vh', maxHeight:'53vh', overflowY:'scroll'}}>
                    <Grid>
                        <div className="H-card__fcontainer">
                            <div className="H-card__fcontainer__filter">
                                <span>Filtro:</span>
                                <TextField
                                    type="text"
                                    id="mi-codigo-de-barras"
                                    placeholder="Buscar producto..."
                                    onChange={handleKeyDown}
                                    className="H-card__fcontainer__filter__input"
                                    style={{ backgroundColor: "white", width: "290px", marginLeft: "10px" }}
                                />
                            </div>
                        </div>
                    </Grid>
                    <Grid item>
                        <TableContainer component={Paper}>
                            <Table sx={{ minWidth: 300}} aria-label="simple table">
                                <TableHead>
                                    <TableRow>
                                        <TableCell>Código</TableCell>
                                        <TableCell>Nombre</TableCell>
                                    </TableRow>
                                </TableHead>

                                <TableBody>
                                {
                                    filter && filter.map((product, index) => (
                                        <TableRow
                                            key={product.id}
                                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                            classes={{
                                                hover: 'hover-row-t'
                                            }}
                                        >
                                            <TableCell component="th" scope="row">
                                                <span className="tabla">{product.codigo_erp}</span>
                                            </TableCell>
                                            <TableCell component="th" scope="row">
                                                <button className="H-card__tableButton" onClick={()=>handleSelectProduct(product)}>
                                                    <span className="tabla">
                                                        {product.nombre.charAt(0).toUpperCase() + product.nombre.slice(1).toLowerCase()}
                                                    </span>
                                                </button>
                                            </TableCell>
                                        </TableRow>
                                    ))
                                }
                                </TableBody>

                                <TableFooter>
                                    <TableRow>
                                        <TablePagination
                                            count= {filter.length}
                                            rowsPerPage={rowsPerPage}
                                            page={page}
                                            onPageChange={handleChangePage}
                                            onRowsPerPageChange={handleChangeRowsPerPage}
                                            rowsPerPageOptions={[10, 15, 20]}
                                            labelRowsPerPage={<span className="tabla">Filas:</span>}
                                            labelDisplayedRows={({ page }) => {
                                            return `Página: ${page+1}`;
                                            }}
                                            SelectProps={{
                                                inputProps: {
                                                "aria-label": "page number"
                                                }
                                            }}
                                            showFirstButton={true}
                                            showLastButton={true}
                                        />
                                    </TableRow>
                                </TableFooter>
                            </Table>
                        </TableContainer>
                    </Grid>
                </Grid> */}

                    {/* <Grid item sm={6} container direction='row'>
                    <Grid xs={12} sx={{minHeight:'53vh', maxHeight:'53vh', overflowY:'scroll', padding: '10px'}}>
                    <div className="H-card__title" style={{paddingLeft: '10px', paddingTop:'10px'}}>
                        {`Productos que se preparan en esta cocina`}
                    </div>
                    <div className="H-card__separator"></div>
                    <div className="H-card__list">
                        {
                            ProductsSelect && ProductsSelect.map((product, index) => (
                                <div className="H-card__list__item" key={index}>
                                    <div className="content-info">
                                        <div className="info">
                                            <span className="name">
                                                {product.nombre.charAt(0).toUpperCase() + product.nombre.slice(1).toLowerCase()}
                                            </span>
                                        </div>
                                    </div>
                                    <button className="dissmiss-more delete" onClick={()=>handleDeleteProduct(index)}>x</button>
                                </div>
                            ))
                        }
                    </div>
                    </Grid>
                </Grid> */}
                    <Grid item xs={12} md={12}>
                        <FormLabel>Familias a asignar:</FormLabel>
                        <Autocomplete
                            multiple={true}
                            disablePortal
                            id="combo-box-"
                            value={selectedFamilies || []}
                            options={familias || []}
                            getOptionLabel={(option) => option?.nombre || ""}
                            fullWidth
                            isOptionEqualToValue={(option, value) => option?.nombre === value?.nombre}
                            renderInput={
                                (params) => <TextField {...params} size="small" />
                            }
                            onChange={(e, newValue) => {
                                setSelectedFamilies(newValue);
                            }}
                            renderOption={(props, option, { selected }) => (
                                <li key={option.id} value={option.id} {...props} >
                                    <Checkbox
                                        icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                                        checkedIcon={<CheckBoxIcon fontSize="small" />}
                                        checked={selected}
                                    />
                                    {option.nombre}
                                </li>
                            )}
                            filterSelectedOptions
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <FormLabel>Tipo:</FormLabel>
                        <RadioGroup
                            aria-labelledby="demo-radio-buttons-group-label"
                            defaultValue="female"
                            name="radio-buttons-group"
                            row
                        >
                            <FormControlLabel control={<Radio />} label="Cocina" value={1} checked={tipo === 1} onChange={() => setTipo(1)} />
                            <FormControlLabel control={<Radio />} label="Barra" value={2} checked={tipo === 2} onChange={() => setTipo(2)} />
                        </RadioGroup>
                    </Grid>
                </Grid>
            </div>
            <Divider sx={{ my: 2 }} />
            <div className="form-group mb-3 buton_container_modal">
                <button className="btn btn-primary" onClick={onSubmit}>
                    Guardar Cocina
                </button>
            </div>
        </>
    );
}