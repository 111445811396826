import { Divider, Grid, MenuItem, Select, TextField, Typography } from "@mui/material";
import { Props } from "./ModalDescuentoCompra.type";

export const ModalDescuentoCompra: React.FC<any> = (props:Props): JSX.Element => {
    const {setTipoDescuento, tipoDescuento, setValorDescuento, valorDescuento, closeModal, indexCart, saveDescuento} = props;

    return (
        <div className="container">
            <Grid sx={{width: '100%'}} spacing={3}>
            <Grid item xs={12} container style={{display: "flex", alignItems: "center", marginBottom:'5px'}}>
                <Grid item xs={4}>
                    <Typography variant="subtitle1" className="custom-input"><b>Tipo Descuento:</b></Typography>
                </Grid>
                <Grid item xs={8} style={{display: 'flex', alignItems: "center"}}>
                    <Select
                        id="filtro"
                        value={tipoDescuento}
                        onChange={(event) => {
                            setTipoDescuento(event.target.value);
                        }}
                        fullWidth
                        size="small"
                        >
                        <MenuItem value={1} onClick={() => {}}>
                                Dinero ($)
                        </MenuItem>
                        <MenuItem value={2} onClick={() => {}}>
                                Porcentaje (%)
                        </MenuItem>
                    </Select>
                </Grid>
            </Grid>
            <Grid item xs={12} container style={{display: "flex", alignItems: "center"}}>
                <Grid item xs={4}>
                    <Typography variant="subtitle1" className="custom-input"><b>Tipo Descuento:</b></Typography>
                </Grid>
                <Grid item xs={8} style={{display: 'flex', alignItems: "center"}}>
                    <TextField
                        id="descuento"
                        type="text"
                        name="descuento"
                        size="small"
                        fullWidth
                        value={valorDescuento}
                        onChange={
                            (e) => {setValorDescuento(e.target.value)}
                        }
                        placeholder="Valor descuento"
                    />
                </Grid>
            </Grid>
            <Divider sx={{ my: 2 }}/>
            {
                <div className="form-group mb-3 buton_container_modal">
                    <button className="btn btn-primary" onClick={()=> {
                        saveDescuento(tipoDescuento, valorDescuento, indexCart);
                        setValorDescuento(0);
                        setTipoDescuento(1);
                        closeModal();
                        }}>
                        Guardar Descuento
                    </button>
                </div>
            }
            </Grid>
        </div>
    );
}
