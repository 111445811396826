
import { connect } from "react-redux";
import { bindActionCreators, compose } from "redux";
import { withRouter } from "react-router-dom";
import { actionSetEcommerce } from '@/redux/actions';
import { ProductsEcommerce as productsclient } from './ProductsEcommerce';

const mapStateToProps = ({ ecommerceReducer }) => ({
    $store: {
      ecommerceReducer,
    },
 });
 const mapDispatchToProps = (dispatch) => ({
    $action: bindActionCreators(
       {
        actionSetEcommerce: actionSetEcommerce
       },
       dispatch
    ),
 });
 
 
 export const ProductsEcommerce: any = compose(
    withRouter,
    connect(mapStateToProps, mapDispatchToProps)
 )(productsclient);