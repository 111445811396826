import { TipoImpuestoRepository } from "../repositories/TipoImpuesto.repository";

export const TipoImpuestoService = {
    getTiposImpuesto
}

async function getTiposImpuesto(){
    const res = await TipoImpuestoRepository.get()
    return res
}
