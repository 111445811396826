import React, { useMemo, useRef, useState } from "react";
import { Button, InputAdornment, IconButton, Grid, useTheme, Snackbar, Alert, Typography, useMediaQuery } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';

import { VALIDATORS } from '@toolbox/helpers/validation-rules';
import { Icon } from '@components/common/Icon';
import { Input } from '@components/common/Input';
import { InputRef } from '@components/common/Input/InputInterfaces';
import CssBaseline from '@mui/material/CssBaseline';
import Paper from '@mui/material/Paper';
import { ThemeProvider } from '@mui/material/styles';
import { VisibilityIcon, VisibilityOffIcon } from '@toolbox/constants/icons';
import { authenticationService } from '@service/services/Authentication.service';
import * as Routes from '@constants/route-map';
//import "./Login.sass";
//import "./login-styles.css"


//const theme = createTheme();
export const Login: React.FC<any> = (props): JSX.Element => {
   const inputRefs = useRef<Array<InputRef | null>>([]);
   const [loading, setLoading] = useState<boolean>(false);
   const [data, setData] = useState({
      username: '',
      rut: '',
      password: '',
      textError: '',
      showPassword: false
   });

   const [snackBarConfig, setSnackBarConfig] = useState<any>({
      open: false,
      severity: 'error',
      message: 'Error',
      autoHideDuration: 3000,
   })

   // const { rut, usuario, password } = props.location && qs.parse(props.location.search.slice(1, -1));
   // useEffect(() => {
   //    if (rut && usuario && password) {
   //       var decodeRutEmpresa;
   //       var decodeUser;
   //       var decodePassword;

   //       try {
   //          decodeRutEmpresa = atob(rut);
   //          decodeUser = atob(usuario);
   //          decodePassword = btoa(password);
   //          authSubmit(decodeUser, decodeRutEmpresa, decodePassword);
   //       } catch (error) {
   //       }
   //    } else {
   //    }
   // }, []);

   const rules = useMemo(() => ({
      username: [
         VALIDATORS.REQUIRED
      ],
      password: [
         VALIDATORS.REQUIRED,
      ],
      rut: [
         VALIDATORS.REQUIRED,
         VALIDATORS.ONLY_LOWERCASE,
      ]
   }), []);

   const validate = () => {
      const refs = inputRefs.current;
      const valid = refs.reduce((prev, ref) => {
         const isValid = ref ? ref.validate(true) : false
         return prev && isValid;
      }, true);

      return valid;
   }

   //obtencion del dominio de acuerdo al pais ('cl', 'pe', 'col')
   // eslint-disable-next-line
   const location = window.location.host.split(".")[2];

   const handleInput = (event: React.ChangeEvent<HTMLInputElement>) => {
      const name = event.target.name;
      const value = event.target.value;

      const formatsRut = {
         rutChile: /^[0-9]{8,9}[-|‐]{1}[0-9kK]{1}$/,
         rutPeru: /^\d{11}$/,
         rutColombia: /^[0-9]{9}[-|‐]{1}[0-9]{1}$/,
      }

      switch (name) {
         case 'username':
            setData(prev => ({ ...prev, username: value, textError: '' }));
            break;

         case 'password':
            setData(prev => ({ ...prev, password: value, textError: '' }));
            break;

         case 'rut':

            /* if(location === 'cl'){
               var Fn = {
                  // Valida el rut con su cadena completa "XXXXXXXX-X"
                  validaRut : function (rutCompleto) {
                     if (!formatsRut.rutChile.test( rutCompleto )) return false;

                     var tmp 	= rutCompleto.split('-');
                     var digv	= tmp[1];
                     var rut 	= tmp[0];
                     if ( digv === 'K' ) digv = 'k' ;
                     return (Fn.dv(rut) === digv );
                  },

                  dv : function(T){
                     var M=0,S=1;
                     for(;T;T=Math.floor(T/10))
                        S=(S+T%10*(9-M++%6))%11;
                     return S?S-1:'k';
                  }
               }

               var foo =value.split("-").join("")
               if(foo.length >0 && foo.length<10){
                  foo= foo.match(new RegExp('.{1,8}', 'g')).join("-");
                  setData(prev => ({ ...prev, rut: foo, textError: '' }))
               }else if(foo.length === 0){
                  setData(prev => ({ ...prev, rut: '', textError: '' }))
               }
            }
            if(location === 'pe'){
               var rutCompleto = value;
               if(rutCompleto.length >= 0 && rutCompleto.length < 12){
                  setData(prev => ({ ...prev, rut: rutCompleto, textError: '' }))
               }
            }
            if(location === 'col'){
               var rutCompleto = value;
               if(rutCompleto.length >= 0 && rutCompleto.length < 11){
                  setData(prev => ({ ...prev, rut: rutCompleto, textError: '' }))
               }
            } */

            var Fn = {
               // Valida el rut con su cadena completa "XXXXXXXX-X"
               validaRut: function (rutCompleto) {
                  if (!formatsRut.rutChile.test(rutCompleto)) return false;

                  var tmp = rutCompleto.split('-');
                  var digv = tmp[1];
                  var rut = tmp[0];
                  if (digv === 'K') digv = 'k';
                  return (Fn.dv(rut) === digv);
               },


               dv: function (T) {
                  var M = 0, S = 1;
                  for (; T; T = Math.floor(T / 10))
                     S = (S + T % 10 * (9 - M++ % 6)) % 11;
                  return S ? S - 1 : 'k';
               }
            }

            var foo = value.split("-").join("")
            if (foo.length > 0 && foo.length < 10) {
               foo = foo.match(new RegExp('.{1,8}', 'g')).join("-");
               setData(prev => ({ ...prev, rut: foo, textError: '' }))
            } else if (foo.length === 0) {
               setData(prev => ({ ...prev, rut: '', textError: '' }))
            }

            break;

         default:
            break;
      }
   };

   const handleInputBlur = (event: any) => {
      const newVal = (event.target.value).trim();
      const name = event.target.name;
      setData(prev => ({ ...prev, [name]: newVal }));
   }

   const handleSubmit = (e) => {
      e.preventDefault();
      const { username, rut, password } = data;
      try {
         if (validate()) {
            authSubmit(username, rut, password);
         }
      } catch (error) {
         setData(prev => ({ ...prev, textError: 'Lo sentimos, ocurrió un error inesperado.' }));
      } finally {
         // setLoading(false);
      }
   }

   const authSubmit = async (username, rut, password) => {
      setLoading(true)
      const response = await authenticationService.loginShop(username, rut, password);
      if (!response.data || !response.data.token) {
         setSnackBarConfig(prev => ({
            ...prev,
            open: true,
            message: "Acceso no autorizado",
         }));
      } else {
         if (response?.user?.main_redirect === Routes.ROUTE_HOME) {
            window.location.reload();
         } else {
            window.location.replace(response.user.main_redirect);
         }
      }
      setLoading(false)
   }

   // eslint-disable-next-line
   const iniciarSesion = async (username, rut, password) => {
      const response = await authenticationService.loginShop(username, rut, password);

      if (response.token === '') {

         setSnackBarConfig(prev => ({
            ...prev,
            open: true,
            message: "Acceso no autorizado",
         }));
         setLoading(false);
      } else {
         if (response?.user?.main_redirect === Routes.ROUTE_HOME) {
            window.location.reload();
         } else {
            window.location.replace(response.user.main_redirect);
         }
      }
   }

   const handleClickShowPassword = () => {
      setData({ ...data, showPassword: !data.showPassword });
   };

   const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
      event.preventDefault();
   };

   const theme = useTheme();
   const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

   const formulario = (
      <form >
         <div className="up-desing"></div>
         <div style={{ padding: "20px" }}>
            <Grid container spacing={2} >
               <Grid item xs={12}>
                  <Input
                     ref={ref => inputRefs.current[0] = ref}
                     name="username"
                     placeholder="Ingrese su nombre de Usuario"
                     value={data.username}
                     onChange={handleInput}
                     onBlur={handleInputBlur}
                     backgroundColor="#ffffff"
                     rules={rules.username}
                     disableElevation
                     validateOnBlur
                     dense
                  />

               </Grid>

               <Grid item xs={12}>
                  <Input
                     ref={ref => inputRefs.current[1] = ref}
                     name="rut"
                     type="text"
                     placeholder="Rut Empresa"
                     value={data.rut}
                     onChange={handleInput}
                     onBlur={handleInputBlur}
                     backgroundColor="#ffffff"
                     rules={rules.rut}
                     disableElevation
                     validateOnBlur
                     dense
                  />
               </Grid>

               <Grid item xs={12}>
                  <Input
                     ref={ref => inputRefs.current[2] = ref}
                     name="password"
                     type={data.showPassword ? 'text' : 'password'}
                     placeholder="Ingrese la contraseña"
                     value={data.password}
                     onChange={handleInput}
                     onBlur={handleInputBlur}
                     backgroundColor="#ffffff"
                     rules={rules.password}
                     appendAdornment={
                        <InputAdornment position="end">
                           <IconButton
                              aria-label="toggle password visibility"
                              onClick={handleClickShowPassword}
                              onMouseDown={handleMouseDownPassword}
                           >
                              {data.showPassword ? <Icon Svg={VisibilityIcon} /> : <Icon Svg={VisibilityOffIcon} />}
                           </IconButton>
                        </InputAdornment>
                     }
                     disableElevation
                     validateOnBlur
                     dense
                  />
               </Grid>

               <Grid item xs={12}>
                  <Button
                     className="btn btn-primary"
                     fullWidth={true}
                     type='submit'
                     variant='contained'
                     onClick={handleSubmit}
                     disabled={loading}
                     disableElevation
                  // sx={{borderRadius:'15px'}}
                  >
                     {
                        loading ?
                           <CircularProgress size={24} style={{ color: "#FFFF" }} /> :
                           <span>Iniciar sesión</span>
                     }
                  </Button>
               </Grid>

            </Grid>
         </div>
      </form>
   )

   return (
      <>

         <div className="d-flex flex-column flex-root" id="kt_app_root">
            <div className="d-flex flex-column flex-lg-row flex-column-fluid">
               <div className="d-flex flex-column flex-lg-row-fluid w-lg-50 p-10 order-2 order-lg-1">
                  <div className="d-flex flex-center flex-column flex-lg-row-fluid">
                     <div className="w-lg-500px p-10">

                        <div className="text-center mb-11">
                           <h1 className="text-gray-900 fw-bolder mb-3">Sign In</h1>
                           <div className="text-gray-500 fw-semibold fs-6">Your Social Campaigns</div>
                        </div>
                        <div className="row g-3 mb-9">
                           <div className="col-md-6">
                              <a href="#" className="btn btn-flex btn-outline btn-text-gray-700 btn-active-color-primary bg-state-light flex-center text-nowrap w-100">
                                 <img alt="Logo" src="https://turismoencasasyfincas.com/assets/media/svg/brand-logos/google-icon.svg" className="h-15px me-3" />Sign in with Google</a>
                           </div>
                           <div className="col-md-6">
                              <a href="#" className="btn btn-flex btn-outline btn-text-gray-700 btn-active-color-primary bg-state-light flex-center text-nowrap w-100">
                                 <img alt="Logo" src="https://turismoencasasyfincas.com/assets/media/svg/brand-logos/apple-black.svg" className="theme-light-show h-15px me-3" />
                                 <img alt="Logo" src="https://turismoencasasyfincas.com/assets/media/svg/brand-logos/apple-black-dark.svg" className="theme-dark-show h-15px me-3" />Sign in with Apple</a>
                           </div>
                        </div>
                        <div className="separator separator-content my-14">
                           <span className="w-125px text-gray-500 fw-semibold fs-7">o Usuario y constraseña</span>
                        </div>





                        <div className="text-gray-500 text-center fw-semibold fs-6">Not a Member yet?
                           <a href="authentication/layouts/corporate/sign-up.html" className="link-primary">Sign up</a></div>

                        {formulario}

                        <div className="d-flex flex-stack flex-wrap gap-3 fs-base fw-semibold mb-8">
                           <div></div>
                           <a href="authentication/layouts/corporate/reset-password.html" className="link-primary">Forgot Password ?</a>
                        </div>
                     </div>
                  </div>
               </div>
               <div className="d-flex flex-lg-row-fluid w-lg-50 bgi-size-cover bgi-position-center order-1 order-lg-2" style={{ backgroundImage: 'url("https://turismoencasasyfincas.com/assets/media/misc/auth-bg.png")' }}>
                  <div className="d-flex flex-column flex-center py-7 py-lg-15 px-5 px-md-15 w-100">
                     <a href="index.html" className="mb-0 mb-lg-12">
                        <h2 className="d-none d-lg-block text-white fs-2qx fw-bolder text-center mb-7">Nupy</h2>
                     </a>
                     <img className="d-none d-lg-block mx-auto w-275px w-md-50 w-xl-500px mb-10 mb-lg-20" src="https://turismoencasasyfincas.com/assets/media/misc/auth-screens.png" alt="" />
                     <h2 className="d-none d-lg-block text-white fs-2qx fw-bolder text-center mb-7">Hecho para gestionar tu punto de venta de manera fácil y rápida</h2>
                     <div className="d-none d-lg-block text-white fs-base text-center">In this kind of post,
                        <a href="#" className="opacity-75-hover text-warning fw-bold me-1">the blogger</a>introduces a person they’ve interviewed
                        <br />and provides some background information about
                        <a href="#" className="opacity-75-hover text-warning fw-bold me-1">the interviewee</a>and their
                        <br />work following this is a transcript of the interview.</div>
                  </div>
               </div>
            </div>
         </div>

         {snackBarConfig.open &&
            <Snackbar
               open={snackBarConfig.open}
               autoHideDuration={snackBarConfig.autoHideDuration}
               onClose={() => setSnackBarConfig(prev => ({ ...prev, open: false }))}
               anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
            >
               <Alert
                  onClose={() => setSnackBarConfig(prev => ({ ...prev, open: false }))}
                  severity={snackBarConfig.severity}
                  variant="filled"
               >
                  {snackBarConfig.message}
               </Alert>
            </Snackbar>
         }
      </>
   )
}

