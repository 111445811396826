import React, { useEffect } from "react";
import { Loading } from "../../components/common/Loading/Loading";
import { Protected } from "@/components/layout/Protected";
import { Box, Button, Grid, Typography } from "@mui/material";
import { Popup } from "../Editor/components/Popup";
import { Props } from "./Beneficios.type";
import { styled } from '@mui/material/styles';
import { BeneficioProducto } from "./componentes/BeneficioProducto";
import { BeneficioMonto } from "./componentes/BeneficioMonto";
import { beneficioProductoService } from "@/service/services/BeneficioProducto.service";
import { beneficioMontoService } from "@/service/services/BeneficioMonto.service";
import { AddModal } from "./componentes/ModalAdd";
import { KEY_BENEFICIO_PRO, KEY_BENEFICIO_MON } from '@constants/local-storage';
import { saveLocalStorage } from '@helpers/local-storage-helper';
import { empresasService } from "@/service/services/Empresas.service";
import { EditEquivalencia } from "./componentes/EditEquivalencia";
import {KEY_EMPRESA} from '@constants/local-storage';
import {readLocalStorage} from '@helpers/local-storage-helper';
import { AddModalMonto } from "./componentes/ModalAddMonto";
import { CuponModal } from "./componentes/Cupones/componentes/CuponModal";
import { CuponesService } from "@/service/services/Cupones.service";
import { Cupones } from "./componentes/Cupones";
import { BeneficioPuntos } from "./componentes/BeneficioPuntos";
import { BeneficioPuntosService } from "@/service/services/BeneficioPuntos.service";
import { PuntosModal } from "./componentes/BeneficioPuntos/PuntosModal";
import { ID_TYPE_RESTAURANTE } from "@/toolbox/constants/type-entity";

const Titulo = styled('div')(({ theme }) => ({
    ...theme.typography.button,
    padding: theme.spacing(1),
    fontWeight: 'bold',
    fontSize: '20px',
    color: 'black',
}));
const Div = styled('div')(({ theme }) => ({
    ...theme.typography.button,
    padding: theme.spacing(1),
    fontWeight: 'bold',
    fontSize: '20px',
    color: 'rgba(102, 102, 102, 0.4)',
  }));

export const Beneficios: React.FC<Props> = (props: Props):JSX.Element => {

    const [loading, setLoading] = React.useState<boolean>(false);
    const [beneficiosProducto, setBeneficiosProducto] = React.useState([]);
    const [beneficiosMonto, setBeneficiosMonto] = React.useState([]);
    const [cupones, setCupones] = React.useState([]);
    const [beneficioPuntos, setBeneficioPuntos] = React.useState([]);
    const [equivalenciaEmpresa, setEquivalenciaEmpresa] = React.useState({});

    const [openPopup, setOpenPopup] = React.useState<boolean>(false);
    const [popUp, setPopUp] = React.useState<String>('');

    const SessionEmpresa: any = readLocalStorage(KEY_EMPRESA);
    const Monedapais = SessionEmpresa.country_abbreviation;

    useEffect(() => {
        getBeneficioProducto();
        getBeneficioMonto();
        getCupones();
        getBeneficioPuntos();
        getEquivalenciaCompany();
    }, [])

    const getEquivalenciaCompany = async () => {
        setLoading(true);
        await empresasService.getEquivalencia().then((data) => {
            setEquivalenciaEmpresa(data);
            setLoading(false);
        })
    }

    const getBeneficioProducto = async() => {
        setLoading(true);
        await beneficioProductoService.getBeneficioProducto().then((data) =>{
            setBeneficiosProducto(data.puntosProducto);
            saveLocalStorage(KEY_BENEFICIO_PRO, (data.puntosProducto||[]));
            setLoading(false);
        })
    }
    const getBeneficioMonto = async() => {
        setLoading(true);
        await beneficioMontoService.getBeneficioMonto().then((data) =>{
            setBeneficiosMonto(data.puntosMonto);
            saveLocalStorage(KEY_BENEFICIO_MON, (data.puntosMonto||[]));
            setLoading(false);
        })
    }

    const getCupones = async() => {
        setLoading(true);
        await CuponesService.getCupones().then((data) =>{
            setCupones(data.cupones);
            setLoading(false);
        })
    }

    const getBeneficioPuntos = async() => {
        setLoading(true);
        await BeneficioPuntosService.getBeneficioPuntos().then((data) =>{
            setBeneficioPuntos(data.beneficios);
            setLoading(false);
        })
    }

    return (
        <Protected>
            {loading && <Loading/>}
            <Grid container maxWidth='xl' sx={{ mt: 1, mb: 2}}>
                <Grid container item xs={12} style={{alignItems:"center", justifyContent:"space-between"}}>
                    <Grid container xs={12} sx={{marginBottom: '10px', paddingRight: '10px'}}>
                        <Grid container justifyContent='space-between' spacing={2}>
                            <Grid item>
                                <Typography variant='h4' >Beneficios</Typography>
                            </Grid>
                        </Grid>
                        <Grid xs={12} container spacing={0} alignItems="center" justifyContent="space-between">
                            <Grid item xs={10}>
                                <Typography variant="body1" sx={{ mt: '20px' }} >
                                    {
                                        !loading && equivalenciaEmpresa > 0  ?
                                        `Equivalencia de puntos: 1 punto = ${equivalenciaEmpresa} ${Monedapais}` :
                                        `Equivalencia de puntos: 1 punto = 0 ${Monedapais}`
                                    }
                                </Typography>
                            </Grid>
                            <Grid item xs={2} >
                                <Button variant="contained" onClick={()=>{setOpenPopup(true); setPopUp('editEquivalencia');}} sx={{mt:'20px',bgcolor:'#229954', color:'white'}} style={{textTransform: "capitalize", width:"100%"}} >
                                    Editar
                                </Button>
                            </Grid>
                        </Grid>
                        <Grid container spacing={0} alignItems="center" justifyContent="center" style={{ marginBottom: '20px', marginTop:'30px'}}>
                            <Grid item xs={10}>
                                <Titulo>{"Beneficios por producto"}</Titulo>
                            </Grid>
                            <Grid item xs={2}>
                                <Button variant='outlined' onClick={()=>{setOpenPopup(true); setPopUp('addBeneficioProducto');}} style={{textTransform: "capitalize"}}>
                                    Agregar
                                </Button>
                            </Grid>
                        </Grid>
                        {
                            !loading && beneficiosProducto.length > 0 && (
                                <>
                                <Box sx={{backgroundColor: 'rgba(224, 224, 224, 0.5)', padding: '15px', borderRadius: '10px', width: '100%'}}>
                                    <BeneficioProducto beneficiosProducto={beneficiosProducto} onListBeneficioProducto={getBeneficioProducto}/>
                                </Box>
                                </>
                            )
                        }
                        {
                            !loading && beneficiosProducto.length === 0 && (
                                <Box sx={{backgroundColor: 'rgba(224, 224, 224, 0.5)', padding: '15px', borderRadius: '10px', height:'100px', justifyContent: 'center',alignItems: 'center', display: 'flex', width: '100%'}} >
                                    <Div>{"Ofertas no encontradas"}</Div>
                                </Box>
                            )
                        }
                        {loading &&
                            <Box sx={{backgroundColor: 'rgba(224, 224, 224, 0.5)', padding: '15px', borderRadius: '10px', height:'100px', width: '100%'}}>
                            </Box>
                        }

                        <Grid container spacing={0} alignItems="center" justifyContent="center" style={{ marginBottom: '20px', marginTop:'30px'}}>
                            <Grid item xs={10}>
                                <Titulo>{"Beneficios por Monto"}</Titulo>
                            </Grid>
                            <Grid item xs={2}>
                                <Button variant='outlined' onClick={()=>{setOpenPopup(true); setPopUp('addBeneficioMonto');}} style={{textTransform: "capitalize"}}>
                                    Agregar
                                </Button>
                            </Grid>
                        </Grid>

                        {
                            !loading && beneficiosMonto.length > 0 && (
                                <>
                                <Box sx={{backgroundColor: 'rgba(224, 224, 224, 0.5)', padding: '15px', borderRadius: '10px', width: '100%' }}>
                                    <BeneficioMonto beneficiosMonto={beneficiosMonto} onListBeneficioMonto={getBeneficioMonto}/>
                                </Box>
                                </>
                            )
                        }
                        {
                            !loading && beneficiosMonto.length === 0 && (
                                <Box sx={{backgroundColor: 'rgba(224, 224, 224, 0.5)', padding: '15px', borderRadius: '10px', height:'100px', justifyContent: 'center',alignItems: 'center', display: 'flex', width: '100%' }} >
                                    <Div>{"Ofertas no encontradas"}</Div>
                                </Box>
                            )
                        }
                        {loading &&
                            <Box sx={{backgroundColor: 'rgba(224, 224, 224, 0.5)', padding: '15px', borderRadius: '10px', height:'100px', width: '100%'}}>
                            </Box>
                        }

                    </Grid>

                    {
                        SessionEmpresa?.id_tipo_empresa !== ID_TYPE_RESTAURANTE && (
                            <>
                            <Grid container xs={12} sx={{paddingLeft: '10px', paddingTop: "20px"}}>
                                <Grid justifyContent='space-between' spacing={2}>
                                    <Grid item>
                                        <Typography variant='h4' >Cupones</Typography>
                                    </Grid>
                                </Grid>
                                <Grid container spacing={0} alignItems="center" justifyContent="center" style={{ marginBottom: '20px', marginTop:'30px'}}>
                                    <Grid item xs={10}>
                                        <Titulo>{"Cupones"}</Titulo>
                                    </Grid>
                                    <Grid item xs={2}>
                                        <Button variant='outlined' onClick={()=>{setOpenPopup(true); setPopUp('addCupon');}} style={{textTransform: "capitalize"}}>
                                            Agregar
                                        </Button>
                                    </Grid>
                                </Grid>
                                {
                                    !loading && cupones.length > 0 && (
                                        <>
                                        <Box sx={{backgroundColor: 'rgba(224, 224, 224, 0.5)', padding: '15px', borderRadius: '10px', width: '100%'}}>
                                            <Cupones cupones={cupones} onListCupones={getCupones}/>
                                        </Box>
                                        </>
                                    )
                                }
                                {
                                    !loading && cupones.length === 0 && (
                                        <Box sx={{backgroundColor: 'rgba(224, 224, 224, 0.5)', padding: '15px', borderRadius: '10px', height:'100px', justifyContent: 'center',alignItems: 'center', display: 'flex', width: '100%'}} >
                                            <Div>{"No se encontraron Cupones"}</Div>
                                        </Box>
                                    )
                                }
                                {loading &&
                                    <Box sx={{backgroundColor: 'rgba(224, 224, 224, 0.5)', padding: '15px', borderRadius: '10px', height:'100px', width: '100%'}}>
                                    </Box>
                                }
                            </Grid>

                            <Grid container xs={12} sx={{paddingLeft: '10px', marginTop: '25px'}}>
                                <Grid justifyContent='space-between' spacing={2}>
                                    <Grid item>
                                        <Typography variant='h4' >Beneficio por Puntos</Typography>
                                    </Grid>
                                </Grid>
                                <Grid container spacing={0} alignItems="center" justifyContent="center" style={{ marginBottom: '20px', marginTop:'30px'}}>
                                    <Grid item xs={10}>
                                        <Titulo>{"Beneficio por Puntos"}</Titulo>
                                    </Grid>
                                    <Grid item xs={2}>
                                        <Button variant='outlined' onClick={()=>{setOpenPopup(true); setPopUp('addBeneficioPuntos');}} style={{textTransform: "capitalize"}}>
                                            Agregar
                                        </Button>
                                    </Grid>
                                </Grid>
                                {
                                    !loading && beneficioPuntos.length > 0 && (
                                        <>
                                        <Box sx={{backgroundColor: 'rgba(224, 224, 224, 0.5)', padding: '15px', borderRadius: '10px', width: '100%'}}>
                                            <BeneficioPuntos beneficios={beneficioPuntos} onListBeneficios={getBeneficioPuntos}/>
                                        </Box>
                                        </>
                                    )
                                }
                                {
                                    !loading && beneficioPuntos.length === 0 && (
                                        <Box sx={{backgroundColor: 'rgba(224, 224, 224, 0.5)', padding: '15px', borderRadius: '10px', height:'100px', justifyContent: 'center',alignItems: 'center', display: 'flex', width: '100%'}} >
                                            <Div>{"No se encontraron Beneficios"}</Div>
                                        </Box>
                                    )
                                }
                                {loading &&
                                    <Box sx={{backgroundColor: 'rgba(224, 224, 224, 0.5)', padding: '15px', borderRadius: '10px', height:'100px', width: '100%'}}>
                                    </Box>
                                }
                            </Grid>
                            </>
                        )
                    }
                </Grid>

            </Grid>

            <Popup
                largo="sm"
                title = {popUp === 'addBeneficioProducto' ? 'Agregar Puntos por Producto' : 'Agregar Puntos por Monto'}
                openPopup = {openPopup}
                setOpenPopup = {setOpenPopup}
            >
                {
                    popUp === 'addBeneficioProducto' && (
                        <AddModal
                            popUp={popUp}
                            closeModal = {() => {setOpenPopup(false)}}
                            onListBeneficioProducto = {getBeneficioProducto}
                        />
                    )
                }
                {
                    popUp === 'addBeneficioMonto' && (
                        <AddModalMonto
                            popUp={popUp}
                            closeModal = {() => {setOpenPopup(false)}}
                            onListBeneficioMonto = {getBeneficioMonto}
                        />
                    )
                }

            </Popup>

            {
                openPopup && popUp === 'addCupon' && (
                    <Popup
                        largo="sm"
                        title = {'Agregar Cupón'}
                        openPopup = {openPopup}
                        setOpenPopup = {setOpenPopup}
                    >
                        <CuponModal
                            onListCupones={getCupones}
                            closeModal={() => {setOpenPopup(false)}}
                        />
                    </Popup>
                )
            }

            {
                openPopup && popUp === 'addBeneficioPuntos' && (
                    <Popup
                        largo="sm"
                        title = {'Agregar Beneficio Puntos'}
                        openPopup = {openPopup}
                        setOpenPopup = {setOpenPopup}
                    >
                        <PuntosModal
                            onListBeneficios={getBeneficioPuntos}
                            closeModal={() => {setOpenPopup(false)}}
                        />
                    </Popup>
                )
            }

            {
                popUp === 'editEquivalencia' && (
                    <Popup
                        largo="sm"
                        title = 'Editar Equivalencia'
                        openPopup = {openPopup}
                        setOpenPopup = {setOpenPopup}
                    >
                        <EditEquivalencia
                            closeModal={() => {setOpenPopup(false)}}
                            equivalencia = {equivalenciaEmpresa}
                            onListEquivalencia = {getEquivalenciaCompany}
                        />

                    </Popup>
                )
            }

        </Protected>
    );
}
