import { listaPrecioRepository } from "../repositories/ListaPrecio.repository";
// import axios from "axios";
// import {KEY_USER_DATA} from '@constants/local-storage';
// import {readLocalStorage} from '@helpers/local-storage-helper';

export const listaPrecioService = {
    register,
    addDetalleProductoPrecio,
    getAjustesListaPrecio,
    getListaPrecioEmpresa,
    getListaPrecio,
    editAjusteListaPrecios,
    editListaPrecioDetalle,
    getCompraDetalleEmpresa,
    getProductosListaPrecio,
    getListaPrecioByProduct,
    getListaPrecioByUsuario,
}

async function register(data:{}){
   const res = await listaPrecioRepository.register(data);
   return res;
}
async function addDetalleProductoPrecio(data:{}){
    const res = await listaPrecioRepository.addDetalleProductoPrecio(data);
    return res;
 }
 async function getAjustesListaPrecio(id){
    const res = await listaPrecioRepository.getAjustesListaPrecio(id);
    return res;
 }
 async function getListaPrecioEmpresa(id_empresa){
   const res = await listaPrecioRepository.getListaPrecioEmpresa(id_empresa);
   return res;
}
async function getListaPrecio(id){
   const res = await listaPrecioRepository.getListaPrecio(id);
   return res;
}
async function getListaPrecioByProduct(idProducto){
   const res = await listaPrecioRepository.getListaPrecioByProduct(idProducto);
   return res;
}
 async function editAjusteListaPrecios(data,id){
    const res = await listaPrecioRepository.editAjusteListaPrecios(data,id);
    return res;
 }
 async function editListaPrecioDetalle(data,id){
   const res = await listaPrecioRepository.editListaPrecioDetalle(data,id);
   return res;
}
async function getCompraDetalleEmpresa(id_empresa){
   const res = await listaPrecioRepository.getCompraDetalleEmpresa(id_empresa);
   return res;
}
async function getProductosListaPrecio(id_empresa, id_usuario){
   const res = await listaPrecioRepository.getProductosListaPrecio(id_empresa, id_usuario);
   return res;
}
async function getListaPrecioByUsuario(id_usuario){
   const res = await listaPrecioRepository.getListaPrecioByUsuario(id_usuario);
   return res;
}
