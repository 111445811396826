import React, { useEffect, useState } from 'react';
import { Autocomplete, TextField } from '@mui/material';
import { Loading } from '@/components/common/Loading/Loading';
import { productoService } from "@/service/services/Producto.service";
import { useLocalStorage } from '@/toolbox/hooks/local-storage.hook';
import { KEY_USER_DATA } from '@/toolbox/constants/local-storage';

import { Divider, Grid, Typography, Tooltip } from '@mui/material';
import { Form, Formik } from 'formik';
import PriorityHighIcon from '@mui/icons-material/PriorityHigh';
import { Snackbar, Alert } from '@mui/material';
import { BeneficioPuntosService } from '@/service/services/BeneficioPuntos.service';

export const PuntosModal: React.FC<any> = (props:any): JSX.Element => {

    const {popUp, closeModal, onListBeneficios, beneficio, detailMode } = props;

    const [ loading, setLoading ] = React.useState<boolean>(false);
    const [ productoSelect,setProductoSelect ] = useState<any>("");
    const [ productsByCompany, setProductsByCompany ] = useState<Array<any>>([]);
   // eslint-disable-next-line
    const [ data, setData ] = React.useState<any>({
        id: "",
        puntos: beneficio ? beneficio.puntos : "",
        descuento: beneficio ? beneficio.descuento : "",
        max_descuento: beneficio ? beneficio.max_descuento : "",
        state: "",
        id_producto: "",
    });

    const [snackBarConfig, setSnackBarConfig] = useState<any>({
        open: false,
        severity: 'error',
        message: 'Error',
        autoHideDuration: 3000,
    });

    useEffect(() => {
        getProductsCompany();
    },  // eslint-disable-next-line
    [popUp])

    const [dataUser] = useLocalStorage(KEY_USER_DATA, undefined);
    const idCompany = dataUser && dataUser.user.id_empresa;

    const getProductsCompany = async() =>{
        setLoading(true);
        await productoService.getProductsByCompany(idCompany).then((data) =>{
            if(data.status === 200){
                setProductsByCompany(data.productos.map((el) =>{
                    return {nombre: el.nombre, id: el.id};
                }));
            }
            if(data.productos){
                setProductoSelect({
                    nombre: data.productos[0].nombre,
                    id: data.productos[0].id
                })
            }
            setLoading(false);
        });
    }

    const validateForm = (values) => {
        let errors: any ={};
        if(!values.puntos) errors.puntos = "cantidad de puntos requerido";
        if(!values.descuento) errors.descuento = "descuento requerido";

        return errors;
    }

    const onSubmit = async(values, {resetForm}) => {
        const formData = new FormData();
        formData.append('puntos', values.puntos);
        formData.append('descuento', values.descuento);
        formData.append('max_descuento', values.max_descuento);

        setLoading(true);

        if(beneficio){
            await BeneficioPuntosService.update(formData, beneficio.id).then((data) => {
                if(data.status === 200){
                    onListBeneficios && onListBeneficios();
                    closeModal && closeModal();
                }
            })
        }else{
            formData.append('id_producto', productoSelect.id);
            await BeneficioPuntosService.register(formData).then((data) => {
                if(data.status === 400){
                    setSnackBarConfig(prev => ({
                        ...prev,
                        open: true,
                        message: `${data.error}`,
                    }));
                }else{
                    onListBeneficios && onListBeneficios();
                    closeModal && closeModal();
                }
            });
        }
        setLoading(false);
    }

    return(
        <div className="container">
            {loading && <Loading/>}

            <Snackbar
                open={snackBarConfig.open}
                autoHideDuration={snackBarConfig.autoHideDuration}
                onClose={() => setSnackBarConfig(prev => ({ ...prev, open: false }))}
                anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                >
                <Alert
                onClose={() => setSnackBarConfig(prev => ({ ...prev, open: false }))}
                severity={snackBarConfig.severity}
                variant="filled"
                >
                {snackBarConfig.message}
                </Alert>
            </Snackbar>

            <Formik enableReinitialize initialValues={data} validate={ (values) => validateForm(values) } onSubmit={onSubmit}>
                {({values, errors, touched, handleSubmit, handleChange, setFieldValue}) => {
                    return (
                        <Form onSubmit={handleSubmit}>
                            <Grid container spacing={3} alignItems="center" justifyContent="center">
                                {
                                    !beneficio && (
                                        <Grid item xs={12} container style={{display: "flex", alignItems: "center"}}>
                                            <Grid item xs={4}>
                                                <Typography variant="subtitle1" className="custom-input"><b>Productos:</b></Typography>
                                            </Grid>
                                            <Grid item xs={8}>
                                                <Autocomplete
                                                    disablePortal
                                                    id="combo-box-demo"
                                                    options={productsByCompany || []}
                                                    getOptionLabel={(option) => option.nombre || ""}
                                                    sx={{ width: 367 }}
                                                    renderInput={(params) => <TextField {...params} label="Escriba para buscar" />}
                                                    value={productoSelect}
                                                    onChange={(e, newValue) => {
                                                        setProductoSelect(newValue);
                                                    }}
                                                />
                                            </Grid>
                                        </Grid>
                                    )
                                }
                                <Grid item xs={12} container style={{display: "flex", alignItems: "center"}}>
                                    <Grid item xs={4}>
                                        <Typography variant="subtitle1" className="custom-input"><b>Puntos requerido:</b></Typography>
                                    </Grid>
                                    <Grid item xs={8} style={{display: 'flex', alignItems: "center"}}>
                                        {
                                            errors.puntos && touched.puntos ? true : false && (
                                                <Tooltip title={errors.puntos}>
                                                    <PriorityHighIcon className={'iconWarning'}/>
                                                </Tooltip>
                                            )
                                        }
                                        <TextField
                                        id="puntos"
                                        type="number"
                                        name="puntos"
                                        fullWidth
                                        value={values.puntos}
                                        onChange={handleChange}
                                        placeholder="Cantidad de Puntos"
                                        error={errors.puntos && touched.puntos ? true : false}
                                        disabled={detailMode}
                                        />
                                    </Grid>
                                </Grid>
                                <Grid item xs={12} container style={{display: "flex", alignItems: "center"}}>
                                    <Grid item xs={4}>
                                        <Typography variant="subtitle1" className="custom-input"><b>Margen Descuento (%):</b></Typography>
                                    </Grid>
                                    <Grid item xs={8} style={{display: 'flex', alignItems: "center"}}>
                                        {
                                            errors.descuento && touched.descuento ? true : false && (
                                                <Tooltip title={errors.descuento}>
                                                    <PriorityHighIcon className={'iconWarning'}/>
                                                </Tooltip>
                                            )
                                        }
                                        <TextField
                                        id="descuento"
                                        type="number"
                                        name="descuento"
                                        fullWidth
                                        value={values.descuento}
                                        onChange={handleChange}
                                        placeholder="Descuento"
                                        error={errors.descuento && touched.descuento ? true : false}
                                        disabled={detailMode}
                                        />
                                    </Grid>
                                </Grid>
                                <Grid item xs={12} container style={{display: "flex", alignItems: "center"}}>
                                    <Grid item xs={4}>
                                        <Typography variant="subtitle1" className="custom-input"><b>Descuento Máximo:</b></Typography>
                                    </Grid>
                                    <Grid item xs={8} style={{display: 'flex', alignItems: "center"}}>
                                        {
                                            errors.max_descuento && touched.max_descuento ? true : false && (
                                                <Tooltip title={errors.max_descuento}>
                                                    <PriorityHighIcon className={'iconWarning'}/>
                                                </Tooltip>
                                            )
                                        }
                                        <TextField
                                        id="max_descuento"
                                        type="number"
                                        name="max_descuento"
                                        fullWidth
                                        value={values.max_descuento}
                                        onChange={handleChange}
                                        placeholder="Maximo Descuento"
                                        error={errors.max_descuento && touched.max_descuento ? true : false}
                                        disabled={detailMode}
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Divider sx={{ my: 2 }} />
                            {
                                !detailMode && (
                                    <div className="form-group mb-3 buton_container_modal">
                                        <button type="submit" className="btn btn-primary">
                                            {beneficio ? "Editar Benefico" : "Agregar Beneficio" }
                                        </button>
                                    </div>
                                )
                            }
                        </Form>
                    )
                }}
            </Formik>
        </div>
    );
}
