import { EmpresasRepository } from "../repositories/Empresas.repository";

export const empresasService = {
    getTipoEmpresa,
    getEquivalencia,
    getEmpresas,
    getEmpresasAvailable,
    getEmpresasByService,
    editState,
    register,
    update,
    listEmpresaByName,
    getPaises,
    getPais,
    editEquivalencia,
    editTipoAtencionMesero,
    editStockRestricction,
    requestServices,
    findCompany,
}

async function getPaises(){
    const res = await EmpresasRepository.getPaises();
    return res;
}

async function getPais(id: number){
    const res = await EmpresasRepository.getPais(id);
    return res;
}

async function getTipoEmpresa(){
    const res = await EmpresasRepository.getTipoEmpresa();
    return res;
}

async function getEquivalencia () {
    const res = await EmpresasRepository.getEquivalencia();
    return res;
}

async function getEmpresas() {
    const res = await EmpresasRepository.getEmpresas();
    return res;
}

async function getEmpresasAvailable() {
    const res = await EmpresasRepository.getEmpresasAvailable();
    return res;
}

async function getEmpresasByService(idService:number) {
    const res = await EmpresasRepository.getEmpresasByService(idService);
    return res;
}

async function editState(idEmpresa:number, state:any){
    const res = await EmpresasRepository.editState(idEmpresa, state);
    return res;
}

async function register(data:{}){
    const res = await EmpresasRepository.register(data);
    return res;
}

async function update(data:{}, idEmpresa:number){
    const res = await EmpresasRepository.update(data, idEmpresa);
    return res;
}

async function listEmpresaByName() {
    const res = await EmpresasRepository.listEmpresaByName();
    return res;
}

async function editEquivalencia(data:{}, idEmpresa:number){
    const res = await EmpresasRepository.editEquivalencia(data, idEmpresa);
    return res;
}

async function editTipoAtencionMesero(data:{}, idEmpresa:number){
    const res = await EmpresasRepository.editTipoAtencionMesero(data, idEmpresa);
    return res;
}

async function editStockRestricction(data:{}, idEmpresa:number){
    const res = await EmpresasRepository.editStockRestricction(data, idEmpresa);
    return res;
}

async function requestServices(data:{}, idEmpresa:number){
    const res = await EmpresasRepository.requestServices(data, idEmpresa);
    return res;
}

async function findCompany(idEmpresa:number){
    const res = await EmpresasRepository.findCompany(idEmpresa);
    return res;
}
