import React, { FunctionComponent, useEffect } from 'react';
import { Protected } from '@/components/layout/Protected';
import { Cuadratura } from './Cuadratura';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { Button, Grid, IconButton, MenuItem, Select, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Tooltip } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { Link } from 'react-router-dom';
import { ROUTE_CAJA } from '@/toolbox/constants/route-map';
import { userService } from '@/service/services/User.service';
import { VisibilityIcon } from '@/toolbox/constants/icons';
import Paper from '@mui/material/Paper';
import { styled } from '@mui/material/styles';
import { Loading } from '@/components/common/Loading';
import { salas } from '@/service/services/Salas.service';
import { useLocalStorage } from '@/toolbox/hooks/local-storage.hook';
import { KEY_EMPRESA, KEY_USER_DATA } from '@/toolbox/constants/local-storage';
import { ID_TYPE_RESTAURANTE } from '@/toolbox/constants/type-entity';
import { readLocalStorage } from '@/toolbox/helpers/local-storage-helper';
import { useHistory } from 'react-router-dom';
import { ROLE_ADMIN } from '@/toolbox/defaults/static-roles';

const Titulo = styled('div')(({ theme }) => ({
   ...theme.typography.button,
   padding: theme.spacing(1),
   fontWeight: 'bold',
   fontSize: '20px',
   color: 'black',
}));

interface TabPanelProps {
   children?: React.ReactNode;
   index: number;
   value: number;
}

function TabPanel(props: TabPanelProps) {
   const { children, value, index, ...other } = props;

   return (
      <div
         role="tabpanel"
         hidden={value !== index}
         id={`simple-tabpanel-${index}`}
         {...other}
      >
         {value === index && (
            <Box sx={{ p: 3 }}>
               <Typography>{children}</Typography>
            </Box>
         )}
      </div>
   );
}


export const PageCuadratura: FunctionComponent = (props: any) => {
   console.log(props)
   const idUserSelected = props?.location?.state?.userSelected || null;

   const [loading, setLoading] = React.useState<boolean>(false);
   const [showCuadratura, setShowCuadratura] = React.useState<number>(0);
   const [users, setUsers] = React.useState<Array<any>>([]);
   const [tables, setTables] = React.useState<Array<any>>([]);
   const [rooms, setRooms] = React.useState<Array<any>>([]);
   const [value, setValue] = React.useState(0);
   const [filtro, setFiltro] = React.useState('users');
   const [filtroSala, setFiltroSala] = React.useState('');
   const [itemSelected, setItemSelected] = React.useState({});
   const [dataEmpresa] = useLocalStorage(KEY_EMPRESA, {
      id: 0, id_tipo_empresa: 0,
   });
   const dataUsuario = readLocalStorage(KEY_USER_DATA);
   const role = dataUsuario?.user?.rol;
   const idUser = dataUsuario?.user?.id_usuario;
   const history = useHistory();

   const handleChange = (event: React.SyntheticEvent, newValue: number) => {
      setValue(newValue);
   };

   useEffect(() => {
      if (role === 'Cajero' || role === 'Mesero') {
         if (idUser) getUserById(idUser);
      } else {
         getUsersByEmpresa();
      }
   }, []);

   useEffect(()=>{
      if(role == ROLE_ADMIN && idUserSelected){
         getUserById(idUserSelected);
      }
   }, [idUserSelected])

   const getUserById = async (id) => {
      setLoading(true);
      await userService.getUserById(id).then(res => {
         if (res.status === 200) {
            setShowCuadratura(res?.user?.id);
            setItemSelected(res?.user)
         }
      })
      setLoading(false);
   }

   const getUsersByEmpresa = async () => {
      setLoading(true);
      await userService.getUsersByEmpresa().then(res => {
         if (res.status === 200) {
            setUsers([...res.users] || []);
         }
      })
      setLoading(false);
   }

   const getTablesByEmpresa = async (idSala) => {
      setLoading(true);
      await salas.cargarMesas(idSala).then(res => {
         if (res.status === 200) {
            setTables(res.mesas || []);
         }
      })
      setLoading(false);
   }

   const getRoomsByEmpresa = async () => {
      setLoading(true);
      await salas.cargarSalas().then(res => {
         if (res.status === 200) {
            setRooms(res.salas || []);
         }
      })
      setLoading(false);
   }

   const showCierreDiario = () => {
      history.push('/cierre-diario');
   }

   console.log(users)
   return (
      <Protected>
         {loading && <Loading />}
         {
            !Boolean(showCuadratura) &&
            <Grid item component={Link} to={ROUTE_CAJA} style={{ display: "flex", justifyContent: "flex-end" }}>
               <ArrowBackIcon style={{ backgroundColor: '#0B3F6E', color: 'white', borderRadius: '3px' }} />
            </Grid>
         }

         {
            dataEmpresa.id_tipo_empresa === ID_TYPE_RESTAURANTE && (
               <Box sx={{ marginBottom: '15px', display: 'flex', alignItems: 'center' }}>

                  <Box >
                     <Titulo>{"Filtros de Cuadratura: "}</Titulo>
                  </Box>

                  <Grid item xs={2} spacing={3} sx={{ marginRight: '5px' }}>
                     <Select
                        id="filtro"
                        size='small'
                        value={filtro}
                        onChange={(event) => {
                           setFiltro(event.target.value);
                        }}
                        sx={{ width: '250px' }}
                        disabled={Boolean(showCuadratura)}
                     >
                        <MenuItem value={''} disabled><em>seleccionar filtro</em></MenuItem>
                        <MenuItem value={'users'} onClick={() => {
                           getUsersByEmpresa();
                        }}
                        >Cuadratura por usuarios</MenuItem>

                        <MenuItem value={'rooms'} onClick={() => {
                           getRoomsByEmpresa();

                        }}>Cuadratura por salas</MenuItem>
                     </Select>
                  </Grid>

                  <Button
                     variant='outlined'
                     sx={{ marginLeft: '10px', height: '36.5px', width: '165.14', fontSize: '13px !important' }}
                     onClick={() => showCierreDiario()}>
                     {'Ver cierre diario'}
                  </Button>
               </Box>
            )
         }

         <Box sx={{ width: '100%' }}>
            {
               !Boolean(showCuadratura) &&
               <>
                  <Typography variant='h6' sx={{ marginTop: "20px" }}>Ver cuadraturas de los usuarios</Typography>
                  <TableContainer component={Paper} sx={{ marginTop: "20px" }}>
                     <Table sx={{ minWidth: 650 }} aria-label="simple table">
                        <TableHead>
                           <TableRow sx={{ bgcolor: '#1976D2' }}>
                              <TableCell sx={{ color: 'white' }}>{`N°`}</TableCell>
                              <TableCell sx={{ color: 'white' }}>Nombre</TableCell>
                              <TableCell align="center" sx={{ color: 'white' }}>Acciones&nbsp;</TableCell>
                           </TableRow>
                        </TableHead>
                        <TableBody>
                           {
                              filtro === 'users' && (
                                 (users || []).map((item, index) => (
                                    <TableRow
                                       key={item.id}
                                       sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                       classes={{ hover: 'hover-row-t' }}
                                    >
                                       <TableCell component="th" scope="row">
                                          {index + 1}
                                       </TableCell>
                                       <TableCell >{item.nombre}</TableCell>
                                       <TableCell align="center" sx={{ width: '100px' }}>
                                          <Tooltip title={"Ver cuadratura del usuario"}>
                                             <IconButton aria-label="add to shopping cart" color="secondary" sx={{ marginRight: '5px' }}
                                                onClick={() => { setShowCuadratura(item.id); setItemSelected(item) }}
                                             >
                                                <VisibilityIcon />
                                             </IconButton>
                                          </Tooltip>
                                       </TableCell>

                                    </TableRow>
                                 ))
                              )
                           }
                           {
                              filtro === 'rooms' && (
                                 (rooms || []).map((item, index) => (
                                    <TableRow
                                       key={item.id}
                                       sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                       classes={{ hover: 'hover-row-t' }}
                                    >
                                       <TableCell component="th" scope="row">
                                          {index + 1}
                                       </TableCell>
                                       <TableCell >{item.nombre}</TableCell>
                                       <TableCell align="center" sx={{ width: '100px' }}>
                                          <Tooltip title={"Ver cuadratura del usuario"}>
                                             <IconButton aria-label="add to shopping cart" color="secondary" sx={{ marginRight: '5px' }}
                                                onClick={() => { setShowCuadratura(item.id); setItemSelected(item) }}
                                             >
                                                <VisibilityIcon />
                                             </IconButton>
                                          </Tooltip>
                                       </TableCell>

                                    </TableRow>
                                 ))
                              )
                           }
                        </TableBody>
                     </Table>
                  </TableContainer>
               </>
            }

            {
               Boolean(showCuadratura) &&
               <>
                  {
                     role && (role !== 'Cajero' && role !== 'Mesero') ? (
                        <Grid item style={{ display: "flex", justifyContent: "flex-end" }}>
                           <IconButton aria-label="add to shopping cart" color="secondary" sx={{ marginRight: '5px' }}
                              onClick={() => { setShowCuadratura(0) }}
                           >
                              <ArrowBackIcon style={{ backgroundColor: '#0B3F6E', color: 'white', borderRadius: '3px' }} />
                           </IconButton>
                        </Grid>
                     ) : (
                        <Grid item style={{ display: "flex", justifyContent: "flex-end" }}>
                           <IconButton aria-label="add to shopping cart" color="secondary" sx={{ marginRight: '5px' }}
                              component={Link} to={ROUTE_CAJA}
                           >
                              <ArrowBackIcon style={{ backgroundColor: '#0B3F6E', color: 'white', borderRadius: '3px' }} />
                           </IconButton>
                        </Grid>
                     )
                  }
                  <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                     <Tabs value={value} onChange={handleChange}>
                        <Tab label="Cuadratura Actual" />
                        <Tab label="Cuadratura Anterior" />
                     </Tabs>
                  </Box>
                  {
                     filtro === 'users' && (
                        <>
                           <TabPanel value={value} index={0}>
                              <Cuadratura type='actual' idUsuario={showCuadratura} itemSelected={itemSelected} filtro={filtro} />
                           </TabPanel>
                           <TabPanel value={value} index={1}>
                              <Cuadratura type='anterior' idUsuario={showCuadratura} filtro={filtro} />
                           </TabPanel>
                        </>
                     )
                  }
                  {
                     filtro === 'rooms' && (
                        <>
                           <TabPanel value={value} index={0}>
                              <Cuadratura type='actual' idSala={showCuadratura} itemSelected={itemSelected} filtro={filtro} />
                           </TabPanel>
                           <TabPanel value={value} index={1}>
                              <Cuadratura type='anterior' idSala={showCuadratura} />
                           </TabPanel>
                        </>
                     )
                  }

               </>
            }
         </Box>
      </Protected>
   );
}
