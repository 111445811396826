import { userRepository } from "../repositories/User.repository";
import { KEY_EMPRESA} from '@constants/local-storage';
import {readLocalStorage} from '@helpers/local-storage-helper';

export const userService = {
   getUser,
   getUsers,
   editState,
   register,
   update,
   listRol,
   getUsersByEmpresa,
   getUsersBySucursal,
   changePassword,
   validateDetalleClienteUser,
   getUserById
};

async function getUser () {
   const user= await userRepository.getUser();
   return user
}

async function getUsers() {
   const res = await userRepository.getUsers();
   return res;
}

async function getUserById(idUser: number) {
   const res = await userRepository.getUserById(idUser);
   return res;
}

async function getUsersByEmpresa() {
   const data: any = readLocalStorage(KEY_EMPRESA);
   const res = await userRepository.getUsersByEmpresa(data.id);
   return res;
}

async function getUsersBySucursal(id_sucursal) {
   const res = await userRepository.getUsersBySucursal(id_sucursal);
   return res;
}

async function editState(idUser:number, state:any){
   const res = await userRepository.editState(idUser, state);
   return res;
}

async function register(data:{}){
   const res = await userRepository.register(data);
   return res;
}

async function update(data:{}, idUser:number){
   const res = await userRepository.update(data, idUser);
   return res;
}

async function listRol () {
   const user= await userRepository.listRol();
   return user
}

async function changePassword(data:{}, idUser:number){
   const res = await userRepository.changePassword(data, idUser);
   return res;
}

//validar table_detalle_cliente_user ->logueo
async function validateDetalleClienteUser(data:{}){
   const res = await userRepository.validateDetalleClienteUser(data);
   return res;
}
