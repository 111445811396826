import React, { FunctionComponent, useEffect } from 'react';
import { Protected } from '@/components/layout/Protected';
import { Box, Card, CardContent, Grid, MenuItem, Select, TextField, Typography } from '@mui/material';
import { Loading } from '@/components/common/Loading';
import { styled } from '@mui/material/styles';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import esLocale from 'date-fns/locale/es';
import DatePicker from '@mui/lab/DatePicker';
import moment from 'moment';
import { ventaService } from "@/service/services/Venta.service";
import './componentes/style.sass';
import { productoService } from '@/service/services/Producto.service';
import { clientesService } from '@/service/services/Clientes.service';
import { BarChart } from './componentes/Graficos/BarChart';
import { DonutChart } from './componentes/Graficos/DonutChart';
import { KEY_EMPRESA } from '@constants/local-storage';
import { readLocalStorage } from '@helpers/local-storage-helper';
import { ROUTE_REPORTE_TABLE } from '@/toolbox/constants/route-map';
import { Link } from 'react-router-dom';
import { useLocalStorage } from '@/toolbox/hooks/local-storage.hook';
import { KEY_USER_DATA } from '@/toolbox/constants/local-storage';
import ContentPasteOffIcon from '@mui/icons-material/ContentPasteOff';
import './Reporte.sass';


const Titulo = styled('div')(({ theme }) => ({
   ...theme.typography.button,
   padding: theme.spacing(1),
   fontWeight: 'bold',
   fontSize: '20px',
   color: 'black',
}));

export const Reporte: FunctionComponent = (props: any) => {
   const [loading, setLoading] = React.useState<boolean>(false);

   const [filtro, setFiltro] = React.useState('month');
   const [ventasMonth, setVentasMonth] = React.useState(new Date());
   const [ventasDay, setVentasDay] = React.useState(new Date());

   const [fechaDe, setFechaDe] = React.useState(new Date());
   const [fechaA, setFechaA] = React.useState(new Date());

   const [TypeProducts, setTypeProducts] = React.useState([]);
   const [gananciaTotal, setGananciaTotal] = React.useState([]);
   const [newClient, setNewClient] = React.useState(0);
   const [ventasTotal, setVentasTotal] = React.useState([]);
   const [topProductos, setTopProductos] = React.useState([]);
   const [topVentas, setTopVentas] = React.useState([]);
   const [formasPago, setFormasPago] = React.useState([]);
   const [empleado, setEmpleado] = React.useState({
      nombre: ''
   });

   const [topProductosSimple, setTopProductosSimple] = React.useState([]);
   const [topProductosAgrupado, setTopProductosAgrupado] = React.useState([]);
   const [topProductosMenu, setTopProductosMenu] = React.useState([]);
   const [productosVendidosByMenu, setProductosVendidosByMenu] = React.useState([]);
   const [productosVendidosByAgrupado, setProductosVendidosByAgrupado] = React.useState([]);
   // eslint-disable-next-line
   const [productsByCompany, setProductsByCompany] = React.useState<Array<any>>([]);
   // const [productoSelect,setProductoSelect] = React.useState<any>("");

   const SessionEmpresa: any = readLocalStorage(KEY_EMPRESA);
   const Monedapais = SessionEmpresa.country_abbreviation;

   useEffect(() => {
      TypeProductsByMes(ventasMonth);
      gananciaTotalMes(ventasMonth);
      ventaTotalMes(ventasMonth);
      topProductsMoreSelledMes(ventasMonth);
      empleadoMes(ventasMonth);
      newClientMes(ventasMonth);
      topVentaMoreSelledMes(ventasMonth);
      topProductSimpleMes(ventasMonth);
      topProductAgrupadoMes(ventasMonth);
      topProductMenuMes(ventasMonth);
      getProductsCompany();
      tiposPagosMasUsadosMes(ventasMonth);
      topProductsByAgrupadoMes(ventasMonth);
      topProductsByMenuMes(ventasMonth);
   }, // eslint-disable-next-line
      [])

   const [dataUser] = useLocalStorage(KEY_USER_DATA, undefined);
   const idCompany = dataUser && dataUser.user.id_empresa;

   const getProductsCompany = async () => {
      setLoading(true);
      await productoService.getProductsByCompany(idCompany).then((data) => {
         if (data.status === 200) {
            setProductsByCompany(data.productos.map((el) => {
               return { label: el.nombre, key: el.id };
            }));
         }
         setLoading(false);
      });
   }

   //TOP 5 PRODUCTOS (DASBOARD BARRAS)
   const topProductsMoreSelledMes = async (date) => {
      setLoading(true);
      const fecha = moment(date).format('MM-YYYY');
      await productoService.topProductsMoreSelledMes(fecha).then((data) => {
         setTopProductos(data)
      })
      setLoading(false);
   }
   const topProductsMoreSelledDay = async (date) => {
      setLoading(true);
      const fecha = moment(date).format('DD-MM-YYYY');
      await productoService.topProductsMoreSelledDay(fecha).then((data) => {
         setTopProductos(data)
      })
      setLoading(false);
   }
   const topProductsMoreSelledFechas = async (fecha_de, fecha_a) => {
      setLoading(true);
      const fecha1 = moment(fecha_de).format('DD-MM-YYYY');
      const fecha2 = moment(fecha_a).format('DD-MM-YYYY');
      await productoService.topProductsMoreSelledFechas(fecha1, fecha2).then((data) => {
         setTopProductos(data)
      })
      setLoading(false);
   }

   //PRODUCTOS MAS VENDIDOS POR AGRUPADOS
   const topProductsByAgrupadoMes = async (date) => {
      setLoading(true);
      const fecha = moment(date).format('MM-YYYY');
      await productoService.topProductsByAgrupadoMes(fecha).then((data) => {
         setProductosVendidosByAgrupado(data)
      })
      setLoading(false);
   }
   const topProductsByAgrupadoDay = async (date) => {
      setLoading(true);
      const fecha = moment(date).format('DD-MM-YYYY');
      await productoService.topProductsByAgrupadoDay(fecha).then((data) => {
         setProductosVendidosByAgrupado(data)
      })
      setLoading(false);
   }
   const topProductsByAgrupadoFechas = async (fecha_de, fecha_a) => {
      setLoading(true);
      const fecha1 = moment(fecha_de).format('DD-MM-YYYY');
      const fecha2 = moment(fecha_a).format('DD-MM-YYYY');
      await productoService.topProductsByAgrupadoFechas(fecha1, fecha2).then((data) => {
         setProductosVendidosByAgrupado(data)
      })
      setLoading(false);
   }

   //PRODUCTOS MAS VENDIDOS POR MENUS
   const topProductsByMenuMes = async (date) => {
      setLoading(true);
      const fecha = moment(date).format('MM-YYYY');
      await productoService.topProductsByMenuMes(fecha).then((data) => {
         setProductosVendidosByMenu(data)
      })
      setLoading(false);
   }
   const topProductsByMenuDay = async (date) => {
      setLoading(true);
      const fecha = moment(date).format('DD-MM-YYYY');
      await productoService.topProductsByMenuDay(fecha).then((data) => {
         setProductosVendidosByMenu(data)
      })
      setLoading(false);
   }
   const topProductsByMenuFechas = async (fecha_de, fecha_a) => {
      setLoading(true);
      const fecha1 = moment(fecha_de).format('DD-MM-YYYY');
      const fecha2 = moment(fecha_a).format('DD-MM-YYYY');
      await productoService.topProductsByMenuFechas(fecha1, fecha2).then((data) => {
         setProductosVendidosByMenu(data)
      })
      setLoading(false);
   }

   //TOP 5 VENTAS (DASBOARD BARRAS)
   const topVentaMoreSelledMes = async (date) => {
      setLoading(true);
      const fecha = moment(date).format('MM-YYYY');
      await ventaService.getVentasTopByMonth(fecha).then((data) => {
         setTopVentas(data)
      })
      setLoading(false);
   }
   const topVentaMoreSelledDay = async (date) => {
      setLoading(true);
      const fecha = moment(date).format('DD-MM-YYYY');
      await ventaService.getVentasTopByDay(fecha).then((data) => {
         setTopVentas(data)
      })
      setLoading(false);
   }
   const topVentaMoreSelledFechas = async (fecha_de, fecha_a) => {
      setLoading(true);
      const fecha1 = moment(fecha_de).format('DD-MM-YYYY');
      const fecha2 = moment(fecha_a).format('DD-MM-YYYY');
      await ventaService.getVentasTopByDFechas(fecha1, fecha2).then((data) => {
         setTopVentas(data)
      })
      setLoading(false);
   }

   //ENDPOINT TARJETAS
   //ganancias
   const gananciaTotalMes = async (date) => {
      setLoading(true);
      const fecha = moment(date).format('MM-YYYY');
      await ventaService.gananciaTotalMes(fecha).then((data) => {
         setGananciaTotal(data)
      })
      setLoading(false);
   }
   const gananciaTotalDay = async (date) => {
      setLoading(true);
      const fecha = moment(date).format('DD-MM-YYYY');
      await ventaService.gananciaTotalDay(fecha).then((data) => {
         setGananciaTotal(data)
      })
      setLoading(false);
   }
   const gananciaTotalFechas = async (fecha_de, fecha_a) => {
      setLoading(true);
      const fecha1 = moment(fecha_de).format('DD-MM-YYYY');
      const fecha2 = moment(fecha_a).format('DD-MM-YYYY');
      await ventaService.gananciaTotalFechas(fecha1, fecha2).then((data) => {
         setGananciaTotal(data)
      })
      setLoading(false);
   }

   //NewClients
   const newClientMes = async (date) => {
      setLoading(true);
      const fecha = moment(date).format('MM-YYYY');
      await clientesService.getNewClientMes(fecha).then((data) => {
         setNewClient(data)
      })
      setLoading(false);
   }
   const newClientDay = async (date) => {
      setLoading(true);
      const fecha = moment(date).format('DD-MM-YYYY');
      await clientesService.getNewClientDay(fecha).then((data) => {
         setNewClient(data)
      })
      setLoading(false);
   }
   const newClientFechas = async (fecha_de, fecha_a) => {
      setLoading(true);
      const fecha1 = moment(fecha_de).format('DD-MM-YYYY');
      const fecha2 = moment(fecha_a).format('DD-MM-YYYY');
      await clientesService.getNewClientFechas(fecha1, fecha2).then((data) => {
         setNewClient(data)
      })
      setLoading(false);
   }
   //num ventas
   const ventaTotalMes = async (date) => {
      setLoading(true);
      const fecha = moment(date).format('MM-YYYY');
      await ventaService.ventaTotalMes(fecha).then((data) => {
         setVentasTotal(data)
      })
      setLoading(false);
   }
   const ventaTotalDay = async (date) => {
      setLoading(true);
      const fecha = moment(date).format('DD-MM-YYYY');
      await ventaService.ventaTotalDay(fecha).then((data) => {
         setVentasTotal(data)
      })
      setLoading(false);
   }
   const ventaTotalFechas = async (fecha_de, fecha_a) => {
      setLoading(true);
      const fecha1 = moment(fecha_de).format('DD-MM-YYYY');
      const fecha2 = moment(fecha_a).format('DD-MM-YYYY');
      await ventaService.ventaTotalFechas(fecha1, fecha2).then((data) => {
         setVentasTotal(data)
      })
      setLoading(false);
   }
   //empleado
   const empleadoMes = async (date) => {
      setLoading(true);
      const fecha = moment(date).format('MM-YYYY');
      await ventaService.empleadoMes(fecha).then((data) => {
         setEmpleado(data)
      })
      setLoading(false);
   }
   const empleadoDay = async (date) => {
      setLoading(true);
      const fecha = moment(date).format('DD-MM-YYYY');
      await ventaService.empleadoDay(fecha).then((data) => {
         setEmpleado(data)
      })
      setLoading(false);
   }
   const empleadoFechas = async (fecha_de, fecha_a) => {
      setLoading(true);
      const fecha1 = moment(fecha_de).format('DD-MM-YYYY');
      const fecha2 = moment(fecha_a).format('DD-MM-YYYY');
      await ventaService.empleadoFechas(fecha1, fecha2).then((data) => {
         setEmpleado(data)
      })
      setLoading(false);
   }

   //FORMAS DE PAGOS MAS USADOS (DASBOARD-DONAS)
   const tiposPagosMasUsadosMes = async (date) => {
      setLoading(true);
      const fecha = moment(date).format('MM-YYYY');
      await ventaService.tiposPagosMasUsadosMes(fecha).then((data) => {
         setFormasPago(data)
      })
      setLoading(false);
   }
   const tiposPagosMasUsadosDay = async (date) => {
      setLoading(true);
      const fecha = moment(date).format('DD-MM-YYYY');
      await ventaService.tiposPagosMasUsadosDay(fecha).then((data) => {
         setFormasPago(data)
      })
      setLoading(false);
   }
   const tiposPagosMasUsadosFechas = async (fecha_de, fecha_a) => {
      setLoading(true);
      const fecha1 = moment(fecha_de).format('DD-MM-YYYY');
      const fecha2 = moment(fecha_a).format('DD-MM-YYYY');
      await ventaService.tiposPagosMasUsadosFechas(fecha1, fecha2).then((data) => {
         setFormasPago(data)
      })
      setLoading(false);
   }

   //TIPOS DE PRODUCTOS MAS VENDIDOS (DASBOARD-DONAS)
   const TypeProductsByMes = async (date) => {
      setLoading(true);
      const fecha = moment(date).format('MM-YYYY');
      await productoService.getTypeProductsByMes(fecha).then((data) => {
         setTypeProducts(data)
      })
      setLoading(false);
   }
   const TypeProductsByDay = async (date) => {
      setLoading(true);
      const fecha = moment(date).format('DD-MM-YYYY');
      await productoService.getTypeProductsByDay(fecha).then((data) => {
         setTypeProducts(data)
      })
      setLoading(false);
   }
   const TypeProductsByFechas = async (fecha_de, fecha_a) => {
      setLoading(true);
      const fecha1 = moment(fecha_de).format('DD-MM-YYYY');
      const fecha2 = moment(fecha_a).format('DD-MM-YYYY');
      await productoService.getTypeProductsByFechas(fecha1, fecha2).then((data) => {
         setTypeProducts(data)
      })
      setLoading(false);
   }

   //PRODUCTOS MAS VENDIDOS - SIMPLES (DASBOARD-DONAS)
   const topProductSimpleMes = async (date) => {
      setLoading(true);
      const fecha = moment(date).format('MM-YYYY');
      await productoService.topProductSimpleMes(fecha).then((data) => {
         setTopProductosSimple(data)
      })
      setLoading(false);
   }
   const topProductSimpleDay = async (date) => {
      setLoading(true);
      const fecha = moment(date).format('DD-MM-YYYY');
      await productoService.topProductSimpleDay(fecha).then((data) => {
         setTopProductosSimple(data)
      })
      setLoading(false);
   }
   const topProductSimpleFechas = async (fecha_de, fecha_a) => {
      setLoading(true);
      const fecha1 = moment(fecha_de).format('DD-MM-YYYY');
      const fecha2 = moment(fecha_a).format('DD-MM-YYYY');
      await productoService.topProductSimpleFechas(fecha1, fecha2).then((data) => {
         setTopProductosSimple(data)
      })
      setLoading(false);
   }
   //PRODUCTOS MAS VENDIDOS - AGRUPADOS (DASBOARD-DONAS)
   const topProductAgrupadoMes = async (date) => {
      setLoading(true);
      const fecha = moment(date).format('MM-YYYY');
      await productoService.topProductAgrupadoMes(fecha).then((data) => {
         setTopProductosAgrupado(data)
      })
      setLoading(false);
   }
   const topProductAgrupadoDay = async (date) => {
      setLoading(true);
      const fecha = moment(date).format('DD-MM-YYYY');
      await productoService.topProductAgrupadoDay(fecha).then((data) => {
         setTopProductosAgrupado(data)
      })
      setLoading(false);
   }
   const topProductAgrupadoFechas = async (fecha_de, fecha_a) => {
      setLoading(true);
      const fecha1 = moment(fecha_de).format('DD-MM-YYYY');
      const fecha2 = moment(fecha_a).format('DD-MM-YYYY');
      await productoService.topProductAgrupadoFechas(fecha1, fecha2).then((data) => {
         setTopProductosAgrupado(data)
      })
      setLoading(false);
   }
   //PRODUCTOS MAS VENDIDOS - MENU (DASBOARD-DONAS)
   const topProductMenuMes = async (date) => {
      setLoading(true);
      const fecha = moment(date).format('MM-YYYY');
      await productoService.topProductMenuMes(fecha).then((data) => {
         setTopProductosMenu(data)
      })
      setLoading(false);
   }
   const topProductMenuDay = async (date) => {
      setLoading(true);
      const fecha = moment(date).format('DD-MM-YYYY');
      await productoService.topProductMenuDay(fecha).then((data) => {
         setTopProductosMenu(data)
      })
      setLoading(false);
   }
   const topProductMenuFechas = async (fecha_de, fecha_a) => {
      setLoading(true);
      const fecha1 = moment(fecha_de).format('DD-MM-YYYY');
      const fecha2 = moment(fecha_a).format('DD-MM-YYYY');
      await productoService.topProductMenuFechas(fecha1, fecha2).then((data) => {
         setTopProductosMenu(data)
      })
      setLoading(false);
   }

   return (
      <Protected>

         {loading && <Loading />}
         <Box sx={{ marginBottom: '15px' }}>
            <Box sx={{ marginBottom: '10px' }}>
               <Titulo>{"Filtros de busqueda"}</Titulo>
            </Box>
         </Box>

         <Grid container item xs={12} className='container'>
            <Grid item xs={2} spacing={3} style={{ marginRight: 50 }}>
               <Select className='select-container'
                  id="filtro"
                  value={filtro}
                  onChange={(event) => {
                     setFiltro(event.target.value);
                  }}
                  fullWidth
               >
                  <MenuItem value={''} disabled><em>seleccionar filtro</em></MenuItem>
                  <MenuItem value={'month'} onClick={() => {
                     TypeProductsByMes(ventasMonth)
                     topProductsMoreSelledMes(ventasMonth)
                     gananciaTotalMes(ventasMonth)
                     ventaTotalMes(ventasMonth)
                     empleadoMes(ventasMonth)
                     newClientMes(ventasMonth)
                     topVentaMoreSelledMes(ventasMonth)
                     topProductSimpleMes(ventasMonth)
                     topProductAgrupadoMes(ventasMonth)
                     topProductMenuMes(ventasMonth)
                     tiposPagosMasUsadosMes(ventasMonth);
                     topProductsByAgrupadoMes(ventasMonth);
                     topProductsByMenuMes(ventasMonth);
                  }}
                  >
                     ventas por mes
                  </MenuItem>
                  <MenuItem value={'day'} onClick={() => {
                     TypeProductsByDay(ventasDay)
                     topProductsMoreSelledDay(ventasDay)
                     gananciaTotalDay(ventasDay)
                     ventaTotalDay(ventasDay)
                     empleadoDay(ventasDay)
                     newClientDay(ventasDay)
                     topVentaMoreSelledDay(ventasDay)
                     topProductSimpleDay(ventasDay)
                     topProductAgrupadoDay(ventasDay)
                     topProductMenuDay(ventasDay)
                     tiposPagosMasUsadosDay(ventasDay)
                     topProductsByAgrupadoDay(ventasDay)
                     topProductsByMenuDay(ventasDay)

                  }}
                  >
                     ventas por día
                  </MenuItem>
                  <MenuItem value={'dates'}>ventas entre fechas</MenuItem>
                  {/* <MenuItem value={'products'}>buscar producto</MenuItem> */}
               </Select>
            </Grid>
            {/* {
            filtro === 'products' && (
               <Grid item xs={2}>
                  <Autocomplete
                     disablePortal
                     id="combo-box-demo"
                     options={productsByCompany}
                     sx={{ width: 300 }}
                     renderInput={(params) => <TextField {...params} label="Escriba para buscar" />}
                     value={productoSelect}
                     onChange={(e, newValue) => {
                        setProductoSelect(newValue);
                     }}
                     size='medium'
                  />
               </Grid>
            )
         } */}
            {
               filtro === 'month' && (
                  <Grid item xs={2}>
                     <LocalizationProvider dateAdapter={AdapterDateFns} locale={esLocale}>
                        <DatePicker
                           views={['year', 'month']}
                           minDate={new Date('2020-01-01')}
                           maxDate={new Date('2023-01-01')}
                           value={ventasMonth}
                           onChange={(newValue) => {
                              setVentasMonth(newValue);
                              TypeProductsByMes(newValue);
                              topProductsMoreSelledMes(newValue);
                              gananciaTotalMes(newValue);
                              ventaTotalMes(newValue);
                              empleadoMes(newValue);
                              newClientMes(newValue);
                              topVentaMoreSelledMes(newValue)
                              topProductSimpleMes(newValue)
                              topProductAgrupadoMes(newValue)
                              topProductMenuMes(newValue)
                              tiposPagosMasUsadosMes(newValue)
                              topProductsByAgrupadoMes(newValue)
                              topProductsByMenuMes(newValue)
                           }}
                           renderInput={(params) => <TextField {...params} helperText={null} />}
                        />
                     </LocalizationProvider>
                  </Grid>
               )
            }
            {
               filtro === 'day' && (
                  <Grid item xs={2}>
                     <LocalizationProvider dateAdapter={AdapterDateFns} locale={esLocale}>
                        <DatePicker
                           views={['year', 'month', 'day']}
                           minDate={new Date('2020-01-01')}
                           maxDate={new Date('2023-01-01')}
                           value={ventasDay}
                           onChange={(newValue) => {
                              setVentasDay(newValue);
                              TypeProductsByDay(newValue);
                              topProductsMoreSelledDay(newValue);
                              gananciaTotalDay(newValue);
                              ventaTotalDay(newValue);
                              empleadoDay(newValue);
                              newClientDay(newValue);
                              topVentaMoreSelledDay(newValue)
                              topProductSimpleDay(newValue)
                              topProductAgrupadoDay(newValue)
                              topProductMenuDay(newValue)
                              tiposPagosMasUsadosDay(newValue)
                              topProductsByAgrupadoDay(newValue)
                              topProductsByMenuDay(newValue)
                           }}
                           renderInput={(params) => <TextField {...params} helperText={null} />}
                        />
                     </LocalizationProvider>
                  </Grid>
               )
            }
            {
               filtro === 'dates' && (
                  <Grid className='container-fechas' xs={8}>
                     <span className='spacing'>Del: </span>
                     <Grid item xs={3} style={{ marginRight: 10 }}>
                        <LocalizationProvider dateAdapter={AdapterDateFns} locale={esLocale}>
                           <DatePicker
                              views={['year', 'month', 'day']}
                              minDate={new Date('2020-01-01')}
                              maxDate={new Date('2023-01-01')}
                              value={fechaDe}
                              onChange={(newValue) => {
                                 setFechaDe(newValue);
                              }}
                              renderInput={(params) => <TextField {...params} helperText={null} />}
                           />
                        </LocalizationProvider>
                     </Grid>
                     <span className='spacing'>al: </span>
                     <Grid item xs={3}>
                        <LocalizationProvider dateAdapter={AdapterDateFns} locale={esLocale}>
                           <DatePicker
                              views={['year', 'month', 'day']}
                              minDate={new Date('2020-01-01')}
                              maxDate={new Date('2023-01-01')}
                              value={fechaA}
                              onChange={(newValue) => {
                                 setFechaA(newValue);
                                 TypeProductsByFechas(fechaDe, newValue);
                                 topProductsMoreSelledFechas(fechaDe, newValue);
                                 gananciaTotalFechas(fechaDe, newValue);
                                 ventaTotalFechas(fechaDe, newValue);
                                 empleadoFechas(fechaDe, newValue);
                                 newClientFechas(fechaDe, newValue);
                                 topVentaMoreSelledFechas(fechaDe, newValue);
                                 topProductSimpleFechas(fechaDe, newValue);
                                 topProductAgrupadoFechas(fechaDe, newValue);
                                 topProductMenuFechas(fechaDe, newValue);
                                 tiposPagosMasUsadosFechas(fechaDe, newValue)
                                 topProductsByAgrupadoFechas(fechaDe, newValue)
                                 topProductsByMenuFechas(fechaDe, newValue)
                              }}
                              renderInput={(params) => <TextField {...params} helperText={null} />}
                           />
                        </LocalizationProvider>
                     </Grid>
                  </Grid>
               )
            }

         </Grid>

         {/* TARJETAS */}
         <Grid container className="container-card" style={{ marginBottom: '20px', marginTop: '30px', alignItems: 'center' }}>
            <Grid item xs={3}>
               <Card className="card" style={{ backgroundColor: "#F0F8FF" }}>
                  <CardContent>
                     <Typography sx={{ fontSize: 16, textAlign: 'center' }} color="text.secondary" gutterBottom>
                        {
                           !loading && gananciaTotal && (
                              `${gananciaTotal} ${Monedapais}`
                           )
                        }
                     </Typography>
                     <Typography color="text.secondary" sx={{ mb: 1.5, textAlign: 'center' }}>
                        Ganancia Total
                     </Typography>
                  </CardContent>
               </Card>
            </Grid>
            <Grid item xs={3}>
               <Card className="card" style={{ backgroundColor: "#F0F8FF" }}>
                  <CardContent>
                     <Typography sx={{ fontSize: 16, textAlign: 'center' }} color="text.secondary" gutterBottom>
                        {
                           !loading && ventasTotal && (
                              `# ${ventasTotal}`
                           )
                        }
                     </Typography>
                     <Typography sx={{ mb: 1.5, textAlign: 'center' }} color="text.secondary">
                        Total Ventas
                     </Typography>
                  </CardContent>
               </Card>
            </Grid>
            <Grid item xs={3} >
               <Card className="card" style={{ backgroundColor: "#F0F8FF" }}>
                  <CardContent>
                     <Typography sx={{ fontSize: 16, textAlign: 'center' }} color="text.secondary" gutterBottom>
                        {
                           !loading && newClient && (
                              `# ${newClient}`
                           )
                        }
                     </Typography>
                     <Typography sx={{ mb: 1.5, textAlign: 'center' }} color="text.secondary">
                        Nuevos Clientes
                     </Typography>
                  </CardContent>
               </Card>
            </Grid>
            <Grid item xs={3}>
               <Card className="card" style={{ backgroundColor: "#F0F8FF" }}>
                  <CardContent>
                     <Typography sx={{ fontSize: 16, textAlign: 'center' }} color="text.secondary" gutterBottom>
                        {
                           empleado.nombre
                        }
                     </Typography>
                     <Typography sx={{ mb: 1.5, textAlign: 'center' }} color="text.secondary">
                        Empleado Mes
                     </Typography>
                  </CardContent>
               </Card>
            </Grid>
         </Grid>

         {/* GRAFICOS */}

         <Grid container spacing={0} alignItems="center" justifyContent="center" style={{ marginBottom: '20px', marginTop: '30px', textAlign: 'center' }}>
            {/* {
               productoSelect && ( */}
            <Grid item xs={6} component={Link} to={ROUTE_REPORTE_TABLE} className="styleDefault">
               <Titulo>{`Tipo de Pagos Mas Usados`}</Titulo>
               {
                  !loading && (
                     <Box sx={{ backgroundColor: 'rgba(224, 224, 224, 0.5)', padding: '15px', borderRadius: '10px', marginRight: '6px' }}>
                        {
                           !loading && formasPago.length > 1 && (formasPago[1][1] > 0 || formasPago[2][1] > 0 || formasPago[3][1] > 0) && (
                              <DonutChart data={formasPago} />
                           )
                        }
                        {
                           !loading && formasPago.length > 1 && (formasPago[1][1] === 0 && formasPago[2][1] === 0 && formasPago[3][1] === 0) && (
                              <Box sx={{ backgroundColor: 'rgb(255, 255, 255)', padding: '15px', borderRadius: '10px', height: '370px' }}>
                                 <Grid container justifyContent="center" marginTop="80px">
                                    <ContentPasteOffIcon className="iconData" />
                                 </Grid>
                              </Box>
                           )
                        }

                     </Box>
                  )
               }
               {
                  loading &&
                  <Box sx={{ backgroundColor: 'rgba(224, 224, 224, 0.5)', padding: '15px', borderRadius: '10px', height: '370px', marginRight: '6px' }}>
                  </Box>
               }
            </Grid>
            {/*  )}  */}

            <Grid item xs={6} component={Link} to={ROUTE_REPORTE_TABLE} className="styleDefault">
               <Titulo>{"Productos mas vendidos"}</Titulo>
               {
                  !loading && (
                     <Box sx={{ backgroundColor: 'rgba(224, 224, 224, 0.5)', padding: '15px', borderRadius: '10px', marginRight: '3px' }}>
                        {
                           !loading && topProductos.length > 1 && (
                              <BarChart data={topProductos} type="producto" />
                           )
                        }
                        {
                           !loading && topProductos.length === 1 && (
                              <Box sx={{ backgroundColor: 'rgb(255, 255, 255)', padding: '15px', borderRadius: '10px', height: '370px' }}>
                                 <Grid container justifyContent="center" marginTop="80px">
                                    <ContentPasteOffIcon className="iconData" />
                                 </Grid>
                              </Box>
                           )
                        }
                     </Box>
                  )
               }
               {
                  loading && (
                     <Box sx={{ backgroundColor: 'rgba(224, 224, 224, 0.5)', padding: '15px', borderRadius: '10px', height: '370px', marginRight: '3px' }}>
                     </Box>
                  )
               }
            </Grid>
            <Grid item xs={6} component={Link} to={ROUTE_REPORTE_TABLE} className="styleDefault">
               <Titulo>{"Top 5 Ventas"}</Titulo>
               {
                  !loading && (
                     <Box sx={{ backgroundColor: 'rgba(224, 224, 224, 0.5)', padding: '15px', borderRadius: '10px', marginLeft: '3px', marginRight: '3px' }}>
                        {
                           !loading && topVentas.length > 1 && (
                              <BarChart data={topVentas} type="venta" />
                           )
                        }
                        {
                           !loading && topVentas.length === 1 && (
                              <Box sx={{ backgroundColor: 'rgb(255, 255, 255)', padding: '15px', borderRadius: '10px', height: '370px', marginLeft: '3px', marginRight: '3px' }}>
                                 <Grid container justifyContent="center" marginTop="80px">
                                    <ContentPasteOffIcon className="iconData" />
                                 </Grid>
                              </Box>
                           )
                        }

                     </Box>
                  )
               }
               {
                  loading && (
                     <Box sx={{ backgroundColor: 'rgba(224, 224, 224, 0.5)', padding: '15px', borderRadius: '10px', height: '370px', marginLeft: '3px', marginRight: '3px' }}>
                     </Box>
                  )
               }
            </Grid>
            <Grid item xs={6} component={Link} to={ROUTE_REPORTE_TABLE} className="styleDefault">
               <Titulo>{"Tipos de Productos mas vendidos"}</Titulo>
               {!loading && (
                  <Box sx={{ backgroundColor: 'rgba(224, 224, 224, 0.5)', padding: '15px', borderRadius: '10px', marginRight: '3px', marginLeft: '3px' }}>
                     {
                        !loading && TypeProducts.length > 1 && (TypeProducts[1][1] > 0 || TypeProducts[2][1] > 0 || TypeProducts[3][1] > 0) && (
                           <DonutChart data={TypeProducts} />
                        )
                     }
                     {
                        !loading && TypeProducts.length > 1 && (TypeProducts[1][1] === 0 && TypeProducts[2][1] === 0 && TypeProducts[3][1] === 0) && (
                           <Box sx={{ backgroundColor: 'rgb(255, 255, 255)', padding: '15px', borderRadius: '10px', height: '370px', marginLeft: '3px' }}>
                              <Grid container justifyContent="center" marginTop="80px">
                                 <ContentPasteOffIcon className="iconData" />
                              </Grid>
                           </Box>
                        )
                     }
                  </Box>
               )
               }
               {
                  loading && (
                     <Box sx={{ backgroundColor: 'rgba(224, 224, 224, 0.5)', padding: '15px', borderRadius: '10px', height: '370px', marginRight: '3px', marginLeft: '3px' }}>
                     </Box>
                  )
               }
            </Grid>
            <Grid item xs={6} component={Link} to={ROUTE_REPORTE_TABLE} className="styleDefault">
               <Titulo>{"Productos Simples mas vendidos"}</Titulo>
               {!loading && (
                  <Box sx={{ backgroundColor: 'rgba(224, 224, 224, 0.5)', padding: '15px', borderRadius: '10px', marginLeft: '3px', marginRight: '3px' }}>
                     {
                        !loading && topProductosSimple.length > 1 && (
                           <DonutChart data={topProductosSimple} />
                        )
                     }
                     {
                        !loading && topProductosSimple.length === 1 && (
                           <Box sx={{ backgroundColor: 'rgb(255, 255, 255)', padding: '15px', borderRadius: '10px', height: '370px', marginRight: '3px' }}>
                              <Grid container justifyContent="center" marginTop="80px">
                                 <ContentPasteOffIcon className="iconData" />
                              </Grid>
                           </Box>
                        )
                     }
                  </Box>
               )
               }
               {
                  loading &&
                  <Box sx={{ backgroundColor: 'rgba(224, 224, 224, 0.5)', padding: '15px', borderRadius: '10px', height: '370px', marginLeft: '3px', marginRight: '3px' }}>
                  </Box>
               }
            </Grid>
            <Grid item xs={6} component={Link} to={ROUTE_REPORTE_TABLE} className="styleDefault">
               <Titulo>{"Productos Agrupados mas vendidos"}</Titulo>
               {!loading && (
                  <Box sx={{ backgroundColor: 'rgba(224, 224, 224, 0.5)', padding: '15px', borderRadius: '10px', marginRight: '3px', marginLeft: '3px' }}>
                     {
                        !loading && topProductosAgrupado.length > 1 && (
                           <DonutChart data={topProductosAgrupado} />
                        )
                     }
                     {
                        !loading && topProductosAgrupado.length === 1 && (
                           <Box sx={{ backgroundColor: 'rgb(255, 255, 255)', padding: '15px', borderRadius: '10px', height: '370px', marginLeft: '3px' }}>
                              <Grid container justifyContent="center" marginTop="80px">
                                 <ContentPasteOffIcon className="iconData" />
                              </Grid>
                           </Box>
                        )
                     }
                  </Box>
               )
               }
               {
                  loading && (
                     <Box sx={{ backgroundColor: 'rgba(224, 224, 224, 0.5)', padding: '15px', borderRadius: '10px', height: '370px', marginRight: '3px', marginLeft: '3px' }}>
                     </Box>
                  )
               }
            </Grid>
            <Grid item xs={6} component={Link} to={ROUTE_REPORTE_TABLE} className="styleDefault">
               <Titulo>{"Productos mas vendidos por agrupado"}</Titulo>
               {
                  !loading && (
                     <Box sx={{ backgroundColor: 'rgba(224, 224, 224, 0.5)', padding: '15px', borderRadius: '10px', marginRight: '3px' }}>
                        {
                           !loading && productosVendidosByAgrupado.length > 1 && (
                              <BarChart data={productosVendidosByAgrupado} type="producto" />
                           )
                        }
                        {
                           !loading && productosVendidosByAgrupado.length === 1 && (
                              <Box sx={{ backgroundColor: 'rgb(255, 255, 255)', padding: '15px', borderRadius: '10px', height: '370px' }}>
                                 <Grid container justifyContent="center" marginTop="80px">
                                    <ContentPasteOffIcon className="iconData" />
                                 </Grid>
                              </Box>
                           )
                        }
                     </Box>
                  )
               }
               {
                  loading && (
                     <Box sx={{ backgroundColor: 'rgba(224, 224, 224, 0.5)', padding: '15px', borderRadius: '10px', height: '370px', marginRight: '3px' }}>
                     </Box>
                  )
               }
            </Grid>
            <Grid item xs={6} component={Link} to={ROUTE_REPORTE_TABLE} className="styleDefault">
               <Titulo>{"Productos Menu mas vendidos"}</Titulo>
               {!loading && (
                  <Box sx={{ backgroundColor: 'rgba(224, 224, 224, 0.5)', padding: '15px', borderRadius: '10px', marginLeft: '3px' }}>
                     {
                        !loading && topProductosMenu.length > 1 && (
                           <DonutChart data={topProductosMenu} />
                        )
                     }
                     {
                        !loading && topProductosMenu.length === 1 && (
                           <Box sx={{ backgroundColor: 'rgb(255, 255, 255)', padding: '15px', borderRadius: '10px', height: '370px' }}>
                              <Grid container justifyContent="center" marginTop="80px">
                                 <ContentPasteOffIcon className="iconData" />
                              </Grid>
                           </Box>
                        )
                     }
                  </Box>
               )
               }
               {
                  loading && (
                     <Box sx={{ backgroundColor: 'rgba(224, 224, 224, 0.5)', padding: '15px', borderRadius: '10px', height: '370px', marginLeft: '3px' }}>
                     </Box>
                  )
               }
            </Grid>
            <Grid item xs={6} component={Link} to={ROUTE_REPORTE_TABLE} className="styleDefault">
               <Titulo>{"Productos mas vendidos por Menu"}</Titulo>
               {
                  !loading && (
                     <Box sx={{ backgroundColor: 'rgba(224, 224, 224, 0.5)', padding: '15px', borderRadius: '10px', marginRight: '3px' }}>
                        {
                           !loading && productosVendidosByMenu.length > 1 && (
                              <BarChart data={productosVendidosByMenu} type="producto" />
                           )
                        }
                        {
                           !loading && productosVendidosByMenu.length === 1 && (
                              <Box sx={{ backgroundColor: 'rgb(255, 255, 255)', padding: '15px', borderRadius: '10px', height: '370px' }}>
                                 <Grid container justifyContent="center" marginTop="80px">
                                    <ContentPasteOffIcon className="iconData" />
                                 </Grid>
                              </Box>
                           )
                        }
                     </Box>
                  )
               }
               {
                  loading && (
                     <Box sx={{ backgroundColor: 'rgba(224, 224, 224, 0.5)', padding: '15px', borderRadius: '10px', height: '370px', marginRight: '3px' }}>
                     </Box>
                  )
               }
            </Grid>
         </Grid>
      </Protected>

   )
}
