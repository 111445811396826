import { editor } from "@/service/services/Editor.service";
import React, { useState, useEffect } from "react";
import  "./../AddCategoria/AddCategoria.sass";
import { Loading } from '@/components/common/Loading/Loading';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { Button } from "@mui/material";
import { TOKEN_API } from "@/toolbox/constants/local-storage";
import { useLocalStorage } from '@toolbox/hooks/local-storage.hook';
import Box from '@mui/material/Box';
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import { Popup } from '../Popup/Popup';
import { Divider, Grid, TextField, Typography } from '@mui/material';
import { DatePicker, LocalizationProvider } from "@mui/lab";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import esLocale from 'date-fns/locale/es';
import moment from "moment";


export const EditProductoAgrupado: React.FC<any> = ({idProducto, handleLoad, closeModal}): JSX.Element => {
    const [token] = useLocalStorage(TOKEN_API, "");
    const [loadData,setLoadData] = React.useState<boolean>(false);
    const [code ,setCode] = React.useState<string>("");
    const [name ,setName] = React.useState<string>("");
    const [price ,setPrice] = React.useState<string | number>("");
    const [priceOferta ,setPriceOferta] = React.useState<string | number>("");
    const [productos, setProductos] =  React.useState([]);
    const [products, setProducts] = useState<Array<any>>([]);
    const [filter, setFilter] = useState<Array<any>>([]);
    const [textSearch, setTextSearch] = useState<string>("");
    const [cargarProd, setCargarProd] = React.useState<boolean>(false);
    const [openPopup, setOpenPopup] = React.useState(false);
    const [edit_actual, setEditActual] = React.useState("");
    const [nameEdit ,setNameEdit] = React.useState<string>("");
    const [cantidadEdit ,setCantidadEdit] = React.useState<string | number>("");
    const [codeEdit ,setCodeEdit] = React.useState<string>("");
    const [dateCaducidad, setDateCaducidad] = React.useState<any>(new Date());

    const guardar = async(e) =>{
        e.preventDefault();
       if ( name && code && price) {
         setLoadData(true);

        let precio_caducidad;
        if(priceOferta !== ""){
            precio_caducidad = moment(dateCaducidad).format('DD-MM-YYYY');
        }else{
            precio_caducidad = null;
        }

         editor.editarProducto(idProducto, {
            nombre: name,
            precio_standar: price,
            precio_oferta: priceOferta || null,
            caducidad_precio_oferta: precio_caducidad|| null,
            is_supply: false,
            stock_minimo: 0,
         }).then((data) => {
             if (data && data.data && data.data.update) {
                 handleLoad && handleLoad();
                 closeModal && closeModal();
                 //window.location.reload();
             }
            setLoadData(false);
         })
       }

    }

    useEffect(()=> {
      if (idProducto && idProducto>=0) {
         setLoadData(true);
         editor.getProductoById(idProducto).then((data) => {
            if (data && data.data && data.data.productos) {
                //setLoadData(false)
               setCode(data.data.productos[0]?.codigo_erp || '')
               setName(data.data.productos[0]?.nombre || '')
               setPrice(data.data.productos[0]?.precio_standar || '')
               setPriceOferta(data.data.productos[0]?.precio_oferta || '')

               let date = data.data.productos[0]?.caducidad_precio_oferta;
               if(date){
                let format = date.split("-").reverse().join("/");
                let fecha = new Date(format);
                setDateCaducidad(fecha || '');
                }
            }
         })
        //  editor.getProductosAgrupados(idProducto)
      }

      cargar();
      fetch(`https://api2.softnet.cl/producto`, {
        method: 'GET',
        headers: {
           'Access-Control-Allow-Origin':'*',
           'Content-Type': 'application/x-www-form-urlencoded',
           token: token,
        },
        })
        .then(dataProducts => dataProducts.json())
        .then(dataProducts => {
            //setLoadData(false)
            let arrayProducts = [];
            // eslint-disable-next-line
            (dataProducts|| []).map((product) => {
               if(product.stock > 0 && product.estado === 1){
                  arrayProducts.push(product)
               }
            })
           setProducts(arrayProducts)
           setFilter(arrayProducts)
        })
        .catch(error => {
            setLoadData(false)
        })

        setTimeout(() => {
            setLoadData(false);
        }, 1100);

    }, // eslint-disable-next-line
     [])

    const handleChangeName = (e) => {
      setName(e.target.value || '')
    }

    const handleChangePrice = (e) => {
      setPrice(e.target.value || '')
    }

    const handleChangePriceOferta = (e) => {
        setPriceOferta(e.target.value || '')
    }

    const handleChangeSearch = (e) => {
        let texto = `${e.target.value}`;
        setTextSearch(texto);
        if (!texto) {
           setFilter([...products])
        }
     }

     React.useEffect(() => {
        if (textSearch) {
           let copyFilters = []
           copyFilters = products.filter(producto => (producto?.nombre.toLowerCase().includes(textSearch.toLowerCase()) || producto?.codigo.toLowerCase().includes(textSearch.toLowerCase())) )
           setFilter([...copyFilters])
        }
     }, // eslint-disable-next-line
     [textSearch])

     React.useEffect(() => {
        setProductos(productos);
      //   setTotalProducto();
     }, // eslint-disable-next-line
     [productos])

    const eliminarItem = async(id) =>{
        editor.deleteItemProductoAgrupado(id);
        cargar();
        //setTotalProducto();
    }

   //  const setTotalProducto = () => {
   //      let suma = 0;
   //      for(let i = 0; i < productos.length ; i++){
   //          if(productos[i].precio_oferta){
   //              suma += Number(productos[i]['oferta']);

   //          }else{
   //              suma += Number(productos[i]['precio_standar']);

   //          }
   //      }
   //      setPrice(suma);
   //  }

    // const handleChangePriceItem = (index, e) => {
    //     let array = productos;
    //    if (array[index]) {
    //       if (e.target.value) {
    //          array[index].precio_standar = e.target.value
    //          setProductos([...array])
    //          let sumaCombo=0;
    //          for (let i = 0; i < array.length; i++) {
    //             sumaCombo += Number(array[i].precio_standar);
    //          }
    //          setTotalCombo(sumaCombo)

    //       }
    //    }
    //  }

    const handleChangePriceEdit = (e) => {
        if (e.target.value) {
           setCantidadEdit(e.target.value || 0)
        }
     }

     const handleChangeNameEdit = (e) => {
        setNameEdit(e.target.value || '')
      }

     const agregarItem = async (products) => {
        setLoadData(true);
        const valores = {
            ...products,
            id_producto_agrupado: idProducto,
            cantidad: 1,
        }

        await editor.addItemProductoAgrupado(valores).then((data) => {
            setLoadData(false);
            return data.item;
        })

        cargar();
        //setTotalProducto();
     }

     const cargar = async ()=>{
        setLoadData(true)
        await editor.getItemsProductoAgrupado(idProducto).then((data) => {
            setLoadData(false);
            setProductos(data.data);
        });
     }

     const guardar_edit = async() =>{
        await editor.editItemProductoAgrupado(edit_actual, cantidadEdit);
        cargar();
        setOpenPopup(false);
    }

    return(
        <div>
        {loadData && <Loading/>}
        {openPopup &&
        <Popup
            largo="sm"
            title = 'Editar Producto'
            openPopup = {openPopup}
            setOpenPopup = {setOpenPopup}
        >
           <div className="container" >
                <div className="row" style={{width: '100%'}}>
                    <div className="col-md-6" >
                        <div className="card-body">

                        <Grid container spacing={3} alignItems="center" justifyContent="center">

                        <Grid item xs={12} container style={{display: "flex", alignItems: "center"}}>
                            <Grid item xs={4}>
                                <Typography variant="subtitle1" className="custom-input"><b>Código:</b></Typography>
                            </Grid>
                            <Grid item xs={8}>
                                <TextField
                                id="codigo"
                                type="text"
                                name="codigo"
                                fullWidth
                                value={codeEdit}
                                placeholder="codigo prducto"
                                disabled
                                />
                            </Grid>
                        </Grid>

                        <Grid item xs={12} container style={{display: "flex", alignItems: "center"}}>
                            <Grid item xs={4}>
                                <Typography variant="subtitle1" className="custom-input"><b>Nombre:</b></Typography>
                            </Grid>
                            <Grid item xs={8}>
                                <TextField
                                id="nombre"
                                type="text"
                                name="nombre"
                                fullWidth
                                value={nameEdit}
                                onChange={handleChangeNameEdit}
                                placeholder="nombre producto"
                                disabled
                                />
                            </Grid>
                        </Grid>

                        <Grid item xs={12} container style={{display: "flex", alignItems: "center"}}>
                            <Grid item xs={4}>
                                <Typography variant="subtitle1" className="custom-input"><b>Cantidad:</b></Typography>
                            </Grid>
                            <Grid item xs={8}>
                                <TextField
                                id="precio"
                                type="number"
                                name="precio"
                                fullWidth
                                value={cantidadEdit}
                                onChange={handleChangePriceEdit}
                                placeholder="precio producto"
                                />
                            </Grid>
                        </Grid>
                        </Grid>

                        <Divider sx={{ my: 2 }} />

                        <div className="form-group mb-3 buton_container_modal">
                            <button onClick={()=>guardar_edit()} className="btn btn-primary">Editar Producto</button>
                        </div>


                        </div>

                    </div>
                </div>
            </div>
        </Popup>}

        {cargarProd &&
            <div>
            <div className="H-card__fcontainer">
                <Box sx={{marginTop:'10px',marginBottom: '10px'}}>
            <div className="H-card__fcontainer__filter">

                <span>Filtro:</span>

                <TextField
                    type="text"
                    value={textSearch}
                    placeholder="Buscar por código o nombre del producto"
                    onChange={handleChangeSearch}
                    classes={{
                    root: "H-card__fcontainer__filter__input"
                    }}
                    sx={{ flexGrow: 1 }}
                />
                <Box
                    sx={{
                        marginLeft: '30px'
                    }}
                >
                <Button
                    onClick={() => setCargarProd(false)}
                    color="error"
                    size="small"
                    sx={{
                        display: 'flex',
                        justifyContent: 'flex-end',
                    }}
                ><KeyboardBackspaceIcon/>atrás</Button>
                </Box>

            </div>
            </Box>
            <span className="H-card__fcontainer__infoTable">*Para agregar un producto, de "click" sobre el nombre.</span>
            </div>
            <TableContainer component={Paper}>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
                <TableHead>
                    <TableRow>
                    <TableCell>{`N°`}</TableCell>
                    <TableCell>Código - Nombre</TableCell>
                    <TableCell align="right">Precio</TableCell>
                    <TableCell align="right">Precio oferta&nbsp;</TableCell>
                    <TableCell align="right">Stock&nbsp;</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {filter.map((products, index) => (
                    <TableRow
                        key={products.id}
                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                        classes={{
                            hover: 'hover-row-t'
                        }}
                    >
                            <TableCell component="th" scope="row">
                                {index+1}
                            </TableCell>
                            <TableCell component="th" scope="row">
                                <button className="H-card__tableButton" onClick={()=>{
                                    //setSeleccionado(products);
                                    setCargarProd(false);


                                    agregarItem(products);

                                    }}>
                                {products.codigo} - {products.nombre}
                                </button>
                            </TableCell>
                            <TableCell align="right">{products.precio}</TableCell>
                            <TableCell align="right">{products.precio_oferta || "--"}</TableCell>
                            <TableCell align="right">{products.stock}</TableCell>
                    </TableRow>
                    ))}
                </TableBody>
            </Table>
            </TableContainer>
            </div>
        }


        {!cargarProd &&
        <div className="container">
        <div className="row">
            <div className="col-md-6">
                <div className="card-body">
                    <form
                    onSubmit={guardar}
                    >
                        <Grid container sx={{justifyContent:"space-between", alignItems: "center"}}>
                            <Grid sx={{display:"flex", alignItems:"center", justifyContent: "center", marginBottom: "10px"}}>
                                <Box sx={{ marginRight:'8px'}}>Nombre producto: </Box>
                                    <Box sx={{ display: 'flex'}}>
                                        <Box sx={{ flexGrow: 1, marginRight:'20px'}}>
                                        <TextField
                                            id="outlined-size-small"
                                            size="small"
                                            style={{marginTop: '20px'}}
                                            value={name}
                                            onChange={handleChangeName} />
                                    </Box>
                                </Box>
                            </Grid>
                            <Grid sx={{display:"flex", alignItems:"center", justifyContent: "center", marginBottom: "10px"}}>
                                <Box sx={{ marginRight:'8px'}}>Precio producto: </Box>
                                <Box sx={{ display: 'flex'}}>
                                    <Box sx={{ flexGrow: 1, marginRight:'20px'}}>
                                        <TextField
                                            id="outlined-size-small"
                                            size="small"
                                            style={{marginTop: '20px'}}
                                            value={price}
                                            onChange={handleChangePrice} />
                                    </Box>
                                </Box>
                            </Grid>
                            <Grid sx={{display:"flex", alignItems:"center", justifyContent: "center", marginBottom: "10px"}}>
                                <Box sx={{ marginRight:'8px'}}>Precio oferta: </Box>
                                <Box sx={{ display: 'flex'}}>
                                    <Box sx={{ flexGrow: 1, marginRight:'20px'}}>
                                        <TextField
                                            id="outlined-size-small"
                                            size="small"
                                            style={{marginTop: '20px'}}
                                            value={priceOferta}
                                            onChange={handleChangePriceOferta} />
                                    </Box>
                                </Box>
                            </Grid>
                            {
                                priceOferta !== "" && (
                                <Grid sx={{display:"flex", alignItems:"center",marginBottom: "10px"}}>
                                    <Box >Caducidad precio: </Box>
                                    <LocalizationProvider dateAdapter={AdapterDateFns} locale={esLocale} style={{width: '300px'}}>
                                        <DatePicker
                                        views={['year', 'month', 'day']}
                                        minDate={new Date('2022-01-01')}
                                        maxDate={new Date('2025-01-01')}
                                        value={dateCaducidad}
                                        onChange={(newValue) => {
                                            setDateCaducidad(newValue);
                                        }}
                                        renderInput={(params) => <TextField {...params} helperText={null} />}
                                        />
                                    </LocalizationProvider>
                                </Grid>
                                )
                            }
                        </Grid>
                        {/* <div className={'form-group'}>
                            <label className="label_modal">Nombre </label>
                            <input type="text" name="nombre" value={name} onChange={handleChangeName} className="form-control input_modal"/>
                        </div> */}
                        {/* <div className={'form-group'}>
                            <label className="label_modal">Precio</label>
                            <input type="number" name="precio" value={price} onChange={handleChangePrice} className="form-control input_modal"/>
                        </div> */}

                        <Divider sx={{ my: 2 }} />

                        <TableContainer component={Paper} sx={{ marginTop: '20px' }} >
                            <Table sx={{ minWidth: 650 }} aria-label="simple table">

                                <TableHead>
                                    <TableRow>
                                    <TableCell>{`N°`}</TableCell>
                                    <TableCell>Código - Nombre</TableCell>
                                    <TableCell align="right">Cantidad</TableCell>
                                    <TableCell align="center">Acciones&nbsp;</TableCell>
                                    </TableRow>
                                </TableHead>

                                <TableBody>
                                    {productos.map((item,index) => (
                                    <TableRow
                                        key={item.id}
                                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                        classes={{
                                            hover: 'hover-row-t'
                                        }}
                                    >
                                            <TableCell component="th" scope="row">
                                                {index+1}
                                            </TableCell>

                                            <TableCell component="th" scope="row">{item?.producto_agrupado_hijo?.codigo_erp} - {item?.producto_agrupado_hijo?.nombre}</TableCell>

                                            <TableCell align="right">{item.cantidad|| "--"} </TableCell>

                                            <TableCell align="right">
                                                <Button
                                                variant="outlined" color="primary" size="small" style={{marginLeft: '20px', height:'30px', marginTop:'10px'}}
                                                onClick={()=>{ setOpenPopup(true);
                                                    setEditActual(item.id);
                                                    setNameEdit(item?.producto_agrupado_hijo?.nombre);
                                                    setCodeEdit(item?.producto_agrupado_hijo?.codigo_erp);
                                                    setCantidadEdit(item.cantidad);
                                                }}
                                                >
                                                Editar
                                                </Button>

                                                <Button
                                                variant="outlined" color="error" size="small" style={{marginLeft: '15px', height:'30px', marginTop:'10px'}}
                                                onClick={()=>eliminarItem(item.id)}
                                                >
                                                Eliminar
                                                </Button>
                                            </TableCell>
                                    </TableRow>
                                    ))}
                                </TableBody>

                            </Table>
                        </TableContainer>

                        <Button
                            onClick={() => {setCargarProd(true)}}
                            style={{marginTop: '20px'}}
                            variant="outlined"
                        >
                            Agregar producto
                        </Button>

                        <Divider sx={{ my: 2 }} />
                        <div className="form-group mb-3 buton_container_modal">
                            <button type="submit" className="btn btn-primary">Editar Producto</button>
                        </div>

                    </form>

                </div>

            </div>
        </div>
        </div>
        }

        </div>

    );
}


